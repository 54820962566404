import React from "react";

import { Graphic } from "@trace-one/design-system";
import { DatePicker } from "antd";
import { RangePickerProps as AntRangerPickerProps } from "antd/lib/date-picker";
import moment from "moment";

type RangePickerProps = AntRangerPickerProps;

const RangePicker: React.FC<RangePickerProps> = props => {
  const format = moment.localeData().longDateFormat("L");

  return (
    <DatePicker.RangePicker
      clearIcon={<Graphic name="close" size="small" />}
      separator={<span>&rarr;</span>}
      format={format}
      {...props}
    />
  );
};

RangePicker.propTypes = {};

export default RangePicker;
