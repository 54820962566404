import { createAsyncThunk } from "@reduxjs/toolkit";

import { CumdAPI, RlmdAPI } from "apis";
import { Languages } from "translations";

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async ({
    userId,
    companyActivityId,
  }: {
    userId: string;
    companyActivityId: string;
  }) => {
    const [user, companyActivityNameInEn] = await Promise.all([
      CumdAPI.getUserById(userId).then(({ data }) => data),
      // We want company activity name as user property in HEAP
      RlmdAPI.getReferenceListItemsByReferenceListName("Company_Activity", {
        languageCode: Languages.ENGLISH,
      }).then(
        ({ data }) =>
          data.find(({ itemCode }) => itemCode === companyActivityId)?.text
      ),
    ]);

    return { user, companyActivityNameInEn };
  }
);

export const fetchUserApplications = createAsyncThunk(
  "user/fetchUserApplications",
  async () => {
    const {
      data: { userApplications },
    } = await CumdAPI.getUserApplications();
    return userApplications;
  }
);

export const fetchUserApplicationsCompanies = createAsyncThunk(
  "user/fetchUserApplicationsCompanies",
  async (companyIds: string[]) => {
    const { data } = await CumdAPI.getCompaniesByCompanyIds({ companyIds });
    return data;
  }
);
