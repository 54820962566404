import { memo, useEffect, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { TradeitemManufactureditemsHistory } from "@trace-one/api-clients.pmd";
import { Heading } from "@trace-one/design-system";

import { PmdAPI, CumdAPI } from "apis";
import { CompanyData } from "models";

import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import Table from "components/Table";
import { CompanyStatus } from "shared/constants";
import buildCompanyTitle from "shared/utils/buildCompanyTitle";

import { ProductVersionsResult } from "../models";

import useManufacturedItemsTable from "./hooks/useManufacturedItemsTable";
import styles from "./ManufacturedTable.module.less";

interface ManufacturedTableProps {
  versionDetails: ProductVersionsResult;
  previousVersionDetails?: ProductVersionsResult;
}

const ManufacturedTable: React.FC<ManufacturedTableProps> = ({
  versionDetails,
  previousVersionDetails,
}) => {
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const [manufacturedItems, setManufacturedItems] = useState([]);
  const [PreviousManufacturedItems, setPreviousManufacturedItems] = useState(
    []
  );

  async function getManufacturedItems(
    manufacturedItems: TradeitemManufactureditemsHistory[]
  ) {
    const companyIds = manufacturedItems
      .flatMap(({ supplierCompanyId }) => [supplierCompanyId])
      .filter(id => id);

    let companies: CompanyData[] = [];

    if (companyIds.length > 0) {
      companies = await CumdAPI.getCompaniesByFilters(
        { companyIds },
        { relatedOwnerCompanyId: ownerCompanyId, includePrivate: true }
      ).then(({ data }) => data.companies);
    }

    const manufacturedItemsForDetails = manufacturedItems.map(
      ({ supplierCompanyId, ...rest }) => {
        let mappedSupplierCompanyId: {
          value?: string;
          label?: string;
          companyRelationExternalIdentifier?: string;
          companyDisplayName: string;
          companyRelationExternalName?: string;
          companyStatus?: CompanyStatus;
          companyRelationStatus?: boolean;
          companyType?: string;
        };
        if (supplierCompanyId) {
          const company = companies.find(
            ({ companyId }) => companyId === supplierCompanyId
          );

          mappedSupplierCompanyId = {
            value: supplierCompanyId,
            label: buildCompanyTitle(company),
            companyRelationExternalIdentifier:
              company?.companyRelationExternalIdentifier,
            companyDisplayName: company?.companyDisplayName,
            companyRelationExternalName: company?.companyRelationExternalName,
            companyStatus: company?.companyStatus as CompanyStatus,
            companyRelationStatus: company?.isCompanyRelationStatusEnabled,
            companyType: company?.companyType,
          };
        }
        return {
          ...rest,
          supplierCompanyId: mappedSupplierCompanyId,
        };
      }
    );
    return manufacturedItemsForDetails;
  }

  const refetchManufItems = useCallback(async () => {
    let mount = true;
    const { data } = await PmdAPI.getTradeitemManufactureditemsHistory(
      versionDetails.id,
      { modifiedDate: versionDetails.modifiedAt }
    );
    const manufacturedItemsData = await getManufacturedItems(data);
    if (mount) {
      setManufacturedItems(manufacturedItemsData);
      if (previousVersionDetails) {
        const { data: previousData } =
          await PmdAPI.getTradeitemManufactureditemsHistory(
            previousVersionDetails.id,
            { modifiedDate: previousVersionDetails.modifiedAt }
          );
        const previousManufacturedItemsData = await getManufacturedItems(
          previousData
        );
        setPreviousManufacturedItems(previousManufacturedItemsData);
      }
    }
    setIsLoading(false);
    return () => {
      mount = false;
    };
  }, [versionDetails, previousVersionDetails]);

  useEffect(() => {
    setIsLoading(true);
    refetchManufItems();
  }, [refetchManufItems]);

  const { columns, data, expandable } = useManufacturedItemsTable({
    manufacturedItems,
    PreviousManufacturedItems,
    versionDetails,
    previousVersionDetails,
  });

  return (
    <div className={styles.manufacturedItemsContainer}>
      <div className={styles.sectionHeader}>
        <Heading color="grey-4" size="s">
          {formatMessage({
            id: "productDetails.manufacturedItems",
          })}
        </Heading>
        <hr />
      </div>
      <div className={styles.fieldSection}>
        <Table
          rowClassName={record => record.rowChanged && "changedRow"}
          columns={columns}
          dataSource={data}
          loading={isLoading}
          pagination={false}
          expandable={expandable}
        />
      </div>
    </div>
  );
};

export default memo(ManufacturedTable);
