import { Row } from "antd";

import TabPage from "components/TabPage";

import ManufacturedTable from "./ManufacturedTable";

interface ManufacturedItemsTabProps {
  refetchProduct: () => void;
}
const ManufacturedItemsTab: React.FC<ManufacturedItemsTabProps> = ({
  refetchProduct,
}) => {
  return (
    <TabPage.Wrapper>
      <Row gutter={[40, 40]}>
        <ManufacturedTable refetchProduct={refetchProduct} />
      </Row>
    </TabPage.Wrapper>
  );
};

export default ManufacturedItemsTab;
