/* istanbul ignore file */
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "reduxStore";
import {
  fetchSupplierCompanies,
  fetchPakerCompanies,
} from "reduxStore/productShared/asyncActions";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import getDisplayName from "shared/utils/getDisplayName";

const withSupplierAndPakerCompanies = <P extends {}>(
  WrappedComponent: React.ComponentType<P>
) => {
  const Component: React.FC<P> = props => {
    const dispatch = useAppDispatch();
    const ownerCompanyId = useSelector(selectUserOwningCompanyId);

    useEffect(() => {
      dispatch(fetchSupplierCompanies({ ownerCompanyId }));
      dispatch(fetchPakerCompanies({ ownerCompanyId }));
    }, [ownerCompanyId]);

    return <WrappedComponent {...props} />;
  };

  Component.displayName = getDisplayName(
    withSupplierAndPakerCompanies,
    WrappedComponent
  );

  return Component;
};

export default withSupplierAndPakerCompanies;
