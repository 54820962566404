/* istanbul ignore file */
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Menu } from "antd";
import { SectionsIds } from "pages/Sites/constants";

import { useAppDispatch } from "reduxStore";
import { setSelectedCertificate } from "reduxStore/siteSupplierForm/slice";

import ActionDropdown from "components/ActionDropdown";

const SiteCertificateAction = ({ record, site }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleEditCertificate = () => {
    dispatch(setSelectedCertificate(record.certificateKeyId));

    history.push({
      pathname: `/sites/edit/${site.id}`,
      hash: `${SectionsIds.CERTIFICATES}:${record.certificateKeyId}`,
    });
  };

  return (
    <ActionDropdown
      // disabled={isRetailer ? !siteRelationsWrite : !sitesWrite && !sitesRead}
      data-test-id="md-site-action"
      overlay={
        <Menu onClick={handleEditCertificate} data-test-id="md-site-edit">
          {
            <Menu.Item key="edit-site">
              {intl.formatMessage({ id: "siteDetails.editCertificate" })}
            </Menu.Item>
          }
        </Menu>
      }
    />
  );
};

export default SiteCertificateAction;
