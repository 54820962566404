import React, { useState, useMemo, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { compose } from "@reduxjs/toolkit";
import { CompanySettings } from "@trace-one/api-clients.pmd/dist/models/company-settings";
import {
  Heading,
  Tooltip,
  TagV1 as Tag,
  Button,
} from "@trace-one/design-system";
import { usePreviousLocation } from "@trace-one/react-components";
import { ProductIntlStatus } from "pages/Products/constants";
import PropTypes from "prop-types";
import { MenuItemType } from "rc-menu/lib/interface";

import { PmdAPI } from "apis";

import { useAppDispatch } from "reduxStore";
import { selectProductDetailsData } from "reduxStore/productDetails/selectors";
import {
  setProductFormData,
  clearProductFormData,
  setProductFormError,
} from "reduxStore/productDetails/slice";
import { fetchProduct } from "reduxStore/productForm/asyncActions";
import { setProductSelectedSupplier } from "reduxStore/productForm/slice";
import {
  selectTradeItemStatusesData,
  selectBrandsData,
} from "reduxStore/shared/selectors";
import {
  selectUserOwningCompanyId,
  selectUserApplications,
} from "reduxStore/user/selectors";

import CommentsSection from "components/CommentsSection";
import LeavingFormPrompt from "components/LeavingFormPrompt";
import NewFormWrapper from "components/NewFormWrapper";
import {
  TeamMemberResponsibilityParentItem,
  TradeItemStatus,
  pdmDetails as pdmIds,
} from "shared/constants";
import {
  withCountries,
  withPointOfSales,
  withCustomProductTypes,
  withManufacturingItemStatuses,
  withTeamMemberResponsibilities,
  withLanguages,
  BaseHocProps,
} from "shared/hocs";
import useAntForm from "shared/hooks/useAntForm";
import useToast from "shared/hooks/useToast";
import { FormItemFeedback } from "shared/typings";

import { ErrorCode } from "../../../../shared/errors";

import AdditionalInformation from "./components/AdditionalInformation";
import ManufacturedSection from "./components/ManufacturedSection";
import ProductInformation from "./components/ProductInformation";
import withSupplierAndPakerCompanies from "./hocs/withSupplierAndPakerCompanies";
import useSaveManufacturedItems from "./hooks/useSaveManufacturedItems";
import {
  GetProductDataForm,
  ProductFormData,
  ProductFormValues,
} from "./models";
import getFailedSubmitResult from "./utils/getFailedSubmitResult";
import prepareFormData from "./utils/prepareFormData";
import prepareInitialValues from "./utils/prepareInitialValues";

interface ProductFormProps extends BaseHocProps {
  initialValues?: GetProductDataForm;
  onCreationPage?: boolean;
  isReadOnly?: boolean;
  withDirtyForm?: boolean;
}

const enhance = compose<React.FC<ProductFormProps>>(
  withCountries(),
  withPointOfSales(),
  withCustomProductTypes(),
  withManufacturingItemStatuses(),
  withTeamMemberResponsibilities({
    includeParentItems: true,
    parentItemId: TeamMemberResponsibilityParentItem.RETAILER,
  }),
  withSupplierAndPakerCompanies,
  withLanguages()
);

export const ProductForm: React.FC<ProductFormProps> = ({
  initialValues: initialValuesProp,
  onCreationPage,
  isReadOnly,
  withDirtyForm,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const previousLocation = usePreviousLocation();

  const { data: tradeItemStatusesData } = useSelector(
    selectTradeItemStatusesData
  );
  const { productFormData, productFormError } = useSelector(
    selectProductDetailsData
  );
  const { data: brands } = useSelector(selectBrandsData);
  const { form, isFormDirty, setIsFormDirty } = useAntForm<ProductFormValues>();

  const [tradeItemId, setTradeItemId] = useState(initialValuesProp?.id);
  const isEditForm = !!tradeItemId;
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const defaultTitle = intl.formatMessage({ id: "productForm.createProduct" });
  const [title, setTitle] = useState(initialValuesProp?.tradeItemName ?? "");
  const [activeTab, setActiveTab] = useState(null);
  const [variableUnit, setVariableUnit] = useState(
    initialValuesProp?.isTradeItemVariableUnit ||
      !!initialValuesProp?.ean7Price ||
      !!initialValuesProp?.ean7Weight
  );
  const [seasonalUnit, setSeasonalUnit] = useState(
    initialValuesProp?.isTradeItemSeasonal ||
      !!initialValuesProp?.seasonalAvailabilityStartDate ||
      !!initialValuesProp?.seasonalAvailabilityEndDate
  );
  const userApplications = useSelector(selectUserApplications);
  const userAppIds = userApplications.map(app => app.applicationTypeId);
  const pdmApplication = pdmIds.find(c => userAppIds.includes(c.id));

  const [companySettings, setCompanySettings] = useState<CompanySettings>({});

  const [submitResult, setSubmitResult] = useState<{
    gtin?: FormItemFeedback;
    brandId?: FormItemFeedback;
    contacts?: any;
    name?: String;
  }>({});

  const [isVisited, setIsVisited] = useState<boolean>(false);
  const saveManufacturedItems = useSaveManufacturedItems();
  const initialValues = useMemo(() => {
    const brandData = brands.filter(
      item => item.id === initialValuesProp?.brandId
    );
    if (isEditForm && !brandData[0]?.isActive) {
      const { brandId, ...rest } = initialValuesProp;
      return prepareInitialValues({ initialValues: rest });
    }
    return prepareInitialValues({ initialValues: initialValuesProp });
  }, [initialValuesProp]);
  const copyOfInitialValues = useMemo(
    () => prepareInitialValues({ initialValues: initialValuesProp }),
    [initialValuesProp]
  );

  const [visible, setVisible] = useState<boolean>(false);
  const [isArchiving, setIsArchiving] = useState(false);

  useEffect(() => {
    if (withDirtyForm) setIsFormDirty(true);
    dispatch(setProductFormData(initialValues));
    dispatch(setProductFormError({ tradeName: false, manufacturedItem: null }));
  }, []);

  useEffect(() => {
    dispatch(setProductFormData(initialValues));
  }, [initialValues]);

  const handleFetchCompanySettings = async () => {
    try {
      const companySettings = await PmdAPI.getCompanySettings(ownerCompanyId);
      setCompanySettings(companySettings.data);
    } catch (error) {
      if (
        error.response?.data.errorCode === ErrorCode.COMPANY_SETTINGS_NOT_FOUND
      ) {
        return null;
      } else {
        toast.saveError({ error });
      }
    }
  };

  useEffect(() => {
    handleFetchCompanySettings();
  }, []);

  const handleAddProductFactory =
    ({ injectIdToManufacturedItems }) =>
    async ({ manufacturedItems, ...formData }: ProductFormData) => {
      const fieldsData = form.getFieldsValue();
      const brandId = form.getFieldValue("brandId");
      const tradeItemName = form.getFieldValue("tradeItemName");
      const productLanguage = form.getFieldValue("productLanguage");

      formData = {
        ...fieldsData,
        ...formData,
        brandId: brandId,
        tradeItemName: tradeItemName,
        productLanguage: productLanguage,
      };
      const {
        data: { tradeItemId },
      } = await PmdAPI.createTradeItem({
        ownerCompanyId,
        ...formData,
      });
      setTradeItemId(tradeItemId);
      toast.success({
        description: intl.formatMessage(
          { id: "productForm.tradeItem.toast.success" },
          { tradeItemName: formData.tradeItemName }
        ),
      });
      const isAllDataSaved: boolean = await saveManufacturedItems({
        tradeItemId,
        manufacturedItems,
        injectIdToManufacturedItems,
      });
      if (!isAllDataSaved) {
        await dispatch(fetchProduct({ id: tradeItemId, ownerCompanyId }));
      }

      return { isAllDataSaved, tradeItemId };
    };

  const handleEditProductFactory =
    ({ injectIdToManufacturedItems }) =>
    async ({ manufacturedItems, ...formData }: ProductFormData) => {
      const fieldsData = form.getFieldsValue();
      const brandId = form.getFieldValue("brandId");
      const productLanguage = form.getFieldValue("productLanguage");

      formData = {
        ...fieldsData,
        ...formData,
        brandId: brandId,
        productLanguage: productLanguage,
      };
      let isAllDataSaved: boolean;
      if (formData.tradeItemStatusId !== TradeItemStatus.INACTIVE) {
        await PmdAPI.updateTradeItem(tradeItemId, formData);
        toast.success({
          description: intl.formatMessage(
            { id: "productForm.tradeItem.toast.success" },
            { tradeItemName: formData.tradeItemName }
          ),
        });
        isAllDataSaved = await saveManufacturedItems({
          tradeItemId,
          manufacturedItems,
          injectIdToManufacturedItems,
        });
      } else {
        await PmdAPI.updateTradeItem(tradeItemId, formData);
        toast.success({
          description: intl.formatMessage(
            { id: "productForm.tradeItem.toast.success" },
            { tradeItemName: formData.tradeItemName }
          ),
        });
        isAllDataSaved = true;
      }
      await dispatch(fetchProduct({ id: tradeItemId, ownerCompanyId }));
      return { isAllDataSaved, tradeItemId };
    };
  const handleSubmit = async (values: ProductFormValues) => {
    dispatch(setProductSelectedSupplier(""));
    const injectIdToManufacturedItems = ({ manufacturedListIds }) => {
      const fields = { ...productFormData, ...form.getFieldsValue() };
      fields.manufacturedItems = productFormData.manufacturedItems?.map(
        (item, index) => ({
          id: manufacturedListIds[index],
          ...item,
        })
      );
      form.setFieldsValue(fields);
    };
    const handler = isEditForm
      ? handleEditProductFactory({ injectIdToManufacturedItems })
      : handleAddProductFactory({ injectIdToManufacturedItems });
    const formData = prepareFormData({
      values:
        productFormData.manufacturedItems?.length > 0
          ? {
              ...productFormData,
              ...form.getFieldsValue(),
            }
          : {
              ...productFormData,
              ...{ manufacturedItems: initialValues.manufacturedItems },
              ...form.getFieldsValue(),
            },
      initialValues: initialValuesProp,
    });
    try {
      const { isAllDataSaved, tradeItemId } = await handler(formData);
      if (isAllDataSaved) {
        form.resetFields();
        setIsFormDirty(false);
        setSubmitResult({});
        if (onCreationPage) {
          dispatch(clearProductFormData());
          history.replace(`/products/edit/${tradeItemId}`, {
            hasPreviousLocation: !!previousLocation,
          });
        } else {
          form.resetFields();
        }
      }
    } catch (error) {
      const result = await getFailedSubmitResult({
        error,
        values,
        ownerCompanyId,
        form,
        setActiveTab,
        setSubmitResult,
        companySettings,
      });

      if (!result)
        dispatch(setProductFormError({ ...productFormError, tradeName: true }));
      setActiveTab(0);
      setSubmitResult(prev => ({ ...prev, ...result }));
      toast.saveError({ error });
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    let dataToSave = { ...productFormData, ...form.getFieldsValue() };
    let manufacturedItem = [];
    dataToSave.manufacturedItems?.forEach(item => {
      if (item.supplierCompanyId !== undefined) manufacturedItem.push(item);
    });
    dataToSave.manufacturedItems = manufacturedItem;
    dispatch(setProductFormData(dataToSave));
    setIsFormDirty(true);
    if ("tradeItemName" in changedValues) {
      dispatch(setProductFormError({ ...productFormError, tradeName: false }));
      setTitle(changedValues.tradeItemName);
    } else if ("gtin" in changedValues) {
      setSubmitResult(({ gtin, ...rest }) => rest);
    } else if ("brandId" in changedValues) {
      setSubmitResult(({ brandId, ...rest }) => rest);
    }
    if (
      "isTradeItemVariableUnit" in changedValues ||
      "ean7Weight" in changedValues ||
      "ean7Price" in changedValues
    ) {
      if (
        !allValues.ean7Weight &&
        !allValues.ean7Price &&
        !allValues.isTradeItemVariableUnit
      ) {
        setVariableUnit(false);
      } else {
        setVariableUnit(true);
      }
    }
    if (
      "isTradeItemSeasonal" in changedValues ||
      "seasonalDate" in changedValues
    ) {
      if (
        !allValues.seasonalDate?.[0] &&
        !allValues.seasonalDate?.[1] &&
        !allValues.isTradeItemSeasonal
      ) {
        setSeasonalUnit(false);
      } else {
        setSeasonalUnit(true);
      }
    }
  };

  let formTitle: React.ReactNode;
  if (isEditForm) {
    formTitle = title || initialValuesProp?.tradeItemName;
  } else {
    formTitle = title || defaultTitle;
  }

  const TradeItemStatusTag = () => {
    const status = tradeItemStatusesData.find(
      item => item.id === initialValuesProp.tradeItemStatusId
    );

    if (!status) return null;

    if (status.id === TradeItemStatus.INACTIVE) {
      return (
        <Tag
          label={intl.formatMessage({
            id: ProductIntlStatus["INACTIVE"].tagLabel,
          })}
          color="grey"
          mode="light"
        />
      );
    }

    return null;
  };

  const tabList = [
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "productDetails.productInformation" })}{" "}
        </Heading>
      ),
      content: (
        <ProductInformation
          submitResult={submitResult}
          isEditForm={isEditForm}
          initialValues={copyOfInitialValues}
          disabled={isReadOnly}
          form={form}
          companySettings={companySettings}
        />
      ),
    },
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "productDetails.manufacturedItems" })}{" "}
        </Heading>
      ),
      content: (
        <ManufacturedSection
          disabled={isReadOnly}
          form={form}
          initialManufacturedItems={copyOfInitialValues.manufacturedItems}
          isEditForm={isVisited}
          companySettings={companySettings}
        />
      ),
    },
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "general.additionalInformation" })}{" "}
        </Heading>
      ),
      content: (
        <AdditionalInformation
          disabled={isReadOnly}
          variableUnit={variableUnit}
          seasonalUnit={seasonalUnit}
        />
      ),
    },
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "general.comments" })}{" "}
        </Heading>
      ),
      content: (
        <CommentsSection
          name="comments"
          disabled={isReadOnly}
          id="comments-section"
        />
      ),
    },
  ];

  const handleChange = (value: boolean) => {
    setActiveTab(null);
    setIsVisited(value);
  };

  const setExpandData = (errors: any) => {
    let manufactureItemError = [];
    errors.forEach(error => {
      if (error.name[0] === "manufacturedItems")
        manufactureItemError.push(error.name);
    });
    dispatch(
      setProductFormError({
        ...productFormError,
        manufacturedItem: manufactureItemError,
      })
    );
    form.scrollToField(errors[0].name, {
      behavior: "smooth",
      block: "center",
    });
  };

  const archiveProduct = async () => {
    try {
      await PmdAPI.archiveTradeItems({ idCollection: [tradeItemId] });
      toast.success({
        message: intl.formatMessage({
          id: "productDetails.archivedSuccessTitle",
        }),
        description: intl.formatMessage({
          id: "productDetails.archivedSuccessText",
        }),
      });
    } catch (error) {
      toast.error({
        description: intl.formatMessage({
          id: "productDetials.archivedFailedText",
        }),
        message: intl.formatMessage({
          id: "productDetials.archivedFailedTitle",
        }),
        checkErrorResponse: false,
      });
    }
  };

  const saveActions: MenuItemType[] = [
    {
      label: (
        <Tooltip
          onOpenChange={() => {
            setVisible(true);
          }}
          placement="left"
          text={intl.formatMessage(
            { id: "productsList.actionTooltipTitle" },
            { Name: "" }
          )}
          actions={[
            {
              text: intl.formatMessage({
                id: "general.cancel",
              }),
              onClick: (event: React.FocusEvent<HTMLElement>) => {
                event.target.blur();
                setVisible(false);
              },
            },
            {
              text: intl.formatMessage({
                id: "general.confirm",
              }),
              onClick: async (event: React.FocusEvent<HTMLElement>) => {
                setIsArchiving(true);
                event.target.blur();
                setVisible(false);
                await archiveProduct();
                setIsArchiving(false);
                history.push(`/products/${tradeItemId}`);
              },
            },
          ]}
          visible={visible}
        >
          <span style={{ display: "inline-block", width: "100%" }}>
            {intl.formatMessage({ id: "general.archive" })}
          </span>
        </Tooltip>
      ),
      onClick: () => {},
      key: "product-archive",
    },
  ];

  return (
    <NewFormWrapper
      title={formTitle}
      subtitle={
        pdmApplication && isEditForm ? (
          <Button
            data-test-id="md-open-product-link"
            iconName="link"
            className="linkToFormButton"
            iconPlacement="right"
            size="medium"
            type="link"
            onClick={() => {
              if (pdmApplication?.url) {
                window.location.href =
                  pdmApplication.url +
                  `?MasterDataCompanyGuid=${ownerCompanyId}&MasterDataEntityGuid=${tradeItemId}`;
              }
            }}
          >
            {intl.formatMessage(
              {
                id: "productDetails.pdmLinkOpen",
              },
              {
                Name: pdmApplication.name,
              }
            )}
          </Button>
        ) : undefined
      }
      status={<TradeItemStatusTag />}
      submitText={intl.formatMessage({ id: "general.save" })}
      cancelText={intl.formatMessage({ id: "general.cancel" })}
      onBackClick={() => {
        dispatch(
          setProductFormError({ tradeName: false, manufacturedItem: null })
        );
        dispatch(clearProductFormData());
        history.goBack();
      }}
      form={form}
      name="product-form"
      withBackNav
      onFinish={handleSubmit}
      initialValues={initialValues}
      onValuesChange={onValuesChange}
      activeTab={activeTab}
      onFinishFailed={({ errorFields }) => {
        const firstError = errorFields[0];
        const isManufacturedItemsError =
          firstError?.name[0] === "manufacturedItems";
        if (isManufacturedItemsError) {
          setExpandData(errorFields);
        } else {
          if (firstError.name[0] === "tradeItemName")
            dispatch(
              setProductFormError({ ...productFormError, tradeName: true })
            );
        }
        toast.saveError({ checkErrorResponse: false });
      }}
      tabs={tabList}
      disabled={isReadOnly}
      handleChange={handleChange}
      hasSaveActions={isEditForm}
      saveActions={isEditForm && saveActions}
      isArchiving={isArchiving}
    >
      <LeavingFormPrompt when={isFormDirty} />
    </NewFormWrapper>
  );
};

ProductForm.propTypes = {
  initialValues: PropTypes.object,
  onCreationPage: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default enhance(ProductForm);
