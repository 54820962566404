import React from "react";
import { useIntl } from "react-intl";

import { Button } from "@trace-one/design-system";
import { Input as AntdInput } from "antd";

import FlexWrap from "components/FlexWrap";

import styles from "./CustomInputGroup.module.less";

interface CustomInputGroupProps {
  leftInput?: React.ReactNode;
  rightInput?: React.ReactNode;
  dualInput?: React.ReactNode;
  onItemAdd: () => void;
  disabled?: boolean;
  addLabel?: string;
  "data-test-id"?: string;
}

const CustomInputGroup: React.FC<CustomInputGroupProps> = ({
  leftInput,
  rightInput,
  onItemAdd,
  disabled,
  "data-test-id": dataTestId,
  addLabel,
  children,
  dualInput,
}) => {
  const intl = useIntl();

  return (
    <div className={styles.root}>
      <div className={styles.inputWrapper}>
        {!dualInput && (
          <AntdInput.Group compact style={{ flexGrow: 1, display: "flex" }}>
            <div style={{ paddingRight: 8 }}>{leftInput}</div>
            {rightInput}
          </AntdInput.Group>
        )}
        {dualInput && <div style={{ width: "500px" }}>{dualInput} </div>}

        <Button
          style={{ marginLeft: 10 }}
          onClick={onItemAdd}
          disabled={disabled}
          type="secondary"
          data-test-id={dataTestId}
        >
          {addLabel ??
            intl.formatMessage({
              id: "general.add",
            })}
        </Button>
      </div>
      {!!children && <FlexWrap>{children}</FlexWrap>}
    </div>
  );
};

export default CustomInputGroup;
