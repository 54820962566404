import { useRef, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

import WebViewer from "@pdftron/webviewer";

import { DmsAPI } from "apis";

import Spinner from "components/Spinner";
import { TOLPDFTRON } from "shared/constants";

import styles from "./DocumentViewer.module.less";

const DocumentViewer = () => {
  const { docId } = useParams<{ docId?: string }>();
  const { search } = useLocation();
  const queryParams = Object.fromEntries(new URLSearchParams(search).entries());
  const { versionId, fileName } = queryParams ?? {};
  const viewer = useRef(null);
  const licenseExtensions = ["pdf", "jpg", "png", "jfif", "tif", "tiff"];
  const docInstance = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  var lastIndex = fileName?.lastIndexOf(".");
  const fileType =
    lastIndex &&
    fileName
      .substring(lastIndex + 1)
      .trim()
      .toLowerCase();

  const downloadFile = async () => {
    try {
      const result = await DmsAPI.downloadDocument(
        docId,
        { versionId } as any,
        {
          responseType: "blob",
        }
      );

      if (!result?.data) {
        return;
      }
      setIsLoading(false);
      return URL.createObjectURL(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const initializeViewer = async () => {
    setIsLoading(true);
    const href = await downloadFile();
    WebViewer(
      {
        path: "/webviewer",
        licenseKey: licenseExtensions.includes(fileType) && TOLPDFTRON,
        initialDoc: href,
        filename: fileName,
        disabledElements: [
          "layoutButtons",
          "pageTransitionButtons",
          "toolsButton",
          "annotationPopup",
          "linkButton",
          "toolbarGroup-Edit",
          "toolbarGroup-FillAndSign",
          "toolbarGroup-Forms",
          "signaturePanelButton",
          "pageManipulationOverlayButton",
          "thumbDelete",
          "notePopupStateCancelled",
          "notePopupStateMarked",
          "notePopupStateUnmarked",
          "toggleCompareModeButton",
          "thumbnailsControlManipulatePopupSmallTrigger",
          "pageManipulationHeader",
          "insertPage",
          "replacePage",
          "extractPage",
          "deletePage",
          "thumbnailsControlDelete",
          "thumbnailsControlExtract",
          "thumbnailsControlInsert",
          "thumbnailsControlReplace",
          "thumbnailsControlManipulatePopupTrigger",
          "pageAdditionalControlsHeader",
          "moveToTop",
          "moveToBottom",
          "leftPanelButton",
          "searchButton",
          "searchPanel",
          "searchOverlay",
          "toggleNotesButton",
          "densitometerAndColorSeparatorButton",
          "toolbarGroup-Annotate",
          "toolbarGroup-Shapes",
          "toolbarGroup-Insert",
          "selectToolButton",
          "panToolButton",
          "saveAsButton",
          "settingsButton",
          "toolsHeader",
        ],
      },
      viewer.current
    )
      .then(instance => {
        docInstance.current = instance;
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    initializeViewer();
  }, []);

  if (isLoading) return <Spinner underHeader />;

  return (
    <div>
      <div className={styles.webviewer} ref={viewer}></div>
    </div>
  );
};

export default DocumentViewer;
