import { useIntl, FormattedMessage } from "react-intl";

import { CompanySettings } from "@trace-one/api-clients.pmd/dist/models/company-settings";
import { Tooltip, Icon } from "@trace-one/design-system";
import { ColumnsType } from "antd/lib/table";

import usePermissions from "core/oidc/usePermissions";
import { BrandData } from "models";

import styles from "../../BrandTable/BrandTable.module.less";
import BrandAction from "../BrandAction";
import { BrandColumn } from "../models";

const mapBrandToColumn = ({ brand }: { brand: BrandData }): BrandColumn => {
  const { id, brandName, externalCode, isActive } = brand;
  return {
    key: id,
    brandName,
    externalCode,
    rawBrandData: brand,
    isActive,
  };
};

const ProductArchivedLabel = ({ record }) => {
  if (!record.isActive) {
    return <FormattedMessage id="general.yes" />;
  }

  if (record.isActive) {
    return <FormattedMessage id="general.no" />;
  }
};

export default function useBrandTable({
  brands,
  refetchBrands,
  companySettings,
}: {
  brands: BrandData[];
  refetchBrands: () => void;
  companySettings: CompanySettings;
}) {
  const intl = useIntl();
  const { brandsWrite } = usePermissions();
  const { isBrandTranslated } = companySettings;

  const brandAction = [
    {
      title: <Icon style={{ margin: "auto" }} name="settings" color="white" />,
      dataIndex: "actions",
      key: "actions",
      width: 20,
      ellipsis: true,
      render: (_, record) => (
        <BrandAction
          record={record}
          refetchBrands={refetchBrands}
          isBrandTranslated={isBrandTranslated}
        />
      ),
    },
  ];
  const columns: ColumnsType<BrandColumn> = [
    {
      title: intl.formatMessage({
        id: "brandList.table.brandName",
      }),
      dataIndex: "brandName",
      key: "brandName",
      width: 150,
      ellipsis: true,
      render: (_, record) => (
        <>
          <Tooltip showFullText text={record.brandName} placement="top">
            <div className={styles.brandListTooltip}>{record.brandName}</div>
          </Tooltip>
        </>
      ),
    },
    {
      title: intl.formatMessage({
        id: "brandList.table.code",
      }),
      dataIndex: "externalCode",
      key: "externalCode",
      width: 150,
      ellipsis: true,
      render: (_, record) => (
        <>
          <Tooltip showFullText text={record.externalCode} placement="top">
            <div className={styles.brandListTooltip}>{record.externalCode}</div>
          </Tooltip>
        </>
      ),
    },
    {
      title: intl.formatMessage({
        id: "general.archived",
      }),
      dataIndex: "isActive",
      key: "isActive",
      width: 100,
      ellipsis: true,
      render: (_, record) => <ProductArchivedLabel record={record} />,
    },
    ...(brandsWrite ? brandAction : []),
  ];

  const data = brands.map(brand =>
    mapBrandToColumn({
      brand,
    })
  );

  return { columns, data };
}
