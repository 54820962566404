import {
  CompanyData,
  ReferenceListItemData,
  UserData,
  CategoryListData,
  BrandData,
  CompanyFromCollectionData,
} from "models";

type SharedBuilderState<DataType, IncludeIsInitiated> =
  IncludeIsInitiated extends true
    ? {
        data: DataType;
        isInitiated: boolean;
        isLoading: boolean;
        hasError: boolean;
      }
    : {
        data: DataType;
        isLoading: boolean;
        hasError: boolean;
      };

interface CompanyDataWithLang extends CompanyFromCollectionData {
  defaultLanguage?: string;
}

interface backButton {
  fromUrl: string;
  tabSection: string;
}

type ContactUserErrorstate = {
  userContact: string;
};

type State = {
  certificatesData: SharedBuilderState<ReferenceListItemData[], true>;
  certificateTypesData: SharedBuilderState<ReferenceListItemData[], true>;
  customProductTypes: SharedBuilderState<ReferenceListItemData[], true>;
  siteFunctionsData: SharedBuilderState<ReferenceListItemData[], true>;
  relatedCompanyList: SharedBuilderState<CompanyData[], false>;
  userList: SharedBuilderState<UserData[], true>;
  countriesData: SharedBuilderState<ReferenceListItemData[], true>;
  pointOfSalesData: SharedBuilderState<ReferenceListItemData[], false>;
  companyIdentifiersData: SharedBuilderState<ReferenceListItemData[], true>;
  categoryListsData: SharedBuilderState<CategoryListData[], true>;
  brandsData: SharedBuilderState<BrandData[], true>;
  netContentMeasuresData: SharedBuilderState<ReferenceListItemData[], true>;
  tradeItemStatusesData: SharedBuilderState<ReferenceListItemData[], true>;
  companyActivityData: SharedBuilderState<ReferenceListItemData[], true>;
  manufacturingItemStatusesData: SharedBuilderState<
    ReferenceListItemData[],
    true
  >;
  teamMemberResponsibilitiesData: SharedBuilderState<
    ReferenceListItemData[],
    true
  >;
  languagesData: SharedBuilderState<ReferenceListItemData[], true>;
  owningCompany: SharedBuilderState<CompanyDataWithLang, true>;
  backButtonData: backButton;
  contactUserError: ContactUserErrorstate;
};

export const initialState: State = {
  certificatesData: {
    data: [],
    isInitiated: false,
    isLoading: false,
    hasError: false,
  },
  certificateTypesData: {
    data: [],
    isInitiated: false,
    isLoading: false,
    hasError: false,
  },
  customProductTypes: {
    data: [],
    isInitiated: false,
    isLoading: false,
    hasError: false,
  },
  siteFunctionsData: {
    data: [],
    isInitiated: false,
    isLoading: false,
    hasError: false,
  },
  relatedCompanyList: {
    data: [],
    isLoading: false,
    hasError: false,
  },
  userList: {
    data: [],
    isLoading: false,
    isInitiated: false,
    hasError: false,
  },
  countriesData: {
    data: [],
    isInitiated: false,
    isLoading: false,
    hasError: false,
  },
  pointOfSalesData: {
    data: [],
    isLoading: false,
    hasError: false,
  },
  companyIdentifiersData: {
    data: [],
    isInitiated: false,
    isLoading: false,
    hasError: false,
  },
  categoryListsData: {
    isInitiated: false,
    isLoading: false,
    hasError: false,
    data: [],
  },
  brandsData: {
    isInitiated: false,
    isLoading: false,
    hasError: false,
    data: [],
  },
  netContentMeasuresData: {
    isInitiated: false,
    isLoading: false,
    hasError: false,
    data: [],
  },
  tradeItemStatusesData: {
    isInitiated: false,
    isLoading: false,
    hasError: false,
    data: [],
  },
  manufacturingItemStatusesData: {
    isInitiated: false,
    isLoading: false,
    hasError: false,
    data: [],
  },
  teamMemberResponsibilitiesData: {
    isInitiated: false,
    isLoading: false,
    hasError: false,
    data: [],
  },
  languagesData: {
    data: [],
    isInitiated: false,
    isLoading: false,
    hasError: false,
  },
  companyActivityData: {
    data: [],
    isInitiated: false,
    isLoading: false,
    hasError: false,
  },
  owningCompany: {
    isInitiated: false,
    isLoading: false,
    hasError: false,
    data: {},
  },
  backButtonData: {
    fromUrl: null,
    tabSection: null,
  },
  contactUserError: {
    userContact: "",
  },
};
