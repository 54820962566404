import { MutableRefObject, useEffect, useState } from "react";

import { DocumentDetailAPIResponse } from "@trace-one/api-clients.dms";
import { Icon, Heading, Button, Paragraph } from "@trace-one/design-system";
import { format } from "date-fns";

import getUsersCollectionMap, {
  UserCollectionMapType,
} from "shared/utils/getUsersCollectionMap";

import { formatFileSize } from "../../../utils/utils";

import styles from "./Versions.module.less";

interface Props {
  fileInfoRef?: MutableRefObject<DocumentDetailAPIResponse>;
  downloadFile: (
    fileId: string,
    versionId: string,
    fileName: string
  ) => Promise<void>;
}

const Versions = ({ fileInfoRef, downloadFile }: Props) => {
  const fileHistory = fileInfoRef.current?.fileHistory || [];
  const fileId = fileInfoRef.current?.fileId;
  const [uploadedUsersMap, setUploadedUsersMap] = useState<
    UserCollectionMapType | {}
  >({});

  useEffect(() => {
    if (!fileHistory.length) {
      return;
    }

    const uploaders = [...new Set(fileHistory.map(x => x.uploadedBy))];

    getUsersCollectionMap(uploaders)
      .then(users => {
        setUploadedUsersMap(users);
      })
      .catch(error => {
        console.error(error);
      });
  }, [fileHistory]);

  return (
    <div>
      {[...fileHistory].reverse().map(item => {
        return (
          <div
            key={item.versionId}
            className={`${styles.fileVersionListItem} d-flex`}
          >
            <div>
              <Icon name="file" size="large" color="grey-3" />
            </div>
            <div className={styles.fileVersionInfoSection}>
              <div>
                <Heading className={styles.fileVersionTitle} size="xxs">
                  {item.fileName}
                </Heading>
              </div>
              <div className={`d-flex ${styles.fileVersionItemSubheading}`}>
                <Paragraph
                  size="xs"
                  title={uploadedUsersMap[item.uploadedBy]?.userName}
                >
                  {uploadedUsersMap[item.uploadedBy]?.userName}
                </Paragraph>
                <Paragraph size="xs">
                  {format(new Date(item.uploadedAt), "dd/MM/yyyy")}
                </Paragraph>
                <Paragraph size="xs">
                  {formatFileSize(item.fileLength)}
                </Paragraph>
              </div>
            </div>
            <div>
              <Button
                type="tertiary"
                onClick={() => {
                  downloadFile(fileId, item.versionId, item.fileName);
                }}
                iconName="download"
                color="grey"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Versions;
