import { useState, memo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Button, Heading } from "@trace-one/design-system";
import { Contact } from "@trace-one/react-components";
import { Form } from "antd";

import { UserData } from "models";

import {
  selectContactUserError,
  selectUserOptions,
} from "reduxStore/shared/selectors";

import FlexWrap from "components/FlexWrap";
import FormWrapper from "components/FormWrapper";
import Input from "components/Input";
import TextArea from "components/Input/TextArea";
import Select from "components/Select";
import { UserStatus } from "shared/constants";

import { SupplierSectionIds } from "../../../../constants";

import styles from "./contactSection.module.less";

interface ContactWrappedProps {
  value?: UserData[];
  disabled?: boolean;
  onChange?: (userData: UserData[]) => void;
}

const ContactSection = ({ disabled }: { disabled: boolean }) => {
  const intl = useIntl();

  return (
    <FormWrapper.Section
      title={
        <Heading size="m">
          {intl.formatMessage({ id: "siteForm.contactsTitle" })}
        </Heading>
      }
      id={SupplierSectionIds.CONTACTS}
    >
      <div style={{ display: "flex", gap: "0px", width: "1166px" }}>
        <Form.Item
          name="sitePhone"
          label={
            <Heading size="xxs">
              {" "}
              {intl.formatMessage({ id: "general.phone" })}
            </Heading>
          }
          shouldUpdate
          style={{ width: "100%" }}
          className="sitePhone"
        >
          <Input
            disabled={disabled}
            maxLength={340}
            data-test-id="md-site-phone"
            style={{ width: "300px" }}
          />
        </Form.Item>

        <Form.Item
          name="siteFax"
          label={
            <Heading size="xxs">
              {" "}
              {intl.formatMessage({ id: "general.fax" })}
            </Heading>
          }
          shouldUpdate
          className="siteFax"
          style={{ width: "100%" }}
        >
          <Input
            disabled={disabled}
            maxLength={256}
            data-test-id="md-site-fax"
            style={{ width: "300px" }}
          />
        </Form.Item>
      </div>

      <Form.Item
        name="siteEmail"
        label={
          <Heading size="xxs">
            {" "}
            {intl.formatMessage({ id: "general.email" })}
          </Heading>
        }
        shouldUpdate
      >
        <Input
          disabled={disabled}
          maxLength={256}
          data-test-id="md-site-email"
          style={{ width: "762px" }}
        />
      </Form.Item>
      <Form.Item
        name="siteEmergencyPhone"
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "siteForm.emergencyPhone" })}
          </Heading>
        }
        shouldUpdate
      >
        <TextArea
          disabled={disabled}
          showCount
          placeholder={intl.formatMessage({ id: "general.enterValue" })}
          autoSize={{ minRows: 3, maxRows: 6 }}
          data-test-id="md-site-emergencyPhone"
          style={{ width: "762px" }}
        />
      </Form.Item>
      <Form.Item
        name="contactUserIds"
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "siteForm.contact" })}
          </Heading>
        }
      >
        <ContactWrapped disabled={disabled} />
      </Form.Item>
    </FormWrapper.Section>
  );
};

const ContactWrapped = ({
  value: users,
  onChange,
  disabled,
}: ContactWrappedProps) => {
  const intl = useIntl();
  const [selectedUser, setSelectedUser] = useState(null);
  const usersOptions = useSelector(selectUserOptions);
  const { userContact } = useSelector(selectContactUserError);
  const values = users || [];
  // const countries = useSelector(selectCountriesOptions);

  return (
    <>
      <div style={{ display: "flex" }}>
        <Select
          showSearch
          id="md-form-user-contact"
          disabled={disabled}
          data-test-id="md-site-contact-select"
          style={{ height: 42, minWidth: 300, width: "100%" }}
          options={usersOptions
            .filter(
              ({ value, userStatus }) =>
                !values.find(u => u === value) &&
                userStatus === UserStatus.ENABLED
            )
            .sort((a, b) => (a.label > b.label ? 1 : -1))
            .map(({ value, label }) => ({ value: value, name: label }))}
          value={selectedUser}
          onSelect={userId => {
            setSelectedUser(userId);
          }}
          filterOption={(input, option) =>
            (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
        <Button
          style={{ marginLeft: 10 }}
          onClick={() => {
            onChange([...values, selectedUser]);
            setSelectedUser(null);
          }}
          disabled={disabled || !selectedUser}
          type="secondary"
          data-test-id="md-site-contact-add"
        >
          {intl.formatMessage({ id: "general.add" })}
        </Button>
      </div>
      <FlexWrap>
        {values.map(userId => {
          let hashError = userId === userContact;
          const { label, userPhotoUrl, userJobTitle, userStatus } =
            usersOptions.find(({ value }) => value === userId) || {};
          const isUserInactive = userStatus !== UserStatus.ENABLED;
          const nameLabel = isUserInactive
            ? `${label} (${intl.formatMessage({ id: "general.inactive" })})`
            : label;

          return (
            <div className={styles.activeTagWrapper}>
              <Contact.Tag
                disabled={disabled}
                key={userId}
                name={nameLabel}
                jobTitle={userJobTitle}
                photoUrl={userPhotoUrl}
                onDelete={() => {
                  onChange(users.filter(u => u !== userId));
                }}
              />
              {hashError && (
                <div className={styles.errorMessage}>
                  {intl.formatMessage({
                    id: "general.userContactStatus.error",
                  })}
                </div>
              )}
            </div>
          );
        })}
      </FlexWrap>
    </>
  );
};

export default memo(ContactSection);
