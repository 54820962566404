import { useHistory } from "react-router-dom";

import { usePreviousLocation } from "@trace-one/react-components";

export default function useShowBackButton() {
  const history = useHistory();
  const previousLocation = usePreviousLocation();

  // @ts-ignore
  const hasPreviousLocation = history.location.state?.hasPreviousLocation;
  const displayBackButton = {
    showbackButton: true,
    goPreviousPage: () => history.goBack(),
  };
  const hideBackButton = {
    showbackButton: false,
    goPreviousPage: null,
  };

  if (!!previousLocation) {
    if (hasPreviousLocation === true) {
      return displayBackButton;
    } else {
      if (hasPreviousLocation === false) return hideBackButton;
      return displayBackButton;
    }
  }

  return hideBackButton;
}
