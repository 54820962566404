import { createAsyncThunk } from "@reduxjs/toolkit";

import { RlmdAPI, CumdAPI, PmdAPI } from "apis";

import { dropdownFilterCount } from "shared/constants";

export const fetchCountries = createAsyncThunk(
  "shared/fetchCountries",
  async ({ languageCode }: { languageCode: string }) => {
    const { data: countries } =
      await RlmdAPI.getReferenceListItemsByReferenceListName("countries", {
        languageCode,
      });
    return countries;
  }
);

export const fetchPointOfSales = createAsyncThunk(
  "shared/fetchPointOfSales",
  async ({
    languageCode,
    companyId,
  }: {
    languageCode: string;
    companyId: string;
  }) => {
    const { data: pointOfSales } =
      await RlmdAPI.getReferenceListItemsByReferenceListName("Point_Of_Sales", {
        languageCode,
        companyId,
      });
    return pointOfSales;
  }
);

export const fetchUsersByCompanyId = createAsyncThunk(
  "shared/fetchUsersByCompanyId",
  async (owningCompanyId: string) => {
    const { data } = await CumdAPI.getUsersByUserIdsAndFilters(
      {},
      { owningCompanyId, isDeactivated: false }
    );

    return data;
  }
);

export const fetchSiteFunctions = createAsyncThunk(
  "shared/fetchSiteFunctions",
  async ({ languageCode }: { languageCode: string }) => {
    const { data } = await RlmdAPI.getReferenceListItemsByReferenceListName(
      "site_functions",
      { languageCode }
    );

    return data;
  }
);

export const fetchCompanyIdentifiers = createAsyncThunk(
  "shared/fetchCompanyIdentifiers",
  async ({ languageCode }: { languageCode: string }) => {
    const { data } = await RlmdAPI.getReferenceListItemsByReferenceListName(
      "company_identifiers",
      {
        languageCode,
      }
    );

    return data;
  }
);

export const fetchCertifications = createAsyncThunk(
  "shared/fetchCertifications",
  async ({ languageCode }: { languageCode: string }) => {
    const { data } = await RlmdAPI.getReferenceListItemsByReferenceListName(
      "certification",
      { languageCode }
    );

    return data;
  }
);

export const fetchCertificationTypes = createAsyncThunk(
  "shared/fetchCertificationTypes",
  async ({ languageCode }: { languageCode: string }) => {
    const { data } = await RlmdAPI.getReferenceListItemsByReferenceListName(
      "certification_type",
      {
        languageCode,
      }
    );

    return data;
  }
);

export const fetchCustomProductTypes = createAsyncThunk(
  "shared/fetchCustomProductType",
  async ({
    languageCode,
    companyId,
  }: {
    languageCode: string;
    companyId: string;
  }) => {
    const { data } = await RlmdAPI.getReferenceListItemsByReferenceListName(
      "Custom_Product_Type",
      {
        languageCode,
        companyId,
      }
    );

    return data;
  }
);

export const fetchRelatedCompanies = createAsyncThunk(
  "shared/fetchRelatedCompanies",
  async ({
    companyId,
    isCompanyRelationStatusEnabled,
    isPaginate,
  }: {
    companyId: string;
    isCompanyRelationStatusEnabled?: boolean;
    isPaginate?: boolean;
  }) => {
    const { data } = await CumdAPI.getCompaniesByFilters(
      {},

      {
        skip: isPaginate ? 0 : undefined,
        take: isPaginate ? dropdownFilterCount : undefined,
        relatedAssociatedCompanyId: companyId,
        isCompanyRelationStatusEnabled,
      }
    );

    return data;
  }
);

export const fetchCategoryLists = createAsyncThunk(
  "shared/fetchCategoryLists",
  async ({ companyId }: { companyId: string }) => {
    const { data: categoryList } = await RlmdAPI.getCategoryListsByCompanyId({
      companyId,
    });
    return categoryList;
  }
);

export const fetchBrands = createAsyncThunk(
  "shared/fetchBrands",
  async ({
    ownerCompanyId,
    languageCode,
  }: {
    ownerCompanyId: string;
    languageCode: string;
  }) => {
    const { data: brands } = await PmdAPI.getBrandsByOwnerCompanyId({
      ownerCompanyId,
      languageCode,
      includeInactive: true,
    });
    brands.sort((a, b) => a.brandName.localeCompare(b.brandName));
    return brands;
  }
);

export const fetchNetContentMeasures = createAsyncThunk(
  "shared/fetchNetContentMeasures",
  async ({
    languageCode,
    parentItemId,
    includeParentItems,
  }: {
    languageCode: string;
    parentItemId?: string;
    includeParentItems?: boolean;
  }) => {
    const { data: netContentMeasures } =
      await RlmdAPI.getReferenceListItemsByReferenceListName("NetContent", {
        languageCode,
        parentItemId,
        includeParentItems,
      });

    return netContentMeasures;
  }
);

export const fetchTradeItemStatuses = createAsyncThunk(
  "shared/fetchTradeItemStatuses",
  async ({ languageCode }: { languageCode: string }) => {
    const { data: statuses } =
      await RlmdAPI.getReferenceListItemsByReferenceListName(
        "Trade_Item_Status",
        {
          languageCode,
        }
      );
    return statuses;
  }
);

export const fetchManufacturingItemStatuses = createAsyncThunk(
  "shared/fetchManufacturingItemStatuses",
  async ({
    languageCode,
    parentItemId,
    includeParentItems,
  }: {
    languageCode: string;
    parentItemId?: string;
    includeParentItems?: boolean;
  }) => {
    const { data: statuses } =
      await RlmdAPI.getReferenceListItemsByReferenceListName(
        "Manufacturing_Item_Status",
        {
          languageCode,
          parentItemId,
          includeParentItems,
        }
      );
    return statuses;
  }
);

export const fetchTeamMemberResponsibilities = createAsyncThunk(
  "shared/fetchTeamMemberResponsibilities",
  async ({
    languageCode,
    parentItemId,
    includeParentItems,
    companyId,
  }: {
    languageCode: string;
    parentItemId?: string;
    includeParentItems?: boolean;
    companyId?: string;
  }) => {
    const { data: statuses } =
      await RlmdAPI.getReferenceListItemsByReferenceListName(
        "Team_Member_Responsibility",
        {
          includeParentItems,
          parentItemId,
          languageCode,
          companyId,
        }
      );
    return statuses;
  }
);

export const fetchLanguages = createAsyncThunk(
  "shared/fetchLanguages",
  async ({ languageCode }: { languageCode: string }) => {
    const { data: languages } =
      await RlmdAPI.getReferenceListItemsByReferenceListName("languages", {
        languageCode,
      });
    return languages;
  }
);

export const fetchCompanyActivity = createAsyncThunk(
  "shared/fetchCompanyActivity",
  async ({ languageCode }: { languageCode: string }) => {
    const { data: companyActivityData } =
      await RlmdAPI.getReferenceListItemsByReferenceListName(
        "Company_Activity",
        {
          languageCode,
        }
      );
    return companyActivityData;
  }
);

export const fetchOwningCompany = createAsyncThunk(
  "shared/fetchOwningCompany",
  async ({ ownerCompanyId }: { ownerCompanyId: string }) => {
    const { data: company } = await CumdAPI.getCompanyById(ownerCompanyId);
    return company;
  }
);
