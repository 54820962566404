import { createSlice } from "@reduxjs/toolkit";

import { fetchSupplierList } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "supplierList",
  initialState,
  reducers: {
    clearSuppliers: state => {
      state.suppliers = initialState.suppliers;
      state.isSuppliersLoading = initialState.isSuppliersLoading;
      state.hasSuppliersError = initialState.hasSuppliersError;
      state.suppliersSkipAndTakeQueryStats =
        initialState.suppliersSkipAndTakeQueryStats;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSupplierList.pending, (state, action) => {
        state.isSuppliersLoading = true;
        state.hasSuppliersError = false;
      })
      .addCase(fetchSupplierList.fulfilled, (state, action) => {
        state.suppliers = action.payload.companies;
        state.suppliersSkipAndTakeQueryStats =
          action.payload.skipAndTakeQueryStats;
        state.isSuppliersLoading = false;
      })
      .addCase(fetchSupplierList.rejected, (state, action) => {
        state.suppliers = [];
        state.isSuppliersLoading = false;
        state.hasSuppliersError = true;
        state.suppliersSkipAndTakeQueryStats =
          initialState.suppliersSkipAndTakeQueryStats;
      });
  },
});

export const { clearSuppliers } = slice.actions;
export default slice.reducer;
