import { createSlice } from "@reduxjs/toolkit";

import { fetchClassificationList } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "classificationList",
  initialState,
  reducers: {
    clearClassifications: (state, action) => {
      state.classifications = [];
      state.hasClassificationError = false;
      state.isClassificationLoading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchClassificationList.pending, (state, action) => {
        state.isClassificationLoading = true;
        state.hasClassificationError = false;
      })
      .addCase(fetchClassificationList.fulfilled, (state, action) => {
        state.classifications = action.payload;
        state.isClassificationLoading = false;
      })
      .addCase(fetchClassificationList.rejected, (state, action) => {
        state.classifications = [];
        state.isClassificationLoading = false;
        state.hasClassificationError = true;
      });
  },
});

export const { clearClassifications } = slice.actions;
export default slice.reducer;
