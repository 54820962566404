import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Heading, Button } from "@trace-one/design-system";
import BrandTranslationTable from "pages/Brands/pages/BrandList/BrandTranslationTable";

import { PmdAPI } from "apis";
import { BrandData } from "models";

import FormModal from "components/FormModal";
import useToast from "shared/hooks/useToast";

import styles from "../BrandTable/BrandTable.module.less";
import { Translation } from "../BrandTranslationTable/models";

interface BrandFormProps {
  initialValues?: BrandData;
  onCancel?: () => void;
}

interface BrandTranslateModalProps extends BrandFormProps {
  visible: boolean;
  refetchBrands?: () => void;
}

const BrandTranslateModal: React.FC<BrandTranslateModalProps> = ({
  refetchBrands,
  visible,
  onCancel,
  initialValues,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const [value, setValue] = useState<Translation[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const intl = useIntl();

  const updateValues = (values: Translation[]) => {
    setIsDisabled(!values.every(v => !!v.text));
    setValue(values);
  };

  useEffect(() => {
    setValue(
      initialValues.brandNameVariants?.map(v => ({
        languageItemCode: v.languageCode,
        text: v.name,
      })) || []
    );
  }, [initialValues]);

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      const data = {
        brandName: initialValues.brandName,
        brandNameVariants: value.map(v => ({
          name: v.text,
          languageCode: v.languageItemCode,
        })),
        externalCode: initialValues.externalCode,
      };

      await PmdAPI.updateBrandName(initialValues.id, data);
      refetchBrands();
      onCancel();
      setIsSubmitting(false);
    } catch (error) {
      toast.saveError({ error });
      setIsSubmitting(false);
    }
  };

  return (
    <FormModal
      title={<Heading size="m">{initialValues.brandName}</Heading>}
      visible={visible}
      onCancel={onCancel}
      closable={!isSubmitting}
    >
      <BrandTranslationTable
        value={value}
        onChange={updateValues}
        brandName={initialValues.brandName}
      />
      <div className={styles.ModalFooterWrapper}>
        <div>
          <Button
            type="secondary"
            data-test-id="md-brand-translate-cancel"
            onClick={onCancel}
          >
            {intl.formatMessage({ id: "general.cancel" })}
          </Button>
        </div>
        <div>
          <Button
            disabled={isSubmitting || isDisabled}
            type="primary"
            className={styles.saveTranslationsBtn}
            onClick={onSubmit}
            data-test-id="md-brand-translate-submit"
          >
            {intl.formatMessage({ id: "general.save" })}
          </Button>
        </div>
      </div>
    </FormModal>
  );
};

export default BrandTranslateModal;
