import React, { useState } from "react";
import { useIntl } from "react-intl";

import { Input, TagV1 as Tag, Tooltip } from "@trace-one/design-system";

import { ReferenceListItemData, TradeItemData } from "models";

import CustomInputGroup from "components/CustomInputGroup";
import { ItemFromArray } from "shared/typings";

import styles from "./NetContentsInput.module.less";

type NetContent = ItemFromArray<TradeItemData["netContents"]>;

interface NetContentsInputProps {
  value: TradeItemData["netContents"];
  onChange: (value: TradeItemData["netContents"]) => void;
  disabled: boolean;
  netContentMeasures: ReferenceListItemData[];
}

const NetContentsInput: React.FC<NetContentsInputProps> = ({
  value: netContents,
  onChange,
  disabled,
  netContentMeasures,
}) => {
  const unitOfMeasurement = netContentMeasures
    .filter(
      item =>
        netContents.findIndex(
          ({ unitOfMeasure }) => unitOfMeasure === item.id
        ) === -1
    )
    .map(({ id, text }) => ({
      value: id,
      name: text,
    }));

  const newFilterUints = unitOfMeasurement.map(item => ({
    label: item.name,
    onClick: () =>
      setNetContent(prev => ({
        ...prev,
        unitOfMeasure: item.name,
      })),
  }));
  const [netContent, setNetContent] = useState<NetContent>({
    value: undefined,
    unitOfMeasure: unitOfMeasurement[0].name,
  });
  const intl = useIntl();
  const getFilterLabel = ({ value, unitOfMeasure }) => {
    const text = netContentMeasures?.find(
      ({ id }) => id === unitOfMeasure
    )?.text;
    return `${value} ${text}`;
  };

  const isMoreOrEqual10 = netContents?.length >= 10;

  const dualInputProps = {
    placeholder: intl.formatMessage({ id: "general.enterValue" }),
    buttonValue: netContent.unitOfMeasure,
    items: newFilterUints,
    maxlength: 20,
    onChange: event => {
      const value = event.target.value;
      setNetContent(prev => ({
        ...prev,
        value,
      }));
    },
    value: netContent.value,
  };

  return (
    <CustomInputGroup
      data-test-id="md-product-netContent-add"
      onItemAdd={() => {
        const id = netContentMeasures.find(
          ({ text }) => text === netContent.unitOfMeasure
        ).id;
        const modifiedNetContent = {
          unitOfMeasure: id,
          value: netContent.value,
        };
        onChange([...netContents, modifiedNetContent]);
        setNetContent({
          value: undefined,
          unitOfMeasure: unitOfMeasurement.filter(
            um => um.name !== netContent.unitOfMeasure
          )[0].name,
        });
      }}
      disabled={
        disabled ||
        !netContent?.value?.trim() ||
        !netContent.unitOfMeasure ||
        isMoreOrEqual10
      }
      dualInput={
        <Input.Dual
          data-test-id="md-product-netContent-value"
          className={styles.netContentsInput}
          disabled={disabled || isMoreOrEqual10}
          {...dualInputProps}
        />
      }
    >
      {netContents.map(({ value, unitOfMeasure }, index) => {
        const label = getFilterLabel({ value, unitOfMeasure });
        return (
          <Tooltip showFullText text={label} placement="top">
            <Tag
              key={`${unitOfMeasure}-${index}`}
              label={<span>{label}</span>}
              data-test-id={`tag-${label}`}
              onClose={() => {
                onChange(
                  netContents.filter(
                    item =>
                      !(
                        item.unitOfMeasure === unitOfMeasure &&
                        item.value === value
                      )
                  )
                );
              }}
            />
          </Tooltip>
        );
      })}
    </CustomInputGroup>
  );
};

export default NetContentsInput;
