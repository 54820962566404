import { memo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading, Tooltip, TagV1 as Tag } from "@trace-one/design-system";
import { Form } from "antd";

import { selectRelatedCompanyOptions } from "reduxStore/shared/selectors";
import { selectSiteSupplierFormData } from "reduxStore/siteSupplierDetails/selectors";
import { selectSiteRelations } from "reduxStore/siteSupplierForm/selectors";

import FlexWrap from "components/FlexWrap";
import FormWrapper from "components/FormWrapper";
import Select from "components/Select";
import { CompanyStatus } from "shared/constants";

import { SectionsIds } from "../../../../constants";
import styles from "../SiteSupplierForm.module.less";

interface RelationsProp {
  ownerCompanyRelationStatus?: string;
  associatedCompanyId?: string;
}

interface VisibilitySectionProps {
  disabled?: boolean;
}
interface VisibleBySelectProps {
  value?: {
    relationsToAdd: RelationsProp[];
    relationsToRemove: RelationsProp[];
  };
  onChange?: (values: {
    relationsToAdd: RelationsProp[];
    relationsToRemove?: RelationsProp[];
  }) => void;
  disabled?: boolean;
}

const VisibilitySection: React.FC<VisibilitySectionProps> = ({ disabled }) => {
  const intl = useIntl();

  return (
    <FormWrapper.Section
      title={
        <Heading size="m">
          {intl.formatMessage({ id: "siteDetails.siteUsage" })}{" "}
        </Heading>
      }
      className={styles.root}
      id={SectionsIds.VISIBILITY}
    >
      <Form.Item
        name="siteRelations"
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "siteForm.visibleBy" })}{" "}
          </Heading>
        }
      >
        <VisibleBySelect disabled={disabled} />
      </Form.Item>
    </FormWrapper.Section>
  );
};

const VisibleBySelect: React.FC<VisibleBySelectProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const intl = useIntl();
  const { relationsToAdd, relationsToRemove } = value;
  const options = useSelector(selectRelatedCompanyOptions);
  const siteRelations = useSelector(selectSiteRelations);
  const siteSupplierFormData = useSelector(selectSiteSupplierFormData);
  const [visible, setVisible] = useState(false);

  const selectedFilteredList = [
    ...relationsToAdd,
    ...siteRelations.filter(
      s =>
        relationsToRemove.findIndex(
          r => r.associatedCompanyId === s.associatedCompanyId
        ) === -1 && s.ownerCompanyRelationStatus === true
    ),
  ];

  const filteredOptions = options
    .filter(
      option =>
        selectedFilteredList.findIndex(
          s =>
            s.associatedCompanyId === option.value ||
            s.ownerCompanyRelationStatus === false
        ) === -1 && option.companyStatus === CompanyStatus.ENABLED
    )
    .map(({ name, value }) => ({ name, value }));

  return (
    <>
      {/* "siteSupplierForm.tooltipTitle.visibility" */}
      <Tooltip
        showFullText
        text={intl.formatMessage({
          id: "siteSupplierForm.tooltipTitle.visibility",
        })}
        placement="top"
        onOpenChange={() => setVisible(!visible)}
        visible={!siteSupplierFormData?.siteActive && visible}
      >
        <Select
          disabled={disabled || !siteSupplierFormData?.siteActive}
          data-test-id="md-site-visibleBy-select"
          style={{ height: 42, width: "100%", marginBottom: 10 }}
          options={filteredOptions}
          value={null}
          onChange={associatedCompanyId => {
            onChange({
              ...value,
              relationsToAdd: [...relationsToAdd, { associatedCompanyId }],
            });
          }}
        />
      </Tooltip>
      <FlexWrap>
        {selectedFilteredList.map(({ associatedCompanyId }) => {
          const { name, companyStatus } =
            options.find(o => o.value === associatedCompanyId) || {};

          const isCompanyInactive = companyStatus !== CompanyStatus.ENABLED;
          const tagLabel = isCompanyInactive
            ? `${name} (${intl.formatMessage({ id: "general.inactive" })})`
            : name;
          return (
            <Tooltip showFullText text={tagLabel} placement="top">
              <Tag
                key={associatedCompanyId}
                label={<span>{tagLabel}</span>}
                data-test-id={`tag-${tagLabel}`}
                onClose={() => {
                  onChange({
                    ...value,
                    relationsToAdd: relationsToAdd.filter(
                      o => o.associatedCompanyId !== associatedCompanyId
                    ),
                    relationsToRemove: [
                      ...relationsToRemove,
                      { associatedCompanyId },
                    ],
                  });
                }}
              />
            </Tooltip>
          );
        })}
      </FlexWrap>
    </>
  );
};

export default memo(VisibilitySection);
