import React from "react";
import { Resizable } from "react-resizable";

export interface ResizableTitleProps {
  onResize: (...args: unknown[]) => void;
  width: number;
}

const ResizableTitle: React.FC<ResizableTitleProps> = ({
  onResize,
  width,
  ...rest
}) => {
  if (!width) {
    return <th {...rest} />;
  }

  return (
    <Resizable
      minConstraints={[100]}
      maxConstraints={[900]}
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...rest} />
    </Resizable>
  );
};

ResizableTitle.propTypes = {};

export default ResizableTitle;
