import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";

import { RlmdAPI } from "apis";

import getContacts from "shared/utils/getContacts";

export const fetchCategoryItemDetails = createAsyncThunk(
  "categoryItemDetails/fetchCategoryItemDetails",
  async ({ selectedKeys }: { selectedKeys: string }, thunkAPI) => {
    try {
      const { data } = await RlmdAPI.getCategoryItemDetailById(selectedKeys);
      const contact = await Promise.all([getContacts(data.contacts)]);
      const newData = {
        categoryId: data.categoryId,
        categoryItemId: data.categoryItemId,
        contacts: contact[0],
        categoryItemName: data.categoryItemName,
        externalCode: data.externalCode,
        translations: data.translations,
        parent4CategoryItemId: data.parent4CategoryItemId,
        isActive: data.isActive,
        modifiedAt: moment(data.modifiedAt ?? data.createdAt ?? "").format(
          "DD/MM/YYYY HH:mm"
        ),
      };
      return newData;
    } catch (err) {
      const status = err?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({ status });
    }
  }
);
