import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Heading, Paragraph } from "@trace-one/design-system";
import { SectionsIds } from "pages/Sites/constants";

import usePermissions from "core/oidc/usePermissions";

import { selectFunctionOptions } from "reduxStore/shared/selectors";
import {
  selectRetailerRelation,
  selectRetailerSite,
} from "reduxStore/siteRetailerDetails/selectors";

import Label from "components/Label";
import TabPage from "components/TabPage";
import { capitalizeText } from "shared/utils/capitalizeFullText";

import styles from "../../../../SiteRetailerDetails.module.less";

const CategoryLine = ({ category }) => {
  return (
    <div>
      {category.parent4CategoryItemName &&
        `${category.parent4CategoryItemName} > `}
      {category.parent3CategoryItemName &&
        `${category.parent3CategoryItemName} > `}
      {category.parent2CategoryItemName &&
        `${category.parent2CategoryItemName} > `}
      {category.parent1CategoryItemName &&
        `${category.parent1CategoryItemName} > `}
      {category.categoryItemName}
    </div>
  );
};

const SiteUsageBloc = () => {
  const intl = useIntl();
  const history = useHistory();
  const site = useSelector(selectRetailerSite);
  const relation = useSelector(selectRetailerRelation);
  const functions = useSelector(selectFunctionOptions);
  const { siteRelationsWrite } = usePermissions();

  if (!site) return null;

  return (
    <>
      <TabPage.SecondaryTitle
        label={
          <Heading size="xs">
            {capitalizeText(
              intl.formatMessage({ id: "siteDetails.siteUsage" })
            )}
          </Heading>
        }
      />
      {siteRelationsWrite && (
        <Button
          type="link"
          className={styles.iconBtn}
          data-test-id="privateInfo-button"
          iconName="edit"
          iconPlacement="left"
          onClick={() => {
            history.push({
              pathname: `/sites/edit/${site.id}`,
              hash: SectionsIds.PRIVATE,
            });
          }}
        >
          {intl.formatMessage({ id: "general.edit" })}
        </Button>
      )}
      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "siteDetails.productCategories" })}{" "}
          </Heading>
        }
        testId="siteUsage-categories"
      >
        <Paragraph size="m">
          {relation.categoriesList.map(category => (
            <CategoryLine category={category} key={category.categoryItemId} />
          ))}
        </Paragraph>
      </Label>

      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "general.activities" })}{" "}
          </Heading>
        }
        testId="siteUsage-activities"
      >
        <Paragraph size="m">
          {relation.functionIds.map((functionId, index) => (
            <div key={index}>
              {functions.find(i => functionId === i.value)?.name}
            </div>
          ))}
        </Paragraph>
      </Label>
    </>
  );
};

export default SiteUsageBloc;
