import {
  CompanyData,
  FileTypesData,
  SiteData,
  TradeItemData,
  ProjectData,
  DocumentsListDataItem,
} from "models";

type State = {
  documents: DocumentsListDataItem[];
  documentListSkipAndTakeQueryStats: {
    currentCount?: number;
    totalCount?: number;
  };
  isDocumentListLoading: boolean;
  relatedCompanies: {
    data: CompanyData[];
    isInitiated: boolean;
    hasError: boolean;
  };
  fileTypes: {
    data: FileTypesData[];
    isInitiated: boolean;
    hasError: boolean;
  };
  sites: SiteData[];
  products: TradeItemData[];
  projects: ProjectData[];
  companies: CompanyData[];
  hasDocumentListError: boolean;
};

export const initialState: State = {
  documents: [],
  companies: [],
  documentListSkipAndTakeQueryStats: {
    currentCount: undefined,
    totalCount: undefined,
  },
  isDocumentListLoading: false,
  relatedCompanies: {
    data: [],
    isInitiated: false,
    hasError: false,
  },
  fileTypes: {
    data: [],
    isInitiated: false,
    hasError: false,
  },
  sites: [],
  products: [],
  projects: [],
  hasDocumentListError: false,
};
