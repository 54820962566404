import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { Heading, Container, Toggle } from "@trace-one/design-system";
import { Row, Col, ColProps } from "antd";
import { RcFile } from "antd/lib/upload";
import cn from "classnames";

import { RlmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import { fetchClassificationList } from "reduxStore/classificationList/asyncActions";
import {
  selectClassifications,
  selectIsClassificationsLoading,
  selectHasClassificationsError,
} from "reduxStore/classificationList/selectors";
import { clearClassifications } from "reduxStore/classificationList/slice";
import {
  selectUserOwningCompanyId,
  selectUserOwningCompanyName,
  selectUserApplications,
} from "reduxStore/user/selectors";

import BtnCreateListItem from "components/BtnCreateListItem";
import CsvImport from "components/CsvImport";
import ErrorFallback from "components/ErrorFallback";
// import Icon from "components/Icon";
import Spinner from "components/Spinner";
import { ApplicationTypes } from "shared/constants";
import useToast from "shared/hooks/useToast";
import encodeFileAsBase64 from "shared/utils/encodeFileAsBase64";

import ClassificationCard from "./ClassificationCard";
import styles from "./ClassificationList.module.less";
import CreateClassificationModal from "./CreateClassificationModal";

const defaultColProps: Partial<ColProps> = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 8,
};

const ClassificationList = () => {
  const intl = useIntl();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const { categoryListsRead, categoryListsImport, categoryListsWrite } =
    usePermissions();
  const queryInactive = new URLSearchParams(search).get("Inactive");
  const [toggleActive, setToggleActive] = useState<boolean>(!queryInactive);
  const companyId = useSelector(selectUserOwningCompanyId);
  const ownerCompanyName = useSelector(selectUserOwningCompanyName);

  const [isCreateClassificationOpen, setIsCreateClassificationOpen] =
    useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const userApplications = useSelector(selectUserApplications);
  const classifications = useSelector(selectClassifications);
  const isClassificationsLoading = useSelector(selectIsClassificationsLoading);
  const hasClassificationsError = useSelector(selectHasClassificationsError);
  const pdmIds = [
    ApplicationTypes.PDM,
    ApplicationTypes.PDMFIND,
    ApplicationTypes.PDMNA,
  ];
  const hasPdm = userApplications?.some(app =>
    pdmIds.includes(app.applicationTypeId)
  );

  useEffect(() => {
    if (categoryListsRead) {
      refetchClassifications();
    }
  }, [companyId, categoryListsRead, toggleActive]);

  const refetchClassifications = () => {
    dispatch(
      fetchClassificationList({ companyId, includeInactive: !toggleActive })
    );
  };
  useEffect(() => {
    return () => {
      dispatch(clearClassifications(null));
    };
  }, []);

  const handleImportCsv = async ({ file }: { file: RcFile }) => {
    const fileContentBase64Encoded = await encodeFileAsBase64(file);
    await RlmdAPI.importCategories({
      fileName: file.name,
      fileContentBase64Encoded,
    });
    toast.info({
      description: intl.formatMessage({
        id: "import.csv.informationSuccess",
      }),
    });
    return true;
  };

  const filterClassifications = switchStatus => {
    setToggleActive(switchStatus);
    history.replace({
      pathname: "list",
      search: !switchStatus && `?Inactive=true`,
    });
  };
  return (
    <>
      <Container isPageWrapper>
        <div className={styles.headActions}>
          <div className={styles.mainContainer}>
            <Heading size="l">
              {intl.formatMessage({ id: "general.classifications" })}
            </Heading>
            <BtnCreateListItem
              text={intl.formatMessage({
                id: "classification.createNewClassification",
              })}
              onClick={() => setIsCreateClassificationOpen(true)}
              iconName="classification"
              hideButton={
                !categoryListsWrite ||
                window.env.SHOW_CLASSIFICATION_DETAIL !== "true" ||
                isClassificationsLoading ||
                (hasPdm &&
                  !!classifications.filter(
                    c => c.companyId !== "00000000-0000-0000-0000-000000000000"
                  ).length)
              }
            />
          </div>
          <div>
            {categoryListsImport && !hasPdm && (
              <CsvImport fileSizeLessThanMB={3} onImport={handleImportCsv} />
            )}
          </div>
        </div>
        {
          <div className={cn(styles.headActions, styles.filterActions)}>
            <Toggle
              data-test-id="classifications-toggle"
              defaultChecked={toggleActive}
              text={intl.formatMessage({ id: "general.displayActiveOnly" })}
              size="small"
              onChange={switchStatus => filterClassifications(switchStatus)}
            />
          </div>
        }

        {categoryListsRead &&
          (isClassificationsLoading ? (
            <Spinner />
          ) : (
            <Row gutter={[24, 24]}>
              <ErrorFallback hasError={hasClassificationsError}>
                {classifications.map(item => (
                  <Col
                    title={item.categoryListName}
                    key={item.categoryListId}
                    {...defaultColProps}
                  >
                    <ClassificationCard
                      item={item}
                      isGlobal={
                        item.companyId ===
                        "00000000-0000-0000-0000-000000000000"
                      }
                      ownerCompanyName={ownerCompanyName}
                      refetchClassifications={refetchClassifications}
                      hasPdm={hasPdm}
                    />
                  </Col>
                ))}
              </ErrorFallback>
            </Row>
          ))}
      </Container>

      <CreateClassificationModal
        visible={isCreateClassificationOpen}
        onCancel={() => setIsCreateClassificationOpen(false)}
        initialValues={{}}
        refetchClassifications={refetchClassifications}
        isEditing={isEditing}
        setIsEditing={() => setIsEditing(false)}
      />
    </>
  );
};

export default ClassificationList;
