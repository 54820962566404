import { memo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { Paragraph } from "@trace-one/design-system";
import { Switch } from "@trace-one/react-components";
import { Col } from "antd";
import { PageCol } from "pages/Sites/constants";

import { selectProductDetailsData } from "reduxStore/productDetails/selectors";

import Table from "components/Table";
import { ManufacturingItemStatus, TradeItemStatus } from "shared/constants";

import useManufacturedItemsTable from "./hooks/useManufacturedItemsTable";
import styles from "./ManufacturedTable.module.less";

interface ManufacturedTableProps {
  refetchProduct: () => void;
}

const ManufacturedTable: React.FC<ManufacturedTableProps> = ({
  refetchProduct,
}) => {
  const { productData } = useSelector(selectProductDetailsData);
  const isArchived = productData.tradeItemStatusId === TradeItemStatus.INACTIVE;

  const [manufacturedItems, setManufacturedItems] = useState(
    productData.manufacturedItems?.filter(m =>
      isArchived
        ? m.manufacturedItemStatus === ManufacturingItemStatus.INACTIVE
        : m.manufacturedItemStatus !== ManufacturingItemStatus.INACTIVE
    )
  );

  const { columns, data, expandable } = useManufacturedItemsTable({
    manufacturedItems,
    refetchProduct,
  });

  const filterManufacturedItems = switchStatus => {
    if (switchStatus) {
      setManufacturedItems(
        productData.manufacturedItems?.filter(
          m => m.manufacturedItemStatus !== ManufacturingItemStatus.INACTIVE
        )
      );
      return;
    }
    setManufacturedItems(productData.manufacturedItems);
  };

  return (
    <>
      <Col {...PageCol.FULL}>
        <div className={styles.switchWrapper}>
          <Switch
            data-test-id="only-archived-switch"
            defaultChecked={isArchived ? false : true}
            size="small"
            onChange={switchStatus => filterManufacturedItems(switchStatus)}
          >
            <Paragraph size="m">
              <FormattedMessage id="productDetails.displayNonArchivedOnly" />
            </Paragraph>
          </Switch>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={false}
          pagination={false}
          expandable={expandable}
        />
      </Col>
    </>
  );
};

export default memo(ManufacturedTable);
