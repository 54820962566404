import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Button } from "@trace-one/design-system";
import { Icon, Popover } from "@trace-one/react-components";
import { Menu, Table } from "antd";
import cn from "classnames";

import { selectLanguagesData } from "reduxStore/shared/selectors";

import styles from "./BrandTranslationTable.module.less";
import FlagIcon from "./FlagIcon";
import useBrandTranslationTable from "./hooks/useBrandTranslationTable";
import { Translation } from "./models";

interface BrandTranslationTableProps {
  value?: Translation[];
  brandName?: string;
  onChange?: (value: Translation[]) => void;
}

const BrandTranslationTable: React.FC<BrandTranslationTableProps> = ({
  value,
  brandName,
  onChange,
}) => {
  const { formatMessage } = useIntl();
  const languagesData = useSelector(selectLanguagesData)?.data;
  const languagesMap = languagesData?.reduce<{ [itemCode: string]: string }>(
    (prev, current) => ({
      ...prev,
      [current.itemCode]: current.text,
    }),
    {}
  );

  const [open, setOpen] = useState(false);
  const handleVisibleChange = (visible: boolean) => {
    setOpen(visible);
  };

  const handleTranslationChange =
    (languageItemCode: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(
        value.map(translation => {
          if (translation.languageItemCode === languageItemCode) {
            const text = event.target.value;
            return { languageItemCode, text };
          } else {
            return translation;
          }
        })
      );
    };

  const handleAddNewItem = (languageItemCode: string) => () => {
    const newTranslation: Translation = {
      languageItemCode,
      text: brandName,
    };
    onChange([...value, newTranslation]);
    setOpen(false);
  };

  const handleRemoveItem = (languageItemCode: string) => () => {
    onChange(
      value.filter(
        translation => translation.languageItemCode !== languageItemCode
      )
    );
  };

  const { columns, data } = useBrandTranslationTable({
    value,
    languagesMap,
    handleTranslationChange,
    handleRemoveItem,
  });

  const renderFooter = () => {
    const filteredLanguages = languagesData.filter(
      ({ itemCode }) => !value.find(item => item.languageItemCode === itemCode)
    );

    if (filteredLanguages.length < 1) {
      return null;
    }

    return (
      <Popover
        overlayClassName={styles.popover}
        content={
          <Menu>
            {filteredLanguages.map(language => (
              <Menu.Item
                onClick={handleAddNewItem(language.itemCode)}
                key={language.itemCode}
                className={styles.menuItem}
                icon={<FlagIcon languageCode={language.itemCode} />}
                data-test-id={`md-select-language-${language.itemCode}`}
              >
                {language.text}
              </Menu.Item>
            ))}
          </Menu>
        }
        visible={open}
        onVisibleChange={handleVisibleChange}
        trigger="click"
      >
        <div className={styles.footer} data-test-id="md-add-new-language">
          <Button type="link" size="s">
            {formatMessage({ id: "classification.category.addNew" })}
          </Button>
          <Icon name="arrow-right" />
        </div>
      </Popover>
    );
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      footer={renderFooter}
      className={cn(styles.root, { [styles.hideBody]: data.length < 1 })}
      pagination={false}
    />
  );
};

export default BrandTranslationTable;
