import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Tooltip } from "@trace-one/design-system";
import { Menu } from "antd";

import usePermissions from "core/oidc/usePermissions";

import {
  selectUserApplications,
  selectUserOwningCompanyId,
} from "reduxStore/user/selectors";

import ActionDropdown from "components/ActionDropdown";
import { pdmDetails as pdmIds } from "shared/constants";

interface ManufacturedActionsProps {
  hideRemove: boolean;
  hideToggleManufacturedStatus: boolean;
  isManufacturedItemInactive: boolean;
  manufacturedItemId?: string;
  onManufacturedRemove: () => void;
  onToggleManufacturedStatus: () => void;
}

const ManufacturedAction: React.FC<ManufacturedActionsProps> = ({
  hideRemove,
  hideToggleManufacturedStatus,
  isManufacturedItemInactive,
  onManufacturedRemove,
  onToggleManufacturedStatus,
  manufacturedItemId,
}) => {
  const intl = useIntl();
  const [visible, setVisible] = useState<boolean>(false);
  const { productsEnable } = usePermissions();
  const userApplications = useSelector(selectUserApplications);
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const userAppIds = userApplications.map(app => app.applicationTypeId);
  const pdmApplication = pdmIds.find(c => userAppIds.includes(c.id));

  const menuItems = [
    ...(!hideToggleManufacturedStatus
      ? [
          {
            key: "archiveAction",
            spinning: false,
            disabled: isManufacturedItemInactive && !productsEnable,
            label: isManufacturedItemInactive ? (
              <Tooltip
                onOpenChange={() => setVisible(true)}
                placement="top"
                className="withNoArrow"
                trigger="click"
                text={intl.formatMessage(
                  {
                    id: "productForm.manufactured.card.unarchiveTooltipTitle",
                  },
                  { Name: "" }
                )}
                actions={[
                  {
                    text: intl.formatMessage({ id: "general.cancel" }),
                    onClick: (event: React.FocusEvent<HTMLElement>) => {
                      event.target.blur();
                      setVisible(false);
                    },
                  },
                  {
                    text: intl.formatMessage({ id: "general.confirm" }),
                    onClick: (event: React.FocusEvent<HTMLElement>) => {
                      onToggleManufacturedStatus();
                      event.target.blur();
                      setVisible(false);
                    },
                  },
                ]}
                visible={visible}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>
                    {intl.formatMessage({
                      id: "general.unArchive",
                    })}
                  </span>
                </div>
              </Tooltip>
            ) : (
              <Tooltip
                onOpenChange={() => setVisible(true)}
                placement="top"
                className="withNoArrow"
                trigger="click"
                text={intl.formatMessage(
                  {
                    id: "productForm.manufactured.card.archiveTooltipTitle",
                  },
                  { Name: "" }
                )}
                actions={[
                  {
                    text: intl.formatMessage({ id: "general.cancel" }),
                    onClick: (event: React.FocusEvent<HTMLElement>) => {
                      event.target.blur();
                      setVisible(false);
                    },
                  },
                  {
                    text: intl.formatMessage({ id: "general.confirm" }),
                    onClick: (event: React.FocusEvent<HTMLElement>) => {
                      onToggleManufacturedStatus();
                      event.target.blur();
                      setVisible(false);
                    },
                  },
                ]}
                visible={visible}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>
                    {intl.formatMessage({
                      id: "general.archive",
                    })}
                  </span>
                </div>
              </Tooltip>
            ),
          },
        ]
      : []),
    {
      key: "deleteAction",
      spinning: false,
      disabled: hideRemove,
      label: (
        <Tooltip
          onOpenChange={() => setVisible(true)}
          placement="top"
          text={intl.formatMessage({
            id: "productForm.manufactured.card.deleteTooltipTitle",
          })}
          actions={[
            {
              text: intl.formatMessage({ id: "general.cancel" }),
              onClick: (event: React.FocusEvent<HTMLElement>) => {
                event.target.blur();
                setVisible(false);
              },
            },
            {
              text: intl.formatMessage({ id: "general.confirm" }),
              onClick: () => {
                onManufacturedRemove();
                setVisible(false);
              },
            },
          ]}
          visible={visible}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>
              {intl.formatMessage({
                id: "general.delete",
              })}
            </span>
          </div>
        </Tooltip>
      ),
    },
    {
      key: "openProductInPdm",
      disabled: !pdmApplication || !hideRemove,
      onClick: () => {
        if (pdmApplication?.url) {
          window.location.href =
            pdmApplication.url +
            `?MasterDataCompanyGuid=${ownerCompanyId}&MasterDataEntityGuid=${manufacturedItemId}`;
        }
      },
      spinning: false,
      label: intl.formatMessage(
        {
          id: "productDetails.pdmLinkOpen",
        },
        {
          Name: pdmApplication?.name,
        }
      ),
    },
  ];

  return (
    <ActionDropdown
      data-test-id="md-manufacturedItem-action"
      disabled={!menuItems.length}
      placement="bottomRight"
      overlay={
        <Menu>
          {menuItems
            .filter(item => !item.disabled)
            .map(item => (
              <Menu.Item
                key={item.key}
                data-test-id={item.key}
                onClick={item.onClick}
              >
                {item.label}
              </Menu.Item>
            ))}
        </Menu>
      }
    />
  );
};

export default ManufacturedAction;
