import { useEffect } from "react";
import { useHistory } from "react-router";

import scrollTo from "shared/utils/scrollTo";

export default function useScrollToTopOnRouteChange() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      scrollTo({ top: 0, left: 0 });
    });
    return () => {
      unlisten();
    };
  }, []);
}
