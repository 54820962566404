import { createAsyncThunk } from "@reduxjs/toolkit";

import { PmdAPI } from "apis";

export const fetchProducts = createAsyncThunk(
  "productList/fetchProducts",
  async ({
    searchText,
    tradeItemStatuses,
    brandIds,
    categoryId,
    categoryItemId,
    OnlyWithoutContacts,
    HasNoBrand,
    HasNoProductCategory,
    ownerCompanyId,
    skip,
    take,
    associatedCompanyIds,
    ContactUserId,
    ContactResponsibilityId,
  }: {
    searchText?: string;
    OnlyWithoutContacts?: boolean;
    tradeItemStatuses: string[];
    brandIds?: string[];
    categoryId?: string;
    HasNoBrand?: boolean;
    HasNoProductCategory?: boolean;
    categoryItemId?: string;
    ownerCompanyId?: string;
    ContactUserId?: string;
    ContactResponsibilityId?: string;

    skip: number;
    take: number;
    associatedCompanyIds: string[];
  }) => {
    let filter = {};
    if (ContactUserId) {
      filter = { ContactResponsibilityId, ContactUserId };
    }

    const { data } = await PmdAPI.getTradeItemsByFilters(
      {
        searchText,
        tradeItemStatuses,
        brandIds,
        supplierCompanyIds: associatedCompanyIds ?? undefined,
      },
      {
        OnlyWithoutContacts,
        ...filter,
        categoryId,
        HasNoBrand,
        HasNoProductCategory,
        categoryItemId,
        ownerCompanyId,
        skip,
        take,
      }
    );
    return data;
  }
);
