import { State } from "./models";

export const initialState: State = {
  isProductLoading: false,
  productData: {},
  hasProductError: false,
  errorStatus: null,
  productFormData: {},
  productFormError: { tradeName: false, manufacturedItem: null },
  productBrandId: "",
};
