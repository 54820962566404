import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

// import { Status } from "@trace-one/react-components";
import { Heading, Paragraph, Tooltip } from "@trace-one/design-system";
import { Row, Col } from "antd";

import {
  selectCountriesOptions,
  selectFunctionOptions,
} from "reduxStore/shared/selectors";
import {
  selectRetailerSite,
  selectRetailerSupplier,
} from "reduxStore/siteRetailerDetails/selectors";

import Label from "components/Label";
import Panel from "components/Panel";
import TabPage from "components/TabPage";
import { capitalizeText } from "shared/utils/capitalizeFullText";

import styles from "../../../../../SiteRtailerDetails/SiteRetailerDetails.module.less";

const SupplierBloc = () => {
  const intl = useIntl();
  const countries = useSelector(selectCountriesOptions);
  const supplier = useSelector(selectRetailerSupplier);
  const site = useSelector(selectRetailerSite);
  const functions = useSelector(selectFunctionOptions);

  if (!supplier) return null;

  // const relationStatus = {
  //   color: relation.status ? "success" : "error",
  //   label: relation.status
  //     ? intl.formatMessage({ id: "general.connected" })
  //     : intl.formatMessage({ id: "general.inactive" }),
  // };

  return (
    <Panel>
      <TabPage.SecondaryTitle
        label={
          <Heading size="xs">
            {capitalizeText(
              intl.formatMessage({ id: "siteForm.activitiesTitle" })
            )}
          </Heading>
        }
      />
      <Row gutter={[30, 30]}>
        {!!supplier.companyLogoUrl && (
          <Col xs={12} lg={8}>
            <img
              width="100%"
              src={supplier.companyLogoUrl}
              alt={supplier.companyDisplayName}
            />
          </Col>
        )}

        <Col xs={24} lg={!!supplier.companyLogoUrl ? 16 : 24}>
          <Label
            label={
              <Heading size="xxs">
                {intl.formatMessage({ id: "general.activities" })}
              </Heading>
            }
            testId="site-activities"
          >
            <Paragraph size="m">
              {site?.siteFunctions?.map((functionId, idx) => (
                <div
                  key={idx}
                  style={{ display: "flex" }}
                  className={styles.siteRetailerDetailsParagraph}
                >
                  {functions.find(i => functionId === i.value)?.name}
                </div>
              ))}
            </Paragraph>
          </Label>

          <Label
            label={
              <Heading size="xxs">
                {intl.formatMessage({
                  id: "siteDetails.operationsPerformed",
                })}
              </Heading>
            }
            testId="site-ops"
          >
            <Paragraph size="m">
              <Tooltip
                showFullText
                text={site?.operationManaged}
                placement="top"
              >
                <div className={styles.siteRetailerDetailsParagraph}>
                  {site?.operationManaged}
                </div>
              </Tooltip>
            </Paragraph>
          </Label>

          <Label
            label={
              <Heading size="xxs">
                {intl.formatMessage({ id: "siteDetails.countryOutside" })}
              </Heading>
            }
            testId="site-approval"
          >
            <Paragraph size="m">
              <div className={styles.siteRetailerDetailsParagraph}>
                {site?.countriesOutsideOfEurope
                  .map(
                    country => countries.find(c => c.value === country)?.name
                  )
                  .filter(Boolean)
                  .join(", ")}
              </div>
            </Paragraph>
          </Label>
        </Col>
      </Row>
    </Panel>
  );
};

export default SupplierBloc;
