export const SectionsIds = Object.freeze({
  IDENTIFICATION: "site-identification-section",
  PRIVATE: "site-private-section",
  VISIBILITY: "site-visibility-section",
  ADDRESS: "site-address-section",
  CONTACTS: "site-contacts-section",
  ACTIVITIES: "site-activities-section",
  CERTIFICATES: "site-certificates-section",
  COMMENTS: "site-comments-section",
});
export const SupplierSectionIds = Object.freeze({
  CONTACTS: "site-supplier-contacts-section",
});

export const SiteIdentification = Object.freeze({
  INTERNAL_CODE: "8b83df2d-bfd1-4a6f-a2d0-3a671383e710",
});

export const PageCol = {
  HALF: { xs: 24, lg: 12 },
  FULL: { xs: 24 },
};

export const AddressModel = Object.freeze({
  ADDRESS_1: "siteAddress1",
  ADDRESS_2: "siteAddress2",
  CITY: "siteCity",
  POSTAL_CODE: "sitePostalCode",
  REGION: "siteRegion",
  COUNTRY_CODE: "siteCountry",
});

export const DaysToExpiredCertificates = 90;
