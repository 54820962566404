/* istanbul ignore file */
import React from "react";
import { useSelector } from "react-redux";

import usePermissions from "core/oidc/usePermissions";

import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import Spinner from "components/Spinner";

import SiteForm from "../../components/SiteSupplierForm";
import withSiteShared, { SiteSharedProps } from "../../hocs/withSiteShared";

interface SiteCreationProps extends SiteSharedProps {}

const SiteCreation: React.FC<SiteCreationProps> = ({
  isSiteSharedInitiated,
}) => {
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const { sitesWrite } = usePermissions();

  if (!isSiteSharedInitiated) {
    return <Spinner underHeader />;
  }

  return (
    <SiteForm
      isReadOnly={!sitesWrite}
      isEditForm={false}
      // @ts-ignore
      isSiteSharedInitiated={isSiteSharedInitiated}
      initialValues={{
        ownerCompanyId,
        siteActive: true,
      }}
    />
  );
};

export default withSiteShared(SiteCreation);
