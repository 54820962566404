import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "reduxStore";
import { fetchOwningCompany } from "reduxStore/shared/asyncActions";
import { selectOwningCompany } from "reduxStore/shared/selectors";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import getDisplayName from "shared/utils/getDisplayName";

import { renderHocMiddleware, BaseHocProps } from "../_helpers";

const withOwningCompany =
  () =>
  <P extends BaseHocProps>(WrappedComponent: React.ComponentType<P>) => {
    const Component: React.FC<P> = props => {
      const dispatch = useAppDispatch();

      const ownerCompanyId = useSelector(selectUserOwningCompanyId);

      const { isInitiated, hasError, isLoading } =
        useSelector(selectOwningCompany);

      useEffect(() => {
        dispatch(fetchOwningCompany({ ownerCompanyId }));
      }, [ownerCompanyId, dispatch]);

      return renderHocMiddleware({
        WrappedComponent,
        _isInitiated: isInitiated,
        _hasError: hasError,
        _isLoading: isLoading,
        ...props,
      });
    };
    Component.displayName = getDisplayName(withOwningCompany, WrappedComponent);

    return Component;
  };

export default withOwningCompany;
