import { createSlice } from "@reduxjs/toolkit";

import { fetchClassificationItem } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "classificationDetails",
  initialState,
  reducers: {
    clearClassificationDetails: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchClassificationItem.pending, (state, action) => {
        state.isClassificationLoading = true;
        state.hasClassificationError = false;
        state.errorStatus = null;
      })
      .addCase(fetchClassificationItem.fulfilled, (state, action) => {
        state.isClassificationLoading = false;
        state.classification = action.payload;
      })
      .addCase(fetchClassificationItem.rejected, (state, action) => {
        state.isClassificationLoading = false;
        state.classification = {};
        state.hasClassificationError = true;
        state.errorStatus = (action.payload as any).status;
      });
  },
});

export const { clearClassificationDetails } = slice.actions;
export default slice.reducer;
