import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  DocumentSearchReqBody,
  DocumentSearchReqParams,
} from "@trace-one/api-clients.dms";
import {
  FilesFilterParams,
  FilesFilterReqBody,
} from "@trace-one/api-clients.global-search";

import {
  DmsAPI,
  CumdAPI,
  SmdAPI,
  PmdAPI,
  ProjectAPI,
  GlobalSearchAPI,
} from "apis";
import { CompanyData } from "models";

import { TradeItemStatus, dropdownFilterCount } from "shared/constants";

export const fetchDocumentsList = createAsyncThunk(
  "documentList/fetchDocumentsList",
  async ({
    params,
    body = null,
  }: {
    params: DocumentSearchReqParams;
    body: DocumentSearchReqBody;
  }) => {
    const { data } = await DmsAPI.getDocuments(params, body);
    return data;
  }
);

export const fetchDocumentsListGlobalSearch = createAsyncThunk(
  "documentList/fetchDocumentsListGlobalSearch",
  async ({
    params,
    body = null,
  }: {
    params: FilesFilterParams;
    body: FilesFilterReqBody;
  }) => {
    const { data } = await GlobalSearchAPI.getDocumentsFromGlobalSearch(
      params,
      body
    );
    return data;
  }
);

export const fetchFileTypes = createAsyncThunk(
  "documentList/fetchFileTypes",
  async (params: { languageCode: string }) => {
    const { data } = await DmsAPI.getFileTypes(params);
    return data;
  }
);

export const fetchSites = createAsyncThunk(
  "documentList/fetchSites",
  async ({
    associatedCompanyId,
    ownerCompanyIdCollection,
  }: {
    associatedCompanyId: string;
    ownerCompanyIdCollection?: string[];
  }) => {
    const { data: siteList } = await SmdAPI.getRelationsByFilters(
      {
        ownerCompanyIdCollection,
      },
      {
        associatedCompanyId,
        ownerCompanyRelationStatus: true,
      }
    );

    const companyIds = siteList.sites.map(s => s.ownerCompanyId);

    let companyList: CompanyData[] = [];
    // Because the API will throw an error if companyIds is empty,
    // we have to make this little condition.
    // I'm not very happy with that, but life isn't about waiting for
    // the storm to pass it's about learning to dance in the rain.
    if (companyIds.length) {
      const companyIdsUniq: string[] = [...new Set(companyIds)];
      companyList = await CumdAPI.getCompaniesByFilters(
        { companyIds: companyIdsUniq },
        {
          relatedOwnerCompanyId: associatedCompanyId,
          includePrivate: true,
        }
      ).then(({ data }) => data.companies);
    }

    return { siteList, companyList };
  }
);

export const fetchProducts = createAsyncThunk(
  "documentList/fetchProducts",
  async ({
    ownerCompanyId,
    supplierCompanyIds,
  }: {
    ownerCompanyId: string;
    supplierCompanyIds?: string[];
  }) => {
    const { data } = await PmdAPI.getTradeItemsByFilters(
      {
        tradeItemStatuses: [TradeItemStatus.ACTIVE],
        supplierCompanyIds,
      },
      {
        ownerCompanyId,
      }
    );
    return data.tradeItems;
  }
);

export const fetchProjects = createAsyncThunk(
  "documentList/fetchProjects",
  async ({
    UserId,
    LanguageCode,
  }: {
    UserId: string;
    LanguageCode: string;
  }) => {
    const { data } = await ProjectAPI.getProjectsByFilter({
      UserId,
      LanguageCode,
    });
    return data.projects;
  }
);

export const fetchRelatedCompanies = createAsyncThunk(
  "documentList/fetchRelatedCompanies",
  async ({ ownerCompanyId }: { ownerCompanyId: string }) => {
    const { data: relatedCompanies } = await CumdAPI.getCompaniesByFilters(
      {},
      {
        skip: 0,
        take: dropdownFilterCount,
        relatedOwnerCompanyId: ownerCompanyId,
        isCompanyRelationStatusEnabled: true,
        includePrivate: false,
      }
    );
    return relatedCompanies.companies;
  }
);
