export function formatFileSize(bytes: number = 0, decimalPoint?: number) {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1000;
  const dm = decimalPoint || 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function downloadBlobAsFile(file: Blob, name?: string): void {
  const href = URL.createObjectURL(file);

  // create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", name ?? "");
  link.click();
  URL.revokeObjectURL(href);

  const fileSizeInMB = Math.floor(file.size / (1024 * 1024));
  const canCauseTabCrashInEdge = fileSizeInMB > 700;

  if (navigator.userAgent.includes("Edg/") && canCauseTabCrashInEdge) {
    window.location.reload();
  }
}
