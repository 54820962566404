import { Fragment, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Tooltip } from "@trace-one/design-system";

import { selectSiteCertificateFormData } from "reduxStore/siteSupplierDetails/selectors";

import Icon from "components/Icon";
import IconButton from "components/IconButton";

interface CertificateActionProps {
  onRemoveCertificateField: () => void;
  handleArchiveCertificate: (typeId: any) => void;
  endDate: string;
  isEditForm: boolean;
  certificateKeyId: string;
  fields: any;
}

const CertificateAction: React.FC<CertificateActionProps> = ({
  onRemoveCertificateField,
  endDate,
  handleArchiveCertificate,
  isEditForm,
  certificateKeyId,
}) => {
  const intl = useIntl();
  const siteCertificateFormData = useSelector(selectSiteCertificateFormData);
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Fragment>
      {!siteCertificateFormData?.length || !isEditForm || !certificateKeyId ? (
        <Tooltip
          onOpenChange={() => setVisible(true)}
          placement="top"
          text={intl.formatMessage({
            id: "siteSupplierForm.tooltipTitle.delete",
          })}
          actions={[
            {
              text: intl.formatMessage({ id: "general.cancel" }),
              onClick: (event: React.FocusEvent<HTMLElement>) => {
                event.target.blur();
                setVisible(false);
              },
            },
            {
              text: intl.formatMessage({ id: "general.confirm" }),
              onClick: () => {
                onRemoveCertificateField();
                setVisible(false);
              },
            },
          ]}
          visible={visible}
        >
          <IconButton data-test-id="md-site-certificate-remove">
            <Icon name="trash" size="small" color="primary" useTOLibrary />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          onOpenChange={() => setVisible(true)}
          placement="top"
          text={intl.formatMessage({
            id: "siteSupplierForm.tooltipTitle.archive",
          })}
          actions={[
            {
              text: intl.formatMessage({ id: "general.cancel" }),
              onClick: (event: React.FocusEvent<HTMLElement>) => {
                event.target.blur();
                setVisible(false);
              },
            },
            {
              text: intl.formatMessage({ id: "general.confirm" }),
              onClick: (event: React.FocusEvent<HTMLElement>) => {
                handleArchiveCertificate(certificateKeyId);
                event.target.blur();
                setVisible(false);
              },
            },
          ]}
          visible={visible}
        >
          <IconButton data-test-id="md-site-certificate-archive">
            {!endDate?.[1] ? (
              <Icon name="archive" size="small" color="primary" useTOLibrary />
            ) : null}
          </IconButton>
        </Tooltip>
      )}
    </Fragment>
  );
};

export default CertificateAction;
