import { createSlice } from "@reduxjs/toolkit";

import { fetchProduct } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "productForm",
  initialState,
  reducers: {
    clearProductForm: () => initialState,
    setProductSelectedSupplier: (state, action) => {
      state.productSelectedSupplier = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProduct.pending, (state, action) => {
        state.isProductLoading = true;
        state.hasProductError = false;
        state.errorStatus = null;
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.productData = action.payload;
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        state.isProductLoading = false;
        state.productData = {};
        state.hasProductError = true;
        state.errorStatus = (action.payload as any).status;
      });
  },
});

export const { clearProductForm, setProductSelectedSupplier } = slice.actions;
export default slice.reducer;
