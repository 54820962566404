import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectSiteSupplierForm = (state: RootState) =>
  state.siteSupplierForm;

export const selectSite = createSelector(
  _selectSiteSupplierForm,
  ({ site }) => site.data
);

export const selectSiteRelations = createSelector(
  _selectSiteSupplierForm,
  ({ siteRelation }) => siteRelation.data
);

export const selectIsSiteLoading = createSelector(
  _selectSiteSupplierForm,
  ({ site, siteRelation }) => site.isLoading || siteRelation.isLoading
);

export const selectHasSiteEditError = createSelector(
  _selectSiteSupplierForm,
  ({ site, siteRelation }) => site.hasError || siteRelation.hasError
);

export const selectSiteErrorStatus = createSelector(
  _selectSiteSupplierForm,
  ({ site }) => site.errorStatus
);

export const selectedCertificate = createSelector(
  _selectSiteSupplierForm,
  ({ selectedCertificate }) => selectedCertificate
);
