/* istanbul ignore file */
import { mapMomentLocalToDate } from "shared/utils/dateUtils";

const prepareFormData = ({ fields }) => {
  return {
    ...fields,
    siteCertificates: fields?.siteCertificates?.map(c => ({
      typeId: c.typeId,
      certificationId: c.certificationId,
      certificateLabel: c.certificateLabel,
      certificateVersion: c.certificateVersion,
      grade: c.certificateGrade,
      attachments: c.attachments,
      startDate: mapMomentLocalToDate(c.certificateDates?.[0]),
      endDate: mapMomentLocalToDate(c.certificateDates?.[1]),
    })),
  };
};

export default prepareFormData;
