/* istanbul ignore file */
import React, { useState } from "react";
import { useIntl } from "react-intl";

import { TagV1 as Tag, Tooltip } from "@trace-one/design-system";

import { TradeItemData } from "models";

import CustomInputGroup from "components/CustomInputGroup";
import Input from "components/Input";

interface AttributesInputProps {
  value?: TradeItemData["retailerCustomizedAttributes"];
  onChange?: Function;
  disabled: boolean;
}

const AttributesInput: React.FC<AttributesInputProps> = ({
  value: retailerCustomizedAttributes = [],
  onChange,
  disabled,
}) => {
  const intl = useIntl();

  const [retailerLabel, setRetailerLabel] = useState(undefined);
  const [retailerCode, setRetailerCode] = useState(undefined);

  const isMoreOrEqual10 = retailerCustomizedAttributes?.length >= 10;

  const isAddDisabled =
    disabled ||
    !retailerCode?.trim() ||
    !retailerLabel?.trim() ||
    isMoreOrEqual10 ||
    !!retailerCustomizedAttributes?.find(
      item =>
        item.retailerLabel === retailerLabel &&
        item.retailerCode === retailerCode
    );

  return (
    <CustomInputGroup
      data-test-id="md-product-retailerCustomizedAttributes-add"
      onItemAdd={() => {
        const attribute = {
          retailerLabel,
          retailerCode,
        };
        onChange([...retailerCustomizedAttributes, attribute]);
        setRetailerLabel(undefined);
        setRetailerCode(undefined);
      }}
      disabled={isAddDisabled}
      leftInput={
        <Input
          value={retailerLabel}
          onChange={event => {
            const newValue = event.target.value;
            setRetailerLabel(newValue);
          }}
          maxLength={256}
          data-test-id="md-product-retailerCustomizedAttributes-label"
          placeholder={intl.formatMessage({
            id: "general.enterAttributeLabel",
          })}
          disabled={disabled || isMoreOrEqual10}
        />
      }
      rightInput={
        <Input
          value={retailerCode}
          onChange={event => {
            const newValue = event.target.value;
            setRetailerCode(newValue);
          }}
          disabled={disabled || isMoreOrEqual10}
          maxLength={256}
          placeholder={intl.formatMessage({
            id: "general.enterAttributeValue",
          })}
          data-test-id="md-product-retailerCustomizedAttributes-code"
        />
      }
    >
      {retailerCustomizedAttributes?.map(
        ({ retailerLabel, retailerCode }, index) => {
          const label = [retailerLabel, retailerCode]
            .filter(text => text)
            .join(": ");

          return (
            <Tooltip showFullText text={label} placement="top">
              <Tag
                key={`${retailerLabel}-${retailerCode}-${index}`}
                data-test-id={`tag-${label}`}
                label={<span>{label}</span>}
                onClose={() => {
                  onChange(
                    retailerCustomizedAttributes?.filter(
                      item =>
                        !(
                          item.retailerLabel === retailerLabel &&
                          item.retailerCode === retailerCode
                        )
                    )
                  );
                }}
              />
            </Tooltip>
          );
        }
      )}
    </CustomInputGroup>
  );
};

export default AttributesInput;
