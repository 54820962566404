import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectClassificationDirectChilds = (state: RootState) =>
  state.classificatonDirectChilds;

export const selectClassificationDirectChildsItems = createSelector(
  _selectClassificationDirectChilds,
  ({
    classificationChildList,
    isClassificationChildLoading,
    hasClassificationChildError,
    classificationErrorStatus,
    selectedLanguage,
    isEditabaleData,
  }) => ({
    classificationChildList,
    isClassificationChildLoading,
    hasClassificationChildError,
    classificationErrorStatus,
    selectedLanguage,
    isEditabaleData,
  })
);
// export const selectCategoryList = createSelector(
//   _selectClassificationDirectChilds,
//   ({ classificationChildList }) => classificationChildList
// );
