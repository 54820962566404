/* istanbul ignore file */
import { useIntl } from "react-intl";

import { SmdAPI, DmsAPI } from "apis";

import Icon from "components/Icon";
import useToast from "shared/hooks/useToast";
import downloadFile from "shared/utils/downloadFile";

import styles from "./FilePreview.module.less";

export interface FilePreviewProps {
  file: {
    [key: string]: any;
  };
  onDelete?: (...args: unknown[]) => void;
}

const FilePreview: React.FC<FilePreviewProps> = ({ file, onDelete }) => {
  const intl = useIntl();
  const toast = useToast();
  const sizeMB = (file.size || file.fileSize) * 0.000001;
  return (
    <div
      className={styles.fileCard}
      onClick={async () => {
        if (!file.fileId) return;

        try {
          if (window.env.USE_DMS === "true") {
            const { data } = await DmsAPI.downloadDocument(
              file.fileId,
              {
                fileName: file.fileName,
              },
              {
                responseType: "blob",
              }
            );
            downloadFile(data, file.name || file.fileName);
          } else {
            const { data } = await SmdAPI.getFile(file.fileId, {
              responseType: "blob",
            });
            downloadFile(data, file.name || file.fileName);
          }
        } catch (_) {
          toast.fetchError({
            checkErrorResponse: false,
          });
        }
      }}
    >
      <div>
        <Icon name="file" size="large" useTOLibrary />
      </div>
      <div className={styles.fileInfo}>
        <p className={styles.fileName}>{file.name || file.fileName}</p>
        <span>
          {intl.formatMessage(
            { id: "general.sizeMB" },
            {
              sizeInMB: sizeMB.toFixed(3),
            }
          )}
        </span>
      </div>
      {onDelete && (
        <div className={styles.fileRemove} onClick={onDelete}>
          <Icon name="trash" size="small" useTOLibrary />
        </div>
      )}
    </div>
  );
};

export default FilePreview;
