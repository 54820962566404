import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { CategoryList } from "@trace-one/api-clients.rlmd";
import { Heading } from "@trace-one/design-system";
import { Form } from "antd";

import { RlmdAPI } from "apis";

import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import { FormModalContent } from "components/FormModal";
import { layout } from "components/FormWrapper";
import Input from "components/Input";
import { ErrorCode, hasErrorOccurred } from "shared/errors";
import useToast from "shared/hooks/useToast";

const classificationFormId = "classification-form";

export interface CreateClassificationFormProps {
  initialValues?: any; //will be used for edit
  onSubmitSuccess?: () => void;
  setIsSubmitting?: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel?: () => void;
  setIsClosable?: any;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}

const CreateClassificationForm: React.FC<CreateClassificationFormProps> = ({
  initialValues,
  onSubmitSuccess,
  onCancel,
  setIsClosable,
  isEditing,
  setIsEditing,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const [submitErrors, setSubmitErrors] = useState({
    externalCode: false,
    categoryListName: false,
  });
  const [validator, setValidator] = useState({
    externalCode: false,
    categoryListName: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toast = useToast();
  const formData = form.getFieldsValue();

  const handleSubmit = async (values: CategoryList) => {
    setIsSubmitting(true);
    setIsClosable(false);

    try {
      if (isEditing) {
        await RlmdAPI.updateCategoryListNameById(initialValues.categoryListId, {
          ...values,
        });
      } else {
        await RlmdAPI.createCategory({
          ...values,
          companyId: ownerCompanyId,
          categoryType: 0,
        });
      }
      if (setIsSubmitting) {
        setIsSubmitting(false);
        setIsClosable(true);
        setIsEditing(false);
      }
      if (onSubmitSuccess) onSubmitSuccess();
    } catch (error) {
      if (
        hasErrorOccurred({
          error,
          errorCode: ErrorCode.CATEGORY_NAME_ALREADY_EXISTED,
        })
      ) {
        setSubmitErrors({ categoryListName: true, externalCode: false });
      } else if (
        hasErrorOccurred({
          error,
          errorCode: ErrorCode.CLASSIFICATION_EXTERNAL_CODE_ALREADY_EXISTED,
        })
      ) {
        setSubmitErrors({ categoryListName: false, externalCode: true });
      } else {
        toast.saveError({ error });
      }
      if (setIsSubmitting) {
        setIsSubmitting(false);
        if (!isEditing) setIsEditing(false);
        setIsClosable(true);
      }
    }
  };

  return (
    <FormModalContent
      form={form}
      {...layout}
      initialValues={initialValues}
      onFinishFailed={({ errorFields }) => {
        if (
          errorFields[0]?.name[0] === "categoryListName" &&
          errorFields[1]?.name[0] === "externalCode"
        ) {
          setValidator({ externalCode: true, categoryListName: true });
        } else if (errorFields[0]?.name[0] === "categoryListName") {
          setValidator(prev => ({ ...prev, categoryListName: true }));
        } else if (errorFields[0]?.name[0] === "externalCode") {
          setValidator(prev => ({ ...prev, externalCode: true }));
        } else {
          setValidator({ externalCode: false, categoryListName: false });
        }
      }}
      id={classificationFormId}
      onFinish={handleSubmit}
      onValuesChange={changedValues => {
        if ("externalCode" in changedValues) {
          setValidator(prev => ({ ...prev, externalCode: false }));
          setSubmitErrors(prev => ({ ...prev, externalCode: false }));
        }
        if ("categoryListName" in changedValues) {
          setValidator(prev => ({ ...prev, categoryListName: false }));
          setSubmitErrors(prev => ({ ...prev, categoryListName: false }));
        }
      }}
      isSubmitting={isSubmitting}
      onCancel={onCancel}
    >
      <div>
        <Form.Item
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "general.name" })}{" "}
            </Heading>
          }
          name="categoryListName"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "",
            },
          ]}
        >
          <Input
            data-test-id="classification-form-categoryListName"
            error={
              formData.categoryListName === "" ||
              validator.categoryListName ||
              submitErrors.categoryListName
            }
            errorDataTestId={
              submitErrors.categoryListName
                ? "classification-form-errorcategoryListName"
                : "md-error-categoryListName-is-required"
            }
            errorMessage={
              submitErrors.categoryListName
                ? intl.formatMessage({
                    id: "classification.name.errors.usedByAnotherClassification",
                  })
                : intl.formatMessage({
                    id: "general.valueIsRequired",
                  })
            }
            maxLength={256}
          />
        </Form.Item>
        <Form.Item
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "general.internalCode" })}{" "}
            </Heading>
          }
          name="externalCode"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "",
            },
          ]}
        >
          <Input
            data-test-id="classification-form-externalCode"
            error={
              formData.externalCode === "" ||
              validator.externalCode ||
              submitErrors.externalCode
            }
            errorDataTestId={
              submitErrors.externalCode
                ? "classification-form-errorexternalCode"
                : "md-error-externalCode-is-required"
            }
            errorMessage={
              submitErrors.externalCode
                ? intl.formatMessage({
                    id: "classification.externalCode.errors.usedByAnotherClassification",
                  })
                : intl.formatMessage({
                    id: "general.valueIsRequired",
                  })
            }
            maxLength={20}
          />
        </Form.Item>
      </div>
    </FormModalContent>
  );
};

export default CreateClassificationForm;
