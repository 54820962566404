import { useState } from "react";

import { Spinner } from "@trace-one/react-components";
import { notification } from "antd";
import { ArgsProps } from "antd/lib/notification";
import { v4 } from "uuid";

import useToast from "shared/hooks/useToast";
import { PartialBy } from "shared/typings";

export type Options = {
  filename?: string;
  fileExtension?: string;
  loadingMessage?: string;
  displayLoadingMessage?: boolean;
  setIsLoading?: (value: boolean) => void;
  successMessage?: string | PartialBy<ArgsProps, "message">;
};

export default function useAsyncHandler<T>(
  asyncCallback: (...args: unknown[]) => Promise<T>,
  {
    loadingMessage,
    displayLoadingMessage,
    setIsLoading,
    successMessage,
  }: Options = {}
) {
  const toast = useToast();
  const [isLoading, _setIsLoading] = useState(false);

  const setLoadingState = value => {
    if (setIsLoading) {
      setIsLoading(value);
    } else {
      _setIsLoading(value);
    }
  };

  const asyncHandler = async (...args) => {
    const key = v4();
    setLoadingState(true);
    if (displayLoadingMessage) {
      notification.open({
        type: "info",
        placement: "bottomLeft",
        message: loadingMessage,
        key,
        duration: 0,
        icon: <Spinner />,
        className: "custom-notification-without-close-icon",
      });
    }

    try {
      const result = await asyncCallback(...args);
      setLoadingState(false);
      if (displayLoadingMessage) notification.close(key);
      if (successMessage) {
        if (typeof successMessage === "object" && successMessage !== null) {
          const { type, ...options } = successMessage;
          toast[type](options);
        } else {
          toast.success({ description: successMessage });
        }
      }
      return result;
    } catch (error) {
      toast.fetchError({ error });
      setLoadingState(false);
      if (displayLoadingMessage) notification.close(key);
    }
  };

  return setIsLoading
    ? ([asyncHandler] as const)
    : ([asyncHandler, isLoading] as const);
}
