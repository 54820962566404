import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectProductDetails = (state: RootState) => state.productDetails;

export const selectProductDetailsData = createSelector(
  _selectProductDetails,
  ({
    isProductLoading,
    productData,
    hasProductError,
    errorStatus,
    productFormData,
    productFormError,
    productBrandId,
  }) => ({
    isProductLoading,
    productData,
    hasProductError,
    errorStatus,
    productFormData,
    productFormError,
    productBrandId,
  })
);
