import { RlmdAPI } from "apis";
import { CategoryItemData } from "models";

export type CategoryItemType = (CategoryItemData & {
  parent1CategoryItemName: string;
  parent2CategoryItemName: string;
  parent3CategoryItemName: string;
  parent4CategoryItemName: string;
})[];

export default async function getCategoriesWithHierarchy(
  parentIds: string[],
  languageCode: string
): Promise<CategoryItemType> {
  let categoryIds = [];
  let categoryList = [];

  await RlmdAPI.getCategoryItems(
    {
      idCollection: parentIds,
    },
    { languageCode }
  ).then(({ data }) => {
    data
      .filter(c => parentIds.includes(c.categoryItemId))
      .forEach(categoryData => {
        categoryList.push(categoryData);
        categoryData.parent1CategoryItemId &&
          categoryIds.push(categoryData.parent1CategoryItemId);

        categoryData.parent2CategoryItemId &&
          categoryIds.push(categoryData.parent2CategoryItemId);

        categoryData.parent3CategoryItemId &&
          categoryIds.push(categoryData.parent3CategoryItemId);

        categoryData.parent4CategoryItemId &&
          categoryIds.push(categoryData.parent4CategoryItemId);
      });
  });

  await RlmdAPI.getCategoryItems(
    { idCollection: categoryIds },
    { languageCode }
  ).then(({ data }) => {
    data.forEach(c => {
      const categoryAdded = categoryList.find(
        cl => cl.categoryItemId === c.categoryItemId
      );
      if (!categoryAdded) categoryList.push(c);
    });
  });

  const parentCategories = categoryList.filter(c =>
    parentIds.includes(c.categoryItemId)
  );

  return parentCategories.map(category => {
    return {
      ...category,
      parent1CategoryItemName: categoryList.find(
        c => c.categoryItemId === category.parent1CategoryItemId
      )?.categoryItemName,
      parent2CategoryItemName: categoryList.find(
        c => c.categoryItemId === category.parent2CategoryItemId
      )?.categoryItemName,
      parent3CategoryItemName: categoryList.find(
        c => c.categoryItemId === category.parent3CategoryItemId
      )?.categoryItemName,
      parent4CategoryItemName: categoryList.find(
        c => c.categoryItemId === category.parent4CategoryItemId
      )?.categoryItemName,
    };
  });
}
