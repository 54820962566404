import { useIntl } from "react-intl";

import { Button } from "@trace-one/design-system";
import { Form, FormProps } from "antd";

import { layout } from "components/FormWrapper";

import styles from "./FormModal.module.less";

export interface FormModalContentProps extends FormProps {
  isSubmitting: boolean;
  onCancel: React.Dispatch<boolean>;
}

const FormModalContent: React.FC<FormModalContentProps> = ({
  children,
  isSubmitting,
  onCancel,
  ...rest
}) => {
  const intl = useIntl();

  return (
    <Form {...layout} {...rest}>
      {children}
      <div className={styles.formFooterWrapper}>
        <Form.Item noStyle>
          <Button disabled={isSubmitting} type="secondary" onClick={onCancel}>
            {intl.formatMessage({ id: "general.cancel" })}
          </Button>
        </Form.Item>
        <Form.Item noStyle>
          <Button
            disabled={isSubmitting}
            type="primary"
            htmlType="submit"
            data-test-id="md-form-submit"
          >
            {intl.formatMessage({ id: "general.save" })}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default FormModalContent;
