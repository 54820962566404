import React, { forwardRef } from "react";

import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./IconButton.module.less";

interface IconButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type?: "button" | "submit" | "reset";
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton({ children, ...rest }, ref) {
    return (
      <button
        type="button"
        {...rest}
        className={cn(styles.root, rest.className)}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IconButton;
