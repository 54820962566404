import { Title } from "@trace-one/react-components";
import cn from "classnames";

import { capitalize } from "shared/utils/capitalizeFirstLetter";

import styles from "../TabPageWrapper.module.less";
import "./SectionTitle.less";

interface SectionTitleProps {
  label: string;
  children?: React.ReactNode;
  isHeader?: boolean;
  isLined?: boolean;
  linedLevel?: number;
}

const SectionTitle = ({
  label,
  children,
  isHeader,
  isLined,
  linedLevel = 2,
}: SectionTitleProps) => {
  return (
    <div
      className={cn({
        [styles.tabHeader]: isHeader,
        tabPageDetails__BlocTitle: isLined,
      })}
    >
      {isLined ? (
        <Title level={linedLevel} value={capitalize(label)} />
      ) : (
        <h3 className={styles.sectionTitle}>{capitalize(label)}</h3>
      )}

      {children}
    </div>
  );
};

export default SectionTitle;
