import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectSupplierList = (state: RootState) => state.supplierList;

export const selectSuppliers = createSelector(
  _selectSupplierList,
  ({
    suppliers,
    suppliersSkipAndTakeQueryStats,
    isSuppliersLoading,
    hasSuppliersError,
  }) => ({
    suppliers,
    suppliersSkipAndTakeQueryStats,
    isSuppliersLoading,
    hasSuppliersError,
  })
);
