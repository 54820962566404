import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Button, Graphic } from "@trace-one/design-system";
import { Col, Row } from "antd";
import cn from "classnames";

import {
  selectUserApplications,
  selectUserOwningCompanyId,
} from "reduxStore/user/selectors";

import { pdmDetails as pdmIds } from "shared/constants";

import styles from "../../../ProductDetails.module.less";

const QuickLinks = () => {
  const intl = useIntl();
  const userApplications = useSelector(selectUserApplications);
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const { productId } = useParams<{ productId?: string }>();
  const userAppIds = userApplications.map(app => app.applicationTypeId);
  const pdmApplication = pdmIds.find(c => userAppIds.includes(c.id));

  return (
    <Row className={styles.linksSection} gutter={[24, 24]}>
      <Col xs={24} lg={8}>
        <button
          onClick={() => {
            if (pdmApplication?.url) {
              window.location.href =
                pdmApplication.url +
                `?MasterDataCompanyGuid=${ownerCompanyId}&MasterDataEntityGuid=${productId}`;
            }
          }}
          data-test-id="pdm-panel"
          className={cn(styles.linkPanel, styles.pdmPanel)}
        >
          <div>
            <Button
              data-test-id="linkto-pdm"
              type="link"
              size="m"
              linkColor="grey-5"
            >
              {intl.formatMessage(
                {
                  id: "productDetails.pdmLinkOpen",
                },
                {
                  Name: pdmApplication.name,
                }
              )}
            </Button>
          </div>
          <Graphic name={pdmApplication.iconName} size="large" />
        </button>
      </Col>
      <Col xs={24} lg={8}>
        <button data-test-id="insight-panel" className={styles.linkPanel}>
          <div>
            <Button
              data-test-id="linkto-insight"
              disabled
              type="link"
              size="m"
              linkColor="grey-5"
            >
              {intl.formatMessage({
                id: "productDetails.isnigntLinkOpen",
              })}
            </Button>
          </div>
          <Graphic name="app-logo-insight-full" size="large" />
        </button>
      </Col>
      <Col xs={24} lg={8}>
        <button data-test-id="project-panel" className={styles.linkPanel}>
          <div>
            <Button
              data-test-id="linkto-project"
              disabled
              type="link"
              size="m"
              linkColor="grey-5"
            >
              {intl.formatMessage({
                id: "productDetails.projectLinkOpen",
              })}
            </Button>
          </div>
          <Graphic name="app-logo-project-full" size="large" />
        </button>
      </Col>
    </Row>
  );
};

export default QuickLinks;
