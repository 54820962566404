import { defineMessages } from "react-intl";

export const messages = defineMessages({
  manufacturedItemSuccess: {
    id: "productForm.manufacturedItem.toast.success",
    defaultMessage:
      "The manufactured item for the supplier {supplierName} is well saved.",
  },
  manufacturedItemError: {
    id: "productForm.manufacturedItem.toast.error",
    defaultMessage:
      "The manufactured item for the supplier {supplierName} is not been saved.",
  },
  allManufacturedItemsSuccess: {
    id: "productForm.manufacturedItems.toast.success",
  },
});
