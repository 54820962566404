import { Row } from "antd";

import TabPage from "components/TabPage";

import PrivateInformation from "./PrivateInformation";
import SupplierIdentification from "./SupplierIdentification";

interface CompanyInformationTabProps {}

const CompanyInformationTab: React.FC<CompanyInformationTabProps> = () => {
  return (
    <TabPage.Wrapper>
      <Row gutter={[32, 32]}>
        <SupplierIdentification />
        <PrivateInformation />
      </Row>
    </TabPage.Wrapper>
  );
};

export default CompanyInformationTab;
