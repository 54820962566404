import { createAsyncThunk } from "@reduxjs/toolkit";

import { PmdAPI } from "apis";

export const fetchBrandsTable = createAsyncThunk(
  "brandList/fetchBrandsTable",
  async ({
    searchText,
    languageCode,
    ownerCompanyId,
    skip,
    isActive,
    take,
  }: {
    searchText?: string;
    languageCode?: string;
    ownerCompanyId: string;
    skip: number;
    take: number;
    isActive: boolean;
  }) => {
    const { data } = await PmdAPI.getBrandsByFilters({
      searchText,
      languageCode,
      ownerCompanyId,
      skip,
      take,
      includeInactive: true,
      isActive,
    });
    return data;
  }
);
