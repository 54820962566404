import { useIntl } from "react-intl";

import { ApplicationTypes } from "shared/constants";

export default function useAppTranslation(appId) {
  const intl = useIntl();

  const AppplicationLabels = {
    [ApplicationTypes.QUALITY_PORTAL]: intl.formatMessage({
      id: "app.qualityPortal",
    }),
    [ApplicationTypes.PACK_PORTAL]: intl.formatMessage({
      id: "app.packPortal",
    }),
    [ApplicationTypes.PDM]: "PDM",
    [ApplicationTypes.BUSINESS_INTELLIGENCE]: "Business Intelligence",
    [ApplicationTypes.INSIGHT]: "Insight",
    [ApplicationTypes.TON]: "Network",
    [ApplicationTypes.PROJECT]: "Project",
    [ApplicationTypes.QMS]: "QMS",
    [ApplicationTypes.SPEC]: "SPEC",
    [ApplicationTypes.RFX]: "RFX",
    [ApplicationTypes.NEW_INSIGHT]: "Insight",
  };

  return {
    appLabel: AppplicationLabels[appId],
  };
}
