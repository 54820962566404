import { mapDateToMomentLocal } from "shared/utils/dateUtils";

import { GetProductDataForm, ProductFormValues } from "../models";

export default function prepareInitialValues({
  initialValues = {},
}: {
  initialValues: GetProductDataForm;
}): ProductFormValues {
  const {
    seasonalAvailabilityStartDate,
    seasonalAvailabilityEndDate,
    manufacturedItems,
    ...values
  } = initialValues;
  return {
    isTradeItemBaseUnit: true,
    isConsumerUnit: true,
    multipack: null,
    productLanguage: null,
    netContents: [],
    contacts: [],
    comments: [],
    seasonalDate: [
      mapDateToMomentLocal(seasonalAvailabilityStartDate),
      mapDateToMomentLocal(seasonalAvailabilityEndDate),
    ],
    manufacturedItems: manufacturedItems?.map(
      ({ manufacturedItemStatus, ...item }) => ({
        manufacturedItemStatusId: manufacturedItemStatus,
        ...item,
        targetMarketsList: item?.targetMarketsList?.map(
          ({ startAvailabilityDate, endAvailabilityDate, ...rest }) => ({
            ...rest,
            availabilityDate: [
              mapDateToMomentLocal(startAvailabilityDate),
              mapDateToMomentLocal(endAvailabilityDate),
            ],
          })
        ),
      })
    ),
    ...values,
  };
}
