import { CompanyData, SiteData, SiteRelationData, UserData } from "models";

type siteSupplierFormErrorState = {
  siteName: boolean;
  certificate?: string[];
};

interface siteCertificateFormData {
  certificationId?: string;
  typeId?: string;
  certificateStartAvailabilityDate?: string;
  certificateEndAvailabilityDate?: string;
  grade?: string;
  certificateLabel?: string;
  attachments?: {
    fileId: string;
    fileUrl: string;
    fileName: string;
    fileSize: string;
  }[];
}

type State = {
  site: {
    data: SiteData;
    isLoading: boolean;
    hasError: boolean;
    errorStatus: any;
  };
  supplier: {
    data: CompanyData;
  };
  contactUsers: {
    data: UserData[];
  };
  siteRelation: {
    data: SiteRelationData[];
    isLoading: boolean;
    hasError: boolean;
  };
  siteSupplierFormData: {
    data: SiteData;
    isLoading: boolean;
    hasError: boolean;
    errorStatus: any;
  };
  siteSupplierFormError: siteSupplierFormErrorState;
  siteCertificateFormData: {
    data: siteCertificateFormData[];
    isLoading: boolean;
    hasError: boolean;
    errorStatus: any;
  };
};

export const initialState: State = {
  site: {
    data: {},
    isLoading: false,
    hasError: false,
    errorStatus: null,
  },
  supplier: {
    data: {},
  },
  contactUsers: {
    data: [],
  },
  siteRelation: {
    data: [],
    isLoading: false,
    hasError: false,
  },
  siteSupplierFormData: {
    data: {},
    isLoading: false,
    hasError: false,
    errorStatus: null,
  },
  siteSupplierFormError: {
    siteName: false,
    certificate: null,
  },
  siteCertificateFormData: {
    data: [],
    isLoading: false,
    hasError: false,
    errorStatus: null,
  },
};
