import { combineReducers, StateFromReducersMapObject } from "@reduxjs/toolkit";

import brandList from "./brandList/slice";
import categoryItemForm from "./categoryItemForm/slice";
import classificationDetails from "./classificationDetails/slice";
import classificationList from "./classificationList/slice";
import classificatonDirectChilds from "./classificatonDirectChilds/slice";
import documentList from "./documentList/slice";
import oidc from "./oidc/slice";
import productDetails from "./productDetails/slice";
import productForm from "./productForm/slice";
import productList from "./productList/slice";
import productShared from "./productShared/slice";
import shared from "./shared/slice";
import siteList from "./siteList/slice";
import siteRetailerDetails from "./siteRetailerDetails/slice";
import siteRetailerForm from "./siteRetailerForm/slice";
import siteSupplierDetails from "./siteSupplierDetails/slice";
import siteSupplierForm from "./siteSupplierForm/slice";
import supplierDetails from "./SupplierDetails/slice";
import supplierForm from "./supplierForm/slice";
import supplierList from "./supplierList/slice";
import user from "./user/slice";

const rootReducer = {
  brandList,
  categoryItemForm,
  classificationDetails,
  classificationList,
  classificatonDirectChilds,
  documentList,
  oidc,
  productDetails,
  productForm,
  productList,
  productShared,
  siteList,
  siteRetailerDetails,
  siteSupplierForm,
  siteSupplierDetails,
  siteRetailerForm,
  shared,
  supplierForm,
  supplierList,
  supplierDetails,
  user,
};

export type RootState = StateFromReducersMapObject<typeof rootReducer>;

export default combineReducers(rootReducer);
