import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectBrandList = (state: RootState) => state.brandList;

export const selectBrands = createSelector(
  _selectBrandList,
  ({ brands }) => brands
);
export const selectBrandsSkipAndTakeQueryStats = createSelector(
  _selectBrandList,
  ({ brandsSkipAndTakeQueryStats }) => brandsSkipAndTakeQueryStats
);
export const selectIsBrandTableLoading = createSelector(
  _selectBrandList,
  ({ isBrandsLoading }) => isBrandsLoading
);
