import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Button, Tooltip } from "@trace-one/design-system";

import { PmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import {
  selectIsExportSelectedManufacturedItemsLoading,
  selectIsExportSelectedProductsLoading,
} from "reduxStore/productList/selectors";
import {
  setIsExportSelectedProductsLoading,
  setIsExportSelectedManufacturedItemsLoading,
} from "reduxStore/productList/slice";

import ShortMsg from "components/ShortMsg";
import Table from "components/Table";
import useAsyncFileDownload from "shared/hooks/useAsyncFileDownload";
import useAsyncHandler from "shared/hooks/useAsyncHandler";

import { ProductFilter } from "../models";

import styles from "./ProductHeader.module.less";

interface ProductHeaderProps {
  listingResult?: React.ReactNode;
  searchText: string;
  filterObj?: ProductFilter;
  selectedRowKeys: string[];
  ownerCompanyId: string;
  isAllSelected: boolean;
  totalCount: number;
  refetchProducts: () => void;
  getArchiveAllProducts: () => Promise<string[]>;
  setSelectedRowKeys?: (rowKeys: string[]) => void;
  setAllSelected?: (boolean) => void;
}

const ProductHeader: React.FC<ProductHeaderProps> = ({
  listingResult,
  searchText,
  filterObj,
  selectedRowKeys,
  ownerCompanyId,
  isAllSelected,
  totalCount,
  refetchProducts,
  getArchiveAllProducts,
  setAllSelected,
  setSelectedRowKeys,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { productsWrite, productsUpdate, productsRead } = usePermissions();
  const [visible, setVisible] = useState<boolean>(false);

  const {
    brandIds,
    productCategory,
    tradeItemStatuses,
    associatedCompanyIds,
    HasNoBrand,
    HasNoProductCategory,
    responsibilityId,
    userId,
    checked,
  } = filterObj;

  const [handleArchiveSelectedTradeItems, isArchiveSelectedLoading] =
    useAsyncHandler(async () => {
      await PmdAPI.archiveTradeItems({
        idCollection: isAllSelected
          ? await getArchiveAllProducts()
          : selectedRowKeys,
      });
      refetchProducts();
      setAllSelected(false);
      setSelectedRowKeys([]);
    });

  const [handleExportAllTradeItems, isExportAllTradeItemsLoading] =
    useAsyncHandler(
      () =>
        PmdAPI.exportTradeItemsInBulk(
          {
            tradeItemStatuses,
            searchText,
            brandIds,
            supplierCompanyIds: associatedCompanyIds,
          },
          {
            categoryId,
            categoryItemId,
            ownerCompanyId,
            HasNoBrand,
            HasNoProductCategory,
            OnlyWithoutContacts: checked,
            ContactResponsibilityId: responsibilityId,
            ContactUserId: userId,
          }
        ),
      {
        successMessage: {
          type: "info",
          description: (
            <ShortMsg.FileRequestProcessed formatMessage={intl.formatMessage} />
          ),
        },
      }
    );
  const [
    handleExportAllManufacturedItems,
    isExportAllManufacturedItemsLoading,
  ] = useAsyncHandler(
    () =>
      PmdAPI.exportManufacturedItemsInBulk(
        {
          tradeItemStatuses,
          searchText,
          brandIds,
          supplierCompanyIds: associatedCompanyIds,
        },
        {
          categoryId,
          categoryItemId,
          ownerCompanyId,
          HasNoBrand,
          HasNoProductCategory,
          OnlyWithoutContacts: checked,
          ContactResponsibilityId: responsibilityId,
          ContactUserId: userId,
        }
      ),
    {
      successMessage: {
        type: "info",
        description: (
          <ShortMsg.FileRequestProcessed formatMessage={intl.formatMessage} />
        ),
      },
    }
  );

  const isExportSelectedTradeItemsLoading = useSelector(
    selectIsExportSelectedProductsLoading
  );
  const [handleExportSelectedTradeItems] = useAsyncFileDownload(
    () => PmdAPI.exportTradeItems({ ids: selectedRowKeys }, { ownerCompanyId }),
    {
      displayLoadingMessage: true,
      loadingMessage: intl.formatMessage({
        id: "general.exportIsInProgress",
      }),
      setIsLoading: value =>
        dispatch(setIsExportSelectedProductsLoading(value)),
      fileExtension: "csv",
    }
  );

  const isExportSelectedManufacturedItemsLoading = useSelector(
    selectIsExportSelectedManufacturedItemsLoading
  );
  const [handleExportSelectedManufacturedItems] = useAsyncFileDownload(
    () =>
      PmdAPI.exportManufacturedItems(
        { tradeItemIds: selectedRowKeys },
        { ownerCompanyId }
      ),
    {
      displayLoadingMessage: true,
      loadingMessage: intl.formatMessage({
        id: "general.exportIsInProgress",
      }),
      setIsLoading: value =>
        dispatch(setIsExportSelectedManufacturedItemsLoading(value)),
      fileExtension: "csv",
    }
  );

  const { categoryId, categoryItemId } = productCategory ?? {};

  const isArchiveSelectedDisabled =
    (!selectedRowKeys.length && !isAllSelected) ||
    isArchiveSelectedLoading ||
    selectedRowKeys.length > 100 ||
    (isAllSelected && totalCount > 100);

  const buttonActions = [
    {
      label: intl.formatMessage({
        id: "general.exportAllProducts",
      }),
      key: "export-all-products",
      onClick: handleExportAllTradeItems,
    },

    {
      label: intl.formatMessage({
        id: "general.exportAllManufacturedItems",
      }),
      key: "export-all-manufactured-items",
      onClick: handleExportAllManufacturedItems,
    },

    ...(productsWrite || productsUpdate
      ? [
          {
            label: isArchiveSelectedLoading ? (
              intl.formatMessage({
                id: "productsListPage.archive",
              })
            ) : (
              <Tooltip
                className="withNoArrow"
                onOpenChange={() =>
                  isArchiveSelectedDisabled
                    ? setVisible(!visible)
                    : setVisible(true)
                }
                placement="top"
                text={intl.formatMessage({
                  id: isArchiveSelectedDisabled
                    ? "productsListPage.archiveDisabled.toolTipTitle"
                    : "productsListPage.archive.toolTipTitle",
                })}
                actions={
                  !isArchiveSelectedDisabled && [
                    {
                      text: intl.formatMessage({ id: "general.cancel" }),
                      onClick: (event: React.FocusEvent<HTMLElement>) => {
                        event.target.blur();
                        setVisible(false);
                      },
                    },
                    {
                      text: intl.formatMessage({ id: "general.confirm" }),
                      onClick: () => {
                        handleArchiveSelectedTradeItems();
                        setVisible(false);
                      },
                    },
                  ]
                }
                visible={visible}
              >
                {intl.formatMessage({
                  id: "productsListPage.archive",
                })}
              </Tooltip>
            ),
            key: "archive-products",
            disabled: isArchiveSelectedDisabled,
          },
        ]
      : []),
    {
      label: intl.formatMessage({
        id: "general.exportSelectedProducts",
      }),
      key: "export-products",
      disabled: !selectedRowKeys.length && !isAllSelected,
      onClick: isAllSelected
        ? handleExportAllTradeItems
        : handleExportSelectedTradeItems,
    },
    {
      label: intl.formatMessage({
        id: "general.exportSelectedManufacturedItems",
      }),
      key: "export-manufactured-items",
      disabled: !selectedRowKeys.length && !isAllSelected,
      onClick: isAllSelected
        ? handleExportAllManufacturedItems
        : handleExportSelectedManufacturedItems,
    },
  ];

  useEffect(() => {
    setVisible(false);
  }, [isArchiveSelectedDisabled]);

  return (
    <Table.Header className={styles.root} listingResult={listingResult}>
      {productsRead && (
        <Button
          loading={
            isExportAllTradeItemsLoading ||
            isExportSelectedTradeItemsLoading ||
            isExportAllManufacturedItemsLoading ||
            isExportSelectedManufacturedItemsLoading ||
            isArchiveSelectedLoading
          }
          // disabled={!selectedRowKeys.length && !isAllSelected}
          type="tertiary"
          hasItems
          items={buttonActions}
          data-test-id="md-products-actions-btn"
        >
          {intl.formatMessage({
            id: "general.actions",
          })}
        </Button>
      )}
    </Table.Header>
  );
};

export default ProductHeader;
