import { Col } from "antd";
import { PageCol } from "pages/Sites/constants";

import Packaging from "./Packaging";

const PackagingInformation = () => {
  return (
    <>
      <Col {...PageCol.FULL}>
        <Packaging />
      </Col>
    </>
  );
};

export default PackagingInformation;
