import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectSupplierDetails = (state: RootState) =>
  state.supplierDetails;

export const selectSupplierDetails = createSelector(
  _selectSupplierDetails,
  ({
    company,
    companyRelation,
    supplierFormData,
    hasError,
    errorStatus,
    isLoading,
    supplierId,
    supplierName,
  }) => ({
    company,
    companyRelation,
    hasError,
    errorStatus,
    supplierFormData,
    supplierId,
    supplierName,
    isLoading,
  })
);

export const selectSupplierDetailsInitiated = createSelector(
  _selectSupplierDetails,
  ({ company, companyRelation }) =>
    Object.keys(company).length !== 0 && !!companyRelation
);
