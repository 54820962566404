import { createSlice } from "@reduxjs/toolkit";

import { fetchClassificationDirectChilds } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "classificationDirectChilds",
  initialState,
  reducers: {
    clearClassificationDirectChilds: () => initialState,
    setLanguageData: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    setEditableData: (state, action) => {
      state.isEditabaleData = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchClassificationDirectChilds.pending, (state, action) => {
        state.isClassificationChildLoading = true;
        state.hasClassificationChildError = false;
        state.classificationErrorStatus = null;
      })
      .addCase(fetchClassificationDirectChilds.fulfilled, (state, action) => {
        state.isClassificationChildLoading = false;
        state.classificationChildList = action.payload;
      })
      .addCase(fetchClassificationDirectChilds.rejected, (state, action) => {
        state.isClassificationChildLoading = false;
        state.hasClassificationChildError = true;
        state.classificationChildList = [];
        state.classificationErrorStatus = (action.payload as any).status;
      });
  },
});

export const {
  clearClassificationDirectChilds,
  setLanguageData,
  setEditableData,
} = slice.actions;
export default slice.reducer;
