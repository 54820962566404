import { useIntl } from "react-intl";

import { UploadFiles } from "@trace-one/business-components";

import useDocumentConfiguration from "shared/hooks/useDocumentConfiguration";

const UploadFile = ({ value, onChange, disabled }) => {
  const intl = useIntl();
  const { filesToAdd, attachments } = value || {};
  const { allowedFileExtensions, maxFileSizeInBytes } =
    useDocumentConfiguration({
      fileType: "proof-of-certification",
    });

  const messages = {
    download: intl.formatMessage({
      id: "general.uploadDragger.messages.download",
    }),
    empty: intl.formatMessage({
      id: "general.uploadDragger.messages.empty",
    }),
    upload: intl.formatMessage({
      id: "general.uploadDragger.messages.upload",
    }),
    fileSize: intl.formatMessage({
      id: "general.uploadDragger.messages.fileSize",
    }),
    generalSize: intl.formatMessage(
      {
        id: "general.uploadDragger.messages.generalSize",
      },
      {
        maxSize: maxFileSizeInBytes / Math.pow(1024, 2),
      }
    ),
    fileType: intl.formatMessage({
      id: "general.uploadDragger.messages.fileType",
    }),
    generalType: intl.formatMessage(
      {
        id: "general.uploadDragger.messages.generalType",
      },
      {
        fileTypes: allowedFileExtensions.join(", "),
      }
    ),
    uploadButtonFromComputer: intl.formatMessage({
      id: "general.uploadDragger.messages.uploadButtonFromComputer",
    }),
    uploadButtonFromLibrary: intl.formatMessage({
      id: "general.uploadDragger.messages.uploadButtonFromLibrary",
    }),
    yes: intl.formatMessage({
      id: "general.uploadDragger.messages.yes",
    }),
    no: intl.formatMessage({
      id: "general.uploadDragger.messages.no",
    }),
    removeTooltipMessage: intl.formatMessage({
      id: "general.uploadDragger.messages.removeTooltipMessage",
    }),
  };
  let list = [];
  if (value?.filesToAdd) {
    list = [
      ...filesToAdd,
      ...attachments.map(file => ({
        id: file.fileId,
        name: file.fileName,
        size: file.fileSize,
      })),
    ];
  }

  return (
    <UploadFiles.Dragger
      disabled={disabled}
      multiple
      fileList={list}
      messages={messages}
      dmsControl={false}
      draggerText={intl.formatMessage({
        id: "general.uploadDragger.title",
      })}
      uploadText={intl.formatMessage({
        id: "general.uploadBtn.title",
      })}
      fileType="proof-of-certification"
      onUploadFiles={files => {
        onChange({
          ...value,
          filesToAdd: [...filesToAdd, ...files],
        });
      }}
      onRemove={removedFile => {
        filesToAdd.find(file => file.id === removedFile.id)
          ? onChange({
              ...value,
              filesToAdd: filesToAdd.filter(
                file => file.uid !== removedFile.uid
              ),
            })
          : onChange({
              ...value,
              attachments: attachments.filter(
                file => file.fileId !== removedFile.id
              ),
            });
      }}
    />
  );
};

export default UploadFile;
