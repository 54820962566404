import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { Languages } from "translations";

import {
  selectUserId,
  selectUserLanguageCode,
} from "reduxStore/user/selectors";

import { LegalMonsterData } from "./models";
import useHeapAnalytic from "./useHeapAnalytic";
import useIntercom from "./useIntercom";

window.onload = function () {
  setInterval(() => {
    const legalMonsterElement = document.querySelectorAll<HTMLElement>(
      ".lm-shield-container"
    )[0];

    if (legalMonsterElement !== undefined) {
      legalMonsterElement.style.setProperty("width", "40px", "important");
      legalMonsterElement.style.setProperty("height", "40px", "important");

      const svgElement = legalMonsterElement.querySelector("svg");
      svgElement.style.setProperty("width", "28px", "important");
      svgElement.style.setProperty("height", "28px", "important");
    }
  }, 200);

  setInterval(() => {
    const intercomElement =
      document.querySelectorAll<HTMLElement>(".intercom-launcher")[0];

    if (intercomElement !== undefined) {
      intercomElement.style.setProperty("width", "40px", "important");
      intercomElement.style.setProperty("height", "40px", "important");
      intercomElement.style.setProperty("bottom", "16px", "important");
      intercomElement.style.setProperty("right", "16px", "important");

      const divSvgElement = intercomElement.querySelector<HTMLElement>("div");
      divSvgElement.style.setProperty("width", "40px", "important");
      divSvgElement.style.setProperty("height", "40px", "important");

      const svgElement = intercomElement.querySelector("svg");
      svgElement.style.setProperty("width", "20px", "important");
      svgElement.style.setProperty("height", "20px", "important");
    }
  }, 200);

  setInterval(() => {
    const frame = document.querySelector<HTMLFrameElement>(
      ".intercom-launcher-frame"
    );

    if (frame !== null) {
      const intercomFrameElement =
        frame.contentWindow.document.querySelectorAll<HTMLElement>(
          ".intercom-launcher"
        )[0];

      if (intercomFrameElement !== undefined) {
        intercomFrameElement.style.setProperty("width", "40px", "important");
        intercomFrameElement.style.setProperty("height", "40px", "important");
        intercomFrameElement.style.setProperty(
          "position",
          "sticky",
          "important"
        );
        intercomFrameElement.style.setProperty("bottom", "16px", "important");
        intercomFrameElement.style.setProperty("right", "16px", "important");

        const divSvgElement =
          intercomFrameElement.querySelector<HTMLElement>("div");
        divSvgElement.style.setProperty("width", "40px", "important");
        divSvgElement.style.setProperty("height", "40px", "important");

        const svgElement = intercomFrameElement.querySelector("svg");
        svgElement.style.setProperty("width", "20px", "important");
        svgElement.style.setProperty("height", "20px", "important");
      }
    }
  }, 200);
};

function parseLanguage(userLanguage) {
  switch (userLanguage) {
    case Languages.FRENCH:
      return "fr-fr";

    default:
      return "en-us";
  }
}

function getCookieSettingsWidgetPublicKey(legalMonsterLanguage) {
  switch (legalMonsterLanguage) {
    case "fr-fr":
      return window.env.LEGAL_MONSTER_COOKIE_WIDGET_FR_ID;

    default:
      return window.env.LEGAL_MONSTER_COOKIE_WIDGET_EN_ID;
  }
}

function useLegalMonsterRef() {
  const userId = useSelector(selectUserId).toUpperCase();
  const userLanguage = parseLanguage(useSelector(selectUserLanguageCode));

  const legalMonsterRef = useRef<LegalMonsterData>({
    userId,
    userLanguage,
  });

  useEffect(() => {
    legalMonsterRef.current.userId = userId;
    legalMonsterRef.current.userLanguage = userLanguage;
  }, [userId, userLanguage]);

  useEffect(() => {
    if (userId) {
      const widgetPublicKey = getCookieSettingsWidgetPublicKey(userLanguage);
      window.legal.widget({
        type: "cookie",
        widgetPublicKey,
        locale: userLanguage,
        identifier: userId,
      });
    }

    return () => {
      document.getElementById("legalmonster-closeButtonPrivacy")?.click();
    };
  }, [userLanguage, userId]);

  return legalMonsterRef;
}

export function useLegalMonster() {
  const legalMonsterRef = useLegalMonsterRef();

  useIntercom(legalMonsterRef);
  useHeapAnalytic(legalMonsterRef);
}
