import { Col, Row } from "antd";
import { PageCol } from "pages/Sites/constants";

import SiteBloc from "./SiteBloc";
import SupplierBloc from "./SupplierBloc";

const SiteIdentification = () => {
  return (
    <>
      {/* <Col {...PageCol.FULL}>
        <TabPage.SectionTitle
          isLined
          isHeader
          label={intl.formatMessage({ id: "siteDetails.siteIdentification" })}
        />
      </Col> */}
      <Row>
        <Col {...PageCol.HALF} style={{ paddingRight: "15px" }}>
          <SiteBloc />
        </Col>
        <Col {...PageCol.HALF} style={{ paddingLeft: "15px" }}>
          <SupplierBloc />
        </Col>
      </Row>
    </>
  );
};

export default SiteIdentification;
