const buildSelectAllHandler =
  ({ setSelectedRowKeys }) =>
  (selected, _, changeRows) => {
    const keys = changeRows.map(({ key }) => key);
    if (selected) {
      setSelectedRowKeys(prev => [...prev, ...keys]);
    } else {
      setSelectedRowKeys(prev => prev.filter(id => !keys.includes(id)));
    }
  };

export default buildSelectAllHandler;
