import { createAsyncThunk } from "@reduxjs/toolkit";

import { CumdAPI } from "apis";

import getAuthorsComments from "shared/utils/getAuthorsComments";
import getCategoriesWithHierarchy from "shared/utils/getCategoriesWithHierarchy";
import getContacts from "shared/utils/getContacts";

export const fetchSupplierDetails = createAsyncThunk(
  "supplierDetails/fetchSupplierDetails",
  async (
    {
      supplierId,
      ownerCompanyId,
      languageCode,
    }: { supplierId: string; ownerCompanyId: string; languageCode: string },
    thunkAPI
  ) => {
    try {
      const [company, rawCompanyRelation] = await Promise.all([
        CumdAPI.getCompanyById(supplierId).then(({ data }) => data),
        CumdAPI.getCompanyRelation(ownerCompanyId, {
          associatedCompanyId: supplierId,
        }).then(({ data }) => data),
      ]);
      const cateoriesId = rawCompanyRelation.categories.map(
        category => category.categoryItemId
      );
      const [comments, contacts, categories] = await Promise.all([
        getAuthorsComments({
          comments: rawCompanyRelation.comments,
        }),
        getContacts(rawCompanyRelation?.contacts),
        await getCategoriesWithHierarchy(cateoriesId, languageCode),
      ]);
      rawCompanyRelation.retailerCustomizedAttributes.sort((a, b) =>
        a.retailerLabel.localeCompare(b.retailerLabel)
      );

      return {
        company,
        companyRelation: {
          ...rawCompanyRelation,
          comments,
          contacts,
          categoriesList: categories,
        },
      };
    } catch (err) {
      const status = err?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({ status });
    }
  }
);
