import { memo, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { CompanySettings } from "@trace-one/api-clients.pmd/dist/models/company-settings";
import { Heading } from "@trace-one/design-system";
import { Form } from "antd";
import { FormListFieldData } from "antd/lib/form/FormList";

import { CumdAPI } from "apis";
import { CompanyData } from "models";

import { selectProductDetailsData } from "reduxStore/productDetails/selectors";
import { setProductFormError } from "reduxStore/productDetails/slice";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import { getSupplierName } from "components/AsyncCompanySelect";
import AsyncSearchSelect, {
  AsyncSearchSelectProps,
} from "components/AsyncSearchSelect";
import { layout } from "components/FormWrapper";
import Input from "components/Input";
import Select from "components/Select";
import { CompanyStatus, ManufacturingItemStatus } from "shared/constants";
import { SelectOption } from "shared/typings";
import buildCompanyTitle from "shared/utils/buildCompanyTitle";

import ManufacturedStatus from "../ManufacturedStatus";

interface ManufacturedItemProps {
  manufacturedField: FormListFieldData;
  isManufacturedExist: boolean;
  disabled: boolean;
  isSupplierCompanyIdDuplicated: boolean;
  countriesOptions: SelectOption[];
  supplierCompanies: CompanyData[];
  manufacturedItemStatusId?: any;
  companySettings?: CompanySettings;
}

type CompanyOption = SelectOption & {
  companyRelationExternalIdentifier?: string;
  companyRelationExternalName?: string;
};

const getCompaniesOptionsForSupplierInput = (
  companies: CompanyData[]
): CompanyOption[] =>
  companies
    .filter(
      ({ companyStatus, isCompanyRelationStatusEnabled }) =>
        companyStatus === CompanyStatus.ENABLED &&
        isCompanyRelationStatusEnabled
    )
    .map(
      ({
        companyId,
        companyDisplayName,
        companyRelationExternalIdentifier,
        companyRelationExternalName,
        isCompanyRelationStatusEnabled,
      }) => {
        const companyName = buildCompanyTitle({
          companyDisplayName,
          companyRelationExternalIdentifier,
          companyRelationExternalName,
        });
        const supplierName = getSupplierName(
          companyName,
          isCompanyRelationStatusEnabled,
          {
            companyRelationExternalName,
            companyDisplayName,
          }
        );

        return {
          value: companyId,
          name: supplierName,
          title: companyName,
          companyRelationExternalIdentifier,
          companyRelationExternalName,
        };
      }
    );

const CompanyAsyncSearchSelect: React.FC<
  AsyncSearchSelectProps & {
    companyActivityId: number;
    isSupplierInput?: boolean;
  }
> = props => {
  const {
    value,
    onChange,
    companyActivityId,
    defaultOptions,
    isSupplierInput,
    error,
    errorMessage,
    errorDataTestId,
    ...rest
  } = props;
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const [searchValue, setSearchValue] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [selectedValue, setSelectedValue] = useState<
    SelectOption &
      {
        companyRelationExternalIdentifier?: string;
        companyRelationExternalName?: string;
      }[]
  >();
  const { productFormError } = useSelector(selectProductDetailsData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) setErrorMsg(error);
    mentionInactive();
  }, [onChange]);

  const mentionInactive = () => {
    const isDisabledInOptions =
      value &&
      defaultOptions.find(
        company => company.value === value.value && company.disabled === true
      );

    if (defaultOptions.length && isDisabledInOptions) {
      setSelectedValue({ ...value, name: isDisabledInOptions.name });
      return;
    }
    if (value) {
      const newSelectedValue = { value: value?.value, label: value?.name };
      setSelectedValue({ ...value, ...newSelectedValue });
    } else {
      setSelectedValue(value);
    }
  };
  const minLengthToSearch = 3;

  return (
    <AsyncSearchSelect
      {...rest}
      error={errorMsg}
      errorMessage={errorMessage}
      errorDataTestId={errorDataTestId}
      defaultOptions={defaultOptions}
      value={selectedValue}
      searchValue={searchValue}
      onChange={(_value, option) => {
        option = option && {
          ...option,
          name: option.title,
          children: option.title,
        };
        setErrorMsg(!option);
        dispatch(
          setProductFormError({ ...productFormError, manufacturedItem: null })
        );
        onChange(option, option);
      }}
      onAsyncSearch={({ searchValue }) => {
        return CumdAPI.getCompaniesByFilters(
          {},
          {
            companyDisplayName: isSupplierInput ? undefined : searchValue,
            searchName: isSupplierInput ? searchValue : undefined,
            relatedOwnerCompanyId: ownerCompanyId,
            companyActivityId,
            isEnabled: true,
            isCompanyRelationStatusEnabled: true,
          }
        ).then(
          ({ data }) =>
            isSupplierInput &&
            getCompaniesOptionsForSupplierInput(data.companies)
        );
      }}
      minLengthToSearch={minLengthToSearch}
      labelInValue
      onSearch={setSearchValue}
    />
  );
};

const ManufacturedItem: React.FC<ManufacturedItemProps> = ({
  manufacturedField,
  isManufacturedExist,
  disabled,
  isSupplierCompanyIdDuplicated,
  countriesOptions,
  supplierCompanies,
  manufacturedItemStatusId,
  companySettings,
}) => {
  const intl = useIntl();

  // const supplierCompanyIdFeedback: FormItemFeedback = isSupplierCompanyIdDuplicated
  //   ? {
  //       hasFeedback: false,
  //       validateStatus: "error",
  //       help: (
  //         <span data-test-id="md-error-manu-item-supplierCompanyId-duplicated">
  //           {intl.formatMessage({
  //             id: "productForm.manufactured.card.supplierCompanyId.duplicated",
  //           })}
  //         </span>
  //       ),
  //     }
  //   : {};

  // const hasError =
  //   productFormError?.manufacturedItem?.length &&
  //   productFormError?.manufacturedItem.find(
  //     mi => mi[1] === manufacturedField.name
  //   );
  return (
    <>
      <Form.Item
        name={[manufacturedField.name, "supplierCompanyId"]}
        label={
          <Heading size="xxs">
            {" "}
            {intl.formatMessage({
              id: "productForm.manufactured.card.supplierCompanyId",
            })}{" "}
          </Heading>
        }
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: "general.valueIsRequired",
            }),
          },
        ]}
        {...layout}
      >
        {/* @ts-ignore */}
        <CompanyAsyncSearchSelect
          error={isSupplierCompanyIdDuplicated}
          errorDataTestId={
            isSupplierCompanyIdDuplicated
              ? "md-error-manu-item-supplierCompanyId-duplicated"
              : "md-error-manu-item-supplierCompanyId-value-is-required"
          }
          errorMessage={
            isSupplierCompanyIdDuplicated &&
            intl.formatMessage({
              id: "productForm.manufactured.card.supplierCompanyId.duplicated",
            })
          }
          data-test-id="md-product-manu-supplierCompanyId"
          disabled={isManufacturedExist || disabled}
          defaultOptions={getCompaniesOptionsForSupplierInput(
            supplierCompanies
          )}
          companyActivityId={2}
          isSupplierInput
        />
      </Form.Item>
      <Form.Item
        name={[manufacturedField.name, "countriesOfOrigin"]}
        label={
          <Heading size="xxs">
            {" "}
            {intl.formatMessage({
              id: "productForm.manufactured.card.countriesOfOrigin",
            })}{" "}
          </Heading>
        }
        {...layout}
      >
        {/* @ts-ignore */}
        <Select
          disabled={disabled}
          data-test-id="md-product-manu-countriesOfOrigin"
          options={countriesOptions}
          showSearch
          mode="multiple"
          filterOption={(input, option) =>
            (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item
        name={[manufacturedField.name, "manufacturedItemName"]}
        label={
          <Heading size="xxs">
            {" "}
            {intl.formatMessage({
              id: "productForm.manufactured.card.manufacturedItemName",
            })}{" "}
          </Heading>
        }
        rules={
          companySettings?.isManufacturedItemNameMandatory && [
            {
              required: true,
              message: intl.formatMessage({
                id: "general.valueIsRequired",
              }),
            },
          ]
        }
        {...layout}
      >
        <Input
          disabled={disabled}
          maxLength={256}
          data-test-id="md-product-manu-manufacturedItemName"
        />
      </Form.Item>
      {manufacturedItemStatusId !== ManufacturingItemStatus.ACTIVE && (
        <Form.Item
          name={[manufacturedField.name, "manufacturedItemStatusId"]}
          label={intl.formatMessage({
            id: "general.status",
          })}
          {...layout}
        >
          <ManufacturedStatus />
        </Form.Item>
      )}
    </>
  );
};

export default memo(ManufacturedItem);
