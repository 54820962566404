import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading } from "@trace-one/design-system";
import BrandFormModal from "pages/Brands/pages/BrandList/BrandFormModal";

import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import { fetchBrandsTable } from "reduxStore/brandList/asyncActions";
import {
  selectBrands,
  selectBrandsSkipAndTakeQueryStats,
  selectIsBrandTableLoading,
} from "reduxStore/brandList/selectors";
import { clearBrands } from "reduxStore/brandList/slice";
import {
  selectUserLanguageCode,
  selectUserOwningCompanyId,
} from "reduxStore/user/selectors";

import Table from "components/Table";
import useSearchFilters from "shared/hooks/useSearchFilters";
import useTablePagination from "shared/hooks/useTablePagination";
import {
  TablePaginationStorage,
  SearchFiltersStorage,
} from "shared/webStorage";

import BrandSearch from "./BrandSearch";
import styles from "./BrandTable.module.less";
import useBrandTable from "./hooks/useBrandTable";
import { BrandFilter } from "./models";

const BrandTable = ({
  isCreateBrandOpen,
  setIsCreateBrandOpen,
  companySettings,
}) => {
  const dispatch = useAppDispatch();
  const { brandsWrite } = usePermissions();
  const intl = useIntl();
  const languageCode = useSelector(selectUserLanguageCode);
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  // const [isCreateBrandOpen, setIsCreateBrandOpen] = useState<boolean>(false);
  const brands = useSelector(selectBrands);
  const skipAndTakeQueryStats = useSelector(selectBrandsSkipAndTakeQueryStats);
  const isLoading = useSelector(selectIsBrandTableLoading);

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const { paginationQuery, setPaginationQuery, resetPageNumber } =
    useTablePagination({
      skipAndTakeQueryStats,
      webStorage: TablePaginationStorage.BRAND,
    });

  const {
    searchText,
    setSearchText,
    filterObj,
    mergeFilters,
    removeAllFiltersAndResetPageNumber,
  } = useSearchFilters<BrandFilter>(
    {
      brandId: undefined,
      archivedStatuses: ["no"],
    },
    {
      clearedFilters: {
        brandId: undefined,
        archivedStatuses: [],
      },
      resetPageNumber,
      setSelectedRowKeys,
      webStorage: brandsWrite ? SearchFiltersStorage.BRAND : undefined,
    }
  );

  const { archivedStatuses } = filterObj;

  const refetchBrands = useCallback(() => {
    const { skip, take } = paginationQuery;
    dispatch(
      fetchBrandsTable({
        searchText,
        ownerCompanyId,
        skip,
        take,
        isActive:
          archivedStatuses.length === 1
            ? archivedStatuses[0] === "no"
            : undefined,
      })
    );
  }, [
    languageCode,
    searchText,
    paginationQuery,
    ownerCompanyId,
    archivedStatuses,
  ]);

  useEffect(() => {
    refetchBrands();
  }, [refetchBrands, filterObj]);

  useEffect(() => {
    return () => {
      dispatch(clearBrands(null));
    };
  }, []);

  const { columns, data } = useBrandTable({
    brands,
    refetchBrands,
    companySettings,
  });

  return (
    <>
      <div className={styles.headActions}>
        <div></div>
      </div>
      <BrandSearch
        filterObj={filterObj}
        mergeFilters={mergeFilters}
        initialSearchValue={searchText}
        onSearch={setSearchText}
        onClearFiltersClick={removeAllFiltersAndResetPageNumber}
      />
      <Table.Header
        listingResult={
          <Heading size="xxs">
            {intl.formatMessage(
              {
                id: "brandList.table.listingResultNumber",
              },
              {
                current: skipAndTakeQueryStats.currentCount ?? 0,
                total: skipAndTakeQueryStats.totalCount ?? 0,
              }
            )}{" "}
          </Heading>
        }
      />

      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        skip={paginationQuery.skip}
        take={paginationQuery.take}
        skipAndTakeQueryStats={skipAndTakeQueryStats}
        setPaginationQuery={setPaginationQuery}
        rowSelection={{
          selectedRowKeys,
        }}
        setSelectedRowKeys={setSelectedRowKeys}
      />

      <BrandFormModal
        visible={isCreateBrandOpen}
        onCancel={() => setIsCreateBrandOpen(false)}
        refetchBrands={refetchBrands}
      />
    </>
  );
};

export default BrandTable;
