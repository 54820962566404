import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Heading, Paragraph, Tooltip } from "@trace-one/design-system";
import { SectionsIds } from "pages/Products/components/ProductForm/constants";

import usePermissions from "core/oidc/usePermissions";

import { selectProductDetailsData } from "reduxStore/productDetails/selectors";

import Label from "components/Label";
import Panel from "components/Panel";
import TabPage from "components/TabPage";
import { TradeItemStatus } from "shared/constants";
import { capitalizeText } from "shared/utils/capitalizeFullText";

import styles from "../../../ProductDetails.module.less";

const Packaging = () => {
  const intl = useIntl();
  const history = useHistory();
  const { productData } = useSelector(selectProductDetailsData);
  const { productsWrite } = usePermissions();
  const productIsDisabled =
    productData.tradeItemStatusId === TradeItemStatus.INACTIVE;

  return (
    <Panel>
      <TabPage.SecondaryTitle
        label={
          <Heading size="xs">
            {capitalizeText(
              intl.formatMessage({ id: "productForm.packaging.title" })
            )}
          </Heading>
        }
      />
      {!productIsDisabled && productsWrite && (
        <Button
          type="link"
          className={styles.iconBtn}
          data-test-id="prodDetails-link-btn"
          iconName="edit"
          iconPlacement="left"
          onClick={() => {
            history.push({
              pathname: `/products/edit/${productData.id}`,
              hash: SectionsIds.PACKAGING,
            });
          }}
        >
          {intl.formatMessage({ id: "general.edit" })}
        </Button>
      )}
      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "productDetails.packagingFormat" })}
          </Heading>
        }
        testId="prodDetails-packagingFormat"
      >
        <Paragraph size="m">
          <Tooltip
            text={productData.packagingFormat}
            showFullText
            placement="top"
          >
            <div className={styles.productDetailsParagraph}>
              {productData.packagingFormat}
            </div>
          </Tooltip>
        </Paragraph>
      </Label>

      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({
              id: "productDetails.packagingNetContentDescription",
            })}
          </Heading>
        }
        testId="prodDetails-packagingNetContentDescription"
      >
        <Paragraph size="m">
          <Tooltip
            text={productData.packagingNetContentDescription}
            showFullText
            placement="top"
          >
            <div className={styles.productDetailsParagraph}>
              {productData.packagingNetContentDescription}
            </div>
          </Tooltip>
        </Paragraph>
      </Label>
    </Panel>
  );
};

export default Packaging;
