import {
  UserApplicationData,
  UserData,
  CompanyFromCollectionData,
} from "models";
import { DEFAULT_LANGUAGE } from "translations";

type State = {
  applications: UserApplicationData[];
  companyActivityNameInEn: string;
  isApplicationsLoading: boolean;
  hasApplicationsError: boolean;
  applicationsCompanies: CompanyFromCollectionData[];
  isUserDetailsLoading: boolean;
  hasUserDetailsError: boolean;
  details: UserData;
};

export const initialState: State = {
  applications: [],
  companyActivityNameInEn: "",
  isApplicationsLoading: true,
  hasApplicationsError: false,
  applicationsCompanies: [],
  isUserDetailsLoading: true,
  hasUserDetailsError: false,
  details: {
    isAccountAdministrator: false,
    owningCompanyId: "",
    userFirstName: "",
    userId: "",
    userJobTitle: "",
    userLanguagePreference: DEFAULT_LANGUAGE,
    userLastName: "",
    userLocked: false,
    userLockedDate: "",
    userLogin: "",
    userNotificationEmail: "",
    userPhone: "",
    userPhotoUrl: "",
    userStatus: "",
    userUnlockedDate: "",
  },
};
