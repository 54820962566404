import { SiteData, SiteRelationData } from "models";

type State = {
  site: {
    data: SiteData;
    isLoading: boolean;
    hasError: boolean;
    errorStatus: any;
  };
  siteRelation: {
    data: SiteRelationData[];
    isLoading: boolean;
    hasError: boolean;
  };
  selectedCertificate: number;
};

export const initialState: State = {
  site: {
    data: {},
    isLoading: false,
    hasError: false,
    errorStatus: null,
  },
  siteRelation: {
    data: [],
    isLoading: false,
    hasError: false,
  },
  selectedCertificate: null,
};
