import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading, UserCard, TagV1 as Tag } from "@trace-one/design-system";

import { selectTeamMemberResponsibilities } from "reduxStore/shared/selectors";
import { selectSupplierDetails } from "reduxStore/SupplierDetails/selectors";

import Label from "components/Label";
import TabPage from "components/TabPage";
import { UserStatus } from "shared/constants";

import styles from "../../../SupplierDetails/SupplierDetails.module.less";

interface CompanyRelationProps {}

const CompanyStatusTag = ({ type }) => {
  const intl = useIntl();
  return (
    <Tag
      label={intl.formatMessage({
        id: type ? "general.active" : "general.inactive",
      })}
      color={type ? "green" : "grey"}
      mode="light"
    />
  );
};

const CompanyRelation: React.FC<CompanyRelationProps> = () => {
  const intl = useIntl();
  const { companyRelation } = useSelector(selectSupplierDetails);
  const teamReponsabilities = useSelector(selectTeamMemberResponsibilities);

  return (
    <div>
      <TabPage.SecondaryTitle
        label={
          <Heading size="xs">
            {intl.formatMessage({ id: "supplierDetails.companyRelation" })}
          </Heading>
        }
      />
      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({
              id: "supplierDetails.relationStatus",
            })}
          </Heading>
        }
        testId="supplierDetails-relationStatus"
      >
        <div style={{ width: "100px" }}>
          <CompanyStatusTag type={companyRelation.enabled} />
        </div>
      </Label>
      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "general.contacts" })}
          </Heading>
        }
        testId="supplierDetails-contacts"
      >
        <div className="d-flex flex-fill flex-wrap">
          {companyRelation.contacts?.map((contact, idx) => {
            const isUserInactive = contact.userStatus !== UserStatus.ENABLED;
            const userResponsabilty = teamReponsabilities.find(
              resp => contact.responsibilityId === resp.id
            );
            return (
              <div key={idx} className={styles.userCardWraper}>
                <UserCard
                  size="xs"
                  disabled={isUserInactive}
                  name={contact.userName}
                  title={userResponsabilty?.text}
                  photoUrl={contact.userPhotoUrl}
                  inactiveTooltipText={intl.formatMessage({
                    id: "general.userInactive",
                  })}
                ></UserCard>
              </div>
            );
          })}
        </div>
      </Label>
    </div>
  );
};

export default CompanyRelation;
