import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { TableColumnsType } from "antd";
import cn from "classnames";

import usePermissions from "core/oidc/usePermissions";

import {
  selectClassificationCompanyId,
  selectGenericCategory,
} from "reduxStore/classificationDetails/selectors";
import { selectClassificationDirectChildsItems } from "reduxStore/classificatonDirectChilds/selectors";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import Icon from "components/Icon";
import IconButton from "components/IconButton";
import Input from "components/Input";

import styles from "../CategoryTranslationTable.module.less";
import FlagIcon from "../FlagIcon";
import { CategoryTranslationColumn, Translation } from "../models";

const useCategoryTranslationTable = ({
  value,
  languagesMap,
  handleTranslationChange,
  handleRemoveItem,
}: {
  value: Translation[];
  languagesMap: { [itemCode: string]: string };
  handleTranslationChange: (
    languageItemCode: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveItem: (languageItemCode: string) => () => void;
}) => {
  const { formatMessage } = useIntl();
  const { categoryListsWrite } = usePermissions();
  const userCompanyId = useSelector(selectUserOwningCompanyId);
  const classificationCompanyId = useSelector(selectClassificationCompanyId);
  const isSameCompanyID: boolean = userCompanyId === classificationCompanyId;
  const isGenericCategory = useSelector(selectGenericCategory);
  const { selectedLanguage, isEditabaleData } = useSelector(
    selectClassificationDirectChildsItems
  );

  const ActionColumn = [
    {
      title: formatMessage({ id: "classification.category.remove" }),
      dataIndex: "remove",
      key: "remove",
      render: (_, record) => {
        return (
          <IconButton
            onClick={handleRemoveItem(record.raw.languageItemCode)}
            // className={styles.deleteBtn}
            data-test-id="md-language-remove"
            disabled={toDisabled}
          >
            <Icon name="trash" color="primary" size="medium" useTOLibrary />
          </IconButton>
        );
      },
      width: 80,
    },
  ];

  const toDisabled =
    !categoryListsWrite || isGenericCategory || !isSameCompanyID;
  const columns: TableColumnsType<CategoryTranslationColumn> = [
    {
      title: formatMessage({ id: "classification.category.language" }),
      dataIndex: "languageText",
      key: "languageText",
      render: (value, record) => (
        <div className={styles.languageFlagWrapper}>
          <FlagIcon languageCode={record.raw.languageItemCode} />
          <span style={{ marginLeft: 8 }}>{value}</span>
        </div>
      ),
    },
    {
      title: formatMessage({ id: "classification.category.text" }),
      dataIndex: "text",
      key: "text",
      render: (value, record) => {
        const isDisabled = selectedLanguage === record.key;
        return (
          <div>
            <Input
              value={value}
              onChange={handleTranslationChange(record.raw.languageItemCode)}
              maxLength={256}
              autoFocus={!isEditabaleData}
              className={cn({
                [styles.textAreaRequired]: !value && !isDisabled,
              })}
              data-test-id="md-language-text-area"
              disabled={toDisabled}
            />
            {!value && !isDisabled && (
              <div className={styles.valueIsRequired}>
                <span style={{ fontSize: "12px" }}>
                  {formatMessage({ id: "general.valueIsRequired" })}{" "}
                </span>
              </div>
            )}
          </div>
        );
      },
    },
    ...(categoryListsWrite ? ActionColumn : []),
  ];

  const data: CategoryTranslationColumn[] = value.map(value => ({
    key: value.languageItemCode,
    languageText: languagesMap[value.languageItemCode],
    text: value.text,
    raw: value,
  }));

  return { columns, data };
};

export default useCategoryTranslationTable;
