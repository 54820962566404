import { createAsyncThunk } from "@reduxjs/toolkit";

import { RlmdAPI } from "apis";

export const fetchClassificationDirectChilds = createAsyncThunk(
  "classificationDirectChilds/fetchClassificationDirectChilds",
  async (
    {
      classificationId,
      languageCode,
      parentId,
      includeInactive,
    }: {
      classificationId: string;
      languageCode: string;
      parentId?: string;
      includeInactive?: boolean;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await RlmdAPI.getCategoryItemsByIdInDirectChildIds(
        classificationId,
        {
          languageCode,
          parentId,
          includeInactive,
        }
      );
      return data;
    } catch (err) {
      const status = err?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({ status });
    }
  }
);
