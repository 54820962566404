import { Children } from "react";

import cn from "classnames";

import styles from "./Label.module.less";

interface LabelProps {
  label: React.ReactNode;
  children?: React.ReactNode;
  bordered?: boolean;
  testId?: string;
}

const Label: React.FC<LabelProps> = ({ label, children, bordered, testId }) => {
  const hasChildren =
    !!children &&
    !!Children.count(children) &&
    !!Children.toArray(children).length;

  const dataTestId = {
    container: testId && `${testId}-container`,
    label: testId && `${testId}-label`,
    value: testId && `${testId}-value`,
  };

  return (
    <div className={styles.labelWrapper} data-test-id={dataTestId.container}>
      <div className={styles.label} data-test-id={dataTestId.label}>
        {label}
      </div>
      <div
        className={cn(styles.value, {
          [styles.bordered]: bordered && hasChildren,
        })}
        data-test-id={dataTestId.value}
      >
        {hasChildren ? children : "-"}
      </div>
    </div>
  );
};

export default Label;
