import { createAsyncThunk } from "@reduxjs/toolkit";

import { RlmdAPI } from "apis";

import { CategoryTypeInput } from "shared/constants";

export const fetchClassificationList = createAsyncThunk(
  "classificationList/fetchClassificationList",
  async ({
    companyId,
    includeInactive,
  }: {
    companyId: string;
    includeInactive?: boolean;
  }) => {
    const { data: classifications } = await RlmdAPI.getCategoryListsByCompanyId(
      {
        companyId,
        categoryType: CategoryTypeInput.PRODUCT,
        includeGlobal: true,
        includeInactive,
      }
    );

    const result = [...classifications].sort((a, b) => {
      const dateDifference =
        new Date(b.updatedDate ?? 0).getTime() -
        new Date(a.updatedDate ?? 0).getTime();
      if (dateDifference !== 0) {
        return dateDifference;
      }
      return a.categoryListName.localeCompare(b.categoryListName);
    });

    return result;
  }
);
