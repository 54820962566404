import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Button, Tooltip } from "@trace-one/design-system";

import { SmdAPI } from "apis";

import { selectIsRetailer } from "reduxStore/oidc/selectors";

import ShortMsg from "components/ShortMsg";
import Table from "components/Table";
import useAsyncFileDownload from "shared/hooks/useAsyncFileDownload";
import useAsyncHandler from "shared/hooks/useAsyncHandler";

import styles from "./SiteHeader.module.less";

interface SiteHeaderProps {
  listingResult?: React.ReactNode;
  selectedRowKeys?: string[];
  ownerCompanyId?: string;
  filterObj?: {
    associatedCompanyId?: string;
    productCategory?: { categoryId: string; categoryItemId: string };
    siteCountry?: string;
    siteActive?: string[];
    checked: boolean;
    responsibilityId?: string;
    userId?: string;
  };
  searchText?: string;
  sitesRead?: boolean;
  isAllSelected?: boolean;
}

const SiteHeader: React.FC<SiteHeaderProps> = props => {
  const {
    listingResult,
    selectedRowKeys,
    ownerCompanyId,
    filterObj,
    searchText,
    sitesRead,
    isAllSelected,
  } = props;

  const intl = useIntl();

  const {
    associatedCompanyId,
    siteCountry,
    siteActive,
    productCategory,
    checked: onlyWithoutContacts,
    userId: contactUserId,
    responsibilityId: contactResponsibilityId,
  } = filterObj;
  const { categoryId, categoryItemId } = productCategory ?? {};
  const isRetailer = useSelector(selectIsRetailer);
  const isExportDisabled = !selectedRowKeys.length && !isAllSelected;
  const [visible, setVisible] = useState<boolean>(false);

  let handleExportAllSites: (...args: any[]) => Promise<any>;
  let isExportAllLoading: boolean;

  if (isRetailer) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    [handleExportAllSites, isExportAllLoading] = useAsyncHandler(
      () => {
        return SmdAPI.exportSiteRelationsInBulk(
          {},
          {
            searchText,
            associatedCompanyId: ownerCompanyId,
            categoryId,
            categoryItemId,
            siteCountry,
            ownerCompanyRelationStatus:
              siteActive.length && siteActive.length < 2
                ? siteActive.includes("true")
                : undefined,
            onlyWithoutContacts,
            contactUserId: contactUserId || undefined,
            contactResponsibilityId: contactResponsibilityId || undefined,
          }
        );
      },
      {
        successMessage: {
          type: "info",
          description: (
            <ShortMsg.FileRequestProcessed formatMessage={intl.formatMessage} />
          ),
        },
      }
    );
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    [handleExportAllSites, isExportAllLoading] = useAsyncFileDownload(
      () => {
        return SmdAPI.exportSites(
          {},
          {
            ownerCompanyId,
            associatedCompanyId,
            siteCountry,
            searchText,
            siteActive:
              siteActive.length && siteActive.length < 2
                ? siteActive.includes("true")
                : undefined,
          }
        );
      },
      {
        displayLoadingMessage: true,
        loadingMessage: intl.formatMessage({
          id: "general.exportIsInProgress",
        }),
        fileExtension: "csv",
      }
    );
  }

  const [handleExportSelectedSites, isExportSelectedLoading] =
    useAsyncFileDownload(
      () => {
        if (isRetailer) {
          return SmdAPI.exportSiterelations(
            { ids: selectedRowKeys },
            { associatedCompanyId: ownerCompanyId }
          );
        } else {
          return SmdAPI.exportSites(
            { ids: selectedRowKeys },
            { ownerCompanyId }
          );
        }
      },
      {
        displayLoadingMessage: true,
        loadingMessage: intl.formatMessage({
          id: "general.exportIsInProgress",
        }),
        fileExtension: "csv",
      }
    );

  return (
    <Table.Header listingResult={listingResult}>
      {sitesRead && (
        <Tooltip
          onOpenChange={() => isExportDisabled && setVisible(!visible)}
          placement="left"
          text={intl.formatMessage({
            id: "sitesListPage.export.toolTipTitle",
          })}
          visible={isExportDisabled && visible}
        >
          <div data-test-id="tooltipBtn" className={styles.action}>
            <Button
              onClick={
                isAllSelected ? handleExportAllSites : handleExportSelectedSites
              }
              loading={isExportAllLoading || isExportSelectedLoading}
              disabled={isExportDisabled}
              type="tertiary"
              data-test-id="md-site-export-btn"
            >
              {intl.formatMessage({
                id: "general.export",
              })}
            </Button>
          </div>
        </Tooltip>
      )}
    </Table.Header>
  );
};

export default SiteHeader;
