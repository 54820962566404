import Icon from "components/Icon";

const FileRequestProcessed = ({ formatMessage }) => {
  return (
    <p>
      {formatMessage({
        id: "general.fileRequestProcessed",
      })}{" "}
      <Icon name="notifications" useTOLibrary stroke="white" size={18} />
    </p>
  );
};

export default FileRequestProcessed;
