import { CompanyData, CompanyRelationData } from "models";

import { AuthorsCommentsType } from "shared/utils/getAuthorsComments";
import { GetContactResut } from "shared/utils/getContacts";

export type CompanyRelationState = Omit<
  CompanyRelationData,
  "comments" | "contacts"
> & {
  comments: AuthorsCommentsType;
  contacts: GetContactResut[];
};

type State = {
  isLoading: boolean;
  company: CompanyData;
  companyRelation: CompanyRelationState;
  hasError: boolean;
  errorStatus: number;
};

export const initialState: State = {
  isLoading: false,
  company: null,
  companyRelation: null,
  hasError: false,
  errorStatus: null,
};
