/* istanbul ignore file */
import { memo, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { Button, Heading, Tag } from "@trace-one/design-system";
import { Card } from "@trace-one/react-components";
import { Form } from "antd";
import moment from "moment";

import { SmdAPI } from "apis";

import { useAppDispatch } from "reduxStore";
import {
  selectCertificateOptions,
  selectCertificateTypeOptions,
} from "reduxStore/shared/selectors";
import { selectSiteSupplierFormError } from "reduxStore/siteSupplierDetails/selectors";
import { setSiteCertificateFormData } from "reduxStore/siteSupplierDetails/slice";
import { selectedCertificate } from "reduxStore/siteSupplierForm/selectors";

import FormWrapper from "components/FormWrapper";
import { layout } from "components/FormWrapper";
import Input from "components/Input";
import RangePicker from "components/RangePicker";
import Select from "components/Select";

import { SectionsIds } from "../../../../constants";
import getCertificateStatus from "../../../utils/getCertificateStatus";

import styles from "./CertificateSection.module.less";
import CertificateAction from "./components/CertificateAction";
import UploadFile from "./UploadFile";

const CERTIFICATION_TYPE_OTHER_ID = "656fd25d-0a8b-4781-8d09-4bd045dcc186";
const CERTIFICATION_ID_OTHER_ID = "0252758d-53f5-49f4-9623-794dc60eff75";
const cardClassName = [
  "ant-card-collapsible-head-content",
  "collapsible-title",
  "ant-card-collapsible-head-icon",
  "ant-card-collapsible-head-icon ant-card-collapsible-head-icon-open",
];

const CertificateSection = ({
  form,
  fetchCertificateSubLists,
  disabled,
  certificateSublists = {},
  isEditForm,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const certificates = useSelector(selectCertificateOptions);
  const certificateTypes = useSelector(selectCertificateTypeOptions);

  const { siteId } = useParams();
  const history = useHistory();
  const siteSupplierFormError = useSelector(selectSiteSupplierFormError);
  const selectedCertficate = useSelector(selectedCertificate);

  const getUpdateData = async () => {
    const { data } = await SmdAPI.getSiteById(siteId);
    dispatch(setSiteCertificateFormData(data.siteCertificates));
  };

  if (history.location.hash) {
    getUpdateData();
  }

  useEffect(() => {
    if (siteId) getUpdateData();
    if (siteSupplierFormError?.certificate) {
      setExpandData(siteSupplierFormError);
    }
  }, [siteSupplierFormError]);

  const urlHash = history.location.hash?.replace("#", "");

  useEffect(() => {
    const fields = form.getFieldsValue();

    if (
      !!fields.siteCertificates &&
      urlHash === "site-certificates-section" &&
      isEditForm
    ) {
      let editedData = [];
      if (
        fields.siteCertificates.length > 0 &&
        fields.siteCertificates[0]?.certificateKeyId !== undefined
      ) {
        let data = fields.siteCertificates;
        editedData = [
          {
            attachments: [],
            typeId: null,
            certificationId: null,
            fileManager: {
              filesToAdd: [],
              attachments: [],
            },
          },
          ...data,
        ];
        form.setFieldsValue({ siteCertificates: editedData });
      }
      if (fields.siteCertificates.length === 0) {
        editedData = [
          {
            attachments: [],
            typeId: null,
            certificationId: null,
            fileManager: {
              filesToAdd: [],
              attachments: [],
            },
          },
        ];
        form.setFieldsValue({ siteCertificates: editedData });
      }
    }
  }, []);

  useEffect(() => {
    const fieldValue = form.getFieldsValue();
    const urlCertficateId = history.location.hash?.split(":")[1];
    const id = selectedCertficate ?? urlCertficateId;
    const index = fieldValue.siteCertificates.findIndex(
      item => item.certificateKeyId === id
    );
    const finalIndex = index === -1 ? 0 : index;
    setTimeout(
      () =>
        form.scrollToField(["siteCertificates", finalIndex, "typeId"], {
          behavior: "smooth",
          block: "center",
        }),
      100
    );
  }, []);

  useEffect(() => {
    const { siteCertificates } = form.getFieldsValue();
    const editedData = siteCertificates.map(certificate => ({
      ...certificate,
      typeIdDisabled: !!certificate.typeId,
      certificationIdDisabled: !!certificate.certificationId,
    }));
    form.setFieldsValue({ siteCertificates: editedData });
  }, []);

  const setExpandData = errors => {
    form.validateFields();
    form.scrollToField(errors.certificate[0], {
      behavior: "smooth",
      block: "center",
    });
  };

  const getSubList = ({ typeId }) => {
    const sublist = Array.isArray(certificateSublists[typeId])
      ? certificateSublists[typeId]?.map(c => ({
          value: c.id,
          name: c.text,
        }))
      : certificates;

    return sublist;
  };

  const getSubListWithoutOutdatedIFSCertificates = ({ typeId }) => {
    const disabledCertifications = new Set(["IFS 4", "IFS 5", "IFS 6"]);
    return getSubList({ typeId }).filter(
      ({ name }) => !disabledCertifications.has(name)
    );
  };

  const handleArchiveCertificate = ind => {
    const date = moment();
    const fields = form.getFieldsValue();
    fields.siteCertificates.forEach((field, index) => {
      if (field.certificateKeyId === ind) {
        fields.siteCertificates[index] = {
          ...fields.siteCertificates[index],
          certificateDates: [null, date],
        };
      }
    });
    form.setFieldsValue(fields);
  };

  const handleExpandCard = (event, index, expand) => {
    const fields = form.getFieldsValue();

    if (
      expand &&
      (cardClassName.includes(event.target.className) ||
        cardClassName.includes(event.target.className.baseVal))
    ) {
      if (fields?.siteCertificates?.length) {
        fields.siteCertificates[index] = {
          ...fields.siteCertificates[index],
          expand: false,
        };
      }
      form.setFieldsValue(fields);
    }
  };
  return (
    <FormWrapper.Section
      title={
        <Heading size="m">
          {" "}
          {intl.formatMessage({ id: "siteForm.certificatesTitle" })}
        </Heading>
      }
      id={SectionsIds.CERTIFICATES}
    >
      <Form.Item shouldUpdate wrapperCol={{ span: 24 }}>
        {() => (
          <Form.List name="siteCertificates">
            {(fields, { add, remove }, { errors }) => {
              return (
                <>
                  {fields.map((field, index) => {
                    const currentKey = ["siteCertificates", field.name];
                    const {
                      typeId,
                      certificationId,
                      certificateLabel,
                      certificateKeyId,
                      certificateDates,
                      typeIdDisabled,
                      certificationIdDisabled,
                      expand,
                    } = form.getFieldValue(currentKey);
                    const toExpand =
                      siteSupplierFormError?.certificate?.length &&
                      index === parseInt(siteSupplierFormError?.certificate[0]);
                    let certifTitle = "";
                    const typeLabel =
                      certificateTypes.find(c => c.value === typeId)?.name ??
                      "";

                    if (typeLabel) {
                      if (typeId === CERTIFICATION_TYPE_OTHER_ID) {
                        certifTitle = !!certificateLabel
                          ? certificateLabel
                          : typeLabel;
                      } else {
                        certifTitle = typeLabel;
                      }
                    }

                    const {
                      label: endDate,
                      color,
                      isNone,
                    } = getCertificateStatus({
                      intl,
                      date: certificateDates?.length && certificateDates[1],
                    });

                    let error = { typeId: false, certificationId: false };
                    siteSupplierFormError?.certificate?.forEach(ce => {
                      if (index === ce[1]) error[ce[2]] = true;
                    });

                    const urlCertficateId =
                      history.location.hash?.split(":")[1];
                    const id = selectedCertficate ?? urlCertficateId;
                    const toBeEpand = id === certificateKeyId;
                    const iscertificateKeyIdExist = !certificateKeyId;
                    const sublist = iscertificateKeyIdExist
                      ? getSubListWithoutOutdatedIFSCertificates({ typeId })
                      : getSubList({ typeId });

                    return (
                      <Card
                        key={field.key}
                        data-test-id={`SCCard-${index}`}
                        title={
                          <>
                            <Heading size="xs">{certifTitle}</Heading>
                            {!isNone && !!certifTitle && (
                              <div style={{ width: 100 }}>
                                {endDate === "Expires Soon" ? (
                                  <div>
                                    <Tag
                                      label={endDate}
                                      color={color}
                                      size="small"
                                      mode="light"
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <Tag
                                      label={endDate}
                                      color={color}
                                      size="small"
                                      mode="light"
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </>
                        }
                        collapsible
                        expand={
                          expand ||
                          toExpand ||
                          toBeEpand ||
                          iscertificateKeyIdExist
                        }
                        onClick={event =>
                          handleExpandCard(event, index, expand)
                        }
                        extra={
                          <CertificateAction
                            endDate={certificateDates}
                            isEditForm={isEditForm}
                            handleArchiveCertificate={handleArchiveCertificate}
                            disabled={disabled}
                            onRemoveCertificateField={() => {
                              remove(field.name);
                            }}
                            certificateKeyId={certificateKeyId}
                            fields={fields}
                            // data-test-id={`md-site-certificates-action`}
                          />
                        }
                        style={{ marginBottom: 15 }}
                      >
                        <div style={{ padding: 20 }}>
                          <Form.Item
                            name={[field.name, "typeId"]}
                            rules={[
                              {
                                required: true,
                                message: "",
                              },
                            ]}
                            label={
                              <Heading size="xxs">
                                {" "}
                                {intl.formatMessage({
                                  id: "siteForm.certificateType",
                                })}
                              </Heading>
                            }
                            {...layout}
                          >
                            <Select
                              showSearch
                              error={
                                typeId === undefined ||
                                (!typeId && error.typeId)
                              }
                              errorDataTestId="md-error-certificateType-is-required"
                              errorMessage={intl.formatMessage({
                                id: "general.valueIsRequired",
                              })}
                              disabled={disabled || typeIdDisabled}
                              allowClear
                              data-test-id="md-site-certificate-type-select"
                              style={{ height: 42, width: "100%" }}
                              onChange={(_value, parentId) => {
                                const fields = form.getFieldsValue();

                                fetchCertificateSubLists([_value]).then(
                                  sublist => {
                                    fields.siteCertificates[index] = {
                                      ...fields.siteCertificates[index],
                                      certificationId:
                                        sublist[parentId?.key][0]?.id,
                                    };
                                    form.setFieldsValueAndMarkDirty(fields);
                                    form.validateFields();
                                  }
                                );
                              }}
                              options={certificateTypes}
                              filterOption={(input, option) =>
                                (option?.name ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, "certificationId"]}
                            label={
                              <Heading size="xxs">
                                {intl.formatMessage({
                                  id: "siteForm.certificateName",
                                })}{" "}
                              </Heading>
                            }
                            rules={[
                              {
                                required: true,
                                message: "",
                              },
                            ]}
                            {...layout}
                          >
                            <Select
                              showSearch
                              error={
                                certificationId === undefined ||
                                (!certificationId && error.certificationId)
                              }
                              errorDataTestId="md-error-certificateName-is-required"
                              errorMessage={intl.formatMessage({
                                id: "general.valueIsRequired",
                              })}
                              allowClear
                              disabled={disabled || certificationIdDisabled}
                              onChange={() => {
                                form.validateFields();
                              }}
                              data-test-id="md-site-certificate-select"
                              style={{ height: 42, width: "100%" }}
                              options={sublist}
                              filterOption={(input, option) =>
                                (option?.name ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, "certificateLabel"]}
                            label={
                              <Heading size="xxs">
                                {" "}
                                {intl.formatMessage({
                                  id: "siteForm.certificateLabel",
                                })}
                              </Heading>
                            }
                            rules={[
                              {
                                required:
                                  certificationId === CERTIFICATION_ID_OTHER_ID,
                                message: "",
                              },
                            ]}
                            validateTrigger={["onChange", "onBlur"]}
                            {...layout}
                          >
                            <Input
                              error={
                                certificationId === CERTIFICATION_ID_OTHER_ID &&
                                (certificateLabel === "" ||
                                  certificateLabel === undefined)
                              }
                              errorDataTestId="md-error-certificateLabel-is-required"
                              errorMessage={intl.formatMessage({
                                id: "general.valueIsRequired",
                              })}
                              disabled={disabled || !iscertificateKeyIdExist}
                              maxLength={256}
                              data-test-id="md-site-certificate-label"
                            />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, "certificateVersion"]}
                            label={
                              <Heading size="xxs">
                                {" "}
                                {intl.formatMessage({
                                  id: "siteForm.certificateVersion",
                                })}
                              </Heading>
                            }
                            {...layout}
                          >
                            <Input
                              disabled={disabled || !iscertificateKeyIdExist}
                              maxLength={256}
                              data-test-id="md-site-certificate-version"
                            />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, "certificateGrade"]}
                            label={
                              <Heading size="xxs">
                                {intl.formatMessage({
                                  id: "siteForm.certificateGrade",
                                })}{" "}
                              </Heading>
                            }
                            {...layout}
                          >
                            <Input
                              disabled={disabled}
                              maxLength={256}
                              data-test-id="md-site-certificate-grade"
                            />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, "certificateDates"]}
                            label={
                              <Heading size="xxs">
                                {" "}
                                {intl.formatMessage({
                                  id: "siteForm.certificateDate",
                                })}
                              </Heading>
                            }
                            {...layout}
                          >
                            <RangePicker
                              disabled={disabled}
                              data-test-id="md-certification-dates"
                              style={{ width: "100%" }}
                              allowEmpty={[true, true]}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, "fileManager"]}
                            className={styles.uploadDragger}
                            label={
                              <Heading size="xxs">
                                {" "}
                                {intl.formatMessage({
                                  id: "siteForm.certificateFile",
                                })}
                              </Heading>
                            }
                            {...layout}
                          >
                            <UploadFile disabled={disabled} />
                          </Form.Item>
                        </div>
                      </Card>
                    );
                  })}
                  <Button
                    style={{ float: "right" }}
                    data-test-id="md-certificate-add"
                    disabled={disabled}
                    onClick={() =>
                      add({
                        attachments: [],
                        fileManager: {
                          filesToAdd: [],
                          attachments: [],
                        },
                        typeId: null,
                        certificationId: null,
                        startDate: null,
                        endDate: null,
                        expand: true,
                      })
                    }
                    type="secondary"
                  >
                    {intl.formatMessage({ id: "siteForm.addCertificate" })}
                  </Button>
                </>
              );
            }}
          </Form.List>
        )}
      </Form.Item>
    </FormWrapper.Section>
  );
};

export default memo(CertificateSection);
