import { CompanySettings } from "@trace-one/api-clients.pmd/dist/models/company-settings";

import { FormInstanceType } from "shared/hooks/useAntForm";

import { ProductFormValues } from "../../models";
import IdentificationSection from "../IdentificationSection";
import RetailerAttributesSection from "../RetailerAttributesSection";

interface ProductInformationTabProps {
  submitResult: any;
  isEditForm: boolean;
  initialValues: ProductFormValues;
  disabled: boolean;
  form: FormInstanceType<ProductFormValues>;
  companySettings?: CompanySettings;
}
const ProductInformation: React.FC<ProductInformationTabProps> = ({
  submitResult,
  isEditForm,
  initialValues,
  disabled,
  form,
  companySettings,
}) => {
  return (
    <>
      <IdentificationSection
        submitResult={submitResult}
        isEditForm={isEditForm}
        initialValues={initialValues}
        disabled={disabled}
        form={form}
        companySettings={companySettings}
      />
      <RetailerAttributesSection
        submitResult={submitResult}
        disabled={disabled}
      />
    </>
  );
};

export default ProductInformation;
