export default function getUniqueListFactory<Item>(
  filterCb: (item: Item, arr: Item[], index: number) => boolean
) {
  return (list: Item[]) => {
    const arr: Item[] = [];
    list.forEach((item, index) => {
      if (filterCb(item, arr, index)) {
        arr.push(item);
      }
    });
    return arr;
  };
}
