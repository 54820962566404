import { memo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading } from "@trace-one/design-system";
import { Form } from "antd";

import { selectTeamMemberResponsibilities } from "reduxStore/shared/selectors";

import ContactsInputGroup from "components/ContactsInputGroup";
import FormWrapper from "components/FormWrapper";

import { SectionsIds } from "../../constants";

import AttributesInput from "./AttributesInput";

const RetailerAttributesSection: React.FC<{
  disabled: boolean;
  submitResult: any;
}> = ({ disabled, submitResult }) => {
  const intl = useIntl();
  const teamMemberResponsibilities = useSelector(
    selectTeamMemberResponsibilities
  );

  return (
    <FormWrapper.Section
      id={SectionsIds.ATTRIBUTES}
      title={
        <Heading size="m">
          {intl.formatMessage({ id: "general.privateInformation" })}{" "}
        </Heading>
      }
    >
      <Form.Item
        name="retailerCustomizedAttributes"
        label={
          <Heading size="xxs">
            {" "}
            {intl.formatMessage({ id: "general.privateAttributes" })}{" "}
          </Heading>
        }
      >
        <AttributesInput disabled={disabled} />
      </Form.Item>
      <span id="contact-scroller"></span>
      <Form.Item
        name="contacts"
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "general.contacts" })}{" "}
          </Heading>
        }
      >
        <ContactsInputGroup
          withErrors={submitResult.contacts?.withErrors}
          teamMemberResponsibilities={teamMemberResponsibilities}
          disabled={disabled}
          withUserStatusMention
        />
      </Form.Item>
    </FormWrapper.Section>
  );
};

export default memo(RetailerAttributesSection);
