import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { UserCard } from "@trace-one/design-system";

import { ReferenceListItemData } from "models";

import CustomInputGroup from "components/CustomInputGroup";
import Select from "components/Select";
import UserAsyncSearchSelect from "components/UserAsyncSearchSelect";
import { UserStatus } from "shared/constants";

import styles from "./ContactsInputGroup.module.less";

type Contact = {
  userId?: string;
  responsibilityId?: string;
  userName?: string;
  userStatus?: string;
  userPhotoUrl?: string;
};

type UserError = {
  userId: string;
  responsibilityId?: string;
};

interface ContactsInputGroupProps {
  value?: Contact[];
  onChange?: (value: Contact[]) => void;
  disabled?: boolean;
  teamMemberResponsibilities: ReferenceListItemData[];
  withUserStatusMention?: boolean;
  withErrors?: UserError[];
}

export function getUserHasNotResponsabilityError(errorMessage: string) {
  const errorMessageSplit = errorMessage.split(" ");

  const withErrors: UserError[] = [
    {
      userId: errorMessageSplit[0].split(":")[1],
      responsibilityId: errorMessageSplit[1].split(":")[1],
    },
  ];
  return {
    contacts: {
      withErrors,
    },
  };
}

export function getUserInactiveError(errorMessage: string) {
  const errorMessageSplit = errorMessage.split(" ");

  const withErrors: UserError[] = [
    {
      userId: errorMessageSplit[0].split(":")[1],
    },
  ];
  return {
    contacts: {
      withErrors,
    },
  };
}

const ContactsInputGroup: React.FC<ContactsInputGroupProps> = ({
  value: contacts,
  onChange,
  disabled,
  teamMemberResponsibilities,
  withUserStatusMention,
  withErrors,
}) => {
  const intl = useIntl();
  const teamMemberResponsibilitiesOptions = useMemo(() => {
    return teamMemberResponsibilities.map(({ id, text }) => ({
      value: id,
      name: text,
    }));
  }, [teamMemberResponsibilities]);
  const teamMemberResponsibilitiesMap = useMemo(() => {
    return teamMemberResponsibilities.reduce<{
      [responsibilityId: string]: ReferenceListItemData;
    }>((prev, current) => ({ ...prev, [current.id]: current }), {});
  }, [teamMemberResponsibilities]);

  const [newContact, setNewContact] = useState<Contact>({
    responsibilityId: undefined,
    userId: undefined,
    userName: undefined,
  });

  const isDuplicated = !!contacts.find(
    item =>
      item.responsibilityId === newContact.responsibilityId &&
      item.userId === newContact.userId
  );

  const isAddBtnDisabled =
    !newContact.responsibilityId ||
    !newContact.userId ||
    isDuplicated ||
    disabled;

  return (
    <CustomInputGroup
      disabled={isAddBtnDisabled}
      onItemAdd={() => {
        onChange([...contacts, newContact]);
        setNewContact({
          responsibilityId: undefined,
          userId: undefined,
          userName: undefined,
        });
      }}
      data-test-id="md-form-contact-add"
      leftInput={
        <Select
          options={teamMemberResponsibilitiesOptions}
          value={newContact.responsibilityId}
          disabled={disabled}
          onChange={responsibilityId => {
            setNewContact({
              responsibilityId,
              userId: undefined,
              userName: undefined,
            });
          }}
          id="md-form-contact-responsibility"
          allowClear
          data-test-id="md-form-contact-responsibility"
        />
      }
      rightInput={
        <UserAsyncSearchSelect
          value={
            newContact.userId
              ? {
                  name: newContact.userName,
                  value: newContact.userId,
                }
              : undefined
          }
          onChange={(_value, option) => {
            setNewContact(prev => ({
              ...prev,
              userId: option?.value,
              userName: option?.name,
              userPhotoUrl: option?.data?.userPhotoUrl,
              userStatus: option?.data?.userStatus,
            }));
          }}
          id="md-form-contact-user"
          disabled={!newContact.responsibilityId || disabled}
          responsibilityId={newContact.responsibilityId}
          data-test-id="md-form-contact-user"
        />
      }
    >
      {contacts?.map((contact, index) => {
        const hasError = !!withErrors?.length
          ? withErrors.find(
              u =>
                u.userId === contact.userId &&
                u?.responsibilityId === contact.responsibilityId
            )
          : false;

        const hasInactiveError =
          !!withErrors?.length &&
          withErrors.find(u => u.userId === contact.userId);

        return (
          <div
            className={styles.activeTagWrapper}
            key={`${contact.responsibilityId}-${contact.userId}`}
            data-key={`contact-${contact.responsibilityId}-${contact.userId}`}
            data-test-id={`user-${
              teamMemberResponsibilitiesMap[contact.responsibilityId]?.text
            }-${contact.userName}`}
          >
            <UserCard
              size="xs"
              name={contact.userName}
              title={
                teamMemberResponsibilitiesMap[contact.responsibilityId]?.text
              }
              disabled={
                withUserStatusMention &&
                contact.hasOwnProperty("userStatus") &&
                contact?.userStatus !== UserStatus.ENABLED
              }
              inactiveTooltipText={intl.formatMessage({
                id: "general.userInactive",
              })}
              photoUrl={contact?.userPhotoUrl}
              closable
              onClose={() => {
                onChange(
                  contacts.filter(
                    item =>
                      !(
                        item.responsibilityId === contact.responsibilityId &&
                        item.userId === contact.userId
                      )
                  )
                );
              }}
            ></UserCard>
            {!!hasError && (
              <div className={styles.errorMessage}>
                {intl.formatMessage({
                  id: "contactsInputGroup.userError.noResponsibility",
                })}
              </div>
            )}
            {!!hasInactiveError && (
              <div className={styles.errorMessage}>
                {intl.formatMessage({
                  id: "general.userContactStatus.error",
                })}
              </div>
            )}
          </div>
        );
      })}
    </CustomInputGroup>
  );
};

export default ContactsInputGroup;
