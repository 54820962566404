import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Heading, Paragraph, Tooltip } from "@trace-one/design-system";
import { Empty } from "@trace-one/react-components";
import { SectionsIds } from "pages/Products/components/ProductForm/constants";

import usePermissions from "core/oidc/usePermissions";

import { selectProductDetailsData } from "reduxStore/productDetails/selectors";

import Label from "components/Label";
import Panel from "components/Panel";
import TabPage from "components/TabPage";
import { TradeItemStatus } from "shared/constants";
import { capitalizeText } from "shared/utils/capitalizeFullText";

import styles from "../../../ProductDetails.module.less";

const CustomAttributes = () => {
  const intl = useIntl();
  const history = useHistory();
  const { productData } = useSelector(selectProductDetailsData);
  const hasAttributes = !!productData.retailerCustomizedAttributes?.length;
  const { productsWrite } = usePermissions();
  const productIsDisabled =
    productData.tradeItemStatusId === TradeItemStatus.INACTIVE;

  return (
    <Panel>
      <TabPage.SecondaryTitle
        label={
          <Heading size="xs">
            {capitalizeText(
              intl.formatMessage({ id: "general.privateAttributes" })
            )}
          </Heading>
        }
      />
      {!productIsDisabled && productsWrite && (
        <Button
          type="link"
          className={styles.iconBtn}
          iconName="edit"
          iconPlacement="left"
          data-test-id="private-attributes-edit"
          onClick={() => {
            history.push({
              pathname: `/products/edit/${productData.id}`,
              hash: SectionsIds.ATTRIBUTES,
            });
          }}
        >
          {intl.formatMessage({ id: "general.edit" })}
        </Button>
      )}

      {hasAttributes ? (
        productData.retailerCustomizedAttributes.map((attribute, idx) => (
          <Label
            key={idx}
            label={
              <Heading size="xxs">
                <Tooltip
                  showFullText
                  text={attribute.retailerLabel}
                  placement="top"
                >
                  <div className={styles.productDetailsParagraph}>
                    {attribute.retailerLabel}
                  </div>
                </Tooltip>
              </Heading>
            }
            testId="productDetails-privateAttributes"
          >
            <Paragraph size="m">
              <Tooltip
                showFullText
                text={attribute.retailerCode}
                placement="top"
              >
                <div className={styles.productDetailsParagraph}>
                  {attribute.retailerCode}
                </div>
              </Tooltip>
            </Paragraph>
          </Label>
        ))
      ) : (
        <div>
          <Empty
            title={intl.formatMessage({ id: "general.noDataDefined" })}
            imageType="simple"
          />
        </div>
      )}
    </Panel>
  );
};

export default CustomAttributes;
