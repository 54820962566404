import { Icon } from "@trace-one/react-components";
import { Modal, ModalProps } from "antd";

import styles from "./FormModal.module.less";

export { default as FormModalContent } from "./FormModalContent";

export interface FormModalProps extends ModalProps {}

const FormModal = ({ children, title, ...rest }) => {
  return (
    <Modal
      width={800}
      closeIcon={
        <Icon
          name="close-cross"
          size={28}
          data-test-id="close-cross"
          style={{ marginTop: 30, marginRight: 30 }}
        />
      }
      destroyOnClose
      maskClosable={false}
      footer={null}
      bodyStyle={{ padding: 0 }}
      {...rest}
    >
      <div className={styles.root}>
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>{title}</h2>
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default FormModal;
