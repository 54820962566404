type State = {
  classification: any;
  categoryList: any;
  isClassificationLoading: boolean;
  hasClassificationError: boolean;
  errorStatus: any;
};

export const initialState: State = {
  classification: {},
  isClassificationLoading: false,
  hasClassificationError: false,
  categoryList: [],
  errorStatus: null,
};
