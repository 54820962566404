import React from "react";
import { useIntl } from "react-intl";

import { Input as AntInput } from "@trace-one/design-system";
import { InputProps as AntdInputProps } from "antd";

interface ErrorMessageProps {
  error?: boolean;
  errorMessage?: React.ReactNode;
  errorDataTestId?: string;
}
interface InputProps extends AntdInputProps, ErrorMessageProps {}

const Input: React.FC<InputProps> = ({
  error,
  errorMessage,
  errorDataTestId,
  ...rest
}) => {
  const intl = useIntl();
  console.log(errorDataTestId);
  return (
    <AntInput
      {...(error && { status: "error" })}
      placeholder={intl.formatMessage({ id: "general.enterValue" })}
      data-test-id="input"
      {...rest}
      error={error}
      errorMessage={
        <span
          data-test-id={errorDataTestId}
          style={{ left: 0, marginBottom: 0, position: "absolute" }}
        >
          {errorMessage}
        </span>
      }
    />
  );
};

export default Input;
