import React, { useEffect, useMemo } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import {
  AxiosInstancesWrapper as BusinessAxiosInstancesWrapper,
  LanguagePreferenceProvider as BusinessLanguagePreferenceProvider,
} from "@trace-one/business-components";
import {
  LanguagePreferenceProvider,
  PreviousLocationProvider,
  LoadingPage,
} from "@trace-one/react-components";
import { ConfigProvider } from "antd";
import { ConfigProviderProps } from "antd/lib/config-provider";
import moment from "moment";

import useInterceptorsSetup from "core/interceptors/useInterceptorsSetup";
import Layout from "core/layout";
import useOidcSetup from "core/oidc/useOidcSetup";
import {
  DEFAULT_LANGUAGE,
  ANTD_CONFIG_LOCALES,
  TO_TRANSLATIONS,
} from "translations";

import { useAppDispatch } from "reduxStore";
import { fetchUser, fetchUserApplications } from "reduxStore/user/asyncActions";
import {
  selectIsUserDetailsLoading,
  selectIsUserApplicationsLoading,
  selectUserLanguageCode,
  selecthasUserDetailsError,
  selecthasApplicationsError,
} from "reduxStore/user/selectors";

import ErrorBoundary from "components/ErrorBoundary";
//import Spinner from "components/Spinner";
import ErrorPage from "components/ErrorPage";
import getDisplayName from "shared/utils/getDisplayName";

import AXIOS_INSTANCES from "./apis/axiosInstances";
import AppRouting from "./AppRouting";

const withLanguageConfig = (App: React.FC) => {
  /**
   * We have to keep same reference of antdConfigLocale
   * because for some reason ConfigProvider unmount all tree and rerender
   */
  const antdConfigLocale = {} as ConfigProviderProps["locale"];

  const Component = props => {
    const userLanguadeCode = useSelector(selectUserLanguageCode);
    moment.locale(userLanguadeCode);

    const traceOneMessages = useMemo(
      () => ({
        ...TO_TRANSLATIONS[DEFAULT_LANGUAGE],
        ...TO_TRANSLATIONS[userLanguadeCode],
      }),
      [userLanguadeCode]
    );

    Object.assign(
      antdConfigLocale,
      ANTD_CONFIG_LOCALES[userLanguadeCode] ??
        ANTD_CONFIG_LOCALES[DEFAULT_LANGUAGE]
    );

    return (
      <LanguagePreferenceProvider languageCode={userLanguadeCode}>
        <BusinessLanguagePreferenceProvider languageCode={userLanguadeCode}>
          <IntlProvider messages={traceOneMessages} locale={userLanguadeCode}>
            <ConfigProvider locale={antdConfigLocale}>
              <App {...props} />
            </ConfigProvider>
          </IntlProvider>
        </BusinessLanguagePreferenceProvider>
      </LanguagePreferenceProvider>
    );
  };
  Component.displayName = getDisplayName(withLanguageConfig, App);

  return Component;
};

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const isUserDetailsLoading = useSelector(selectIsUserDetailsLoading);
  const isUserApplicationsLoading = useSelector(
    selectIsUserApplicationsLoading
  );
  const hasUserDetailsError = useSelector(selecthasUserDetailsError);
  const hasApplicationsError = useSelector(selecthasApplicationsError);
  const {
    oidcUser: {
      access_token,
      profile: { global_user_id, company_activity },
    },
  } = useOidcSetup();

  useInterceptorsSetup({ accessToken: access_token });

  useEffect(() => {
    dispatch(
      fetchUser({ userId: global_user_id, companyActivityId: company_activity })
    );
    dispatch(fetchUserApplications());
  }, [global_user_id, company_activity, dispatch]);

  if (isUserDetailsLoading || isUserApplicationsLoading) {
    return <LoadingPage />;
  }

  return (
    <Router>
      <BusinessAxiosInstancesWrapper
        axiosInstanceCUMD={AXIOS_INSTANCES.CUMD}
        axiosInstancePMD={AXIOS_INSTANCES.PMD}
        axiosInstanceRLMD={AXIOS_INSTANCES.RLMD}
        axiosInstanceSMD={AXIOS_INSTANCES.SMD}
        axiosInstanceNOTIF={AXIOS_INSTANCES.NOTIF}
        axiosInstanceTON={AXIOS_INSTANCES.TON}
        axiosInstanceDOCUMENT={AXIOS_INSTANCES.DMS}
        axiosInstancePROJECT={AXIOS_INSTANCES.PROJECT}
      >
        <PreviousLocationProvider>
          <Layout>
            <ErrorBoundary>
              {hasUserDetailsError || hasApplicationsError ? (
                <ErrorPage primaryButton={{ isVisible: false }} />
              ) : (
                <AppRouting />
              )}
            </ErrorBoundary>
          </Layout>
        </PreviousLocationProvider>
      </BusinessAxiosInstancesWrapper>
    </Router>
  );
};

export default withLanguageConfig(App);
