import { CumdAPI } from "apis";
import { UserData } from "models";

export type GetUserResut = UserData & {
  userName?: string;
};

export type UserCollectionMapType = { [userId: string]: GetUserResut };

export default async function getUsersCollectionMap(
  userIds: string[]
): Promise<UserCollectionMapType> {
  let users: GetUserResut[] = [];
  const userIdsToSend = userIds.filter(id => id);

  if (userIdsToSend.length > 0) {
    users = await CumdAPI.getUsersByUserIds({
      userIds: userIdsToSend,
    }).then(({ data }) => data);
  }

  const usersMap = users.reduce<UserCollectionMapType>(
    (prev, current) => ({ ...prev, [current.userId]: current }),
    {}
  );
  return usersMap;
}
