/* istanbul ignore file */
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { ModalService, ConfirmationModal } from "@trace-one/react-components";
import { Menu } from "antd";
import BrandFormModal from "pages/Brands/pages/BrandList/BrandFormModal";
import BrandTranslateModal from "pages/Brands/pages/BrandList/BrandTranslateModal";

import { PmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";
import { BrandData } from "models";

import { useAppDispatch } from "reduxStore";
import { setProductBrandId } from "reduxStore/productDetails/slice";
import { selectUserLanguageCode } from "reduxStore/user/selectors";

import ActionDropdown from "components/ActionDropdown";
import { TradeItemStatus } from "shared/constants";
import useToast from "shared/hooks/useToast";

import { BrandColumn } from "../models";

type BrandActionProps = {
  record: BrandColumn;
  refetchBrands: () => void;
  isBrandTranslated: boolean;
};

const BrandAction: React.FC<BrandActionProps> = ({
  record,
  refetchBrands,
  isBrandTranslated,
}) => {
  const intl = useIntl();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { brandsWrite } = usePermissions();
  const languageCode = useSelector(selectUserLanguageCode);
  const [isUpdateBrandModalOpen, setIsUpdateBrandModalOpen] = useState(false);
  const [isTranslateBrandModalOpen, setIsTranslateBrandModalOpen] =
    useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const tradeItemStatuses = [TradeItemStatus.ACTIVE];
  const handleBrandArchive = async (brandData: BrandData) => {
    setVisible(false);
    let toArchiveBrand = false;
    const { ownerCompanyId, id: brandId } = brandData;
    const { data } = await PmdAPI.getTradeItemsByFilters(
      { tradeItemStatuses, brandIds: [brandId] },
      { ownerCompanyId }
    );
    if (data.tradeItems.length === 0) {
      toArchiveBrand = true;
    } else {
      toArchiveBrand = false;
    }
    ModalService.openModal({
      component: (
        <ConfirmationModal
          languageCode={languageCode}
          withIcon={false}
          confirmationModalTitle={intl.formatMessage({
            id: "general.information",
          })}
          confirmBtnText={intl.formatMessage({
            id: "general.confirm",
          })}
          cancelBtnText={intl.formatMessage({
            id: "general.cancel",
          })}
          withButton={toArchiveBrand}
          onConfirm={() =>
            onToggleManufacturedStatus(toArchiveBrand, brandData)
          }
          linkText={
            !toArchiveBrand
              ? intl.formatMessage({
                  id: "brandNotification.Link",
                })
              : undefined
          }
          children={
            toArchiveBrand
              ? intl.formatMessage({
                  id: "brandNotification.archive",
                })
              : intl.formatMessage({
                  id: "brandNotification.active",
                })
          }
        />
      ),
    });
  };

  const onToggleManufacturedStatus = async (
    toArchiveBrand: boolean,
    brandData: BrandData
  ) => {
    const { id } = brandData;
    try {
      if (toArchiveBrand) {
        await PmdAPI.archiveBrand(id);
        toast.success({
          description: intl.formatMessage({ id: "brandNotification.Success" }),
        });
        refetchBrands();
      } else {
        dispatch(setProductBrandId(id));
        history.push("/products");
      }
    } catch (error) {
      console.log(error);
      toast.saveError({ error });
    }
  };

  const handleVisible = () => {
    if (!brandsWrite) {
      setVisible(false);
    } else {
      setVisible(!visible);
    }
  };
  return (
    <>
      <ActionDropdown
        data-test-id="md-brand-action"
        visible={visible}
        onVisibleChange={handleVisible}
        overlay={
          <Menu>
            {brandsWrite && (
              <>
                <Menu.Item
                  key="edit-brand"
                  onClick={() => {
                    setVisible(false);
                    setIsUpdateBrandModalOpen(true);
                  }}
                >
                  {intl.formatMessage({
                    id: "brandList.table.actions.edit",
                  })}
                </Menu.Item>
                {record.isActive && (
                  <Menu.Item
                    key="archive-brand"
                    onClick={() => handleBrandArchive(record.rawBrandData)}
                  >
                    {intl.formatMessage({
                      id: "general.archive",
                    })}
                  </Menu.Item>
                )}
                {isBrandTranslated && (
                  <Menu.Item
                    key="translate-brand"
                    onClick={() => {
                      setVisible(false);
                      setIsTranslateBrandModalOpen(true);
                    }}
                  >
                    {intl.formatMessage({
                      id: "brandList.table.actions.addTranslations",
                    })}
                  </Menu.Item>
                )}
              </>
            )}
          </Menu>
        }
      />
      <BrandFormModal
        visible={isUpdateBrandModalOpen}
        onCancel={() => setIsUpdateBrandModalOpen(false)}
        initialValues={record.rawBrandData}
        refetchBrands={refetchBrands}
      />
      <BrandTranslateModal
        visible={isTranslateBrandModalOpen}
        onCancel={() => setIsTranslateBrandModalOpen(false)}
        initialValues={record.rawBrandData}
        refetchBrands={refetchBrands}
      />
    </>
  );
};

export default BrandAction;
