import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading, Paragraph } from "@trace-one/design-system";
import { Empty } from "@trace-one/react-components";
import { Row, Col } from "antd";
import moment from "moment";
import { PageCol } from "pages/Sites/constants";

import { selectSupplierDetails } from "reduxStore/SupplierDetails/selectors";

import Panel from "components/Panel";
import TabPage from "components/TabPage";

import styles from "../SupplierDetails.module.less";

interface CommentsTabProps {}

const CommentsTab: React.FC<CommentsTabProps> = () => {
  const intl = useIntl();

  const { companyRelation } = useSelector(selectSupplierDetails);

  const hasComments = !!companyRelation?.comments.length;

  return (
    <TabPage.Wrapper>
      <Row gutter={[40, 40]}>
        <Col {...PageCol.FULL}>
          <div
            className={styles.commentsWrapper}
            data-test-id="comments-container"
          >
            {hasComments ? (
              companyRelation.comments.map((comment, idx) => {
                const commentDate = moment(comment.commentDate).format("L");
                return (
                  <Panel key={comment.id}>
                    <div
                      className={styles.commentHeader}
                      data-test-id={`comment-${idx}-header`}
                    >
                      <Heading size="xxs" color="primary">
                        {" "}
                        {`${comment.authorFullname} - ${commentDate}`}{" "}
                      </Heading>
                    </div>
                    <div
                      className={styles.commentContent}
                      data-test-id={`comment-${idx}-body`}
                    >
                      <Paragraph size="m">{comment.value} </Paragraph>
                    </div>
                  </Panel>
                );
              })
            ) : (
              <div style={{ marginTop: 40 }}>
                <Empty
                  title={intl.formatMessage({ id: "general.noComment" })}
                  imageType="default"
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </TabPage.Wrapper>
  );
};

export default CommentsTab;
