import { memo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading } from "@trace-one/design-system";
import { Form } from "antd";

import { selectTeamMemberResponsibilities } from "reduxStore/shared/selectors";
import { selectFunctionOptions } from "reduxStore/shared/selectors";

import CategoryTreeSelect from "components/CategoryTreeSelect";
import ContactsInputGroup from "components/ContactsInputGroup";
import FormWrapper from "components/FormWrapper";
import Input from "components/Input";
import Select from "components/Select";

import { SectionsIds } from "../../../../constants";

import AttributesInput from "./AttributesInput";

const PrivateInformationSection = ({
  disabled,
  submitErrors,
}: {
  disabled: boolean;
  submitErrors?: any;
}) => {
  const intl = useIntl();
  const teamMemberResponsibilities = useSelector(
    selectTeamMemberResponsibilities
  );
  const functions = useSelector(selectFunctionOptions);

  return (
    <FormWrapper.Section
      title={
        <Heading size="m">
          {intl.formatMessage({ id: "general.privateInformation" })}{" "}
        </Heading>
      }
      id={SectionsIds.PRIVATE}
    >
      <Form.Item
        name="identifier"
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "siteForm.code" })}{" "}
          </Heading>
        }
      >
        <Input
          disabled={disabled}
          maxLength={20}
          data-test-id="md-site-identifier"
        />
      </Form.Item>
      <Form.Item
        name="functionIds"
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "siteForm.function" })}{" "}
          </Heading>
        }
      >
        <Select
          allowClear
          mode="multiple"
          disabled={disabled}
          data-test-id="md-site-function-select"
          style={{ width: "100%" }}
          options={functions}
          filterOption={(input, option) =>
            (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item
        name="categories"
        label={
          <Heading size="xxs">
            {" "}
            {intl.formatMessage({ id: "siteForm.categories" })}
          </Heading>
        }
      >
        {/*
        // @ts-ignore */}
        <CategoryTreeSelect
          disabled={disabled}
          multiple
          multiParentSelection
          categorySelection={false}
          data-test-id="md-site-categories"
        />
      </Form.Item>
      <Form.Item
        name="retailerCustomizedAttributes"
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "siteForm.retailerAttributes" })}{" "}
          </Heading>
        }
      >
        <AttributesInput disabled={disabled} />
      </Form.Item>

      <Form.Item
        name="contacts"
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "general.contacts" })}{" "}
          </Heading>
        }
      >
        <ContactsInputGroup
          withErrors={submitErrors?.contacts?.withErrors}
          teamMemberResponsibilities={teamMemberResponsibilities}
          disabled={disabled}
          withUserStatusMention
        />
      </Form.Item>
    </FormWrapper.Section>
  );
};

export default memo(PrivateInformationSection);
