import { useSelector } from "react-redux";

import { AddressModel } from "pages/Sites/constants";

import { SiteData } from "models";

import { selectCountriesOptions } from "reduxStore/shared/selectors";

import { GOOGLEMAP_QUERY_URL } from "shared/constants";

export default function useSiteAddress(site: SiteData) {
  const countries = useSelector(selectCountriesOptions);

  const checkedParamForAddress = [
    AddressModel.ADDRESS_1,
    AddressModel.ADDRESS_2,
    AddressModel.CITY,
    AddressModel.POSTAL_CODE,
    AddressModel.REGION,
    AddressModel.COUNTRY_CODE,
  ];
  const checkedForGmapLink = [
    AddressModel.ADDRESS_1,
    AddressModel.POSTAL_CODE,
    AddressModel.COUNTRY_CODE,
  ];

  const showGmapLink = checkedForGmapLink.every(
    x => site.hasOwnProperty(x) && site[x] !== "" && site[x] !== null
  );
  const siteHasAddress =
    checkedParamForAddress.every(x => site[x] === "" || site[x] === null) ===
    false;

  const addressData = [
    ...(site.siteAddress1 ? [site.siteAddress1] : []),
    ...(site.siteAddress2 ? [site.siteAddress2] : []),
    ...(site.siteCity ? [site.siteCity] : []),
    ...(site.sitePostalCode ? [site.sitePostalCode] : []),
    ...(site.siteRegion ? [site.siteRegion] : []),
    ...(site.siteCountry
      ? [countries.find(c => c.value === site?.siteCountry)?.name]
      : []),
  ];

  return {
    hasAddress: siteHasAddress,
    addressData: siteHasAddress ? addressData : [],
    showGmapLink,
    gmapLink: showGmapLink
      ? `${GOOGLEMAP_QUERY_URL}${addressData.join(",")}`
      : "",
  };
}
