import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Button,
  Heading,
  Paragraph,
  Tooltip,
  UserCard,
} from "@trace-one/design-system";
import { Col, Row } from "antd";
import { PageCol } from "pages/Sites/constants";
import { SupplierSectionIds } from "pages/Sites/constants";

import usePermissions from "core/oidc/usePermissions";

import {
  selectContactUsers,
  selectSite,
} from "reduxStore/siteSupplierDetails/selectors";

import Label from "components/Label";
import Panel from "components/Panel";
import TabPage from "components/TabPage";
import { UserStatus } from "shared/constants";
import { capitalizeText } from "shared/utils/capitalizeFullText";

import styles from "../../SiteSupplierDetails.module.less";

const ContactsBloc = () => {
  const intl = useIntl();
  const site = useSelector(selectSite);
  const { sitesWrite } = usePermissions();
  const users = useSelector(selectContactUsers);
  const history = useHistory();

  return (
    <Panel>
      <TabPage.SecondaryTitle
        label={
          <Heading size="xs">
            {capitalizeText(
              intl.formatMessage({ id: "siteForm.contactsTitle" })
            )}
          </Heading>
        }
      />
      {site.siteActive && sitesWrite && (
        <Button
          type="link"
          className={styles.iconBtn}
          data-test-id="contactInfo-button"
          iconName="edit"
          iconPlacement="left"
          onClick={() => {
            history.push({
              pathname: `/sites/edit/${site.id}`,
              hash: SupplierSectionIds.CONTACTS,
            });
          }}
        >
          {intl.formatMessage({ id: "general.edit" })}
        </Button>
      )}
      <Row>
        <Col {...PageCol.HALF}>
          <Label
            label={
              <Heading size="xxs">
                {intl.formatMessage({ id: "siteDetails.siteContactDetails" })}
              </Heading>
            }
            bordered
            testId="contacts-siteContact"
          >
            {site?.siteEmail && (
              <div>
                <Paragraph size="m">{site.siteEmail} </Paragraph>
              </div>
            )}
            {site?.sitePhone && (
              <div>
                <Paragraph size="m">{site.sitePhone}</Paragraph>
              </div>
            )}
            {site?.siteFax && (
              <div>
                <Paragraph size="m">
                  <div className={styles.siteSupplierDetailsParagraph}>
                    {site.siteFax}
                  </div>
                </Paragraph>
              </div>
            )}
          </Label>
          <Label
            label={
              <Heading size="xxs">
                {intl.formatMessage({ id: "siteDetails.emergencyContact" })}
              </Heading>
            }
            bordered
            testId="contacts-emergency"
          >
            <Paragraph size="m">
              {site?.emergencyPhone && (
                <Tooltip
                  showFullText
                  text={site.emergencyPhone}
                  placement="top"
                >
                  <div className={styles.siteSupplierDetailsParagraph}>
                    {site.emergencyPhone}
                  </div>
                </Tooltip>
              )}
            </Paragraph>
          </Label>
        </Col>
        <Col xs={24} lg={12}>
          <Label
            label={
              <Heading size="xxs">
                {intl.formatMessage({ id: "siteDetails.additionalContacts" })}
              </Heading>
            }
            testId="contacts-contacts"
          >
            {!!site?.contactUserIds.length && (
              <div className={styles.additionalContact}>
                {site.contactUserIds.map(userId => {
                  const contactData =
                    users.find(u => u.userId === userId) || {};
                  const isUserInactive =
                    contactData.userStatus !== UserStatus.ENABLED;
                  const fullName = `${contactData.userFirstName} ${contactData.userLastName}`;
                  return (
                    <div key={userId} className="m-0-5">
                      <UserCard
                        size="xs"
                        disabled={isUserInactive}
                        name={fullName}
                        title={contactData.userJobTitle}
                        photoUrl={contactData.userPhotoUrl}
                        inactiveTooltipText={intl.formatMessage({
                          id: "general.userInactive",
                        })}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </Label>
        </Col>
      </Row>
    </Panel>
  );
};

export default ContactsBloc;
