import React, { useEffect, useState } from "react";

import { FakeIntercom as BaseFakeIntercom } from "@trace-one/react-components";

const FakeIntercom = () => {
  const [initialState, setInitialState] = useState<boolean>(true);
  const [marketingConsent, setMarketingConsent] = useState<boolean>(false);

  const handleClick = () => {
    document.querySelector<HTMLElement>(".lm-shield-container")?.click();
  };

  useEffect(() => {
    const acceptedHandler = () => {
      if (!marketingConsent && !initialState) {
        setTimeout(() => {
          window.Intercom("show");
        }, 1500);
      }
      setMarketingConsent(true);
      setInitialState(false);
    };

    window.addEventListener(
      "legalmonster.cookie.marketing.accepted",
      acceptedHandler
    );

    const rejectedHandler = () => {
      setMarketingConsent(false);
      setInitialState(false);
    };

    window.addEventListener(
      "legalmonster.cookie.marketing.rejected",
      rejectedHandler
    );
  }, [marketingConsent, initialState]);

  return !marketingConsent ? <BaseFakeIntercom onClick={handleClick} /> : null;
};

export default FakeIntercom;
