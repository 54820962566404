import { Row, Col } from "antd";
import { PageCol } from "pages/Sites/constants";

import styles from "../../SiteRetailerDetails.module.less";

import ContactsBloc from "./components/ContactsBloc";
import PrivateInformation from "./components/PrivateInformation";
import SiteIdentification from "./components/SiteIdentification";

const SiteInformationsTab = () => {
  return (
    <div className={styles.tabWrapper}>
      <Row gutter={[32, 32]}>
        <PrivateInformation />

        <Col
          {...PageCol.HALF}
          className={styles.boxshadow}
          style={{ paddingLeft: "15px" }}
        >
          <ContactsBloc />
        </Col>
        <Col {...PageCol.FULL}>
          <SiteIdentification />
        </Col>
      </Row>
    </div>
  );
};

export default SiteInformationsTab;
