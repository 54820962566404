import { useState, useCallback, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";

import { selectUserId } from "reduxStore/user/selectors";

import { StorageType } from "shared/webStorage/storage";

type PaginationInput = {
  skip: number;
  take: number;
};

const defaultInitialValue: PaginationInput = {
  skip: 0,
  take: 10,
};

type TablePaginationOptions = {
  webStorage?: StorageType;
  skipAndTakeQueryStats: { totalCount?: number };
  initialValue?: PaginationInput;
};

export default function useTablePagination({
  webStorage,
  skipAndTakeQueryStats: { totalCount },
  initialValue,
}: TablePaginationOptions) {
  const userId = useSelector(selectUserId);

  const initialPaginationQuery = useMemo<PaginationInput>(() => {
    const storedValues = webStorage?.get();
    if (
      storedValues &&
      storedValues.userId === userId &&
      Number.isInteger(storedValues.take) &&
      Number.isInteger(storedValues.skip) &&
      storedValues.skip >= 0 &&
      storedValues.take >= 0
    ) {
      return { skip: storedValues.skip, take: storedValues.take };
    }
    return initialValue ?? defaultInitialValue;
  }, [userId, webStorage, initialValue]);

  const [paginationQuery, setPaginationQuery] = useState(
    initialPaginationQuery
  );

  useEffect(() => {
    webStorage?.set({ ...paginationQuery, userId });
  }, [paginationQuery, userId, webStorage]);

  const resetPageNumber = useCallback(() => {
    setPaginationQuery(prev => ({ ...prev, skip: 0 }));
  }, [setPaginationQuery]);

  useEffect(() => {
    if (totalCount >= 0 && paginationQuery.skip >= totalCount) {
      setPaginationQuery(prev => ({ ...prev, skip: 0 }));
    }
  }, [totalCount, paginationQuery.skip, resetPageNumber]);

  return {
    paginationQuery,
    setPaginationQuery,
    resetPageNumber,
  };
}
