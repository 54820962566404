import { TradeItemData } from "models";

type State = {
  products: TradeItemData[];
  productsSkipAndTakeQueryStats: {
    currentCount?: number;
    totalCount?: number;
  };
  isProductsLoading: boolean;
  hasProductsError: boolean;
  isExportSelectedProductsLoading: boolean;
  isExportSelectedManufacturedItemsLoading: boolean;
};

export const initialState: State = {
  products: [],
  productsSkipAndTakeQueryStats: {
    currentCount: undefined,
    totalCount: undefined,
  },
  isProductsLoading: false,
  hasProductsError: false,
  isExportSelectedProductsLoading: false,
  isExportSelectedManufacturedItemsLoading: false,
};
