import React, { memo, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Header } from "@trace-one/business-components";
import cn from "classnames";

import { useLegalMonster } from "core/legalMonster";
import useLogout from "core/oidc/useLogout";
import usePermissions from "core/oidc/usePermissions";
import { Languages } from "translations";

import { useAppDispatch } from "reduxStore";
import { selectOidcAccessToken } from "reduxStore/oidc/selectors";
import { fetchUserApplicationsCompanies } from "reduxStore/user/asyncActions";
import {
  selectUserId,
  selectUserApplications,
} from "reduxStore/user/selectors";

import FakeIntercom from "components/FakeIntercom";

import Footer from "./Footer";
import useBackButton from "./hooks/useBackButton";
import useHeaderRoutes from "./hooks/useHeaderRoutes";
import useScrollToTopOnRouteChange from "./hooks/useScrollTopOnRouteChange";
import styles from "./Layout.module.less";

export interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  useLegalMonster();
  useScrollToTopOnRouteChange();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const hideHeaderPaths = ["/documents/viewer"];
  const [hideHeader, setHideHeader] = useState(false);
  const { isMasquerade } = usePermissions();
  const { appIcon, appLabel, handleBackClick, isBackButtonVisible } =
    useBackButton();

  useEffect(() => {
    hideHeaderPaths.forEach(path => {
      if (pathname.includes(path)) {
        setHideHeader(true);
      }
    });
  }, [pathname]);
  const { homePath, headerRoutes } = useHeaderRoutes();
  const userApplications = useSelector(selectUserApplications);
  const userId = useSelector(selectUserId);
  const accessToken = useSelector(selectOidcAccessToken);
  const logout = useLogout();
  const { hasAccessToMd, isCompanyAdmin, hasAccessToAdministration } =
    usePermissions();

  useEffect(() => {
    const companyIds = userApplications.map(
      ({ masterDataApplicationOwnerId }) => masterDataApplicationOwnerId
    );
    const companyIdsUniq: string[] = [...new Set(companyIds)];
    if (companyIdsUniq.length > 0) {
      dispatch(fetchUserApplicationsCompanies(companyIdsUniq));
    }
  }, [userApplications]);

  return (
    <>
      {!hideHeader && (
        <Header
          appLogo={"md"}
          appName={"master data"}
          userId={userId}
          isMasquerade={isMasquerade}
          onLogout={logout}
          homeUrl={homePath}
          routes={headerRoutes}
          appSwitcherProps={{
            accessToken,
            userId: userId,
            showDiscoverSolutions: true,
            excludeApps: [100, 90],
          }}
          extraAboveAppLogo={<FakeIntercom />}
          redirectBanner={{
            visible: isBackButtonVisible,
            bannerTitle: `${formatMessage({
              id: "general.backTo",
            })} ${appLabel}`,
            appIconName: appIcon,
            onBackClick: handleBackClick,
          }}
          helpLinks={{
            [Languages.ENGLISH]: window.env.TO_HELP_LINK_EN,
            [Languages.FRENCH]: window.env.TO_HELP_LINK_FR,
          }}
          displayAdminUserAndAccessRoute={
            hasAccessToMd && isCompanyAdmin && hasAccessToAdministration
          }
          adminUserAndAccessUrl={window.env.UI_ADMIN_USERS_LIST_URL}
        />
      )}
      <div
        className={cn(
          styles.appContent,
          isBackButtonVisible && styles.withPreviousAppBanner
        )}
      >
        {children}
      </div>
      <Footer />
      <div
        className={cn(isBackButtonVisible && "withPreviousAppOverlay")}
        id="notificationsRoot"
      />
      <div
        className={cn(isBackButtonVisible && "withPreviousAppOverlay")}
        id="allFiltersModalRoot"
      />
    </>
  );
};

export default memo(Layout);
