import { createSlice } from "@reduxjs/toolkit";

import { fetchSupplierCompanies, fetchPakerCompanies } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "productShared",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSupplierCompanies.pending, (state, action) => {
        state.supplierCompaniesData.isInitiated =
          state.supplierCompaniesData.isInitiated &&
          !state.supplierCompaniesData.hasError;
        state.supplierCompaniesData.isLoading = true;
        state.supplierCompaniesData.hasError = false;
      })
      .addCase(fetchSupplierCompanies.fulfilled, (state, action) => {
        state.supplierCompaniesData.isInitiated = true;
        state.supplierCompaniesData.isLoading = false;
        state.supplierCompaniesData.data = action.payload;
      })
      .addCase(fetchSupplierCompanies.rejected, (state, action) => {
        state.supplierCompaniesData.isLoading = false;
        state.supplierCompaniesData.data = [];
        state.supplierCompaniesData.hasError = true;
      })

      .addCase(fetchPakerCompanies.pending, (state, action) => {
        state.pakerCompaniesData.isInitiated =
          state.pakerCompaniesData.isInitiated &&
          !state.pakerCompaniesData.hasError;
        state.pakerCompaniesData.isLoading = true;
        state.pakerCompaniesData.hasError = false;
      })
      .addCase(fetchPakerCompanies.fulfilled, (state, action) => {
        state.pakerCompaniesData.isInitiated = true;
        state.pakerCompaniesData.isLoading = false;
        state.pakerCompaniesData.data = action.payload;
      })
      .addCase(fetchPakerCompanies.rejected, (state, action) => {
        state.pakerCompaniesData.isLoading = false;
        state.pakerCompaniesData.data = [];
        state.pakerCompaniesData.hasError = true;
      });
  },
});

export default slice.reducer;
