import { useSelector } from "react-redux";

import { Col, Row } from "antd";
import { PageCol } from "pages/Sites/constants";

import { selectSupplierDetails } from "reduxStore/SupplierDetails/selectors";

import MainAdditionalInformation from "./MainAdditionalInformation";

interface SupplierIdentificationProps {}

const SupplierIdentification: React.FC<SupplierIdentificationProps> = () => {
  const { company, companyRelation } = useSelector(selectSupplierDetails);

  return (
    <>
      <Col {...PageCol.FULL} className="my">
        <Row justify="space-between" gutter={40}>
          {!!company.companyLogoUrl && (
            <Col style={{ display: "flex", alignItems: "start" }} flex={2}>
              <img
                style={{ margin: "auto", maxHeight: 300, maxWidth: "70%" }}
                src={company.companyLogoUrl}
                alt={
                  !!companyRelation.externalName
                    ? companyRelation.externalName
                    : company.companyDisplayName
                }
              />
            </Col>
          )}
          <Col flex={10}>
            <MainAdditionalInformation />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default SupplierIdentification;
