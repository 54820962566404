import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading, Paragraph, Tooltip } from "@trace-one/design-system";
import { Col, Row } from "antd";
import { PageCol } from "pages/Sites/constants";

import { selectRetailerRelation } from "reduxStore/siteRetailerDetails/selectors";

import Label from "components/Label";
import TabPage from "components/TabPage";

import styles from "../../../../../SiteRtailerDetails/SiteRetailerDetails.module.less";

const CustomAttributesBloc = () => {
  const intl = useIntl();
  const relation = useSelector(selectRetailerRelation);

  return (
    <Row>
      <Col {...PageCol.FULL}>
        <TabPage.SecondaryTitle
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "siteDetails.customAttributes" })}
            </Heading>
          }
        />

        <Label
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "siteDetails.internalCode" })}
            </Heading>
          }
          testId="customAttr-internalCode"
        >
          <Paragraph size="m">
            <div className={styles.siteRetailerDetailsParagraph}>
              {relation.identifier}
            </div>
          </Paragraph>
        </Label>

        {relation?.retailerCustomizedAttributes.map((attribute, idx) => (
          <Label
            key={idx}
            label={
              <Paragraph size="m">
                <Tooltip
                  showFullText
                  text={attribute.retailerLabel}
                  placement="top"
                >
                  <div className={styles.siteRetailerDetailsParagraph}>
                    {attribute.retailerLabel}
                  </div>
                </Tooltip>
              </Paragraph>
            }
            testId={`customAttr-privateAttr-${idx}`}
          >
            <Paragraph size="m">
              <Tooltip
                showFullText
                text={attribute.retailerCode}
                placement="top"
              >
                <div className={styles.siteRetailerDetailsParagraph}>
                  {attribute.retailerCode}
                </div>
              </Tooltip>
            </Paragraph>
          </Label>
        ))}
      </Col>
    </Row>
  );
};

export default CustomAttributesBloc;
