import styles from "../TabPageWrapper.module.less";

interface SecondaryTitleProps {
  label?: React.ReactNode;
}

const SecondaryTitle = ({ label }: SecondaryTitleProps) => {
  return <div className={styles.secondaryTitle}>{label}</div>;
};

export default SecondaryTitle;
