import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading, Tooltip } from "@trace-one/design-system";
import { Form } from "antd";
import { FormListFieldData } from "antd/lib/form/FormList";

import { selectPointOfSalesOptions } from "reduxStore/shared/selectors";

import { layout } from "components/FormWrapper";
import Icon from "components/Icon";
import IconButton from "components/IconButton";
import RangePicker from "components/RangePicker";
import Select from "components/Select";
import { SelectOption } from "shared/typings";

import styles from "./TargetMarket.module.less";

interface TargetMarketProps {
  disabled: boolean;
  targetMarketField: FormListFieldData;
  onRemove: () => void;
  countriesOptions: SelectOption[];
}

const TargetMarket: React.FC<TargetMarketProps> = ({
  disabled,
  targetMarketField,
  onRemove,
  countriesOptions,
}) => {
  const intl = useIntl();
  const pointOfSalesOptions = useSelector(selectPointOfSalesOptions);
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <div
      className={styles.root}
      data-test-id={`TMCard ${targetMarketField.name}`}
    >
      <div className={styles.dateAndMarketContent}>
        <Form.Item
          name={[targetMarketField.name, "countryCodes"]}
          label={
            <Heading size="xxs">
              {" "}
              {intl.formatMessage({
                id: "productForm.manufactured.card.targetMarkets",
              })}
            </Heading>
          }
          {...layout}
        >
          {/* @ts-ignore */}
          <Select
            disabled={disabled}
            options={countriesOptions}
            showSearch
            mode="multiple"
            data-test-id="md-product-manu-target-markets-countryCodes"
            filterOption={(input, option) =>
              (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>

        {!!pointOfSalesOptions?.length && (
          <Form.Item
            name={[targetMarketField.name, "pointsOfSales"]}
            label={
              <Heading size="xxs">
                {" "}
                {intl.formatMessage({
                  id: "productForm.manufactured.card.pointOfSales",
                })}
              </Heading>
            }
            {...layout}
          >
            {/* @ts-ignore */}

            <Select
              disabled={disabled}
              data-test-id="md-product-manu-target-markets-pointsOfSales"
              options={pointOfSalesOptions}
              showSearch
              mode="multiple"
              filterOption={(input, option) =>
                (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        )}
        <Form.Item
          name={[targetMarketField.name, "availabilityDate"]}
          label={
            <Heading size="xxs">
              {" "}
              {intl.formatMessage({
                id: "productForm.manufactured.card.availabilityDate",
              })}
            </Heading>
          }
          {...layout}
        >
          <RangePicker
            disabled={disabled}
            data-test-id="md-product-manu-target-markets-availabilityDate"
            style={{ width: "100%" }}
            allowEmpty={[true, true]}
          />
        </Form.Item>
      </div>
      <div className={styles.deleteIconWrapper}>
        <Tooltip
          onOpenChange={() => setVisible(true)}
          placement="top"
          text={intl.formatMessage({
            id: "productForm.manufactured.card.marketTitle",
          })}
          actions={[
            {
              text: intl.formatMessage({ id: "general.cancel" }),
              onClick: (event: React.FocusEvent<HTMLElement>) => {
                event.target.blur();
                setVisible(false);
              },
            },
            {
              text: intl.formatMessage({ id: "general.confirm" }),
              onClick: () => {
                onRemove();
                setVisible(false);
              },
            },
          ]}
          visible={visible}
        >
          <IconButton
            data-test-id="md-product-manu-remove-target-market"
            disabled={disabled}
          >
            <Icon useTOLibrary name="trash" size="small" color="primary" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default TargetMarket;
