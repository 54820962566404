const prepareInitialValues = ({ initialValues }) => ({
  ...initialValues,
  // packagingManagerId:
  //   relatedUsers.find(u => u.value === initialValues.packagingManagerId) ||
  //   null,
  // productManagerId:
  //   relatedUsers.find(u => u.value === initialValues.productManagerId) || null,
  // qualityManagerId:
  //   relatedUsers.find(u => u.value === initialValues.qualityManagerId) || null,
});

export default prepareInitialValues;
