import { FormattedMessage } from "react-intl";

import { ErrorCode, hasErrorOccurred } from "shared/errors";

export default async function getFailedSubmitResult({
  error,
  setSubmitErrors,
}: {
  error: any;
  setSubmitErrors: any;
}) {
  const submittedError = ({
    field,
    validateStatus,
    errorMessage,
  }: {
    field?: string;
    validateStatus?: string;
    errorMessage?: string;
  }) => {
    setSubmitErrors(oldErrors => ({
      ...oldErrors,
      [field]: {
        validateStatus: !validateStatus ? "warning" : validateStatus,
        help: (
          <span data-test-id={`classification-form-${validateStatus}${field}`}>
            <FormattedMessage id={errorMessage} />
          </span>
        ),
      },
    }));
  };

  if (
    hasErrorOccurred({
      error,
      errorCode: ErrorCode.CATEGORY_NAME_ALREADY_EXISTED,
    })
  ) {
    submittedError({
      field: "categoryItemName",
      errorMessage:
        "classification.categoryListName.errors.usedByAnotherCategory",
    });
    return true;
  }
  if (
    hasErrorOccurred({
      error,
      errorCode: ErrorCode.CATEGORY_EXTERNAL_CODE_ALREADY_EXISTED,
    })
  ) {
    submittedError({
      field: "externalCode",
      errorMessage: "classification.externalCode.errors.usedByAnotherCategory",
    });
    return true;
  }
  if (
    hasErrorOccurred({
      error,
      errorCode: ErrorCode.CLASSIFICATION_EXTERNAL_CODE_UPDATE_EXISTED,
    })
  ) {
    submittedError({
      field: "externalCode",
      errorMessage: "classification.externalCode.errors.usedByAnotherCategory",
    });
    return true;
  }
  return false;
}
