import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import prepareInitialValues from "pages/Sites/components/SiteSupplierForm/utils/prepareInitialValues";

import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import { selectIsRelatedCompanyLoading } from "reduxStore/shared/selectors";
import {
  fetchSite,
  fetchSiteRelations,
} from "reduxStore/siteSupplierForm/asyncActions";
import {
  selectSite,
  selectIsSiteLoading,
  selectHasSiteEditError,
  selectSiteErrorStatus,
} from "reduxStore/siteSupplierForm/selectors";
import { clearSiteSupplierForm } from "reduxStore/siteSupplierForm/slice";

import ErrorPage from "components/ErrorPage";
import Spinner from "components/Spinner";

import SiteSupplierForm from "../../components/SiteSupplierForm";
import withSiteShared, { SiteSharedProps } from "../../hocs/withSiteShared";

interface SiteSupplierEditProps extends SiteSharedProps {}

export const SiteSupplierEdit: React.FC<SiteSupplierEditProps> = ({
  isSiteSharedInitiated,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { siteId } = useParams<{ siteId?: string }>();
  const { sitesWrite } = usePermissions();
  const site = useSelector(selectSite);

  const isRelatedCompanyLoading = useSelector(selectIsRelatedCompanyLoading);
  const isLoading = useSelector(selectIsSiteLoading);
  const hasErrors = useSelector(selectHasSiteEditError);
  const errorStatus = useSelector(selectSiteErrorStatus);

  useEffect(() => {
    dispatch(fetchSite(siteId));
    dispatch(fetchSiteRelations(siteId));

    return () => {
      dispatch(clearSiteSupplierForm());
    };
  }, [siteId]);

  if (isLoading || isRelatedCompanyLoading || !isSiteSharedInitiated) {
    return <Spinner underHeader />;
  }

  if (site.siteActive === false) {
    history.push(`/sites/${siteId}`);
  }

  if (hasErrors) {
    return <ErrorPage status={errorStatus} />;
  }

  return (
    <SiteSupplierForm
      isEditForm
      isReadOnly={!sitesWrite}
      initialValues={prepareInitialValues({ initialValues: site })}
    />
  );
};

export default withSiteShared(SiteSupplierEdit);
