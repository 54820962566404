import { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { TagV1 as Tag } from "@trace-one/design-system";
import { Typography } from "@trace-one/design-system";
import { ColumnsType } from "antd/lib/table";
import cn from "classnames";

import { useAppDispatch } from "reduxStore";
import { ManufacturedItemState } from "reduxStore/productDetails/models";
import { selectCountriesOptions } from "reduxStore/shared/selectors";
import { setBackButtonData } from "reduxStore/shared/slice";

import { CompanyType, ManufacturingItemStatus } from "shared/constants";

import { ProductVersionsResult } from "../../models";
import TargetMarketList from "../components/TargetMarketList";
import styles from "../ManufacturedTable.module.less";
import { ManuItemsColumn } from "../models";

const mapManuItemsToColumn = ({
  manuItem,
  countriesList,
  versionDetails,
  previousVersionDetails,
  PreviousManufacturedItems,
}) => {
  const countriesOfOrigin = manuItem.countriesOfOrigin
    ?.map(countryCode => countriesList.find(c => c.value === countryCode)?.name)
    .join(", ");

  const previousManuItem = PreviousManufacturedItems?.find(
    m => m.id === manuItem.id
  );
  const name = manuItem.manufacturedItemName
    ? manuItem.manufacturedItemName
    : versionDetails.itemName;
  const previousName = previousManuItem?.manufacturedItemName
    ? previousManuItem?.manufacturedItemName
    : previousVersionDetails?.itemName;

  return {
    key: manuItem.id,
    manufacturedItemName: name,
    packerCompany: manuItem.packerCompanyId?.label,
    manufacturedItemStatus: manuItem.manufacturedItemStatus,
    countriesOfOrigin,
    targetMarketsList: manuItem.targetMarketsList,
    previousTargetMarketsList: previousManuItem?.targetMarketsList,
    supplier: manuItem.supplierCompanyId,
    rowChanged: !previousManuItem,
    itemNameChanged: previousManuItem ? name !== previousName : false,
    countriesChanged: previousManuItem
      ? !(
          manuItem.countriesOfOrigin.length ===
            previousManuItem.countriesOfOrigin.length &&
          manuItem.countriesOfOrigin.every(
            (v, i) => previousManuItem.countriesOfOrigin[i] === v
          )
        )
      : false,
    supplierChanged: previousManuItem
      ? manuItem.supplierCompanyId.value !==
        previousManuItem.supplierCompanyId.value
      : false,
    statusChanged: previousManuItem
      ? manuItem.manufacturedItemStatus !==
        previousManuItem.manufacturedItemStatus
      : false,
  };
};

export default function useManufacturedItemsTable({
  manufacturedItems,
  PreviousManufacturedItems,
  versionDetails,
  previousVersionDetails,
}: {
  manufacturedItems: ManufacturedItemState[];
  PreviousManufacturedItems: ManufacturedItemState[];
  versionDetails: ProductVersionsResult;
  previousVersionDetails: ProductVersionsResult;
}) {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { productId } = useParams<{ productId?: string }>();
  const history = useHistory();
  const countriesList = useSelector(selectCountriesOptions);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  useEffect(() => {
    const ids = manufacturedItems
      .filter(m => !!m.targetMarketsList.length)
      .map(m => m.id);
    setExpandedRowKeys(ids);
  }, [manufacturedItems]);

  const columns: ColumnsType<ManuItemsColumn> = [
    {
      title: intl.formatMessage({ id: "productDetails.manufacturedItemName" }),
      dataIndex: "manufacturedItemName",
      key: "manufacturedItemName",
      width: 200,
      ellipsis: true,
      render: (manufacturedItemName, record) => {
        return (
          <Typography
            className={record.itemNameChanged && "changedField"}
            component="span"
          >
            {manufacturedItemName}
          </Typography>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "productDetails.countriesOfOrigin" }),
      dataIndex: "countriesOfOrigin",
      key: "countriesOfOrigin",
      width: 200,
      ellipsis: true,
      render: (countriesOfOrigin, record) => (
        <span className={record.countriesChanged && "changedField"}>
          {countriesOfOrigin || <>&nbsp;</>}
        </span>
      ),
    },
    {
      title: intl.formatMessage({ id: "general.supplier" }),
      dataIndex: "supplier",
      key: "supplier",
      width: 200,
      ellipsis: true,
      render: (supplier, record) => {
        const companyName = [
          supplier.label,
          supplier.companyRelationExternalName
            ? supplier.companyDisplayName
            : "",
        ];
        return (
          <>
            <div
              className={cn(
                styles.supplierNameSection,
                record.supplierChanged && "changedField"
              )}
            >
              <Typography
                component="a"
                data-test-id={`detail-supplier-${supplier.value}`}
                onClick={() => {
                  dispatch(
                    setBackButtonData({
                      fromUrl: `/products/history/${productId}`,
                    })
                  );
                  history.push(`/suppliers/${supplier.value}`);
                }}
              >
                <div
                  className={cn(
                    styles.supplierTableName,
                    supplier.companyType === CompanyType.PRIVATE &&
                      styles.privateCompanyTitle
                  )}
                >
                  {companyName.map(
                    (name, i) => name && <span key={i}>{name}</span>
                  )}
                </div>
              </Typography>
              {!supplier.companyRelationStatus && (
                <Tag
                  label={intl.formatMessage({ id: "general.inactive" })}
                  size="small"
                  color="grey"
                  mode="light"
                />
              )}
            </div>
          </>
        );
      },
      // render: supplier => <span>{supplier.label}</span>,
    },
    {
      title: intl.formatMessage({ id: "general.archived" }),
      dataIndex: "manufacturedItemStatus",
      key: "manufacturedItemStatus",
      width: 90,
      ellipsis: true,
      render: (manufacturedItemStatus, record) => {
        return (
          <span className={record.statusChanged && "changedField"}>
            <FormattedMessage
              id={
                manufacturedItemStatus === ManufacturingItemStatus.ACTIVE
                  ? "general.no"
                  : "general.yes"
              }
            />
          </span>
        );
      },
    },
  ];

  const data =
    Array.isArray(manufacturedItems) &&
    manufacturedItems.map(manuItem =>
      mapManuItemsToColumn({
        manuItem,
        countriesList,
        versionDetails,
        previousVersionDetails,
        PreviousManufacturedItems,
      })
    );

  const expandable = {
    expandedRowKeys,
    expandIcon: ({ expanded, onExpand, record }) => {
      return (
        !!record.targetMarketsList.length && (
          <div
            data-test-id={`expand-button-${record.key}`}
            onClick={e => onExpand(record, e)}
            className={cn(styles.expandableCenter, expanded && styles.expanded)}
          >
            <span>&#8735;</span>
          </div>
        )
      );
    },
    expandedRowClassName: () => "marketRowPadding",
    expandedRowRender: ({ targetMarketsList, previousTargetMarketsList }) => (
      <TargetMarketList
        targetMarketsList={targetMarketsList}
        previousTargetMarketsList={previousTargetMarketsList}
      />
    ),
    rowExpandable: ({ targetMarketsList }) => !!targetMarketsList.length,
    onExpand: (expandable, record) => {
      if (expandable) {
        setExpandedRowKeys([...expandedRowKeys, record.key]);
      } else {
        setExpandedRowKeys(expandedRowKeys.filter(id => record.key !== id));
      }
    },
  };

  return { columns, data, expandable };
}
