import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Tooltip, TagV1 as Tag, Icon } from "@trace-one/design-system";
import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import cn from "classnames";

import { CompanyData } from "models";

import {
  selectCountriesMap,
  selectCompanyActivityData,
} from "reduxStore/shared/selectors";

import { CompanyType } from "shared/constants";
import buildCompanyTitle from "shared/utils/buildCompanyTitle";

import { SupplierColumn } from "../models";
import SupplierAction from "../SupplierAction";
import styles from "../SupplierList.module.less";
export default function useSupplierTable({
  suppliers,
  refetchSuppliers,
  handleContactModel,
}: {
  suppliers: CompanyData[];
  refetchSuppliers: () => void;
  handleContactModel: (value: {}) => void;
}) {
  const intl = useIntl();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const countriesMap = useSelector(selectCountriesMap);
  const { data: companyActivity } = useSelector(selectCompanyActivityData);
  const columns: ColumnsType<SupplierColumn> = [
    {
      title: formatMessage({ id: "general.name" }),
      dataIndex: "companyDisplayName",
      key: "companyDisplayName",
      width: 200,
      ellipsis: true,
      render: (supplier, record) => (
        <>
          <Typography.Link
            onClick={() => {
              history.push(`/suppliers/${record.key}`);
            }}
          >
            <div
              className={cn(
                styles.supplierTableName,
                record?.companyType === CompanyType.PRIVATE &&
                  styles.privateCompanyTitle
              )}
            >
              {supplier.map(name => name && <span>{name}</span>)}
            </div>
          </Typography.Link>
        </>
      ),
    },

    {
      title: formatMessage({ id: "supplierDetails.activity" }),
      dataIndex: "companyActivityName",
      key: "companyActivityName",
      width: 150,
      ellipsis: true,
      render: companyActivityName => (
        <>
          <Tooltip
            showFullText
            text={companyActivityName?.text}
            placement="top"
          >
            <div className={styles.supplierListTooltip}>
              {companyActivityName?.text}
            </div>
          </Tooltip>
        </>
      ),
    },
    {
      title: formatMessage({ id: "general.supplierCode" }),
      dataIndex: "code",
      key: "code",
      width: 120,
      ellipsis: true,
      render: code => (
        <>
          <Tooltip showFullText text={code} placement="top">
            <div className={styles.supplierListTooltip}>{code}</div>
          </Tooltip>
        </>
      ),
    },
    {
      title: formatMessage({ id: "general.country" }),
      dataIndex: "country",
      key: "country",
      width: 150,
      ellipsis: true,
      render: country => (
        <>
          <Tooltip showFullText text={country} placement="top">
            <div className={styles.supplierListTooltip}>{country}</div>
          </Tooltip>
        </>
      ),
    },
    {
      title: intl.formatMessage({
        id: "siteForm.contact",
      }),
      dataIndex: "sitecontact",
      key: "sitecontact",
      width: 110,
      ellipsis: true,
      render: (_, record) => {
        let count = record?.rawData?.contacts?.length;
        return (
          <>
            {count === 0 ? (
              "-"
            ) : (
              <>
                <Button
                  type="link"
                  data-test-id="md-companyRelationList-modal"
                  onClick={() => handleContactModel(record.rawData)}
                >
                  <div>{count}</div>
                </Button>
              </>
            )}
          </>
        );
      },
    },
    {
      title: formatMessage({ id: "general.relationStatus" }),
      dataIndex: "isCompanyRelationStatusEnabled",
      key: "isCompanyRelationStatusEnabled",
      width: 120,
      ellipsis: true,
      render: isCompanyRelationStatusEnabled => {
        let tagData = {
          label: intl.formatMessage({ id: "general.inactive" }),
          color: "grey",
        };
        if (isCompanyRelationStatusEnabled) {
          tagData = {
            label: intl.formatMessage({ id: "general.active" }),
            color: "green",
          };
        }
        return (
          <div className={styles.status}>
            <Tag mode="light" {...tagData} />
          </div>
        );
      },
    },
    {
      title: <Icon style={{ margin: "auto" }} name="settings" color="white" />,
      dataIndex: "actions",
      key: "actions",
      width: 40,
      fixed: "right",
      ellipsis: true,
      render: (_, record) => (
        <SupplierAction record={record} refetchSuppliers={refetchSuppliers} />
      ),
    },
  ];

  const data: SupplierColumn[] = suppliers.map(supplier => ({
    key: supplier.companyId,
    companyDisplayName: [
      buildCompanyTitle(supplier),
      supplier.companyRelationExternalName ? supplier.companyDisplayName : "",
    ],
    companyType: supplier.companyType,
    code: supplier.seExternalCode,
    postalCode: supplier.companyPostalCode,
    city: supplier.companyCity,
    provinceStateRegion: supplier.companyState,
    country: countriesMap[supplier.companyCountry],
    companyStatus: supplier.companyStatus,
    isCompanyRelationStatusEnabled: supplier.isCompanyRelationStatusEnabled,
    companyActivityId: supplier.companyActivityId,
    companyActivityName: companyActivity.find(
      item => item.itemCode === supplier.companyActivityId.toString()
    ),

    rawData: supplier,
  }));

  return { columns, data };
}
