import { Button } from "@trace-one/design-system";
import PropTypes from "prop-types";

import styles from "./BtnCreateListItem.module.less";

interface BtnCreateListItemProps extends React.HTMLProps<HTMLDivElement> {
  text: React.ReactNode;
  onClick: () => void;
  iconName: string;
  hideButton?: boolean;
}

const BtnCreateListItem: React.FC<BtnCreateListItemProps> = ({
  text,
  onClick,
  iconName,
  hideButton,
  ...rest
}) => {
  return (
    <div className={styles.root} {...rest}>
      {!hideButton && (
        <Button size="m" onClick={onClick} data-test-id="md-create-btn">
          {text}
        </Button>
      )}
    </div>
  );
};

BtnCreateListItem.propTypes = {
  text: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  iconName: PropTypes.string.isRequired,
};

export default BtnCreateListItem;
