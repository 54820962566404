import { useMemo } from "react";
import { useLocation } from "react-router";

import qs from "qs";

export default function useRouteSearchParams<T extends object>(options?) {
  const { search } = useLocation();
  const searchParamsObj = useMemo<Partial<T>>(
    () =>
      qs.parse(search, {
        ignoreQueryPrefix: true,
        ...options,
      }) as any,
    [search]
  );
  return searchParamsObj;
}
