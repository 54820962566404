import { CategoryListData } from "models";

type State = {
  classifications: CategoryListData[];
  isClassificationLoading: boolean;
  hasClassificationError: boolean;
};

export const initialState: State = {
  classifications: [],
  isClassificationLoading: false,
  hasClassificationError: false,
};
