import { FormattedMessage } from "react-intl";

import { ErrorCode, hasErrorOccurred } from "shared/errors";
import { FormItemFeedback } from "shared/typings";

export default async function getFailedSubmitResult({
  error,
}: {
  error: any;
}): Promise<{
  internalCode: FormItemFeedback;
}> {
  if (
    hasErrorOccurred({
      error,
      errorCode: ErrorCode.INTERNAL_CODE_ALREADY_EXISTED,
    })
  ) {
    return {
      internalCode: {
        hasFeedback: true,
        validateStatus: "warning",
        help: (
          <span data-test-id="md-warning-internalcode-is-not-unique">
            <FormattedMessage id="siteForm.siteIdentifier.errorCodeUsed" />
          </span>
        ),
      },
    };
  }
  if (hasErrorOccurred({ error, errorCode: ErrorCode.USER_NOT_ACTIVE })) {
    setTimeout(() => {
      document.querySelector("#md-form-user-contact")?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }, 10);
    return;
  }
}
