import { useIntl } from "react-intl";

import PropTypes from "prop-types";

import styles from "./EnterAtLeast.module.less";

const EnterAtLeast = ({ value }) => {
  const intl = useIntl();

  return (
    <div className={styles.root}>
      <span className={styles.enterAtLeastMsg}>
        {intl.formatMessage(
          { id: "general.enterAtLeast" },
          {
            number: value,
          }
        )}
      </span>
    </div>
  );
};

EnterAtLeast.propTypes = {
  value: PropTypes.number.isRequired,
};

export default EnterAtLeast;
