import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading } from "@trace-one/design-system";
import { Form } from "antd";

import { PmdAPI } from "apis";
import { BrandData } from "models";

import { useAppDispatch } from "reduxStore";
import { fetchBrands } from "reduxStore/shared/asyncActions";
import {
  selectUserLanguageCode,
  selectUserOwningCompanyId,
} from "reduxStore/user/selectors";

import FormModal, { FormModalContent } from "components/FormModal";
import { layout } from "components/FormWrapper";
import Input from "components/Input";
import { ErrorCode, hasErrorOccurred } from "shared/errors";
import useToast from "shared/hooks/useToast";

const brandFormId = "brand-form";

interface BrandFormProps {
  initialValues?: BrandData;
  onSubmitSuccess?: () => void;
  isSubmitting?: boolean;
  setIsSubmitting?: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel?: () => void;
  setSelectedBrand?: (value) => void;
  isEditBrand?: boolean;
}

interface BrandFormModalProps extends BrandFormProps {
  visible: boolean;
  refetchBrands?: () => void;
  setSelectedBrand?: (value) => void;
  isEditBrand?: boolean;
}

export const BrandForm: React.FC<BrandFormProps> = ({
  initialValues,
  onSubmitSuccess,
  onCancel,
  isEditBrand,
  setSelectedBrand,
  isSubmitting,
  setIsSubmitting,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const languageCode = useSelector(selectUserLanguageCode);
  const [submitErrors, setSubmitErrors] = useState<{
    externalCode?: boolean;
    brandName?: boolean;
  }>({
    externalCode: false,
    brandName: false,
  });
  const [validator, setValidator] = useState({
    externalCode: false,
    brandName: false,
  });

  const formdata = form.getFieldsValue();
  const toast = useToast();

  const handleSubmit = async values => {
    setIsSubmitting(true);
    try {
      if (isEditBrand) {
        const { data } = await PmdAPI.createBrand({
          ...values,
          ownerCompanyId,
        });
        if (data.brandId) {
          await dispatch(fetchBrands({ ownerCompanyId, languageCode }));
          setSelectedBrand(data.brandId);
        }

        onCancel();
        if (setIsSubmitting) {
          setIsSubmitting(false);
        }
        if (onSubmitSuccess) onSubmitSuccess();
      } else {
        await (initialValues?.id
          ? PmdAPI.updateBrandName(initialValues.id, {
              ...initialValues,
              ...values,
            })
          : PmdAPI.createBrand({ ...values, ownerCompanyId }));

        if (setIsSubmitting) {
          setIsSubmitting(false);
        }
        if (onSubmitSuccess) onSubmitSuccess();
      }
    } catch (error) {
      if (
        hasErrorOccurred({
          error,
          errorCode: ErrorCode.BRAND_EXTERNAL_CODE_ALREADY_EXISTED,
        })
      ) {
        setSubmitErrors(prev => ({ ...prev, externalCode: true }));
      } else {
        toast.saveError({ error });
      }
      if (setIsSubmitting) {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <FormModalContent
      form={form}
      {...layout}
      initialValues={initialValues}
      id={brandFormId}
      onFinish={handleSubmit}
      onFinishFailed={({ errorFields }) => {
        if (
          errorFields[0]?.name[0] === "brandName" &&
          errorFields[1]?.name[0] === "externalCode"
        ) {
          setValidator({ externalCode: true, brandName: true });
        } else if (errorFields[0]?.name[0] === "brandName") {
          setValidator(prev => ({ ...prev, brandName: true }));
        } else if (errorFields[0]?.name[0] === "externalCode") {
          setValidator(prev => ({ ...prev, externalCode: true }));
        } else {
          setValidator({ externalCode: false, brandName: false });
        }
      }}
      onValuesChange={changedValues => {
        if ("externalCode" in changedValues) {
          setValidator(prev => ({ ...prev, externalCode: false }));
          setSubmitErrors(prev => ({ ...prev, externalCode: false }));
        }
        if ("brandName" in changedValues) {
          setValidator(prev => ({ ...prev, brandName: false }));
          setSubmitErrors(prev => ({ ...prev, brandName: false }));
        }

        // if ("externalCode" in changedValues) {
        //   setSubmitErrors(prev => ({ ...prev, externalCode: {} }));
        // }
      }}
      onCancel={onCancel}
      isSubmitting={isSubmitting}
    >
      <div>
        <Form.Item
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "general.name" })}
            </Heading>
          }
          name="brandName"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "",
            },
          ]}
        >
          <Input
            data-test-id="md-brand-form-brandName"
            error={formdata.brandName === "" || validator.brandName}
            errorDataTestId="md-error-brandName-is-required"
            errorMessage={intl.formatMessage({ id: "general.valueIsRequired" })}
            maxLength={256}
          />
        </Form.Item>
        <Form.Item
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "general.code" })}
            </Heading>
          }
          name="externalCode"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "",
            },
          ]}
        >
          <Input
            data-test-id="md-brand-form-externalCode"
            error={
              formdata.externalCode === "" ||
              validator.externalCode ||
              submitErrors.externalCode
            }
            errorDataTestId={
              submitErrors.externalCode
                ? "md-brand-form-external-code-is-not-unique"
                : "md-error-externalCode-is-required"
            }
            errorMessage={
              submitErrors.externalCode
                ? intl.formatMessage({
                    id: "brandForm.externalCode.errors.usedByAnotherBrand",
                  })
                : intl.formatMessage({ id: "general.valueIsRequired" })
            }
            maxLength={36}
          />
        </Form.Item>
      </div>
    </FormModalContent>
  );
};

const BrandFormModal: React.FC<BrandFormModalProps> = ({
  visible,
  onCancel,
  refetchBrands,
  isEditBrand,
  setSelectedBrand,
  initialValues,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const intl = useIntl();

  return (
    <FormModal
      title={
        <Heading size="m">
          {intl.formatMessage({ id: "general.brand" })}{" "}
        </Heading>
      }
      visible={visible}
      onCancel={onCancel}
      closable={!isSubmitting}
    >
      <BrandForm
        initialValues={initialValues}
        onSubmitSuccess={() => {
          onCancel();
          refetchBrands && refetchBrands();
        }}
        setIsSubmitting={setIsSubmitting}
        isSubmitting={isSubmitting}
        onCancel={onCancel}
        setSelectedBrand={setSelectedBrand}
        isEditBrand={isEditBrand}
      />
    </FormModal>
  );
};

export default BrandFormModal;
