import { createAsyncThunk } from "@reduxjs/toolkit";

import { SmdAPI, CumdAPI } from "apis";
import { SiteData, CompanyData, UserData } from "models";

export const fetchSite = createAsyncThunk<
  {
    site: SiteData;
    supplier: CompanyData;
    contactUsers: UserData[];
  },
  string,
  { rejectValue: { status: number } }
>("siteSupplierDetails/fetchSite", async (siteId: string, thunkAPI) => {
  try {
    const { data: site } = await SmdAPI.getSiteById(siteId);

    const [supplier, contactUsers] = await Promise.all([
      await CumdAPI.getCompanyById(site.ownerCompanyId).then(
        ({ data }) => data
      ),
      site.contactUserIds.length
        ? await CumdAPI.getUsersByUserIds({
            userIds: site.contactUserIds,
          }).then(({ data }) => data)
        : [],
    ]);

    return {
      site,
      supplier,
      contactUsers,
    };
  } catch (err) {
    const status: number = err?.response?.status ?? 500;
    return thunkAPI.rejectWithValue({ status });
  }
});

export const fetchSiteRelations = createAsyncThunk(
  "siteSupplierDetails/fetchSiteRelations",
  async (siteId: string) => {
    const { data } = await SmdAPI.getSiteRelations(siteId);
    return data;
  }
);
