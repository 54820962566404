import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./FlexWrap.module.less";

interface FlexWrapProps extends React.HTMLProps<HTMLDivElement> {
  disableSpacing?: boolean;
}

/**
 * FlexWrap fix problem with wrapping contents on IE11
 * https://github.com/philipwalton/flexbugs/issues/179
 */
const FlexWrap: React.FC<FlexWrapProps> = ({
  children,
  disableSpacing,
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={cn(
        styles.root,
        { [styles.spacing]: !disableSpacing },
        rest.className
      )}
    >
      <div className={styles.inner}>{children}</div>
    </div>
  );
};

FlexWrap.propTypes = {
  children: PropTypes.node,
};

export default FlexWrap;
