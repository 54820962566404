/* istanbul ignore file */
import React from "react";

import { Icon } from "@trace-one/design-system";
import { Dropdown, DropDownProps } from "antd";
import PropTypes from "prop-types";

import IconButton from "components/IconButton";

import styles from "./ActionDropdown.module.less";

export interface ActionDropdownProps extends DropDownProps {
  "data-test-id"?: string;
  size?: string;
}

const ActionDropdown: React.FC<ActionDropdownProps> = ({
  overlay,
  "data-test-id": dataTestId,
  size = "m",
  ...rest
}) => {
  return (
    <Dropdown
      overlay={overlay}
      overlayClassName={styles.overlay}
      trigger={["click"]}
      {...rest}
    >
      <IconButton
        className={styles.root}
        disabled={rest.disabled}
        data-test-id={dataTestId}
      >
        <div className={styles.iconWrapper}>
          <Icon name="Kebab" size="medium" />
        </div>
      </IconButton>
    </Dropdown>
  );
};

ActionDropdown.propTypes = {
  overlay: PropTypes.element,
};

export default ActionDropdown;
