import { createAsyncThunk } from "@reduxjs/toolkit";

import { CumdAPI } from "apis";

import getAuthorsComments from "shared/utils/getAuthorsComments";
import getContacts from "shared/utils/getContacts";

export const fetchSupplierForm = createAsyncThunk(
  "supplierForm/fetchSupplierForm",
  async (
    {
      supplierId,
      ownerCompanyId,
    }: { supplierId: string; ownerCompanyId: string },
    thunkAPI
  ) => {
    try {
      const [company, rawCompanyRelation] = await Promise.all([
        CumdAPI.getCompanyById(supplierId).then(({ data }) => data),
        CumdAPI.getCompanyRelation(ownerCompanyId, {
          associatedCompanyId: supplierId,
        }).then(({ data }) => data),
      ]);
      const [comments, contacts] = await Promise.all([
        getAuthorsComments({
          comments: rawCompanyRelation.comments,
        }),
        getContacts(rawCompanyRelation?.contacts),
      ]);

      return {
        company,
        companyRelation: { ...rawCompanyRelation, comments, contacts },
      };
    } catch (err) {
      const status = err?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({ status });
    }
  }
);
