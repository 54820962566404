import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { ColumnsType } from "antd/lib/table";
import moment from "moment";

import { ManufacturedItemState } from "reduxStore/productDetails/models";
import {
  selectCountriesOptions,
  selectPointOfSalesOptions,
} from "reduxStore/shared/selectors";

const mapTargetMarketItemToColumn = ({
  targetItem,
  previousTargetMarketsList,
}) => {
  const startDate = !!targetItem.startAvailabilityDate
    ? moment(targetItem.startAvailabilityDate).format(
        moment.localeData().longDateFormat("L")
      )
    : "";
  const endDate = !!targetItem.endAvailabilityDate
    ? moment(targetItem.endAvailabilityDate).format(
        moment.localeData().longDateFormat("L")
      )
    : "";
  let isChanged = true;
  previousTargetMarketsList?.forEach(item => {
    if (!isChanged) {
      return;
    }
    const sales =
      targetItem.pointsOfSales.length === item.pointsOfSales.length &&
      targetItem.pointsOfSales.every((v, i) => item.pointsOfSales[i] === v);
    const countries =
      targetItem.countryCodes.length === item.countryCodes.length &&
      targetItem.countryCodes.every((v, i) => item.countryCodes[i] === v);
    const dates =
      targetItem.startAvailabilityDate === item.startAvailabilityDate &&
      targetItem.endAvailabilityDate === item.endAvailabilityDate;

    if (sales && countries && dates) {
      isChanged = false;
    }
  });

  return {
    key: targetItem.id,
    countryCodes: targetItem.countryCodes,
    pointsOfSales: targetItem.pointsOfSales,
    availabilityDates: `${startDate} - ${endDate}`,
    rowChanged: isChanged,
  };
};

export default function useTargetMarketListTable({
  targetMarketsList,
  previousTargetMarketsList,
}: {
  targetMarketsList: ManufacturedItemState["targetMarketsList"];
  previousTargetMarketsList: ManufacturedItemState["targetMarketsList"];
}) {
  const intl = useIntl();
  const countriesList = useSelector(selectCountriesOptions);
  const pointOfSalesList = useSelector(selectPointOfSalesOptions);

  const columns: ColumnsType<any> = [
    {
      title: intl.formatMessage({ id: "productDetails.targetMarkets" }),
      dataIndex: "countryCodes",
      key: "countryCodes",
      width: 200,
      ellipsis: true,
      render: countryCodes => {
        return (
          <span>
            {countryCodes
              .map(code => countriesList.find(c => c.value === code)?.name)
              .join(", ")}
          </span>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "productDetails.pointOfSales" }),
      dataIndex: "pointsOfSales",
      key: "pointsOfSales",
      width: 200,
      ellipsis: true,
      render: pointsOfSales => {
        return (
          <span>
            {pointsOfSales
              .map(point => pointOfSalesList.find(p => p.value === point)?.name)
              .join(", ")}
          </span>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "productDetails.availabilityDates" }),
      dataIndex: "availabilityDates",
      key: "availabilityDates",
      width: 200,
      ellipsis: true,
      render: availabilityDates => {
        return <span>{availabilityDates}</span>;
      },
    },
  ];

  const data =
    Array.isArray(targetMarketsList) &&
    targetMarketsList.map(targetItem =>
      mapTargetMarketItemToColumn({ targetItem, previousTargetMarketsList })
    );

  return { columns, data };
}
