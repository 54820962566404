import { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Heading, Paragraph } from "@trace-one/design-system";
import { Tooltip } from "@trace-one/react-components";
import { Col, Row } from "antd";
import cn from "classnames";
import { PageCol } from "pages/Sites/constants";
import useSupplierAddress from "pages/Suppliers/hooks/useSupplierAddress";
import useSupplierDeliveryAddress from "pages/Suppliers/hooks/useSupplierDeliveryAddress";

import { SmdAPI, PmdAPI } from "apis";

import { selectIdentifiersOptions } from "reduxStore/shared/selectors";
import { selectSupplierDetails } from "reduxStore/SupplierDetails/selectors";
import {
  setSupplierId,
  setSupplierName,
} from "reduxStore/SupplierDetails/slice";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import Label from "components/Label";
import Panel from "components/Panel";
import TabPage from "components/TabPage";
import {
  CompanyActivityId,
  CompanyType,
  TradeItemStatus,
} from "shared/constants";
import buildCompanyTitle from "shared/utils/buildCompanyTitle";

import styles from "../../SupplierDetails.module.less";

interface MainAdditionalInformationProps {}

const MainAdditionalInformation: React.FC<
  MainAdditionalInformationProps
> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { company } = useSelector(selectSupplierDetails);
  const identifiersOptions = useSelector(selectIdentifiersOptions);
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const tradeItemStatuses = [TradeItemStatus.ACTIVE];
  const [disableProductLink, setDisableProductLink] = useState<boolean>(false);
  const [disableSiteLink, setDisableSiteLink] = useState<boolean>(false);
  const history = useHistory();
  const { hasAddress, addressData } = useSupplierAddress(company);

  const {
    hasDeliveryAddress,
    deliveryAddressData,
    showDeliveryAddressGmapLink,
    deliveryAddressGmapLink,
  } = useSupplierDeliveryAddress(company);

  useEffect(() => {
    handleProductLink();
    handleSiteLink();
  }, []);

  const handleProductLink = async () => {
    const supplierCompanyId = company.companyId;
    const { data } = await PmdAPI.getTradeItemsByFilters(
      { tradeItemStatuses, supplierCompanyIds: [supplierCompanyId] },
      { ownerCompanyId }
    );
    if (data.tradeItems.length === 0) setDisableProductLink(true);
  };

  const handleSiteLink = async () => {
    const associatedCompanyId = company.companyId;
    const { data } = await SmdAPI.getRelationsByFilters(
      { ownerCompanyIdCollection: [associatedCompanyId] },
      { associatedCompanyId: ownerCompanyId, siteActive: true }
    );
    if (data.sites.length === 0) setDisableSiteLink(true);
  };

  return (
    <>
      <Row>
        <Col
          {...PageCol.HALF}
          className={styles.fullHightPanel}
          style={{ paddingRight: "15px" }}
        >
          <Panel>
            <TabPage.SecondaryTitle
              label={
                <Heading size="xs">
                  {formatMessage({ id: "supplierDetails.mainInformation" })}
                </Heading>
              }
            />
            <Col {...PageCol.FULL} className={styles.borderMain}>
              <Label
                label={
                  <Heading size="xxs">
                    {formatMessage({ id: "supplierDetails.companyName" })}
                  </Heading>
                }
                testId="supplierDetails-companyName"
              >
                <Paragraph size="m">
                  <div
                    className={cn(
                      styles.supplierDetailsParagraph,
                      company?.companyType === CompanyType.PRIVATE &&
                        styles.privateCompanyTitle
                    )}
                  >
                    {company.companyDisplayName}
                  </div>
                </Paragraph>
              </Label>

              <Label
                label={
                  <Heading size="xxs">
                    {formatMessage({ id: "supplier.mainInformation.codes" })}
                  </Heading>
                }
                testId="supplierDetails-Codes"
              >
                <Paragraph size="m">
                  {company?.companyIdentifiers !== null &&
                    company?.companyIdentifiers?.map(
                      ({ value, typeId }, idx) => {
                        const typeLabel = identifiersOptions.find(
                          i => typeId === i.value
                        )?.name;
                        return (
                          <div
                            key={idx}
                            className={styles.supplierDetailsParagraph}
                          >{`${value} (${typeLabel})`}</div>
                        );
                      }
                    )}
                </Paragraph>
              </Label>
            </Col>

            <TabPage.SecondaryTitle
              label={
                <Heading size="xs">
                  {formatMessage({
                    id: "supplierDetails.additionalInformation",
                  })}
                </Heading>
              }
            />
            <Col {...PageCol.FULL}>
              <Label
                label={
                  <Heading size="xxs">
                    {formatMessage({ id: "supplierDetails.companySize" })}
                  </Heading>
                }
                testId="supplierDetails-companySize"
              >
                <Paragraph size="m">
                  <div className={styles.supplierDetailsParagraph}>
                    {company.companySize}
                  </div>
                </Paragraph>
              </Label>
              <Label
                label={
                  <Heading size="xxs">
                    {formatMessage({ id: "supplierDetails.companyRevenue" })}
                  </Heading>
                }
                testId="supplierDetails-companyRevenue"
              >
                <Paragraph size="m">
                  {company.companyRevenue
                    ? `${company.companyRevenue} ${
                        company.companyCurrency ?? ""
                      }`
                    : ""}
                </Paragraph>
              </Label>
              <Label
                label={
                  <Heading size="xxs">
                    {formatMessage({
                      id: "supplierDetails.productionCapacity",
                    })}
                  </Heading>
                }
                testId="supplierDetails-productionCapacity"
              >
                <Paragraph size="m">
                  <div className={styles.supplierDetailsParagraph}>
                    {company.companyProductionCapacity}
                  </div>
                </Paragraph>
              </Label>
            </Col>
          </Panel>
        </Col>

        <Col {...PageCol.HALF} style={{ paddingLeft: "15px" }}>
          <Col {...PageCol.FULL}>
            <Panel>
              <TabPage.SecondaryTitle
                label={
                  <Heading size="xs">
                    {formatMessage({ id: "supplierDetails.address" })}
                  </Heading>
                }
              />
              <Col {...PageCol.FULL}>
                <Label
                  label={
                    <Heading size="xxs">
                      {formatMessage({ id: "general.address" })}
                    </Heading>
                  }
                  bordered
                  testId="supplier-address-value"
                >
                  <Paragraph size="m">
                    {hasAddress &&
                      addressData.map((ln, idx) => (
                        <div
                          key={idx}
                          className={styles.supplierDetailsParagraph}
                        >
                          {ln}
                        </div>
                      ))}
                  </Paragraph>
                </Label>
              </Col>
              <Col {...PageCol.FULL}>
                <Label
                  label={
                    <Heading size="xxs">
                      {formatMessage({
                        id: "supplierDetails.deliveryAddress",
                      })}
                    </Heading>
                  }
                  bordered
                  testId="supplier-delivery-address-value"
                >
                  <Paragraph size="m">
                    {hasDeliveryAddress &&
                      deliveryAddressData.map((ln, idx) => (
                        <div
                          key={idx}
                          className={styles.supplierDetailsParagraph}
                        >
                          {ln}
                        </div>
                      ))}
                    {showDeliveryAddressGmapLink && (
                      <Button
                        type="link"
                        size="smaill"
                        iconName="link"
                        iconPlacement="right"
                        onClick={() => {
                          window.open(deliveryAddressGmapLink);
                        }}
                        className={styles.openGmap}
                      >
                        <div className={styles.linkWrapper}>
                          <FormattedMessage id="general.openGmap" />
                        </div>
                      </Button>
                    )}
                  </Paragraph>
                </Label>
              </Col>
            </Panel>
          </Col>

          <Col {...PageCol.FULL} style={{ marginTop: "30px" }}>
            {company.companyActivityId === CompanyActivityId.SUPPLIER && (
              <Panel>
                <TabPage.SecondaryTitle
                  label={
                    <Heading size="xs">
                      {formatMessage({ id: "supplierDetails.links" })}
                    </Heading>
                  }
                />
                <Col {...PageCol.FULL}>
                  <Label
                    label={
                      <Heading size="xxs">
                        {formatMessage({ id: "header.navMenu.sites" })}
                      </Heading>
                    }
                    bordered
                    testId="supplier-link-sites"
                  >
                    <Tooltip
                      title={formatMessage({
                        id: "supplierDetails.NoActiveSites",
                      })}
                      placement="leftTop"
                      overlayStyle={{ maxWidth: "280px" }}
                      trigger={disableSiteLink ? ["focus", "hover"] : []}
                    >
                      {
                        <Button
                          type="link"
                          size="smaill"
                          onClick={() => {
                            dispatch(setSupplierId(company.companyId));
                            dispatch(
                              setSupplierName(buildCompanyTitle(company))
                            );
                            history.push(`/sites/list`);
                          }}
                          disabled={disableSiteLink}
                        >
                          <div className={styles.linkWrapper}>
                            {formatMessage({
                              id: "supplierDetails.seeTheSites",
                            })}
                          </div>
                        </Button>
                      }
                    </Tooltip>
                  </Label>
                </Col>
                <Col {...PageCol.FULL}>
                  <Label
                    label={
                      <Heading size="xxs">
                        {formatMessage({
                          id: "header.navMenu.products",
                        })}
                      </Heading>
                    }
                    bordered
                    testId="supplier-link-products"
                  >
                    <Tooltip
                      title={formatMessage({
                        id: "supplierDetails.NoActiveProducts",
                      })}
                      placement="leftTop"
                      overlayStyle={{ maxWidth: "280px" }}
                      trigger={disableProductLink ? ["focus", "hover"] : []}
                    >
                      {
                        <Button
                          type="link"
                          size="smaill"
                          data-test-id="supplierDetails-NoActiveProducts"
                          onClick={() => {
                            dispatch(setSupplierId(company.companyId));
                            dispatch(
                              setSupplierName(buildCompanyTitle(company))
                            );
                            history.push(`/products/list`);
                          }}
                          disabled={disableProductLink}
                        >
                          <div className={styles.linkWrapper}>
                            {formatMessage({
                              id: "supplierDetails.seeTheProduct",
                            })}
                          </div>
                        </Button>
                      }
                    </Tooltip>
                  </Label>
                </Col>
              </Panel>
            )}
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default MainAdditionalInformation;
