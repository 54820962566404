import React from "react";

import styles from "./Footer.module.less";

const Footer: React.FC = () => {
  return (
    <div className={styles.footerWrapper}>
      {window.env.hasOwnProperty("APP_VERSION") && (
        <span>Version {window.env.APP_VERSION}</span>
      )}
    </div>
  );
};

export default Footer;
