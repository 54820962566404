import { Icon } from "@trace-one/react-components";

import { Languages } from "translations";

import styles from "./FlagIcon.module.less";

const flagMapper = {
  [Languages.FRENCH]: "flag-france",
  [Languages.GERMAN]: "flag-germany",
  [Languages.ITALIAN]: "flag-italy",
  [Languages.NETHERLANDS]: "flag-netherlands",
  [Languages.PORTUGUESE]: "flag-portugal",
  [Languages.SPANISH]: "flag-spain",
  [Languages.ENGLISH]: "flag-uk",
} as const;

interface FlagIconProps {
  languageCode: string;
}

const FlagIcon: React.FC<FlagIconProps> = ({ languageCode }) => {
  return <Icon name={flagMapper[languageCode]} className={styles.root} />;
};

export default FlagIcon;
