import { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { TagV1 as Tag } from "@trace-one/design-system";

import { CumdAPI } from "apis";

import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import AsyncSearchSelect, {
  AsyncSearchSelectProps,
} from "components/AsyncSearchSelect";
import { UserStatus } from "shared/constants";
import useToast from "shared/hooks/useToast";
import { SelectOption } from "shared/typings";

import styles from "./UserAsyncSearchSelect.module.less";

interface UserAsyncSearchSelectProps
  extends Omit<AsyncSearchSelectProps, "onAsyncSearch"> {
  responsibilityId?: string;
  userIds?: string[];
  /**
   * show (inactive) next to the user name/lastname label
   * when selected user is `not active`
   */
  mentionInactive?: boolean;
  showTag?: boolean;
  teamMemberResponsibilitiesOption?: any;
}

const getTagName = (userStatus: string, name: string) => {
  let inActive = userStatus !== UserStatus.ENABLED;
  const tagName = inActive && (
    <Tag
      size="small"
      label={<FormattedMessage id="general.inactive" />}
      color="grey"
      mode="light"
    />
  );
  return (
    <div className={styles.brandTag}>
      <div className={styles.contactNameTitle}>
        <span className={inActive && styles.contactName}>{name} </span>
      </div>

      {tagName}
    </div>
  );
};
const UserAsyncSearchSelect: React.FC<UserAsyncSearchSelectProps> = props => {
  const {
    value,
    onChange,
    responsibilityId,
    userIds,
    mentionInactive,
    placeholder,
    teamMemberResponsibilitiesOption,
    showTag,
    ...rest
  } = props;

  const toast = useToast();
  const intl = useIntl();
  const owningCompanyId = useSelector(selectUserOwningCompanyId);
  const minLengthToSearch = 3;

  const [defaultOptions, setDefaultOptions] = useState<SelectOption[]>([]);
  const [selectedValue, setSelectedValue] = useState<SelectOption>();
  const [searchValue, setSearchValue] = useState("");

  const fetchUsers = ({ searchValue } = { searchValue: "" }) => {
    return CumdAPI.getUsersByUserIdsAndFilters(
      { userIds },
      {
        owningCompanyId,
        isEnabled: showTag ? undefined : true,
        user: searchValue,
        responsibilityId,
        isDeactivated: false,
      }
    ).then(({ data }) => {
      return data.users.map(user => {
        const { userFirstName, userLastName, userId, userStatus } = user;
        return {
          name: showTag
            ? getTagName(userStatus, `${userFirstName} ${userLastName}`)
            : `${userFirstName} ${userLastName}`,
          value: userId,
          data: user,
          title: `${userFirstName} ${userLastName}`,
        };
      });
    });
  };

  useEffect(() => {
    let mount = true;
    setDefaultOptions([]);
    fetchUsers()
      .then(newDefaultOptions => {
        if (mount) buildDefaultOptions(newDefaultOptions);
      })
      .catch(error => {
        toast.fetchError({ error });
      });
    return () => {
      mount = false;
    };
  }, [responsibilityId]);

  useEffect(() => {
    //prevent setting a selectedValue when no options are populated
    if (defaultOptions.length) setSelectedValue(value);
  }, [onChange]);

  const buildDefaultOptions = (newDefaultOptions: SelectOption[]) => {
    if (value && value.hasOwnProperty("value") && mentionInactive) {
      //check if selection is missing from options
      if (value.userStatus !== UserStatus.ENABLED) {
        const inactiveLabel = intl.formatMessage({ id: "general.inactive" });
        const contactValue = {
          value: value.value,
          name: `${value.label} (${inactiveLabel})`,
          disabled: true,
          user: value.user,
        };
        setDefaultOptions([...newDefaultOptions, contactValue]);
        setSelectedValue({
          value: value.value,
          name: `${value.label} (${inactiveLabel})`,
          data: value.data,
        });
        return;
      }
    }
    setDefaultOptions(
      newDefaultOptions.map(({ value, name, data }) => ({ data, value, name }))
    );
    setSelectedValue(value);
  };

  return (
    <AsyncSearchSelect
      value={selectedValue}
      searchValue={searchValue}
      onChange={onChange}
      minLengthToSearch={minLengthToSearch}
      labelInValue
      onSearch={setSearchValue}
      onAsyncSearch={fetchUsers}
      placeholder={placeholder}
      defaultOptions={defaultOptions}
      {...rest}
    />
  );
};

export default UserAsyncSearchSelect;
