import { createSlice } from "@reduxjs/toolkit";

import { fetchRetailerForm } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "siteRetailerForm",
  initialState,
  reducers: {
    clearSiteRetailerForm: (state, action) => {
      state = initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRetailerForm.pending, (state, action) => {
        state.isLoading = true;
        state.hasErrors = false;
      })
      .addCase(fetchRetailerForm.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchRetailerForm.rejected, (state, action) => {
        state.data = {};
        state.isLoading = false;
        state.hasErrors = true;
        state.errorStatus = action.payload.status;
      });
  },
});

export const { clearSiteRetailerForm } = slice.actions;
export default slice.reducer;
