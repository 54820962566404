import { State } from "./models";

export const initialState: State = {
  isCategoryitemLoading: false,
  categoryItemData: {
    categoryId: "",
    categoryItemId: "",
    categoryItemName: "New category",
    externalCode: "",
    contacts: [],
    translations: [],
    parent4CategoryItemId: "",
    isActive: true,
    modifiedAt: "",
  },
  categoryItemText: "New category",
  hasCategoryItemError: false,
  errorStatus: null,
};
