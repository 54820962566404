export const adjustIntercomButton = (isNotificationPanelOpen: boolean) => {
  [
    ".fake-intercom-popover-container",
    ".fake-intercom-icon-wrapper",
    ".fake-intercom",
    ".intercom-lightweight-app",
    ".intercom-app",
  ]
    .map(selector => document.querySelector(selector))
    .filter(Boolean)
    .forEach((elem: HTMLElement) => {
      if (isNotificationPanelOpen) {
        elem.style.display = "none";
      } else {
        elem.style.display = null;
      }
    });
};
