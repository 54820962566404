import React from "react";

import { Input as AntdInput } from "antd";

import FlexWrap from "components/FlexWrap";

import styles from "./CustomInputGroup.module.less";

interface CustomInputGroupProps {
  leftInput?: React.ReactNode;
  rightInput?: React.ReactNode;
  dualInput?: React.ReactNode;
}

const CustomInputGroup: React.FC<CustomInputGroupProps> = ({
  leftInput,
  rightInput,
  children,
  dualInput,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.inputWrapper}>
        {!dualInput && (
          <AntdInput.Group compact style={{ flexGrow: 1, display: "flex" }}>
            <div style={{ paddingRight: 8 }}>{leftInput}</div>
            {rightInput}
          </AntdInput.Group>
        )}
        {dualInput && <div style={{ width: "500px" }}>{dualInput} </div>}
      </div>
      {!!children && <FlexWrap>{children}</FlexWrap>}
    </div>
  );
};

export default CustomInputGroup;
