import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Heading, TagV1 as Tag } from "@trace-one/design-system";

import { CumdAPI } from "apis";
import { CompanyData } from "models";

import { useAppDispatch } from "reduxStore";
import { selectSupplierDetails } from "reduxStore/SupplierDetails/selectors";
import {
  setSupplierFormData,
  clearSupplierFormData,
  setSupplierFormError,
} from "reduxStore/SupplierDetails/slice";
import { fetchSupplierForm } from "reduxStore/supplierForm/asyncActions";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import CommentsSection from "components/CommentsSection";
import LeavingFormPrompt from "components/LeavingFormPrompt";
import NewFormWrapper from "components/NewFormWrapper";
import { CompanyStatus } from "shared/constants";
import useAntForm from "shared/hooks/useAntForm";
import useToast from "shared/hooks/useToast";

import PrivateInformationSection from "./components/PrivateInformationSection";
import { SectionsIds } from "./constants";
import {
  GetSupplierDataForm,
  SupplierFormValues,
  SupplierSubmitResult,
} from "./models";
import getFailedSubmitResult from "./utils/getFailedSubmitResult";

export interface SupplierFormProps {
  company: CompanyData;
  companyRelation: GetSupplierDataForm;
  isReadOnly?: boolean;
  withDirtyForm?: boolean;
}

const SupplierForm: React.FC<SupplierFormProps> = ({
  company,
  companyRelation,
  isReadOnly,
  withDirtyForm,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const toast = useToast();
  const [activeTab, setActiveTab] = useState(null);
  const [formTitleMap, setFormTitleMap] = useState({
    externalName: companyRelation.externalName,
    externalIdentifier: companyRelation.externalIdentifier,
  });
  const [submitResult, setSubmitResult] = useState<SupplierSubmitResult>({});
  const { form, isFormDirty, setIsFormDirty } =
    useAntForm<SupplierFormValues>();
  const { supplierFormData } = useSelector(selectSupplierDetails);

  const supplierId = companyRelation.associatedWithCompanyId;
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  useEffect(() => {
    if (withDirtyForm) setIsFormDirty(true);
    dispatch(setSupplierFormData(companyRelation));
    dispatch(setSupplierFormError({ externalName: false }));
    return () => {
      dispatch(clearSupplierFormData());
    };
  }, []);

  const handleSubmit = async (values: SupplierFormValues) => {
    const fields = { ...supplierFormData, ...form.getFieldsValue() };
    try {
      await CumdAPI.updateCompanyRelation(ownerCompanyId, supplierId, fields);
      await dispatch(
        fetchSupplierForm({
          supplierId,
          ownerCompanyId,
        })
      );
      toast.saveSuccess();
      setIsFormDirty(false);
      setSubmitResult({});
    } catch (error) {
      const submitResult = getFailedSubmitResult({ error, setActiveTab });
      setSubmitResult(prev => ({ ...prev, ...submitResult }));
      toast.saveError({ error });
    }
  };

  const handleChange = (value: boolean) => {
    setActiveTab(null);
  };

  const tabList = [
    {
      label: (
        <Heading size="xs">
          {formatMessage({ id: "suplierDetails.companyInformation" })}
        </Heading>
      ),
      content: (
        <PrivateInformationSection
          disabled={isReadOnly}
          submitResult={submitResult}
          companyRelation={companyRelation}
        />
      ),
    },

    {
      label: (
        <Heading size="xs">{formatMessage({ id: "general.comments" })}</Heading>
      ),
      content: (
        <CommentsSection
          id={SectionsIds.COMMENTS}
          name="comments"
          disabled={isReadOnly}
        />
      ),
    },
  ];

  const CompanyStatusTag = () => {
    if (company.companyStatus === CompanyStatus.ENABLED) {
      return (
        <Tag
          label={formatMessage({ id: "general.active" })}
          color="green"
          mode="light"
        />
      );
    }
    if (
      company.companyStatus === CompanyStatus.SUSPENDED ||
      company.companyStatus === CompanyStatus.DISABLED
    ) {
      return (
        <Tag
          label={formatMessage({ id: "general.inactive" })}
          color="grey"
          mode="light"
        />
      );
    }
    return null;
  };

  const HeaderTitle = () => {
    let title = !!formTitleMap.externalName
      ? formTitleMap.externalName
      : company.companyDisplayName;

    if (formTitleMap.externalIdentifier) {
      title = `${title} (${formTitleMap.externalIdentifier})`;
    }

    return <>{title}</>;
  };

  return (
    <NewFormWrapper
      title={<HeaderTitle />}
      status={<CompanyStatusTag />}
      submitText={formatMessage({ id: "general.save" })}
      cancelText={formatMessage({ id: "general.cancel" })}
      onBackClick={() => {
        history.goBack();
      }}
      form={form}
      withBackNav
      onFinish={handleSubmit}
      initialValues={companyRelation}
      activeTab={activeTab}
      onValuesChange={changedValues => {
        let dataToSave = { ...supplierFormData, ...form.getFieldsValue() };
        dispatch(setSupplierFormData(dataToSave));
        setIsFormDirty(true);

        if ("externalName" in changedValues) {
          setFormTitleMap(prev => ({
            ...prev,
            externalName: changedValues.externalName,
          }));
        } else if ("externalIdentifier" in changedValues) {
          setFormTitleMap(prev => ({
            ...prev,
            externalIdentifier: changedValues.externalIdentifier,
          }));
        }
      }}
      disabled={isReadOnly}
      tabs={tabList}
      handleChange={handleChange}
    >
      <LeavingFormPrompt when={isFormDirty} />
    </NewFormWrapper>
  );
};

export default SupplierForm;
