export const NET_CONTENT_PARENT_ID_FOR_PRODUCT_FORM =
  "8fcb063c-81bf-4be4-9f88-e64aa2bbf57f";

export const ProductIntlStatus = {
  INACTIVE: {
    searchLabel: "general.yes",
    tagLabel: "general.archived",
    tableLabel: "general.yes",
    statusColor: "error",
  },
  ACTIVE: {
    searchLabel: "general.no",
    tagLabel: "general.notArchived",
    tableLabel: "general.no",
    statusColor: "success",
  },
};

export const DUPLICATE_QUERY_IDENTIFIER = "duplicate";
