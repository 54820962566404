import React from "react";

import { Heading, Typography } from "@trace-one/design-system";
import cn from "classnames";

import styles from "./DetailSection.module.less";

interface DetailSectionProps {
  heading: string;
  fieldItems: {
    title?: string;
    fields: {
      label?: string;
      value: React.ReactNode;
      isChanged?: boolean;
      testId?: string;
    }[];
  }[];
}

const DetailSection: React.FC<DetailSectionProps> = ({
  heading,
  fieldItems,
}) => {
  return (
    <>
      <div
        data-test-id={`detail-heading-${heading}`}
        className={styles.sectionHeader}
      >
        <Heading color="grey-4" size="s">
          {heading}
        </Heading>
        <hr />
      </div>
      {fieldItems.map(({ title, fields }, idx) => {
        return (
          <div key={idx} className={styles.fieldSection}>
            {title && (
              <Typography
                data-test-id={`detail-title-${title}`}
                className={styles.sectionTitle}
                color="grey-4"
                variant="heading-xxs"
              >
                {title}
              </Typography>
            )}
            {fields.map(({ label, value, isChanged, testId }, idx) => {
              return (
                <div key={idx} className={styles.fieldItem}>
                  <div
                    data-test-id={`detail-label-${label ?? testId}`}
                    className={cn(
                      isChanged && styles.changedSection,
                      styles.fieldItemSection
                    )}
                  >
                    {label && (
                      <Typography
                        color="grey-4"
                        className={cn(
                          isChanged && styles.changedLabel,
                          styles.fieldLabel
                        )}
                        variant="heading-xxs"
                      >
                        {label}
                      </Typography>
                    )}
                    {React.isValidElement(value) ? (
                      value
                    ) : Array.isArray(value) ? (
                      <div className={styles.fieldValues}>
                        {value.map((value, idx) => {
                          if (React.isValidElement(value)) {
                            return value;
                          }
                          return (
                            <Typography
                              className={isChanged && styles.changedValue}
                              color="grey-4"
                              variant="body-regular"
                              key={idx}
                            >
                              {value}
                            </Typography>
                          );
                        })}
                      </div>
                    ) : (
                      <div className={styles.fieldValues}>
                        <Typography
                          className={isChanged && styles.changedValue}
                          color="grey-4"
                          variant="body-regular"
                        >
                          {value}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default DetailSection;
