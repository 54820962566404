import React from "react";

import { TextArea as TextAreaDesign } from "@trace-one/design-system";
import { TextAreaProps as AntdTextAreaProps } from "antd/lib/input";

interface ErrorMessageProps {
  error?: boolean;
  errorMessage?: string;
}
interface TextAreaProps extends AntdTextAreaProps, ErrorMessageProps {}

const TextArea: React.FC<TextAreaProps> = ({
  maxLength = 2000,
  error,
  errorMessage,
  placeholder,
  ...rest
}) => {
  return (
    <TextAreaDesign
      error={error}
      errorMessage={errorMessage}
      placeholder={placeholder}
      maxLength={maxLength}
      {...rest}
    />
  );
};

export default TextArea;
