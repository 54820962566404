import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

/* istanbul ignore next */
export const _selectSiteRetailerForm = (state: RootState) =>
  state.siteRetailerForm;

export const selectRetailerFormSite = createSelector(
  _selectSiteRetailerForm,
  ({ data }) => data.site
);

export const selectRetailerFormCompanyRelation = createSelector(
  _selectSiteRetailerForm,
  ({ data }) => data.companyRelation
);

export const selectRetailerFormSiteHasData = createSelector(
  _selectSiteRetailerForm,
  ({ data }) => !!data && !!data.site && !!data.relation
  // !!data.relatedUsers.length
);

export const selectRetailerFormRelation = createSelector(
  _selectSiteRetailerForm,
  ({ data }) => data.relation
);

export const selectRetailerFormRelatedUsers = createSelector(
  _selectSiteRetailerForm,
  ({ data }) =>
    data.relatedUsers?.map(u => ({
      ...u,
      value: u.userId,
      label: `${u.userFirstName} ${u.userLastName}`,
    }))
);

export const selectRetailerFormIsLoading = createSelector(
  _selectSiteRetailerForm,
  ({ isLoading }) => isLoading
);

export const selectRetailerFormHasErrors = createSelector(
  _selectSiteRetailerForm,
  ({ hasErrors }) => hasErrors
);

export const selectRetailerFormErrorStatus = createSelector(
  _selectSiteRetailerForm,
  ({ errorStatus }) => errorStatus
);
