const buildSelectHandler =
  ({ setSelectedRowKeys }) =>
  ({ key }: { key: string | number }, selected: boolean) => {
    if (selected) {
      setSelectedRowKeys(prev => [...prev, key]);
    } else {
      setSelectedRowKeys(prev => prev.filter(id => id !== key));
    }
  };

export default buildSelectHandler;
