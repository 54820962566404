import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { compose } from "@reduxjs/toolkit";

import { useAppDispatch } from "reduxStore";
import { selectIsRetailer } from "reduxStore/oidc/selectors";
import {
  fetchUsersByCompanyId,
  fetchRelatedCompanies,
} from "reduxStore/shared/asyncActions";
import {
  selectIsSiteSharedHasInitiated,
  selectIsSiteSharedHasError,
} from "reduxStore/shared/selectors";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import ErrorPage from "components/ErrorPage";
import { TeamMemberResponsibilityParentItem } from "shared/constants";
import {
  withCountries,
  withCertifications,
  withCertificationTypes,
  withSiteFunctions,
  withCompanyIdentifiers,
  BaseHocProps,
  withTeamMemberResponsibilities,
} from "shared/hocs";
import getDisplayName from "shared/utils/getDisplayName";

export interface SiteSharedProps extends BaseHocProps {
  isSiteSharedInitiated?: boolean;
}
type Payload = {
  companyId: string;
  isCompanyRelationStatusEnabled?: boolean;
};
const withSiteShared = <P extends SiteSharedProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  const Component: React.FC<P> = ({ isInitiated, ...rest }) => {
    const dispatch = useAppDispatch();
    const companyId = useSelector(selectUserOwningCompanyId);

    const isRetailer = useSelector(selectIsRetailer);
    const hasErrors = useSelector(selectIsSiteSharedHasError);
    const hasInitiated = useSelector(selectIsSiteSharedHasInitiated);

    useEffect(() => {
      let payload: Payload;
      payload = { companyId: companyId };
      if (!isRetailer) {
        payload = { ...payload, isCompanyRelationStatusEnabled: true };
      }
      dispatch(fetchRelatedCompanies(payload));

      if (!isRetailer) {
        dispatch(fetchUsersByCompanyId(companyId));
      }
    }, [companyId]);

    if (hasErrors) {
      return <ErrorPage />;
    }

    return (
      <WrappedComponent
        {...(rest as P)}
        isSiteSharedInitiated={hasInitiated && isInitiated}
      />
    );
  };

  Component.displayName = getDisplayName(withSiteShared, WrappedComponent);

  return Component;
};

export default compose(
  withCountries(),
  withCertifications(),
  withCertificationTypes(),
  withSiteFunctions(),
  withCompanyIdentifiers(),
  withTeamMemberResponsibilities({
    includeParentItems: true,
    parentItemId: TeamMemberResponsibilityParentItem.RETAILER,
  }),
  withSiteShared
);
