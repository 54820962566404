import { useLayoutEffect, useState } from "react";

import { ColumnsType, ColumnType } from "antd/lib/table";

import ResizableTitle from "../components/ResizableTitle";

export default function useResizableColumns<RecordType>(
  columnsProp: ColumnsType<RecordType>
) {
  const [columns, setColumns] = useState<ColumnsType<RecordType>>([]);

  useLayoutEffect(() => {
    setColumns(prev => {
      if (prev.length === columnsProp.length) {
        return columnsProp.map((col, i) => ({ ...col, width: prev[i].width }));
      } else {
        return columnsProp;
      }
    });
  }, [columnsProp]);

  const handleResize =
    (index: number) =>
    (e, { size }) => {
      setColumns(prev => {
        const nextColumns = [...prev];
        nextColumns[index] = {
          ...resizableColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const resizableColumns: ColumnsType<RecordType> = columns.map(
    (col, index) => ({
      ...col,
      onHeaderCell: (column: ColumnType<RecordType>) => ({
        width: column.width,
        onResize: handleResize(index),
      }),
    })
  ) as ColumnsType<RecordType>;

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  return { resizableColumns, components, handleResize };
}
