import { createSlice } from "@reduxjs/toolkit";

import { fetchRetailerSite } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "siteRetailerDetails",
  initialState,
  reducers: {
    clearSiteRetailerDetails: () => initialState,
    setSiteRetailerFormData: (state, action) => {
      state.siteRetailerFormData = action.payload;
    },
    clearSiteRetailerFormData: state => {
      state.siteRetailerFormData = {};
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRetailerSite.pending, state => {
        state.isLoading = true;
        state.hasErrors = false;
      })
      .addCase(fetchRetailerSite.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchRetailerSite.rejected, (state, action) => {
        state.data = {};
        state.isLoading = false;
        state.hasErrors = true;
        state.errorStatus = action.payload.status;
      });
  },
});

export const {
  clearSiteRetailerDetails,
  setSiteRetailerFormData,
  clearSiteRetailerFormData,
} = slice.actions;
export default slice.reducer;
