const buildTableHandler =
  ({ setPaginationQuery }) =>
  (page, _) => {
    setPaginationQuery({
      skip: (page.current - 1) * page.pageSize,
      take: page.pageSize,
    });
  };

export default buildTableHandler;
