import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "reduxStore";
import { fetchPointOfSales } from "reduxStore/shared/asyncActions";
import { selectPointOfSalesData } from "reduxStore/shared/selectors";
import {
  selectUserLanguageCode,
  selectUserOwningCompanyId,
} from "reduxStore/user/selectors";

import getDisplayName from "shared/utils/getDisplayName";

import { BaseHocProps, renderHocMiddleware } from "../_helpers";

const withPointOfSales =
  () =>
  <P extends BaseHocProps>(WrappedComponent: React.ComponentType<P>) => {
    const Component: React.FC<P> = props => {
      const dispatch = useAppDispatch();
      const languageCode = useSelector(selectUserLanguageCode);
      const ownerCompanyId = useSelector(selectUserOwningCompanyId);

      const { hasError, isLoading } = useSelector(selectPointOfSalesData);

      useEffect(() => {
        dispatch(
          fetchPointOfSales({ languageCode, companyId: ownerCompanyId })
        );
      }, [ownerCompanyId, languageCode]);

      return renderHocMiddleware({
        WrappedComponent,
        _hasError: hasError,
        _isInitiated: true,
        _isLoading: isLoading,
        ...props,
      });
    };

    Component.displayName = getDisplayName(withPointOfSales, WrappedComponent);

    return Component;
  };

export default withPointOfSales;
