/* istanbul ignore file */
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Tooltip } from "@trace-one/design-system";
import { Spinner } from "@trace-one/react-components";
import { Menu } from "antd";

import { SmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import ActionDropdown from "components/ActionDropdown";
import useAsyncFileDownload from "shared/hooks/useAsyncFileDownload";
import useAsyncHandler from "shared/hooks/useAsyncHandler";
import useToast from "shared/hooks/useToast";

const SiteAction = ({ record, isRetailer, refetchSites }) => {
  const intl = useIntl();
  const toast = useToast();
  const history = useHistory();
  const userOwningCompanyId = useSelector(selectUserOwningCompanyId);
  const [visible, setVisible] = useState(false);
  const { sitesWrite, sitesRead, siteRelationsWrite } = usePermissions();

  const [handleExportSite, isExportLoading] = useAsyncFileDownload(
    () => {
      if (isRetailer) {
        return SmdAPI.exportSiterelations(
          { ids: [record.key] },
          { associatedCompanyId: userOwningCompanyId }
        );
      } else {
        return SmdAPI.exportSites(
          { ids: [record.key] },
          { ownerCompanyId: userOwningCompanyId }
        );
      }
    },
    {
      displayLoadingMessage: true,
      loadingMessage: intl.formatMessage({
        id: "general.exportIsInProgress",
      }),
      fileExtension: "csv",
    }
  );

  const [handleArchiveSite, isArchiveSiteLoading] = useAsyncHandler(
    async () => {
      try {
        record?.siteActive
          ? await SmdAPI.disableSite(record.key)
          : await SmdAPI.enableSite(record.key);
        toast.success({
          message: intl.formatMessage({
            id: record?.siteActive
              ? "sitesListPage.inactiveSuccessTitle"
              : "sitesListPage.reActiveSuccessTitle",
          }),
          description: intl.formatMessage({
            id: record?.siteActive
              ? "sitesListPage.inactiveSuccessText"
              : "sitesListPage.reActiveSuccessText",
          }),
        });
        refetchSites();
      } catch (error) {
        toast.error({
          message: intl.formatMessage({
            id: record?.siteActive
              ? "sitesListPage.inactiveFailedTitle"
              : "sitesListPage.reActiveFailedTitle",
          }),
          description: intl.formatMessage({
            id: record?.siteActive
              ? "sitesListPage.inactiveFailedText"
              : "sitesListPage.reActiveFailedText",
          }),
          checkErrorResponse: false,
        });
      }
    }
  );

  return (
    <ActionDropdown
      // disabled={isRetailer ? !siteRelationsWrite : !sitesWrite && !sitesRead}
      data-test-id="md-site-action"
      overlay={
        <Menu
          onClick={e => {
            if (e.key === "edit-site") {
              history.push(`/sites/edit/${record.key}`);
            }
          }}
          data-test-id="md-site-edit"
        >
          {(isRetailer
            ? siteRelationsWrite
            : sitesWrite && record?.siteActive) && (
            <Menu.Item key="edit-site">
              {intl.formatMessage({ id: "sitesListPage.action.editSite" })}
            </Menu.Item>
          )}

          {sitesRead && (
            <Menu.Item
              key="export-site"
              onClick={handleExportSite}
              data-test-id="md-site-export"
            >
              <Spinner spinning={isExportLoading}>
                {intl.formatMessage({
                  id: "general.exportSite",
                })}
              </Spinner>
            </Menu.Item>
          )}

          {sitesWrite && !isRetailer && (
            <Menu.Item disabled={isArchiveSiteLoading}>
              <Tooltip
                className="withNoArrow"
                onOpenChange={() => setVisible(true)}
                placement="top"
                trigger="click"
                text={intl.formatMessage(
                  {
                    id: record.siteActive
                      ? "sitesListPage.tooltip.deActivate.title"
                      : "sitesListPage.tooltip.activate.title",
                  },
                  { Name: " " + record.siteName }
                )}
                actions={[
                  {
                    text: intl.formatMessage({ id: "general.cancel" }),
                    onClick: event => {
                      event.target.blur();
                      setVisible(false);
                    },
                  },
                  {
                    text: intl.formatMessage({ id: "general.confirm" }),
                    onClick: () => {
                      handleArchiveSite();
                      setVisible(false);
                    },
                  },
                ]}
                visible={visible}
              >
                <Spinner spinning={isArchiveSiteLoading}>
                  <div>
                    {intl.formatMessage({
                      id: record.siteActive
                        ? "siteSupplier.deActivate"
                        : "siteSupplier.activate",
                    })}
                  </div>
                </Spinner>
              </Tooltip>
            </Menu.Item>
          )}
          {sitesRead && (
            <Menu.Item
              key="view-history"
              onClick={() => {
                history.push(`/sites/history/${record.key}`);
              }}
              data-test-id="md-site-view-history"
            >
              {intl.formatMessage({
                id: "general.viewVersionsHistory",
              })}
            </Menu.Item>
          )}
        </Menu>
      }
    />
  );
};

export default SiteAction;
