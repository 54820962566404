import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectClassificationList = (state: RootState) =>
  state.classificationList;

export const selectClassifications = createSelector(
  _selectClassificationList,
  ({ classifications }) => classifications
);
export const selectIsClassificationsLoading = createSelector(
  _selectClassificationList,
  ({ isClassificationLoading }) => isClassificationLoading
);
export const selectHasClassificationsError = createSelector(
  _selectClassificationList,
  ({ hasClassificationError }) => hasClassificationError
);
