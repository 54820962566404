import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button, Container } from "@trace-one/react-components";
import { Form, FormProps } from "antd";
import {} from "antd/lib/form";
import cn from "classnames";
import { renderToStaticMarkup } from "react-dom/server";

import Icon from "components/Icon";
import IconButton from "components/IconButton";
import scrollTo from "shared/utils/scrollTo";

import HeaderBg from "./assets/HeaderDetailsBackground";
import SectionMenu, { SectionsMenuProps } from "./components/SectionsMenu";
import styles from "./FormWrapper.module.less";
import useShowBackButton from "./hooks/useShowBackButton";
import Section from "./Section";

export const layout: Pick<
  FormProps,
  "labelCol" | "wrapperCol" | "labelAlign" | "size"
> = {
  labelCol: { span: 8, offset: 0 },
  wrapperCol: { span: 16, offset: 0 },
  labelAlign: "left",
  size: "large",
};

export interface FormWrapperProps<Values = any>
  extends Omit<FormProps<Values>, "title" | "onFinish"> {
  onBackClick: () => void;
  title: React.ReactNode;
  status?: React.ReactNode;
  submitText: string;
  cancelText?: string;
  disabled?: boolean;
  menuOptions?: SectionsMenuProps["options"];
  rightSide?: React.ReactNode;
  onFinish?: (values: Values) => Promise<any>;
}

const FormWrapperInternal = <Values extends {}>(
  props: FormWrapperProps<Values>
): JSX.Element => {
  const {
    onBackClick,
    title,
    status,
    children,
    submitText,
    cancelText,
    form,
    disabled,
    onFinish,
    menuOptions,
    rightSide,
    ...rest
  } = props;
  const history = useHistory();
  const { showbackButton, goPreviousPage } = useShowBackButton();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const headerRef = useRef(null);
  const svgString = encodeURIComponent(renderToStaticMarkup(<HeaderBg />));

  useEffect(() => {
    const urlHash = history.location.hash?.replace("#", "");
    if (!!urlHash) {
      const offsetTop =
        (headerRef?.current?.getBoundingClientRect().bottom ?? 0) + 20;
      const sectionEl = document.getElementById(urlHash);
      const top =
        sectionEl?.getBoundingClientRect().top + window.pageYOffset - offsetTop;
      scrollTo({ top, behavior: "smooth" });
    }
  }, []);

  return (
    <div className={cn(styles.root, { [styles.wrapIfRightSide]: !!rightSide })}>
      <Form
        form={form}
        scrollToFirstError={{ behavior: "smooth", block: "center" }}
        onFinish={values => {
          setIsSubmitting(true);
          return onFinish(values)
            .then(() => {
              setIsSubmitting(false);
            })
            .catch(() => {
              setIsSubmitting(false);
            });
        }}
        {...layout}
        {...rest}
      >
        <div
          className={cn("formHeaderWrapper", styles.headerWrapper)}
          ref={headerRef}
        >
          <div
            className={styles.headerBackgroundWrapper}
            style={{
              backgroundImage: `url("data:image/svg+xml,${svgString}")`,
            }}
          >
            <Container component="header" className={styles.header}>
              <div className={styles.headerLeft}>
                {showbackButton && (
                  <IconButton
                    onClick={goPreviousPage}
                    data-test-id="md-form-back"
                    className={styles.backButton}
                  >
                    <Icon useTOLibrary name="long-arrow-left" />
                  </IconButton>
                )}
                {title}
                {!!status && (
                  <div className={styles.statusWrapper}>{status}</div>
                )}
              </div>
              <div className={styles.headerRight}>
                {!!cancelText && (
                  <Button
                    type="secondary"
                    onClick={onBackClick}
                    data-test-id="md-form-cancel"
                    disabled={isSubmitting}
                  >
                    {cancelText}
                  </Button>
                )}
                <Button
                  htmlType="submit"
                  loading={isSubmitting}
                  data-test-id="md-form-submit"
                  disabled={disabled}
                >
                  {submitText}
                </Button>
              </div>
            </Container>
          </div>
        </div>

        <Container className={styles.container}>
          {!!menuOptions && (
            <SectionMenu
              getOffsetTop={() =>
                (headerRef.current?.getBoundingClientRect().bottom ?? 0) + 20
              }
              options={menuOptions}
            />
          )}
          <div
            className={cn(styles.right, {
              [styles.rightWithMenuOptions]: !!menuOptions,
            })}
          >
            <div className={styles.children}>{children}</div>
            {!!rightSide && <div className={styles.rightSide}>{rightSide}</div>}
          </div>
        </Container>
      </Form>
    </div>
  );
};

type InternalFormWrapperType = typeof FormWrapperInternal;
interface FormWrapperInterface extends InternalFormWrapperType {
  Section: typeof Section;
}

const FormWrapper = FormWrapperInternal as FormWrapperInterface;
FormWrapper.Section = Section;

export default FormWrapper;
