import React, { memo } from "react";
import { useIntl } from "react-intl";

import { Heading } from "@trace-one/design-system";
import { Form, Radio } from "antd";

import FormWrapper from "components/FormWrapper";
import Input from "components/Input";
import RangePicker from "components/RangePicker";

import { SectionsIds } from "../../constants";

import styles from "./CharacteristicsSection.module.less";

interface CharacteristicsSectionProps {
  disabled: boolean;
  variableUnit?: boolean;
  seasonalUnit?: boolean;
}
const CharacteristicsSection: React.FC<CharacteristicsSectionProps> = ({
  disabled,
  variableUnit,
  seasonalUnit,
}) => {
  const intl = useIntl();
  return (
    <FormWrapper.Section
      id={SectionsIds.CHARATERISTICS}
      title={
        <Heading size="m">
          {intl.formatMessage({ id: "productForm.characteristics.title" })}{" "}
        </Heading>
      }
    >
      <Form.Item
        name="manufacturingPeriod"
        label={
          <Heading size="xxs">
            {" "}
            {intl.formatMessage({
              id: "productForm.characteristics.manufacturingPeriod",
            })}
          </Heading>
        }
      >
        <Input
          disabled={disabled}
          data-test-id="md-product-manufacturingPeriod"
          maxLength={256}
        />
      </Form.Item>
      <Form.Item
        name="isTradeItemVariableUnit"
        label={
          <Heading size="xxs">
            {intl.formatMessage({
              id: "productForm.characteristics.variableUnit",
            })}{" "}
          </Heading>
        }
      >
        <Radio.Group disabled={disabled}>
          <Radio
            value={true}
            style={{ marginRight: 30 }}
            data-test-id="md-product-isTradeItemVariableUnit-yes"
          >
            {intl.formatMessage({ id: "general.yes" })}
          </Radio>
          <Radio
            value={false}
            data-test-id="md-product-isTradeItemVariableUnit-no"
          >
            {intl.formatMessage({ id: "general.no" })}
          </Radio>
        </Radio.Group>
      </Form.Item>
      {variableUnit === true && (
        <>
          <Form.Item
            name="ean7Weight"
            label={
              <Heading size="xxs">
                {" "}
                {intl.formatMessage({
                  id: "productForm.characteristics.ean7Weight",
                })}
              </Heading>
            }
            className={styles.subFormItem}
          >
            <Input
              disabled={disabled}
              data-test-id="md-product-ean7Weight"
              maxLength={20}
            />
          </Form.Item>
          <Form.Item
            name="ean7Price"
            label={
              <Heading size="xxs">
                {intl.formatMessage({
                  id: "productForm.characteristics.ean7Price",
                })}{" "}
              </Heading>
            }
            className={styles.subFormItem}
          >
            <Input
              disabled={disabled}
              data-test-id="md-product-ean7Price"
              maxLength={20}
            />
          </Form.Item>
        </>
      )}

      <Form.Item
        name="isTradeItemSeasonal"
        label={
          <Heading size="xxs">
            {intl.formatMessage({
              id: "productForm.characteristics.seasonalUnit",
            })}{" "}
          </Heading>
        }
      >
        <Radio.Group disabled={disabled}>
          <Radio
            value={true}
            style={{ marginRight: 30 }}
            data-test-id="md-product-isTradeItemSeasonal-yes"
          >
            {intl.formatMessage({ id: "general.yes" })}
          </Radio>
          <Radio value={false} data-test-id="md-product-isTradeItemSeasonal-no">
            {intl.formatMessage({ id: "general.no" })}
          </Radio>
        </Radio.Group>
      </Form.Item>
      {true && (
        <Form.Item
          name="seasonalDate"
          label={intl.formatMessage({
            id: "productForm.characteristics.seasonalDate",
          })}
          className={styles.subFormItem}
        >
          <RangePicker
            disabled={disabled}
            data-test-id="md-product-seasonalDate"
            style={{ width: "100%" }}
            allowEmpty={[true, true]}
          />
        </Form.Item>
      )}
    </FormWrapper.Section>
  );
};

export default memo(CharacteristicsSection);
