import { useEffect, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { Heading, TagV1 as Tag } from "@trace-one/design-system";
import { SectionsIds } from "pages/Products/components/ProductForm/constants";
import { NET_CONTENT_PARENT_ID_FOR_PRODUCT_FORM } from "pages/Products/constants";
import { compose } from "redux";

import { PmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import { selectIsRetailer } from "reduxStore/oidc/selectors";
import { fetchProduct } from "reduxStore/productDetails/asyncActions";
import { selectProductDetailsData } from "reduxStore/productDetails/selectors";
import { clearProductDetails } from "reduxStore/productDetails/slice";
import { setBackButtonData } from "reduxStore/shared/slice";
import {
  selectUserLanguageCode,
  selectUserOwningCompanyId,
} from "reduxStore/user/selectors";

import ErrorPage from "components/ErrorPage";
import HeaderDetails from "components/HeaderDetails";
import Spinner from "components/Spinner";
import {
  TeamMemberResponsibilityParentItem,
  TradeItemStatus,
} from "shared/constants";
import {
  withBrands,
  withCategoryLists,
  withCountries,
  withCustomProductTypes,
  withLanguages,
  withManufacturingItemStatuses,
  withNetContentMeasures,
  withPointOfSales,
  withTeamMemberResponsibilities,
  withTradeItemStatuses,
} from "shared/hocs";
import useToast from "shared/hooks/useToast";

import AdditionalInformationTab from "./components/AdditionalInformationTab";
import CommentsTab from "./components/CommentsTab";
import ManufacturedItemsTab from "./components/ManufacturedItemsTab";
import ProductInformationTab from "./components/ProductInformationTab";

const enhance = compose<React.FC>(
  withBrands(),
  withCategoryLists(),
  withNetContentMeasures({
    includeParentItems: true,
    parentItemId: NET_CONTENT_PARENT_ID_FOR_PRODUCT_FORM,
  }),
  withTradeItemStatuses(),
  withCustomProductTypes(),
  withManufacturingItemStatuses(),
  withTeamMemberResponsibilities({
    includeParentItems: true,
    parentItemId: TeamMemberResponsibilityParentItem.RETAILER,
  }),
  withPointOfSales(),
  withCountries(),
  withLanguages()
);

const ProductDetails = ({ isInitiated }) => {
  const intl = useIntl();
  // const showBtn = ["productDetailTabs"];
  const dispatch = useAppDispatch();
  const { productId } = useParams<{ productId?: string }>();
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const [isLoading, setIsLoading] = useState(false);
  const isRetailer = useSelector(selectIsRetailer);
  const languageCode = useSelector(selectUserLanguageCode);
  const { productsWrite, productsEnable } = usePermissions();
  const { productData, hasProductError, errorStatus } = useSelector(
    selectProductDetailsData
  );
  const productIsDisabled =
    productData.tradeItemStatusId === TradeItemStatus.INACTIVE;
  const isArchiveTitle = intl.formatMessage(
    {
      id: !productIsDisabled
        ? "productsList.actionTooltipTitle"
        : "productsList.actionTooltipTitle.unArchive",
    },
    { Name: "" }
  );
  const toast = useToast();
  const history = useHistory();

  const refetchProduct = useCallback(() => {
    setIsLoading(true);
    dispatch(
      fetchProduct({ id: productId, ownerCompanyId, languageCode })
    ).then(() => {
      setIsLoading(false);
    });
  }, [productId, ownerCompanyId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearProductDetails());
    };
  }, []);

  useEffect(() => {
    refetchProduct();
  }, [refetchProduct]);

  useEffect(() => {
    if (productIsDisabled) {
      dispatch(
        setBackButtonData({
          fromUrl: `/products/list`,
        })
      );
    }
  }, [productIsDisabled]);

  const handleArchiveUnArchiveProduct = async () => {
    try {
      if (!productIsDisabled) {
        await PmdAPI.archiveTradeItems({ idCollection: [productId] });
        toast.success({
          message: intl.formatMessage({
            id: "productDetails.archivedSuccessTitle",
          }),
          description: intl.formatMessage({
            id: "productDetails.archivedSuccessText",
          }),
        });
      } else {
        await PmdAPI.unArchiveTradeItems(productId);
        toast.success({
          message: intl.formatMessage({
            id: "productDetails.unArchivedSuccessTitle",
          }),
          description: intl.formatMessage({
            id: "productDetails.unArchivedSuccessText",
          }),
        });
      }
      refetchProduct();
    } catch (error) {
      if (!productIsDisabled) {
        toast.error({
          description: intl.formatMessage({
            id: "productDetials.archivedFailedText",
          }),
          message: intl.formatMessage({
            id: "productDetials.archivedFailedTitle",
          }),
          checkErrorResponse: false,
        });
      } else {
        toast.error({
          description: intl.formatMessage({
            id: "productDetials.unArchivedFailedText",
          }),
          message: intl.formatMessage({
            id: "productDetials.unArchivedFailedTitle",
          }),
          checkErrorResponse: false,
        });
      }
    }
  };

  const handleActionItem = (sectionId = null) => {
    if (sectionId) {
      history.push(`/products/edit/${productId}#${sectionId}`);
      return;
    }
    history.push(`/products/edit/${productId}`);
  };

  const buttonList = [
    {
      text: "general.actions",
      showBtn: isRetailer && productsWrite,
      testId: "product-actions",
      tab: 0,
      actionList: [
        {
          name: "general.edit",
          "data-test-id": "product-details-edit",
          disabled: productIsDisabled,
          onClick: () => handleActionItem(),
          showTooltip: false,
          showBtn: true,
        },
        {
          name: `${
            productIsDisabled ? "general.unArchive" : "general.archive"
          }`,
          "data-test-id": "product-details-archive-status",
          disabled: false,
          showTooltip: true,
          showBtn: productIsDisabled ? productsEnable : true,
        },
        {
          name: "productDetails.addASupplier",
          disabled: productIsDisabled,
          "data-test-id": "product-details-add-supplier",
          onClick: () => handleActionItem(SectionsIds.MANUFACTURED_ITEMS),
          showTooltip: false,
          showBtn: true,
        },
        {
          name: "general.addComment",
          disabled: productIsDisabled,
          "data-test-id": "product-details-add-comment",
          onClick: () => handleActionItem(SectionsIds.COMMENTS),
          showTooltip: false,
          showBtn: true,
        },
        {
          name: "general.viewVersionsHistory",
          "data-test-id": "product-details-view-history",
          onClick: () => {
            history.push(`/products/history/${productId}`);
          },
          showTooltip: false,
          showBtn: true,
        },
      ],
    },
  ];

  const tabList = [
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "productDetails.productInformation" })}
        </Heading>
      ),
      content: <ProductInformationTab />,
    },
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "productDetails.manufacturedItems" })}{" "}
        </Heading>
      ),
      content: <ManufacturedItemsTab refetchProduct={refetchProduct} />,
    },
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "general.additionalInformation" })}
        </Heading>
      ),
      content: <AdditionalInformationTab />,
    },
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "general.comments" })}
        </Heading>
      ),
      content: <CommentsTab />,
    },
  ];

  if (hasProductError) return <ErrorPage status={errorStatus} />;
  if (!isInitiated || isLoading) return <Spinner underHeader />;

  const HeaderSubtitle = () => {
    return <></>;
  };

  const ProductStatusTag = () => {
    if (productData.tradeItemStatusId === TradeItemStatus.INACTIVE) {
      return (
        <Tag
          label={intl.formatMessage({ id: "general.archived" })}
          color="grey"
          mode="light"
        />
      );
    }

    return null;
  };

  return (
    <div>
      <HeaderDetails
        withBackNav
        title={productData.tradeItemName}
        subtitle={<HeaderSubtitle />}
        status={<ProductStatusTag />}
        tabs={tabList}
        headerIcon="product-add"
        buttons={buttonList}
        handleStatus={handleArchiveUnArchiveProduct}
        tooltipTitle={isArchiveTitle}
        haveActionItems
      />
    </div>
  );
};

export default enhance(ProductDetails);
