import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Tooltip } from "@trace-one/design-system";
import { Heading, Paragraph } from "@trace-one/design-system";
import { Button } from "@trace-one/design-system";
import { Card } from "@trace-one/react-components";
import { Form } from "antd";
import moment from "moment";
import { v4 } from "uuid";

import { CommentData } from "models";

import { selectUserId, selectUserName } from "reduxStore/user/selectors";

import FormWrapper from "components/FormWrapper";
import Icon from "components/Icon";
import IconButton from "components/IconButton";
import TextArea from "components/Input/TextArea";

import styles from "./CommentsFormItem.module.less";

type CommentValue = CommentData & { authorFullname?: string };

interface ErrorMessageProps {
  error?: boolean;
  errorMessage?: string;
}

interface CommentsListProps extends ErrorMessageProps {
  value?: CommentValue[];
  onChange?: (value: CommentValue[]) => void;
  userId: string;
  userName: string;
  disabled?: boolean;
}

const CommentsList: React.FC<CommentsListProps> = ({
  value: comments,
  onChange,
  errorMessage,
  userId,
  userName,
  disabled,
}) => {
  const intl = useIntl();

  const [text, setText] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [isindex, setIndex] = useState(null);

  const handleAddComment = () => {
    const newComment = {
      authorId: userId,
      value: text,
      commentDate: moment().format(),
    };
    onChange([newComment, ...(comments ?? [])]);
    setText("");
  };

  const handleOpenChange = (i: number) => {
    setIndex(i);
    comments.forEach((_item, index) => {
      if (index === i) {
        setVisible(true);
      }
    });
  };

  return (
    <div>
      {comments?.map(
        ({ id, value, authorId, commentDate, authorFullname }, index) => {
          const name = authorId === userId ? userName : authorFullname;
          const formattedCommentDate = moment(commentDate).format("L");

          return (
            <Card
              title={
                <Heading size="xxs" color="primary">
                  {name && (
                    <span className={styles.authorFullname}>{name} </span>
                  )}
                  {" - "}
                  <span className={styles.commentDate}>
                    {formattedCommentDate}
                  </span>
                </Heading>
              }
              data-test-id="form-comment-card"
              extra={
                (userId === authorId || authorId === null) &&
                !disabled && (
                  <Tooltip
                    onOpenChange={() => handleOpenChange(index)}
                    placement="top"
                    text={intl.formatMessage({
                      id: "general.commentTooltipTitle",
                    })}
                    actions={[
                      {
                        text: intl.formatMessage({ id: "general.cancel" }),
                        onClick: (event: React.FocusEvent<HTMLElement>) => {
                          event.target.blur();
                          setVisible(false);
                        },
                      },
                      {
                        text: intl.formatMessage({ id: "general.confirm" }),
                        onClick: () => {
                          onChange(
                            comments.filter(
                              comment =>
                                !(
                                  comment.commentDate === commentDate &&
                                  comment.value === value
                                )
                            )
                          );
                          setVisible(false);
                        },
                      },
                    ]}
                    visible={isindex === index && visible}
                  >
                    <IconButton
                      data-test-id="form-comment-delete"
                      className={styles.removeBtn}
                    >
                      <Icon
                        name="trash"
                        size="small"
                        useTOLibrary
                        color="primary"
                      />
                    </IconButton>
                  </Tooltip>
                )
              }
              key={id ?? v4()}
              className={styles.card}
              size="small"
              bodyStyle={{
                padding: "0 12px 12px",
              }}
            >
              <Paragraph size="m">{value} </Paragraph>
            </Card>
          );
        }
      )}
      <div>
        <TextArea
          value={text}
          onChange={event => {
            const value = event.target.value;
            setText(value);
          }}
          disabled={disabled}
          placeholder={intl.formatMessage({ id: "general.enterValue" })}
          autoSize={{ minRows: 4, maxRows: 4 }}
          maxLength={256}
          data-test-id="form-comment-area"
          autoFocus={true}
        />
        <Button
          onClick={handleAddComment}
          disabled={disabled || !text?.length}
          className={styles.addBtn}
          data-test-id="form-comment-addBtn"
        >
          {intl.formatMessage({ id: "general.addComment" })}
        </Button>
      </div>
    </div>
  );
};

const CommentsSection: React.FC<{
  disabled?: boolean;
  id?: string;
  name: string;
}> = ({ disabled, id, name }) => {
  const intl = useIntl();
  const userId = useSelector(selectUserId);
  const userName = useSelector(selectUserName);

  return (
    <FormWrapper.Section
      title={
        <Heading size="m">
          {intl.formatMessage({ id: "general.comments" })}{" "}
        </Heading>
      }
      className={styles.root}
      id={id}
    >
      <Form.Item name={name} labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
        <CommentsList userId={userId} userName={userName} disabled={disabled} />
      </Form.Item>
    </FormWrapper.Section>
  );
};

export default CommentsSection;
