import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { TagV1 as Tag } from "@trace-one/design-system";
import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import cn from "classnames";
import { SectionsIds } from "pages/Products/components/ProductForm/constants";

import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import { ManufacturedItemState } from "reduxStore/productDetails/models";
import { selectProductDetailsData } from "reduxStore/productDetails/selectors";
import { setProductSelectedSupplier } from "reduxStore/productForm/slice";
import { selectCountriesOptions } from "reduxStore/shared/selectors";
import { setBackButtonData } from "reduxStore/shared/slice";

import {
  CompanyType,
  ManufacturingItemStatus,
  TradeItemStatus,
} from "shared/constants";

import ManufacturedItemAction from "../components/ManufacturedItemAction";
import TargetMarketList from "../components/TargetMarketList";
import styles from "../ManufacturedTable.module.less";
import { ManuItemsColumn } from "../models";

const mapManuItemsToColumn = ({ manuItem, countriesList, productData }) => {
  const countriesOfOrigin = manuItem.countriesOfOrigin
    ?.map(countryCode => countriesList.find(c => c.value === countryCode)?.name)
    .join(", ");

  return {
    key: manuItem.id,
    manufacturedItemName: manuItem.manufacturedItemName
      ? manuItem.manufacturedItemName
      : productData.tradeItemName,
    packerCompany: manuItem.packerCompanyId?.label,
    manufacturedItemStatus: manuItem.manufacturedItemStatus,
    countriesOfOrigin,
    targetMarketsList: manuItem.targetMarketsList,
    supplier: manuItem.supplierCompanyId,
  };
};

export default function useManufacturedItemsTable({
  manufacturedItems,
  refetchProduct,
}: {
  manufacturedItems: ManufacturedItemState[];
  refetchProduct: () => void;
}) {
  const dispatch = useAppDispatch();
  const { productsWrite } = usePermissions();
  const intl = useIntl();
  const { productId } = useParams<{ productId?: string }>();
  const history = useHistory();
  const countriesList = useSelector(selectCountriesOptions);
  const { productData } = useSelector(selectProductDetailsData);
  const productIsDisabled =
    productData.tradeItemStatusId === TradeItemStatus.INACTIVE;

  const handleEditManufaturedItem = (key: string) => {
    dispatch(setProductSelectedSupplier(key));
    history.push({
      pathname: `/products/edit/${productData.id}`,
      hash: SectionsIds.MANUFACTURED_ITEMS_SUPPLIER,
    });
  };

  const manufacturedItemAction = [
    {
      title: intl.formatMessage({ id: "general.actions" }),
      dataIndex: "actions",
      key: "actions",
      width: 80,
      ellipsis: true,
      render: (_, key) => {
        return (
          <ManufacturedItemAction
            productId={productData.id}
            supplierId={key}
            refetchProduct={refetchProduct}
          />
        );
      },
    },
  ];

  const columns: ColumnsType<ManuItemsColumn> = [
    {
      title: intl.formatMessage({ id: "productDetails.manufacturedItemName" }),
      dataIndex: "manufacturedItemName",
      key: "manufacturedItemName",
      width: 200,
      ellipsis: true,
      render: (manufacturedItemName, key) => {
        return (
          <>
            {!productIsDisabled && productsWrite ? (
              <Typography.Link
                onClick={() => handleEditManufaturedItem(key.key)}
              >
                {manufacturedItemName}
              </Typography.Link>
            ) : (
              <span>{manufacturedItemName}</span>
            )}
          </>
        );
      },
    },

    // {
    //   title: intl.formatMessage({ id: "productDetails.packer" }),
    //   dataIndex: "packerCompany",
    //   key: "packerCompanyId",
    //   width: 200,
    //   ellipsis: true,
    //   render: packerCompany => <span>{packerCompany ?? "-"}</span>,
    // },
    {
      title: intl.formatMessage({ id: "productDetails.countriesOfOrigin" }),
      dataIndex: "countriesOfOrigin",
      key: "countriesOfOrigin",
      width: 200,
      ellipsis: true,
      render: countriesOfOrigin => <span>{countriesOfOrigin ?? "-"}</span>,
    },

    {
      title: intl.formatMessage({ id: "general.supplier" }),
      dataIndex: "supplier",
      key: "supplier",
      width: 200,
      ellipsis: true,
      render: supplier => {
        const companyName = [
          supplier.label,
          supplier.companyRelationExternalName
            ? supplier.companyDisplayName
            : "",
        ];
        return (
          <>
            <div className={styles.supplierNameSection}>
              <Typography.Link
                onClick={() => {
                  dispatch(
                    setBackButtonData({
                      fromUrl: `/products/${productId}`,
                      tabSection: SectionsIds.MANUFACTURED_ITEMS,
                    })
                  );
                  history.push(`/suppliers/${supplier.value}`);
                }}
              >
                <div
                  className={cn(
                    styles.supplierTableName,
                    supplier.companyType === CompanyType.PRIVATE &&
                      styles.privateCompanyTitle
                  )}
                >
                  {companyName.map(name => name && <span>{name}</span>)}
                </div>
              </Typography.Link>
              {!supplier.companyRelationStatus && (
                <Tag
                  label={intl.formatMessage({ id: "general.inactive" })}
                  size="small"
                  color="grey"
                  mode="light"
                />
              )}
            </div>
          </>
        );
      },
      // render: supplier => <span>{supplier.label}</span>,
    },
    {
      title: intl.formatMessage({ id: "general.archived" }),
      dataIndex: "manufacturedItemStatus",
      key: "manufacturedItemStatus",
      width: 90,
      ellipsis: true,
      render: manufacturedItemStatus => {
        return (
          <FormattedMessage
            id={
              manufacturedItemStatus === ManufacturingItemStatus.ACTIVE
                ? "general.no"
                : "general.yes"
            }
          />
        );
      },
    },
    ...(!productIsDisabled && productsWrite ? manufacturedItemAction : []),
  ];

  const data =
    Array.isArray(manufacturedItems) &&
    manufacturedItems.map(manuItem =>
      mapManuItemsToColumn({ manuItem, countriesList, productData })
    );

  const expandable = {
    expandIcon: ({ expanded, onExpand, record }) => {
      return (
        !!record.targetMarketsList.length && (
          <div
            data-test-id={`expand-button-${record.key}`}
            onClick={e => onExpand(record, e)}
            className={cn(styles.expandableCenter, expanded && styles.expanded)}
          >
            <span>&#8735;</span>
          </div>
        )
      );
    },
    expandedRowClassName: () => "marketRowPadding",
    expandedRowRender: ({ targetMarketsList }) => (
      <TargetMarketList targetMarketsList={targetMarketsList} />
    ),
    rowExpandable: ({ targetMarketsList }) => !!targetMarketsList.length,
  };

  return { columns, data, expandable };
}
