import { createSelector } from "@reduxjs/toolkit";

import { CompanyData } from "models";

import { RootState } from "reduxStore";

import buildCompanyTitle from "shared/utils/buildCompanyTitle";

export const _selectSiteList = (state: RootState) => state.siteList;

export const selectSites = createSelector(
  _selectSiteList,
  ({ sites }) => sites
);
export const selectSitesSkipAndTakeQueryStats = createSelector(
  _selectSiteList,
  ({ sitesSkipAndTakeQueryStats }) => sitesSkipAndTakeQueryStats
);
export const selectIsSiteTableLoading = createSelector(
  _selectSiteList,
  ({ isSitesLoading }) => isSitesLoading
);

export const selectCompaniesMapForTable = createSelector(
  _selectSiteList,
  ({ companies }) => {
    const result: { [companyId: string]: CompanyData } = {};

    companies.forEach(company => (result[company.companyId] = company));
    return result;
  }
);

export const selectSupplierCompaniesOptions = createSelector(
  _selectSiteList,
  ({ defaultSupplierCompanies }) =>
    defaultSupplierCompanies.map(c => ({
      ...c,
      value: c.companyId,
      name: buildCompanyTitle(c),
    }))
);

export const selectAllSupplierCompaniesOptions = createSelector(
  _selectSiteList,
  ({ allSupplierCompanies }) =>
    allSupplierCompanies.map(c => ({
      ...c,
      value: c.companyId,
      name: buildCompanyTitle(c),
    }))
);

export const selectSiteKpiData = createSelector(
  _selectSiteList,
  ({ kpiData }) => kpiData
);

export const selectIsKpiDataLoading = createSelector(
  _selectSiteList,
  ({ isKpiDataLoading }) => isKpiDataLoading
);
