import { AxiosError } from "axios";

export enum ErrorCode {
  GTIN_ALREADY_DEFINED_FOR_TRADE_ITEM = "a486cffa-9f82-45b3-9f7f-81c9603a830f",
  GTIN_INVALID = "9668056d-ca97-4410-869a-94632be5ab92",
  BRAND_EXTERNAL_CODE_ALREADY_EXISTED = "cca25833-0fb4-4a9a-9dac-1e4294c2e033",
  INTERNAL_CODE_ALREADY_EXISTED = "c51bc731-2abd-465b-98c3-a84bc6d1b21e",
  USER_WITH_NO_RESPONSABILITY = "a0c24df3-69e0-488b-9b85-a8d529f8cf98",
  CATEGORY_NAME_ALREADY_EXISTED = "9f047f87-e710-459d-af0a-8d19cc01be06",
  CATEGORY_EXTERNAL_CODE_ALREADY_EXISTED = "172e813f-d85b-42b0-99ca-cd5a6dc792b5",
  CLASSIFICATION_EXTERNAL_CODE_ALREADY_EXISTED = "ecee34eb-44c5-4afa-b33c-3a1b5431ec42",
  CLASSIFICATION_EXTERNAL_CODE_UPDATE_EXISTED = "b762026d-b821-4bfa-937b-7e9e77b9726e",
  USER_RESPONSIBILITY_NOT_FOUND = "a0c24df3-69e0-488b-9b85-a8d529f8cf98",
  INVALID_LANGUAGE_CODE = "c2bead2c-eddb-45a6-b41b-d6e250146bc8",
  MANUFACTURED_ITEM_ALREADY_EXIST = "75d8cbf8-8402-4adf-bd71-7d74f97c2ef0",
  FORBIDDEN_ERROR_CODE = "50baa73d-3f3b-4d3d-b308-ad91f04a3a14",
  NOT_FOUND_ERROR_CODE = "d6f45f8a-909c-402f-b3a6-ba8e3d99c290",
  BAD_REQUEST_CODE = "615bba3e-c5ec-4a04-9b31-7cda90375096",
  ADD_PRODUCT_IN_ARCHIVE_BRAND = "912cc528-856a-4741-951e-7edafbe25329",
  CERTIFICATE_TYPE_ID_NOT_FOUND = "7515d5f4-e333-4449-b8f5-bb005cf411bc",
  USER_NOT_ACTIVE = "1010c5c5-c3af-4827-a2fa-2b092c8a8174",
  PARTICULAR_CATEGORY_LEVEL_REQUIRED = "0248f4ea-03c5-47a9-8168-45e083476f9f",
  COMPANY_SETTINGS_NOT_FOUND = "d6f45f8a-909c-402f-b3a6-ba8e3d99c290",
  PRODUCT_CATEGORY_NOT_BELONG = "63d8ada1-1779-479b-a134-242b37fe35d2",
}

function isAxiosError(error: any): error is AxiosError<{ errorCode?: string }> {
  return error?.isAxiosError;
}

export function hasErrorOccurred({
  error,
  errorCode,
}: {
  error: any;
  errorCode: ErrorCode;
}) {
  if (!isAxiosError(error)) {
    return false;
  }

  return error?.response?.data?.errorCode === errorCode;
}
