/* istanbul ignore file */
import React from "react";
import { useIntl } from "react-intl";

import { Upload, UploadProps } from "antd";

import useToast from "shared/hooks/useToast";

export interface UploadDraggerProps extends UploadProps {
  fileSizeLessThanMB: number;
  allowedFile?: string[];
}

const UploadDragger: React.FC<UploadDraggerProps> = ({
  fileSizeLessThanMB,
  beforeUpload,
  allowedFile,
  ...rest
}) => {
  const intl = useIntl();
  const toast = useToast();

  const validateFileSize = ({ file, fileSizeLessThanMB }) => {
    if (file.size >= fileSizeLessThanMB * 1024 * 1024) {
      toast.error({
        description: intl.formatMessage(
          { id: "general.fileSizeShouldBeLessMB" },
          {
            sizeInMB: fileSizeLessThanMB,
          }
        ),
        checkErrorResponse: false,
      });
      return false;
    }
    return true;
  };

  const validateFileExtension = () => {
    toast.error({
      description: intl.formatMessage({
        id: "siteSupplierForm.fileExtenstion",
      }),
      checkErrorResponse: false,
    });
  };

  return (
    <Upload.Dragger
      data-test-id="upload-attachment-input"
      beforeUpload={(file, fileList) => {
        if (
          allowedFile &&
          !allowedFile.includes(file.name.split(".").pop().toUpperCase())
        ) {
          validateFileExtension();
          return false;
        }
        if (
          Number.isInteger(fileSizeLessThanMB) &&
          !validateFileSize({ file, fileSizeLessThanMB })
        ) {
          return false;
        }
        return beforeUpload ? beforeUpload(file, fileList) : true;
      }}
      {...rest}
    />
  );
};

export default UploadDragger;
