import { createAsyncThunk } from "@reduxjs/toolkit";

import { CumdAPI } from "apis";

export const fetchSupplierList = createAsyncThunk(
  "supplierList/fetchSupplierList",
  async ({
    ownerCompanyId,
    searchText,
    companyCountry,
    isCompanyRelationStatusEnabled,
    CompanyActivityId,
    OnlyWithoutContacts,
    ContactUserId,
    ContactResponsibilityId,
    categoryId,
    categoryItemId,
    skip,
    take,
  }: {
    ownerCompanyId: string;
    searchText?: string;
    companyCountry?: string;
    isCompanyRelationStatusEnabled?: boolean;
    CompanyActivityId?: number;
    OnlyWithoutContacts?: boolean;
    ContactResponsibilityId?: string;
    ContactUserId?: string;
    categoryId?: string;
    categoryItemId?: string;
    skip: number;
    take: number;
  }) => {
    const { data: supplierCompanies } = await CumdAPI.getCompaniesByFilters(
      {},
      {
        relatedOwnerCompanyId: ownerCompanyId,
        companyActivityId: CompanyActivityId,
        companyCountry,
        searchName: searchText,
        isCompanyRelationStatusEnabled,
        includePrivate: true,
        OnlyWithoutContacts,
        ContactResponsibilityId,
        ContactUserId,
        relatedCategoryId: categoryId,
        relatedCategoryItemId: categoryItemId,
        skip,
        take,
      }
    );
    return supplierCompanies;
  }
);
