import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Heading, Paragraph, Tooltip } from "@trace-one/design-system";
import { SectionsIds } from "pages/Sites/constants";
import useSiteAddress from "pages/Sites/hooks/useSiteAddress";

import usePermissions from "core/oidc/usePermissions";

import { selectIdentifiersOptions } from "reduxStore/shared/selectors";
import { selectSite } from "reduxStore/siteSupplierDetails/selectors";

import Label from "components/Label";
import Panel from "components/Panel";
import TabPage from "components/TabPage";
import { capitalizeText } from "shared/utils/capitalizeFullText";

import styles from "../../SiteSupplierDetails.module.less";

const SiteBloc = () => {
  const intl = useIntl();

  const site = useSelector(selectSite);
  const identifiersOptions = useSelector(selectIdentifiersOptions);
  const history = useHistory();
  const { sitesWrite } = usePermissions();

  const { hasAddress, addressData, showGmapLink, gmapLink } =
    useSiteAddress(site);

  return (
    <>
      <div style={{ marginBottom: 30 }}>
        <Panel>
          <TabPage.SecondaryTitle
            label={
              <Heading size="xs">
                {capitalizeText(intl.formatMessage({ id: "siteForm.idTitle" }))}
              </Heading>
            }
          />
          {site.siteActive && sitesWrite && (
            <Button
              type="link"
              className={styles.iconBtn}
              data-test-id="privateInfo-button"
              iconName="edit"
              iconPlacement="left"
              onClick={() => {
                history.push({
                  pathname: `/sites/edit/${site.id}`,
                  hash: SectionsIds.IDENTIFICATION,
                });
              }}
            >
              {intl.formatMessage({ id: "general.edit" })}
            </Button>
          )}
          <Label
            bordered
            label={
              <Heading size="xxs">
                {intl.formatMessage({ id: "siteDetails.code" })}
              </Heading>
            }
            testId="site-codes"
          >
            <Paragraph size="m">
              {site?.identifierCodes.map(({ value, typeId }, idx) => {
                const typeLabel = identifiersOptions.find(
                  i => typeId === i.value
                )?.name;
                return (
                  <Tooltip
                    showFullText
                    text={`${value} (${typeLabel})`}
                    placement="top"
                  >
                    <div
                      key={idx}
                      className={styles.siteSupplierDetailsParagraph}
                    >{`(${typeLabel}) ${value}`}</div>
                  </Tooltip>
                );
              })}
            </Paragraph>
          </Label>
        </Panel>
      </div>

      <Panel>
        <TabPage.SecondaryTitle
          label={
            <Heading size="xs">
              {capitalizeText(
                intl.formatMessage({ id: "siteForm.addressTitle" })
              )}
            </Heading>
          }
        />
        {site.siteActive && sitesWrite && (
          <Button
            type="link"
            className={styles.iconBtn}
            data-test-id="privateInfo-button"
            iconName="edit"
            iconPlacement="left"
            onClick={() => {
              history.push({
                pathname: `/sites/edit/${site.id}`,
                hash: SectionsIds.ADDRESS,
              });
            }}
          >
            {intl.formatMessage({ id: "general.edit" })}
          </Button>
        )}
        <>
          <Label
            label={
              <Heading size="xxs">
                {intl.formatMessage({ id: "general.address" })}
              </Heading>
            }
            bordered
            testId="site-address"
          >
            <Paragraph size="m">
              {hasAddress &&
                addressData.map((ln, idx) => (
                  <Tooltip showFullText text={ln} placement="top">
                    <div
                      key={idx}
                      className={styles.siteSupplierDetailsParagraph}
                    >
                      {ln}
                    </div>
                  </Tooltip>
                ))}

              {showGmapLink && (
                <Button
                  data-test-id="gmap-link"
                  type="link"
                  size="smaill"
                  iconName="link"
                  iconPlacement="right"
                  onClick={() => {
                    window.open(gmapLink);
                  }}
                  className={styles.openGmap}
                >
                  <div className={styles.linkWrapper}>
                    <FormattedMessage id="general.openGmap" />
                  </div>
                </Button>
              )}
            </Paragraph>
          </Label>
        </>
      </Panel>
    </>
  );
};

export default SiteBloc;
