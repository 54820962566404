import { MutableRefObject, useState, useEffect } from "react";
import { useIntl } from "react-intl";

import { DocumentDetailAPIResponse } from "@trace-one/api-clients.dms";
import {
  Paragraph,
  Spinner,
  Illustration,
  Button,
  Tooltip,
} from "@trace-one/design-system";

import { fileLinksTypeId } from "shared/constants";

import useLinkedSites from "../../hooks/useLinkedSites";

import styles from "./LinkedItems.module.less";

interface ItemsProps {
  name: string;
  path: string;
}
interface Props {
  fileInfoRef?: MutableRefObject<DocumentDetailAPIResponse>;
}
const LinkedItems = ({ fileInfoRef }: Props) => {
  const intl = useIntl();

  const links =
    fileInfoRef.current?.fileHistory?.find(item => item.isCurrentVersion)
      ?.links || [];

  const { isLinksDataFetchingCompleted, siteLinksRef, projectLinksRef } =
    useLinkedSites({
      links,
    });
  const [linkedSites, setLinkedSites] = useState<ItemsProps[]>([]);
  const [linkedProjects, setLinkedProjects] = useState<ItemsProps[]>([]);

  useEffect(() => {
    if (isLinksDataFetchingCompleted) {
      const siteItems =
        links
          .filter(link => link.objectTypeId === fileLinksTypeId.site)
          .map(link => ({
            name:
              siteLinksRef?.current[link.objectId]?.siteName +
              " - " +
              siteLinksRef?.current[link.objectId]?.company?.displayName,
            path: `/sites/${link.objectId}`,
          })) || [];
      setLinkedSites(siteItems);

      const projectItems =
        projectLinksRef?.current?.map(link => ({
          name: link.name,
          path: link.link,
        })) || [];
      setLinkedProjects(projectItems);
    }
  }, [siteLinksRef, projectLinksRef, links, isLinksDataFetchingCompleted]);

  const Items = ({
    title,
    linkedItems,
    newTab,
  }: {
    title: string;
    linkedItems: ItemsProps[];
    newTab?: boolean;
  }) => {
    const [isItemsVisible, setIsItemsVisible] = useState<boolean>(true);
    return (
      <div className={styles.linkedItems}>
        <Paragraph
          onClick={() => setIsItemsVisible(prev => !prev)}
          className={styles.linkedItemTitle}
        >
          {title}
          <Button
            type="tertiary"
            data-test-id="md-documents-linkeditems-icon"
            iconOnly
            iconName={isItemsVisible ? "arrow-up" : "arrow-down"}
            className={styles.arrowIcon}
            size="small"
          />
        </Paragraph>
        {isItemsVisible && (
          <div className={styles.linkedItems}>
            {linkedItems.map(item => {
              return (
                <Tooltip
                  key={item.path}
                  showFullText
                  text={item.name}
                  placement="left"
                >
                  <Button
                    type="link"
                    size="small"
                    linkColor="grey-5"
                    className={styles.linkedItemLink}
                    href={item.path}
                    target={newTab && "_blank"}
                  >
                    {item.name}
                  </Button>
                </Tooltip>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {/* <Heading size="xxs">Linked Sites</Heading> */}
      <div>
        {(links.length === 0 ||
          (isLinksDataFetchingCompleted &&
            linkedSites.length === 0 &&
            linkedProjects.length === 0)) && (
          <div className={styles.noLinkSection}>
            <Illustration color="grey" name="no-data" />
            <Paragraph>
              {intl.formatMessage({
                id: "documentLibraryListPage.docDetails.tabs.linkedItems.noLinks",
              })}
            </Paragraph>
          </div>
        )}
        {links.length > 0 && !isLinksDataFetchingCompleted && (
          <Spinner color="primary" size="small" />
        )}
        {linkedSites.length > 0 && isLinksDataFetchingCompleted && (
          <Items
            title={intl.formatMessage({
              id: "documentLibraryListPage.docDetails.tabs.linkedSites.title",
            })}
            linkedItems={linkedSites}
          />
        )}
        {linkedProjects.length > 0 && isLinksDataFetchingCompleted && (
          <Items
            title={intl.formatMessage({
              id: "documentLibraryListPage.docDetails.tabs.linkedProjects.title",
            })}
            linkedItems={linkedProjects}
            newTab={true}
          />
        )}
      </div>
    </div>
  );
};

export default LinkedItems;
