import AXIOS_INSTANCES from "apis/axiosInstances";

const NotifAPI = AXIOS_INSTANCES.NOTIF;

// I have no idea why we are forced to repeat code in that way :(
const NotificationAPI = {
  getNotifications: ({
    NotificationEventUtcDateTimeAfter,
    SearchText,
    SenderZone,
    LanguageCode,
    Take,
    Skip,
  }) =>
    NotifAPI.get("/api/webNotifications", {
      params: {
        Skip,
        Take,
        LanguageCode,
        SenderZone,
        SearchText,
        NotificationEventUtcDateTimeAfter,
      },
    }),
  getNotificationIds: ({
    languageCode,
    senderZone,
    searchText,
    NotificationEventUtcDateTimeAfter,
  }) =>
    NotifAPI.get("/api/webNotifications/ids", {
      params: {
        languageCode,
        senderZone,
        searchText,
        NotificationEventUtcDateTimeAfter,
      },
    }),
  setReadStatus: (occurrenceUserInstanceIds, newStatus) =>
    NotifAPI.patch("api/occurrenceUserInstances/list/updateStatus", {
      occurrenceUserInstanceIds,
      newStatus,
    }),
  getNotificationPreferences: ({ languageCode, senderZone, level }) =>
    NotifAPI.get("/api/notificationPreferences", {
      params: {
        languageCode,
        senderZone,
        level,
      },
    }),
  postNotificationPreferences: ({ notificationPreferences }) =>
    NotifAPI.post("/api/notificationPreferences", {
      notificationPreferences,
    }),
  deleteNotifications: occurrenceUserInstanceIds =>
    NotifAPI.delete("api/occurrenceUserInstances/list", {
      data: { occurrenceUserInstanceIds },
    }),
  getCount: () =>
    NotifAPI.get("/api/webNotifications/countUnread", {
      params: { withDetailsPerSender: true },
    }),
};

export default NotificationAPI;
