import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { ColumnsType } from "antd/lib/table";
import moment from "moment";

import { ManufacturedItemState } from "reduxStore/productDetails/models";
import {
  selectCountriesOptions,
  selectPointOfSalesOptions,
} from "reduxStore/shared/selectors";

const mapTargetMarketItemToColumn = ({ targetItem }) => {
  const startDate = !!targetItem.startAvailabilityDate
    ? moment(targetItem.startAvailabilityDate).format(
        moment.localeData().longDateFormat("L")
      )
    : "";
  const endDate = !!targetItem.endAvailabilityDate
    ? moment(targetItem.endAvailabilityDate).format(
        moment.localeData().longDateFormat("L")
      )
    : "";

  return {
    key: targetItem.id,
    countryCodes: targetItem.countryCodes,
    pointsOfSales: targetItem.pointsOfSales,
    availabilityDates: `${startDate} - ${endDate}`,
  };
};

export default function useTargetMarketListTable({
  targetMarketsList,
}: {
  targetMarketsList: ManufacturedItemState["targetMarketsList"];
}) {
  const intl = useIntl();
  const countriesList = useSelector(selectCountriesOptions);
  const pointOfSalesList = useSelector(selectPointOfSalesOptions);

  const columns: ColumnsType<any> = [
    {
      title: intl.formatMessage({ id: "productDetails.targetMarkets" }),
      dataIndex: "countryCodes",
      key: "countryCodes",
      width: 200,
      ellipsis: true,
      render: countryCodes => {
        return (
          <span>
            {countryCodes
              .map(code => countriesList.find(c => c.value === code)?.name)
              .join(", ")}
          </span>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "productDetails.pointOfSales" }),
      dataIndex: "pointsOfSales",
      key: "pointsOfSales",
      width: 200,
      ellipsis: true,
      render: pointsOfSales => {
        return (
          <span>
            {pointsOfSales
              .map(point => pointOfSalesList.find(p => p.value === point)?.name)
              .join(", ")}
          </span>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "productDetails.availabilityDates" }),
      dataIndex: "availabilityDates",
      key: "availabilityDates",
      width: 200,
      ellipsis: true,
      render: availabilityDates => {
        return <span>{availabilityDates}</span>;
      },
    },
  ];

  const data =
    Array.isArray(targetMarketsList) &&
    targetMarketsList.map(targetItem =>
      mapTargetMarketItemToColumn({ targetItem })
    );

  return { columns, data };
}
