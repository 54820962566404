import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading, Button, Paragraph, Tooltip } from "@trace-one/design-system";
import useSiteAddress from "pages/Sites/hooks/useSiteAddress";

import { selectIdentifiersOptions } from "reduxStore/shared/selectors";
import { selectRetailerSite } from "reduxStore/siteRetailerDetails/selectors";

import Label from "components/Label";
import Panel from "components/Panel";
import TabPage from "components/TabPage";
import { capitalizeText } from "shared/utils/capitalizeFullText";

import styles from "../../../../SiteRetailerDetails.module.less";

const SiteBloc = () => {
  const intl = useIntl();
  const site = useSelector(selectRetailerSite);
  const identifiersOptions = useSelector(selectIdentifiersOptions);

  const { hasAddress, addressData, showGmapLink, gmapLink } =
    useSiteAddress(site);

  return (
    <Panel>
      <TabPage.SecondaryTitle
        label={
          <Heading size="xs">
            {capitalizeText(
              intl.formatMessage({ id: "siteForm.addressTitle" })
            )}
          </Heading>
        }
      />
      <>
        <Label
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "general.address" })}
            </Heading>
          }
          bordered
          testId="site-address"
        >
          <Paragraph size="m">
            {hasAddress &&
              addressData.map(ln => (
                <div key={ln} className={styles.siteRetailerDetailsParagraph}>
                  <Tooltip showFullText text={ln} placement="top">
                    {ln}
                  </Tooltip>
                </div>
              ))}

            {showGmapLink && (
              <Button
                type="link"
                size="smaill"
                iconName="link"
                iconPlacement="right"
                onClick={() => {
                  window.open(gmapLink);
                }}
                className={styles.openGmap}
              >
                <div className={styles.linkWrapper}>
                  <FormattedMessage id="general.openGmap" />
                </div>
              </Button>
            )}
          </Paragraph>
        </Label>

        <Label
          bordered
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "siteDetails.code" })}
            </Heading>
          }
          testId="site-codes"
        >
          <Paragraph size="m">
            {site?.identifierCodes.map(({ value, typeId }, idx) => {
              const typeLabel = identifiersOptions.find(
                i => typeId === i.value
              )?.name;
              return (
                <div key={idx} className={styles.siteRetailerDetailsParagraph}>
                  {`(${typeLabel}) ${value}`}
                </div>
              );
            })}
          </Paragraph>
        </Label>
      </>
    </Panel>
  );
};

export default SiteBloc;
