import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectSupplierForm = (state: RootState) => state.supplierForm;

export const selectSupplierForm = createSelector(
  _selectSupplierForm,
  ({ company, companyRelation, hasError, errorStatus, isLoading }) => ({
    company,
    companyRelation,
    hasError,
    errorStatus,
    isLoading,
  })
);
