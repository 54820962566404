import { ContactData } from "models/index";

import getUsersCollectionMap from "shared/utils/getUsersCollectionMap";

export type GetContactResut = ContactData & {
  userName?: string;
  userPhotoUrl?: string;
  userJobTitle?: string;
  userStatus?: string;
};

export default async function getContacts(
  contacts: ContactData[]
): Promise<GetContactResut[]> {
  if (!(contacts?.length > 0)) {
    return [];
  }
  const contactsMap = await getUsersCollectionMap(
    contacts.map(({ userId }) => userId)
  );

  const newContacts = contacts.map(item => ({
    ...item,
    userName: contactsMap[item.userId]?.userName,
    userPhotoUrl: contactsMap[item.userId]?.userPhotoUrl,
    userJobTitle: contactsMap[item.userId]?.userJobTitle,
    userStatus: contactsMap[item.userId]?.userStatus,
  }));

  return newContacts;
}
