const HeaderDetailsBackground = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="500"
      height="124.114"
      viewBox="0 0 500 124.114"
    >
      <g transform="translate(-133)">
        <path
          d="M500,124.114H220s-26.667-41.577-64-47.173c-28.909-12.367-53.333-4.2-101.333-32.175S0,0,0,0H500Z"
          transform="translate(133)"
          fill="#fbeef5"
        />
      </g>
    </svg>
  );
};

export default HeaderDetailsBackground;
