import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Heading, Paragraph, Tooltip } from "@trace-one/design-system";
import moment from "moment";
import { SectionsIds } from "pages/Products/components/ProductForm/constants";

import usePermissions from "core/oidc/usePermissions";

import { selectProductDetailsData } from "reduxStore/productDetails/selectors";

import Label from "components/Label";
import Panel from "components/Panel";
import TabPage from "components/TabPage";
import { TradeItemStatus } from "shared/constants";
import { capitalizeText } from "shared/utils/capitalizeFullText";

import styles from "../../../ProductDetails.module.less";

const SeasonalDates = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  const formatDate = (date: string) => {
    return !!date
      ? moment(date).format(moment.localeData().longDateFormat("L"))
      : "-";
  };

  if (!startDate && !endDate) return null;

  return (
    <span style={{ marginLeft: 10 }}>
      {`( ${formatDate(startDate)} to ${formatDate(endDate)} )`}
    </span>
  );
};

const ProductCharacteristicsInformation = () => {
  const intl = useIntl();
  const history = useHistory();
  const { productData } = useSelector(selectProductDetailsData);
  const { productsWrite } = usePermissions();
  const productIsDisabled =
    productData.tradeItemStatusId === TradeItemStatus.INACTIVE;

  return (
    <Panel>
      <TabPage.SecondaryTitle
        label={
          <Heading size="xs">
            {capitalizeText(
              intl.formatMessage({ id: "productForm.characteristics.title" })
            )}
          </Heading>
        }
      />
      {!productIsDisabled && productsWrite && (
        <Button
          type="link"
          className={styles.iconBtn}
          iconName="edit"
          iconPlacement="left"
          onClick={() => {
            history.push({
              pathname: `/products/edit/${productData.id}`,
              hash: SectionsIds.CHARATERISTICS,
            });
          }}
        >
          {intl.formatMessage({ id: "general.edit" })}
        </Button>
      )}

      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "productDetails.manufacturingPeriod" })}
          </Heading>
        }
        testId="prodDetails-manufacturingPeriod"
      >
        <Paragraph size="m">
          <Tooltip
            showFullText
            text={productData.manufacturingPeriod}
            placement="top"
          >
            <div className={styles.productDetailsParagraph}>
              {productData.manufacturingPeriod}
            </div>
          </Tooltip>
        </Paragraph>
      </Label>

      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "productDetails.variableUnit" })}
          </Heading>
        }
        testId="prodDetails-variableUnit"
      >
        {productData.isTradeItemVariableUnit !== null && (
          <FormattedMessage
            id={
              productData.isTradeItemVariableUnit ? "general.yes" : "general.no"
            }
          />
        )}
      </Label>

      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "productDetails.ean7Weight" })}
          </Heading>
        }
        testId="prodDetails-ean7Weight"
      >
        <Paragraph size="m"> {productData.ean7Weight} </Paragraph>
      </Label>

      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "productDetails.ean7Price" })}
          </Heading>
        }
        testId="prodDetails-ean7Price"
      >
        {productData.ean7Price}
      </Label>

      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "productDetails.seasonalUnit" })}
          </Heading>
        }
        testId="prodDetails-seasonalUnit"
      >
        {productData.isTradeItemSeasonal !== null && (
          <FormattedMessage
            id={productData.isTradeItemSeasonal ? "general.yes" : "general.no"}
          />
        )}
        {productData.seasonalAvailabilityStartDate !== null &&
          productData.seasonalAvailabilityEndDate !== null && (
            <SeasonalDates
              startDate={productData.seasonalAvailabilityStartDate}
              endDate={productData.seasonalAvailabilityEndDate}
            />
          )}
      </Label>
    </Panel>
  );
};

export default ProductCharacteristicsInformation;
