import { CompanyData } from "models";

type State = {
  suppliers: CompanyData[];
  suppliersSkipAndTakeQueryStats: {
    currentCount?: number;
    totalCount?: number;
  };
  isSuppliersLoading: boolean;
  hasSuppliersError: boolean;
};

export const initialState: State = {
  suppliers: [],
  suppliersSkipAndTakeQueryStats: {
    currentCount: undefined,
    totalCount: undefined,
  },
  isSuppliersLoading: false,
  hasSuppliersError: false,
};
