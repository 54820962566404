import { mapDateToMomentLocal } from "shared/utils/dateUtils";

const prepareInitialValues = ({ initialValues }) => {
  return {
    ...initialValues,
    siteRelations: {
      relationsToAdd: [],
      relationsToRemove: [],
    },
    siteCertificates:
      initialValues?.siteCertificates?.map(c => ({
        ...c,
        fileManager: {
          filesToAdd: [],
          attachments: c.attachments,
        },
        certificateDates: [
          mapDateToMomentLocal(c.certificateStartAvailabilityDate),
          mapDateToMomentLocal(c.certificateEndAvailabilityDate),
        ],
      })) ?? [],
  };
};

export default prepareInitialValues;
