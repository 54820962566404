import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Heading, Button, Tooltip } from "@trace-one/design-system";
import { Container, usePreviousLocation } from "@trace-one/react-components";
import { Form, FormProps } from "antd";
import {} from "antd/lib/form";
import cn from "classnames";
import { MenuItemType } from "rc-menu/lib/interface";
import { renderToStaticMarkup } from "react-dom/server";

import Icon from "components/Icon";
import IconButton from "components/IconButton";
import scrollTo from "shared/utils/scrollTo";

import HeaderBg from "./assets/HeaderDetailsBackground";
import { SectionsMenuProps } from "./components/SectionsMenu";
import TabHeader from "./components/TabHeader";
import styles from "./NewFormWrapper.module.less";
export const layout: Pick<
  FormProps,
  "labelCol" | "wrapperCol" | "labelAlign" | "size"
> = {
  labelCol: { span: 8, offset: 0 },
  wrapperCol: { span: 16, offset: 0 },
  labelAlign: "left",
  size: "large",
};

const setActiveProductTab = tab => {
  if (["product-identification-section", "attributes-section"].includes(tab)) {
    return 0;
  } else if (
    ["private-section"].includes(tab) ||
    [
      "site-identification-section",
      "site-visibility-section",
      "site-activities-section",
      "site-contacts-section",
      "site-private-section",
    ].includes(tab)
  ) {
    return 0;
  } else if (
    tab === "supplier-comments-section" ||
    [
      "site-address-section",
      "site-supplier-contacts-section",
      "site-comments-section",
    ].includes(tab)
  ) {
    return 1;
  } else if (
    [
      "manufactured-items-section",
      "manufactured-items-section-supplier-section",
    ].includes(tab)
  ) {
    return 1;
  } else if (["site-certificates-section"].includes(tab)) {
    return 2;
  } else if (tab === "comments-section") {
    return 3;
  } else {
    return 2;
  }
};

export interface FormWrapperProps<Values = any>
  extends Omit<FormProps<Values>, "title" | "onFinish"> {
  onBackClick: () => void;
  title: React.ReactNode;
  status?: React.ReactNode;
  submitText: string;
  cancelText?: string;
  disabled?: boolean;
  menuOptions?: SectionsMenuProps["options"];
  rightSide?: React.ReactNode;
  onFinish?: (values: Values) => Promise<any>;
  headerIcon?: any;
  tabs?: any;
  withBackNav?: any;
  subtitle?: any;
  handleChange?: (value: boolean) => void;
  activeTab?: number;
  hasSaveActions?: boolean;
  saveActions?: MenuItemType[];
  isArchiving?: boolean;
}

const FormWrapperInternal = <Values extends {}>(
  props: FormWrapperProps<Values>
): JSX.Element => {
  const {
    onBackClick,
    title,
    status,
    children,
    submitText,
    cancelText,
    form,
    disabled,
    onFinish,
    menuOptions,
    rightSide,
    headerIcon,
    tabs,
    withBackNav,
    subtitle,
    handleChange,
    activeTab,
    hasSaveActions,
    saveActions,
    isArchiving,
    ...rest
  } = props;
  const history = useHistory();
  // const { showbackButton, goPreviousPage } = useShowBackButton();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const headerRef = useRef(null);
  const svgString = encodeURIComponent(renderToStaticMarkup(<HeaderBg />));
  const previousLocation = usePreviousLocation();
  const [selectedTab, setSelectedTab] = useState(
    history?.location.hash
      ? setActiveProductTab(history?.location.hash?.replace("#", ""))
      : 0
  );

  useEffect(() => {
    const urlHash = history?.location.hash?.replace("#", "");
    if (!!urlHash) {
      const offsetTop =
        (headerRef?.current?.getBoundingClientRect().bottom ?? 0) + 20;
      const sectionEl = document.getElementById(urlHash);
      const top =
        sectionEl?.getBoundingClientRect().top +
        window.pageYOffset -
        offsetTop -
        200;
      scrollTo({ top, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (activeTab !== null) {
      setSelectedTab(activeTab);
    }
  }, [activeTab]);

  const setActiveTab = activeKey => {
    handleChange(true);
    setSelectedTab(parseInt(activeKey));
  };

  return (
    <div
      className={cn(styles.root, "new-form-wrapper", {
        [styles.wrapIfRightSide]: !!rightSide,
      })}
    >
      <Form
        form={form}
        scrollToFirstError={{ behavior: "smooth", block: "center" }}
        onFinish={values => {
          setIsSubmitting(true);
          return onFinish(values)
            .then(() => {
              setIsSubmitting(false);
            })
            .catch(() => {
              setIsSubmitting(false);
            });
        }}
        {...layout}
        {...rest}
      >
        <div className={cn(styles.root)}>
          <div
            className={cn("formHeaderWrapper", styles.headerWrapper)}
            style={{
              backgroundImage: `url("data:image/svg+xml,${svgString}")`,
            }}
          >
            <Container component="header" className={styles.header}>
              <div
                className={cn(styles.headerContent, {
                  [styles.paddingBottom]: !tabs,
                  [styles.headerContentWithSub]: !!subtitle,
                })}
              >
                <div className={styles.headerSubContent}>
                  {withBackNav && !!previousLocation && (
                    <div className={styles.backButtonWrapper}>
                      <IconButton
                        onClick={onBackClick}
                        data-test-id="md-form-back"
                        className={styles.backButton}
                      >
                        <Icon useTOLibrary name="long-arrow-left" />
                      </IconButton>
                    </div>
                  )}

                  {!!status && (
                    <div className={styles.status} data-test-id="header-status">
                      {status}
                    </div>
                  )}

                  <div className={styles.headerInfoWrapper}>
                    <div className={styles.titleWrapper}>
                      <Heading data-test-id="header-title" size="m">
                        <Tooltip showFullText text={title} placement="top">
                          <div className={styles.headerTitle}>{title} </div>
                        </Tooltip>
                      </Heading>
                    </div>
                    {!!subtitle && (
                      <div className={styles.subtitleWrapper}>
                        <span data-test-id="header-subtitle">{subtitle}</span>
                      </div>
                    )}
                  </div>
                </div>

                {!!tabs && (
                  <TabHeader
                    onChange={setActiveTab}
                    activeTab={selectedTab?.toString()}
                    tabs={tabs}
                  />
                )}
              </div>

              <div className={styles.iconWrapper}>
                <div className={styles.headerRight}>
                  {!!cancelText && (
                    <Button
                      type="secondary"
                      onClick={onBackClick}
                      data-test-id="md-form-cancel"
                      disabled={isSubmitting}
                    >
                      {cancelText}
                    </Button>
                  )}
                  {hasSaveActions ? (
                    <Button.Split
                      htmlType="submit"
                      type="primary"
                      loading={isArchiving || isSubmitting}
                      data-test-id="md-form-submit"
                      disabled={disabled}
                      hasItems={hasSaveActions}
                      items={saveActions}
                    >
                      {submitText}
                    </Button.Split>
                  ) : (
                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={isSubmitting}
                      data-test-id="md-form-submit"
                      disabled={disabled}
                    >
                      {submitText}
                    </Button>
                  )}
                </div>
                <div className={styles.headerIcon}>
                  <Icon name={headerIcon} />
                </div>
              </div>
            </Container>
          </div>
          {!!tabs ? (
            <Container className={styles.ProductInformation}>
              {tabs[selectedTab]?.content}
            </Container>
          ) : (
            <Container className={styles.childrenContainer}>
              {children}
            </Container>
          )}
        </div>

        <Container className={styles.container}>
          {/* {!!menuOptions && (
            <SectionMenu
              getOffsetTop={() =>
                (headerRef.current?.getBoundingClientRect().bottom ?? 0) + 20
              }
              options={menuOptions}
            />
          )} */}
          <div
            className={cn(styles.right, {
              [styles.rightWithMenuOptions]: !!menuOptions,
            })}
          >
            <div className={styles.children}>{children}</div>
            {!!rightSide && <div className={styles.rightSide}>{rightSide}</div>}
          </div>
        </Container>
      </Form>
    </div>
  );
};

type InternalFormWrapperType = typeof FormWrapperInternal;
interface FormWrapperInterface extends InternalFormWrapperType {}

const NewFormWrapper = FormWrapperInternal as FormWrapperInterface;

export default NewFormWrapper;
