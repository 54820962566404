import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Heading, Paragraph, TagV1 as Tag } from "@trace-one/design-system";
import { Col, Row } from "antd";
import moment from "moment";
import { PageCol } from "pages/Sites/constants";
import { compose } from "redux";

import { useAppDispatch } from "reduxStore";
import { fetchClassificationItem } from "reduxStore/classificationDetails/asyncActions";
import {
  selectClassificationItem,
  selectGlobalCompanyName,
} from "reduxStore/classificationDetails/selectors";
import { setEditableData } from "reduxStore/classificatonDirectChilds/slice";
import { selectUserOwningCompanyName } from "reduxStore/user/selectors";

import ErrorPage from "components/ErrorPage";
import HeaderDetails from "components/HeaderDetails";
import Spinner from "components/Spinner";
import { TeamMemberResponsibilityParentItem } from "shared/constants";
import {
  withCategoryLists,
  withLanguages,
  withTeamMemberResponsibilities,
} from "shared/hocs";

import { CatageoryItemData } from "../ClassificationDetails/CategoryItemForm/models";

import CategoryItemForm from "./CategoryItemForm";
import styles from "./ClassificationDetails.module.less";
import ClassificationDirectChilds from "./ClassificationDirectChilds";

const enhance = compose<React.FC>(
  withLanguages(),
  withCategoryLists(),
  withTeamMemberResponsibilities({
    includeParentItems: true,
    parentItemId: TeamMemberResponsibilityParentItem.RETAILER,
  })
);

interface EditModalData {
  isEditModal: boolean;
  selectedKey: string[];
}

const ClassificationDetails = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { classificationId } = useParams<{ classificationId?: string }>();

  const ownerCompanyName = useSelector(selectUserOwningCompanyName);
  const globalCompanyName = useSelector(selectGlobalCompanyName);
  const {
    isClassificationLoading,
    hasClassificationError,
    classification,
    errorStatus,
  } = useSelector(selectClassificationItem);

  const [isLoading, setIsLoading] = useState(false);
  const [showCategory, setShowCategory] = useState<boolean>(false);
  const [categoryValue, setCategoryValue] = useState<string>("");
  const [parentId, setParentId] = useState<string[]>([]);
  const [isFormChangedValue, setFormChangedValue] = useState<boolean>(false);
  const [editModalData, setEditModalData] = useState<EditModalData>({
    isEditModal: false,
    selectedKey: [],
  });
  const [categoryData, setCategoryData] = useState<CatageoryItemData>();

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchClassificationItem({ classificationId })).then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!showCategory) setCategoryValue("");
  }, [showCategory]);

  const ClassificationSubtitle = () => {
    const updatedDate = moment(classification.updatedDate).format(
      moment.localeData().longDateFormat("L")
    );
    return (
      <div className={styles.coloredSubtitle}>
        <div className={styles.lastDate}>
          <Heading size="xxs">
            {intl.formatMessage({
              id: classification.isActive
                ? "general.lastUpdateDate"
                : "classification.archived.date.label",
            })}
            :
          </Heading>
          <Paragraph color="primary">{updatedDate}</Paragraph>
        </div>
        /
        <div className={styles.owner}>
          <Heading size="xxs">
            {intl.formatMessage({ id: "general.owner" })}:
          </Heading>
          <Paragraph color="primary">
            {globalCompanyName || ownerCompanyName}
          </Paragraph>
        </div>
      </div>
    );
  };

  if (hasClassificationError) return <ErrorPage status={errorStatus} />;
  if (isClassificationLoading || isLoading) return <Spinner underHeader />;

  const handleCategoryDetail = (value: boolean, selectedKey: string[]) => {
    if (!value) setFormChangedValue(false);
    setShowCategory(value);
    setParentId(selectedKey);
  };

  const handleCategoryValue = (value: string) => {
    setCategoryValue(value);
  };

  const handleFormChanged = (value: boolean) => {
    setFormChangedValue(value);
  };

  const handleEditModal = (value: EditModalData) => {
    dispatch(setEditableData(value.isEditModal));
    setEditModalData(value);
  };

  const handleCategoryData = (value: CatageoryItemData) => {
    setCategoryData(value);
  };
  const ClassificationStatusTag = () => {
    if (classification.isActive) {
      return (
        <Tag
          label={intl.formatMessage({ id: "general.active" })}
          color="green"
          mode="light"
        />
      );
    } else {
      return (
        <Tag
          label={intl.formatMessage({ id: "general.archived" })}
          color="grey"
          mode="light"
        />
      );
    }
  };

  return (
    <>
      <HeaderDetails
        withBackNav
        title={classification?.categoryListName}
        subtitle={<ClassificationSubtitle />}
        status={<ClassificationStatusTag />}
        headerIcon="classification-detail"
      ></HeaderDetails>
      <Row>
        <Col {...PageCol.HALF}>
          <ClassificationDirectChilds
            categoryActive={classification?.isActive}
            handleCategoryDetail={handleCategoryDetail}
            categoryValue={categoryValue}
            showCategory={showCategory}
            isFormChangedValue={isFormChangedValue}
            handleCategoryValue={handleCategoryValue}
            handleEditModal={handleEditModal}
            handleCategoryData={handleCategoryData}
            editModalData={editModalData}
            handleFormChanged={handleFormChanged}
          />
        </Col>
        {showCategory && (
          <Col {...PageCol.HALF}>
            <CategoryItemForm
              handleCategoryValue={handleCategoryValue}
              classificationId={classificationId}
              handleCategoryDetail={handleCategoryDetail}
              parentId={parentId}
              handleFormChanged={handleFormChanged}
              editModalData={editModalData}
              categoryData={categoryData}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default enhance(ClassificationDetails);
