import moment, { Moment } from "moment";

export function mapDateToMomentLocal(dateString?: moment.MomentInput) {
  return dateString ? moment.utc(dateString).local() : null;
}

export function mapMomentLocalToDate(dateMoment?: Moment) {
  return dateMoment
    ? dateMoment.set({ hour: 0, minute: 0, second: 0 }).utc(true).format()
    : null;
}
