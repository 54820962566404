import { useState } from "react";
import { useIntl } from "react-intl";

import { Tooltip, TagV1 as Tag } from "@trace-one/design-system";

import CustomInputGroup from "components/CustomInputGroup";
import Input from "components/Input";

interface AttributesInputProps {
  value?: any;
  onChange?: Function;
  disabled: boolean;
}
interface AttributesParams {
  retailerLabel: string;
  retailerCode: string;
}

const AttributesInput: React.FC<AttributesInputProps> = ({
  value: retailerCustomizedAttributes = [],
  onChange,
  disabled,
}) => {
  const intl = useIntl();
  const formInitialState = { retailerLabel: null, retailerCode: null };
  const [attributeForm, setAttributeForm] =
    useState<AttributesParams>(formInitialState);

  const isMoreOrEqual10 = retailerCustomizedAttributes?.length >= 10;
  const isFormUncomplete = Object.values(attributeForm).some(
    x => x === null || x === ""
  );

  return (
    <CustomInputGroup
      data-test-id="md-supplier-retailerCustomizedAttributes-add"
      disabled={isFormUncomplete}
      leftInput={
        <Input
          value={attributeForm.retailerLabel}
          onChange={event => {
            const { value } = event.target;
            setAttributeForm(state => ({ ...state, retailerLabel: value }));
          }}
          disabled={disabled || isMoreOrEqual10}
          maxLength={256}
          placeholder={intl.formatMessage({
            id: "general.enterAttributeLabel",
          })}
          data-test-id="md-supplier-retailerCustomizedAttributes-code"
        />
      }
      rightInput={
        <Input
          value={attributeForm.retailerCode}
          onChange={event => {
            const { value } = event.target;
            setAttributeForm(state => ({ ...state, retailerCode: value }));
          }}
          disabled={disabled || isMoreOrEqual10}
          maxLength={256}
          placeholder={intl.formatMessage({
            id: "general.enterAttributeValue",
          })}
          data-test-id="md-supplier-retailerCustomizedAttributes-value"
        />
      }
      onItemAdd={() => {
        onChange([...retailerCustomizedAttributes, attributeForm]);
        setAttributeForm(formInitialState); //clear fields
      }}
    >
      {retailerCustomizedAttributes.map(
        ({ retailerCode, retailerLabel }, index) => {
          const label = `${retailerLabel}: ${retailerCode}`;
          return (
            <Tooltip showFullText text={label} placement="top">
              <Tag
                key={`tag-${index}`}
                label={<span>{label}</span>}
                data-test-id={`tag-${label}`}
                onClose={() => {
                  onChange(
                    retailerCustomizedAttributes.filter(
                      item =>
                        !(
                          item.retailerCode === retailerCode &&
                          item.retailerLabel === retailerLabel
                        )
                    )
                  );
                }}
              />
            </Tooltip>
          );
        }
      )}
    </CustomInputGroup>
  );
};

export default AttributesInput;
