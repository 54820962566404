import { memo, useState, useEffect, useRef, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Filter } from "@trace-one/business-components";
import { Typography, Picker } from "@trace-one/design-system";
import { Checkbox } from "antd";

import { CumdAPI } from "apis";

import {
  selectCountriesOptions,
  selectTeamMemberResponsibilities,
  selectUserOptions,
} from "reduxStore/shared/selectors";
import {
  selectSupplierCompaniesOptions,
  selectAllSupplierCompaniesOptions,
} from "reduxStore/siteList/selectors";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import { getSupplierName } from "components/AsyncCompanySelect";
import AsyncPickerDropdown from "components/AsyncPickerDropdown";
import CategoryTreeSelect from "components/CategoryTreeSelect";
import CheckableTags from "components/CheckableTags";
import FilterMultiSelection from "components/FilterMultiSelection";
import ContactsInputGroup from "components/SearchFilters/components/ContactInputGroupFilter";
import Select from "components/Select";
import { CompanyActivityId } from "shared/constants";
import buildCompanyTitle from "shared/utils/buildCompanyTitle";
import getUsersCollectionMap from "shared/utils/getUsersCollectionMap";
import { adjustIntercomButton } from "shared/utils/toggleIntercom";

import { SiteRetailerFilter } from "../modals";

interface RetailerSearchProps {
  isRetailer: boolean;
  filterObj: SiteRetailerFilter;
  mergeFilters: (
    value:
      | Partial<SiteRetailerFilter>
      | ((prevState: SiteRetailerFilter) => SiteRetailerFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: (searchValue: string) => void;
  onClearFiltersClick: () => void;
  setSelectedKpi: (kpi: string) => void;
}

const SiteRetailerSearch: React.FC<RetailerSearchProps> = ({
  isRetailer,
  filterObj,
  onSearch,
  initialSearchValue,
  mergeFilters,
  onClearFiltersClick,
  setSelectedKpi,
}) => {
  const intl = useIntl();
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const countries = useSelector(selectCountriesOptions);
  const supplierCompanyOptions = useSelector(selectSupplierCompaniesOptions);
  const allSupplierCompanyOptions = useSelector(
    selectAllSupplierCompaniesOptions
  );
  const companies = isRetailer ? supplierCompanyOptions : [];
  const usersOptions = useSelector(selectUserOptions);
  const teamMemberResponsibilitiesOption = useSelector(
    selectTeamMemberResponsibilities
  );
  const [selectedFilters, setSelectedFilters] = useState([]);
  const showIntercom = useRef(true);

  const companyOptions = useMemo(() => {
    return companies.map(company => ({
      label: getSupplierName(
        buildCompanyTitle(company),
        company.isCompanyRelationStatusEnabled,
        company
      ),
      value: company.companyId,
      searchLabel: buildCompanyTitle(company),
    }));
  }, [companies]);

  const users = usersOptions
    .map(({ userFirstName, userLastName, userId, userStatus }) => ({
      name: `${userFirstName} ${userLastName}`,
      value: userId,
      userStatus,
    }))
    .filter(item => item.name !== " ");

  const activeStatus = [
    {
      value: "true",
      label: intl.formatMessage({ id: "general.active" }),
    },
    {
      value: "false",
      label: intl.formatMessage({ id: "general.inactive" }),
    },
  ];

  const siteCertificatesOptions = [
    {
      value: "SiteWithNoCertificate",
      name: intl.formatMessage({ id: "siteForm.SiteWithNoCertificate" }),
    },
    {
      value: "SiteWithCertificateExpire",
      name: intl.formatMessage({ id: "siteForm.SiteWithCertificateExpire" }),
    },
    {
      value: "SiteWithCertificateNoEndDate",
      name: intl.formatMessage({ id: "siteForm.SiteWithCertificateNoEndDate" }),
    },
  ];

  useEffect(() => {
    const { userId } = filterObj;
    if (userId) {
      fetchUser(userId);
    }
  }, [filterObj, users]);

  useEffect(() => {
    const {
      associatedCompanyIds,
      associatedCompanyLabels,
      productCategory,
      siteCountry,
      siteActive,
      checked,
      userId,
      siteCertificate,
    } = filterObj;

    const defaultSelectedFilters = [
      ...(associatedCompanyIds
        ? [
            {
              filterId: "1",
              values: associatedCompanyLabels
                ? associatedCompanyLabels
                : companies
                    ?.filter(company =>
                      associatedCompanyIds.includes(company.companyId)
                    )
                    .map(company => buildCompanyTitle(company)),
            },
          ]
        : []),

      ...(siteCountry
        ? [
            {
              filterId: "2",
              values: [siteCountry],
            },
          ]
        : []),
      ...(productCategory
        ? [
            {
              filterId: "3",
              values: [productCategory],
            },
          ]
        : []),
      ...(siteActive
        ? [
            {
              filterId: "4",
              values: siteActive,
            },
          ]
        : []),
      ...(userId
        ? [
            {
              filterId: "5",
              values: [userId],
            },
          ]
        : []),
      ...(checked
        ? [
            {
              filterId: "6",
              values: [true],
            },
          ]
        : []),
      ...(siteCertificate
        ? [
            {
              filterId: "7",
              values: [true],
            },
          ]
        : []),
    ];
    setSelectedFilters(defaultSelectedFilters);
  }, [filterObj]);

  const fetchUser = async userId => {
    if (users && userId) {
      let currentUser = users.find(({ value }) => value === userId);
      if (!currentUser) {
        const user = await getUsersCollectionMap([userId]);
        if (user) {
          const { userFirstName, userLastName, userStatus } = user[userId];
          const selectedUser = {
            name: `${userFirstName} ${userLastName}`,
            value: userId,
            userStatus,
          };
          users.push(selectedUser);
        }
      }
    }
  };

  const filters = [
    {
      title: intl.formatMessage({ id: "general.supplier" }),
      controlType: "multi_selection",
      clearSelection: () => {
        mergeFilters({
          associatedCompanyIds: null,
          associatedCompanyLabels: null,
        });
      },
      elementToRender: (
        <FilterMultiSelection
          key="supplier-selection"
          dataTestId="md-siteFilter-supplier-selection"
          options={companyOptions}
          values={filterObj.associatedCompanyIds ?? []}
          onAsyncSearch={({ searchValue }) => {
            return CumdAPI.getCompaniesByFilters(
              {},
              {
                searchName: searchValue,
                relatedOwnerCompanyId: ownerCompanyId,
                companyActivityId: CompanyActivityId.SUPPLIER,
                includePrivate: true,
              }
            ).then(({ data }) => {
              return data.companies.map(company => ({
                label: getSupplierName(
                  buildCompanyTitle(company),
                  company.isCompanyRelationStatusEnabled,
                  company
                ),
                value: company.companyId,
                searchLabel: buildCompanyTitle(company),
              }));
            });
          }}
          onScrollSearch={take => {
            return CumdAPI.getCompaniesByFilters(
              {},
              {
                skip: 0,
                take: take,
                relatedOwnerCompanyId: ownerCompanyId,
                companyActivityId: CompanyActivityId.SUPPLIER,
                includePrivate: true,
                isCompanyRelationStatusEnabled: true,
              }
            ).then(({ data }) => {
              return data.companies.map(company => ({
                label: getSupplierName(
                  buildCompanyTitle(company),
                  company.isCompanyRelationStatusEnabled,
                  company
                ),
                value: company.companyId,
                searchLabel: buildCompanyTitle(company),
              }));
            });
          }}
          getValuesOnly
          onChange={(selectedValues: string[]) => {
            setSelectedKpi(null);
            const associatedCompanyLabels = selectedValues.length
              ? allSupplierCompanyOptions
                  .filter(company => selectedValues.includes(company.companyId))
                  .map(company => buildCompanyTitle(company))
              : undefined;
            mergeFilters({
              associatedCompanyIds: selectedValues.length
                ? selectedValues
                : undefined,
              associatedCompanyLabels: associatedCompanyLabels,
            });
          }}
        />
      ),
      quickFilter: (
        <AsyncPickerDropdown
          data-test-id="picker-filter-supplier"
          searchPlaceholderText={intl.formatMessage({ id: "general.search" })}
          clearBtnText={intl.formatMessage({ id: "general.clear" })}
          closeBtnText={intl.formatMessage({ id: "general.close" })}
          title={intl.formatMessage({ id: "general.supplier" })}
          showBadgeCount
          defaultOptions={companyOptions}
          value={filterObj.associatedCompanyIds ?? []}
          selectionType="multi"
          onSearch={undefined}
          showCheckBoxes
          minLengthToSearch={3}
          onAsyncSearch={searchValue => {
            return CumdAPI.getCompaniesByFilters(
              {},
              {
                searchName: searchValue,
                relatedOwnerCompanyId: isRetailer ? ownerCompanyId : undefined,
                relatedAssociatedCompanyId: isRetailer
                  ? undefined
                  : ownerCompanyId,
                companyActivityId: CompanyActivityId.SUPPLIER,
                includePrivate: true,
              }
            ).then(({ data }) => {
              return data.companies.map(company => ({
                label: getSupplierName(
                  buildCompanyTitle(company),
                  company.isCompanyRelationStatusEnabled,
                  company
                ),
                value: company.companyId,
                searchLabel: buildCompanyTitle(company),
              }));
            });
          }}
          searchOnChange
          showSearchInput
          onChange={({ selectedItem, value }) => {
            setSelectedKpi(null);
            let associatedCompanyLabels = [];
            if (
              filterObj.associatedCompanyLabels?.includes(
                selectedItem.searchLabel
              )
            ) {
              associatedCompanyLabels =
                filterObj.associatedCompanyLabels.filter(
                  x => x !== selectedItem.searchLabel
                );
            } else {
              associatedCompanyLabels = [
                ...(filterObj.associatedCompanyLabels || []),
                selectedItem.searchLabel,
              ];
            }
            mergeFilters({
              associatedCompanyIds: value,
              associatedCompanyLabels: associatedCompanyLabels,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              associatedCompanyIds: null,
              associatedCompanyLabels: null,
            })
          }
        ></AsyncPickerDropdown>
      ),
      filterId: "1",
    },
    {
      title: intl.formatMessage({
        id: "general.country",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({ siteCountry: null });
      },
      elementToRender: (
        <Select
          allowClear
          showSearch
          options={countries}
          value={filterObj.siteCountry}
          getPopupContainer={trigger => trigger.parentElement}
          onChange={siteCountry => {
            setSelectedKpi(null);
            mergeFilters({ siteCountry });
          }}
          data-test-id="md-siteFilter-country"
          filterOption={(input, option) =>
            (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
      filterId: "2",
    },
    {
      title: intl.formatMessage({
        id: "general.productCategory",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({ productCategory: undefined });
      },
      elementToRender: (
        <CategoryTreeSelect
          showSearch
          multiple={false}
          onChange={productCategory => {
            setSelectedKpi(null);
            mergeFilters({ productCategory });
          }}
          value={filterObj?.productCategory}
          getPopupContainer={trigger => trigger.parentElement}
          onFullChange={value => {}}
          categorySelection={true}
          data-test-id="md-productCategory"
        />
      ),
      filterId: "3",
    },
    {
      title: intl.formatMessage({
        id: "general.status",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          siteActive: [],
        });
      },
      elementToRender: (
        <CheckableTags
          value={filterObj.siteActive}
          onChange={siteActive => {
            setSelectedKpi(null);
            mergeFilters({ siteActive });
          }}
          options={activeStatus}
        />
      ),
      quickFilter: (
        <Picker.Filter
          data-test-id="picker-filter-status"
          title={intl.formatMessage({
            id: "general.status",
          })}
          clearBtnText={intl.formatMessage({ id: "general.clear" })}
          closeBtnText={intl.formatMessage({ id: "general.close" })}
          showBadgeCount
          items={activeStatus}
          value={filterObj.siteActive ?? []}
          selectionType="multi"
          onChange={({ value: siteActive }) => {
            setSelectedKpi(null);
            mergeFilters({
              siteActive,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              siteActive: [],
            })
          }
        ></Picker.Filter>
      ),
      filterId: "4",
    },
    {
      title: intl.formatMessage({
        id: "productDetails.contactNameText",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          userId: undefined,
          responsibilityId: undefined,
        });
      },
      elementToRender: (
        <div style={{ maxWidth: "100%" }}>
          <ContactsInputGroup
            setSelectedKpi={setSelectedKpi}
            filterObj={filterObj}
            mergeFilters={mergeFilters}
            teamMemberUsers={users}
            disabled={filterObj.checked}
            withUserStatusMention
            teamMemberResponsibilitiesOption={teamMemberResponsibilitiesOption}
          />
        </div>
      ),
      filterId: "5",
    },
    {
      title: intl.formatMessage({
        id: "general.site",
      }),
      controlType: "single_selection",
      elementToRender: (
        <Checkbox
          data-test-id="md-with-no-contact"
          checked={filterObj.checked}
          onChange={e => {
            setSelectedKpi(null);
            mergeFilters({
              checked: e.target.checked,
              userId: undefined,
              responsibilityId: undefined,
            });
          }}
        >
          <Typography component="span">
            {intl.formatMessage({
              id: "productDetails.withNoContactText",
            })}
          </Typography>
        </Checkbox>
      ),
      filterId: "6",
    },
    {
      title: intl.formatMessage({
        id: "sitesListPage.table.siteCertificates",
      }),
      controlType: "single_selection",
      elementToRender: (
        <Select
          allowClear
          showSearch
          options={siteCertificatesOptions}
          getPopupContainer={trigger => trigger.parentElement}
          value={filterObj.siteCertificate}
          onChange={siteCertificate => {
            setSelectedKpi(null);
            mergeFilters({ siteCertificate });
          }}
          data-test-id="md-siteFilter-certificate"
        />
      ),
      filterId: "7",
    },
  ];

  return (
    <Filter
      filters={filters}
      selectedFilters={selectedFilters}
      clearAllFilters={() => {
        setSelectedKpi(null);
        onClearFiltersClick();
      }}
      search={{
        defaultValue: initialSearchValue,
        placeholder: intl.formatMessage({
          id: "sitesListPage.search",
        }),
        onSearch: query => {
          onSearch(query);
        },
      }}
      onAllFilterPanelToggle={() => {
        showIntercom.current = !showIntercom.current;
        adjustIntercomButton(showIntercom.current);
      }}
    />
  );
};

export default memo(SiteRetailerSearch);
