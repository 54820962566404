const prepareFormData = ({
  identifier,
  status,
  functionIds,
  categories,
  comments,
  ...rest
}) => ({
  ...rest,
  associatedCompanyIdentifier: identifier,
  associatedCompanyFunctionIds: functionIds,
  categories: categories,
  comments: comments,
  // productManagerId: values.productManagerId?.value,
  // qualityManagerId: values.qualityManagerId?.value,
  // packagingManagerId: values.packagingManagerId?.value,
});

export default prepareFormData;
