import { Col } from "antd";
import { PageCol } from "pages/Sites/constants";

import ProductCharacteristicsInformation from "./ProductCharacteristicsInformation";

const ProductCharacteristics = () => {
  return (
    <>
      <Col {...PageCol.FULL}>
        <ProductCharacteristicsInformation />
      </Col>
    </>
  );
};

export default ProductCharacteristics;
