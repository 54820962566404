import { createAsyncThunk } from "@reduxjs/toolkit";

import { SmdAPI, CumdAPI } from "apis";
import {
  CompanyRelationData,
  SiteData,
  SiteRelationData,
  UserData,
} from "models";

import getAuthorsComments, {
  AuthorsCommentsType,
} from "shared/utils/getAuthorsComments";
import getCategoriesWithHierarchy from "shared/utils/getCategoriesWithHierarchy";

export const fetchRetailerSite = createAsyncThunk<
  {
    site: SiteData & {
      siteOwnerCompanyName: string;
    };
    relation: Omit<SiteRelationData, "comments"> & {
      comments: AuthorsCommentsType;
    };
    relatedUsers: UserData[];
    companyRelation: CompanyRelationData;
  },
  { siteId: string; associatedCompanyId: string; languageCode: string },
  { rejectValue: { status: number } }
>(
  "siteRetailerDetails/fetchRetailerSite",
  async ({ siteId, associatedCompanyId, languageCode }, thunkAPI) => {
    try {
      const [site, relation] = await Promise.all([
        SmdAPI.getSiteById(siteId).then(({ data }) => data),
        SmdAPI.getSiteRelationBySiteIdAndAssociatedCompanyId(siteId, {
          associatedCompanyId,
        }).then(({ data }) => data),
      ]);
      const userIds = [
        ...(site.contactUserIds || []),
        ...(relation?.contacts?.map(c => c.userId) || []),
      ];

      const cateoriesId = relation.categories.map(
        category => category.categoryItemId
      );

      const [contactUsers, supplier, companyRelation, categories, comments] =
        await Promise.all([
          userIds.length
            ? await CumdAPI.getUsersByUserIds({
                userIds,
              }).then(({ data }) => data)
            : [],
          await CumdAPI.getCompanyById(site.ownerCompanyId).then(
            ({ data }) => data
          ),
          CumdAPI.getCompanyRelation(relation.associatedCompanyId, {
            associatedCompanyId: site.ownerCompanyId,
          }).then(({ data }) => data),
          await getCategoriesWithHierarchy(cateoriesId, languageCode),
          await getAuthorsComments({ comments: relation?.comments }),
        ]);

      const relationContacts =
        relation?.contacts?.map(contact => {
          return {
            ...contactUsers.find(({ userId }) => userId === contact.userId),
            ...contact,
          };
        }) || [];
      relation.retailerCustomizedAttributes.sort((a, b) =>
        a.retailerLabel.localeCompare(b.retailerLabel)
      );
      return {
        site: { ...site, siteOwnerCompanyName: supplier.companyDisplayName },
        supplier: { ...supplier },
        relation: {
          ...relation,
          comments,
          contacts: relationContacts,
          categoriesList: categories,
        },
        relatedUsers: contactUsers,
        companyRelation,
      };
    } catch (err) {
      const status = err?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({ status });
    }
  }
);
