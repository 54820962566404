import { Row } from "antd";

import TabPage from "components/TabPage";

import PackagingInformation from "./PackagingInformation";
import ProductCharacteristics from "./ProductCharacteristics";

interface AdditionalInformationTabProps {}
const AdditionalInformationTab: React.FC<
  AdditionalInformationTabProps
> = () => {
  return (
    <TabPage.Wrapper>
      <Row gutter={[32, 32]}>
        <ProductCharacteristics />
        <PackagingInformation />
      </Row>
    </TabPage.Wrapper>
  );
};

export default AdditionalInformationTab;
