import { createAsyncThunk } from "@reduxjs/toolkit";

import { RlmdAPI } from "apis";

export const fetchClassificationItem = createAsyncThunk(
  "classificationDetails/fetchClassificationItem",
  async ({ classificationId }: { classificationId: string }, thunkAPI) => {
    try {
      const { data } = await RlmdAPI.getCategoryListById(classificationId);
      return data;
    } catch (err) {
      const status = err?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({ status });
    }
  }
);
