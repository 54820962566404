import { ReactComponent as archive } from "./svg/archive.svg";
import { ReactComponent as brandAdd } from "./svg/brand-add.svg";
import { ReactComponent as classificationDetail } from "./svg/classification-detail.svg";
import { ReactComponent as classification } from "./svg/classification.svg";
import { ReactComponent as factory } from "./svg/factory.svg";
import { ReactComponent as notifications } from "./svg/notifications.svg";
import { ReactComponent as productAdd } from "./svg/product-add.svg";
import { ReactComponent as siteAdd } from "./svg/site-add.svg";
import { ReactComponent as streamline } from "./svg/streamline.svg";
import { ReactComponent as supplier } from "./svg/supplier.svg";
import { ReactComponent as suppliers } from "./svg/suppliers.svg";

export interface IconMapper {
  Component: React.ElementType;
  defaultProps?: object;
}

const createSvgIcon = (
  Component: IconMapper["Component"],
  defaultProps: IconMapper["defaultProps"] = {}
) => ({
  Component,
  defaultProps,
});

const iconMapping = Object.freeze({
  archive: createSvgIcon(archive, { width: 20, height: 20 }),
  "brand-add": createSvgIcon(brandAdd),
  classification: createSvgIcon(classification),
  "classification-detail": createSvgIcon(classificationDetail),
  notifications: createSvgIcon(notifications, { width: 20, height: 20 }),
  "product-add": createSvgIcon(productAdd),
  "site-add": createSvgIcon(siteAdd),
  streamline: createSvgIcon(streamline),
  supplier: createSvgIcon(supplier),
  suppliers: createSvgIcon(suppliers),
  factory: createSvgIcon(factory),
});

export type IconName = keyof typeof iconMapping;

export default iconMapping;
