import React from "react";

export default function getDisplayName(
  hocFunc: ((...args: unknown[]) => any) | string,
  Component: React.ComponentType | string
) {
  let name: string;
  if (typeof Component === "string") {
    name = Component;
  } else {
    name = Component?.displayName || Component?.name;
  }

  let wrapper: string;
  if (typeof hocFunc === "string") {
    wrapper = hocFunc;
  } else {
    wrapper = hocFunc.name;
  }
  return `${wrapper}(${name})`;
}
