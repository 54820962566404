import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Button, Tooltip } from "@trace-one/design-system";

import styles from "../../HeaderDetails.module.less";

type buttonProps = {
  text?: string;
  pathname?: string;
  showBtn?: boolean;
  testId?: string;
  showTooltip?: boolean;
  tab?: number;
  hash?: string;
  disabled?: boolean;
  actionList?: [];
};

interface HeaderButtonProps {
  buttons: buttonProps[];
  selectedTab: number;
  handleStatus?: () => void;
  tooltipTitle: React.ReactNode;
  haveActionItems?: boolean;
}

const HeaderButton: React.FC<HeaderButtonProps> = props => {
  const intl = useIntl();
  const history = useHistory();
  const [visible, setVisible] = useState<boolean>(false);
  const { buttons, selectedTab, handleStatus, tooltipTitle, haveActionItems } =
    props;
  let button = buttons.find(button => button.tab === selectedTab);

  const tooltipActions = [
    {
      text: intl.formatMessage({
        id: "general.cancel",
      }),
      onClick: (event: React.FocusEvent<HTMLElement>) => {
        event.target.blur();
        setVisible(false);
      },
    },
    {
      text: intl.formatMessage({
        id: "general.confirm",
      }),
      onClick: (event: React.FocusEvent<HTMLElement>) => {
        event.target.blur();
        handleStatus();
        setVisible(false);
      },
    },
  ];

  if (haveActionItems) {
    button = buttons[0];
    const actionItems = button.actionList
      .filter(({ showBtn }) => !!showBtn)
      .map(actionItem => {
        const { name, disabled, onClick, showTooltip } = actionItem;
        return {
          label: !showTooltip ? (
            <FormattedMessage id={name} />
          ) : (
            <Tooltip
              onOpenChange={() => {
                showTooltip ? setVisible(true) : setVisible(false);
              }}
              className="withNoArrow"
              trigger="click"
              placement="left"
              text={tooltipTitle}
              actions={tooltipActions}
              visible={visible}
            >
              <span style={{ display: "inline-block", width: "100%" }}>
                <FormattedMessage id={name} />
              </span>
            </Tooltip>
          ),
          disabled: disabled,
          onClick: onClick,
          "data-test-id": `product-details-action-${intl
            .formatMessage({
              id: name,
            })
            .toLowerCase()
            .split(" ")
            .join("-")}`,
        };
      });
    return (
      <div className={styles.buttonwrapper}>
        {button?.showBtn && (
          <Button
            iconPlacement="right"
            children={intl.formatMessage({ id: button.text })}
            hasItems={true}
            items={actionItems}
            data-test-id="product-details-actions-btn"
          />
        )}
      </div>
    );
  }

  return (
    <div className={styles.buttonwrapper}>
      {button?.showBtn && (
        <Tooltip
          onOpenChange={() => {
            if (button.showTooltip) {
              setVisible(true);
            } else {
              setVisible(false);
            }
          }}
          placement="left"
          text={tooltipTitle}
          actions={tooltipActions}
          visible={visible}
        >
          <Button
            disabled={button.disabled}
            data-test-id={button?.testId}
            onClick={() => {
              history.push({
                pathname: button?.pathname,
                hash: button?.hash,
              });
            }}
          >
            <FormattedMessage id={button?.text} />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default HeaderButton;
