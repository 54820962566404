import { memo } from "react";
import { useState, useEffect } from "react";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  Button,
  Paragraph,
  Tooltip,
  Radio,
  Alert,
} from "@trace-one/design-system";
import { Form } from "antd";
import cn from "classnames";

import { CumdAPI, PmdAPI, RlmdAPI, SmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";
import { Languages } from "translations";

import { useAppDispatch } from "reduxStore";
import { setCategoryItemData } from "reduxStore/categoryItemForm/slice";
import {
  selectClassificationCompanyId,
  selectGenericCategory,
} from "reduxStore/classificationDetails/selectors";
import { selectTeamMemberResponsibilities } from "reduxStore/shared/selectors";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import ContactsInputGroup from "components/ContactsInputGroup";
import FormWrapper from "components/FormWrapper";
import Input from "components/Input";
import LeavingFormPrompt from "components/LeavingFormPrompt";
import { ListUrls } from "shared/constants";
import { ErrorCode, hasErrorOccurred } from "shared/errors";
import useAsyncHandler from "shared/hooks/useAsyncHandler";
import useToast from "shared/hooks/useToast";

import styles from "./CategoryItemForm.module.less";
import CategoryTranslationTable from "./CategoryTranslationTable";
import { CategoryItemSubmitResult, CatageoryItemData } from "./models";
import getFailedSubmitResult from "./utils/getFailedSubmitResult";

interface editModalData {
  isEditModal: boolean;
  selectedKey: Array<string>;
}

interface LinkedItems {
  productsLinked: number;
  sitesLinked: number;
  suppliersLinked: number;
}

interface CategoryItemFormProps {
  handleCategoryValue: any; //can expect input in any language
  classificationId: string;
  handleCategoryDetail: any;
  parentId: Array<string>;
  handleFormChanged: any;
  editModalData: editModalData;
  categoryData: CatageoryItemData;
}

const CategoryItemForm: React.FC<CategoryItemFormProps> = ({
  handleCategoryValue,
  classificationId,
  handleCategoryDetail,
  parentId,
  handleFormChanged,
  editModalData,
  categoryData,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { categoryListsWrite } = usePermissions();
  const toast = useToast();
  const [form] = Form.useForm();
  const teamMemberResponsibilities = useSelector(
    selectTeamMemberResponsibilities
  );
  const userCompanyId = useSelector(selectUserOwningCompanyId);
  const classificationCompanyId = useSelector(selectClassificationCompanyId);
  const isGenericCategory = useSelector(selectGenericCategory);
  const isSameCompanyID: boolean = userCompanyId === classificationCompanyId;
  const isDisabled =
    !categoryListsWrite ||
    isGenericCategory ||
    !isSameCompanyID ||
    !categoryData.isActive;
  const [submitErrors, setSubmitErrors] = useState({
    externalCode: false,
    categoryItemName: false,
  });
  const [submitResult, setSubmitResult] = useState<CategoryItemSubmitResult>(
    {}
  );
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>();
  const [hasExternalCodeError, setHasExternalCodeError] =
    useState<boolean>(false);
  const [saveTooltip, setSaveTooltip] = useState<boolean>(false);
  const [cancelTooltip, setCancelTooltip] = useState<boolean>(false);
  const [linkedItems, setLinkedItems] = useState<LinkedItems>(null);
  const { productsLinked, sitesLinked, suppliersLinked } = linkedItems ?? {};
  const { categoryId, categoryItemId } = categoryData;
  const canArchive = !(productsLinked + sitesLinked + suppliersLinked);
  const archiveAlertMessage = (
    <span>
      {intl.formatMessage({ id: "classification.archive.warning" })}
      {!!productsLinked && (
        <>
          <br />
          <Link
            to={{
              pathname: `/${ListUrls.products}/list`,
              search: `?productListId=${categoryId}&productListItemId=${categoryItemId}`,
            }}
          >
            {intl.formatMessage(
              { id: "classification.productsLinked" },
              { productsCount: productsLinked }
            )}
          </Link>
        </>
      )}
      {!!sitesLinked && (
        <>
          <br />
          <Link
            to={{
              pathname: `/${ListUrls.sites}/list`,
              search: `?productListId=${categoryId}&productListItemId=${categoryItemId}`,
            }}
          >
            {intl.formatMessage(
              { id: "classification.sitesLinked" },
              { sitesCount: sitesLinked }
            )}
          </Link>
        </>
      )}
      {!!suppliersLinked && (
        <>
          <br />
          <Link
            to={{
              pathname: `/${ListUrls.suppliers}/list`,
              search: `?productListId=${categoryId}&productListItemId=${categoryItemId}`,
            }}
          >
            {intl.formatMessage(
              { id: "classification.suppliersLinked" },
              { suppliersCount: suppliersLinked }
            )}
          </Link>
        </>
      )}
    </span>
  );
  const contactNames = categoryData.contacts
    ?.map(contact => contact.userName)
    .join(", ");
  const languageNames = categoryData.translations
    ?.map(data => {
      const language = Object.keys(Languages)
        .find(key => Languages[key] === data.languageItemCode)
        .toLowerCase();
      return language.charAt(0).toUpperCase() + language.slice(1);
    })
    .join(", ");
  const activeOptions = [
    {
      label: intl.formatMessage({ id: "general.yes" }),
      value: true,
      disabled: isDisabled,
      "data-test-id": "archive-radio-yes",
    },
    {
      label: intl.formatMessage({ id: "general.no" }),
      value: false,
      "data-test-id": "archive-radio-no",
      disabled: !canArchive || isDisabled,
    },
  ];

  const formData = form.getFieldsValue();

  useEffect(() => {
    const data = intl.formatMessage({
      id: "category.form.newCategory",
    });
    if (!editModalData.isEditModal) {
      if (inputValue === undefined) {
        const formData = form.getFieldsValue();
        const newFormValue = {
          categoryItemName: data,
          contacts: formData.contacts,
          externalCode: formData.externalCode,
          translations: formData.translations,
          isActive: formData.isActive,
        };
        form.setFieldsValue(newFormValue);
        handleCategoryValue(data);
        handleFormChanged(true);
        dispatch(setCategoryItemData(data));
      }
    }
  }, []);

  useEffect(() => {
    dispatch(setCategoryItemData(categoryData.categoryItemName));
    form.setFieldsValue(categoryData);
  }, [editModalData.selectedKey]);

  useEffect(() => {
    if (!canArchive) form.setFieldValue("isActive", true);
  }, [canArchive]);

  const [handleArchiveClassification, isArchiveClassificationLoading] =
    useAsyncHandler(() =>
      Promise.all([
        PmdAPI.getTradeItemsByFilters(
          {},
          { categoryId, categoryItemId, ownerCompanyId: userCompanyId }
        ).then(({ data }) => data),
        SmdAPI.getRelationsByFilters(
          {},
          { categoryId, categoryItemId, associatedCompanyId: userCompanyId }
        ).then(({ data }) => data),
        CumdAPI.getCompaniesByFilters(
          {},
          {
            relatedCategoryId: categoryId,
            relatedCategoryItemId: categoryItemId,
            relatedOwnerCompanyId: userCompanyId,
            includePrivate: true,
          }
        ).then(({ data }) => data),
      ])
    );

  const onFinish = async (values: any) => {
    if (editModalData.isEditModal) {
      try {
        await RlmdAPI.updateCategoryItem(editModalData.selectedKey[0], {
          ...values,
        });
        toast.saveSuccess();
        handleCategoryDetail(false);
      } catch (error) {
        if (
          hasErrorOccurred({
            error,
            errorCode: ErrorCode.CATEGORY_NAME_ALREADY_EXISTED,
          })
        ) {
          setSubmitErrors(prev => ({ ...prev, categoryItemName: true }));
        } else if (
          hasErrorOccurred({
            error,
            errorCode: ErrorCode.CLASSIFICATION_EXTERNAL_CODE_UPDATE_EXISTED,
          })
        ) {
          setSubmitErrors(prev => ({ ...prev, externalCode: true }));
        }
        // const isError = await getFailedSubmitResult({
        //   error,
        //   setSubmitErrors,
        // });
        else {
          setSubmitResult(prev => ({ ...prev, ...error }));
          toast.saveError({ error });
        }
      }
    } else {
      try {
        await RlmdAPI.createCategoryWithId(
          classificationId,
          parentId.length > 0
            ? {
                ...values,
                parent1CategoryItemId: parentId[0],
              }
            : {
                ...values,
              }
        );
        toast.saveSuccess();
        handleCategoryDetail(false);
      } catch (error) {
        const isError = await getFailedSubmitResult({
          error,
          setSubmitErrors,
        });
        if (!isError) {
          setSubmitResult(prev => ({ ...prev, ...error }));
          toast.saveError({ error });
        }
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    dispatch(setCategoryItemData(e.target.value));
    handleCategoryValue(e.target.value);
  };

  const onConfirmationClick = () => {
    handleCategoryDetail(false);
    handleCategoryValue("");
    handleFormChanged(false);
  };

  const checkArchiveStatus = async () => {
    const [tradeItems, siteItems, supplierItems] =
      await handleArchiveClassification();

    setLinkedItems({
      productsLinked: tradeItems.tradeItems.length,
      sitesLinked: siteItems.sites.length,
      suppliersLinked: supplierItems.companies.length,
    });
  };
  return (
    <div
      className={cn(styles.categoryDetails, {
        [styles.categoryDetailsReadOnly]: !categoryData.isActive,
      })}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinishFailed={({ errorFields }) => {
          const firstName = errorFields[0];
          if (firstName.name[0] === "externalCode") {
            setHasExternalCodeError(true);
          }
        }}
        onFinish={onFinish}
        initialValues={categoryData}
        onValuesChange={changedValues => {
          if ("categoryItemName" in changedValues) {
            setSubmitErrors(prev => ({ ...prev, categoryItemName: false }));
          }
          if ("externalCode" in changedValues) {
            changedValues.externalCode === ""
              ? setHasExternalCodeError(true)
              : setHasExternalCodeError(false);
            setSubmitErrors(prev => ({ ...prev, externalCode: false }));
          }
          if ("isActive" in changedValues && editModalData.isEditModal) {
            !changedValues.isActive
              ? checkArchiveStatus()
              : setLinkedItems(null);
          }

          if (!isFormChanged) {
            setIsFormChanged(true);
            handleFormChanged(true);
          }
        }}
        data-test-id="cg-form-submit"
      >
        <FormWrapper.Section
          title={
            editModalData.isEditModal
              ? intl.formatMessage({
                  id: "category.form.categoryDetails",
                })
              : intl.formatMessage({
                  id: "category.form.newCategory",
                })
          }
        >
          <Form.Item
            name="categoryItemName"
            label={intl.formatMessage({
              id: "general.name",
            })}
            rules={[
              {
                required: categoryData.isActive && true,
                whitespace: true,
                message: "",
              },
            ]}
          >
            {categoryData.isActive ? (
              <Input
                data-test-id="category-item-form-categoryItemName"
                error={
                  formData.categoryItemName === "" ||
                  submitErrors.categoryItemName
                }
                errorDataTestId={
                  submitErrors.categoryItemName
                    ? "classification-form-errorcategoryItemName"
                    : "md-error-name-is-required"
                }
                errorMessage={
                  submitErrors.categoryItemName
                    ? intl.formatMessage({
                        id: "classification.categoryListName.errors.usedByAnotherCategory",
                      })
                    : intl.formatMessage({
                        id: "general.valueIsRequired",
                      })
                }
                maxLength={256}
                value={categoryData.categoryItemName}
                onChange={handleInputChange}
                disabled={isDisabled}
              />
            ) : (
              <Paragraph
                className={styles.classificationDetailsParagraph}
                size="m"
              >
                <Tooltip
                  showFullText
                  text={categoryData.categoryItemName}
                  placement="top"
                >
                  {categoryData.categoryItemName}
                </Tooltip>
              </Paragraph>
            )}
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: "general.internalCode" })}
            name="externalCode"
            rules={[
              {
                required: categoryData.isActive && true,
                whitespace: true,
                message: "",
              },
            ]}
          >
            {categoryData.isActive ? (
              <Input
                data-test-id="category-item-form-externalCode"
                disabled={isDisabled}
                error={hasExternalCodeError || submitErrors.externalCode}
                errorDataTestId={
                  submitErrors.externalCode
                    ? "classification-form-errorexternalCode"
                    : "md-error-externalCode-is-required"
                }
                errorMessage={
                  submitErrors.externalCode
                    ? intl.formatMessage({
                        id: "classification.externalCode.errors.usedByAnotherCategory",
                      })
                    : intl.formatMessage({
                        id: "general.valueIsRequired",
                      })
                }
                maxLength={36}
              />
            ) : (
              <Paragraph
                className={styles.classificationDetailsParagraph}
                size="m"
              >
                <Tooltip
                  showFullText
                  text={categoryData.externalCode}
                  placement="top"
                >
                  {categoryData.externalCode}
                </Tooltip>
              </Paragraph>
            )}
          </Form.Item>
          <Form.Item
            name="contacts"
            label={intl.formatMessage({ id: "general.contacts" })}
          >
            {categoryData.isActive ? (
              <ContactsInputGroup
                withErrors={submitResult.contacts?.withErrors}
                teamMemberResponsibilities={teamMemberResponsibilities}
                disabled={isDisabled}
                withUserStatusMention
              />
            ) : (
              <Paragraph
                className={styles.classificationDetailsParagraph}
                size="m"
              >
                <Tooltip showFullText text={contactNames} placement="top">
                  {contactNames}
                </Tooltip>
              </Paragraph>
            )}
          </Form.Item>
          {!canArchive && (
            <Alert
              message={intl.formatMessage({ id: "general.information" })}
              type="info"
              description={archiveAlertMessage}
              banner={true}
              closable={false}
            />
          )}
          {editModalData.isEditModal && (
            <Form.Item
              name="isActive"
              label={intl.formatMessage({
                id: categoryData.isActive
                  ? "general.active"
                  : "classification.archived.date.label",
              })}
            >
              {categoryData.isActive ? (
                <Radio.Group direction="horizontal" options={activeOptions} />
              ) : (
                <Paragraph
                  className={styles.classificationDetailsParagraph}
                  size="m"
                >
                  <Tooltip
                    showFullText
                    text={categoryData.modifiedAt}
                    placement="top"
                  >
                    {categoryData.modifiedAt}
                  </Tooltip>
                </Paragraph>
              )}
            </Form.Item>
          )}
          <Form.Item
            name="translations"
            label={intl.formatMessage({ id: "category.form.translations" })}
            wrapperCol={{
              span: 24,
            }}
            rules={[
              {
                validator: (
                  rule,
                  value: { languageItemCode?: string; text?: string }[]
                ) => {
                  if (!value.every(({ text }) => !!text)) {
                    return Promise.reject(true);
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            {categoryData.isActive ? (
              <CategoryTranslationTable />
            ) : (
              <Paragraph
                className={styles.classificationDetailsParagraph}
                size="m"
              >
                <Tooltip showFullText text={languageNames} placement="top">
                  {languageNames}
                </Tooltip>
              </Paragraph>
            )}
          </Form.Item>
          {!isDisabled && (
            <Form.Item className={styles.categoryButton}>
              <Tooltip
                onOpenChange={() => setCancelTooltip(!cancelTooltip)}
                placement="top"
                text={intl.formatMessage({
                  id: "general.areYouSure",
                })}
                actions={[
                  {
                    text: intl.formatMessage({ id: "general.no" }),
                    onClick: () => {
                      setCancelTooltip(false);
                    },
                  },
                  {
                    text: intl.formatMessage({ id: "general.yes" }),
                    onClick: () => {
                      setCancelTooltip(false);
                      onConfirmationClick();
                    },
                  },
                ]}
                visible={cancelTooltip}
              >
                <Button
                  type="secondary"
                  data-test-id="category-item-form-cancel"
                >
                  {intl.formatMessage({ id: "general.cancel" })}
                </Button>
              </Tooltip>
              {linkedItems && canArchive ? (
                <Tooltip
                  onOpenChange={() => setSaveTooltip(true)}
                  placement="top"
                  text={intl.formatMessage({
                    id: "classificationItem.archive.confirmation",
                  })}
                  actions={[
                    {
                      text: intl.formatMessage({ id: "general.cancel" }),
                      onClick: () => {
                        setSaveTooltip(false);
                      },
                    },
                    {
                      text: intl.formatMessage({ id: "general.confirm" }),
                      onClick: () => {
                        setSaveTooltip(false);
                        onFinish(formData);
                      },
                    },
                  ]}
                  visible={saveTooltip}
                >
                  <Button data-test-id="save-category-item-tooltip">
                    {intl.formatMessage({ id: "general.save" })}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  disabled={isArchiveClassificationLoading}
                  data-test-id="save-category-item"
                  htmlType="submit"
                >
                  {intl.formatMessage({ id: "general.save" })}
                </Button>
              )}
            </Form.Item>
          )}
          <LeavingFormPrompt when={isFormChanged} />
        </FormWrapper.Section>
      </Form>
    </div>
  );
};

export default memo(CategoryItemForm);
