import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading, UserCard, Typography } from "@trace-one/design-system";
import moment from "moment";

import {
  selectNetContentMeasuresData,
  selectTeamMemberResponsibilities,
  selectBrandsData,
} from "reduxStore/shared/selectors";

import { UserStatus } from "shared/constants";
import { TradeItemStatus } from "shared/constants";

import ManufacturedTable from "../ManufacturedTable";
import { ProductVersionsResult } from "../models";

import DetailSection from "./DetailSection";
import styles from "./VersionsDetails.module.less";

const VersionsDetails = ({
  versionDetails,
  previousVersionDetails,
}: {
  versionDetails: ProductVersionsResult;
  previousVersionDetails?: ProductVersionsResult;
}) => {
  const { formatMessage } = useIntl();
  const { data: netContentMeasures } = useSelector(
    selectNetContentMeasuresData
  );
  const teamReponsabilities = useSelector(selectTeamMemberResponsibilities);
  const { data: brands } = useSelector(selectBrandsData);
  const CategoryLine = category => {
    const categoryLine = [
      category.parent4CategoryItemName &&
        `${category.parent4CategoryItemName} > `,
      category.parent3CategoryItemName &&
        `${category.parent3CategoryItemName} > `,
      category.parent2CategoryItemName &&
        `${category.parent2CategoryItemName} > `,
      category.parent1CategoryItemName &&
        `${category.parent1CategoryItemName} > `,
      category.categoryItemName,
    ].join("");

    return categoryLine;
  };

  const netContentLabel = ({ value, unitOfMeasure }) => {
    const text = netContentMeasures?.find(
      ({ id }) => id === unitOfMeasure
    )?.text;
    return `${value} ${text}`;
  };

  const ProductInformation = [
    {
      title: formatMessage({
        id: "productDetails.productIdentification",
      }),
      fields: [
        {
          label: formatMessage({
            id: "general.name",
          }),
          value: versionDetails.itemName,
          isChanged: previousVersionDetails
            ? previousVersionDetails.itemName !== versionDetails.itemName
            : false,
        },
        {
          label: formatMessage({
            id: "general.brand",
          }),
          value:
            versionDetails.brandName ??
            brands.find(brand => brand.id === versionDetails.brandId)
              ?.brandName,
          isChanged: previousVersionDetails
            ? previousVersionDetails.brandId !== versionDetails.brandId
            : false,
        },
        {
          label: formatMessage({
            id: "productDetails.netContents",
          }),
          value: versionDetails.netContents
            ?.map(({ value, unitOfMeasure }) =>
              netContentLabel({ value, unitOfMeasure })
            )
            .join(", "),
          isChanged: previousVersionDetails
            ? !(
                versionDetails.netContents.length ===
                  previousVersionDetails.netContents.length &&
                versionDetails.netContents.every(
                  (v, i) =>
                    previousVersionDetails.netContents[i].value === v.value &&
                    previousVersionDetails.netContents[i].unitOfMeasure ===
                      v.unitOfMeasure
                )
              )
            : false,
        },
        {
          label: formatMessage({
            id: "productForm.productIdentification.GTIN",
          }),
          value: versionDetails.gtin,
          isChanged: previousVersionDetails
            ? previousVersionDetails.gtin !== versionDetails.gtin
            : false,
        },
        {
          label: formatMessage({
            id: "productDetails.productCategory",
          }),
          value: (() => {
            const isChanged = previousVersionDetails
              ? !(
                  versionDetails.productCategories.length ===
                    previousVersionDetails.productCategories.length &&
                  versionDetails.productCategories.every(
                    (v, i) =>
                      previousVersionDetails.productCategories[i].categoryId ===
                        v.categoryId &&
                      previousVersionDetails.productCategories[i]
                        .categoryItemId === v.categoryItemId
                  )
                )
              : false;
            const categoryLines = versionDetails.productCategories
              ?.map(({ categoryItemId }) =>
                CategoryLine(versionDetails.categoriesData[categoryItemId])
              )
              .sort(function (a, b) {
                return a.localeCompare(b);
              });
            return categoryLines?.map(category => (
              <Typography
                className={isChanged && styles.changedField}
                color="grey-4"
                variant="body-regular"
              >
                {category}
              </Typography>
            ));
          })(),
          isChanged: previousVersionDetails
            ? !(
                versionDetails.productCategories.length ===
                  previousVersionDetails.productCategories.length &&
                versionDetails.productCategories.every(
                  (v, i) =>
                    previousVersionDetails.productCategories[i].categoryId ===
                      v.categoryId &&
                    previousVersionDetails.productCategories[i]
                      .categoryItemId === v.categoryItemId
                )
              )
            : false,
        },
        {
          label: formatMessage({
            id: "productDetails.baseUnit",
          }),
          value: versionDetails.isTradeItemBaseUnit
            ? formatMessage({
                id: "general.yes",
              })
            : formatMessage({
                id: "general.no",
              }),
          isChanged: previousVersionDetails
            ? previousVersionDetails.isTradeItemBaseUnit !==
              versionDetails.isTradeItemBaseUnit
            : false,
        },
        {
          label: formatMessage({
            id: "productDetails.consumerUnit",
          }),
          value: versionDetails.isConsumerUnit
            ? formatMessage({
                id: "general.yes",
              })
            : formatMessage({
                id: "general.no",
              }),
          isChanged: previousVersionDetails
            ? previousVersionDetails.isConsumerUnit !==
              versionDetails.isConsumerUnit
            : false,
        },
        {
          label: formatMessage({
            id: "productDetails.isProductArchived",
          }),
          value:
            versionDetails.tradeItemStatus === TradeItemStatus.INACTIVE
              ? formatMessage({
                  id: "general.yes",
                })
              : formatMessage({
                  id: "general.no",
                }),
          isChanged: previousVersionDetails
            ? previousVersionDetails.tradeItemStatus !==
              versionDetails.tradeItemStatus
            : false,
        },
      ],
    },
    {
      title: formatMessage({
        id: "general.privateInformation",
      }),
      fields: [
        {
          label: formatMessage({
            id: "general.privateAttributes",
          }),
          value: versionDetails.retailerCustomizedAttributes
            ?.map(
              ({ retailerLabel, retailerCode }) =>
                `${retailerLabel}: ${retailerCode}`
            )
            .join(", "),
          isChanged: previousVersionDetails
            ? !(
                versionDetails.retailerCustomizedAttributes.length ===
                  previousVersionDetails.retailerCustomizedAttributes.length &&
                versionDetails.retailerCustomizedAttributes.every(
                  (v, i) =>
                    previousVersionDetails.retailerCustomizedAttributes[i]
                      .retailerCode === v.retailerCode &&
                    previousVersionDetails.retailerCustomizedAttributes[i]
                      .retailerLabel === v.retailerLabel
                )
              )
            : false,
        },
        {
          label: formatMessage({
            id: "general.contacts",
          }),
          value: versionDetails.contacts.map(({ userId, responsibilityId }) => {
            const userData = versionDetails.usersData[userId];
            if (!userData) {
              return null;
            }
            const isUserInactive = userData.userStatus !== UserStatus.ENABLED;
            const userResponsabilty = teamReponsabilities?.find(
              resp => responsibilityId === resp.id
            );
            return (
              <UserCard
                key={userId}
                name={userData.userFirstName + " " + userData.userLastName}
                title={userResponsabilty?.text}
                photoUrl={userData.userPhotoUrl}
                size="xs"
                disabled={isUserInactive}
                inactiveTooltipText={formatMessage({
                  id: "general.userInactive",
                })}
                icon={false}
              />
            );
          }),
          isChanged: previousVersionDetails
            ? !(
                versionDetails.contacts.length ===
                  previousVersionDetails.contacts.length &&
                versionDetails.contacts.every(
                  (v, i) =>
                    previousVersionDetails.contacts[i].userId === v.userId
                )
              )
            : false,
        },
      ],
    },
  ];
  // const SiteUsage = [
  //   ...(!isRetailer
  //     ? [
  //         {
  //           fields: [
  //             {
  //               value: versionDetails.visibleByCompanyIds
  //                 ?.map(s => relatedCompanies.find(c => c.value === s).name)
  //                 .join(", "),
  //               isChanged: previousVersionDetails
  //                 ? !(
  //                     versionDetails.visibleByCompanyIds.length ===
  //                       previousVersionDetails.visibleByCompanyIds.length &&
  //                     versionDetails.visibleByCompanyIds.every(
  //                       (v, i) =>
  //                         previousVersionDetails.visibleByCompanyIds[i] === v
  //                     )
  //                   )
  //                 : false,
  //             },
  //           ],
  //         },
  //       ]
  //     : []),
  // ];
  const AdditionalInformation = [
    {
      title: formatMessage({
        id: "productForm.characteristics.title",
      }),
      fields: [
        {
          label: formatMessage({
            id: "productForm.characteristics.manufacturingPeriod",
          }),
          value: versionDetails.manufacturingPeriod,
          isChanged: previousVersionDetails
            ? previousVersionDetails.manufacturingPeriod !==
              versionDetails.manufacturingPeriod
            : false,
        },
        {
          label: formatMessage({
            id: "productDetails.variableUnit",
          }),
          value: versionDetails.isTradeItemVariableUnit
            ? formatMessage({
                id: "general.yes",
              })
            : formatMessage({
                id: "general.no",
              }),
          isChanged: previousVersionDetails
            ? previousVersionDetails.isTradeItemVariableUnit !==
              versionDetails.isTradeItemVariableUnit
            : false,
        },
        {
          label: formatMessage({
            id: "productDetails.ean7Weight",
          }),
          value: versionDetails.ean7Weight,
          isChanged: previousVersionDetails
            ? previousVersionDetails.ean7Weight !== versionDetails.ean7Weight
            : false,
        },
        {
          label: formatMessage({
            id: "productDetails.ean7Price",
          }),
          value: versionDetails.ean7Price,
          isChanged: previousVersionDetails
            ? previousVersionDetails.ean7Price !== versionDetails.ean7Price
            : false,
        },
        {
          label: formatMessage({
            id: "productDetails.seasonalUnit",
          }),
          value: versionDetails.isTradeItemSeasonal
            ? formatMessage({
                id: "general.yes",
              })
            : formatMessage({
                id: "general.no",
              }),
          isChanged: previousVersionDetails
            ? previousVersionDetails.isTradeItemSeasonal !==
              versionDetails.isTradeItemSeasonal
            : false,
        },
        {
          label: formatMessage({
            id: "productDetials.seasonalDate.startDate",
          }),
          value: versionDetails.seasonalAvailabilityStartDate
            ? moment(versionDetails.seasonalAvailabilityStartDate).format(
                "MM/DD/yyyy"
              )
            : "",
          isChanged: previousVersionDetails
            ? previousVersionDetails.seasonalAvailabilityStartDate !==
              versionDetails.seasonalAvailabilityStartDate
            : false,
        },
        {
          label: formatMessage({
            id: "productDetials.seasonalDate.endDate",
          }),
          value: versionDetails.seasonalAvailabilityEndDate
            ? moment(versionDetails.seasonalAvailabilityEndDate).format(
                "MM/DD/yyyy"
              )
            : "",
          isChanged: previousVersionDetails
            ? previousVersionDetails.seasonalAvailabilityEndDate !==
              versionDetails.seasonalAvailabilityEndDate
            : false,
        },
      ],
    },
    {
      title: formatMessage({
        id: "productForm.packaging.title",
      }),
      fields: [
        {
          label: formatMessage({
            id: "productForm.packaging.packagingFormat",
          }),
          value: versionDetails.packagingFormat,
          isChanged: previousVersionDetails
            ? previousVersionDetails.packagingFormat !==
              versionDetails.packagingFormat
            : false,
        },
        {
          label: formatMessage({
            id: "productForm.packaging.packagingNetContentDescription",
          }),
          value: versionDetails.packagingNetContentDescription,
          isChanged: previousVersionDetails
            ? previousVersionDetails.packagingNetContentDescription !==
              versionDetails.packagingNetContentDescription
            : false,
        },
      ],
    },
  ];

  const CommentsInformation = [
    {
      fields: versionDetails.commentIds.map(id => {
        return {
          value: (() => {
            const isChanged = previousVersionDetails
              ? !previousVersionDetails.commentIds.includes(id)
              : false;
            return versionDetails.comments[id] ? (
              <div className={isChanged && styles.changedField}>
                <Typography component="p">
                  {versionDetails.comments[id]?.authorFullname +
                    " on " +
                    moment(versionDetails.comments[id]?.commentDate).format(
                      "LL"
                    )}
                </Typography>
                <Typography className={styles.commentValue} component="p">
                  {versionDetails.comments[id]?.value}
                </Typography>
              </div>
            ) : (
              ""
            );
          })(),
          isChanged: previousVersionDetails
            ? !previousVersionDetails.commentIds.includes(id)
            : false,
          testId: versionDetails.comments[id]?.id,
        };
      }),
    },
  ];

  return (
    <div className={styles.detailsContailer}>
      <Heading color="grey-5" className={styles.versionsTitle} size="m">
        {versionDetails.version ? "V" + versionDetails.version + " - " : ""}
        {versionDetails.itemName}
      </Heading>
      <Typography color="grey-5" variant="body-regular">
        {"(" +
          formatMessage({
            id: "general.modifiedAt",
          }) +
          " " +
          moment(versionDetails.modifiedAt).format("L") +
          ","}{" "}
        {moment(versionDetails.modifiedAt).format("LT") + ")"}
      </Typography>
      <DetailSection
        heading={formatMessage({
          id: "productDetails.productInformation",
        })}
        fieldItems={ProductInformation}
      />
      <DetailSection
        heading={formatMessage({
          id: "general.additionalInformation",
        })}
        fieldItems={AdditionalInformation}
      />
      {!!versionDetails.commentIds.length && (
        <DetailSection
          heading={formatMessage({
            id: "general.comments",
          })}
          fieldItems={CommentsInformation}
        />
      )}
      <ManufacturedTable
        versionDetails={versionDetails}
        previousVersionDetails={previousVersionDetails}
      />
    </div>
  );
};

export default VersionsDetails;
