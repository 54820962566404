export enum UserResponsibilityId {
  PRODUCT_MANAGER = "697b1df9-fc70-451e-b85c-83905adb1a31",
  QUALITY_MANAGER = "91bf8999-dc12-44af-8ea0-4f13679e5579",
}

export enum TradeItemStatus {
  ACTIVE = "34a95104-3a04-4078-94d5-30068b8c583a",
  INACTIVE = "cf5ac506-fdc8-4650-8a9d-a670b458bb8f",
  DRAFT = "e89a4ac6-d823-4605-819a-c2a76e185714",
}

export enum ManufacturingItemStatus {
  ACTIVE = "7dbe3b47-b5c6-4304-bac9-3895efa5a8f9",
  INACTIVE = "86fc13bc-cac5-4867-8c08-5df3b3ef41ae",
}

export enum SiteCode {
  PACKER_TYPE = "afd4f468-2620-4356-b09d-440ec3e6f601",
  HEALTHMAKER_TYPE = "a923234e-4695-4bc2-8de5-3f9c353f5094",
  SITE_TYPE = "8b83df2d-bfd1-4a6f-a2d0-3a671383e710",
}

export enum CategoryTypeInput {
  PRODUCT = 0,
  RAW_MATERIAL = 1,
}

export enum CategoryType {
  PRODUCT = "Product",
}

export enum CompanyActivityId {
  SUPPLIER = 2,
  PAKER = 9,
}

export enum CompanyType {
  REAL = "Real",
  PRIVATE = "Private",
}

export enum TeamMemberResponsibilityParentItem {
  RETAILER = "1243372f-3ab8-404e-9684-590d90dead90",
  SUPPLIER = "60dc67f0-7b2c-4b44-b2c2-291fe2cabd0b",
}

export enum CompanyStatus {
  ENABLED = "Enabled",
  DISABLED = "Disabled",
  SUSPENDED = "Suspended",
}

export enum UserStatus {
  ENABLED = "Enabled",
  DISABLED = "Disabled",
  SUSPENDED = "Suspended",
}

export enum ApplicationTypes {
  QUALITY_PORTAL = 0,
  PACK_PORTAL = 1,
  PDM = 8,
  PDMFIND = 23,
  PDMNA = 22,
  BUSINESS_INTELLIGENCE = 9,
  INSIGHT = 21,
  TON = 40,
  PROJECT = 50,
  QMS = 51,
  SPEC = 70,
  RFX = 80,
  MASTER_DATA = 100,
  NEW_INSIGHT = 110,
  ADMINISTRATION = 90,
}

export enum ListUrls {
  products = "products",
  sites = "sites",
  suppliers = "suppliers",
}

export enum fileLinksTypeId {
  site = "17591ca1-538e-480c-ba89-b8f1672d588e",
  project = "01b75634-545c-4612-928c-80fd90d0b92e",
}

export const GOOGLEMAP_QUERY_URL = "https://www.google.com/maps?q=";
export const HISTORY_INITIAL_LOAD = "POP";
export const HISTORY_REPLACE_LOAD = "REPLACE";
export const dropdownFilterCount = 100;
export const disabledFileFormates = [
  "txt",
  "csv",
  "bmp",
  "jpeg",
  "tif",
  "tiff",
];
export const TOLPDFTRON =
  "Trace One SAS(traceone.com/en):OEM:See Agreement::B+:AMS(20221230):4FB53D120477580A7360B13AC9A2737860614F8B5C700A1BBD12C401ED2CB9A6328431F5C7";
export const pdmDetails = [
  {
    id: ApplicationTypes.PDM,
    url: window.env["UrlPlm"],
    iconName: "app-logo-pdm-full",
    name: "PDM",
  },
  {
    id: ApplicationTypes.PDMFIND,
    url: window.env["UrlPlmFind"],
    iconName: "app-logo-pdm_ms-full",
    name: "M&S FIND",
  },
  {
    id: ApplicationTypes.PDMNA,
    url: window.env["UrlPlmNa"],
    iconName: "app-logo-pdm_na-full",
    name: "PLM NA",
  },
];
