import React, { useEffect, memo } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { CompanySettings } from "@trace-one/api-clients.pmd/dist/models/company-settings";
import { Button, Heading } from "@trace-one/design-system";
import { Form } from "antd";

import FormWrapper from "components/FormWrapper";
import { ManufacturingItemStatus, TradeItemStatus } from "shared/constants";
import { FormInstanceType } from "shared/hooks/useAntForm";

import { SectionsIds } from "../../constants";
import { ProductFormValues, ManufacturedItemFormValues } from "../../models";

import ManufacturedCard from "./ManufacturedCard";
import styles from "./ManufacturedSection.module.less";

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

interface ManufacturedSectionProps {
  disabled: boolean;
  form: FormInstanceType<ProductFormValues>;
  initialManufacturedItems: ManufacturedItemFormValues[];
  isEditForm: boolean;
  companySettings?: CompanySettings;
}

const findSupplierCompanyIdsDuplicates = (
  manufacturedItems: ManufacturedItemFormValues[]
) => {
  const usedAndSavedSupplierCompanyIds = {};

  const newManufacturedItems: ManufacturedItemFormValues[] = [];
  manufacturedItems?.forEach(item => {
    const supplierCompanyIdStr = item.supplierCompanyId?.value;
    if (item.id) {
      if (supplierCompanyIdStr) {
        usedAndSavedSupplierCompanyIds[supplierCompanyIdStr] = true;
      }
    } else {
      newManufacturedItems.push(item);
    }
  });

  const newSelectedSupplierCompanyIds: {
    [supplierCompanyId: string]: boolean;
  } = {};

  const duplicates: string[] = [];
  newManufacturedItems?.forEach(({ id, supplierCompanyId }) => {
    const supplierCompanyIdStr = supplierCompanyId?.value;
    if (
      usedAndSavedSupplierCompanyIds[supplierCompanyIdStr] ||
      newSelectedSupplierCompanyIds[supplierCompanyIdStr]
    ) {
      duplicates.push(supplierCompanyIdStr);
    }

    if (supplierCompanyIdStr) {
      newSelectedSupplierCompanyIds[supplierCompanyIdStr] = true;
    }
  });

  return duplicates;
};

export const validator = async (
  _,
  manufacturedItems: ManufacturedItemFormValues[]
) => {
  const duplicates = findSupplierCompanyIdsDuplicates(manufacturedItems);
  if (duplicates.length > 0) {
    return Promise.reject(duplicates);
  }
};

const ManufacturedSection: React.FC<ManufacturedSectionProps> = ({
  disabled,
  form,
  initialManufacturedItems,
  isEditForm,
  companySettings,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const urlHash = history.location.hash?.replace("#", "");
  useEffect(() => {
    const fields = form.getFieldsValue();
    if (
      !!fields.manufacturedItems &&
      urlHash === "manufactured-items-section" &&
      !isEditForm
    ) {
      let editedData = [];
      if (
        fields.manufacturedItems.length > 0 &&
        fields.manufacturedItems[0].id !== undefined
      ) {
        let data = fields.manufacturedItems;
        editedData = [
          { manufacturedItemStatusId: ManufacturingItemStatus.ACTIVE },
          ...data,
        ];
        form.setFieldsValue({ manufacturedItems: editedData });
      }
      if (fields.manufacturedItems.length === 0) {
        editedData = [
          { manufacturedItemStatusId: ManufacturingItemStatus.ACTIVE },
        ];
        form.setFieldsValue({ manufacturedItems: editedData });
      }
    }
  }, []);

  return (
    <FormWrapper.Section
      id={SectionsIds.MANUFACTURED_ITEMS}
      title={
        <Heading size="m">
          {intl.formatMessage({
            id: "productForm.manufactured.title",
          })}{" "}
        </Heading>
      }
    >
      <Form.Item
        shouldUpdate={(prev, current) =>
          prev.manufacturedItems !== current.manufacturedItems ||
          prev.tradeItemStatusId !== current.tradeItemStatusId
        }
        {...layout}
      >
        {() => {
          const duplicatedSupplierCompanyId = findSupplierCompanyIdsDuplicates(
            form.getFieldValue<ProductFormValues["manufacturedItems"]>(
              "manufacturedItems"
            )
          );
          const isTradeItemInactive =
            form.getFieldValue<ProductFormValues["tradeItemStatusId"]>(
              "tradeItemStatusId"
            ) === TradeItemStatus.INACTIVE;
          return (
            <Form.List
              name="manufacturedItems"
              rules={[
                {
                  validator,
                },
              ]}
            >
              {(manufacturedItems, { add, remove }) => (
                <div>
                  {manufacturedItems.map((manufacturedField, index) => (
                    <ManufacturedCard
                      key={manufacturedField.key}
                      manufacturedField={manufacturedField}
                      onManufacturedRemove={() => {
                        remove(manufacturedField.name);
                      }}
                      disabled={disabled}
                      form={form}
                      duplicatedSupplierCompanyId={duplicatedSupplierCompanyId}
                      isTradeItemInactive={isTradeItemInactive}
                      initialManufacturedItems={initialManufacturedItems}
                      companySettings={companySettings}
                    />
                  ))}
                  <Button
                    onClick={() => {
                      add({
                        manufacturedItemStatusId:
                          ManufacturingItemStatus.ACTIVE,
                      });
                    }}
                    disabled={disabled || isTradeItemInactive}
                    type="secondary"
                    className={styles.addASupplier}
                    data-test-id="md-product-add-supplier"
                  >
                    {intl.formatMessage({
                      id: "productForm.manufactured.addASupplier",
                    })}
                  </Button>
                </div>
              )}
            </Form.List>
          );
        }}
      </Form.Item>
    </FormWrapper.Section>
  );
};

export default memo(ManufacturedSection);
