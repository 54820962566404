import { useIntl } from "react-intl";

import { Typography } from "antd";

export interface ErrorFallbackProps {
  hasError?: boolean;
  errorMessage?: React.ReactNode;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  children,
  hasError,
  errorMessage,
}) => {
  const intl = useIntl();

  const renderError = () => {
    return errorMessage ? (
      <>{errorMessage}</>
    ) : (
      <Typography.Text type="danger">
        {intl.formatMessage({
          id: "toast.alert.unidentifiedErrorDescription",
        })}
      </Typography.Text>
    );
  };

  if (children) {
    if (hasError) {
      return renderError();
    }
    return <>{children}</>;
  }

  return renderError();
};

export default ErrorFallback;
