import { memo, useState, useMemo, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Filter } from "@trace-one/business-components";
import { TagV1 as Tag, Typography, Picker } from "@trace-one/design-system";
import { Checkbox } from "antd";

import { CumdAPI } from "apis";

import { selectIsRetailer } from "reduxStore/oidc/selectors";
import { selectProductDetailsData } from "reduxStore/productDetails/selectors";
import {
  selectBrandsData,
  selectTeamMemberResponsibilities,
  selectTradeItemStatusesData,
  selectUserOptions,
} from "reduxStore/shared/selectors";
import {
  selectSupplierCompaniesOptions,
  selectAllSupplierCompaniesOptions,
} from "reduxStore/siteList/selectors";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import { getSupplierName } from "components/AsyncCompanySelect";
import AsyncPickerDropdown from "components/AsyncPickerDropdown";
import CategoryTreeSelect from "components/CategoryTreeSelect";
import CheckableTags from "components/CheckableTags";
import FilterMultiSelection from "components/FilterMultiSelection";
import { SearchFiltersProps } from "components/SearchFilters";
import ContactsInputGroup from "components/SearchFilters/components/ContactInputGroupFilter";
import { CompanyActivityId } from "shared/constants";
import { TradeItemStatus } from "shared/constants";
import buildCompanyTitle from "shared/utils/buildCompanyTitle";
import { adjustIntercomButton } from "shared/utils/toggleIntercom";

import { ProductFilter } from "../models";

import styles from "./ProductSearch.module.less";

const TRADE_ITEM_STATUS_ORDER = {
  [TradeItemStatus.DRAFT]: 1,
  [TradeItemStatus.ACTIVE]: 2,
  [TradeItemStatus.INACTIVE]: 3,
};

interface ProductSearchProps {
  filterObj: ProductFilter;
  mergeFilters: (
    value:
      | Partial<ProductFilter>
      | ((prevState: ProductFilter) => ProductFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: SearchFiltersProps["onSearch"];
  onClearFiltersClick: SearchFiltersProps["onClearFiltersClick"];
}

const ProductSearch: React.FC<ProductSearchProps> = ({
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
}) => {
  const intl = useIntl();

  const { data: brands } = useSelector(selectBrandsData);
  const usersOptions = useSelector(selectUserOptions);
  const teamMemberResponsibilitiesOption = useSelector(
    selectTeamMemberResponsibilities
  );
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const supplierCompanyOptions = useSelector(selectSupplierCompaniesOptions);
  const allSupplierCompanyOptions = useSelector(
    selectAllSupplierCompaniesOptions
  );
  const { productBrandId } = useSelector(selectProductDetailsData);

  const isRetailer = useSelector(selectIsRetailer);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const showIntercom = useRef(true);
  const companies = isRetailer ? supplierCompanyOptions : [];
  const users = usersOptions
    .map(({ userFirstName, userLastName, userId, userStatus }) => ({
      name: `${userFirstName} ${userLastName}`,
      value: userId,
      userStatus,
    }))
    .filter(item => item.name !== " ");

  const { data: _allTradeItemStatuses } = useSelector(
    selectTradeItemStatusesData
  );

  const allTradeItemStatuses = [..._allTradeItemStatuses].sort(
    (a, b) =>
      (TRADE_ITEM_STATUS_ORDER[a.id] ?? 0) -
      (TRADE_ITEM_STATUS_ORDER[b.id] ?? 0)
  );

  useEffect(() => {
    const {
      associatedCompanyIds,
      brandIds,
      brandLabels,
      associatedCompanyLabels,
      productCategory,
      tradeItemStatuses,
      HasNoBrand,
      HasNoProductCategory,
      checked,
      userId,
    } = filterObj;
    const defaultSelectedFilters = [
      ...(associatedCompanyIds
        ? [
            {
              filterId: "1",
              values: associatedCompanyLabels
                ? associatedCompanyLabels
                : companies
                    ?.filter(company =>
                      associatedCompanyIds.includes(company.companyId)
                    )
                    .map(company => buildCompanyTitle(company)),
            },
          ]
        : []),
      ...(brandIds
        ? [
            {
              filterId: "2",
              values: brandLabels
                ? brandLabels
                : brands
                    ?.filter(brand => brandIds.includes(brand.id))
                    .map(brand => brand.brandName),
            },
          ]
        : []),
      ...(productCategory
        ? [
            {
              filterId: "3",
              values: [productCategory],
            },
          ]
        : []),
      ...(tradeItemStatuses
        ? [
            {
              filterId: "4",
              values: tradeItemStatuses,
            },
          ]
        : []),
      ...(userId
        ? [
            {
              filterId: "5",
              values: [userId],
            },
          ]
        : []),
      ...(HasNoBrand || HasNoProductCategory || checked
        ? [
            {
              filterId: "6",
              values: [true],
            },
          ]
        : []),
    ];
    setSelectedFilters(defaultSelectedFilters);
  }, [filterObj]);

  const getTagName = (brandname, isActive) => {
    const tagName = !isActive && (
      <Tag
        size="small"
        label={intl.formatMessage({ id: "general.archived" })}
        color="grey"
        mode="light"
      />
    );
    return (
      <div className={styles.brandTag}>
        <div className={styles.brandNameTitle}>
          <span className={!isActive && styles.brandName}> {brandname} </span>
        </div>
        {tagName}
      </div>
    );
  };
  const companyOptions = useMemo(() => {
    return companies.map(company => ({
      label: getSupplierName(
        buildCompanyTitle(company),
        company.isCompanyRelationStatusEnabled,
        company
      ),
      value: company.companyId,
      searchLabel: buildCompanyTitle(company),
    }));
  }, [companies]);

  const brandOptions = useMemo(() => {
    return brands
      .filter(item => item.isActive)
      .map(({ id, brandName, isActive }) => ({
        label: getTagName(brandName, isActive),
        value: id,
        searchLabel: brandName,
      }));
  }, [brands]);

  const filters = [
    {
      title: intl.formatMessage({ id: "general.supplier" }),
      controlType: "multi_selection",
      clearSelection: () => {
        mergeFilters({
          associatedCompanyIds: null,
          associatedCompanyLabels: null,
        });
      },
      elementToRender: (
        <FilterMultiSelection
          key="supplier-selection"
          dataTestId="md-productfilter-supplier-selection"
          options={companyOptions}
          values={filterObj.associatedCompanyIds ?? []}
          onAsyncSearch={({ searchValue }) => {
            return CumdAPI.getCompaniesByFilters(
              {},
              {
                searchName: searchValue,
                relatedOwnerCompanyId: ownerCompanyId,
                companyActivityId: CompanyActivityId.SUPPLIER,
                includePrivate: true,
              }
            ).then(({ data }) => {
              return data.companies.map(company => ({
                label: getSupplierName(
                  buildCompanyTitle(company),
                  company.isCompanyRelationStatusEnabled,
                  company
                ),
                value: company.companyId,
                searchLabel: buildCompanyTitle(company),
              }));
            });
          }}
          onScrollSearch={take => {
            return CumdAPI.getCompaniesByFilters(
              {},
              {
                skip: 0,
                take: take,
                relatedOwnerCompanyId: ownerCompanyId,
                companyActivityId: CompanyActivityId.SUPPLIER,
                includePrivate: true,
                isCompanyRelationStatusEnabled: true,
              }
            ).then(({ data }) => {
              return data.companies.map(company => ({
                label: getSupplierName(
                  buildCompanyTitle(company),
                  company.isCompanyRelationStatusEnabled,
                  company
                ),
                value: company.companyId,
                searchLabel: buildCompanyTitle(company),
              }));
            });
          }}
          getValuesOnly
          onChange={(selectedValues: string[]) => {
            const associatedCompanyLabels = selectedValues.length
              ? allSupplierCompanyOptions
                  .filter(company => selectedValues.includes(company.companyId))
                  .map(company => buildCompanyTitle(company))
              : undefined;
            mergeFilters({
              associatedCompanyIds: selectedValues.length
                ? selectedValues
                : undefined,
              associatedCompanyLabels: associatedCompanyLabels,
            });
          }}
        />
      ),
      quickFilter: (
        <AsyncPickerDropdown
          data-test-id="picker-filter-supplier"
          searchPlaceholderText={intl.formatMessage({ id: "general.search" })}
          clearBtnText={intl.formatMessage({ id: "general.clear" })}
          closeBtnText={intl.formatMessage({ id: "general.close" })}
          title={intl.formatMessage({ id: "general.supplier" })}
          showBadgeCount
          defaultOptions={companyOptions}
          value={filterObj.associatedCompanyIds ?? []}
          selectionType="multi"
          onSearch={undefined}
          showCheckBoxes
          minLengthToSearch={3}
          onAsyncSearch={searchValue => {
            return CumdAPI.getCompaniesByFilters(
              {},
              {
                searchName: searchValue,
                relatedOwnerCompanyId: isRetailer ? ownerCompanyId : undefined,
                relatedAssociatedCompanyId: isRetailer
                  ? undefined
                  : ownerCompanyId,
                companyActivityId: CompanyActivityId.SUPPLIER,
                includePrivate: true,
              }
            ).then(({ data }) => {
              return data.companies.map(company => ({
                label: getSupplierName(
                  buildCompanyTitle(company),
                  company.isCompanyRelationStatusEnabled,
                  company
                ),
                value: company.companyId,
                searchLabel: buildCompanyTitle(company),
              }));
            });
          }}
          searchOnChange
          showSearchInput
          onChange={({ selectedItem, value }) => {
            let associatedCompanyLabels = [];
            if (
              filterObj.associatedCompanyLabels?.includes(
                selectedItem.searchLabel
              )
            ) {
              associatedCompanyLabels =
                filterObj.associatedCompanyLabels.filter(
                  x => x !== selectedItem.searchLabel
                );
            } else {
              associatedCompanyLabels = [
                ...(filterObj.associatedCompanyLabels || []),
                selectedItem.searchLabel,
              ];
            }
            mergeFilters({
              associatedCompanyIds: value,
              associatedCompanyLabels: associatedCompanyLabels,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              associatedCompanyIds: null,
              associatedCompanyLabels: null,
            })
          }
        ></AsyncPickerDropdown>
      ),
      filterId: "1",
    },
    ...(!filterObj.HasNoBrand
      ? [
          {
            title: intl.formatMessage({
              id: "general.brand",
            }),
            controlType: "multi_selection",
            clearSelection: () => {
              mergeFilters({
                brandIds: undefined,
                brandLabels: undefined,
              });
            },
            elementToRender: (
              <FilterMultiSelection
                dataTestId="md-productfilter-brand-selection"
                key="brand-selection"
                options={brandOptions}
                onSearch={str => {
                  const filteredBrands = brands.filter(option => {
                    return option.brandName
                      .toLowerCase()
                      .includes(str.toLowerCase());
                  });
                  return filteredBrands.map(({ id, brandName, isActive }) => ({
                    label: getTagName(brandName, isActive),
                    value: id,
                    searchLabel: brandName,
                  }));
                }}
                values={filterObj.brandIds ?? []}
                getValuesOnly
                onChange={(selectedValues: string[]) => {
                  const brandLabels = selectedValues.length
                    ? brands
                        .filter(brand => selectedValues.includes(brand.id))
                        .map(brand => brand.brandName)
                    : undefined;
                  mergeFilters({
                    brandIds: selectedValues.length
                      ? selectedValues
                      : undefined,
                    brandLabels: brandLabels,
                  });
                }}
              />
            ),
            quickFilter: (
              <AsyncPickerDropdown
                data-test-id="picker-filter-brand"
                searchPlaceholderText={intl.formatMessage({
                  id: "general.search",
                })}
                clearBtnText={intl.formatMessage({ id: "general.clear" })}
                closeBtnText={intl.formatMessage({ id: "general.close" })}
                title={intl.formatMessage({
                  id: "general.brand",
                })}
                showBadgeCount
                showCheckBoxes
                showSearchInput
                defaultOptions={brandOptions}
                value={filterObj.brandIds ?? []}
                searchOnChange
                onAsyncSearch={undefined}
                minLengthToSearch={1}
                onSearch={str => {
                  const filteredBrands = brands.filter(option => {
                    return option.brandName
                      .toLowerCase()
                      .includes(str.toLowerCase());
                  });
                  return filteredBrands.map(({ id, brandName, isActive }) => ({
                    label: getTagName(brandName, isActive),
                    value: id,
                    searchLabel: brandName,
                  }));
                }}
                selectionType="multi"
                onChange={({ selectedItem, value }) => {
                  let brandLabels = [];
                  if (
                    filterObj.brandLabels?.includes(selectedItem.searchLabel)
                  ) {
                    brandLabels = filterObj.brandLabels.filter(
                      x => x !== selectedItem.searchLabel
                    );
                  } else {
                    brandLabels = [
                      ...(filterObj.brandLabels || []),
                      selectedItem.searchLabel,
                    ];
                  }
                  mergeFilters({
                    brandIds: value,
                    brandLabels: brandLabels,
                  });
                }}
                clearSelection={() =>
                  mergeFilters({
                    brandIds: undefined,
                    brandLabels: undefined,
                  })
                }
              ></AsyncPickerDropdown>
            ),
            filterId: "2",
          },
        ]
      : []),
    {
      title: intl.formatMessage({
        id: "general.productCategory",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          productCategory: undefined,
        });
      },
      elementToRender: (
        <CategoryTreeSelect
          showSearch
          multiple={false}
          disabled={filterObj.HasNoProductCategory}
          onChange={productCategory => {
            mergeFilters({ productCategory });
          }}
          value={!productBrandId && filterObj?.productCategory}
          onFullChange={value => {}}
          getPopupContainer={trigger => trigger.parentElement}
          categorySelection={true}
          data-test-id="md-productCategory"
        />
      ),
      filterId: "3",
    },
    {
      title: intl.formatMessage({
        id: "general.status",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          tradeItemStatuses: [],
        });
      },
      elementToRender: (
        <CheckableTags
          value={filterObj.tradeItemStatuses}
          onChange={tradeItemStatuses =>
            mergeFilters({
              tradeItemStatuses,
            })
          }
          options={allTradeItemStatuses.map(({ id }) => {
            if (id === TradeItemStatus.INACTIVE) {
              return {
                value: id,
                label: intl.formatMessage({ id: "general.archived" }),
              };
            }
            return {
              value: id,
              label: intl.formatMessage({ id: "general.notArchived" }),
            };
          })}
        />
      ),
      quickFilter: (
        <Picker.Filter
          data-test-id="picker-filter-status"
          title={intl.formatMessage({
            id: "general.status",
          })}
          showBadgeCount
          items={allTradeItemStatuses.map(({ id }) => {
            if (id === TradeItemStatus.INACTIVE) {
              return {
                value: id,
                label: intl.formatMessage({ id: "general.archived" }),
              };
            }
            return {
              value: id,
              label: intl.formatMessage({ id: "general.notArchived" }),
            };
          })}
          value={filterObj.tradeItemStatuses ?? []}
          selectionType="multi"
          clearBtnText={intl.formatMessage({ id: "general.clear" })}
          closeBtnText={intl.formatMessage({ id: "general.close" })}
          onChange={({ value: tradeItemStatuses }) => {
            mergeFilters({
              tradeItemStatuses,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              tradeItemStatuses: [],
            })
          }
        ></Picker.Filter>
      ),
      filterId: "4",
    },
    {
      title: intl.formatMessage({
        id: "productDetails.contactNameText",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          userId: undefined,
          responsibilityId: undefined,
          userName: undefined,
        });
      },
      elementToRender: (
        <div style={{ maxWidth: "100%" }}>
          <ContactsInputGroup
            filterObj={filterObj}
            mergeFilters={mergeFilters}
            teamMemberUsers={users}
            disabled={filterObj.checked}
            withUserStatusMention
            teamMemberResponsibilitiesOption={teamMemberResponsibilitiesOption}
          />
        </div>
      ),
      filterId: "5",
    },
    {
      title: intl.formatMessage({
        id: "productDetails.productTitle",
      }),
      controlType: "single_selection",
      elementToRender: (
        <div className={styles.productFilterCheckboxesSections}>
          <Checkbox
            checked={filterObj.HasNoBrand}
            onChange={e => {
              mergeFilters({
                HasNoBrand: e.target.checked,
                brandIds: undefined,
                brandLabels: undefined,
              });
            }}
            data-test-id="md-nobrand-input"
          >
            <Typography component="span">
              {intl.formatMessage({
                id: "productsListPage.filterComponent.withNoBrand",
              })}
            </Typography>
          </Checkbox>
          <Checkbox
            checked={filterObj.HasNoProductCategory}
            onChange={e => {
              mergeFilters({
                HasNoProductCategory: e.target.checked,
                productCategory: undefined,
              });
            }}
            data-test-id="md-nocategory-input"
          >
            <Typography component="span">
              {intl.formatMessage({
                id: "productsListPage.filterComponent.withNoProduct",
              })}
            </Typography>
          </Checkbox>
          <Checkbox
            data-test-id="md-with-no-contact"
            checked={filterObj.checked}
            onChange={e => {
              mergeFilters({
                checked: e.target.checked,
                userId: undefined,
                responsibilityId: undefined,
                userName: undefined,
              });
            }}
          >
            <Typography component="span">
              {intl.formatMessage({
                id: "productDetails.withNoContactText",
              })}
            </Typography>
          </Checkbox>
        </div>
      ),
      filterId: "6",
    },
  ];

  return (
    <div className={styles.SearchFilter}>
      <Filter
        filters={filters}
        selectedFilters={selectedFilters}
        clearAllFilters={() => {
          onClearFiltersClick();
        }}
        search={{
          defaultValue: initialSearchValue,
          placeholder: intl.formatMessage({
            id: "productsListPage.searchPlaceholder",
          }),
          onSearch: query => {
            onSearch(query);
          },
        }}
        onAllFilterPanelToggle={() => {
          showIntercom.current = !showIntercom.current;
          adjustIntercomButton(showIntercom.current);
        }}
      />
    </div>
  );
};

export default memo(ProductSearch);
