import { useReactOidc } from "@axa-fr/react-oidc-context";

import {
  SearchFiltersStorage,
  TablePaginationStorage,
  backToAppStorage,
} from "shared/webStorage";

export default function useLogout() {
  const oidc = useReactOidc();

  const cleanupData = () => {
    for (let storage of Object.values(SearchFiltersStorage)) {
      storage.remove();
    }
    for (let storage of Object.values(TablePaginationStorage)) {
      storage.remove();
    }
    backToAppStorage.remove();
  };

  const logout = () => {
    cleanupData();
    oidc.logout();
  };

  return logout;
}
