import { useEffect, useRef, useState, useCallback } from "react";

import { DocumentDetailAPIResponse } from "@trace-one/api-clients.dms";

import { CumdAPI, ProjectAPI, SmdAPI } from "apis";

import { fileLinksTypeId } from "shared/constants";

interface Props {
  links: DocumentDetailAPIResponse["fileHistory"][number]["links"];
}

const useLinkedSites = ({ links }: Props) => {
  const [isLinksDataFetchingCompleted, setIsLinksDataFetchingCompleted] =
    useState(false);

  const siteLinksRef = useRef(null);
  const projectLinksRef = useRef(null);

  const fetchLinksAndCompanies = async (siteLinks: string[]) => {
    if (!siteLinks.length) {
      return [];
    }
    try {
      const {
        data: { sites },
      } = await SmdAPI.getSitesByIdCollection({
        idCollection: siteLinks,
      });

      const { data: companies } = await CumdAPI.getCompaniesByCompanyIds({
        companyIds: sites.map(site => site.ownerCompanyId),
      });

      const comapniesMap = new Map(
        companies.map(c => [
          c.companyId,
          {
            id: c.companyId,
            name: c.companyLegalName,
            displayName: c.companyDisplayName,
          },
        ])
      );

      return sites.map(site => {
        return {
          ...site,
          company: comapniesMap.get(site.ownerCompanyId),
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProjects = async (projectLinks: string[]) => {
    if (!projectLinks.length) {
      return [];
    }
    try {
      const { data } = await ProjectAPI.getProjectsByIdCollection({
        inIds: projectLinks,
      });
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const refetchLinks = useCallback(async () => {
    if (!links.length) {
      return;
    }
    setIsLinksDataFetchingCompleted(false);
    const siteLinks = links
      .filter(x => x.objectTypeId === fileLinksTypeId.site)
      .map(x => x.objectId);
    const projectLinks = links
      .filter(x => x.objectTypeId === fileLinksTypeId.project)
      .map(x => x.objectId);

    const [sites, projects] = await Promise.all([
      fetchLinksAndCompanies(siteLinks),
      fetchProjects(projectLinks),
    ]);

    siteLinksRef.current = sites.reduce((prev, current) => {
      return { ...prev, [current.id]: current };
    }, {});
    projectLinksRef.current = projects;
    setIsLinksDataFetchingCompleted(true);
  }, [links]);

  useEffect(() => {
    refetchLinks();
  }, [refetchLinks]);

  return {
    isLinksDataFetchingCompleted,
    siteLinksRef,
    projectLinksRef,
  };
};

export default useLinkedSites;
