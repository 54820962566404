import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Heading, Container, KPI as Kpi } from "@trace-one/design-system";
import { compose } from "redux";

import { SmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import { selectIsRetailer } from "reduxStore/oidc/selectors";
import { fetchKpiIndicators } from "reduxStore/siteList/asyncActions";
import {
  selectSiteKpiData,
  selectIsKpiDataLoading,
} from "reduxStore/siteList/selectors";
import { clearKpiData } from "reduxStore/siteList/slice";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import BtnCreateListItem from "components/BtnCreateListItem";
import CsvImport from "components/CsvImport";
import Spinner from "components/Spinner";
import { TeamMemberResponsibilityParentItem } from "shared/constants";
import {
  withCountries,
  withTeamMemberResponsibilities,
  withCategoryLists,
} from "shared/hocs";
import withUsers from "shared/hocs/withUsers";
import useToast from "shared/hooks/useToast";
import encodeFileAsBase64 from "shared/utils/encodeFileAsBase64";

import styles from "./SiteList.module.less";
import SiteTable from "./SiteTable";

const enhance = compose(
  withUsers(),
  withCategoryLists(),
  withTeamMemberResponsibilities({
    includeParentItems: true,
    parentItemId: TeamMemberResponsibilityParentItem.RETAILER,
  }),
  withCountries()
);

export const SiteList = ({ isInitiated }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const toast = useToast();
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const kpiData = useSelector(selectSiteKpiData);
  const kpiLoading = useSelector(selectIsKpiDataLoading);
  const isRetailer = useSelector(selectIsRetailer);
  const [selectedKpi, setSelectedKpi] = useState(null);
  const { sitesRead, sitesWrite, sitesImport, siteRelationsImport } =
    usePermissions();

  const handleImportCsv = async ({ file }) => {
    const fileContentBase64Encoded = await encodeFileAsBase64(file);
    isRetailer
      ? await SmdAPI.importSiteRelation({
          fileName: file.name,
          fileContentBase64Encoded,
        })
      : await SmdAPI.importSites({
          fileName: file.name,
          fileContentBase64Encoded,
        });
    toast.info({
      description: intl.formatMessage({
        id: "import.csv.informationSuccess",
      }),
    });
    return true;
  };

  useEffect(() => {
    dispatch(fetchKpiIndicators({ ownerCompanyId, isRetailer }));
    return () => {
      dispatch(clearKpiData());
    };
  }, []);

  if (kpiLoading) {
    return <Spinner underHeader />;
  }

  return (
    <Container isPageWrapper>
      <div className={styles.headActions}>
        <div className={styles.mainContainer}>
          <Heading size="l">
            {intl.formatMessage({ id: "sitesListPage.title" })}
          </Heading>
          <BtnCreateListItem
            text={intl.formatMessage({ id: "sitesListPage.createSite" })}
            onClick={() => history.push("/sites/create")}
            hideButton={isRetailer || !sitesWrite}
            iconName="site-add"
          />
        </div>
        <div>
          {sitesImport && !isRetailer && (
            <CsvImport fileSizeLessThanMB={3} onImport={handleImportCsv} />
          )}
          {siteRelationsImport && isRetailer && (
            <CsvImport fileSizeLessThanMB={3} onImport={handleImportCsv} />
          )}
        </div>
      </div>
      <div className={styles.kpiContainer}>
        <Kpi
          kpiName={intl.formatMessage({ id: "siteForm.noCertificates" })}
          kpiNameGraphic="missing"
          kpiValue={kpiData?.missingCount}
          clickable={true}
          data-test-id="missingCertificatesKpi"
          onClick={() => setSelectedKpi("missingCertificates")}
          selected={selectedKpi === "missingCertificates"}
        />
        <Kpi
          kpiName={intl.formatMessage({
            id: "siteForm.expiringCertificates",
          })}
          kpiNameGraphic="expiring"
          data-test-id="expiringCertificatesKpi"
          kpiValue={kpiData?.expiringCount}
          clickable={true}
          onClick={() => setSelectedKpi("expiringCertificates")}
          selected={selectedKpi === "expiringCertificates"}
        />
        <Kpi
          kpiName={intl.formatMessage({ id: "siteForm.undatedCertificates" })}
          kpiNameGraphic="alert"
          kpiValue={kpiData?.noDateCount}
          clickable={true}
          data-test-id="undatedCertificatesKpi"
          onClick={() => setSelectedKpi("undatedCertificates")}
          selected={selectedKpi === "undatedCertificates"}
        />
      </div>
      <Spinner spinning={!isInitiated} hideChildrenVisibilityOnLoading>
        <SiteTable
          selectedKpi={selectedKpi}
          setSelectedKpi={setSelectedKpi}
          sitesRead={sitesRead}
        />
      </Spinner>
    </Container>
  );
};

export default enhance(SiteList);
