import { Switch, Redirect } from "react-router-dom";

import usePermissions from "core/oidc/usePermissions";

import AuthorizedRoute from "components/AuthorizedRoute";
import RouteIfNotFound from "components/RouteIfNotFound";

import DocumentList from "./pages/DocumentList";
import DocumentViewer from "./pages/DocumentViewer";

const DocumentsRouting: React.FC = () => {
  const { documentRead } = usePermissions();

  return (
    <Switch>
      <AuthorizedRoute
        path="/documents/list"
        component={DocumentList}
        canActive={documentRead}
        exact
      />
      <AuthorizedRoute
        path="/documents/viewer/:docId"
        component={DocumentViewer}
        canActive={documentRead}
        exact
      />
      <Redirect from="/documents/" to="/documents/list" exact />
      <RouteIfNotFound />
    </Switch>
  );
};

export default DocumentsRouting;
