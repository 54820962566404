import { CompanyData, CompanyRelationData } from "models";

import { AuthorsCommentsType } from "shared/utils/getAuthorsComments";
import { CategoryItemType } from "shared/utils/getCategoriesWithHierarchy";
import { GetContactResut } from "shared/utils/getContacts";

export type CompanyRelationState = Omit<
  CompanyRelationData,
  "comments" | "contacts"
> & {
  comments: AuthorsCommentsType;
  categoriesList: CategoryItemType;
  contacts: GetContactResut[];
  retailerCustomizedAttributes?: {
    retailerLabel?: string;
    retailerCode?: string;
  }[];
};

type SupplierFormErrorState = {
  externalName?: boolean;
};
type State = {
  isLoading: boolean;
  company: CompanyData;
  companyRelation: CompanyRelationState;
  supplierFormData: any;
  hasError: boolean;
  supplierFormError: SupplierFormErrorState;
  errorStatus: number;
  supplierId: string;
  supplierName: string;
};

export const initialState: State = {
  isLoading: false,
  company: {},
  companyRelation: null,
  supplierFormData: {},
  supplierFormError: { externalName: false },
  hasError: false,
  supplierId: null,
  supplierName: null,
  errorStatus: null,
};
