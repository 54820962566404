import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useReactOidc } from "@axa-fr/react-oidc-context";

import { selectOidcUserPermissions } from "reduxStore/oidc/selectors";
import { selectHasUserAccessToMd } from "reduxStore/user/selectors";

export const PERMISSIONS = Object.freeze({
  brandsWrite: "brands.write",
  categoryListsRead: "categoryLists.read",
  categoryListsImport: "categoryLists.import",
  categoryListsWrite: "categoryLists.write",
  categoryListsDelete: "categoryLists.delete",
  companyprivateWrite: "companyprivate.write",
  companyRelationsWrite: "companyRelations.write",
  companiesRead: "companies.read",
  dunsRead: "duns.read",
  masterdataMyAssociatedSites: "masterdata.myAssociatedSites",
  masterdataMyBrands: "masterdata.myBrands",
  masterdataMyCategories: "masterdata.myCategories",
  masterdataMyProducts: "masterdata.myProducts",
  masterdataMySites: "masterdata.mySites",
  masterdataMySuppliers: "masterdata.mySuppliers",
  productsWrite: "products.write",
  productsRead: "products.read",
  productsUpdate: "products.update",
  productsImport: "products.import",
  productsEnable: "products.enable",
  sitesWrite: "sites.write",
  sitesImport: "sites.import",
  sitesRead: "sites.read",
  sitesDelete: "sites.delete",
  siteRelationsWrite: "siteRelations.write",
  siteRelationsImport: "siteRelations.import",
  documentRead: "documents.read",
});

type PermissionObject = Record<keyof typeof PERMISSIONS, boolean>;

export function getPermissionsObject(permissions = "") {
  const permissionsList = permissions?.split(",") || [];

  const hasPermission = permissionType =>
    permissionsList.includes(permissionType);
  return Object.fromEntries(
    Object.keys(PERMISSIONS).map(key => [key, hasPermission(PERMISSIONS[key])])
  ) as PermissionObject;
}

export default function usePermissions() {
  const permissions = useSelector(selectOidcUserPermissions);
  const hasAccessToMd = useSelector(selectHasUserAccessToMd);

  const {
    oidcUser: {
      profile: {
        global_user_is_admin,
        is_company_traceone,
        accessToAdministration,
        isMasquerade,
      },
    },
  } = useReactOidc();

  const permissionsObject = useMemo(
    () => getPermissionsObject(permissions),
    [permissions]
  );

  return {
    hasAccessToMd,
    isCompanyAdmin: global_user_is_admin === "True",
    hasAccessToAdministration: accessToAdministration === "True",
    isTraceoneAdmin: is_company_traceone === "True",
    isMasquerade: isMasquerade === "True",
    ...permissionsObject,
  };
}
