/** @jsxRuntime classic */
import "./polyfills";
import "./styles/_normalize.css";
import "./styles/_app.less";
import "./styles/_override.less";

import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";

import {
  AuthenticationProvider,
  OidcSecure,
  oidcLog,
  InMemoryWebStorage,
} from "@axa-fr/react-oidc-context";
import {
  AxiosInstancesWrapper,
  LoadingPage,
} from "@trace-one/react-components";
import AXIOS_INSTANCES from "apis/axiosInstances";
import moment from "moment";
import momentDurationPlugin from "moment-duration-format";

import oidcConfig from "core/oidc/oidcConfig";

import store from "reduxStore";

import App from "./App";

momentDurationPlugin(moment);

ReactDOM.render(
  <ReduxProvider store={store}>
    <AuthenticationProvider
      configuration={oidcConfig}
      UserStore={InMemoryWebStorage}
      loggerLevel={
        process.env.NODE_ENV === "production" ? oidcLog.DEBUG : oidcLog.ERROR
      }
      callbackComponentOverride={LoadingPage}
      authenticating={LoadingPage}
    >
      <OidcSecure>
        <AxiosInstancesWrapper
          axiosInstanceCUMD={AXIOS_INSTANCES.CUMD}
          axiosInstancePMD={AXIOS_INSTANCES.PMD}
          axiosInstanceRLMD={AXIOS_INSTANCES.RLMD}
          axiosInstanceSMD={AXIOS_INSTANCES.SMD}
          axiosInstanceTON={AXIOS_INSTANCES.TON}
          // axiosInstanceTAGS
        >
          <App />
        </AxiosInstancesWrapper>
      </OidcSecure>
    </AuthenticationProvider>
  </ReduxProvider>,
  document.getElementById("root")
);
