import { ManufacturedItemState } from "reduxStore/productDetails/models";

import Table from "components/Table";

import useTargetMarketListTable from "../hooks/useTargetMarketListTable";

interface TargetMarket {
  targetMarketsList: ManufacturedItemState["targetMarketsList"];
}

const TargetMarketList: React.FC<TargetMarket> = ({ targetMarketsList }) => {
  const { columns, data } = useTargetMarketListTable({
    targetMarketsList,
  });

  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={false}
      pagination={false}
      data-test-id="target-markets-table"
    />
  );
};

export default TargetMarketList;
