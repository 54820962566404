import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import validator from "validator";

import { selectUserId } from "reduxStore/user/selectors";

import { ApplicationTypes } from "shared/constants";
import useRouteSearchParams from "shared/hooks/useRouteSearchParams";
import { backToAppStorage } from "shared/webStorage";

import useAppTranslation from "./useAppTranslation";

interface BackData {
  from?: string;
  appid?: string;
}

const appGraphicMap = {
  "0": "app-logo-qp-full",
  "1": "app-logo-pp-full",
  "8": "app-logo-pdm-full",
  "9": "app-logo-bi-full",
  "22": "app-logo-pdm_na-full",
  "23": "app-logo-pdm-full",
  "40": "app-logo-ton-full",
  "50": "app-logo-project-full",
  "51": "app-logo-qms-full",
  "70": "app-logo-spec-full",
  "80": "app-logo-rfx-full",
  "90": "app-logo-admin-full",
  "100": "app-logo-md-full",
  "110": "app-logo-insight-full",
};

function validateBackData(data: BackData) {
  return (
    data?.from &&
    validator.isURL(data.from, { require_protocol: true }) &&
    Object.values(ApplicationTypes).includes(parseInt(data?.appid))
  );
}

function useInitialBackData({ userId }: { userId: string }) {
  return useMemo<BackData>(() => {
    const initialData = backToAppStorage.get();
    const isValid =
      validateBackData(initialData) && initialData.userId === userId;
    return isValid ? initialData : null;
  }, []);
}

const useBackButton = () => {
  const userId = useSelector(selectUserId);
  const { from, appid } = useRouteSearchParams<BackData>();

  const initialData = useInitialBackData({ userId });
  const [data, setData] = useState(initialData);
  const { appLabel } = useAppTranslation(data?.appid);

  const handleBackClick = () => {
    window.location.assign(data.from);
  };

  useEffect(() => {
    const data = { from, appid };
    if (from && appid) {
      if (validateBackData(data)) {
        setData(data);
        backToAppStorage.set({ ...data, userId });
      } else {
        setData(null);
        backToAppStorage.remove();
      }
    }
  }, [from, appid]);

  useEffect(() => {
    return () => {
      if (appid && from) {
        handleBackClick();
      }
    };
  }, []);

  return {
    isBackButtonVisible: !!validateBackData(data),
    from: data?.from,
    appid: data?.appid,
    handleBackClick,
    appLabel,
    appIcon: appGraphicMap[data?.appid],
  };
};

export default useBackButton;
