import { Card, Col } from "antd";
import { PageCol } from "pages/Sites/constants";

import styles from "../../../../SiteRetailerDetails.module.less";

import CustomAttributesBloc from "./CustomAttributesBloc";
import SiteUsageBloc from "./SiteUsageBloc";

import "./BlocTitle.less";

const PrivateInformationBloc = () => {
  return (
    <>
      <Col
        {...PageCol.HALF}
        className={styles.boxshadow}
        style={{ paddingRight: "15px" }}
      >
        <Card>
          <SiteUsageBloc />

          <CustomAttributesBloc />
        </Card>
      </Col>
    </>
  );
};

export default PrivateInformationBloc;
