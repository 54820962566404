import { createSlice } from "@reduxjs/toolkit";

import {
  fetchCountries,
  fetchPointOfSales,
  fetchCompanyIdentifiers,
  fetchUsersByCompanyId,
  fetchSiteFunctions,
  fetchCertifications,
  fetchCertificationTypes,
  fetchCustomProductTypes,
  fetchRelatedCompanies,
  fetchCategoryLists,
  fetchBrands,
  fetchNetContentMeasures,
  fetchTradeItemStatuses,
  fetchManufacturingItemStatuses,
  fetchTeamMemberResponsibilities,
  fetchLanguages,
  fetchCompanyActivity,
  fetchOwningCompany,
} from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    clearNetContentMeasures: (state, action) => {
      state.netContentMeasuresData = initialState.netContentMeasuresData;
    },
    clearManufacturingItemStatuses: (state, action) => {
      state.manufacturingItemStatusesData =
        initialState.manufacturingItemStatusesData;
    },
    clearTeamMemberResponsibilities: (state, action) => {
      state.teamMemberResponsibilitiesData =
        initialState.teamMemberResponsibilitiesData;
    },
    setBackButtonData: (state, action) => {
      state.backButtonData = action.payload;
    },
    setContactUserError: (state, action) => {
      state.contactUserError = {
        ...state.contactUserError,
        ...action.payload,
      };
    },
    clearContactUserError: (state, action) => {
      state.contactUserError = initialState.contactUserError;
    },
    clearBackButtonData: (state, action) => {
      state.backButtonData = initialState.backButtonData;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCountries.pending, (state, action) => {
        state.countriesData.isLoading = true;
        state.countriesData.isInitiated =
          state.countriesData.isInitiated && !state.countriesData.hasError;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countriesData.data = action.payload;
        state.countriesData.isInitiated = true;
        state.countriesData.isLoading = false;
        state.countriesData.hasError = false;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.countriesData.data = [];
        state.countriesData.isLoading = false;
        state.countriesData.hasError = true;
      })

      .addCase(fetchPointOfSales.pending, (state, action) => {
        state.pointOfSalesData.isLoading = true;
      })
      .addCase(fetchPointOfSales.fulfilled, (state, action) => {
        state.pointOfSalesData.data = action.payload;
        state.pointOfSalesData.isLoading = false;
        state.pointOfSalesData.hasError = false;
      })
      .addCase(fetchPointOfSales.rejected, (state, action) => {
        state.pointOfSalesData.data = [];
        state.pointOfSalesData.isLoading = false;
        state.pointOfSalesData.hasError = true;
      })

      .addCase(fetchCompanyIdentifiers.pending, (state, action) => {
        state.companyIdentifiersData.isLoading = true;
        state.companyIdentifiersData.isInitiated =
          state.companyIdentifiersData.isInitiated &&
          !state.companyIdentifiersData.hasError;
      })
      .addCase(fetchCompanyIdentifiers.fulfilled, (state, action) => {
        state.companyIdentifiersData.data = action.payload;
        state.companyIdentifiersData.isInitiated = true;
        state.companyIdentifiersData.isLoading = false;
        state.companyIdentifiersData.hasError = false;
      })
      .addCase(fetchCompanyIdentifiers.rejected, (state, action) => {
        state.companyIdentifiersData.data = [];
        state.companyIdentifiersData.isLoading = false;
        state.companyIdentifiersData.hasError = true;
      })

      .addCase(fetchUsersByCompanyId.pending, (state, action) => {
        state.userList.isLoading = true;
        state.userList.isInitiated =
          state.userList.isInitiated && !state.userList.hasError;
      })
      .addCase(fetchUsersByCompanyId.fulfilled, (state, action) => {
        state.userList.data = action.payload.users;
        state.userList.isInitiated = true;
        state.userList.isLoading = false;
        state.userList.hasError = false;
      })
      .addCase(fetchUsersByCompanyId.rejected, (state, action) => {
        state.userList.data = [];
        state.userList.isLoading = false;
        state.userList.hasError = true;
      })

      .addCase(fetchSiteFunctions.pending, (state, action) => {
        state.siteFunctionsData.isLoading = true;
        state.siteFunctionsData.isInitiated =
          state.siteFunctionsData.isInitiated &&
          !state.siteFunctionsData.hasError;
      })
      .addCase(fetchSiteFunctions.fulfilled, (state, action) => {
        state.siteFunctionsData.data = action.payload;
        state.siteFunctionsData.isInitiated = true;
        state.siteFunctionsData.isLoading = false;
        state.siteFunctionsData.hasError = false;
      })
      .addCase(fetchSiteFunctions.rejected, (state, action) => {
        state.siteFunctionsData.data = [];
        state.siteFunctionsData.isLoading = false;
        state.siteFunctionsData.hasError = true;
      })

      .addCase(fetchCertifications.pending, (state, action) => {
        state.certificatesData.isLoading = true;
        state.certificatesData.isInitiated =
          state.certificatesData.isInitiated &&
          !state.certificatesData.hasError;
      })
      .addCase(fetchCertifications.fulfilled, (state, action) => {
        state.certificatesData.data = action.payload;
        state.certificatesData.isLoading = false;
        state.certificatesData.isInitiated = true;
        state.certificatesData.hasError = false;
      })
      .addCase(fetchCertifications.rejected, (state, action) => {
        state.certificatesData.data = [];
        state.certificatesData.isLoading = false;
        state.certificatesData.hasError = true;
      })

      .addCase(fetchCertificationTypes.pending, (state, action) => {
        state.certificateTypesData.isLoading = true;
        state.certificateTypesData.isInitiated =
          state.certificateTypesData.isInitiated &&
          !state.certificateTypesData.hasError;
      })
      .addCase(fetchCertificationTypes.fulfilled, (state, action) => {
        state.certificateTypesData.data = action.payload;
        state.certificateTypesData.isInitiated = true;
        state.certificateTypesData.isLoading = false;
        state.certificateTypesData.hasError = false;
      })
      .addCase(fetchCertificationTypes.rejected, (state, action) => {
        state.certificateTypesData.data = [];
        state.certificateTypesData.isLoading = false;
        state.certificateTypesData.hasError = true;
      })

      .addCase(fetchCustomProductTypes.pending, (state, action) => {
        state.customProductTypes.isLoading = true;
        state.customProductTypes.isInitiated =
          state.customProductTypes.isInitiated &&
          !state.customProductTypes.hasError;
      })
      .addCase(fetchCustomProductTypes.fulfilled, (state, action) => {
        state.customProductTypes.data = action.payload;
        state.customProductTypes.isInitiated = true;
        state.customProductTypes.isLoading = false;
        state.customProductTypes.hasError = false;
      })
      .addCase(fetchCustomProductTypes.rejected, (state, action) => {
        state.customProductTypes.data = [];
        state.customProductTypes.isLoading = false;
        state.customProductTypes.hasError = false; // To be changed to true when implemented in staging
      })

      .addCase(fetchRelatedCompanies.pending, (state, action) => {
        state.relatedCompanyList.isLoading = true;
      })
      .addCase(fetchRelatedCompanies.fulfilled, (state, action) => {
        state.relatedCompanyList.data = action.payload.companies;
        state.relatedCompanyList.isLoading = false;
        state.relatedCompanyList.hasError = false;
      })
      .addCase(fetchRelatedCompanies.rejected, (state, action) => {
        state.relatedCompanyList.data = [];
        state.relatedCompanyList.isLoading = false;
        state.relatedCompanyList.hasError = true;
      })

      .addCase(fetchCategoryLists.pending, (state, action) => {
        state.categoryListsData.isInitiated =
          state.categoryListsData.isInitiated &&
          !state.categoryListsData.hasError;
        state.categoryListsData.isLoading = true;
        state.categoryListsData.hasError = false;
      })
      .addCase(fetchCategoryLists.fulfilled, (state, action) => {
        state.categoryListsData.isInitiated = true;
        state.categoryListsData.isLoading = false;
        state.categoryListsData.data = action.payload;
      })
      .addCase(fetchCategoryLists.rejected, (state, action) => {
        state.categoryListsData.isLoading = false;
        state.categoryListsData.data = [];
        state.categoryListsData.hasError = true;
      })

      .addCase(fetchBrands.pending, (state, action) => {
        state.brandsData.isInitiated =
          state.brandsData.isInitiated && !state.brandsData.hasError;
        state.brandsData.isLoading = true;
        state.brandsData.hasError = false;
      })
      .addCase(fetchBrands.fulfilled, (state, action) => {
        state.brandsData.isInitiated = true;
        state.brandsData.isLoading = false;
        state.brandsData.data = action.payload;
      })
      .addCase(fetchBrands.rejected, (state, action) => {
        state.brandsData.isLoading = false;
        state.brandsData.data = [];
        state.brandsData.hasError = true;
      })

      .addCase(fetchNetContentMeasures.pending, (state, action) => {
        state.netContentMeasuresData.isInitiated =
          state.netContentMeasuresData.isInitiated &&
          !state.netContentMeasuresData.hasError;
        state.netContentMeasuresData.isLoading = true;
        state.netContentMeasuresData.hasError = false;
      })
      .addCase(fetchNetContentMeasures.fulfilled, (state, action) => {
        state.netContentMeasuresData.isInitiated = true;
        state.netContentMeasuresData.isLoading = false;
        state.netContentMeasuresData.data = action.payload;
      })
      .addCase(fetchNetContentMeasures.rejected, (state, action) => {
        state.netContentMeasuresData.isLoading = false;
        state.netContentMeasuresData.data = [];
        state.netContentMeasuresData.hasError = true;
      })

      .addCase(fetchTradeItemStatuses.pending, (state, action) => {
        state.tradeItemStatusesData.isInitiated =
          state.tradeItemStatusesData.isInitiated &&
          !state.tradeItemStatusesData.hasError;
        state.tradeItemStatusesData.isLoading = true;
        state.tradeItemStatusesData.hasError = false;
      })
      .addCase(fetchTradeItemStatuses.fulfilled, (state, action) => {
        state.tradeItemStatusesData.isInitiated = true;
        state.tradeItemStatusesData.isLoading = false;
        state.tradeItemStatusesData.data = action.payload;
      })
      .addCase(fetchTradeItemStatuses.rejected, (state, action) => {
        state.tradeItemStatusesData.isLoading = false;
        state.tradeItemStatusesData.data = [];
        state.tradeItemStatusesData.hasError = true;
      })

      .addCase(fetchManufacturingItemStatuses.pending, (state, action) => {
        state.manufacturingItemStatusesData.isInitiated =
          state.manufacturingItemStatusesData.isInitiated &&
          !state.manufacturingItemStatusesData.hasError;
        state.manufacturingItemStatusesData.isLoading = true;
        state.manufacturingItemStatusesData.hasError = false;
      })
      .addCase(fetchManufacturingItemStatuses.fulfilled, (state, action) => {
        state.manufacturingItemStatusesData.isInitiated = true;
        state.manufacturingItemStatusesData.isLoading = false;
        state.manufacturingItemStatusesData.data = action.payload;
      })
      .addCase(fetchManufacturingItemStatuses.rejected, (state, action) => {
        state.manufacturingItemStatusesData.isLoading = false;
        state.manufacturingItemStatusesData.data = [];
        state.manufacturingItemStatusesData.hasError = true;
      })

      .addCase(fetchTeamMemberResponsibilities.pending, (state, action) => {
        state.teamMemberResponsibilitiesData.isInitiated =
          state.teamMemberResponsibilitiesData.isInitiated &&
          !state.teamMemberResponsibilitiesData.hasError;
        state.teamMemberResponsibilitiesData.isLoading = true;
        state.teamMemberResponsibilitiesData.hasError = false;
      })
      .addCase(fetchTeamMemberResponsibilities.fulfilled, (state, action) => {
        state.teamMemberResponsibilitiesData.isInitiated = true;
        state.teamMemberResponsibilitiesData.isLoading = false;
        state.teamMemberResponsibilitiesData.data = action.payload;
      })
      .addCase(fetchTeamMemberResponsibilities.rejected, (state, action) => {
        state.teamMemberResponsibilitiesData.isLoading = false;
        state.teamMemberResponsibilitiesData.data = [];
        state.teamMemberResponsibilitiesData.hasError = true;
      })
      .addCase(fetchLanguages.pending, (state, action) => {
        state.languagesData.isLoading = true;
        state.languagesData.isInitiated =
          state.languagesData.isInitiated && !state.languagesData.hasError;
      })
      .addCase(fetchLanguages.fulfilled, (state, action) => {
        state.languagesData.data = action.payload;
        state.languagesData.isInitiated = true;
        state.languagesData.isLoading = false;
        state.languagesData.hasError = false;
      })
      .addCase(fetchLanguages.rejected, (state, action) => {
        state.languagesData.data = [];
        state.languagesData.isLoading = false;
        state.languagesData.hasError = true;
      })

      .addCase(fetchCompanyActivity.pending, (state, action) => {
        state.companyActivityData.isLoading = true;
        state.companyActivityData.isInitiated =
          state.companyActivityData.isInitiated &&
          !state.companyActivityData.hasError;
      })
      .addCase(fetchCompanyActivity.fulfilled, (state, action) => {
        state.companyActivityData.data = action.payload;
        state.companyActivityData.isInitiated = true;
        state.companyActivityData.isLoading = false;
        state.companyActivityData.hasError = false;
      })
      .addCase(fetchCompanyActivity.rejected, (state, action) => {
        state.companyActivityData.data = [];
        state.companyActivityData.isLoading = false;
        state.companyActivityData.hasError = true;
      })

      .addCase(fetchOwningCompany.pending, (state, action) => {
        state.owningCompany.isLoading = true;
        state.owningCompany.isInitiated =
          state.owningCompany.isInitiated && !state.owningCompany.hasError;
      })
      .addCase(fetchOwningCompany.fulfilled, (state, action) => {
        state.owningCompany.data = action.payload;
        state.owningCompany.isInitiated = true;
        state.owningCompany.isLoading = false;
        state.owningCompany.hasError = false;
      })
      .addCase(fetchOwningCompany.rejected, (state, action) => {
        state.owningCompany.data = {};
        state.owningCompany.isLoading = false;
        state.owningCompany.hasError = true;
      });
  },
});

export const {
  clearNetContentMeasures,
  clearManufacturingItemStatuses,
  clearTeamMemberResponsibilities,
  setBackButtonData,
  clearBackButtonData,
  clearContactUserError,
  setContactUserError,
} = slice.actions;
export default slice.reducer;
