import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

import parseToJSON from "shared/utils/parseToJSON";

export const _selectShared = (state: RootState) => state.shared;

export const _selectRelatedCompanies = createSelector(
  _selectShared,
  ({ relatedCompanyList }) => relatedCompanyList
);
export const selectCertificatesData = createSelector(
  _selectShared,
  ({ certificatesData }) => certificatesData
);
export const selectCertificateTypesData = createSelector(
  _selectShared,
  ({ certificateTypesData }) => certificateTypesData
);
export const selectCustomProductTypes = createSelector(
  _selectShared,
  ({ customProductTypes }) => customProductTypes
);
export const selectSiteFunctionsData = createSelector(
  _selectShared,
  ({ siteFunctionsData }) => siteFunctionsData
);
export const _selectUserList = createSelector(
  _selectShared,
  ({ userList }) => userList
);
export const selectCountriesData = createSelector(
  _selectShared,
  ({ countriesData }) => countriesData
);
export const selectPointOfSalesData = createSelector(
  _selectShared,
  ({ pointOfSalesData }) => pointOfSalesData
);
export const selectCompanyIdentifiersData = createSelector(
  _selectShared,
  ({ companyIdentifiersData }) => companyIdentifiersData
);
export const selectBackButtonData = createSelector(
  _selectShared,
  ({ backButtonData }) => backButtonData
);

export const selectContactUserError = createSelector(
  _selectShared,
  ({ contactUserError }) => contactUserError
);
export const selectCategoryListsData = createSelector(
  _selectShared,
  ({ categoryListsData }) => categoryListsData
);
export const selectBrandsData = createSelector(
  _selectShared,
  ({ brandsData }) => brandsData
);
export const selectNetContentMeasuresData = createSelector(
  _selectShared,
  ({ netContentMeasuresData }) => netContentMeasuresData
);
export const selectTradeItemStatusesData = createSelector(
  _selectShared,
  ({ tradeItemStatusesData }) => tradeItemStatusesData
);
export const selectManufacturingItemStatusesData = createSelector(
  _selectShared,
  ({ manufacturingItemStatusesData }) => manufacturingItemStatusesData
);

export const selectCompanyActivityData = createSelector(
  _selectShared,
  ({ companyActivityData }) => companyActivityData
);

export const selectCompanyActivityOptions = createSelector(
  selectCompanyActivityData,
  ({ data }) =>
    data.map(({ itemCode, text }) => ({ value: itemCode, name: text }))
);

export const selectCountriesOptions = createSelector(
  selectCountriesData,
  ({ data }) =>
    data.map(({ itemCode, text }) => ({ value: itemCode, name: text }))
);

export const selectCompanyActivityMap = createSelector(
  selectCompanyActivityData,
  ({ data }) =>
    data.reduce<{ [itemCode: string]: string }>(
      (prev, { itemCode, text }) => ({ ...prev, [itemCode]: text }),
      {}
    )
);

export const selectCountriesMap = createSelector(
  selectCountriesData,
  ({ data }) =>
    data.reduce<{ [itemCode: string]: string }>(
      (prev, { itemCode, text }) => ({ ...prev, [itemCode]: text }),
      {}
    )
);

export const selectPointOfSalesOptions = createSelector(
  selectPointOfSalesData,
  ({ data }) => data.map(({ id, text }) => ({ value: id, name: text }))
);

export const selectIdentifiersOptions = createSelector(
  selectCompanyIdentifiersData,
  ({ data }) => data.map(({ id, text }) => ({ value: id, name: text }))
);

export const selectUserOptions = createSelector(_selectUserList, ({ data }) =>
  data.map(u => ({
    ...u,
    value: u.userId,
    label: `${u.userFirstName} ${u.userLastName}`,
  }))
);

export const selectFunctionOptions = createSelector(
  selectSiteFunctionsData,
  ({ data }) =>
    data.map(f => ({
      value: f.id,
      name: f.text,
    }))
);

export const selectCertificateOptions = createSelector(
  selectCertificatesData,
  ({ data }) =>
    data.map(c => ({
      value: c.id,
      name: c.text,
    }))
);

export const selectIsCertificateLoading = createSelector(
  selectCertificatesData,
  ({ isLoading }) => isLoading
);

export const selectCertificateTypeOptions = createSelector(
  selectCertificateTypesData,
  ({ data }) =>
    data.map(c => ({
      value: c.id,
      name: c.text,
    }))
);

export const selectIsCertificateTypeLoading = createSelector(
  selectCertificateTypesData,
  ({ isLoading }) => isLoading
);

export const selectRelatedCompanyOptions = createSelector(
  _selectRelatedCompanies,
  ({ data }) =>
    data.map(c => ({
      value: c.companyId,
      name: c.companyDisplayName,
      companyStatus: c.companyStatus,
    }))
);

export const selectIsRelatedCompanyLoading = createSelector(
  _selectRelatedCompanies,
  ({ isLoading }) => isLoading
);

export const selectIdentifierLoading = createSelector(
  selectCompanyIdentifiersData,
  ({ isLoading }) => isLoading
);

export const selectCategoryListTD = createSelector(
  selectCategoryListsData,
  ({ data }) =>
    data.map(({ categoryListId, categoryListName }) => {
      const valueObj = {
        categoryId: categoryListId,
        categoryItemId: "",
      };
      const value = parseToJSON(valueObj);
      return {
        key: value,
        id: categoryListId,
        pId: 0,
        value: value,
        title: categoryListName,
        categoryListId,
      };
    })
);

export const selectIsSiteSharedHasInitiated = createSelector(
  _selectShared,
  ({
    certificatesData,
    certificateTypesData,
    companyIdentifiersData,
    siteFunctionsData,
  }) =>
    certificateTypesData?.isInitiated &&
    certificatesData?.isInitiated &&
    companyIdentifiersData?.isInitiated &&
    siteFunctionsData?.isInitiated
);

export const selectIsSiteSharedHasError = createSelector(
  _selectShared,
  ({
    certificatesData,
    certificateTypesData,
    companyIdentifiersData,
    siteFunctionsData,
  }) =>
    certificateTypesData?.hasError ||
    certificatesData?.hasError ||
    companyIdentifiersData?.hasError ||
    siteFunctionsData?.hasError
);

export const selectTeamMemberResponsibilities = createSelector(
  _selectShared,
  ({ teamMemberResponsibilitiesData }) => teamMemberResponsibilitiesData?.data
);

export const selectLanguagesData = createSelector(
  _selectShared,
  ({ languagesData }) => languagesData
);

export const selectOwningCompany = createSelector(
  _selectShared,
  ({ owningCompany }) => owningCompany
);
