import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

/* istanbul ignore next */
export const _selectSiteRetailerDetails = (state: RootState) =>
  state.siteRetailerDetails;

export const selectRetailerSite = createSelector(
  _selectSiteRetailerDetails,
  ({ data }) => data.site
);

export const selectRetailerSupplier = createSelector(
  _selectSiteRetailerDetails,
  ({ data }) => data.supplier
);
export const selectRetailerRelation = createSelector(
  _selectSiteRetailerDetails,
  ({ data }) => data.relation
);

export const selectRetailerCompanyRelation = createSelector(
  _selectSiteRetailerDetails,
  ({ data }) => data.companyRelation
);

export const selectSiteRetailerFormData = createSelector(
  _selectSiteRetailerDetails,
  ({ siteRetailerFormData }) => siteRetailerFormData
);

export const selectRetailerRelatedUsers = createSelector(
  _selectSiteRetailerDetails,
  ({ data }) =>
    data.relatedUsers?.map(u => ({
      ...u,
      value: u.userId,
      label: `${u.userFirstName} ${u.userLastName}`,
    }))
);

export const selectRetailerSiteIsLoading = createSelector(
  _selectSiteRetailerDetails,
  ({ isLoading }) => isLoading
);

export const selectRetailerSiteHasErrors = createSelector(
  _selectSiteRetailerDetails,
  ({ hasErrors }) => hasErrors
);

export const selectRetailerSiteErrorStatus = createSelector(
  _selectSiteRetailerDetails,
  ({ errorStatus }) => errorStatus
);

export const selectRetailerSiteInitiated = createSelector(
  _selectSiteRetailerDetails,
  ({ data }) => Object.keys(data).length !== 0
);
