/* istanbul ignore file */
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import usePermissions from "core/oidc/usePermissions";

import { selectIsRetailer } from "reduxStore/oidc/selectors";

interface BaseHeaderRoute {
  path: string;
  text: React.ReactNode;
  onClick?: () => void;
  "data-test-id"?: string;
}

interface HeaderRoute extends BaseHeaderRoute {
  subNav?: BaseHeaderRoute[];
  isVisible: boolean;
}

export default function useHeaderRoutes() {
  const intl = useIntl();
  const isRetailer = useSelector(selectIsRetailer);
  const {
    hasAccessToMd,
    masterdataMyProducts,
    masterdataMyAssociatedSites,
    masterdataMySites,
    masterdataMyBrands,
    masterdataMyCategories,
    masterdataMySuppliers,
    documentRead,
  } = usePermissions();

  const homePath = "/home";

  const headerRoutes: HeaderRoute[] = [
    {
      path: homePath,
      "data-test-id": "home-link",
      text: intl.formatMessage({ id: "header.navMenu.home" }),
      subNav: [],
      isVisible: true,
    },
    {
      path: "/products",
      "data-test-id": "products-link",
      text: intl.formatMessage({ id: "header.navMenu.products" }),
      isVisible: hasAccessToMd && masterdataMyProducts,
      subNav: [],
    },
    {
      path: "/sites",
      "data-test-id": "sites-link",
      text: intl.formatMessage({ id: "header.navMenu.sites" }),
      isVisible:
        hasAccessToMd &&
        (isRetailer ? masterdataMyAssociatedSites : masterdataMySites),
      subNav: [],
    },
    {
      path: "/brands/list",
      "data-test-id": "brands-link",
      text: intl.formatMessage({ id: "header.navMenu.brands" }),
      isVisible: hasAccessToMd && masterdataMyBrands,
      subNav: [],
    },
    {
      path: "/classifications/list",
      "data-test-id": "classifications-link",
      text: intl.formatMessage({ id: "general.classifications" }),
      isVisible: hasAccessToMd && masterdataMyCategories,
      subNav: [],
    },
    {
      path: "/documents/list",
      "data-test-id": "documents-link",
      text: intl.formatMessage({ id: "general.documentLibrary" }),
      isVisible: hasAccessToMd && isRetailer && documentRead,
      subNav: [],
    },
    {
      path: "/suppliers",
      "data-test-id": "suppliers-link",
      text: intl.formatMessage({ id: "general.suppliers" }),
      isVisible: hasAccessToMd && masterdataMySuppliers,
      subNav: [],
    },
  ];

  return {
    homePath,
    headerRoutes: headerRoutes
      .filter(r => r.isVisible)
      .map(({ isVisible, ...rest }) => rest),
  };
}
