import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { TagV1 as Tag } from "@trace-one/design-system";
import { ProductIntlStatus } from "pages/Products/constants";

import { selectManufacturingItemStatusesData } from "reduxStore/shared/selectors";

import { ManufacturingItemStatus } from "shared/constants";

interface ManufacturedStatusProps {
  value?: string;
  size?: string;
}

const ManufacturedStatus: React.FC<ManufacturedStatusProps> = ({
  value,
  size = "medium",
}) => {
  const intl = useIntl();
  const { data: manufacturingItemStatuses } = useSelector(
    selectManufacturingItemStatusesData
  );
  const { text, itemCode } =
    manufacturingItemStatuses.find(({ id }) => id === value) ?? {};

  if (value === ManufacturingItemStatus.ACTIVE) return null;

  return text ? (
    <div style={{ display: "flex" }}>
      <Tag
        label={intl.formatMessage({ id: ProductIntlStatus[itemCode].tagLabel })}
        size={size}
        mode="light"
        color="grey"
      />
    </div>
  ) : null;
};

export default ManufacturedStatus;
