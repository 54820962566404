import React, { memo } from "react";
import { useIntl } from "react-intl";

import { Heading, Paragraph } from "@trace-one/design-system";
import { Form } from "antd";

import FormWrapper from "components/FormWrapper";
import Input from "components/Input";

import { SectionsIds } from "../../constants";

import styles from "./PackagingSection.module.less";

const PackagingSection = ({ disabled }) => {
  const intl = useIntl();

  return (
    <FormWrapper.Section
      id={SectionsIds.PACKAGING}
      title={
        <Heading size="m">
          {intl.formatMessage({
            id: "productForm.packaging.title",
          })}{" "}
        </Heading>
      }
      className={styles.packagingFormWrapper}
    >
      <Form.Item
        name="packagingFormat"
        label={
          <Paragraph size="m">
            {" "}
            {intl.formatMessage({
              id: "productForm.packaging.packagingFormat",
            })}{" "}
          </Paragraph>
        }
      >
        <Input
          disabled={disabled}
          data-test-id="md-product-packagingFormat"
          maxLength={256}
        />
      </Form.Item>
      <Form.Item
        name="packagingNetContentDescription"
        label={
          <Paragraph size="m">
            {intl.formatMessage({
              id: "productForm.packaging.packagingNetContentDescription",
            })}{" "}
          </Paragraph>
        }
      >
        <Input
          disabled={disabled}
          data-test-id="md-product-packagingNetContentDescription"
          maxLength={256}
        />
      </Form.Item>
    </FormWrapper.Section>
  );
};

export default memo(PackagingSection);
