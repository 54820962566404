import ErrorPage from "components/ErrorPage";

export interface BaseHocProps {
  isInitiated?: boolean;
  hasError?: boolean;
  isLoading?: boolean;
}

type Options<P = {}> = React.PropsWithChildren<BaseHocProps> & {
  WrappedComponent: React.ComponentType<P>;
  _isInitiated: boolean;
  _hasError: boolean;
  _isLoading: boolean;
};

type RenderHocMiddleware = <P>(options: Options<P>) => JSX.Element;

export const renderHocMiddleware: RenderHocMiddleware = ({
  WrappedComponent,
  isInitiated = true,
  hasError = false,
  isLoading = false,

  _isInitiated = true,
  _hasError = false,
  _isLoading = false,
  ...rest
}) => {
  const hasErrors = hasError || _hasError;
  if (hasErrors) {
    return <ErrorPage />;
  }

  const defaultProps = {
    isLoading: isLoading && _isLoading,
    hasError: hasErrors,
    isInitiated: isInitiated && _isInitiated,
  };

  return <WrappedComponent {...rest} {...(defaultProps as any)} />;
};
