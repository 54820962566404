import { createAsyncThunk } from "@reduxjs/toolkit";
import { SiteIdentification } from "pages/Sites/constants";

import { SmdAPI } from "apis";
import { SiteData } from "models";

export const fetchSite = createAsyncThunk<
  SiteData,
  string,
  { rejectValue: { status: number } }
>("siteSupplierForm/fetchSite", async (siteId: string, thunkAPI) => {
  try {
    const { data } = await SmdAPI.getSiteById(siteId);
    let internalCode = data.identifierCodes?.find(
      ({ typeId }) => typeId === SiteIdentification.INTERNAL_CODE
    )?.value;
    return { ...data, internalCode };
  } catch (err) {
    const status: number = err?.response?.status ?? 500;
    return thunkAPI.rejectWithValue({ status });
  }
});

export const fetchSiteRelations = createAsyncThunk(
  "siteSupplierForm/fetchSiteRelations",
  async (siteId: string) => {
    const { data } = await SmdAPI.getSiteRelations(siteId);
    return data;
  }
);
