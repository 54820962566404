// import React from "react";
import { Icon as BaseIcon } from "@trace-one/design-system";

// import { Icon as OldBaseIcon } from "@trace-one/react-components";
import iconMapping, { IconName } from "./iconMapping";

interface InternalIconProps {
  name: IconName;
  color?: string;
  size?: string;
  useTOLibrary?: false;
}

interface ExternalIconProps {
  name: string;
  useTOLibrary?: true;
  color?: string;
  size?: string;
}

export type IconProps = InternalIconProps | ExternalIconProps;

const isInternalIcon = (props: IconProps): props is InternalIconProps =>
  !props.useTOLibrary;

const Icon: React.FC<IconProps> = props => {
  if (!isInternalIcon(props)) {
    const { name, useTOLibrary, size, color, ...rest } = props;
    return (
      <BaseIcon
        name={name}
        size={size}
        color={color}
        aria-label={name}
        {...rest}
      />
    );
  }

  const { name, useTOLibrary, size, color, ...rest } = props;
  const icon = iconMapping[name];
  if (!icon) {
    return null;
  }

  const { Component, defaultProps } = icon;

  return <Component {...defaultProps} {...rest} aria-label={name} />;
};

Icon.defaultProps = {
  useTOLibrary: false,
};

export default Icon;

// interface IconProps {
//   name:string;
//   size:string;
//   color:string;
// }

// const Icon:React.FC<IconProps>=({name,size,color})=>{

// return (
//   <>
//   <BaseIcon name={name} size={size} color={color}/>
//   </>
// )
// }

// export default Icon;
