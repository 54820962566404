import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { useReactOidc } from "@axa-fr/react-oidc-context";
import { Heading, Paragraph, Container } from "@trace-one/design-system";
import { InsightLinkWidget } from "@trace-one/react-components";

import {
  selectIsRetailer,
  selectOidcAccessToken,
} from "reduxStore/oidc/selectors";
import {
  selectUserInsightStsURL,
  selectUserInsightGetURL,
  selectUserOwningCompanyId,
  selectUserLanguageCode,
} from "reduxStore/user/selectors";

import styles from "./Home.module.less";

const Home: React.FC = () => {
  const intl = useIntl();
  const {
    oidcUser: {
      profile: { company_country },
    },
  } = useReactOidc();
  const isRetailer = useSelector(selectIsRetailer);
  const accessToken = useSelector(selectOidcAccessToken);
  const insightGetUrl = useSelector(selectUserInsightGetURL);
  const insightStsURL = useSelector(selectUserInsightStsURL);
  const ownerId = useSelector(selectUserOwningCompanyId);
  const showInsightWidget = window.env.SHOW_INSIGHT_WIDGET === "true";
  const userLanguageCode = useSelector(selectUserLanguageCode);

  return (
    <Container isPageWrapper>
      <div>
        <section className={styles.homeTop}>
          <Heading size="xl" data-test-id={`${userLanguageCode}`}>
            {intl.formatMessage({ id: "home.title" })}{" "}
          </Heading>
        </section>
        <section className={styles.root}>
          <div className={styles.left}>
            <div className={styles.title}>
              <Heading size="m">
                {intl.formatMessage({ id: "home.subtitle" })}
              </Heading>
              <hr />
            </div>
            <div className={styles.footer}>
              <div className={styles.footerParagraphs}>
                <Paragraph size="m">
                  {intl.formatMessage({ id: "home.firstParagraph" })}
                </Paragraph>
                {isRetailer ? (
                  <>
                    <Paragraph size="m">
                      {intl.formatMessage({
                        id: "home.retailer.secondParagraph",
                      })}
                    </Paragraph>
                    <Paragraph size="m">
                      {intl.formatMessage({
                        id: "home.retailer.thirdParagraph",
                      })}
                    </Paragraph>
                  </>
                ) : (
                  <Paragraph size="m">
                    {intl.formatMessage({
                      id: "home.supplier.secondParagraph",
                    })}
                  </Paragraph>
                )}
              </div>
            </div>
          </div>

          {showInsightWidget &&
            isRetailer &&
            (insightStsURL || insightGetUrl) && (
              <div className={styles.right}>
                <InsightLinkWidget
                  insightStsUrl={insightStsURL}
                  insightGetUrl={`${insightGetUrl}/embed/dashboards/master_data::master_data`}
                  companyLanguageCode={company_country}
                  accessToken={accessToken}
                  ownerId={ownerId}
                  appName="md"
                  attachApplicationTypeId={false}
                />
              </div>
            )}
        </section>
      </div>
    </Container>
  );
};

export default Home;
