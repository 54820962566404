import parseToJSON from "shared/utils/parseToJSON";

function findClosestParent(
  categoryItems,
  {
    parent1CategoryItemId,
    parent2CategoryItemId,
    parent3CategoryItemId,
    parent4CategoryItemId,
  }
) {
  let closestParent1, closestParent2, closestParent3, closestParent4;
  for (const item of categoryItems) {
    const { categoryItemId } = item;
    if (categoryItemId === parent1CategoryItemId) {
      closestParent1 = item;
      break;
    } else if (categoryItemId === parent2CategoryItemId) {
      closestParent2 = item;
    } else if (categoryItemId === parent3CategoryItemId) {
      closestParent3 = item;
    } else if (categoryItemId === parent4CategoryItemId) {
      closestParent4 = item;
    }
  }
  return closestParent1 ?? closestParent2 ?? closestParent3 ?? closestParent4;
}

export default function mapToCategoryItemsTD(
  categoryItems,
  { shouldFindExistedClosestParent, existedCategoryItems } = {}
) {
  const expandedKeysObj = {};

  const result = categoryItems.map(
    ({
      categoryId,
      categoryItemId,
      categoryItemName,
      parent1CategoryItemId,
      parent2CategoryItemId,
      parent3CategoryItemId,
      parent4CategoryItemId,
    }) => {
      const valueObj = {
        categoryId,
        categoryItemId,
      };
      const value = parseToJSON(valueObj);
      let closestParentId = parent1CategoryItemId;
      if (shouldFindExistedClosestParent) {
        const parent = findClosestParent(
          [...categoryItems, ...existedCategoryItems],
          {
            parent1CategoryItemId,
            parent2CategoryItemId,
            parent3CategoryItemId,
            parent4CategoryItemId,
          }
        );
        const valueObj = {
          categoryId: parent?.categoryId ?? categoryId,
          categoryItemId: parent?.categoryItemId ?? "",
        };
        const expandedKey = parseToJSON(valueObj);
        expandedKeysObj[expandedKey] = true;
        closestParentId = parent?.categoryItemId;
      }

      return {
        key: value,
        id: categoryItemId,
        pId: closestParentId ?? categoryId,
        value,
        title: categoryItemName,
        categoryListId: categoryId,
      };
    }
  );
  return { result, expandedKeys: Object.keys(expandedKeysObj) };
}
