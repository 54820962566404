import React from "react";
import { Route } from "react-router-dom";

import ErrorPage from "components/ErrorPage";

const RouteIfNotFound: React.FC = () => {
  return (
    <Route>
      <ErrorPage status={404} />
    </Route>
  );
};

export default RouteIfNotFound;
