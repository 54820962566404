import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Spinner } from "@trace-one/react-components";
import { Menu } from "antd";

import { CumdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import ActionDropdown from "components/ActionDropdown";
import useAsyncFileDownload from "shared/hooks/useAsyncFileDownload";

import { SupplierColumn } from "../models";

interface SupplierActionProps {
  record: SupplierColumn;
  refetchSuppliers: () => void;
}

const SupplierAction: React.FC<SupplierActionProps> = ({
  record,
  refetchSuppliers,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const { companyRelationsWrite, companiesRead } = usePermissions();

  const [handleExportSupplier, isExportSupplierLoading] = useAsyncFileDownload(
    () => {
      return CumdAPI.exportCompanies(
        { companyIds: [record.key] },
        { relatedOwnerCompanyId: ownerCompanyId }
      );
    },
    { fileExtension: "csv" }
  );

  return (
    <ActionDropdown
      data-test-id="md-supplier-action"
      overlay={
        <Menu>
          {companyRelationsWrite && (
            <Menu.Item
              key="supplier-edit"
              onClick={() => {
                history.push(`/suppliers/edit/${record.key}`);
              }}
              data-test-id="md-supplier-edit"
            >
              {intl.formatMessage({
                id: "general.editSupplier",
              })}
            </Menu.Item>
          )}
          {companiesRead && (
            <Menu.Item
              key="supplier-export"
              onClick={handleExportSupplier}
              data-test-id="md-supplier-export"
            >
              <Spinner spinning={isExportSupplierLoading}>
                {intl.formatMessage({
                  id: "general.exportSupplier",
                })}
              </Spinner>
            </Menu.Item>
          )}
        </Menu>
      }
    />
  );
};

export default SupplierAction;
