import { createSlice } from "@reduxjs/toolkit";

import { fetchSupplierForm } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "supplierForm",
  initialState,
  reducers: {
    clearSupplierForm: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSupplierForm.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.errorStatus = null;
      })
      .addCase(fetchSupplierForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.company = action.payload.company;
        state.companyRelation = action.payload.companyRelation;
      })
      .addCase(fetchSupplierForm.rejected, (state, action) => {
        state.isLoading = false;
        state.company = initialState.company;
        state.companyRelation = initialState.companyRelation;
        state.hasError = true;
        state.errorStatus = (action.payload as any).status;
      });
  },
});

export const { clearSupplierForm } = slice.actions;
export default slice.reducer;
