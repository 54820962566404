import { createAsyncThunk } from "@reduxjs/toolkit";

import { SmdAPI, CumdAPI } from "apis";
import {
  SiteData,
  SiteRelationData,
  CompanyRelationData,
  UserData,
} from "models";

import getAuthorsComments, {
  AuthorsCommentsType,
} from "shared/utils/getAuthorsComments";

export const fetchRetailerForm = createAsyncThunk<
  {
    site: SiteData & {
      siteOwnerCompanyName: string;
      siteOwnerCompanyType: string;
    };
    relation: Omit<SiteRelationData, "comments"> & {
      comments: AuthorsCommentsType;
    };
    relatedUsers: UserData[];
    companyRelation: CompanyRelationData;
  },
  { siteId: string; associatedCompanyId: string },
  { rejectValue: { status: number } }
>(
  "siteRetailerForm/fetchRetailerForm",
  async ({ siteId, associatedCompanyId }, thunkAPI) => {
    try {
      const [site, relation] = await Promise.all([
        SmdAPI.getSiteById(siteId).then(({ data }) => data),
        SmdAPI.getSiteRelationBySiteIdAndAssociatedCompanyId(siteId, {
          associatedCompanyId,
        }).then(({ data }) => data),
      ]);
      const userIds = [
        ...(site.contactUserIds || []),
        ...(relation?.contacts?.map(c => c.userId) || []),
      ];

      const [contactUsers, supplier, companyRelation, comments] =
        await Promise.all([
          userIds.length
            ? await CumdAPI.getUsersByUserIds({
                userIds,
              }).then(({ data }) => data)
            : [],
          CumdAPI.getCompaniesByFilters(
            { companyIds: [site.ownerCompanyId] },
            { relatedOwnerCompanyId: associatedCompanyId, includePrivate: true }
          ).then(({ data }) => data.companies[0]),
          CumdAPI.getCompanyRelation(relation.associatedCompanyId, {
            associatedCompanyId: site.ownerCompanyId,
          }).then(({ data }) => data),
          getAuthorsComments({ comments: relation?.comments }),
        ]);

      const relationContacts =
        relation?.contacts?.map(contact => {
          return {
            ...contactUsers.find(({ userId }) => userId === contact.userId),
            ...contact,
          };
        }) || [];

      return {
        site: {
          ...site,
          siteOwnerCompanyName: supplier.companyDisplayName,
          siteOwnerCompanyType: supplier.companyType,
        },
        relation: {
          ...relation,
          comments,
          contacts: relationContacts,
        },
        relatedUsers: contactUsers,
        companyRelation,
      };
    } catch (err) {
      const status = err?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({ status });
    }
  }
);
