import { Switch } from "react-router-dom";

import usePermissions from "core/oidc/usePermissions";

import AuthorizedRoute from "components/AuthorizedRoute";
import RouteIfNotFound from "components/RouteIfNotFound";

import BrandList from "./pages/BrandList";

const BrandsRouting: React.FC = () => {
  const { masterdataMyBrands } = usePermissions();

  return (
    <Switch>
      <AuthorizedRoute
        path="/brands/list"
        canActive={masterdataMyBrands}
        component={BrandList}
        exact
      />
      <RouteIfNotFound />
    </Switch>
  );
};

export default BrandsRouting;
