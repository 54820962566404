import { createAsyncThunk } from "@reduxjs/toolkit";

import { CumdAPI } from "apis";

import { CompanyActivityId } from "shared/constants";

export const fetchSupplierCompanies = createAsyncThunk(
  "productShared/fetchSupplierCompanies",
  async ({ ownerCompanyId }: { ownerCompanyId: string }) => {
    const { data: supplierCompanies } = await CumdAPI.getCompaniesByFilters(
      {},
      {
        relatedOwnerCompanyId: ownerCompanyId,
        companyActivityId: CompanyActivityId.SUPPLIER,
      }
    );
    return supplierCompanies.companies;
  }
);

export const fetchPakerCompanies = createAsyncThunk(
  "productShared/fetchPakerCompanies",
  async ({ ownerCompanyId }: { ownerCompanyId: string }) => {
    const { data: pakerCompanies } = await CumdAPI.getCompaniesByFilters(
      {},
      {
        relatedOwnerCompanyId: ownerCompanyId,
        companyActivityId: CompanyActivityId.PAKER,
      }
    );
    return pakerCompanies.companies;
  }
);
