import { createSlice } from "@reduxjs/toolkit";

import { fetchCategoryItemDetails } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "categoryItemForm",
  initialState,
  reducers: {
    clearCategoryItemForm: () => initialState,
    setCategoryDetailData: (state, action) => {
      state.categoryItemData.categoryId = "";
      state.categoryItemData.categoryItemId = "";
      state.categoryItemData.categoryItemName = "New category";
      state.categoryItemData.externalCode = "";
      state.categoryItemData.contacts = [];
      state.categoryItemData.translations = action.payload;
      state.categoryItemData.isActive = true;
      state.categoryItemData.modifiedAt = "";
    },
    setCategoryItemData: (state, action) => {
      state.categoryItemText = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCategoryItemDetails.pending, (state, action) => {
        state.isCategoryitemLoading = true;
        state.hasCategoryItemError = false;
        state.errorStatus = null;
      })
      .addCase(fetchCategoryItemDetails.fulfilled, (state, action) => {
        state.isCategoryitemLoading = false;
        state.categoryItemData = action.payload;
      })
      .addCase(fetchCategoryItemDetails.rejected, (state, action) => {
        state.isCategoryitemLoading = false;
        state.categoryItemData = { translations: [] };
        state.hasCategoryItemError = true;
        state.categoryItemText = null;
        state.errorStatus = (action.payload as any).status;
      });
  },
});

export const {
  clearCategoryItemForm,
  setCategoryDetailData,
  setCategoryItemData,
} = slice.actions;
export default slice.reducer;
