export type BuildCompanyTitleArgs = {
  companyDisplayName?: string;
  companyRelationExternalIdentifier?: string;
  companyRelationExternalName?: string;
};

export default function buildCompanyTitle(args: BuildCompanyTitleArgs): string {
  if (!args) {
    return "";
  }
  const {
    companyDisplayName,
    companyRelationExternalIdentifier,
    companyRelationExternalName,
  } = args;
  let title: string[] = [companyDisplayName];

  if (companyRelationExternalIdentifier && companyRelationExternalName) {
    title = [
      `${companyRelationExternalName} (${companyRelationExternalIdentifier})`,
    ];
  } else if (companyRelationExternalIdentifier) {
    title = [`${companyDisplayName} (${companyRelationExternalIdentifier})`];
  } else if (companyRelationExternalName) {
    title = [companyRelationExternalName];
  } else {
    title = [companyDisplayName];
  }

  return title.filter(item => item).join(" ");
}
