import { MutableRefObject } from "react";
import { useIntl } from "react-intl";

import { DocumentDetailAPIResponse } from "@trace-one/api-clients.dms";
import { Paragraph, Heading, Divider, Tooltip } from "@trace-one/design-system";
import { format } from "date-fns";

import { GetUserResut } from "shared/utils/getUsersCollectionMap";

import { formatFileSize } from "../../../utils/utils";
import { signerInfoData } from "../../hooks/useFileDetails";

import styles from "./Information.module.less";

interface Props {
  fileInfoRef?: MutableRefObject<DocumentDetailAPIResponse>;
  uploadedUserInfoRef?: MutableRefObject<GetUserResut>;
  signatureInfoRef?: MutableRefObject<signerInfoData[]>;
}

const Information = ({
  fileInfoRef,
  uploadedUserInfoRef,
  signatureInfoRef,
}: Props) => {
  const fileDetails = fileInfoRef?.current;
  const uploadedUser = uploadedUserInfoRef?.current;
  const fileState = fileDetails.customMetadata.find(
    i => i.name === "project_file_state"
  )?.value;
  let ownerCompanyName = "";
  if (uploadedUser?.owningCompanyId === fileDetails?.ownerCompanyId) {
    ownerCompanyName = uploadedUser.owningCompanyName;
  }

  const { formatMessage } = useIntl();

  const getFormatedMsg = (key: string) => {
    return formatMessage({
      id: `documentLibraryListPage.docDetails.tabs.informations.${key}`,
    });
  };

  const fileInformation = [
    [getFormatedMsg("company"), ownerCompanyName],
    [getFormatedMsg("docType"), fileDetails?.fileTypeName],
    [getFormatedMsg("docSize"), formatFileSize(fileDetails?.fileLength)],
    [getFormatedMsg("uploadedBy"), uploadedUser?.userName ?? ""],
    [getFormatedMsg("version"), fileDetails?.version],
    [
      getFormatedMsg("dateTime"),
      format(new Date(fileDetails?.uploadedAt), "dd/MM/yyyy"),
    ],
  ];

  return (
    <div>
      {fileInformation.map(([key, value]) => {
        return (
          <div className="d-flex py-1" key={key}>
            <Heading
              size="xxs"
              color="grey-4"
              className={styles.fileInformationKeys}
            >
              {key}
            </Heading>
            <Paragraph className={styles.fileInformationValues} color="grey-4">
              {value}
            </Paragraph>
          </div>
        );
      })}
      {fileState && (
        <div className="d-flex py-1">
          <Heading
            size="xxs"
            color="grey-4"
            className={styles.fileInformationKeys}
          >
            {formatMessage({
              id: `documentLibraryListPage.docDetails.state`,
            })}
          </Heading>
          <Paragraph className={styles.fileInformationValues} color="grey-4">
            {formatMessage({
              id: `documentLibraryListPage.docDetails.state.${fileState.toLowerCase()}`,
            })}
          </Paragraph>
        </div>
      )}
      {signatureInfoRef?.current && (
        <div className="d-flex py-1">
          <Heading
            size="xxs"
            color="grey-4"
            className={styles.fileInformationKeys}
          >
            {getFormatedMsg("signedBy")}
          </Heading>
          <div className={styles.fileInformationValues}>
            {signatureInfoRef.current.map((signer, index) => {
              const { userName, userCompanyName, signedDate, signStatus } =
                signer;
              return (
                <div key={index} className={styles.signerInfoContainer}>
                  {userName && (
                    <Tooltip placement="top" text={signer.userName}>
                      <Paragraph color="grey-4">{userName}</Paragraph>
                    </Tooltip>
                  )}
                  {userCompanyName && (
                    <Tooltip placement="top" text={signer.userCompanyName}>
                      <Paragraph color="grey-4">{userCompanyName}</Paragraph>
                    </Tooltip>
                  )}
                  {signedDate && (
                    <Paragraph color="grey-4">
                      {format(new Date(signedDate), "dd/MM/yyyy")}
                    </Paragraph>
                  )}
                  <Paragraph color="grey-4">{signStatus}</Paragraph>
                  {++index !== signatureInfoRef.current.length && (
                    <Divider dashed color="grey-3" height={8}></Divider>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Information;
