import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "reduxStore";
import { fetchCategoryLists } from "reduxStore/shared/asyncActions";
import { selectCategoryListsData } from "reduxStore/shared/selectors";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import getDisplayName from "shared/utils/getDisplayName";

import { renderHocMiddleware, BaseHocProps } from "../_helpers";

const withCategoryLists =
  () =>
  <P extends BaseHocProps>(WrappedComponent: React.ComponentType<P>) => {
    const Component: React.FC<P> = props => {
      const dispatch = useAppDispatch();
      const ownerCompanyId = useSelector(selectUserOwningCompanyId);
      const { isInitiated, hasError, isLoading } = useSelector(
        selectCategoryListsData
      );

      useEffect(() => {
        dispatch(fetchCategoryLists({ companyId: ownerCompanyId }));
      }, [ownerCompanyId]);

      return renderHocMiddleware({
        WrappedComponent,
        _hasError: hasError,
        _isInitiated: isInitiated,
        _isLoading: isLoading,
        ...props,
      });
    };

    Component.displayName = getDisplayName(withCategoryLists, WrappedComponent);

    return Component;
  };

export default withCategoryLists;
