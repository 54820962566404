import { useIntl } from "react-intl";

import { Empty } from "@trace-one/react-components";
import {
  TreeSelect as AntTreeSelect,
  TreeSelectProps as AntdTreeSelectProps,
} from "antd";
import PropTypes from "prop-types";

import ShortMsg from "components/ShortMsg";
import Spinner from "components/Spinner";

interface TreeSelectProps extends AntdTreeSelectProps<any> {
  loading?: boolean;
  searchValue?: string;
  minLengthToAsyncSearch?: number;
}

const TreeSelect: React.FC<TreeSelectProps> = ({
  loading,
  searchValue,
  minLengthToAsyncSearch,
  multiple,
  ...rest
}) => {
  const intl = useIntl();

  const isMinLengthNumberProvided = Number.isInteger(minLengthToAsyncSearch);

  const hasTextEnoughLength = text => {
    return text?.trim()?.length >= minLengthToAsyncSearch;
  };

  const asyncTreeSelectProps: Partial<TreeSelectProps> = {};
  if (isMinLengthNumberProvided) {
    const shouldDisplayDefaultTD = !searchValue;

    if (!shouldDisplayDefaultTD && !hasTextEnoughLength(searchValue)) {
      asyncTreeSelectProps.dropdownRender = () => (
        <ShortMsg.EnterAtLeast value={minLengthToAsyncSearch} />
      );
    }
    if (loading) {
      asyncTreeSelectProps.dropdownRender = () => <Spinner />;
    }
  }

  return (
    <AntTreeSelect
      notFoundContent={
        loading ? (
          <Spinner />
        ) : (
          <Empty title={intl.formatMessage({ id: "general.noData" })} />
        )
      }
      treeDataSimpleMode
      filterTreeNode={(inputValue, treeNode) => {
        const { title, pId } = treeNode;
        if (pId === 0) {
          return false;
        }
        return typeof title === "string"
          ? title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          : true;
      }}
      placeholder={intl.formatMessage({
        id: multiple ? "general.selectValues" : "general.selectValue",
      })}
      {...asyncTreeSelectProps}
      searchValue={searchValue}
      multiple={multiple}
      {...rest}
    />
  );
};

TreeSelect.propTypes = {
  loading: PropTypes.bool,
  searchValue: PropTypes.string,
  minLengthToAsyncSearch: PropTypes.number,
  multiple: PropTypes.bool,
};

export default TreeSelect;
