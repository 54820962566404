import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Container } from "@trace-one/design-system";
import moment from "moment";

import { CumdAPI, PmdAPI, RlmdAPI, SmdAPI } from "apis";

import ErrorPage from "components/ErrorPage";
import Icon from "components/Icon";
import useAsyncFileDownload from "shared/hooks/useAsyncFileDownload";
import useRouteSearchParams from "shared/hooks/useRouteSearchParams";

import styles from "./Downloads.module.less";

interface QueryData {
  type?: string;
  fileId?: string;
}

const AllowedTypes = {
  SMD: "smd",
  PMD: "pmd",
  RLMD: "rlmd",
  CUMD: "cumd",
};

const Downloads: React.FC = () => {
  const intl = useIntl();
  const { type, fileId } = useRouteSearchParams<QueryData>();

  const isQueryCorrect =
    !!type &&
    !!fileId &&
    Object.values(AllowedTypes).includes(type.toLowerCase());

  const filename = `ExportFile_${moment().format("DDMMYYYYhhmm")}.csv`;

  useEffect(() => {
    if (isQueryCorrect) handleFileExport();
  }, []);

  const getFileEndpoint = (type: string, fileId: string) => {
    type = type.toLowerCase();
    if (type === AllowedTypes.PMD) return PmdAPI.getFile(fileId);
    if (type === AllowedTypes.SMD) return SmdAPI.getStorageFile(fileId);
    if (type === AllowedTypes.RLMD) return RlmdAPI.getFile(fileId);
    if (type === AllowedTypes.CUMD) return CumdAPI.getFile(fileId);
  };

  const [handleFileExport] = useAsyncFileDownload(
    () => getFileEndpoint(type, fileId),
    {
      displayLoadingMessage: true,
      loadingMessage: intl.formatMessage({ id: "general.exportIsInProgress" }),
      filename,
    }
  );

  if (!isQueryCorrect) return <ErrorPage status={404} />;
  return (
    <div className={styles.downloadFile}>
      <Container maxWidth="md" isPageWrapper>
        <Icon name="streamline" />
        <div className={styles.downloadTitle}>
          <FormattedMessage
            id="downloadsPage.title"
            values={{
              b: str => <b>{str}</b>,
              filename,
            }}
          />
        </div>
        <div className={styles.downloadSubTitle}>
          <FormattedMessage
            id="downloadsPage.subTitle"
            values={{
              link: str => (
                <span
                  data-test-id="download-manually"
                  onClick={handleFileExport}
                >
                  {str}
                </span>
              ),
            }}
          />
        </div>
      </Container>
    </div>
  );
};

export default Downloads;
