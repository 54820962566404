import { Row } from "antd";

import TabPage from "components/TabPage";

import PrivateInformation from "./PrivateInformation";
import ProductIdentification from "./ProductIdentification";

interface ProductInformationTabProps {}
const ProductInformationTab: React.FC<ProductInformationTabProps> = () => {
  return (
    <TabPage.Wrapper>
      <Row gutter={[32, 32]}>
        <ProductIdentification />
        <PrivateInformation />
      </Row>
    </TabPage.Wrapper>
  );
};

export default ProductInformationTab;
