import { TradeItemStatus } from "shared/constants";
import { mapMomentLocalToDate } from "shared/utils/dateUtils";

import {
  GetProductDataForm,
  ProductFormValues,
  ProductFormData,
} from "./../models";

export default function prepareFormData({
  values,
  initialValues = {},
}: {
  values: ProductFormValues;
  initialValues: GetProductDataForm;
}): ProductFormData {
  const { id, ...restInitialValues } = initialValues;
  const { manufacturedItems, seasonalDate, tradeItemStatusId, ...restValues } =
    values;
  const mappedManufacturedItems = manufacturedItems?.map(
    ({
      supplierCompanyId,
      packerCompanyId,
      targetMarketsList = [],
      ...rest
    }) => {
      return {
        ...rest,
        supplierCompanyId: supplierCompanyId?.value,
        packerCompanyId: packerCompanyId?.value,
        targetMarketsList: targetMarketsList.map(
          ({ availabilityDate, ...rest }) => ({
            ...rest,
            startAvailabilityDate: mapMomentLocalToDate(availabilityDate?.[0]),
            endAvailabilityDate: mapMomentLocalToDate(availabilityDate?.[1]),
          })
        ),
      };
    }
  );

  const result: ProductFormData = {
    ...restInitialValues,
    ...restValues,
    tradeItemStatusId: tradeItemStatusId ?? TradeItemStatus.ACTIVE,
    seasonalAvailabilityStartDate: mapMomentLocalToDate(seasonalDate?.[0]),
    seasonalAvailabilityEndDate: mapMomentLocalToDate(seasonalDate?.[1]),
    manufacturedItems: mappedManufacturedItems,
  };
  return result;
}
