import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { compose } from "@reduxjs/toolkit";
import {
  Heading,
  TagV1 as Tag,
  Container,
  ActionBanner,
} from "@trace-one/design-system";
import { ModalService, ModalSupplierCreate } from "@trace-one/react-components";
import { ColumnsType } from "antd/lib/table";
import { RcFile } from "antd/lib/upload";

import { CumdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import { fetchSupplierList } from "reduxStore/supplierList/asyncActions";
import { selectSuppliers } from "reduxStore/supplierList/selectors";
import { clearSuppliers } from "reduxStore/supplierList/slice";
import {
  selectUserLanguageCode,
  selectUserOwningCompanyId,
} from "reduxStore/user/selectors";

import BtnCreateListItem from "components/BtnCreateListItem";
import ContactsModal from "components/ContactsModal";
import CsvImport from "components/CsvImport";
import ErrorPage from "components/ErrorPage";
import Table from "components/Table";
import {
  CompanyActivityId,
  TeamMemberResponsibilityParentItem,
  UserStatus,
} from "shared/constants";
import {
  withCountries,
  BaseHocProps,
  withCompanyActivity,
  withTeamMemberResponsibilities,
  withCategoryLists,
} from "shared/hocs";
import withUsers from "shared/hocs/withUsers";
import useSearchFilters from "shared/hooks/useSearchFilters";
import useTablePagination from "shared/hooks/useTablePagination";
import useToast from "shared/hooks/useToast";
import encodeFileAsBase64 from "shared/utils/encodeFileAsBase64";
import getUsersCollectionMap from "shared/utils/getUsersCollectionMap";
import {
  SearchFiltersStorage,
  TablePaginationStorage,
} from "shared/webStorage";

import useSupplierTable from "./hooks/useSupplierTable";
import { SupplierFilter, ContactColumns } from "./models";
import SupplierHeader from "./SupplierHeader";
import styles from "./SupplierList.module.less";
import SupplierSearch from "./SupplierSearch";

interface SupplierListProps extends BaseHocProps {}

const enhance = compose<React.FC<SupplierListProps>>(
  withCountries(),
  withCompanyActivity(),
  withUsers(),
  withCategoryLists(),
  withTeamMemberResponsibilities({
    includeParentItems: true,
    parentItemId: TeamMemberResponsibilityParentItem.RETAILER,
  })
);

export const SupplierList: React.FC<SupplierListProps> = ({
  isLoading,
  hasError,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const {
    companyprivateWrite,
    dunsRead,
    companiesRead,
    companyRelationsWrite,
  } = usePermissions();
  const languageCode = useSelector(selectUserLanguageCode);
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);

  const {
    isSuppliersLoading,
    suppliers,
    suppliersSkipAndTakeQueryStats,
    hasSuppliersError,
  } = useSelector(selectSuppliers);

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [contactsData, setContacts] = useState(null);
  const [openModel, setOpenModel] = useState<boolean>(false);
  const [allSelected, setAllSelected] = useState(false);
  const [showActionBanner, setShowActionBanner] = useState(false);

  const { paginationQuery, setPaginationQuery, resetPageNumber } =
    useTablePagination({
      skipAndTakeQueryStats: suppliersSkipAndTakeQueryStats,
      webStorage: TablePaginationStorage.SUPPLIER,
    });
  const {
    searchText,
    setSearchText,
    filterObj,
    mergeFilters,
    removeAllFiltersAndResetPageNumber,
  } = useSearchFilters<SupplierFilter>(
    {
      companyCountry: undefined,
      companyActivity: undefined,
      relationStatuses: ["active"],
      userId: "",
      responsibilityId: "",
      checked: false,
      productCategory: undefined,
    },
    {
      clearedFilters: {
        companyCountry: undefined,
        companyActivity: undefined,
        relationStatuses: [],
        userId: "",
        responsibilityId: "",
        checked: false,
        productCategory: undefined,
      },
      resetPageNumber,
      setSelectedRowKeys,
      setAllSelected,
      webStorage: SearchFiltersStorage.SUPPLIER,
    }
  );
  const isEnabled =
    filterObj.relationStatuses.length && filterObj.relationStatuses.length < 2
      ? filterObj.relationStatuses.includes("active")
      : undefined;
  const CompanyActivityData = filterObj.companyActivity
    ? parseInt(filterObj.companyActivity)
    : undefined;
  const refetchSuppliers = useCallback(() => {
    const { skip, take } = paginationQuery;
    dispatch(
      fetchSupplierList({
        ownerCompanyId,
        searchText,
        companyCountry: filterObj.companyCountry,
        isCompanyRelationStatusEnabled: isEnabled,
        CompanyActivityId: CompanyActivityData,
        OnlyWithoutContacts: filterObj.checked,
        ContactUserId: filterObj.userId,
        ContactResponsibilityId: filterObj.responsibilityId,
        categoryId: filterObj?.productCategory?.categoryId,
        categoryItemId: filterObj?.productCategory?.categoryItemId,
        skip,
        take,
      })
    );
  }, [filterObj, isEnabled, ownerCompanyId, searchText, paginationQuery]);

  useEffect(() => {
    if (companiesRead) {
      refetchSuppliers();
    } else {
      dispatch(clearSuppliers());
    }
  }, [companiesRead, refetchSuppliers]);

  useEffect(() => {
    return () => {
      dispatch(clearSuppliers());
    };
  }, []);

  useEffect(() => {
    if (!!selectedRowKeys.length || allSelected) setShowActionBanner(true);
    else setShowActionBanner(false);
    if (
      !!selectedRowKeys.length &&
      selectedRowKeys.length === suppliersSkipAndTakeQueryStats?.totalCount
    ) {
      setAllSelected(prev => !prev);
      setSelectedRowKeys([]);
    }
  }, [selectedRowKeys, allSelected, suppliersSkipAndTakeQueryStats]);

  const handleContactModel = async rawData => {
    const contacts = await getUsersCollectionMap(
      rawData?.contacts?.map(c => c.userId)
    );
    rawData?.contacts?.forEach(p => {
      if (contacts[p.userId])
        contacts[p.userId]["responsibilityId"] = p.responsibilityId;
    });
    setContacts({
      itemName: rawData.companyDisplayName,
      contacts: Object.values(contacts),
    });
    setOpenModel(true);
  };

  const { columns, data } = useSupplierTable({
    suppliers,
    refetchSuppliers,
    handleContactModel,
  });

  if (hasError || hasSuppliersError) {
    return <ErrorPage />;
  }

  const contactColumn: ColumnsType<ContactColumns> = [
    {
      title: intl.formatMessage({ id: "productDetails.contactNameText" }),
      dataIndex: "contactName",
      render: contactName => {
        return <>{contactName ? contactName : "-"}</>;
      },
    },
    {
      title: intl.formatMessage({ id: "productListPage.modal.table.header" }),
      dataIndex: "responsibility",
    },
    {
      title: intl.formatMessage({ id: "general.status" }),
      dataIndex: "status",

      render: record => {
        let lable = intl.formatMessage({ id: "general.inactive" });
        let color = "grey";
        if (record === UserStatus.ENABLED) {
          lable = intl.formatMessage({ id: "general.active" });
          color = "green";
        }

        return (
          <>
            <Tag label={lable} color={color} mode="light" />
          </>
        );
      },
    },
  ];

  const handleImportCsv = async ({ file }: { file: RcFile }) => {
    const fileContentBase64Encoded = await encodeFileAsBase64(file);
    await CumdAPI.commandCsvImport({
      fileName: file.name,
      fileContentBase64Encoded,
    });
    toast.info({
      description: intl.formatMessage({
        id: "import.csv.informationSuccess",
      }),
    });
    return true;
  };

  return (
    <Container isPageWrapper>
      <div className={styles.headActions}>
        <div className={styles.mainContainer}>
          <Heading size="l">
            {intl.formatMessage({ id: "general.listOfSuppliers" })}
          </Heading>
          <BtnCreateListItem
            text={intl.formatMessage({
              id: "general.createNewSupplier",
            })}
            hideButton={!(companyprivateWrite && dunsRead)}
            onClick={() => {
              ModalService.openModal({
                component: (
                  <ModalSupplierCreate
                    languageCode={languageCode}
                    companyActivityId={CompanyActivityId.SUPPLIER}
                    onConfirmSuccess={refetchSuppliers}
                    portalTypeId={"a04b9c9b-a0df-4c75-afac-1ab4279b60ba"}
                  />
                ),
              });
            }}
            iconName="suppliers"
          />
        </div>
        <div>
          {companyRelationsWrite && (
            <CsvImport fileSizeLessThanMB={3} onImport={handleImportCsv} />
          )}
        </div>
      </div>
      {companiesRead && (
        <SupplierSearch
          filterObj={filterObj}
          initialSearchValue={searchText}
          mergeFilters={mergeFilters}
          onClearFiltersClick={removeAllFiltersAndResetPageNumber}
          onSearch={setSearchText}
        />
      )}
      <SupplierHeader
        listingResult={
          <Heading style={{ color: "#4e4e4e" }} size="xxs">
            {intl.formatMessage(
              {
                id: "supplierListPage.table.listingResultNumber",
              },
              {
                current: suppliersSkipAndTakeQueryStats.currentCount ?? 0,
                total: suppliersSkipAndTakeQueryStats.totalCount ?? 0,
              }
            )}
          </Heading>
        }
        searchText={searchText}
        filterObj={filterObj}
        isEnabled={isEnabled}
        selectedRowKeys={selectedRowKeys}
        ownerCompanyId={ownerCompanyId}
        isAllSelected={allSelected}
      />
      {showActionBanner && (
        <ActionBanner
          message={
            allSelected
              ? intl.formatMessage(
                  { id: "supplierListPage.table.allSelected" },
                  {
                    selectedCount:
                      suppliersSkipAndTakeQueryStats.totalCount ?? 0,
                  }
                )
              : intl.formatMessage(
                  {
                    id:
                      selectedRowKeys.length === 1
                        ? "supplierListPage.table.oneSelected"
                        : "supplierListPage.table.selectedSuppliers",
                  },
                  { selectedCount: selectedRowKeys.length }
                )
          }
          data-test-id="selectedSuppliers-banner"
          link={{
            name: allSelected
              ? intl.formatMessage({ id: "supplierListPage.table.clearAll" })
              : intl.formatMessage(
                  { id: "supplierListPage.table.selectAllSuppliers" },
                  { allCount: suppliersSkipAndTakeQueryStats.totalCount ?? 0 }
                ),
            onClick: () => {
              setAllSelected(prev => !prev);
              setSelectedRowKeys([]);
            },
          }}
          type="info"
          closable={!allSelected}
          showIcon={true}
          onClose={() => setShowActionBanner(false)}
        />
      )}
      <Table
        columns={columns}
        dataSource={data}
        loading={isSuppliersLoading || isLoading}
        skip={paginationQuery.skip}
        take={paginationQuery.take}
        skipAndTakeQueryStats={suppliersSkipAndTakeQueryStats}
        setPaginationQuery={setPaginationQuery}
        rowSelection={{
          selectedRowKeys,
        }}
        setSelectedRowKeys={setSelectedRowKeys}
        isAllSelected={allSelected}
        showActionBanner={showActionBanner}
      />
      <ContactsModal
        open={openModel}
        title={"siteDetails.supplierName"}
        contactsData={contactsData}
        contactColumn={contactColumn}
        onCancel={() => {
          setOpenModel(false);
          setContacts(null);
        }}
        onPrimaryButtonClick={() => {
          setOpenModel(false);
          setContacts(null);
        }}
      />
    </Container>
  );
};

export default enhance(SupplierList);
