import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import moment from "moment";

import { useAppDispatch } from "reduxStore";
import {
  fetchDocumentsList,
  fetchDocumentsListGlobalSearch,
} from "reduxStore/documentList/asyncActions";
import {
  selectDocuments,
  selectDocumentsSkipAndTakeQueryStats,
  selectIsDocumentTableLoading,
  selectRelatedCompaniesOptions,
  selectFileTypes,
} from "reduxStore/documentList/selectors";
import {
  selectUserLanguageCode,
  selectUserOwningCompanyId,
} from "reduxStore/user/selectors";

import useTablePagination from "shared/hooks/useTablePagination";
import { TablePaginationStorage } from "shared/webStorage";

const useDocumentsList = ({ filters, listType, searchText }) => {
  const skipAndTakeQueryStats = useSelector(
    selectDocumentsSkipAndTakeQueryStats
  );

  const { paginationQuery, setPaginationQuery, resetPageNumber } =
    useTablePagination({
      skipAndTakeQueryStats,
      webStorage: TablePaginationStorage.DOCUMENTLIB,
      initialValue: { skip: 0, take: 20 },
    });

  const documents = useSelector(selectDocuments);
  const languageCode = useSelector(selectUserLanguageCode);
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const dispatch = useAppDispatch();
  const isListLoading = useSelector(selectIsDocumentTableLoading);
  const fileTypesInitiated = useSelector(selectFileTypes).isInitiated;
  const companiesInitiated = useSelector(
    selectRelatedCompaniesOptions
  ).isInitiated;
  const isPageLoading = !(fileTypesInitiated && companiesInitiated);

  const refetchDocuments = useCallback(() => {
    const {
      fileTypeIds,
      isMyCompany,
      ownerCompanyId: externalCompanyId,
      uploadedAtStartDate,
      uploadedAtEndDate,
      tradeItemId,
      siteId,
      projectId,
    } = filters;
    const { skip, take } = paginationQuery;

    const PageSize = take;
    const PageNumber = Math.floor(skip / take) + 1;

    window.env.GSS_INTEGRATION === "true"
      ? dispatch(
          fetchDocumentsListGlobalSearch({
            params: {
              PageSize,
              PageNumber,
              languageCode,
            },
            body: {
              searchText,
              fileTypeIds,
              tradeItemId,
              siteId,
              projectId,
              ownerCompanyId: isMyCompany ? ownerCompanyId : externalCompanyId,
              uploadedAtStartDate:
                uploadedAtStartDate &&
                moment(uploadedAtStartDate).format("YYYY-MM-DD"),
              uploadedAtEndDate:
                uploadedAtEndDate &&
                moment(uploadedAtEndDate).format("YYYY-MM-DD"),
            },
          })
        )
      : dispatch(
          fetchDocumentsList({
            params: {
              skip,
              take,
              languageCode,
            },
            body: {
              searchText,
              fileTypeIds,
              ownerCompanyId: isMyCompany ? ownerCompanyId : externalCompanyId,
              uploadedAtStartDate:
                uploadedAtStartDate &&
                moment(uploadedAtStartDate).format("YYYY-MM-DD"),
              uploadedAtEndDate:
                uploadedAtEndDate &&
                moment(uploadedAtEndDate).format("YYYY-MM-DD"),
            },
          })
        );
  }, [
    paginationQuery,
    languageCode,
    filters,
    ownerCompanyId,
    searchText,
    dispatch,
  ]);

  useEffect(() => {
    refetchDocuments();
  }, [refetchDocuments]);

  useEffect(() => {
    setPaginationQuery(prev => ({
      ...prev,
      take: listType === "tiles" ? 20 : 10,
    }));

    resetPageNumber();
  }, [listType, setPaginationQuery, resetPageNumber]);

  return {
    documents,
    isListLoading,
    isPageLoading,
    paginationQuery,
    setPaginationQuery,
    refetchDocuments,
    resetPageNumber,
    skipAndTakeQueryStats,
  };
};

export default useDocumentsList;
