import { createSlice } from "@reduxjs/toolkit";

import { fetchSupplierDetails } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "supplierDetails",
  initialState,
  reducers: {
    clearSupplierDetails: state => {
      state.isLoading = false;
      state.company = {};
      state.companyRelation = null;
      state.supplierFormData = {};
      state.supplierFormError = { externalName: false };
      state.hasError = false;
      state.errorStatus = null;
    },
    setSupplierFormData: (state, action) => {
      state.supplierFormData = action.payload;
    },
    setSupplierId: (state, action) => {
      state.supplierId = action.payload;
    },
    setSupplierName: (state, action) => {
      state.supplierName = action.payload;
    },
    setSupplierFormError: (state, action) => {
      state.supplierFormError = {
        ...state.supplierFormError,
        ...action.payload,
      };
    },
    clearSupplierFormData: state => {
      state.supplierFormData = {};
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSupplierDetails.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.errorStatus = null;
      })
      .addCase(fetchSupplierDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.company = action.payload.company;
        state.companyRelation = action.payload.companyRelation;
      })
      .addCase(fetchSupplierDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.company = initialState.company;
        state.companyRelation = initialState.companyRelation;
        state.hasError = true;
        state.errorStatus = (action.payload as any).status;
      });
  },
});

export const {
  clearSupplierDetails,
  setSupplierFormData,
  setSupplierFormError,
  clearSupplierFormData,
  setSupplierId,
  setSupplierName,
} = slice.actions;
export default slice.reducer;
