import { createSlice } from "@reduxjs/toolkit";

import {
  fetchDocumentsList,
  fetchRelatedCompanies,
  fetchFileTypes,
  fetchDocumentsListGlobalSearch,
  fetchProducts,
  fetchProjects,
  fetchSites,
} from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "documentList",
  initialState,
  reducers: {
    clearDocuments: (state, action) => {
      state.documents = initialState.documents;
      state.documentListSkipAndTakeQueryStats =
        initialState.documentListSkipAndTakeQueryStats;
      state.isDocumentListLoading = false;
      state.hasDocumentListError = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDocumentsList.pending, (state, action) => {
        state.isDocumentListLoading = true;
        state.hasDocumentListError = false;
      })
      .addCase(fetchDocumentsList.fulfilled, (state, action) => {
        const { items, currentCount, totalCount } = action.payload;
        state.documents = items;
        state.documentListSkipAndTakeQueryStats = { currentCount, totalCount };
        state.isDocumentListLoading = false;
      })
      .addCase(fetchDocumentsList.rejected, (state, action) => {
        state.documents = [];
        state.isDocumentListLoading = false;
        state.hasDocumentListError = true;
        state.documentListSkipAndTakeQueryStats =
          initialState.documentListSkipAndTakeQueryStats;
      })

      .addCase(fetchDocumentsListGlobalSearch.pending, (state, action) => {
        state.isDocumentListLoading = true;
        state.hasDocumentListError = false;
      })
      .addCase(fetchDocumentsListGlobalSearch.fulfilled, (state, action) => {
        const { items, currentCount, totalCount } = action.payload;
        state.documents = items;
        state.documentListSkipAndTakeQueryStats = { currentCount, totalCount };
        state.isDocumentListLoading = false;
      })
      .addCase(fetchDocumentsListGlobalSearch.rejected, (state, action) => {
        state.documents = [];
        state.isDocumentListLoading = false;
        state.hasDocumentListError = true;
        state.documentListSkipAndTakeQueryStats =
          initialState.documentListSkipAndTakeQueryStats;
      })

      .addCase(fetchRelatedCompanies.fulfilled, (state, action) => {
        state.relatedCompanies.data = action.payload;
        state.relatedCompanies.isInitiated = true;
        state.relatedCompanies.hasError = false;
      })
      .addCase(fetchRelatedCompanies.rejected, (state, action) => {
        state.relatedCompanies.data = [];
        state.relatedCompanies.isInitiated = true;
        state.relatedCompanies.hasError = true;
      })

      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.products = [];
      })

      .addCase(fetchSites.fulfilled, (state, action) => {
        state.sites = action.payload.siteList.sites;
        state.companies = action.payload.companyList;
      })
      .addCase(fetchSites.rejected, (state, action) => {
        state.sites = [];
      })

      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.projects = action.payload;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.projects = [];
      })

      .addCase(fetchFileTypes.fulfilled, (state, action) => {
        state.fileTypes.data = action.payload;
        state.fileTypes.isInitiated = true;
        state.fileTypes.hasError = false;
      })
      .addCase(fetchFileTypes.rejected, (state, action) => {
        state.fileTypes.data = [];
        state.fileTypes.isInitiated = true;
        state.fileTypes.hasError = true;
      });
  },
});

export const { clearDocuments } = slice.actions;
export default slice.reducer;
