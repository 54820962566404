import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { compose } from "@reduxjs/toolkit";
import { Heading, Container } from "@trace-one/design-system";
import { RcFile } from "antd/lib/upload";

import { PmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import BtnCreateListItem from "components/BtnCreateListItem";
import CsvImport from "components/CsvImport";
import Spinner from "components/Spinner";
import { TeamMemberResponsibilityParentItem } from "shared/constants";
import {
  BaseHocProps,
  withBrands,
  withCategoryLists,
  withNetContentMeasures,
  withTeamMemberResponsibilities,
  withTradeItemStatuses,
} from "shared/hocs";
import withUsers from "shared/hocs/withUsers";
import useToast from "shared/hooks/useToast";
import encodeFileAsBase64 from "shared/utils/encodeFileAsBase64";

import styles from "./ProductList.module.less";
import ProductTable from "./ProductTable";

interface ProductListProps extends BaseHocProps {}

const enhance = compose<React.FC<ProductListProps>>(
  withBrands(),
  withCategoryLists(),
  withNetContentMeasures(),
  withTradeItemStatuses(),
  withUsers(),
  withTeamMemberResponsibilities({
    includeParentItems: true,
    parentItemId: TeamMemberResponsibilityParentItem.RETAILER,
  })
);

export const ProductList: React.FC<ProductListProps> = ({ isInitiated }) => {
  const intl = useIntl();
  const history = useHistory();
  const { productsWrite, productsImport, productsRead } = usePermissions();

  const toast = useToast();

  const handleImportCsv = async ({ file }: { file: RcFile }) => {
    const fileContentBase64Encoded = await encodeFileAsBase64(file);
    await PmdAPI.commandCsvImport({
      fileName: file.name,
      // @ts-ignore
      fileContentBase64Encoded,
    });
    toast.info({
      description: intl.formatMessage({
        id: "import.csv.informationSuccess",
      }),
    });
    return true;
  };

  return (
    <Container isPageWrapper>
      <div className={styles.headActions}>
        <div className={styles.mainContainer}>
          <Heading size="l">
            {intl.formatMessage({
              id: "productsListPage.title",
            })}
          </Heading>
          <BtnCreateListItem
            hideButton={!productsWrite}
            text={intl.formatMessage({ id: "productsListPage.createProduct" })}
            onClick={() => history.push("/products/create")}
            iconName="product-add"
          />
        </div>

        <div>
          {productsImport && (
            <>
              <CsvImport fileSizeLessThanMB={3} onImport={handleImportCsv} />
            </>
          )}
        </div>
      </div>
      <Spinner spinning={!isInitiated} hideChildrenVisibilityOnLoading>
        <ProductTable productsRead={productsRead} />
      </Spinner>
    </Container>
  );
};

export default enhance(ProductList);
