import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { NET_CONTENT_PARENT_ID_FOR_PRODUCT_FORM } from "pages/Products/constants";
import { compose } from "redux";

import { useAppDispatch } from "reduxStore";
import { fetchProduct } from "reduxStore/productForm/asyncActions";
import { selectProductFormData } from "reduxStore/productForm/selectors";
import { clearProductForm } from "reduxStore/productForm/slice";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import ErrorPage from "components/ErrorPage";
import Spinner from "components/Spinner";
import {
  TeamMemberResponsibilityParentItem,
  TradeItemStatus,
} from "shared/constants";
import {
  withBrands,
  withCategoryLists,
  withNetContentMeasures,
  BaseHocProps,
  withTradeItemStatuses,
  withManufacturingItemStatuses,
  withTeamMemberResponsibilities,
} from "shared/hocs";

import ProductForm from "../../components/ProductForm";
interface ProductEditProps extends BaseHocProps {
  isReadOnly: boolean;
}

const enhance = compose<React.FC<ProductEditProps>>(
  withBrands(),
  withCategoryLists(),
  withManufacturingItemStatuses(),
  withTeamMemberResponsibilities({
    includeParentItems: true,
    parentItemId: TeamMemberResponsibilityParentItem.RETAILER,
  }),
  withNetContentMeasures({
    includeParentItems: true,
    parentItemId: NET_CONTENT_PARENT_ID_FOR_PRODUCT_FORM,
  }),
  withTradeItemStatuses()
);

export const ProductEdit: React.FC<ProductEditProps> = ({
  isInitiated,
  isReadOnly,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { productId } = useParams<{ productId?: string }>();
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const [isLoading, setIsLoading] = useState(false);
  const { productData, hasProductError, errorStatus } = useSelector(
    selectProductFormData
  );
  const isProductArchived =
    productData?.tradeItemStatusId === TradeItemStatus.INACTIVE;

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchProduct({ id: productId, ownerCompanyId })).then(() => {
      setIsLoading(false);
    });
    return () => {
      dispatch(clearProductForm());
    };
  }, [productId, ownerCompanyId, dispatch]);

  if (isProductArchived) {
    history.push(`/products/${productId}`);
  }

  if (hasProductError) {
    return <ErrorPage status={errorStatus} />;
  }

  if (!isInitiated || isLoading) {
    return <Spinner underHeader />;
  }

  return <ProductForm initialValues={productData} isReadOnly={isReadOnly} />;
};
export default enhance(ProductEdit);
