import { memo, useEffect, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading, TagV1 as Tag, ActionBanner } from "@trace-one/design-system";
import PropTypes from "prop-types";

import { useAppDispatch } from "reduxStore";
import { selectIsRetailer } from "reduxStore/oidc/selectors";
import { fetchRelatedCompanies } from "reduxStore/shared/asyncActions";
import {
  selectCountriesData,
  selectCountriesMap,
} from "reduxStore/shared/selectors";
import {
  fetchSites,
  fetchCompaniesSupplier,
  fetchAllCompaniesSupplier,
} from "reduxStore/siteList/asyncActions";
import {
  selectSites,
  selectIsSiteTableLoading,
  selectCompaniesMapForTable,
  selectSitesSkipAndTakeQueryStats,
} from "reduxStore/siteList/selectors";
import { clearSites } from "reduxStore/siteList/slice";
import { selectSupplierDetails } from "reduxStore/SupplierDetails/selectors";
import {
  setSupplierId,
  setSupplierName,
} from "reduxStore/SupplierDetails/slice";
import {
  selectUserOwningCompanyId,
  selectUserLanguageCode,
} from "reduxStore/user/selectors";

import ContactsModal from "components/ContactsModal";
import Table from "components/Table";
import { UserStatus } from "shared/constants";
import useSearchFilters from "shared/hooks/useSearchFilters";
import useTablePagination from "shared/hooks/useTablePagination";
import getUsersCollectionMap from "shared/utils/getUsersCollectionMap";
import {
  SearchFiltersStorage,
  TablePaginationStorage,
} from "shared/webStorage";

import useSiteTable from "./hooks/useSiteTable";
import SiteHeader from "./SiteHeader";
import SiteRetailerSearch from "./SiteRetailerSearch";
import SiteSearch from "./SiteSearch";
import styles from "./SiteTable.module.less";

const SiteTable = ({ sitesRead, selectedKpi, setSelectedKpi }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [contactsData, setContacts] = useState(null);
  const [openModel, setOpenModel] = useState(false);
  const [allSelected, setAllSelected] = useState(false);

  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const { supplierId, supplierName } = useSelector(selectSupplierDetails);

  const companiesMap = useSelector(selectCompaniesMapForTable);
  const sites = useSelector(selectSites);
  const skipAndTakeQueryStats = useSelector(selectSitesSkipAndTakeQueryStats);

  const isRetailer = useSelector(selectIsRetailer);
  const languageCode = useSelector(selectUserLanguageCode);

  const { paginationQuery, setPaginationQuery, resetPageNumber } =
    useTablePagination({
      webStorage: sitesRead
        ? isRetailer
          ? TablePaginationStorage.RETAILER_SITE
          : TablePaginationStorage.SUPPLIER_SITE
        : undefined,
      skipAndTakeQueryStats,
    });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showActionBanner, setShowActionBanner] = useState(false);

  const {
    searchText,
    setSearchText,
    filterObj,
    mergeFilters,
    removeAllFiltersAndResetPageNumber,
  } = useSearchFilters(
    {
      associatedCompanyId: null,
      associatedCompanyIds: null,
      associatedCompanyLabels: null,
      siteCertificate: null,
      siteCountry: null,
      productCategory: undefined,
      siteStatus: ["active"],
      siteActive: ["true"],
      checked: false,
      responsibilityId: "",
      userId: "",
    },
    {
      clearedFilters: {
        associatedCompanyId: null,
        associatedCompanyIds: null,
        associatedCompanyLabels: null,
        siteCertificate: null,
        siteCountry: null,
        productCategory: undefined,
        siteStatus: [],
        siteActive: [],
        checked: false,
        responsibilityId: "",
        userId: "",
      },
      resetPageNumber,
      setSelectedRowKeys,
      setAllSelected,
      webStorage: sitesRead
        ? isRetailer
          ? SearchFiltersStorage.RETAILER_SITE
          : SearchFiltersStorage.SUPPLIER_SITE
        : undefined,
    }
  );

  const isCountriesLoading = useSelector(selectCountriesData).isLoading;
  const countriesMap = useSelector(selectCountriesMap);
  const isSiteTableLoading = useSelector(selectIsSiteTableLoading);
  const isLoading = sitesRead && (isSiteTableLoading || isCountriesLoading);

  const refetchSites = useCallback(() => {
    const { skip, take } = paginationQuery;
    const {
      siteCountry,
      associatedCompanyId,
      associatedCompanyIds,
      productCategory,
      siteCertificate,
      siteActive,
      checked,
      userId,
      responsibilityId,
    } = filterObj;

    const filters = {
      siteCountry,
      ...productCategory,
    };

    if (siteActive.length && siteActive.length < 2) {
      filters.siteActive = siteActive.includes("true");
    }

    dispatch(
      fetchSites({
        associatedCompanyIds,
        ownerCompanyId,
        associatedCompanyId,
        isRetailer,
        skip,
        take,
        searchText,
        ...filters,
        OnlyWithoutContacts: checked,
        ContactUserId: userId,
        ContactResponsibilityId: responsibilityId,
        SiteWithNoCertificate:
          siteCertificate === "SiteWithNoCertificate" ? true : undefined,
        SiteWithCertificateExpire:
          siteCertificate === "SiteWithCertificateExpire" ? true : undefined,
        SiteWithCertificateNoEndDate:
          siteCertificate === "SiteWithCertificateNoEndDate" ? true : undefined,
      })
    );
  }, [
    searchText,
    filterObj,
    ownerCompanyId,
    isRetailer,
    paginationQuery,
    languageCode,
    dispatch,
  ]);

  useEffect(() => {
    const associatedCompanyId = supplierId;
    const associatedCompanyLabel = supplierName;
    if (supplierId) {
      mergeFilters({
        associatedCompanyId: null,
        associatedCompanyIds: associatedCompanyId
          ? [associatedCompanyId]
          : null,
        associatedCompanyLabels: associatedCompanyLabel
          ? [associatedCompanyLabel]
          : null,
        siteCertificate: null,
        siteCountry: null,
        productCategory: undefined,
        siteStatus: [],
        siteActive: ["true"],
        checked: false,
        responsibilityId: "",
        userId: "",
      });
    }
    return () => {
      dispatch(setSupplierId(null));
      dispatch(setSupplierName(null));
    };
  }, [supplierId]);

  useEffect(() => {
    dispatch(
      fetchRelatedCompanies({
        companyId: ownerCompanyId,
        isPaginate: true,
      })
    );
    dispatch(fetchCompaniesSupplier({ ownerCompanyId }));
    dispatch(fetchAllCompaniesSupplier({ ownerCompanyId }));
  }, [ownerCompanyId]);

  useEffect(() => {
    if (sitesRead) {
      refetchSites();
    } else {
      dispatch(clearSites(null));
    }
  }, [sitesRead, refetchSites]);

  useEffect(() => {
    if (selectedKpi) {
      removeAllFiltersAndResetPageNumber();
      setSearchText("");
      let siteCertificate;
      switch (selectedKpi) {
        case "missingCertificates":
          siteCertificate = "SiteWithNoCertificate";
          break;
        case "expiringCertificates":
          siteCertificate = "SiteWithCertificateExpire";
          break;
        case "undatedCertificates":
          siteCertificate = "SiteWithCertificateNoEndDate";
      }
      mergeFilters({
        siteCertificate,
        siteActive: ["true"],
      });
    }
  }, [selectedKpi]);

  useEffect(() => {
    if (!!selectedRowKeys.length || allSelected) setShowActionBanner(true);
    else setShowActionBanner(false);
    if (
      !!selectedRowKeys.length &&
      selectedRowKeys.length === skipAndTakeQueryStats?.totalCount
    ) {
      setAllSelected(prev => !prev);
      setSelectedRowKeys([]);
    }
  }, [selectedRowKeys, allSelected, skipAndTakeQueryStats]);

  const handleSiteRelationModal = async record => {
    const contacts = await getUsersCollectionMap(
      record.siteRelationContacts?.map(c => c.userId)
    );
    let newContacts = [];
    record.siteRelationContacts?.forEach(p => {
      newContacts.push({
        ...newContacts,
        ...contacts[p.userId],
        responsibilityId: p.responsibilityId,
      });
    });
    setContacts({
      itemName: record.siteName,
      contacts: newContacts,
    });
    setOpenModel(true);
  };

  const contactColumn = [
    {
      title: intl.formatMessage({ id: "productDetails.contactNameText" }),
      dataIndex: "contactName",
      render: contactName => {
        return <>{contactName ? contactName : "-"}</>;
      },
    },
    {
      title: intl.formatMessage({ id: "productListPage.modal.table.header" }),
      dataIndex: "responsibility",
    },
    {
      title: intl.formatMessage({ id: "general.status" }),
      dataIndex: "status",

      render: record => {
        let lable = intl.formatMessage({ id: "general.inactive" });
        let color = "grey";
        if (record === UserStatus.ENABLED) {
          lable = intl.formatMessage({ id: "general.active" });
          color = "green";
        }

        return (
          <>
            <Tag label={lable} color={color} mode="light" />
          </>
        );
      },
    },
  ];

  const { columns, data } = useSiteTable({
    sites,
    countriesMap,
    companiesMap,
    isRetailer,
    refetchSites,
    handleSiteRelationModal,
  });

  return (
    <>
      {sitesRead &&
        (isRetailer ? (
          <SiteRetailerSearch
            isRetailer={isRetailer}
            setSelectedKpi={setSelectedKpi}
            onSearch={setSearchText}
            filterObj={filterObj}
            initialSearchValue={searchText}
            mergeFilters={mergeFilters}
            onClearFiltersClick={removeAllFiltersAndResetPageNumber}
          />
        ) : (
          <SiteSearch
            isRetailer={isRetailer}
            setSelectedKpi={setSelectedKpi}
            onSearch={setSearchText}
            filterObj={filterObj}
            initialSearchValue={searchText}
            mergeFilters={mergeFilters}
            onClearFiltersClick={removeAllFiltersAndResetPageNumber}
          />
        ))}
      <SiteHeader
        listingResult={
          <Heading style={{ color: "#4e4e4e" }} size="xxs">
            <div className={styles.tableHeading}>
              {intl.formatMessage(
                { id: "sitesListPage.table.listingResultNumber" },
                {
                  current: skipAndTakeQueryStats.currentCount ?? 0,
                  total: skipAndTakeQueryStats.totalCount ?? 0,
                }
              )}
            </div>
          </Heading>
        }
        sitesRead={sitesRead}
        selectedRowKeys={selectedRowKeys}
        ownerCompanyId={ownerCompanyId}
        filterObj={filterObj}
        searchText={searchText}
        isAllSelected={allSelected}
      />
      {showActionBanner && (
        <ActionBanner
          message={
            allSelected
              ? intl.formatMessage(
                  { id: "sitesListPage.table.allSelected" },
                  { selectedCount: skipAndTakeQueryStats.totalCount ?? 0 }
                )
              : intl.formatMessage(
                  {
                    id:
                      selectedRowKeys.length === 1
                        ? "sitesListPage.table.oneSelected"
                        : "sitesListPage.table.selectedSites",
                  },
                  { selectedCount: selectedRowKeys.length }
                )
          }
          data-test-id="selectedSites-banner"
          link={{
            name: allSelected
              ? intl.formatMessage({ id: "sitesListPage.table.clearAll" })
              : intl.formatMessage(
                  { id: "sitesListPage.table.selectAllSites" },
                  { allCount: skipAndTakeQueryStats.totalCount ?? 0 }
                ),
            onClick: () => {
              setAllSelected(prev => !prev);
              setSelectedRowKeys([]);
            },
          }}
          type="info"
          closable={!allSelected}
          showIcon={true}
          onClose={() => setShowActionBanner(false)}
        />
      )}
      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        skip={paginationQuery.skip}
        take={paginationQuery.take}
        skipAndTakeQueryStats={skipAndTakeQueryStats}
        setPaginationQuery={setPaginationQuery}
        rowSelection={{ selectedRowKeys }}
        setSelectedRowKeys={setSelectedRowKeys}
        isAllSelected={allSelected}
        showActionBanner={showActionBanner}
      />
      <ContactsModal
        open={openModel}
        title={"sitesListPage.table.name"}
        contactsData={contactsData}
        contactColumn={contactColumn}
        onCancel={() => {
          setOpenModel(false);
          setContacts(null);
        }}
        onPrimaryButtonClick={() => {
          setOpenModel(false);
          setContacts(null);
        }}
      />
    </>
  );
};

SiteTable.propTypes = {
  sitesRead: PropTypes.bool.isRequired,
};

export default memo(SiteTable);
