import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { compose } from "@reduxjs/toolkit";

import { useAppDispatch } from "reduxStore";
import { fetchSupplierForm } from "reduxStore/supplierForm/asyncActions";
import { selectSupplierForm } from "reduxStore/supplierForm/selectors";
import { clearSupplierForm } from "reduxStore/supplierForm/slice";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import ErrorPage from "components/ErrorPage";
import Spinner from "components/Spinner";
import { TeamMemberResponsibilityParentItem } from "shared/constants";
import {
  BaseHocProps,
  withCategoryLists,
  withCountries,
  withTeamMemberResponsibilities,
} from "shared/hocs";

import SupplierForm from "./SupplierForm";

interface SupplierEditProps extends BaseHocProps {
  isReadOnly?: boolean;
}

const enhance = compose<React.FC<SupplierEditProps>>(
  withCountries(),
  withCategoryLists(),
  withTeamMemberResponsibilities({
    includeParentItems: true,
    parentItemId: TeamMemberResponsibilityParentItem.RETAILER,
  })
);

export const SupplierEdit: React.FC<SupplierEditProps> = ({
  isInitiated,
  isReadOnly,
}) => {
  const dispatch = useAppDispatch();
  const { supplierId } = useParams<{ supplierId?: string }>();
  const [isLoading, setIsLoading] = useState(true);

  const ownerCompanyId = useSelector(selectUserOwningCompanyId);

  const { company, companyRelation, hasError, errorStatus } =
    useSelector(selectSupplierForm);

  useEffect(() => {
    setIsLoading(true);
    (dispatch(fetchSupplierForm({ supplierId, ownerCompanyId })) as any).then(
      () => {
        setIsLoading(false);
      }
    );

    return () => {
      dispatch(clearSupplierForm());
    };
  }, [supplierId, ownerCompanyId]);

  if (hasError) {
    return <ErrorPage status={errorStatus} />;
  }

  if (!isInitiated || isLoading) {
    return <Spinner underHeader />;
  }

  return (
    <SupplierForm
      company={company}
      companyRelation={companyRelation}
      isReadOnly={isReadOnly}
    />
  );
};

export default enhance(SupplierEdit);
