import { Col, Row } from "antd";
import { PageCol } from "pages/Sites/constants";

import Panel from "components/Panel";

import CompanyRelation from "./CompanyRelation";
import CustomAttributes from "./CustomAttributes";

interface PrivateInformationProps {}

const PrivateInformation: React.FC<PrivateInformationProps> = () => {
  return (
    <>
      <Col {...PageCol.FULL}>
        <Panel>
          <Row>
            <Col {...PageCol.HALF}>
              <CompanyRelation />
            </Col>
            <Col {...PageCol.HALF}>
              <CustomAttributes />
            </Col>
          </Row>
        </Panel>
      </Col>
      {/* <Col {...PageCol.FULL} style={{ display: "flex" }}>
        <CustomAttributes />
      </Col> */}
    </>
  );
};

export default PrivateInformation;
