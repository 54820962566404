import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import {
  selectUserCompanyActivityNameInEng,
  selectUserJobTitle,
  selectUserLogin,
  selectUserOwningCompanyName,
} from "reduxStore/user/selectors";

import { LegalMonsterData } from "./models";

enum UserProperty {
  MAIL = "User mail",
  JOB_TITLE = "User job title",
  COMPANY_NAME = "Company name",
  COMPANY_ACTIVITY_NAME = "Company activity",
}

export default function useHeapAnalytic(
  legalMonsterRef: React.MutableRefObject<LegalMonsterData>
) {
  const userLogin = useSelector(selectUserLogin);
  const userJobTitle = useSelector(selectUserJobTitle);
  const userCompanyName = useSelector(selectUserOwningCompanyName);
  const userCompanyActivityName = useSelector(
    selectUserCompanyActivityNameInEng
  );

  const handleEnableHeapAnalyticRef = useRef<() => void>();

  useEffect(() => {
    const addUserProperties = () => {
      window.heap.addUserProperties({
        [UserProperty.MAIL]: userLogin,
        [UserProperty.JOB_TITLE]: userJobTitle,
        [UserProperty.COMPANY_NAME]: userCompanyName,
        [UserProperty.COMPANY_ACTIVITY_NAME]: userCompanyActivityName,
      });
    };

    handleEnableHeapAnalyticRef.current = () => {
      if (!window.heap) {
        return;
      }

      const isHeapInitiated = typeof window.heap?.loaded === "boolean";
      if (isHeapInitiated) {
        window.heap.loaded = true;
      } else {
        window.heap.load(window.env.HEAP_APP_ID);
        window.heap.identify(legalMonsterRef.current.userId);
        addUserProperties();
      }
    };

    if (window.heap?.loaded) {
      addUserProperties();
    }
  }, [userLogin, userJobTitle, userCompanyName, userCompanyActivityName]);

  useEffect(() => {
    const handleEnableHeapAnalytic = () => {
      handleEnableHeapAnalyticRef.current();
    };

    const handleDisableHeapAnalytic = () => {
      const isHeapInitiated = typeof window.heap?.loaded === "boolean";
      if (isHeapInitiated) {
        window.heap.loaded = false;
      }
    };

    window.addEventListener(
      "legalmonster.cookie.analytics.accepted",
      handleEnableHeapAnalytic
    );
    window.addEventListener(
      "legalmonster.cookie.analytics.rejected",
      handleDisableHeapAnalytic
    );

    return () => {
      window.removeEventListener(
        "legalmonster.cookie.analytics.accepted",
        handleEnableHeapAnalytic
      );
      window.removeEventListener(
        "legalmonster.cookie.analytics.rejected",
        handleDisableHeapAnalytic
      );
    };
  }, []);
}
