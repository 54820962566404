import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectProductForm = (state: RootState) => state.productForm;

export const selectProductFormData = createSelector(
  _selectProductForm,
  ({
    isProductLoading,
    productData,
    hasProductError,
    errorStatus,
    productSelectedSupplier,
  }) => ({
    isProductLoading,
    productData,
    hasProductError,
    errorStatus,
    productSelectedSupplier,
  })
);
