import React from "react";
import { Route, RouteProps } from "react-router-dom";

import PropTypes from "prop-types";

import ErrorPage from "components/ErrorPage";

interface AuthorizedRouteProps extends RouteProps {
  canActive: boolean;
}

const AuthorizedRoute: React.FC<AuthorizedRouteProps> = ({
  canActive,
  ...rest
}) => {
  if (!canActive) {
    return <ErrorPage status={403} />;
  }
  return <Route {...rest} />;
};

AuthorizedRoute.propTypes = {
  canActive: PropTypes.bool,
};

export default AuthorizedRoute;
