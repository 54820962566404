/* istanbul ignore file */
import { IntlShape } from "react-intl";

import moment from "moment";
import { DaysToExpiredCertificates } from "pages/Sites/constants";

type CertificateStatus = {
  color: string;
  isNone?: boolean;
  label: string;
};

interface GetCertificateStatus {
  date: moment.Moment;
  intl: IntlShape;
}

const getCertificateStatus = ({ date, intl }: GetCertificateStatus) => {
  const today = moment();

  let certificateStatus: CertificateStatus = {
    color: "grey",
    isNone: true,
    label: intl.formatMessage({ id: "siteForm.certificateStatusNone" }),
  };

  if (date) {
    if (date.isSameOrBefore(today, "day")) {
      certificateStatus = {
        color: "grey",
        label: intl.formatMessage({
          id: "siteForm.certificateStatusInactive",
        }),
      };
    } else if (
      date.isBetween(
        today,
        moment().add(DaysToExpiredCertificates, "day"),
        "day",
        "[]"
      )
    ) {
      certificateStatus = {
        color: "orange",
        label: intl.formatMessage({
          id: "siteForm.certificateStatusExpiredSoon",
        }),
      };
    } else {
      certificateStatus = {
        color: "green",
        label: intl.formatMessage({ id: "siteForm.certificateStatusActive" }),
      };
    }
  }

  return certificateStatus;
};

export default getCertificateStatus;
