import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Heading, Paragraph, Tooltip } from "@trace-one/design-system";

import usePermissions from "core/oidc/usePermissions";

import { selectSupplierDetails } from "reduxStore/SupplierDetails/selectors";

import Label from "components/Label";
import TabPage from "components/TabPage";

import styles from "./customAttributes.module.less";

interface CustomAttributesProps {}

const CategoryLine = ({ category }) => {
  return (
    <div>
      {category.parent4CategoryItemName &&
        `${category.parent4CategoryItemName} > `}
      {category.parent3CategoryItemName &&
        `${category.parent3CategoryItemName} > `}
      {category.parent2CategoryItemName &&
        `${category.parent2CategoryItemName} > `}
      {category.parent1CategoryItemName &&
        `${category.parent1CategoryItemName} > `}
      {category.categoryItemName}
    </div>
  );
};

const CustomAttributes: React.FC<CustomAttributesProps> = () => {
  const intl = useIntl();

  const { companyRelation } = useSelector(selectSupplierDetails);
  const privateAttribute = companyRelation?.retailerCustomizedAttributes;

  const { company } = useSelector(selectSupplierDetails);
  const history = useHistory();
  const { companyRelationsWrite } = usePermissions();

  return (
    <div className={styles.mainContainer}>
      <div style={{ width: "100%" }}>
        {companyRelationsWrite && (
          <Button
            type="link"
            className={styles.iconBtn}
            data-test-id="edit-supplier-privateAttributes"
            iconName="edit"
            iconPlacement="left"
            onClick={() => {
              history.push(`/suppliers/edit/${company.companyId}`);
            }}
          >
            {intl.formatMessage({ id: "general.edit" })}
          </Button>
        )}
        <TabPage.SecondaryTitle
          label={
            <Heading size="xs">
              {intl.formatMessage({ id: "supplierDetails.privateAttribute" })}
            </Heading>
          }
        />
        <Label
          label={
            <Heading size="xxs">
              {intl.formatMessage({
                id: "supplierDetails.internalName",
              })}
            </Heading>
          }
          testId="supplierDetails-internalName"
        >
          <Paragraph size="m">
            <Tooltip
              showFullText
              text={companyRelation.externalName}
              placement="top"
            >
              <div className={styles.supplierDetailsParagraph}>
                {companyRelation.externalName}
              </div>
            </Tooltip>
          </Paragraph>
        </Label>

        <Label
          label={
            <Heading size="xxs">
              {intl.formatMessage({
                id: "supplierDetails.internalCode",
              })}
            </Heading>
          }
          testId="supplierDetails-internalCode"
        >
          <Paragraph size="m">{companyRelation.externalIdentifier}</Paragraph>
        </Label>

        <Label
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "general.productCategory" })}{" "}
            </Heading>
          }
          testId="supplierDetails-categories"
        >
          <Paragraph size="m">
            {companyRelation.categoriesList.map(category => (
              <CategoryLine category={category} key={category.categoryItemId} />
            ))}
          </Paragraph>
        </Label>

        {!!privateAttribute.length &&
          privateAttribute.map((ele, index) => (
            <Label
              label={
                <Heading size="xxs">
                  <Tooltip
                    showFullText
                    text={ele.retailerLabel}
                    placement="top"
                  >
                    <div className={styles.supplierDetailsParagraph}>
                      {ele.retailerLabel}
                    </div>
                  </Tooltip>
                </Heading>
              }
              testId="supplierDetails-privateAttributes"
              key={ele.retailerLabel}
            >
              <Paragraph size="m">
                <Tooltip showFullText text={ele.retailerCode} placement="top">
                  <div className={styles.supplierDetailsParagraph}>
                    {ele.retailerCode}
                  </div>
                </Tooltip>
              </Paragraph>
            </Label>
          ))}
      </div>
    </div>
  );
};

export default CustomAttributes;
