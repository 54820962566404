import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { Heading, TagV1 as Tag } from "@trace-one/design-system";
import moment from "moment";
import { DaysToExpiredCertificates, SectionsIds } from "pages/Sites/constants";

import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import { selectIsRetailer } from "reduxStore/oidc/selectors";
import { fetchRetailerSite } from "reduxStore/siteRetailerDetails/asyncActions";
import {
  selectRetailerSite,
  selectRetailerSiteIsLoading,
  selectRetailerSiteHasErrors,
  selectRetailerSiteErrorStatus,
  selectRetailerSiteInitiated,
  selectRetailerRelation,
  selectRetailerSupplier,
  selectRetailerCompanyRelation,
} from "reduxStore/siteRetailerDetails/selectors";
import { clearSiteRetailerDetails } from "reduxStore/siteRetailerDetails/slice";
import {
  selectUserLanguageCode,
  selectUserOwningCompanyId,
} from "reduxStore/user/selectors";

import ErrorPage from "components/ErrorPage";
import HeaderDetails from "components/HeaderDetails";
import Spinner from "components/Spinner";
import { CompanyType } from "shared/constants";

import {
  CommentsTab,
  CertificationsTab,
  SiteInformationsTab,
} from "../../components/SiteRtailerDetails";
import withSiteShared, { SiteSharedProps } from "../../hocs/withSiteShared";

import styles from "./SiteRetailerDetails.module.less";

interface SiteRetailerEditProps extends SiteSharedProps {}

export const HeaderSubtitle = (companyRelation, siteName, companyType) => {
  let subtitle: string[] = [];
  if (companyRelation.externalIdentifier && companyRelation.externalName) {
    subtitle = [
      `${companyRelation.externalName} (${companyRelation.externalIdentifier})`,
      `${siteName}`,
    ];
  } else if (companyRelation.externalIdentifier) {
    subtitle = [`${siteName} (${companyRelation.externalIdentifier})`];
  } else if (companyRelation.externalName) {
    subtitle = [`${companyRelation.externalName}`, `${siteName}`];
  } else {
    subtitle = [`${siteName}`];
  }
  return (
    <span
      className={
        companyType === CompanyType.PRIVATE && styles.privateCompanyTitle
      }
    >
      {subtitle.filter(item => item).join(", ")}
    </span>
  );
};

const SiteRetailerDetails: React.FC<SiteRetailerEditProps> = ({
  isSiteSharedInitiated,
}) => {
  const { siteId } = useParams<{ siteId?: string }>();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const today = moment();

  const site = useSelector(selectRetailerSite);
  const isLoading = useSelector(selectRetailerSiteIsLoading);
  const hasErrors = useSelector(selectRetailerSiteHasErrors);
  const errorStatus = useSelector(selectRetailerSiteErrorStatus);
  const associatedCompanyId = useSelector(selectUserOwningCompanyId);
  const siteInitiated = useSelector(selectRetailerSiteInitiated);
  const relation = useSelector(selectRetailerRelation);
  const languageCode = useSelector(selectUserLanguageCode);
  const { siteRelationsWrite } = usePermissions();
  const isRetailer = useSelector(selectIsRetailer);
  // const hasComments = !!relation?.comments.length;
  const supplier = useSelector(selectRetailerSupplier);
  const companyRelation = useSelector(selectRetailerCompanyRelation);

  useEffect(() => {
    dispatch(fetchRetailerSite({ siteId, associatedCompanyId, languageCode }));
    return () => {
      dispatch(clearSiteRetailerDetails());
    };
  }, [siteId]);

  const buttonList = [
    {
      text: "general.actions",
      showBtn: true,
      testId: "site-actions",
      tab: 0,
      actionList: [
        {
          name: "general.edit",
          "data-test-id": "editSite-button",
          disabled: false,
          onClick: () => history.push(`/sites/edit/${site?.id}`),
          showTooltip: false,
          showBtn: isRetailer && siteRelationsWrite,
        },
        {
          name: "general.addComment",
          "data-test-id": "editComments-button",
          disabled: false,
          onClick: () =>
            history.push(`/sites/edit/${site?.id}#${SectionsIds.COMMENTS}`),
          showTooltip: false,
          showBtn: isRetailer && siteRelationsWrite,
        },
        {
          name: "siteDetails.siteSupplierInformation",
          "data-test-id": "seeSupplier-button",
          disabled: false,
          onClick: () => history.push(`/suppliers/${supplier?.companyId}`),
          showTooltip: false,
          showBtn: isRetailer,
        },
        {
          name: "general.viewVersionsHistory",
          "data-test-id": "viewVersionsHistory-button",
          disabled: false,
          onClick: () => history.push(`/sites/history/${siteId}`),
          showTooltip: false,
          showBtn: isRetailer,
        },
      ],
    },
  ];

  const tabList = [
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "siteDetails.siteInformation" })}{" "}
        </Heading>
      ),
      content: <SiteInformationsTab />,
    },

    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "general.classificates" })}{" "}
        </Heading>
      ),
      count: site?.siteCertificates?.filter(certif => {
        return (
          certif.certificateEndAvailabilityDate &&
          moment(certif.certificateEndAvailabilityDate).isBetween(
            today,
            moment().add(DaysToExpiredCertificates, "day"),
            "day",
            "[]"
          )
        );
      }).length,
      content: <CertificationsTab />,
    },
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "general.comments" })}{" "}
        </Heading>
      ),
      count: 0,
      content: <CommentsTab />,
    },
  ];

  const SiteStatusTag = () => {
    const statusTagData = relation?.ownerCompanyRelationStatus
      ? {
          label: intl.formatMessage({ id: "general.active" }),
          color: "green",
        }
      : {
          label: intl.formatMessage({ id: "general.inactive" }),
          color: "grey",
        };
    return <Tag mode="light" {...statusTagData} />;
  };

  if (isLoading || !isSiteSharedInitiated || !siteInitiated)
    return <Spinner underHeader />;
  if (hasErrors) return <ErrorPage status={errorStatus} />;

  return (
    <div>
      <HeaderDetails
        withBackNav
        title={site?.siteName}
        subtitle={HeaderSubtitle(
          companyRelation,
          site.siteOwnerCompanyName,
          supplier.companyType
        )}
        status={<SiteStatusTag />}
        tabs={tabList}
        headerIcon="factory"
        buttons={buttonList}
        haveActionItems
      />
    </div>
  );
};

export default withSiteShared(SiteRetailerDetails);
