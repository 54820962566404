export default function scrollTo({ top, left, behavior }: ScrollToOptions) {
  const isOptionsScrollSupported =
    "scrollBehavior" in document.documentElement.style;

  if (isOptionsScrollSupported) {
    window.scroll({ top, left, behavior });
  } else {
    const x = left ?? window.pageXOffset;
    const y = top ?? window.pageYOffset;
    window.scrollTo(x, y);
  }
}
