import {
  getUserHasNotResponsabilityError,
  getUserInactiveError,
} from "components/ContactsInputGroup";
import { ErrorCode, hasErrorOccurred } from "shared/errors";

export default function getFailedSubmitResult({
  error,
  setActiveTab,
}: {
  error: any;
  setActiveTab: (value: number) => void;
}) {
  if (
    hasErrorOccurred({
      error,
      errorCode: ErrorCode.USER_WITH_NO_RESPONSABILITY,
    })
  ) {
    const result = getUserHasNotResponsabilityError(
      error?.response?.data?.error
    );
    const contactWithoutRes = result.contacts.withErrors[0];
    document
      .querySelector(
        `[data-key="contact-${contactWithoutRes.responsibilityId}-${contactWithoutRes.userId}"]`
      )
      ?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });

    return result;
  }

  if (hasErrorOccurred({ error, errorCode: ErrorCode.USER_NOT_ACTIVE })) {
    setActiveTab(0);
    const result = getUserInactiveError(error?.response?.data?.error);

    setTimeout(() => {
      document
        .querySelector("#md-form-contact-responsibility")
        ?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
    }, 10);
    return result;
  }
}
