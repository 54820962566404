import { useSelector } from "react-redux";

import { DeliveryAddressModel } from "pages/Suppliers/constants";

import { CompanyData } from "models";

import { selectCountriesOptions } from "reduxStore/shared/selectors";

import { GOOGLEMAP_QUERY_URL } from "shared/constants";

export default function useSupplierDeliveryAddress(company: CompanyData) {
  const countries = useSelector(selectCountriesOptions);

  const checkedParamForAddress = [
    DeliveryAddressModel.ADDRESS_1,
    DeliveryAddressModel.ADDRESS_2,
    DeliveryAddressModel.CITY,
    DeliveryAddressModel.POSTAL_CODE,
    DeliveryAddressModel.COUNTRY_CODE,
  ];
  const checkedForGmapLink = [
    DeliveryAddressModel.ADDRESS_1,
    DeliveryAddressModel.POSTAL_CODE,
    DeliveryAddressModel.COUNTRY_CODE,
  ];

  const showDeliveryAddressGmapLink = checkedForGmapLink.every(
    x => company.hasOwnProperty(x) && company[x] !== "" && company[x] !== null
  );
  const companyHasAddress =
    checkedParamForAddress.every(
      x => company[x] === "" || company[x] === null
    ) === false;

  const addressData = [
    ...(company[DeliveryAddressModel.ADDRESS_1]
      ? [company[DeliveryAddressModel.ADDRESS_1]]
      : []),
    ...(company[DeliveryAddressModel.ADDRESS_2]
      ? [company[DeliveryAddressModel.ADDRESS_2]]
      : []),
    ...(company[DeliveryAddressModel.CITY]
      ? [company[DeliveryAddressModel.CITY]]
      : []),
    ...(company[DeliveryAddressModel.POSTAL_CODE]
      ? [company[DeliveryAddressModel.POSTAL_CODE]]
      : []),
    ...(company[DeliveryAddressModel.COUNTRY_CODE]
      ? [
          countries.find(
            c => c.value === company[DeliveryAddressModel.COUNTRY_CODE]
          )?.name,
        ]
      : []),
  ];

  return {
    hasDeliveryAddress: companyHasAddress,
    deliveryAddressData: companyHasAddress ? addressData : [],
    showDeliveryAddressGmapLink,
    deliveryAddressGmapLink: showDeliveryAddressGmapLink
      ? `${GOOGLEMAP_QUERY_URL}${addressData.join(",")}`
      : "",
  };
}
