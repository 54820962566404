import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "reduxStore";
import { fetchCountries } from "reduxStore/shared/asyncActions";
import { selectCountriesData } from "reduxStore/shared/selectors";
import { selectUserLanguageCode } from "reduxStore/user/selectors";

import getDisplayName from "shared/utils/getDisplayName";

import { BaseHocProps, renderHocMiddleware } from "../_helpers";

const withCountries =
  () =>
  <P extends BaseHocProps>(WrappedComponent: React.ComponentType<P>) => {
    const Component: React.FC<P> = props => {
      const dispatch = useAppDispatch();
      const languageCode = useSelector(selectUserLanguageCode);

      const { isInitiated, hasError, isLoading } =
        useSelector(selectCountriesData);

      useEffect(() => {
        dispatch(fetchCountries({ languageCode }));
      }, [languageCode]);

      return renderHocMiddleware({
        WrappedComponent,
        _hasError: hasError,
        _isInitiated: isInitiated,
        _isLoading: isLoading,
        ...props,
      });
    };

    Component.displayName = getDisplayName(withCountries, WrappedComponent);

    return Component;
  };

export default withCountries;
