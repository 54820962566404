import { CategoryItemData } from "models";

type State = {
  classificationChildList: CategoryItemData[];
  isClassificationChildLoading: boolean;
  hasClassificationChildError: boolean;
  classificationErrorStatus: any;
  selectedLanguage: string;
  isEditabaleData: boolean;
};

export const initialState: State = {
  classificationChildList: [],
  isClassificationChildLoading: false,
  hasClassificationChildError: false,
  classificationErrorStatus: null,
  selectedLanguage: "",
  isEditabaleData: true,
};
