import { useEffect, useState } from "react";

import { RlmdAPI } from "apis";

import useDebounce from "shared/hooks/useDebounce";
import useToast from "shared/hooks/useToast";

import mapToCategoryItemsTD from "../utils/mapToCategoryItemsTD";

export default function useCategoryItemsByName({
  minLengthToAsyncSearch,
  searchItemName,
  ownerCompanyId,
  languageCode,
  categoryItemsTD,
  setTreeExpandedKeys,
}) {
  const toast = useToast();

  const [categoryItemsByNameTD, setCategoryItemsByNameTD] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearchItemName = useDebounce(searchItemName, 500);

  useEffect(() => {
    setCategoryItemsByNameTD([]);
    if (debouncedSearchItemName?.trim().length >= minLengthToAsyncSearch) {
      setIsLoading(true);
      RlmdAPI.getCategoryItemsByFilters({
        itemName: debouncedSearchItemName,
        ownerCompanyId,
        languageCode,
      })
        .then(({ data }) => {
          const { result, expandedKeys } = mapToCategoryItemsTD(data, {
            shouldFindExistedClosestParent: true,
            existedCategoryItems: categoryItemsTD,
          });

          setCategoryItemsByNameTD(result);
          setTreeExpandedKeys(expandedKeys);
          setIsLoading(false);
        })
        .catch(error => {
          toast.fetchError({ error });
          setIsLoading(false);
        });
    }

    return () => {
      setIsLoading(false);
      setCategoryItemsByNameTD([]);
    };
  }, [debouncedSearchItemName, languageCode]);

  return {
    categoryItemsByNameTD,
    isLoading,
  };
}
