import { memo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading } from "@trace-one/design-system";
import { Form } from "antd";

import { selectCountriesOptions } from "reduxStore/shared/selectors";

import FormWrapper from "components/FormWrapper";
import Input from "components/Input";
import Select from "components/Select";

import { SectionsIds } from "../../../../constants";

const AddressSection: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const intl = useIntl();
  const countries = useSelector(selectCountriesOptions);

  return (
    <FormWrapper.Section
      title={
        <Heading size="m">
          {" "}
          {intl.formatMessage({ id: "siteForm.addressTitle" })}
        </Heading>
      }
      id={SectionsIds.ADDRESS}
    >
      <Form.Item
        name="siteAddress1"
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "general.address" })}{" "}
          </Heading>
        }
        shouldUpdate
        className="input1"
      >
        <Input
          disabled={disabled}
          maxLength={256}
          data-test-id="md-site-siteAddress1"
          className="input2"
          style={{ width: "762px" }}
        />
      </Form.Item>

      <Form.Item
        name="siteAddress2"
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "siteForm.address2" })}{" "}
          </Heading>
        }
        shouldUpdate
      >
        <Input
          disabled={disabled}
          maxLength={256}
          data-test-id="md-site-siteAddress2"
          style={{ width: "762px" }}
        />
      </Form.Item>

      <div style={{ display: "flex", gap: "0px", width: "1166px" }}>
        <Form.Item
          name="siteCity"
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "general.city" })}{" "}
            </Heading>
          }
          shouldUpdate
          className="sitePhone phonew1"
        >
          <Input
            disabled={disabled}
            maxLength={256}
            data-test-id="md-site-city"
            style={{ width: "300px" }}
          />
        </Form.Item>

        <Form.Item
          name="sitePostalCode"
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "general.postalCode" })}
            </Heading>
          }
          shouldUpdate
          className="siteFax faxw1"
        >
          <Input
            disabled={disabled}
            maxLength={256}
            data-test-id="md-site-postalcode"
            style={{ width: "300px" }}
          />
        </Form.Item>
      </div>

      <div style={{ display: "flex", gap: "0px", width: "1166px" }}>
        <Form.Item
          name="siteRegion"
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "general.regionState" })}{" "}
            </Heading>
          }
          shouldUpdate
          className="sitePhone phonew1"
        >
          <Input
            disabled={disabled}
            maxLength={256}
            data-test-id="md-site-region"
            style={{ width: "300px" }}
          />
        </Form.Item>

        <Form.Item
          name="siteCountry"
          label={
            <Heading size="xxs">
              {" "}
              {intl.formatMessage({ id: "general.country" })}
            </Heading>
          }
          shouldUpdate
          className="siteFax faxw1"
        >
          <Select
            showSearch
            allowClear
            disabled={disabled}
            data-test-id="md-site-country"
            style={{ height: 42, width: "300px" }}
            options={countries}
            filterOption={(input, option) =>
              (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
      </div>
    </FormWrapper.Section>
  );
};

export default memo(AddressSection);
