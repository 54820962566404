import { IntlShape } from "react-intl";

import moment from "moment";

type CertificateStatus = {
  color: string;
  isNone?: boolean;
  label: string;
};

interface GetCertificateSort {
  certificates: {
    key: number;
    certificateName: string;
    certificateType: string;
    certificateStatus: CertificateStatus;
    certificateFiles: {
      fileId: string;
      fileUrl: string;
      fileName: string;
      fileSize: string;
    }[];
    certificateEndDate: moment.Moment;
    certificateKeyId: string;
  }[];
  intl: IntlShape;
}
const getCertificateSort = ({ certificates, intl }: GetCertificateSort) => {
  let length = certificates.length;
  for (let i = 0; i < length; i++) {
    for (let j = 0; j < length - 1; j++) {
      if (
        certificates[j].certificateType === certificates[j + 1].certificateType
      ) {
        if (
          certificates[j].certificateStatus.label ===
          certificates[j + 1].certificateStatus.label
        ) {
          if (
            certificates[j].certificateEndDate >
            certificates[j + 1].certificateEndDate
          ) {
            let tmp = certificates[j];
            certificates[j] = certificates[j + 1];
            certificates[j + 1] = tmp;
          }
        } else {
          const inactive = intl.formatMessage({
            id: "siteForm.certificateStatusInactive",
          });
          const active = intl.formatMessage({
            id: "siteForm.certificateStatusActive",
          });
          const none = intl.formatMessage({
            id: "siteForm.certificateStatusNone",
          });
          const expire = intl.formatMessage({
            id: "siteForm.certificateStatusExpiredSoon",
          });
          const statusData = [active, expire, none, inactive];
          const highOrder = statusData.indexOf(
            certificates[j].certificateStatus.label
          );
          const lowOrder = statusData.indexOf(
            certificates[j + 1].certificateStatus.label
          );
          if (highOrder > lowOrder) {
            let tmp = certificates[j];
            certificates[j] = certificates[j + 1];
            certificates[j + 1] = tmp;
          }
        }
      }
    }
  }
  return certificates;
};

export default getCertificateSort;
