import { AxiosPromise } from "axios";
import moment from "moment";

import useAsyncHandler, { Options } from "shared/hooks/useAsyncHandler";
import downloadFile from "shared/utils/downloadFile";

export default function useAsyncFileDownload(
  asyncCallback: (...args: unknown[]) => AxiosPromise,
  {
    filename,
    fileExtension,
    loadingMessage,
    displayLoadingMessage,
    setIsLoading,
  }: Options
) {
  const result = useAsyncHandler(
    async event => {
      const { data } = await asyncCallback(event);

      downloadFile(
        data,
        filename ??
          `ExportFile_${moment().format("DDMMYYYYhhmm")}.${fileExtension}`
      );
    },
    { loadingMessage, displayLoadingMessage, setIsLoading }
  );

  return result;
}
