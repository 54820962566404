import { memo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading } from "@trace-one/design-system";
import { Form } from "antd";

import { SiteData } from "models";

import { selectSiteSupplierFormError } from "reduxStore/siteSupplierDetails/selectors";

import FormWrapper from "components/FormWrapper";
import Input from "components/Input";
import { FormInstanceType } from "shared/hooks/useAntForm";

import { SectionsIds } from "../../../../constants";
import styles from "../SiteSupplierForm.module.less";

import IdentifierSelect from "./IdentifierSelect";

interface IdentificationSectionProps {
  form: FormInstanceType<SiteData>;
  submitResult?: any;
  disabled?: boolean;
  onChange?: (identifierContent: IdentifierContentTypes[]) => void;
}

interface IdentifierContentTypes {
  typeId?: string;
  value?: string;
}

const IdentificationSection: React.FC<IdentificationSectionProps> = ({
  disabled,
  form,
  submitResult,
}) => {
  const intl = useIntl();
  const siteSupplierFormError = useSelector(selectSiteSupplierFormError);
  const formData = form.getFieldsValue();
  const hasError = siteSupplierFormError?.siteName || formData.siteName === "";

  return (
    <div className={styles.root}>
      <FormWrapper.Section
        title={
          <Heading size="m">
            {intl.formatMessage({ id: "siteForm.idTitle" })}
          </Heading>
        }
        id={SectionsIds.IDENTIFICATION}
      >
        <Form.Item
          name="siteName"
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "siteForm.siteName" })}{" "}
            </Heading>
          }
          rules={[
            {
              required: true,
              whitespace: true,
              message: "",
            },
          ]}
          shouldUpdate
        >
          <Input
            disabled={disabled}
            maxLength={256}
            error={hasError}
            errorDataTestId="md-error-siteName-is-required"
            errorMessage={intl.formatMessage({ id: "general.valueIsRequired" })}
            data-test-id="md-site-siteName"
          />
        </Form.Item>

        {/* {history?.location?.pathname !== create ? (
          <Form.Item
            name="siteActive"
            label={
              <Heading size="xxs">
                {intl.formatMessage({ id: "siteForm.active" })}{" "}
              </Heading>
            }
            shouldUpdate
          >
            <Radio.Group disabled={disabled}>
              <Radio
                value={true}
                style={{ marginRight: 30 }}
                data-test-id="md-site-active-yes"
              >
                {intl.formatMessage({ id: "general.yes" })}
              </Radio>
              <Radio value={false} data-test-id="md-site-active-no">
                {intl.formatMessage({ id: "general.no" })}
              </Radio>
            </Radio.Group>
          </Form.Item>
        ) : null} */}

        <Form.Item
          name="internalCode"
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "siteForm.siteInternalCode" })}{" "}
            </Heading>
          }
          shouldUpdate
        >
          <Input
            error={submitResult.internalCode}
            errorDataTestId="md-site-form-internalCode-is-not-unique"
            errorMessage={intl.formatMessage({
              id: "siteForm.siteIdentifier.errorCodeUsed",
            })}
            disabled={disabled}
            maxLength={256}
            data-test-id="md-site-internalCode"
          />
        </Form.Item>

        <Form.Item
          name="identifierCodes"
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "siteForm.siteIdentifier" })}{" "}
            </Heading>
          }
        >
          <IdentifierSelect disabled={disabled} />
        </Form.Item>
      </FormWrapper.Section>
    </div>
  );
};

export default memo(IdentificationSection);
