import { Brand } from "@trace-one/api-clients.pmd";

type State = {
  brands: Brand[];
  brandsSkipAndTakeQueryStats: {
    currentCount?: number;
    totalCount?: number;
  };
  isBrandsLoading: boolean;
  hasBrandsError: boolean;
};

export const initialState: State = {
  brands: [],
  brandsSkipAndTakeQueryStats: {
    currentCount: undefined,
    totalCount: undefined,
  },
  isBrandsLoading: false,
  hasBrandsError: false,
};
