import { Switch } from "react-router-dom";

import usePermissions from "core/oidc/usePermissions";

import AuthorizedRoute from "components/AuthorizedRoute";
import RouteIfNotFound from "components/RouteIfNotFound";

import ClassificationDetails from "./pages/ClassificationDetails";
import ClassificationList from "./pages/ClassificationList";

const ClassificationsRouting: React.FC = () => {
  const { masterdataMyCategories } = usePermissions();
  return (
    <Switch>
      <AuthorizedRoute
        path="/classifications/list"
        canActive={masterdataMyCategories}
        component={ClassificationList}
        exact
      />

      {window.env.SHOW_CLASSIFICATION_DETAIL === "true" && (
        <AuthorizedRoute
          path="/classifications/:classificationId"
          canActive={true}
          component={ClassificationDetails}
          exact
        />
      )}

      <RouteIfNotFound />
    </Switch>
  );
};

export default ClassificationsRouting;
