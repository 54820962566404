import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectProductList = (state: RootState) => state.productList;

export const selectProducts = createSelector(
  _selectProductList,
  ({ products }) => products
);
export const selectProductsSkipAndTakeQueryStats = createSelector(
  _selectProductList,
  ({ productsSkipAndTakeQueryStats }) => productsSkipAndTakeQueryStats
);
export const selectIsProductTableLoading = createSelector(
  _selectProductList,
  ({ isProductsLoading }) => isProductsLoading
);
export const selectIsExportSelectedProductsLoading = createSelector(
  _selectProductList,
  ({ isExportSelectedProductsLoading }) => isExportSelectedProductsLoading
);
export const selectIsExportSelectedManufacturedItemsLoading = createSelector(
  _selectProductList,
  ({ isExportSelectedManufacturedItemsLoading }) =>
    isExportSelectedManufacturedItemsLoading
);
