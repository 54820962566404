import { useEffect, useState } from "react";

import { RlmdAPI } from "apis";

import useToast from "shared/hooks/useToast";

import mapToCategoryItemsTD from "../utils/mapToCategoryItemsTD";

export default function useCategoryItemsByLanguage({
  valueWithLabel,
  onValueWithLabelChange,
  languageCode,
  multiple,
  initialValue = [],
}) {
  const toast = useToast();

  const [categoryItemsTD, setCategoryItemsTD] = useState(initialValue);

  useEffect(() => {
    let valueIdCollection = [];
    if (multiple) {
      valueIdCollection =
        valueWithLabel?.map(({ categoryItemId }) => categoryItemId) ?? [];
    } else if (valueWithLabel?.categoryItemId) {
      valueIdCollection.push(valueWithLabel.categoryItemId);
    }

    if (valueIdCollection.length === 0) {
      return;
    }

    RlmdAPI.getCategoryItems(
      { idCollection: valueIdCollection },
      { languageCode }
    )
      .then(({ data }) => {
        if (multiple) {
          onValueWithLabelChange(
            valueWithLabel?.map(
              ({ categoryItemId, categoryItemName, ...rest }) => ({
                ...rest,
                categoryItemId,
                categoryItemName: data.find(
                  item => item.categoryItemId === categoryItemId
                )?.categoryItemName,
              })
            )
          );
        } else if (data.length === 1) {
          const { categoryId, categoryItemName } = data[0];
          onValueWithLabelChange({
            ...valueWithLabel,
            categoryId,
            categoryItemName,
          });
        }
      })
      .catch(error => {
        toast.fetchError({ error });
      });
  }, [languageCode]);

  useEffect(() => {
    const fetchedIdCollection = categoryItemsTD.map(({ id }) => id);
    if (fetchedIdCollection.length === 0) {
      return;
    }

    RlmdAPI.getCategoryItems(
      { idCollection: fetchedIdCollection },
      { languageCode }
    )
      .then(({ data }) => {
        const { result } = mapToCategoryItemsTD(data);
        setCategoryItemsTD(result);
      })
      .catch(error => {
        toast.fetchError({ error });
      });
  }, [languageCode]);

  return { categoryItemsTD, setCategoryItemsTD };
}
