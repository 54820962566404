import { createSlice } from "@reduxjs/toolkit";

import { fetchSite, fetchSiteRelations } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "siteSupplierForm",
  initialState,
  reducers: {
    clearSiteSupplierForm: () => initialState,
    setSelectedCertificate: (state, action) => {
      state.selectedCertificate = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSite.pending, (state, action) => {
        state.site.isLoading = true;
        state.site.hasError = false;
      })
      .addCase(fetchSite.fulfilled, (state, action) => {
        state.site.data = action.payload;
        state.site.isLoading = false;
      })
      .addCase(fetchSite.rejected, (state, action) => {
        state.site.data = {};
        state.site.isLoading = false;
        state.site.hasError = true;
        state.site.errorStatus = action.payload.status;
      })

      .addCase(fetchSiteRelations.pending, state => {
        state.siteRelation.isLoading = true;
        state.siteRelation.hasError = false;
      })
      .addCase(fetchSiteRelations.fulfilled, (state, action) => {
        state.siteRelation.data = action.payload;
        state.siteRelation.isLoading = false;
      })
      .addCase(fetchSiteRelations.rejected, state => {
        state.siteRelation.data = [];
        state.siteRelation.isLoading = false;
        state.siteRelation.hasError = true;
      });
  },
});

export const { clearSiteSupplierForm, setSelectedCertificate } = slice.actions;
export default slice.reducer;
