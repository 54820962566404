import CharacteristicsSection from "../CharacteristicsSection";
import PackagingSection from "../PackagingSection";
interface AdditionalInformationTabProps {
  disabled: boolean;
  variableUnit?: boolean;
  seasonalUnit?: boolean;
}
const AdditionalInformation: React.FC<AdditionalInformationTabProps> = ({
  disabled,
  variableUnit,
  seasonalUnit,
}) => {
  return (
    <>
      <CharacteristicsSection
        disabled={disabled}
        variableUnit={variableUnit}
        seasonalUnit={seasonalUnit}
      />
      <PackagingSection disabled={disabled} />
    </>
  );
};

export default AdditionalInformation;
