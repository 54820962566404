export default function mapToCategoriesValue(rawValue, multiParentSelection) {
  const categoriesObjList = rawValue.map(({ value, ...rest }) => ({
    value: JSON.parse(value),
    ...rest,
  }));

  const result = [];
  for (let item of categoriesObjList) {
    const { label, value } = item;
    const isUnique =
      result.findIndex(item => item.categoryId === value.categoryId) === -1;

    if (multiParentSelection) {
      result.push({ categoryItemName: label, ...value });
    } else {
      if (isUnique) {
        result.push({ categoryItemName: label, ...value });
      }
    }
  }
  return result;
}
