import { useSelector } from "react-redux";

import { AddressModel } from "pages/Suppliers/constants";

import { CompanyData } from "models";

import { selectCountriesOptions } from "reduxStore/shared/selectors";

import { GOOGLEMAP_QUERY_URL } from "shared/constants";

export default function useSupplierAddress(company: CompanyData) {
  const countries = useSelector(selectCountriesOptions);

  const checkedParamForAddress = [
    AddressModel.ADDRESS_1,
    AddressModel.ADDRESS_2,
    AddressModel.CITY,
    AddressModel.POSTAL_CODE,
    AddressModel.COUNTRY_CODE,
  ];
  const checkedForGmapLink = [
    AddressModel.ADDRESS_1,
    AddressModel.POSTAL_CODE,
    AddressModel.COUNTRY_CODE,
  ];

  const showGmapLink = checkedForGmapLink.every(
    x => company.hasOwnProperty(x) && company[x] !== "" && company[x] !== null
  );
  const companyHasAddress =
    checkedParamForAddress.every(
      x => company[x] === "" || company[x] === null
    ) === false;

  const addressData = [
    ...(company[AddressModel.ADDRESS_1]
      ? [company[AddressModel.ADDRESS_1]]
      : []),
    ...(company[AddressModel.ADDRESS_2]
      ? [company[AddressModel.ADDRESS_2]]
      : []),
    ...(company[AddressModel.CITY] ? [company[AddressModel.CITY]] : []),
    ...(company[AddressModel.POSTAL_CODE]
      ? [company[AddressModel.POSTAL_CODE]]
      : []),
    ...(company[AddressModel.COUNTRY_CODE]
      ? [
          countries.find(c => c.value === company[AddressModel.COUNTRY_CODE])
            ?.name,
        ]
      : []),
  ];

  return {
    hasAddress: companyHasAddress,
    addressData: companyHasAddress ? addressData : [],
    showGmapLink,
    gmapLink: showGmapLink
      ? `${GOOGLEMAP_QUERY_URL}${addressData.join(",")}`
      : "",
  };
}
