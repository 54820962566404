import { SiteData, CompanyData, SiteKpiData } from "models";

type State = {
  sites: SiteData[];
  companies: CompanyData[];
  sitesSkipAndTakeQueryStats: {
    currentCount?: number;
    totalCount?: number;
  };
  defaultSupplierCompanies: CompanyData[];
  kpiData: SiteKpiData;
  isKpiDataLoading: boolean;
  allSupplierCompanies: CompanyData[];
  isSitesLoading: boolean;
  hasSitesError: boolean;
};

export const initialState: State = {
  sites: [],
  companies: [],
  sitesSkipAndTakeQueryStats: {
    currentCount: undefined,
    totalCount: undefined,
  },
  defaultSupplierCompanies: [],
  kpiData: undefined,
  allSupplierCompanies: [],
  isSitesLoading: false,
  isKpiDataLoading: true,
  hasSitesError: false,
};
