import { Col } from "antd";
import { PageCol } from "pages/Sites/constants";

import SiteBloc from "./SiteBloc";
import SiteUsageBloc from "./SiteUsageBloc";

const SiteIdentification = () => {
  return (
    <>
      <Col {...PageCol.HALF}>
        <SiteBloc />
      </Col>
      <Col {...PageCol.HALF}>
        <SiteUsageBloc />
      </Col>
    </>
  );
};

export default SiteIdentification;
