import { CompanyRelationData, SiteData, UserData } from "models";

type State = {
  data: {
    site?: SiteData & {
      siteOwnerCompanyName: string;
      siteOwnerCompanyType: string;
    };
    relation?: any;
    relatedUsers?: UserData[];
    companyRelation?: CompanyRelationData;
  };
  isLoading: boolean;
  hasErrors: boolean;
  errorStatus: any;
};

export const initialState: State = {
  data: {},
  isLoading: false,
  hasErrors: false,
  errorStatus: null,
};
