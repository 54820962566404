import AddressSection from "../AddressSection";
import ContactSection from "../ContactSection";

interface SupplierSiteContactProps {
  disabled: boolean;
}
const SupplierSiteContact: React.FC<SupplierSiteContactProps> = ({
  disabled,
}) => {
  return (
    <>
      <AddressSection disabled={disabled} />
      <ContactSection disabled={disabled} />
    </>
  );
};

export default SupplierSiteContact;
