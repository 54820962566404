import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading, Container, ToggleButtons } from "@trace-one/design-system";
import cn from "classnames";

import usePermissions from "core/oidc/usePermissions";
import { DocumentsListDataItem } from "models";

import { useAppDispatch } from "reduxStore";
import {
  fetchRelatedCompanies,
  fetchFileTypes,
  fetchProjects,
} from "reduxStore/documentList/asyncActions";
import {
  selectUserLanguageCode,
  selectUserId,
  selectUserOwningCompanyId,
} from "reduxStore/user/selectors";

import Spinner from "components/Spinner";
import useSearchFilters from "shared/hooks/useSearchFilters";
import { SearchFiltersStorage } from "shared/webStorage";

import DocumentCards from "./DocumentCards";
import styles from "./DocumentList.module.less";
import DocumentSearch from "./DocumentSearch";
import DocumentTable from "./DocumentTable";
import FileDetails from "./FileDetails";
import useFileDetails from "./FileDetails/hooks/useFileDetails";
import FileSelection from "./FileSelection";
import useDocumentsList from "./hooks/useDocumentsList";
import { DocumentFilter } from "./models";

const DocumentList = () => {
  const intl = useIntl();

  const { documentRead } = usePermissions();
  const dispatch = useAppDispatch();
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const languageCode = useSelector(selectUserLanguageCode);
  const userLanguadeCode = useSelector(selectUserLanguageCode);
  const userId = useSelector(selectUserId);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<
    DocumentsListDataItem[]
  >([]);
  const [isFilesSelectionVisible, setIsFilesSelectionVisible] =
    useState<boolean>(false);

  const [listType, setListType] = useState<"tiles" | "table">("tiles");

  const resetListPageNumber = () => {
    resetPageNumber();
  };

  const {
    searchText,
    setSearchText,
    filterObj,
    mergeFilters,
    removeAllFiltersAndResetPageNumber,
  } = useSearchFilters<DocumentFilter>(
    {
      ownerCompanyId: undefined,
      fileTypeIds: undefined,
      isMyCompany: false,
      uploadedAtStartDate: undefined,
      uploadedAtEndDate: undefined,
      tradeItemId: undefined,
      siteId: undefined,
      projectId: undefined,
    },
    {
      clearedFilters: {
        ownerCompanyId: undefined,
        fileTypeIds: undefined,
        isMyCompany: false,
        uploadedAtStartDate: undefined,
        uploadedAtEndDate: undefined,
        tradeItemId: undefined,
        siteId: undefined,
        projectId: undefined,
      },
      resetPageNumber: resetListPageNumber,
      setSelectedRowKeys,
      webStorage: documentRead ? SearchFiltersStorage.DOCUMENT : undefined,
    }
  );

  const {
    documents,
    // refetchDocuments,
    isPageLoading,
    isListLoading,
    paginationQuery,
    setPaginationQuery,
    resetPageNumber,
    skipAndTakeQueryStats,
  } = useDocumentsList({
    filters: filterObj,
    searchText,
    listType,
  });

  const {
    isFileInfoVisible,
    setIsFileInfoVisible,
    fileInfoRef,
    isFileDetailsLoading,
    displayFileInfo,
    uploadedUserInfoRef,
    signatureInfoRef,
    downloadFile,
    thumbnail,
  } = useFileDetails({
    listType,
  });

  useEffect(() => {
    dispatch(fetchRelatedCompanies({ ownerCompanyId }));
    dispatch(
      fetchFileTypes({
        languageCode: languageCode,
      })
    );
    dispatch(
      fetchProjects({
        UserId: userId,
        LanguageCode: userLanguadeCode,
      })
    );
  }, [ownerCompanyId, languageCode, dispatch]);

  useEffect(() => {
    if (selectedRowKeys.length > selectedDocuments.length) {
      const newDocuments = documents.filter(
        doc =>
          !selectedDocuments.some(x => x.fileId === doc.fileId) &&
          selectedRowKeys.includes(doc.fileId)
      );
      setSelectedDocuments(prev => [...prev, ...newDocuments]);
    } else
      setSelectedDocuments(prev =>
        prev.filter(doc => selectedRowKeys.includes(doc.fileId))
      );
    setIsFileInfoVisible(false);
    setIsFilesSelectionVisible(false);
    if (selectedRowKeys.length === 1) displayFileInfo(selectedRowKeys[0]);
    else if (selectedRowKeys.length > 1) setIsFilesSelectionVisible(true);
  }, [selectedRowKeys]);

  const hideFileInfo = () => {
    setIsFileInfoVisible(false);
  };

  const toggleButtons = [
    {
      value: "tiles",
      name:
        !isFileInfoVisible && !isFilesSelectionVisible
          ? intl.formatMessage({
              id: "documentLibraryListPage.listToggle.tiles",
            })
          : "",
      iconName: "mosaic",
    },
    {
      value: "table",
      name:
        !isFileInfoVisible && !isFilesSelectionVisible
          ? intl.formatMessage({
              id: "documentLibraryListPage.listToggle.table",
            })
          : "",
      iconName: "list",
    },
  ];

  const totalDocuments = skipAndTakeQueryStats?.totalCount ?? 0;
  const pageSize = paginationQuery.take ?? 20;

  if (isPageLoading) {
    return <Spinner underHeader />;
  }
  return (
    <div className={`d-flex ${styles.mainContainer}`}>
      <Container isPageWrapper className={styles.docListContainer}>
        <div className={styles.headActions}>
          <div className={styles.headingContainer}>
            <Heading size="l">
              {intl.formatMessage({
                id: "documentLibraryListPage.title",
              })}
            </Heading>
            <div></div>
          </div>

          <div>
            {/* <DocumentSearch /> */}
            {/* {productsImport && (
              <>
                <CsvImport fileSizeLessThanMB={3} onImport={handleImportCsv} />
              </>
            )} */}
          </div>
        </div>

        <div className={styles.listHeader}>
          <div
            className={cn(styles.listFilterContainer, {
              [styles.withDocumentDetailsView]:
                isFileInfoVisible || isFilesSelectionVisible,
            })}
          >
            <DocumentSearch
              filterObj={filterObj}
              mergeFilters={mergeFilters}
              initialSearchValue={searchText}
              onSearch={setSearchText}
              onClearFiltersClick={removeAllFiltersAndResetPageNumber}
            />

            <ToggleButtons
              options={toggleButtons}
              value={listType}
              onChange={e => {
                setListType(e);
              }}
            />
          </div>

          <div className={styles.pageInformation}>
            {intl.formatMessage(
              { id: "documentLibraryListPage.paginationInfo" },
              {
                currentCount:
                  paginationQuery.skip + pageSize > totalDocuments
                    ? totalDocuments
                    : paginationQuery.skip + pageSize,
                totalCount: totalDocuments,
              }
            )}
          </div>
        </div>

        {listType === "tiles" ? (
          <DocumentCards
            documents={documents}
            isLoading={isListLoading}
            paginationQuery={paginationQuery}
            skipAndTakeQueryStats={skipAndTakeQueryStats}
            setPaginationQuery={setPaginationQuery}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            isFileInfoVisible={isFileInfoVisible}
            isFilesSelectionVisible={isFilesSelectionVisible}
          ></DocumentCards>
        ) : (
          <DocumentTable
            documents={documents}
            isLoading={isListLoading}
            paginationQuery={paginationQuery}
            skipAndTakeQueryStats={skipAndTakeQueryStats}
            setPaginationQuery={setPaginationQuery}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            displayFileInfo={displayFileInfo}
          ></DocumentTable>
        )}
      </Container>

      {isFileInfoVisible && (
        <div
          className={cn(
            styles.docDetailsviewContainer,
            "docDetailsviewContainer"
          )}
        >
          <FileDetails
            fileInfoRef={fileInfoRef}
            uploadedUserInfoRef={uploadedUserInfoRef}
            isLoading={isFileDetailsLoading}
            closeFileInfo={hideFileInfo}
            downloadFile={downloadFile}
            signatureInfoRef={signatureInfoRef}
            thumbnail={thumbnail}
          />
        </div>
      )}

      {isFilesSelectionVisible && (
        <div
          className={cn(
            styles.docSelectionviewContainer,
            "docSelectionviewContainer"
          )}
        >
          <FileSelection
            setSelectedRowKeys={setSelectedRowKeys}
            selectedDocuments={selectedDocuments}
            setIsFilesSelectionVisible={setIsFilesSelectionVisible}
          />
        </div>
      )}
    </div>
  );
};

export default DocumentList;
