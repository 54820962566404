import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "reduxStore";
import { fetchBrands } from "reduxStore/shared/asyncActions";
import { selectBrandsData } from "reduxStore/shared/selectors";
import {
  selectUserLanguageCode,
  selectUserOwningCompanyId,
} from "reduxStore/user/selectors";

import getDisplayName from "shared/utils/getDisplayName";

import { renderHocMiddleware, BaseHocProps } from "../_helpers";

const withBrands =
  () =>
  <P extends BaseHocProps>(WrappedComponent: React.ComponentType<P>) => {
    const Component: React.FC<P> = props => {
      const dispatch = useAppDispatch();

      const languageCode = useSelector(selectUserLanguageCode);
      const ownerCompanyId = useSelector(selectUserOwningCompanyId);

      const { isInitiated, hasError, isLoading } =
        useSelector(selectBrandsData);

      useEffect(() => {
        dispatch(fetchBrands({ ownerCompanyId, languageCode }));
      }, [ownerCompanyId, languageCode, dispatch]);

      return renderHocMiddleware({
        WrappedComponent,
        _isInitiated: isInitiated,
        _hasError: hasError,
        _isLoading: isLoading,
        ...props,
      });
    };

    Component.displayName = getDisplayName(withBrands, WrappedComponent);

    return Component;
  };

export default withBrands;
