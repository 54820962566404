import React from "react";

import scrollTo from "shared/utils/scrollTo";

import styles from "./SectionsMenu.module.less";

export interface SectionsMenuProps {
  getOffsetTop: () => number;
  options: {
    id: string;
    title: string;
  }[];
}

const SectionsMenu: React.FC<SectionsMenuProps> = ({
  getOffsetTop,
  options,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.widget}>
        {options.map(({ id, title }) => (
          <div
            key={id}
            onClick={() => {
              const sectionEl = document.getElementById(id);
              const top =
                sectionEl.getBoundingClientRect().top +
                window.pageYOffset -
                getOffsetTop();

              scrollTo({ top, behavior: "smooth" });
            }}
            data-test-id="sections-menu-item"
            className={styles.menu}
            aria-label={id}
            role="button"
          >
            {title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionsMenu;
