import { MutableRefObject, useState } from "react";
import { useIntl } from "react-intl";

import { DocumentDetailAPIResponse } from "@trace-one/api-clients.dms";
import {
  Heading,
  Button,
  ToggleButtons,
  Typography,
  Divider,
} from "@trace-one/design-system";
import { Menu } from "antd";
import cn from "classnames";

import ActionDropdown from "components/ActionDropdown";
import Spinner from "components/Spinner";
import { disabledFileFormates } from "shared/constants";
import { GetUserResut } from "shared/utils/getUsersCollectionMap";

import { signerInfoData } from "../FileDetails/hooks/useFileDetails";

import styles from "./FileDetails.module.less";
import GSSLinkedItems from "./Tabs/GSSLinkedItems";
import Information from "./Tabs/Information";
import LinkedItems from "./Tabs/LinkedItems";
import Versions from "./Tabs/Versions";

interface Props {
  closeFileInfo: () => void;
  isLoading: boolean;
  fileInfoRef?: MutableRefObject<DocumentDetailAPIResponse>;
  uploadedUserInfoRef?: MutableRefObject<GetUserResut>;
  signatureInfoRef?: MutableRefObject<signerInfoData[]>;
  downloadFile: (
    fileId: string,
    versionId: string,
    fileName: string
  ) => Promise<void>;
  thumbnail?: string;
}

const FileDetails: React.FC<Props> = ({
  closeFileInfo,
  isLoading,
  fileInfoRef,
  uploadedUserInfoRef,
  signatureInfoRef,
  downloadFile,
  thumbnail,
}) => {
  const [currentTab, setCurrentTab] = useState<string>("informations");
  const { formatMessage } = useIntl();
  const fileDetails = fileInfoRef.current;
  var lastIndex = fileDetails?.fileName.lastIndexOf(".");
  const fileType =
    lastIndex &&
    fileDetails?.fileName
      .substring(lastIndex + 1)
      .trim()
      .toLowerCase();
  const canViewFile = !disabledFileFormates.includes(fileType);

  const tabbedbuttons = [
    {
      value: "informations",
      name: formatMessage({
        id: "documentLibraryListPage.docDetails.tabs.informations.title",
      }),
    },
    {
      value: "linked-item",
      name: formatMessage({
        id: "documentLibraryListPage.docDetails.tabs.linkedItems.title",
      }),
    },
    {
      value: "Versions",
      name: formatMessage({
        id: "documentLibraryListPage.docDetails.tabs.versions.title",
      }),
    },
  ];

  if (isLoading) {
    return (
      <div>
        <Spinner className={styles.detailLoader} />
      </div>
    );
  }

  return (
    <div className={styles.fileDetailsContainer}>
      <div className={`${styles.headerSection} d-flex justify-content-between`}>
        <Heading size="xxs">
          {formatMessage({
            id: "documentLibraryListPage.docDetails.title",
          })}
        </Heading>

        <Button
          data-test-id="file-info-closing-btn"
          type="tertiary"
          color="grey"
          iconName="close"
          onClick={closeFileInfo}
        />
      </div>
      <div
        className={cn(styles.thumbnailImage, canViewFile && styles.canViewFile)}
      >
        <img
          src={thumbnail ?? "/assets/images/file-thumbnail-default.png"}
          style={{ cursor: canViewFile ? "pointer" : "auto" }}
          onClick={() => {
            if (canViewFile) {
              window.open(
                `/documents/viewer/${fileDetails?.fileId}?versionId=${fileDetails?.versionId}&fileName=${fileDetails?.fileName}`,
                "_blank"
              );
            }
          }}
          alt=""
        />
      </div>
      <div
        className={`py-3 d-flex justify-content-between align-content-center`}
      >
        <Typography
          className={styles.fileTitleName}
          variant="heading-xs"
          color="grey-5"
          component="span"
        >
          {fileDetails?.fileName}
        </Typography>

        <div className={styles.fileDetailsMenuTriggerContainer}>
          <ActionDropdown
            data-test-id="md-filedetails-action"
            overlay={
              <Menu>
                <Menu.Item
                  key="download-file"
                  onClick={() =>
                    downloadFile(
                      fileDetails?.fileId,
                      fileDetails?.versionId,
                      fileDetails?.fileName
                    )
                  }
                >
                  {formatMessage({
                    id: "general.download",
                  })}
                </Menu.Item>
                <Menu.Item
                  key="preview-file"
                  onClick={() => {
                    if (canViewFile) {
                      window.open(
                        `/documents/viewer/${fileDetails?.fileId}?versionId=${fileDetails?.versionId}&fileName=${fileDetails?.fileName}`,
                        "_blank"
                      );
                    }
                  }}
                >
                  {formatMessage({
                    id: "general.preview",
                  })}
                </Menu.Item>
              </Menu>
            }
          />
          {/* <Button
            type="tertiary"
            iconName="download"
            color="grey"
            onClick={() =>
              downloadFile(
                fileDetails?.fileId,
                fileDetails?.versionId,
                fileDetails?.fileName
              )
            }
          /> */}
        </div>
      </div>

      {/* <div>
        <Paragraph>Last version</Paragraph>
      </div> */}

      <div>
        <ToggleButtons
          value={currentTab}
          onChange={value => {
            setCurrentTab(value);
          }}
          options={tabbedbuttons}
        ></ToggleButtons>
        <Divider dashed color="grey-3" height={24}></Divider>
      </div>

      {currentTab === tabbedbuttons[0].value && (
        <Information
          uploadedUserInfoRef={uploadedUserInfoRef}
          fileInfoRef={fileInfoRef}
          signatureInfoRef={signatureInfoRef}
        />
      )}

      {currentTab === tabbedbuttons[1].value &&
        (window.env.GSS_INTEGRATION === "true" ? (
          <GSSLinkedItems
            fileId={fileDetails?.fileId}
            uploadedUserInfoRef={uploadedUserInfoRef}
          />
        ) : (
          <LinkedItems fileInfoRef={fileInfoRef} />
        ))}

      {currentTab === tabbedbuttons[2].value && (
        <Versions fileInfoRef={fileInfoRef} downloadFile={downloadFile} />
      )}
    </div>
  );
};

export default FileDetails;
