import { ErrorPage as ToErrorPage } from "@trace-one/react-components";

interface ErrorPageProps {
  status?: number;
  logoPath?: string;
  primaryButton?: {
    isVisible?: boolean;
    path?: string;
  };
  secondaryButton?: {
    isVisible?: boolean;
    path?: string;
  };
}

const ErrorPage: React.FC<ErrorPageProps> = props => {
  return (
    <ToErrorPage
      status={500}
      {...props}
      primaryButton={{
        path: "/home",
        isVisible: true,
        ...props.primaryButton,
      }}
      secondaryButton={{
        path: "/",
        isVisible: false,
        ...props.secondaryButton,
      }}
    />
  );
};

ErrorPage.propTypes = {};

export default ErrorPage;
