import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Heading,
  Paragraph,
  Button,
  Tooltip,
  Toggle,
  Datatable,
  TagV1 as Tag,
} from "@trace-one/design-system";
import { Empty, Modal, Row, Col } from "antd";
import { SectionsIds } from "pages/Sites/constants";
import { PageCol } from "pages/Sites/constants";

import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import {
  selectCertificateTypeOptions,
  selectCertificateOptions,
} from "reduxStore/shared/selectors";
import { selectSite } from "reduxStore/siteSupplierDetails/selectors";
import { setSelectedCertificate } from "reduxStore/siteSupplierForm/slice";

import FilePreview from "components/FilePreview";
import { mapDateToMomentLocal } from "shared/utils/dateUtils";

import getCertificateSort from "../../utils/getCertificateSort";
import getCertificateStatus from "../../utils/getCertificateStatus";
import styles from "../SiteSupplierDetails.module.less";

import SiteCertificateAction from "./SiteCertificateAction";

const CertificationsTab = () => {
  const intl = useIntl();
  const [fileModal, setFileModal] = useState({
    visible: false,
    certificateType: null,
    files: [],
  });

  const certificateList = useSelector(selectCertificateOptions);
  const certificateTypeList = useSelector(selectCertificateTypeOptions);
  const [defaultValue, setDefaultValue] = useState<boolean>(true);
  const site = useSelector(selectSite);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { sitesWrite } = usePermissions();

  let certificates = site?.siteCertificates.map((certificate, key) => {
    const certificateName = certificateList.find(
      c => c.value === certificate.certificationId
    )?.name;
    const certificateLabel = certificate.certificateLabel;
    const certificateKeyId = certificate.certificateKeyId;
    const certificateType = certificateTypeList.find(
      c => c.value === certificate.typeId
    )?.name;

    const certificateEndDate = mapDateToMomentLocal(
      certificate.certificateEndAvailabilityDate
    );

    const certificateStatus = getCertificateStatus({
      date: certificateEndDate,
      intl,
    });

    const otherNames = ["Other", "Autre"];

    return {
      key,
      certificateName: otherNames.includes(certificateName)
        ? certificateLabel
        : certificateName,
      certificateType,
      certificateStatus,
      certificateFiles: certificate.attachments,
      certificateEndDate: certificateEndDate,
      certificateKeyId,
    };
  });

  if (certificates.length) {
    certificates.sort((a, b) =>
      a.certificateType.localeCompare(b.certificateType)
    );
    certificates = getCertificateSort({ certificates, intl });
  }

  const handleEditCertificate = record => {
    dispatch(setSelectedCertificate(record.certificateKeyId));
    history.push({
      pathname: `/sites/edit/${site.id}`,
      hash: `${SectionsIds.CERTIFICATES}:${record.certificateKeyId}`,
    });
  };

  const certificateAction = [
    {
      title: intl.formatMessage({ id: "general.actions" }),
      dataIndex: "actions",
      key: "actions",
      width: 80,
      ellipsis: true,
      render: (_, record) => {
        return <SiteCertificateAction record={record} site={site} />;
      },
    },
  ];

  const filterCertificatesItem = switchStatus => {
    setDefaultValue(switchStatus);
  };

  return (
    <div className={styles.tabWrapper}>
      <Row gutter={[40, 40]}>
        <Col {...PageCol.FULL}>
          <div className={styles.switchWrapper}>
            <Toggle
              data-test-id="active-inactive-toggle"
              defaultChecked={true}
              text={intl.formatMessage({ id: "siteDetails.displayActiveOnly" })}
              size="small"
              onChange={switchStatus => filterCertificatesItem(switchStatus)}
            />
          </div>

          <Datatable
            data-test-id="certification-table"
            columns={[
              {
                title: intl.formatMessage({
                  id: "siteDetails.certificatesTitle",
                }),
                dataIndex: "certificateType",
                sorter: (a, b) =>
                  a.certificateType.localeCompare(b.certificateType),
                key: "certificateType",
                ellipsis: true,
                width: 160,
                render: (certificateType, record) => {
                  return (
                    <>
                      {site.siteActive ? (
                        <Button
                          type="link"
                          onClick={() => handleEditCertificate(record)}
                        >
                          <div>{certificateType}</div>
                        </Button>
                      ) : (
                        <div className={styles.certificateListTooltip}>
                          <Tooltip text={certificateType} placement="top">
                            {certificateType}
                          </Tooltip>
                        </div>
                      )}
                    </>
                  );
                },
              },
              {
                title: intl.formatMessage({ id: "siteForm.certificateName" }),
                dataIndex: "certificateName",
                key: "certificateName",
                sorter: (a, b) =>
                  a.certificateName?.localeCompare(b.certificateName),
                width: 130,
              },
              {
                title: intl.formatMessage({
                  id: "siteForm.certificateEndDate",
                }),
                dataIndex: "certificateEndDate",
                key: "certificateEndDate",
                render: date => date?.format("L"),
                sorter: (a, b) =>
                  (b.certificateEndDate?.toDate()?.getTime() ?? 0) -
                  (a.certificateEndDate?.toDate()?.getTime() ?? 0),
                width: 90,
              },
              {
                title: intl.formatMessage({ id: "general.status" }),
                dataIndex: "certificateStatus",
                key: "certificateStatus",
                width: 90,
                render: ({ color, label }) => {
                  return (
                    <div
                      className={
                        label ===
                        intl.formatMessage({
                          id: "siteForm.certificateStatusExpiredSoon",
                        })
                          ? styles.expireSoon
                          : styles.status
                      }
                    >
                      {label ===
                      intl.formatMessage({
                        id: "siteForm.certificateStatusNone",
                      }) ? (
                        label
                      ) : (
                        <Tag label={label} color={color} mode="light" />
                      )}
                    </div>
                  );
                },
              },
              {
                title: intl.formatMessage({ id: "siteForm.certificateFile" }),
                dataIndex: "certificateFiles",
                key: "certificateFiles",
                width: 100,
                render: (files, { certificateType }) =>
                  files.length > 0 && (
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        maxWidth: "100%",
                      }}
                      onClick={() =>
                        setFileModal({ visible: true, certificateType, files })
                      }
                    >
                      {intl.formatMessage({ id: "siteForm.seeFiles" })}
                    </div>
                  ),
              },

              ...(site.siteActive && sitesWrite ? certificateAction : []),
            ]}
            dataSource={
              !defaultValue
                ? certificates
                : certificates.filter(
                    item =>
                      intl.formatMessage({
                        id: "siteForm.certificateStatusInactive",
                      }) !== item.certificateStatus.label
                  )
            }
            scroll={{ x: "100%" }}
            tableLayout="fixed"
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  description={intl.formatMessage({ id: "general.noData" })}
                />
              ),
            }}
          />

          <Modal
            title={
              <Heading size="s">
                {" "}
                {intl.formatMessage(
                  { id: "siteForm.fileModalTitle" },
                  { title: fileModal.certificateType }
                )}
              </Heading>
            }
            visible={fileModal.visible}
            onCancel={() =>
              setFileModal({ visible: false, certificateType: null, files: [] })
            }
            footer={null}
          >
            <Paragraph size="m">
              {fileModal.files.map(file => (
                <FilePreview key={file.fileId} file={file} />
              ))}
            </Paragraph>
          </Modal>
        </Col>
      </Row>
    </div>
  );
};

export default CertificationsTab;
