/* istanbul ignore file */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { compose } from "@reduxjs/toolkit";
import {
  DUPLICATE_QUERY_IDENTIFIER,
  NET_CONTENT_PARENT_ID_FOR_PRODUCT_FORM,
} from "pages/Products/constants";

import { useAppDispatch } from "reduxStore";
import { fetchProduct } from "reduxStore/productForm/asyncActions";
import { selectProductFormData } from "reduxStore/productForm/selectors";
import { clearProductForm } from "reduxStore/productForm/slice";
import { selectOwningCompany } from "reduxStore/shared/selectors";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import ErrorPage from "components/ErrorPage";
import Spinner from "components/Spinner";
import {
  BaseHocProps,
  withBrands,
  withCategoryLists,
  withNetContentMeasures,
  withOwningCompany,
} from "shared/hocs";

import ProductForm from "../../components/ProductForm";

interface ProductCreateProps extends BaseHocProps {}

const enhance = compose<React.FC<ProductCreateProps>>(
  withBrands(),
  withCategoryLists(),
  withNetContentMeasures({
    includeParentItems: true,
    parentItemId: NET_CONTENT_PARENT_ID_FOR_PRODUCT_FORM,
  }),
  withOwningCompany()
);

const ProductCreate: React.FC<ProductCreateProps> = ({ isInitiated }) => {
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [withDirtyForm, setWithDirtyForm] = useState(false);
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const { productData, hasProductError, errorStatus } = useSelector(
    selectProductFormData
  );
  const { data: owningCompany } = useSelector(selectOwningCompany);

  useEffect(() => {
    if (!!search) {
      const urlSearchParams = new URLSearchParams(search);
      const queryParams = Object.fromEntries(urlSearchParams.entries());
      const duplicateId = queryParams[DUPLICATE_QUERY_IDENTIFIER];
      if (!!duplicateId) {
        setIsLoading(true);
        setWithDirtyForm(true);
        dispatch(fetchProduct({ id: duplicateId, ownerCompanyId })).then(e => {
          setIsLoading(false);
        });
      }
    }
    return () => {
      dispatch(clearProductForm());
    };
  }, []);

  const prepareInitialDuplicatedValues = productData => {
    const manufacturedItems = !!productData?.manufacturedItems?.length
      ? productData.manufacturedItems.map(item => ({
          ...item,
          id: null,
        }))
      : null;
    return {
      ...productData,
      id: null,
      comments: null,
      manufacturedItems,
      productLanguage: owningCompany.defaultLanguage,
    };
  };

  if (hasProductError) {
    return <ErrorPage status={errorStatus} />;
  }

  if (!isInitiated || isLoading) {
    return <Spinner underHeader />;
  }

  return (
    <ProductForm
      initialValues={prepareInitialDuplicatedValues(productData)}
      onCreationPage
      withDirtyForm={withDirtyForm}
    />
  );
};

export default enhance(ProductCreate);
