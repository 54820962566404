import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Heading, Tooltip } from "@trace-one/design-system";
import { Container, usePreviousLocation } from "@trace-one/react-components";
import cn from "classnames";
import { renderToStaticMarkup } from "react-dom/server";

import { useAppDispatch } from "reduxStore";
import { selectBackButtonData } from "reduxStore/shared/selectors";
import { clearBackButtonData } from "reduxStore/shared/slice";

import Icon from "components/Icon";
import IconButton from "components/IconButton";

import HeaderBg from "./assets/HeaderDetailsBackground";
import HeaderButton from "./components/HeaderButton";
import TabHeader from "./components/TabHeader";
import styles from "./HeaderDetails.module.less";

type tabProps = {
  label: React.ReactNode;
  count?: number;
  content?: React.ReactNode;
};
type buttonProps = {
  text?: string;
  pathname?: string;
  showTooltip?: boolean;
  showBtn?: boolean;
  testId?: string;
  tab?: number;
  hash?: string;
};

interface HeaderDetailsProps {
  title: React.ReactNode | string;
  subtitle?: React.ReactNode;
  status?: React.ReactNode;
  withBackNav?: boolean;
  tooltipTitle?: React.ReactNode;
  tabs?: tabProps[];
  buttons?: buttonProps[];
  headerIcon?: string;
  children?: React.ReactNode;
  showBtn?: string[];
  handleStatus?: () => void;
  haveActionItems?: boolean;
}

const setActiveHeaderTab = tab => {
  if (["product-identification-section", "attributes-section"].includes(tab)) {
    return 0;
  } else if (["private-section"].includes(tab)) {
    return 0;
  } else if (tab === "supplier-comments-section") {
    return 1;
  } else if (
    [
      "manufactured-items-section",
      "manufactured-items-section-supplier-section",
    ].includes(tab)
  ) {
    return 1;
  } else if (tab === "comments-section") {
    return 3;
  } else {
    return 2;
  }
};

const HeaderDetails: React.FC<HeaderDetailsProps> = props => {
  const {
    title,
    subtitle,
    status,
    tabs,
    withBackNav,
    tooltipTitle,
    handleStatus,
    headerIcon,
    buttons,
    children,
    haveActionItems,
  } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const previousLocation = usePreviousLocation();
  const { fromUrl, tabSection } = useSelector(selectBackButtonData);
  const svgString = encodeURIComponent(renderToStaticMarkup(<HeaderBg />));
  const [selectedTab, setSelectedTab] = useState(
    history.location.hash
      ? setActiveHeaderTab(history.location.hash?.replace("#", ""))
      : 0
  );

  const setActiveTab = activeKey => {
    setSelectedTab(parseInt(activeKey));
  };

  const handleChangeUrl = () => {
    if (fromUrl) {
      history.push({
        pathname: fromUrl,
        hash: tabSection,
      });
      dispatch(clearBackButtonData({ fromUrl: null, tabSection: null }));
    } else {
      history.goBack();
    }
  };
  return (
    <>
      <div className={cn(styles.root, "header-form-details")}>
        <div
          className={cn("formHeaderWrapper", styles.headerWrapper)}
          style={{ backgroundImage: `url("data:image/svg+xml,${svgString}")` }}
        >
          <Container component="header" className={styles.header}>
            <div
              className={cn(styles.headerContent, {
                [styles.paddingBottom]: !tabs,
              })}
            >
              <div style={{ display: "flex" }}>
                {withBackNav && !!previousLocation && (
                  <div className={styles.backButtonWrapper}>
                    <IconButton
                      onClick={() => handleChangeUrl()}
                      data-test-id="md-form-back"
                      className={styles.backButton}
                    >
                      <Icon useTOLibrary name="long-arrow-left" />
                    </IconButton>
                  </div>
                )}

                {!!status && (
                  <div className={styles.status} data-test-id="header-status">
                    {status}
                  </div>
                )}

                <div className={styles.headerInfoWrapper}>
                  <div className={styles.titleWrapper}>
                    <Heading data-test-id="header-title" size="m">
                      <Tooltip showFullText text={title} placement="top">
                        <div className={styles.headerTitle}>{title} </div>
                      </Tooltip>
                    </Heading>
                  </div>
                  {!!subtitle && (
                    <div className={styles.subtitleWrapper}>
                      <span data-test-id="header-subtitle">{subtitle}</span>
                    </div>
                  )}
                </div>
              </div>

              {!!tabs && (
                <TabHeader
                  onChange={setActiveTab}
                  activeTab={selectedTab.toString()}
                  tabs={tabs}
                />
              )}
            </div>
            {!!buttons && (
              <HeaderButton
                buttons={buttons}
                selectedTab={selectedTab}
                handleStatus={handleStatus}
                tooltipTitle={tooltipTitle}
                haveActionItems={haveActionItems}
              />
            )}
            {!!headerIcon && (
              <div className={styles.iconWrapper}>
                <Icon name={headerIcon} />
              </div>
            )}
          </Container>
        </div>

        {!!tabs ? (
          <Container className={cn("formtabContainer", styles.tabContainer)}>
            {tabs[selectedTab].content}
          </Container>
        ) : (
          <Container className={styles.childrenContainer}>{children}</Container>
        )}
      </div>
    </>
  );
};

export default HeaderDetails;
