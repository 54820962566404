import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/pt";
import "moment/locale/it";
import "moment/locale/nl-be";

export enum Languages {
  ENGLISH = "en-US",
  FRENCH = "fr-FR",
  GERMAN = "de-DE",
  SPANISH = "es-ES",
  PORTUGUESE = "pt-PT",
  ITALIAN = "it-IT",
  NETHERLANDS = "nl-BE",
}

export const DEFAULT_LANGUAGE = Languages.ENGLISH;

export const TO_TRANSLATIONS = Object.freeze({
  [Languages.ENGLISH]: require("./en-US.json"),
  [Languages.FRENCH]: require("./fr-FR.json"),
  [Languages.GERMAN]: require("./de-DE.json"),
  [Languages.SPANISH]: require("./es-ES.json"),
  [Languages.PORTUGUESE]: require("./pt-PT.json"),
  [Languages.ITALIAN]: require("./it-IT.json"),
  [Languages.NETHERLANDS]: require("./nl-BE.json"),
});
export const ANTD_CONFIG_LOCALES = Object.freeze({
  [Languages.ENGLISH]: require("antd/lib/locale/en_US").default,
  [Languages.FRENCH]: require("antd/lib/locale/fr_FR").default,
  [Languages.GERMAN]: require("antd/lib/locale/de_DE").default,
  [Languages.SPANISH]: require("antd/lib/locale/es_ES").default,
  [Languages.PORTUGUESE]: require("antd/lib/locale/pt_PT").default,
  [Languages.ITALIAN]: require("antd/lib/locale/it_IT").default,
  [Languages.NETHERLANDS]: require("antd/lib/locale/nl_BE").default,
});

export const parseToSupportedLanguage = (languageCode: string) => {
  if (TO_TRANSLATIONS[languageCode]) {
    return languageCode;
  }
  return DEFAULT_LANGUAGE;
};
