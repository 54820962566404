import { memo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading } from "@trace-one/design-system";
import { Form } from "antd";

import { selectTeamMemberResponsibilities } from "reduxStore/shared/selectors";

import CategoryTreeSelect from "components/CategoryTreeSelect";
import ContactsInputGroup from "components/ContactsInputGroup";
import FormWrapper from "components/FormWrapper";
import Input from "components/Input";

import { SectionsIds } from "../../constants";
import { GetSupplierDataForm } from "../../models";
import { SupplierSubmitResult } from "../../models";

import AttributesInput from "./AttributesInput";

interface PrivateInformationSectionProps {
  disabled?: boolean;
  submitResult?: SupplierSubmitResult;
  companyRelation: GetSupplierDataForm;
}

const PrivateInformationSection: React.FC<PrivateInformationSectionProps> = ({
  disabled,
  submitResult,
}) => {
  const { formatMessage } = useIntl();
  const teamMemberResponsibilities = useSelector(
    selectTeamMemberResponsibilities
  );

  return (
    <FormWrapper.Section
      id={SectionsIds.PRIVATE_INFORMATION}
      title={
        <Heading size="m">
          {formatMessage({ id: "general.privateInformation" })}{" "}
        </Heading>
      }
    >
      <Form.Item
        name="externalName"
        label={
          <Heading size="xxs">
            {formatMessage({ id: "general.internalName" })}{" "}
          </Heading>
        }
      >
        <Input
          data-test-id="md-supplier-externalName"
          disabled={disabled}
          maxLength={256}
        />
      </Form.Item>
      <Form.Item
        name="externalIdentifier"
        label={
          <Heading size="xxs">
            {formatMessage({ id: "general.internalCode" })}{" "}
          </Heading>
        }
      >
        <Input
          data-test-id="md-supplier-externalIdentifier"
          disabled={disabled}
          maxLength={20}
        />
      </Form.Item>
      <Form.Item
        name="categories"
        label={
          <Heading size="xxs">
            {formatMessage({ id: "general.productCategory" })}{" "}
          </Heading>
        }
      >
        {/*
        // @ts-ignore */}
        <CategoryTreeSelect
          disabled={disabled}
          multiple
          multiParentSelection
          categorySelection={false}
          data-test-id="md-supplier-categories"
        />
      </Form.Item>
      <Form.Item
        name="retailerCustomizedAttributes"
        label={
          <Heading size="xxs">
            {formatMessage({ id: "general.privateAttributes" })}{" "}
          </Heading>
        }
      >
        <AttributesInput disabled={disabled} />
      </Form.Item>

      <Form.Item
        name="contacts"
        label={
          <Heading size="xxs">
            {formatMessage({ id: "general.contacts" })}{" "}
          </Heading>
        }
      >
        <ContactsInputGroup
          teamMemberResponsibilities={teamMemberResponsibilities}
          disabled={disabled}
          withErrors={submitResult?.contacts?.withErrors}
          withUserStatusMention
        />
      </Form.Item>
    </FormWrapper.Section>
  );
};

export default memo(PrivateInformationSection);
