import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { Search, Checkbox, Typography } from "@trace-one/design-system";

import ShortMsg from "components/ShortMsg";
import Spinner from "components/Spinner";
import { dropdownFilterCount } from "shared/constants";
import useDebounce from "shared/hooks/useDebounce";
import useToast from "shared/hooks/useToast";

import styles from "./FilterMultiSelection.module.less";

export interface option {
  label: string | React.ReactNode;
  searchLabel?: string;
  value: string;
  "data-test-id"?: string;
}

interface FilterMultiSelectionProps {
  options: option[];
  onChange: (values: option[] | string[]) => void;
  onAsyncSearch?: ({
    searchValue,
  }: {
    searchValue: string;
  }) => Promise<option[]>;
  onSearch?: (searchStr: string) => option[];
  onScrollSearch?: (take: number) => Promise<option[]>;
  includeSelectAll?: boolean;
  getValuesOnly?: boolean;
  values: string[];
  dataTestId?: string;
  minLengthToSearch?: number;
}

const FilterMultiSelection: React.FC<FilterMultiSelectionProps> = ({
  options,
  onChange,
  values,
  dataTestId,
  onAsyncSearch,
  getValuesOnly,
  onSearch,
  minLengthToSearch = 3,
  onScrollSearch,
  includeSelectAll,
}) => {
  const isDirty = useRef(false);
  const toast = useToast();
  const { formatMessage } = useIntl();

  const getFilteredOptions = (opts: option[]) => {
    return opts.map(option => {
      return {
        ...option,
        onChange: handleChangeOption,
      };
    });
  };

  const handleChangeOption = e => {
    isDirty.current = true;
    setSelectedValues(selectedOptions => {
      const opts = new Set(selectedOptions);
      if (opts.has(e.target.value)) {
        opts.delete(e.target.value);
      } else {
        opts.add(e.target.value);
      }

      return [...opts];
    });
  };

  const onAllSelect = e => {
    isDirty.current = true;
    if (isAllSelected) {
      setSelectedValues(values => {
        const filteredValues = filteredOptions.map(({ value }) => value);
        return values.filter(val => !filteredValues.includes(val));
      });
    } else {
      setSelectedValues(values => {
        const filteredValues = filteredOptions.map(({ value }) => value);
        return [
          ...values.filter(val => !filteredValues.includes(val)),
          ...filteredValues,
        ];
      });
    }
    setIsAllSelected(prev => !prev);
  };

  const [filteredOptions, setFilteredOptions] = useState(
    getFilteredOptions(options)
  );
  const [selectedValues, setSelectedValues] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [paginateLoading, setPaginateLoading] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue);
  const optionsRef = useRef([]);
  const listInnerRef = useRef();
  const totalCount = useRef(dropdownFilterCount);

  const filterItems = e => {
    const searchStr = e.target.value;
    setSearchValue(searchStr);
    if (!searchStr) {
      setFilteredOptions(getFilteredOptions(options));
      totalCount.current = dropdownFilterCount;
      onAsyncSearch && setLoading(false);
      return;
    }
    if (onAsyncSearch) {
      if (searchStr?.trim()?.length >= minLengthToSearch) {
        setLoading(true);
      }

      // Need to memorize ref of options if user type in fast value that will not trigger useEffect
      // Clearing options in cleanup will create vanish effect
      if (searchStr === debouncedSearchValue) {
        setFilteredOptions(optionsRef.current);
        setLoading(false);
      } else {
        setFilteredOptions([]);
      }
      return;
    }
    if (onSearch) {
      setFilteredOptions(getFilteredOptions(onSearch(searchStr)));
      return;
    }
    const newFilteredOptions = options.filter(option => {
      const filterLabel =
        typeof option.label === "string" ? option.label : option.searchLabel;

      return filterLabel.toLowerCase().includes(searchStr.toLowerCase());
    });
    setFilteredOptions(getFilteredOptions(newFilteredOptions));
  };

  useEffect(() => {
    isDirty.current = false;
    setSelectedValues(values);
  }, [values]);

  useEffect(() => {
    if (isDirty.current) {
      const selectedOptions = getValuesOnly
        ? selectedValues
        : options.filter(option => selectedValues.includes(option.value));
      onChange(selectedOptions);
    }
  }, [selectedValues]);

  useEffect(() => {
    if (!includeSelectAll) {
      return;
    }
    const isFiltered = filteredOptions.length < options.length;
    if (!isFiltered && options.length === selectedValues.length) {
      setIsAllSelected(true);
    } else if (
      isFiltered &&
      filteredOptions.every(e => selectedValues.includes(e.value))
    ) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [selectedValues, filteredOptions]);

  useEffect(() => {
    // Race condition secure
    if (!onAsyncSearch) {
      return;
    }

    let mount = true;
    if (debouncedSearchValue?.trim()?.length >= minLengthToSearch) {
      onAsyncSearch({ searchValue: debouncedSearchValue })
        .then(options => {
          const isOptionsListArray = Array.isArray(options);
          if (!isOptionsListArray) {
            console.warn(
              "[AsyncSearchSelect] onAsyncSearch type ({ searchValue }) => Promise<{ label, value }[]>"
            );
          }
          if (mount && isOptionsListArray) {
            const newFilteredOptions = getFilteredOptions(options);
            setFilteredOptions(newFilteredOptions);
            optionsRef.current = newFilteredOptions;
          }
          setLoading(false);
        })
        .catch(error => {
          if (mount) {
            toast.fetchError({ error });
            setFilteredOptions([]);
            optionsRef.current = [];
          }
          setLoading(false);
        });
    }
    return () => {
      mount = false;
      optionsRef.current = [];
    };
  }, [debouncedSearchValue]);

  const onScroll = async () => {
    let target = listInnerRef.current as HTMLElement;
    if (target) {
      if (
        Math.round(target.scrollTop + target.offsetHeight) >
        target.scrollHeight - 3
      ) {
        if (searchValue || paginateLoading) {
          return;
        }

        setPaginateLoading(true);
        totalCount.current = totalCount.current + dropdownFilterCount;
        try {
          const data = await onScrollSearch(totalCount.current);
          setFilteredOptions(getFilteredOptions(data));
          setPaginateLoading(false);
        } catch (error) {
          toast.fetchError({ error });
        }
      }
    }
  };

  return (
    <div
      {...(onScrollSearch ? { onScroll: onScroll } : {})}
      ref={listInnerRef}
      className={styles.root}
      data-test-id={"filter-multiselection-panel"}
    >
      <Search
        className={styles.searchInput}
        data-test-id={dataTestId}
        value={searchValue}
        onChange={filterItems}
      ></Search>
      <div className={styles.content}>
        {onAsyncSearch &&
        searchValue &&
        searchValue?.trim()?.length < minLengthToSearch ? (
          <Typography component="span">
            <ShortMsg.EnterAtLeast value={minLengthToSearch} />
          </Typography>
        ) : loading ? (
          <Spinner className={styles.spinner} />
        ) : !filteredOptions.length ? (
          <Typography className={styles.noData} component="h6">
            {formatMessage({
              id: `general.noData`,
            })}
          </Typography>
        ) : (
          <>
            <div>
              {includeSelectAll && (
                <div>
                  <Checkbox
                    checked={isAllSelected}
                    className={styles.selectAll}
                    onChange={onAllSelect}
                    data-test-id="select-all-checkbox"
                  >
                    <Typography component="span">
                      {formatMessage({
                        id: `general.selectAll`,
                      })}
                    </Typography>
                  </Checkbox>
                </div>
              )}
              <Checkbox.Group
                direction="vertical"
                value={selectedValues}
                options={filteredOptions}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FilterMultiSelection;
