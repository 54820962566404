import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectProductShared = (state: RootState) => state.productShared;

export const selectSupplierCompaniesData = createSelector(
  _selectProductShared,
  ({ supplierCompaniesData }) => supplierCompaniesData
);
export const selectPakerCompaniesData = createSelector(
  _selectProductShared,
  ({ pakerCompaniesData }) => pakerCompaniesData
);
