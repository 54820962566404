import Table from "components/Table";

import styles from "./DocumentTable.module.less";
import useDocumentTable from "./hooks/useDocumentTable";

const DocumentTable = ({
  documents,
  isLoading,
  paginationQuery,
  skipAndTakeQueryStats,
  setPaginationQuery,
  selectedRowKeys,
  setSelectedRowKeys,
  displayFileInfo,
}) => {
  const { columns, data } = useDocumentTable({
    documents,
    displayFileInfo,
    setSelectedRowKeys,
  });

  // const {
  //   searchText,
  //   setSearchText,
  //   filterObj,
  //   mergeFilters,
  //   removeAllFiltersAndResetPageNumber,
  // } = useSearchFilters<BrandFilter>(
  //   {
  //     brandId: undefined,
  //     archivedStatuses: ["no"],
  //   },
  //   {
  //     clearedFilters: {
  //       brandId: undefined,
  //       archivedStatuses: [],
  //     },
  //     resetPageNumber,
  //     setSelectedRowKeys,
  //     // webStorage: brandsWrite ? SearchFiltersStorage.BRAND : undefined,
  //   }
  // );

  // const { archivedStatuses } = filterObj;

  return (
    <div className={styles.documentTable}>
      <Table
        columns={columns}
        dataSource={data}
        size="small"
        loading={isLoading}
        skip={paginationQuery.skip}
        take={paginationQuery.take}
        skipAndTakeQueryStats={skipAndTakeQueryStats}
        setPaginationQuery={setPaginationQuery}
        rowSelection={{ selectedRowKeys }}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </div>
  );
};

export default DocumentTable;
