import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

// import { Container } from "@trace-one/react-components";
import { Button } from "@trace-one/design-system";
import { Icon, Popover } from "@trace-one/react-components";
import { Menu, Table } from "antd";
import cn from "classnames";

import usePermissions from "core/oidc/usePermissions";

import { selectCategoryItemFormData } from "reduxStore/categoryItemForm/selectors";
import {
  selectClassificationCompanyId,
  selectGenericCategory,
} from "reduxStore/classificationDetails/selectors";
import { selectLanguagesData } from "reduxStore/shared/selectors";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";
// import { withLanguages } from "shared/hocs";

import styles from "./CategoryTranslationTable.module.less";
import FlagIcon from "./FlagIcon";
import useCategoryTranslationTable from "./hooks/useCategoryTranslationTable";
import { Translation } from "./models";

interface CategoryTranslationTableProps {
  value?: Translation[];
  onChange?: (value: Translation[]) => void;
}

const CategoryTranslationTable: React.FC<CategoryTranslationTableProps> = ({
  value,
  onChange,
}) => {
  const { formatMessage } = useIntl();
  const { categoryListsWrite } = usePermissions();
  const languagesData = useSelector(selectLanguagesData).data;
  const userCompanyId = useSelector(selectUserOwningCompanyId);
  const classificationCompanyId = useSelector(selectClassificationCompanyId);
  const { categoryItemText } = useSelector(selectCategoryItemFormData);
  const isSameCompanyID: boolean = userCompanyId === classificationCompanyId;
  const isGenericCategory = useSelector(selectGenericCategory);
  const isDisabled =
    !categoryListsWrite || isGenericCategory || !isSameCompanyID;
  const languagesMap = languagesData.reduce<{ [itemCode: string]: string }>(
    (prev, current) => ({
      ...prev,
      [current.itemCode]: current.text,
    }),
    {}
  );

  const [open, setOpen] = useState(false);
  const handleVisibleChange = (visible: boolean) => {
    setOpen(visible);
  };

  const handleTranslationChange =
    (languageItemCode: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(
        value.map(translation => {
          if (translation.languageItemCode === languageItemCode) {
            const text = event.target.value;
            return { languageItemCode, text };
          } else {
            return translation;
          }
        })
      );
    };

  const handleAddNewItem = (languageItemCode: string) => () => {
    const newTranslation: Translation = {
      languageItemCode,
      text: categoryItemText,
    };
    onChange([...value, newTranslation]);
    setOpen(false);
  };

  const handleRemoveItem = (languageItemCode: string) => () => {
    onChange(
      value.filter(
        translation => translation.languageItemCode !== languageItemCode
      )
    );
  };

  const { columns, data } = useCategoryTranslationTable({
    value,
    languagesMap,
    handleTranslationChange,
    handleRemoveItem,
  });

  const renderFooter = () => {
    const filteredLanguages = languagesData.filter(
      ({ itemCode }) => !value.find(item => item.languageItemCode === itemCode)
    );

    if (filteredLanguages.length < 1) {
      return null;
    }

    return (
      <Popover
        overlayClassName={styles.popover}
        content={
          <Menu>
            {filteredLanguages.map(language => (
              <Menu.Item
                onClick={handleAddNewItem(language.itemCode)}
                key={language.itemCode}
                className={styles.menuItem}
                icon={<FlagIcon languageCode={language.itemCode} />}
                data-test-id={`md-select-language-${language.itemCode}`}
              >
                {language.text}
              </Menu.Item>
            ))}
          </Menu>
        }
        visible={open}
        onVisibleChange={handleVisibleChange}
        trigger="click"
      >
        {!isDisabled && (
          <div
            className={styles.footer}
            role="button"
            data-test-id="md-add-new-language"
          >
            <Button type="link" size="s">
              {formatMessage({ id: "classification.category.addNew" })}
            </Button>
            <Icon name="arrow-right" />
          </div>
        )}
      </Popover>
    );
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      footer={renderFooter}
      className={cn(styles.root, { [styles.hideBody]: data.length < 1 })}
      pagination={false}
    />
  );
};

export default CategoryTranslationTable;
