import { useIntl } from "react-intl";

import { toaster as toasterService } from "@trace-one/design-system";
import { ArgsProps } from "antd/lib/notification";

import { messages } from "./messages";

type ToastOptions = Partial<ArgsProps>;
type ErrorResponseArgToast = {
  error?: any;
  checkErrorResponse?: boolean;
  description?: string;
};
type ToastOptionsWithError = ToastOptions & ErrorResponseArgToast;

export default function useToast() {
  const intl = useIntl();

  const addSuccess = (options: ToastOptions) => {
    toasterService.open({
      message: intl.formatMessage(messages.confirmation),
      ...options,
      type: "confirmation",
    });
  };

  const addError = ({
    error,
    checkErrorResponse = true,
    ...options
  }: ToastOptionsWithError) => {
    let shouldDisplay = false;
    if (checkErrorResponse) {
      if (!!error?.response) {
        shouldDisplay = true;
      }
    } else {
      shouldDisplay = true;
    }
    if (shouldDisplay) {
      toasterService.open({
        message: intl.formatMessage(messages.alert),
        ...options,
        type: "alert",
      });
    }
  };

  return {
    success: addSuccess,
    error: addError,
    info: (options: ToastOptions) => {
      toasterService.open({
        message: intl.formatMessage(messages.information),
        ...options,
        type: "information",
      });
    },
    saveSuccess: () => {
      addSuccess({
        description: intl.formatMessage(messages.confirmSavingDescription),
      });
    },
    saveError: ({
      error,
      checkErrorResponse = true,
      description,
    }: ErrorResponseArgToast) => {
      addError({
        error,
        checkErrorResponse,
        description:
          description ?? intl.formatMessage(messages.alertSavingDescription),
      });
    },
    fetchError: ({
      error,
      checkErrorResponse = true,
    }: ErrorResponseArgToast) => {
      addError({
        error,
        checkErrorResponse,
        description: intl.formatMessage(
          messages.alertUnidentifiedErrorDescription
        ),
      });
    },
  };
}
