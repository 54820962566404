import React from "react";
import { Switch, Route } from "react-router-dom";

import { USER_PROFILE_TILE } from "@trace-one/business-components";
import BrandsRouting from "pages/Brands/routing";
import ClassificationsRouting from "pages/Classifications/routing";
import DocumentsRouting from "pages/Documents/routing";
import Downloads from "pages/Downloads";
import Home from "pages/Home";
import NotificationSettings from "pages/NotificationSettings";
import ProductsRouting from "pages/Products/routing";
import SitesRouting from "pages/Sites/routing";
import SuppliersRouting from "pages/Suppliers/routing";

import usePermissions from "core/oidc/usePermissions";

import AuthorizedRoute from "components/AuthorizedRoute";
import RouteIfNotFound from "components/RouteIfNotFound";

import UserProfile from "./pages/UserPage";

const AppRouting: React.FC = () => {
  const { hasAccessToMd } = usePermissions();

  return (
    <Switch>
      <Route path={["/", "/home"]} component={Home} exact />
      <AuthorizedRoute
        exact
        path={["/notifications-settings", "/notifications-settings/:zone"]}
        canActive={hasAccessToMd}
        component={NotificationSettings}
      />
      <AuthorizedRoute
        path="/download"
        canActive={hasAccessToMd}
        component={Downloads}
        exact
      />
      <AuthorizedRoute
        path="/products"
        canActive={hasAccessToMd}
        component={ProductsRouting}
      />
      <AuthorizedRoute
        path="/sites"
        canActive={hasAccessToMd}
        component={SitesRouting}
      />

      <AuthorizedRoute
        path="/suppliers"
        component={SuppliersRouting}
        canActive={hasAccessToMd}
      />

      <AuthorizedRoute
        path="/brands"
        canActive={hasAccessToMd}
        component={BrandsRouting}
      />

      <AuthorizedRoute
        path="/classifications"
        canActive={hasAccessToMd}
        component={ClassificationsRouting}
      />

      <AuthorizedRoute
        path="/documents"
        canActive={hasAccessToMd}
        component={DocumentsRouting}
      ></AuthorizedRoute>

      <AuthorizedRoute
        path="/user-profile/my-settings"
        canActive={hasAccessToMd}
        component={() => (
          <UserProfile section={USER_PROFILE_TILE.MY_SETTINGS} />
        )}
        exact
      />

      <AuthorizedRoute
        path="/user-profile/notification-settings"
        canActive={hasAccessToMd}
        component={() => (
          <UserProfile section={USER_PROFILE_TILE.NOTIFICATION_SETTINGS} />
        )}
        exact
      />

      <RouteIfNotFound />
    </Switch>
  );
};

export default AppRouting;
