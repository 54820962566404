import { Card } from "antd";

import styles from "./Panel.module.less";

interface PanelProps {
  title?: string | boolean;
  children: React.ReactNode;
}

const Panel: React.FC<PanelProps> = ({ title, children }) => {
  return (
    <Card className={styles.panelWrapper}>
      {title && (
        <div className={styles.titleWrapper}>
          <h3>{title}</h3>
        </div>
      )}
      <div className={styles.panel}>{children}</div>
    </Card>
  );
};

export default Panel;
