import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Heading, Paragraph, Tooltip } from "@trace-one/design-system";
import { SectionsIds } from "pages/Sites/constants";

import usePermissions from "core/oidc/usePermissions";

import {
  selectCountriesOptions,
  selectFunctionOptions,
  selectRelatedCompanyOptions,
} from "reduxStore/shared/selectors";
import {
  selectSite,
  selectSiteRelations,
} from "reduxStore/siteSupplierDetails/selectors";

import Label from "components/Label";
import Panel from "components/Panel";
import TabPage from "components/TabPage";
import { capitalizeText } from "shared/utils/capitalizeFullText";

import styles from "../../SiteSupplierDetails.module.less";

const SiteUsageBloc = () => {
  const intl = useIntl();

  const relatedCompanies = useSelector(selectRelatedCompanyOptions);
  const siteRelation = useSelector(selectSiteRelations);
  const site = useSelector(selectSite);
  const countries = useSelector(selectCountriesOptions);
  const functions = useSelector(selectFunctionOptions);
  const history = useHistory();
  const { sitesWrite } = usePermissions();

  const siteCompanyRelation = siteRelation
    .filter(s => s.ownerCompanyRelationStatus === true)
    .map(s => relatedCompanies.find(c => c.value === s.associatedCompanyId));

  return (
    <>
      <Panel>
        <TabPage.SecondaryTitle
          label={
            <Heading size="xs">
              {capitalizeText(
                intl.formatMessage({ id: "siteDetails.siteUsage" })
              )}
            </Heading>
          }
        />
        {site.siteActive && sitesWrite && (
          <Button
            type="link"
            className={styles.iconBtn}
            data-test-id="privateInfo-button"
            iconName="edit"
            iconPlacement="left"
            onClick={() => {
              history.push({
                pathname: `/sites/edit/${site.id}`,
                hash: SectionsIds.VISIBILITY,
              });
            }}
          >
            {intl.formatMessage({ id: "general.edit" })}
          </Button>
        )}
        <Label
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "siteDetails.visibleBy" })}
            </Heading>
          }
          bordered
          testId="site-usage"
        >
          <Paragraph size="m">
            {siteCompanyRelation
              ?.filter(relation => !!relation?.name)
              .map((relation, idx) => relation.name)
              .join(", ")}
          </Paragraph>
        </Label>
      </Panel>

      <div style={{ marginTop: 30 }}>
        <Panel>
          <TabPage.SecondaryTitle
            label={
              <Heading size="xs">
                {capitalizeText(
                  intl.formatMessage({ id: "siteForm.function" })
                )}
              </Heading>
            }
          />
          {site.siteActive && sitesWrite && (
            <Button
              type="link"
              className={styles.iconBtn}
              data-test-id="privateInfo-button"
              iconName="edit"
              iconPlacement="left"
              onClick={() => {
                history.push({
                  pathname: `/sites/edit/${site.id}`,
                  hash: SectionsIds.ACTIVITIES,
                });
              }}
            >
              {intl.formatMessage({ id: "general.edit" })}
            </Button>
          )}
          <Label
            label={
              <Heading size="xxs">
                {intl.formatMessage({ id: "general.activities" })}
              </Heading>
            }
            testId="site-activities"
          >
            <Paragraph size="m">
              {site?.siteFunctions
                ?.map(
                  (functionId, idx) =>
                    functions.find(i => functionId === i.value)?.name
                )
                .join(", ")}
            </Paragraph>
          </Label>

          <Label
            label={
              <Heading size="xxs">
                {intl.formatMessage({
                  id: "siteDetails.operationsPerformed",
                })}
              </Heading>
            }
            testId="site-ops"
          >
            <Paragraph size="m">
              <Tooltip
                showFullText
                text={site?.operationManaged}
                placement="top"
              >
                <div className={styles.siteSupplierDetailsParagraph}>
                  {site?.operationManaged}
                </div>
              </Tooltip>
            </Paragraph>
          </Label>

          <Label
            label={
              <Heading size="xxs">
                {intl.formatMessage({ id: "siteDetails.countryOutside" })}
              </Heading>
            }
            testId="site-approval"
          >
            <Paragraph size="m">
              {site?.countriesOutsideOfEurope
                .map(country => countries.find(c => c.value === country)?.name)
                .filter(Boolean)
                .join(", ")}
            </Paragraph>
          </Label>
        </Panel>
      </div>
    </>
  );
};

export default SiteUsageBloc;
