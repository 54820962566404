import { createSlice } from "@reduxjs/toolkit";

import { fetchProduct } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "productDetails",
  initialState,
  reducers: {
    clearProductDetails: () => initialState,
    setProductFormData: (state, action) => {
      state.productFormData = action.payload;
    },
    setProductFormError: (state, action) => {
      state.productFormError = {
        ...state.productFormError,
        ...action.payload,
      };
    },
    clearProductFormData: state => {
      state.productFormData = {};
    },
    setProductBrandId: (state, action) => {
      state.productBrandId = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProduct.pending, (state, action) => {
        state.isProductLoading = true;
        state.hasProductError = false;
        state.errorStatus = null;
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.productData = action.payload;
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        state.isProductLoading = false;
        state.productData = {};
        state.hasProductError = true;
        state.errorStatus = (action.payload as any).status;
      });
  },
});

export const {
  clearProductDetails,
  setProductFormData,
  setProductFormError,
  clearProductFormData,
  setProductBrandId,
} = slice.actions;
export default slice.reducer;
