import { useState } from "react";
import { useIntl } from "react-intl";

import { Button } from "@trace-one/design-system";
import { Typography } from "antd";
import { RcFile } from "antd/lib/upload";
import classNames from "classnames";
import PropTypes from "prop-types";

import Icon from "components/Icon";
import IconButton from "components/IconButton";
import OldIcon from "components/OldIcon";
import UploadDragger from "components/UploadDragger";
import useToast from "shared/hooks/useToast";

import styles from "./CsvImport.module.less";

export interface CsvImportProps {
  fileSizeLessThanMB: number;
  onImport: ({ file }: { file: RcFile }) => Promise<boolean>;
}

const CsvImport: React.FC<CsvImportProps> = ({
  fileSizeLessThanMB,
  onImport,
}) => {
  const intl = useIntl();
  const toast = useToast();

  const [file, setFile] = useState<RcFile>(null);
  const [loading, setLoading] = useState(false);

  const handleImportFile = async () => {
    setLoading(true);
    try {
      const success = await onImport({ file });
      if (success) {
        setFile(null);
      }
      setLoading(false);
    } catch (error) {
      toast.error({
        checkErrorResponse: false,
        description: intl.formatMessage({ id: "import.csv.importFailed" }),
      });
      setLoading(false);
    }
  };

  return (
    <div className={styles.root} data-test-id="md-import-wrapper">
      <UploadDragger
        accept=".csv, text/csv, application/csv"
        fileSizeLessThanMB={fileSizeLessThanMB}
        beforeUpload={(file, fileList) => {
          setFile(file);
          return false;
        }}
        fileList={[]}
        data-test-id="md-import-input"
        className={classNames({ [styles.uploadHide]: !!file })}
      >
        <div className={styles.beforeUpload}>
          <span className={styles.uploadIcon}>
            <Icon
              name="drag-and-drop"
              size="small"
              color="primary"
              useTOLibrary
            />
          </span>
          <span>{intl.formatMessage({ id: "import.csv" })}</span>
        </div>
      </UploadDragger>
      {file && (
        <div className={styles.afterUpload}>
          <div className={styles.uploadBlock}>
            <OldIcon
              name="file"
              className={styles.fileIcon}
              useTOLibrary
              size={25}
            />
            <div className={styles.uploadInformation}>
              <Typography.Text ellipsis title={file.name}>
                {file.name}
              </Typography.Text>
              <Typography.Text ellipsis>
                {intl.formatMessage(
                  { id: "general.sizeKB" },
                  {
                    sizeInKB: (file.size / 1024).toFixed(3),
                  }
                )}
              </Typography.Text>
            </div>
            <IconButton
              className={styles.deleteFile}
              onClick={() => {
                setFile(null);
              }}
              disabled={loading}
              data-test-id="md-import-delete"
            >
              <OldIcon name="delete" useTOLibrary />
            </IconButton>
          </div>
          <Button
            onClick={handleImportFile}
            data-test-id="md-import-btn"
            loading={loading}
          >
            {intl.formatMessage({ id: "general.import" })}
          </Button>
        </div>
      )}
    </div>
  );
};

CsvImport.propTypes = {
  fileSizeLessThanMB: PropTypes.number,
  onImport: PropTypes.func.isRequired,
};

export default CsvImport;
