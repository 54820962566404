import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  Button,
  BadgeList,
  Tooltip,
  TagV1 as Tag,
  Icon,
} from "@trace-one/design-system";
import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import cn from "classnames";

import { CompanyData, SiteData } from "models";

import { CompanyType, SiteCode } from "shared/constants";
import buildCompanyTitle from "shared/utils/buildCompanyTitle";

import { SiteColumn } from "../modals";
import SiteAction from "../SiteAction";
import styles from "../SiteTable.module.less";

type UseSiteTableArgs = {
  sites: SiteData[];
  countriesMap: { [itemCode: string]: string };
  companiesMap: { [companyId: string]: CompanyData };
  isRetailer: boolean;
  refetchSites: () => void;
  handleSiteRelationModal: (param) => void;
};

const mapSiteToColumn = ({
  site,
  countriesMap,
  companiesMap,
}: {
  site: SiteData;
  countriesMap: UseSiteTableArgs["countriesMap"];
  companiesMap: UseSiteTableArgs["companiesMap"];
}) => {
  const {
    id,
    siteName,
    identifierCodes: codes,
    siteCity,
    ownerCompanyId,
    siteRelations,
    siteCountry,
    siteRegion,
    associatedCompanyStatus,
    associatedCompanyIdentifier,
    ownerCompanyRelationStatus,
    siteActive,
    siteRelationContacts,
  } = site;

  return {
    key: id,
    siteName,
    siteCode: codes?.find(c => c.typeId === SiteCode.SITE_TYPE)?.value,
    siteCity,
    associatedCompanyStatus,
    associatedCompanyIdentifier,
    siteStatus: ownerCompanyRelationStatus,
    siteActive: siteActive,
    siteCountry: siteCountry && countriesMap[siteCountry],
    siteOwnerCompany: ownerCompanyId
      ? [
          buildCompanyTitle(companiesMap[ownerCompanyId]),
          companiesMap[ownerCompanyId]?.companyRelationExternalName
            ? companiesMap[ownerCompanyId].companyDisplayName
            : "",
        ]
      : [],
    siteOwnerPrivate:
      companiesMap[ownerCompanyId]?.companyType === CompanyType.PRIVATE,
    siteRelations:
      siteRelations?.associatedCompanyIds?.map(
        s => companiesMap[s]?.companyDisplayName
      ) || [],
    siteRegion,
    ownerCompanyId,
    siteRelationContacts,
  };
};

export default function useSiteTable({
  sites,
  countriesMap,
  companiesMap,
  isRetailer,
  refetchSites,
  handleSiteRelationModal,
}: UseSiteTableArgs) {
  const intl = useIntl();
  const history = useHistory();

  const ownerCompanyColumn = [
    {
      title: intl.formatMessage({ id: "sitesListPage.table.myCode" }),
      dataIndex: "associatedCompanyIdentifier",
      key: "associatedCompanyIdentifier",
      width: 100,
      ellipsis: true,
      render: (_, record) => (
        <>
          <Tooltip
            showFullText
            text={record.associatedCompanyIdentifier}
            placement="top"
          >
            <div className={styles.siteListTooltip}>
              {record.associatedCompanyIdentifier}
            </div>
          </Tooltip>
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: "siteForm.ownerCompany" }),
      dataIndex: "siteOwnerCompany",
      key: "siteOwnerCompany",
      width: 120,
      ellipsis: true,
      render: (supplier, key) => {
        return (
          <Typography.Link
            onClick={() => {
              history.push(`/suppliers/${key.ownerCompanyId}`);
            }}
          >
            <div
              className={cn(
                styles.supplierTableName,
                key?.siteOwnerPrivate && styles.privateCompanyTitle
              )}
            >
              {supplier.map(name => name && <span>{name}</span>)}
            </div>
          </Typography.Link>
        );
      },
    },
  ];

  const supplierVisibleColumn = [
    {
      title: intl.formatMessage({ id: "sitesListPage.table.visibleBy" }),
      dataIndex: "siteRelations",
      key: "key",
      width: 120,
      render: relations => {
        const responsibilitiesList = relations;
        let text = responsibilitiesList.slice(0, 1);
        if (responsibilitiesList.length === 0) {
          return null;
        } else if (responsibilitiesList.length === 1) {
          return (
            <span>
              <Tooltip showFullText text={text} placement="top">
                {text}
              </Tooltip>
            </span>
          );
        } else {
          return (
            <div style={{ display: "flex" }}>
              <span className={styles.visibleBy} style={{ marginRight: 10 }}>
                <Tooltip showFullText text={text} placement="top">
                  {text}
                </Tooltip>
              </span>
              <BadgeList options={responsibilitiesList.slice(1)}> </BadgeList>
            </div>
          );
        }
      },
      ellipsis: true,
    },
  ];

  const retailerColumns = [
    {
      title: intl.formatMessage({ id: "siteForm.contact" }),
      dataIndex: "siteRelationContact",
      key: "siteRelationContact",
      ellipsis: true,
      width: 100,
      render: (_, record) => {
        let count = record?.siteRelationContacts?.length;
        return (
          <>
            {count === 0 ? (
              "-"
            ) : (
              <>
                <Button
                  type="link"
                  data-test-id="md-siteRelation-modal"
                  onClick={() => handleSiteRelationModal(record)}
                >
                  <div>{count}</div>
                </Button>
              </>
            )}
          </>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "general.status" }),
      dataIndex: "siteStatus",
      key: "siteStatus",
      width: 90,
      ellipsis: true,
      render: status => {
        let statusData = {
          label: intl.formatMessage({ id: "general.inactive" }),
          color: "grey",
        };
        if (status) {
          statusData = {
            label: intl.formatMessage({ id: "general.active" }),
            color: "green",
          };
        }

        return (
          <div className={styles.status}>
            <Tag mode="light" {...statusData} />
          </div>
        );
      },
    },
  ];

  const supplierColumns = [
    {
      title: intl.formatMessage({ id: "general.status" }),
      dataIndex: "siteActive",
      key: "siteActive",
      width: 100,
      ellipsis: true,
      render: status =>
        status ? (
          <div className={styles.status}>
            <Tag
              label={intl.formatMessage({ id: "general.active" })}
              color="green"
              mode="light"
            />
          </div>
        ) : (
          <div className={styles.status}>
            <Tag
              label={intl.formatMessage({ id: "general.inactive" })}
              color="grey"
              mode="light"
            />
          </div>
        ),
    },
  ];

  const columns: ColumnsType<SiteColumn> = [
    {
      title: intl.formatMessage({ id: "sitesListPage.table.name" }),
      dataIndex: "siteName",
      key: "siteName",
      width: 140,
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            history.push(`/sites/${record.key}`);
          }}
        >
          <div>{text}</div>
        </Button>
      ),
      ellipsis: true,
    },
    ...(!isRetailer ? supplierVisibleColumn : []),
    ...(isRetailer ? ownerCompanyColumn : []),
    ...(!isRetailer
      ? [
          {
            title: intl.formatMessage({ id: "sitesListPage.table.code" }),
            dataIndex: "siteCode",
            key: "siteCode",
            ellipsis: true,
            width: 90,
            render: (_, record) => (
              <>
                <Tooltip showFullText text={record.siteCode} placement="top">
                  <div className={styles.siteListTooltip}>
                    {record.siteCode}
                  </div>
                </Tooltip>
              </>
            ),
          },
        ]
      : []),
    {
      title: intl.formatMessage({ id: "sitesListPage.table.city" }),
      dataIndex: "siteCity",
      key: "siteCity",
      ellipsis: true,
      width: 90,
      render: (_, record) => (
        <>
          <Tooltip showFullText text={record.siteCity} placement="top">
            <div className={styles.siteListTooltip}>{record.siteCity}</div>
          </Tooltip>
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: "general.country" }),
      dataIndex: "siteCountry",
      key: "siteCountry",
      ellipsis: true,
      width: 100,
      render: (_, record) => (
        <>
          <Tooltip showFullText text={record.siteCountry} placement="top">
            <div className={styles.siteListTooltip}>{record.siteCountry}</div>
          </Tooltip>
        </>
      ),
    },
    ...(isRetailer ? retailerColumns : supplierColumns),
    {
      title: <Icon style={{ margin: "auto" }} name="settings" color="white" />,
      dataIndex: "actions",
      key: "actions",
      width: 40,
      fixed: "right",
      ellipsis: true,
      render: (_, record) => (
        <SiteAction
          isRetailer={isRetailer}
          record={record}
          refetchSites={refetchSites}
        />
      ),
    },
  ];

  const data = sites.map(site =>
    mapSiteToColumn({
      site,
      countriesMap,
      companiesMap,
    })
  );
  return { columns, data };
}
