import { useEffect, useState } from "react";

import { DisplayCard, Pagination } from "@trace-one/design-system";
import cn from "classnames";
import { format } from "date-fns";

import Spinner from "components/Spinner";
import getUsersCollectionMap, {
  UserCollectionMapType,
} from "shared/utils/getUsersCollectionMap";

import styles from "./DocumentCards.module.less";

const DocumentCards = ({
  documents,
  isLoading,
  paginationQuery,
  skipAndTakeQueryStats,
  setPaginationQuery,
  selectedRowKeys,
  setSelectedRowKeys,
  isFileInfoVisible,
  isFilesSelectionVisible,
}) => {
  const [usersMap, setUsersMap] = useState<UserCollectionMapType>({});
  const totalDocuments = skipAndTakeQueryStats?.totalCount ?? 0;
  const pageSize = paginationQuery.take ?? 20;
  const calculateSkipFromPageNoAndSize = (pageNo: number, pageSize: number) => {
    return (pageNo - 1) * pageSize;
  };

  useEffect(() => {
    if (documents.length > 0) {
      const uploadedUserIds = new Set<string | null>();

      for (const doc of documents) {
        uploadedUserIds.add(doc.uploadedBy);
      }
      getUsersCollectionMap([...uploadedUserIds].filter(Boolean)).then(
        users => {
          setUsersMap(Object.assign({}, usersMap, users));
        }
      );
    }
  }, [documents]);

  // const getUploadedUserName = (uploadedBy: string) => {
  //   if (uploadedBy) {
  //     return usersMap[uploadedBy]?.userName ?? "";
  //   }

  //   return "";
  // };

  return (
    <div className={styles.tilesLayout}>
      {isLoading ? (
        <Spinner className={styles.mosaicListSpinner} />
      ) : (
        <div
          className={cn(styles.documentCardsLayout, {
            [styles.withDocumentDetailsView]:
              isFileInfoVisible || isFilesSelectionVisible,
          })}
        >
          {documents.map(doc => {
            const {
              fileId,
              fileName,
              fileTypeName,
              version,
              // ownerCompanyId,
              uploadedAt,
            } = doc as any;

            const fileProps = {
              title: fileName,
              // fileType,
              subtitle: fileTypeName,
              tertiaryTitle: format(new Date(uploadedAt), "dd/MM/yyyy"),
              version: `V${version}`,
              selected: selectedRowKeys.includes(fileId),
              iconName: "document",
            };

            return (
              <DisplayCard
                key={fileId}
                {...fileProps}
                onClick={() => {
                  setSelectedRowKeys(current => {
                    if (current.includes(fileId)) {
                      return current.filter(x => x !== fileId);
                    }
                    return [...current, fileId];
                  });
                }}
              />
            );
          })}
        </div>
      )}

      <section className="d-flex justify-content-end">
        <Pagination
          total={totalDocuments}
          current={Math.floor(paginationQuery.skip / paginationQuery.take) + 1}
          pageSize={pageSize}
          showSizeChanger
          pageSizeOptions={[20, 40, 60, 80]}
          onChange={(page, pageSize) => {
            const skip = calculateSkipFromPageNoAndSize(page, pageSize);
            setPaginationQuery({ skip, take: pageSize });
          }}
          onShowSizeChange={(page, pageSize) => {
            const skip = calculateSkipFromPageNoAndSize(page, pageSize);
            setPaginationQuery({ skip, take: pageSize });
          }}
        />
      </section>
    </div>
  );
};

export default DocumentCards;
