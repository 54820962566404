import { createSlice } from "@reduxjs/toolkit";

import { fetchBrandsTable } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "brandList",
  initialState,
  reducers: {
    clearBrands: (state, action) => {
      state.brands = initialState.brands;
      state.brandsSkipAndTakeQueryStats =
        initialState.brandsSkipAndTakeQueryStats;
      state.isBrandsLoading = false;
      state.hasBrandsError = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchBrandsTable.pending, (state, action) => {
        state.isBrandsLoading = true;
        state.hasBrandsError = false;
      })
      .addCase(fetchBrandsTable.fulfilled, (state, action) => {
        state.brands = action.payload.brands;
        state.brandsSkipAndTakeQueryStats =
          action.payload.skipAndTakeQueryStats;
        state.isBrandsLoading = false;
      })
      .addCase(fetchBrandsTable.rejected, (state, action) => {
        state.brands = [];
        state.isBrandsLoading = false;
        state.hasBrandsError = true;
        state.brandsSkipAndTakeQueryStats =
          initialState.brandsSkipAndTakeQueryStats;
      });
  },
});

export const { clearBrands } = slice.actions;
export default slice.reducer;
