import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectSiteSupplierDetails = (state: RootState) =>
  state.siteSupplierDetails;

export const selectSite = createSelector(
  _selectSiteSupplierDetails,
  ({ site }) => site.data
);

export const selectSupplier = createSelector(
  _selectSiteSupplierDetails,
  ({ supplier }) => supplier.data
);

export const selectContactUsers = createSelector(
  _selectSiteSupplierDetails,
  ({ contactUsers }) => contactUsers.data
);

export const selectSiteSupplierFormData = createSelector(
  _selectSiteSupplierDetails,
  ({ siteSupplierFormData }) => siteSupplierFormData.data
);

export const selectSiteCertificateFormData = createSelector(
  _selectSiteSupplierDetails,
  ({ siteCertificateFormData }) => siteCertificateFormData.data
);

export const selectSiteSupplierFormError = createSelector(
  _selectSiteSupplierDetails,
  ({ siteSupplierFormError }) => siteSupplierFormError
);
export const selectSiteRelations = createSelector(
  _selectSiteSupplierDetails,
  ({ siteRelation }) => siteRelation.data
);

export const selectSiteInitiated = createSelector(
  _selectSiteSupplierDetails,
  ({ site }) => Object.keys(site.data).length !== 0
);
export const selectIsSiteLoading = createSelector(
  _selectSiteSupplierDetails,
  ({ site, siteRelation }) => site.isLoading || siteRelation.isLoading
);

export const selectHasSiteEditError = createSelector(
  _selectSiteSupplierDetails,
  ({ site, siteRelation }) => site.hasError || siteRelation.hasError
);

export const selectSiteErrorStatus = createSelector(
  _selectSiteSupplierDetails,
  ({ site }) => site.errorStatus
);
