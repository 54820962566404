import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectClassificationDetails = (state: RootState) =>
  state.classificationDetails;

export const selectClassificationItem = createSelector(
  _selectClassificationDetails,
  ({
    classification,
    isClassificationLoading,
    hasClassificationError,
    errorStatus,
  }) => ({
    classification,
    isClassificationLoading,
    hasClassificationError,
    errorStatus,
  })
);
export const selectCategoryList = createSelector(
  _selectClassificationDetails,
  ({ categoryList }) => categoryList
);

export const selectGlobalCompanyName = createSelector(
  _selectClassificationDetails,
  ({ classification }) => {
    return classification.companyId === "00000000-0000-0000-0000-000000000000"
      ? "TRACE ONE"
      : null;
  }
);

export const selectGenericCategory = createSelector(
  _selectClassificationDetails,
  ({ classification }) => {
    return classification.companyId === "00000000-0000-0000-0000-000000000000";
  }
);

export const selectClassificationCompanyId = createSelector(
  _selectClassificationDetails,
  ({ classification }) => classification.companyId
);
