import { useState } from "react";
import { useIntl } from "react-intl";

import { Heading } from "@trace-one/design-system";

import FormModal from "components/FormModal";

import CreateClassificationForm, {
  CreateClassificationFormProps,
} from "./CreateClassificationForm";

interface CreateClassificationModalProps extends CreateClassificationFormProps {
  visible: boolean;
  onCancel: () => void;
  refetchClassifications: () => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}

const CreateClassificationModal: React.FC<CreateClassificationModalProps> = ({
  visible,
  onCancel,
  refetchClassifications,
  initialValues,
  isEditing,
  setIsEditing,
}) => {
  const intl = useIntl();
  const [isClosable, setIsClosable] = useState(true);

  return (
    <FormModal
      title={
        isEditing ? (
          <Heading size="m">
            {intl.formatMessage({ id: "classification.editmodel.title" })}{" "}
          </Heading>
        ) : (
          <Heading size="m">
            {intl.formatMessage({ id: "classification.createClassification" })}{" "}
          </Heading>
        )
      }
      visible={visible}
      onCancel={onCancel}
      closable={isClosable}
    >
      <CreateClassificationForm
        setIsClosable={setIsClosable}
        initialValues={initialValues}
        onSubmitSuccess={() => {
          onCancel();
          refetchClassifications && refetchClassifications();
        }}
        onCancel={onCancel}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </FormModal>
  );
};

export default CreateClassificationModal;
