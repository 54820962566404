import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectCategoryItemForm = (state: RootState) =>
  state.categoryItemForm;

export const selectCategoryItemFormData = createSelector(
  _selectCategoryItemForm,
  ({
    isCategoryitemLoading,
    categoryItemData,
    hasCategoryItemError,
    errorStatus,
    categoryItemText,
  }) => ({
    isCategoryitemLoading,
    categoryItemData,
    hasCategoryItemError,
    errorStatus,
    categoryItemText,
  })
);
