import { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";

import { Graphic } from "@trace-one/design-system";
import PropTypes from "prop-types";

import { RlmdAPI } from "apis";

import { selectCategoryListTD } from "reduxStore/shared/selectors";
import {
  selectUserLanguageCode,
  selectUserOwningCompanyId,
} from "reduxStore/user/selectors";

import TreeSelect from "components/TreeSelect";
import getUniqueListFactory from "shared/utils/getUniqueListFactory";
import parseToJSON from "shared/utils/parseToJSON";

import useCategoryItemsByLanguage from "./hooks/useCategoryItemsByLanguage";
import useCategoryItemsByName from "./hooks/useCategoryItemsByName";
import mapToCategoriesValue from "./utils/mapToCategoriesValue";
import mapToCategoryItemsTD from "./utils/mapToCategoryItemsTD";

const getUniqueTreeData = getUniqueListFactory(
  (item, arr) => arr.findIndex(({ id }) => id === item.id) === -1
);

const CategoryTreeSelect = ({
  value,
  onChange,
  multiple,
  onFullChange,
  multiParentSelection = false,
  categorySelection,
  ...rest
}) => {
  const minLengthToAsyncSearch = 3;
  const languageCode = useSelector(selectUserLanguageCode);
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const categoryListTD = useSelector(selectCategoryListTD).map(
    categoryList => ({
      ...categoryList,
      selectable: categorySelection,
    })
  );

  const [searchItemName, setSearchItemName] = useState("");
  const [treeExpandedKeys, setTreeExpandedKeys] = useState([]);

  // Handler for passing labels outside component and for using `labelInValue`, clear value
  const [valueWithLabel, setValueWithLabel] = useState(value);
  const onValueWithLabelChange = value => {
    setValueWithLabel(value);
    onFullChange && onFullChange(value);
  };
  useEffect(() => {
    if (!value && valueWithLabel) {
      onValueWithLabelChange(value);
    }
  }, [value]);
  // It will fetch category items from expanding and each time language is changed
  const { categoryItemsTD, setCategoryItemsTD } = useCategoryItemsByLanguage({
    valueWithLabel,
    onValueWithLabelChange,
    languageCode,
    multiple,
  });
  // It will fetch category items depends on search input and language
  const { categoryItemsByNameTD, isLoading } = useCategoryItemsByName({
    minLengthToAsyncSearch,
    searchItemName,
    ownerCompanyId,
    languageCode,
    categoryItemsTD,
    setTreeExpandedKeys,
  });

  // TreeData must be merged to avoid conflict data
  const treeData = useMemo(
    () => [
      ...categoryListTD,
      ...getUniqueTreeData([...categoryItemsTD, ...categoryItemsByNameTD]),
    ],
    [categoryListTD, categoryItemsTD, categoryItemsByNameTD]
  );

  /* istanbul ignore next */
  const onLoadData = treeNode => {
    const { categoryListId, id } = treeNode;
    return RlmdAPI.getCategoryItemsByIdInDirectChildIds(categoryListId, {
      languageCode,
      parentId: treeNode.pId === 0 ? null : id,
    })
      .then(({ data }) => mapToCategoryItemsTD(data))
      .then(({ result }) => {
        setTreeExpandedKeys(prev => [...prev, id]);
        setCategoryItemsTD(prev => {
          const newSubTreeData = getUniqueTreeData([...prev, ...result]);
          return newSubTreeData;
        });
      })
      .catch(error => {
        console.warn("[CategoryTreeSelect] onLoadData error", error);
      });
  };

  // Hack because controlled expandedKeys has issue bug when toggle button if it is controlled
  const shouldExpandKeysBeControlled = categoryItemsByNameTD.length > 0;
  const expandProps = shouldExpandKeysBeControlled
    ? {
        treeExpandedKeys,
        onTreeExpand: expandedKeys => {
          setTreeExpandedKeys(expandedKeys);
        },
      }
    : {};

  const selectProps = {};
  if (multiple) {
    selectProps.value = valueWithLabel?.map(
      ({ categoryItemName, ...item }) => ({
        value: parseToJSON(item),
        label: categoryItemName,
      })
    );
    selectProps.onChange = newValue => {
      const result = mapToCategoriesValue(newValue, multiParentSelection);

      onValueWithLabelChange(result);
      onChange(
        result.map(({ categoryId, categoryItemId }) => ({
          categoryId,
          categoryItemId,
        }))
      );
    };
  } else {
    const { categoryItemName, ...item } = valueWithLabel ?? {};
    selectProps.value = valueWithLabel
      ? { value: parseToJSON(item), label: categoryItemName }
      : value;
    selectProps.onChange = newValue => {
      if (!newValue) {
        onChange(newValue);
        onValueWithLabelChange(newValue);
      } else {
        const { label, value } = newValue;
        const result = JSON.parse(value);
        onChange(result);
        onValueWithLabelChange({ categoryItemName: label, ...result });
      }
    };
  }

  return (
    <TreeSelect
      allowClear
      clearIcon={<Graphic name="close" size="small" />}
      minLengthToAsyncSearch={minLengthToAsyncSearch}
      loading={isLoading}
      multiple={multiple}
      style={{ width: "100%", height: "auto" }}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      {...rest}
      {...expandProps}
      {...selectProps}
      treeDataSimpleMode
      loadData={onLoadData}
      treeData={treeData}
      labelInValue
      searchValue={searchItemName}
      onSearch={setSearchItemName}
    />
  );
};

const CategoryItemProp = PropTypes.shape({
  categoryItemId: PropTypes.string,
  categoryItemName: PropTypes.string,
  categoryListId: PropTypes.string,
});

CategoryTreeSelect.propTypes = {
  value: PropTypes.oneOfType([
    CategoryItemProp,
    PropTypes.arrayOf(CategoryItemProp),
  ]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  onFullChange: PropTypes.func,
  multiParentSelection: PropTypes.bool,
  categorySelection: PropTypes.bool,
};

export default CategoryTreeSelect;
