import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Heading, UserCard } from "@trace-one/design-system";
import { SectionsIds } from "pages/Products/components/ProductForm/constants";

import usePermissions from "core/oidc/usePermissions";

import { selectProductDetailsData } from "reduxStore/productDetails/selectors";
import { selectTeamMemberResponsibilities } from "reduxStore/shared/selectors";

import Label from "components/Label";
import Panel from "components/Panel";
import TabPage from "components/TabPage";
import { TradeItemStatus, UserStatus } from "shared/constants";
import { capitalizeText } from "shared/utils/capitalizeFullText";

import styles from "../../../ProductDetails.module.less";

const ProductManagement = () => {
  const intl = useIntl();
  const history = useHistory();
  const { productData } = useSelector(selectProductDetailsData);
  const { productsWrite } = usePermissions();
  const teamReponsabilities = useSelector(selectTeamMemberResponsibilities);
  const productIsDisabled =
    productData.tradeItemStatusId === TradeItemStatus.INACTIVE;

  return (
    <Panel>
      <TabPage.SecondaryTitle
        label={
          <Heading size="xs">
            {capitalizeText(
              intl.formatMessage({ id: "productDetails.productManagement" })
            )}
          </Heading>
        }
      />
      {!productIsDisabled && productsWrite && (
        <Button
          type="link"
          className={styles.iconBtn}
          iconName="edit"
          iconPlacement="left"
          data-test-id="product-management-edit"
          onClick={() => {
            history.push({
              pathname: `/products/edit/${productData.id}`,
              hash: SectionsIds.ATTRIBUTES,
            });
          }}
        >
          {intl.formatMessage({ id: "general.edit" })}
        </Button>
      )}
      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "general.contacts" })}
          </Heading>
        }
        testId="productDetails-contacts"
      >
        {productData.contacts?.map((contact, idx) => {
          const userResponsabilty = teamReponsabilities.find(
            resp => contact.responsibilityId === resp.id
          );
          return (
            <span className="d-inline-block m-0-5" key={idx}>
              <UserCard
                size="xs"
                name={contact.userName}
                title={userResponsabilty?.text}
                photoUrl={contact.userPhotoUrl}
                disabled={contact?.userStatus !== UserStatus.ENABLED}
                inactiveTooltipText={intl.formatMessage({
                  id: "general.userInactive",
                })}
              ></UserCard>
            </span>
          );
        })}
      </Label>
    </Panel>
  );
};

export default ProductManagement;
