import { CountBadge } from "@trace-one/react-components";
import { Tabs } from "antd";

import "./tabHeader.less";

const { TabPane } = Tabs;

interface TabHeaderProp {
  tabs: {
    label: React.ReactNode;
    count?: number;
  }[];
  onChange: (activeKey: string) => void;
  activeTab: string;
}

const TabHeader: React.FC<TabHeaderProp> = ({ tabs, onChange, activeTab }) => {
  return (
    <div className="detailPageHeader__tabsWrapper">
      <Tabs defaultActiveKey={activeTab} onChange={onChange} tabBarGutter={10}>
        {tabs.map((tab, idx) => {
          const hasCount = tab.count && tab.count > 0 ? true : false;
          return (
            <TabPane
              tab={
                <div className="tabLabel">
                  <span data-test-id={`tab-${idx}-label`}>{tab.label}</span>

                  {hasCount && (
                    <div
                      className="countWrapper"
                      data-test-id={`tab-${idx}-count`}
                    >
                      <CountBadge count={tab.count} />
                    </div>
                  )}
                </div>
              }
              key={idx}
            />
          );
        })}
      </Tabs>
    </div>
  );
};

export default TabHeader;
