import { useSelector } from "react-redux";

import { NotificationsSettings as ToNotificationsSettings } from "@trace-one/react-components";
import NotificationAPI from "apis/NotificationAPI";

import { selectNotificationUserApplications } from "reduxStore/user/selectors";

const NotificationSettings = () => {
  const senderZoneIds = useSelector(selectNotificationUserApplications).map(
    ({ applicationTypeId }) => applicationTypeId
  );

  return (
    <ToNotificationsSettings
      notificationsApi={NotificationAPI}
      senderZoneIds={senderZoneIds}
      pathroot="/notifications-settings"
    />
  );
};

export default NotificationSettings;
