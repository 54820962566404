import { createSelector } from "@reduxjs/toolkit";

import { CompanyData } from "models";

import { RootState } from "reduxStore";

import buildCompanyTitle from "shared/utils/buildCompanyTitle";

export const _selectDocumentList = (state: RootState) => state.documentList;

export const selectDocuments = createSelector(
  _selectDocumentList,
  ({ documents }) => documents
);
export const selectDocumentsSkipAndTakeQueryStats = createSelector(
  _selectDocumentList,
  ({ documentListSkipAndTakeQueryStats }) => documentListSkipAndTakeQueryStats
);
export const selectIsDocumentTableLoading = createSelector(
  _selectDocumentList,
  ({ isDocumentListLoading }) => isDocumentListLoading
);

export const selectRelatedCompaniesOptions = createSelector(
  _selectDocumentList,
  ({ relatedCompanies }) => ({
    isInitiated: relatedCompanies.isInitiated,
    hasError: relatedCompanies.hasError,
    data: relatedCompanies.data.map(c => ({
      ...c,
      value: c.companyId,
      name: buildCompanyTitle(c),
    })),
  })
);

export const selectFileTypes = createSelector(
  _selectDocumentList,
  ({ fileTypes }) => fileTypes
);

export const selectProducts = createSelector(
  _selectDocumentList,
  ({ products }) =>
    products.map(c => ({
      value: c.id,
      name: `${c.itemName}${c.gtin ? ", " + c.gtin : ""}`,
    }))
);

export const selectSites = createSelector(_selectDocumentList, ({ sites }) =>
  sites.map(c => ({
    value: c.id,
    name: c.siteName,
    companyId: c.ownerCompanyId,
  }))
);

export const selectProjects = createSelector(
  _selectDocumentList,
  ({ projects }) =>
    projects.map(c => ({
      value: c.id,
      name: c.name,
    }))
);

export const selectCompaniesMapForTable = createSelector(
  _selectDocumentList,
  ({ companies }) => {
    const result: { [companyId: string]: CompanyData } = {};

    companies.forEach(company => (result[company.companyId] = company));
    return result;
  }
);
