import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { PmdAPI } from "apis";
import { ManufacturedItemData } from "models";

import { selectSupplierCompaniesData } from "reduxStore/productShared/selectors";

import useToast from "shared/hooks/useToast";

import { messages } from "./messages";

export default function useSaveManufacturedItems() {
  const intl = useIntl();
  const toast = useToast();

  const { data: supplierCompanies } = useSelector(selectSupplierCompaniesData);

  const saveManufacturedItem = async (
    tradeItemId: string,
    { id, ...manufacturedItem }: ManufacturedItemData
  ): Promise<{
    isSaved: boolean;
    id?: string;
    description: string;
    error?: any;
  }> => {
    const supplierName = supplierCompanies.find(
      item => item.companyId === manufacturedItem?.supplierCompanyId
    )?.companyDisplayName;
    try {
      const result = {
        isSaved: true,
        id,
        description: intl.formatMessage(messages.manufacturedItemSuccess, {
          supplierName,
        }),
      };
      if (id) {
        await PmdAPI.updateManufacturedItem(id, manufacturedItem);
      } else {
        const { data } = await PmdAPI.createManufacturedItem(
          tradeItemId,
          manufacturedItem
        );
        result.id = data.manufacturedItemId;
      }

      return result;
    } catch (error) {
      return {
        isSaved: false,
        error,
        description: intl.formatMessage(messages.manufacturedItemError, {
          supplierName,
        }),
      };
    }
  };

  const saveAllManufacturedItems = async ({
    tradeItemId,
    manufacturedItems,
    injectIdToManufacturedItems,
  }: {
    tradeItemId: string;
    manufacturedItems: ManufacturedItemData[];
    injectIdToManufacturedItems: ({
      manufacturedListIds,
    }: {
      manufacturedListIds: string[];
    }) => void;
  }) => {
    if (!(manufacturedItems?.length > 0)) {
      return true;
    }
    const promiseResponse = await Promise.all(
      manufacturedItems.map(data => saveManufacturedItem(tradeItemId, data))
    );
    const manufacturedListIds = promiseResponse.map(({ id }) => id);
    // injectIdToManufacturedItems({
    //   manufacturedListIds,
    // });

    const isAllDataSaved = !manufacturedListIds.includes(undefined);
    if (isAllDataSaved) {
      toast.success({
        description: intl.formatMessage(messages.allManufacturedItemsSuccess),
      });
    } else {
      promiseResponse.forEach(({ isSaved, error, description }) => {
        isSaved
          ? toast.success({ description })
          : toast.error({ error, description });
      });

      injectIdToManufacturedItems({
        manufacturedListIds,
      });
    }

    return isAllDataSaved;
  };

  return saveAllManufacturedItems;
}
