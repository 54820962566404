import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Button } from "@trace-one/design-system";
import { TagV1 as Tag, Tooltip } from "@trace-one/design-system";

import { selectIdentifiersOptions } from "reduxStore/shared/selectors";

import FlexWrap from "components/FlexWrap";
import Input from "components/Input";
import Select from "components/Select";
//import { ErrorCode, hasErrorOccurred } from "shared/errors";

import { SiteIdentification } from "../../../../constants";

import styles from "./IdentificationSection.module.less";

interface IdentifierContentTypes {
  typeId?: string;
  value?: string;
}

interface IdentifierSelectProps {
  value?: IdentifierContentTypes[];
  onChange?: (identifierContent: IdentifierContentTypes[]) => void;
  disabled?: boolean;
}

const IdentifierSelect: React.FC<IdentifierSelectProps> = props => {
  const { value: identifierCodes = [], onChange, disabled } = props;
  const intl = useIntl();
  const identifiersOptions = useSelector(selectIdentifiersOptions);
  //const [internalCodeError, setInternalCodeError] = useState(false);
  // const [restrictedInternalCode, setRestrictedInternalCode] = useState<
  //   string[]
  // >([]);
  const filterIdentifiersOptions = identifiersOptions.filter(
    item => item.value !== SiteIdentification.INTERNAL_CODE
  );

  const [identifierContent, setIdentifierContent] =
    useState<IdentifierContentTypes>({
      typeId: undefined,
      value: undefined,
    });

  // const checkInternalCodeHasError = error => {
  //   const indentifierError = ErrorCode.INTERNAL_CODE_ALREADY_EXISTED;
  //   const identifierErrorHasOccured = hasErrorOccurred({
  //     error,
  //     errorCode: indentifierError,
  //   });

  //   const internalCodeSelected = identifierCodes.find(
  //     ({ typeId }) => typeId === SiteIdentification.INTERNAL_CODE
  //   );

  //   if (!!identifierErrorHasOccured && !!internalCodeSelected) {
  //     setRestrictedInternalCode(codes => [
  //       ...new Set([...codes, internalCodeSelected.value]),
  //     ]);
  //     setInternalCodeError(true);
  //     return;
  //   }
  //   setInternalCodeError(false);
  // };

  // useEffect(() => {
  //   checkInternalCodeHasError(errorResponse);
  // }, [errorResponse]);

  //   useEffect(() => {
  //     const internalCodeSelected = identifierCodes.find(
  //       ({ typeId }) => typeId === SiteIdentification.INTERNAL_CODE
  //     );
  //     // setInternalCodeError(
  //     //   internalCodeSelected &&
  //     //     restrictedInternalCode.includes(internalCodeSelected?.value)
  //     // );
  //   }, [identifierCodes]);
  // //
  return (
    <div className={styles.root}>
      <div className={styles.inputWrapper}>
        <div style={{ flexGrow: 1, display: "flex", gap: "10px" }}>
          <Select
            disabled={disabled}
            allowClear
            data-test-id="md-site-indentifier-code"
            style={{ height: 42, width: "100%" }}
            options={filterIdentifiersOptions.filter(
              ({ value }) =>
                !identifierCodes.find(({ typeId }) => typeId === value)
            )}
            value={identifierContent.typeId}
            onChange={typeId => {
              setIdentifierContent(prev => ({
                ...prev,
                typeId,
              }));
            }}
          />
          <Input
            disabled={disabled}
            value={identifierContent.value}
            onChange={event => {
              const value = event.target.value;
              setIdentifierContent(prev => ({
                ...prev,
                value,
              }));
            }}
            maxLength={256}
            data-test-id="md-site-identifier-value"
          />
        </div>
        <Button
          style={{ marginLeft: 10 }}
          onClick={() => {
            onChange([...identifierCodes, identifierContent]);
            setIdentifierContent({ value: undefined, typeId: undefined });
          }}
          disabled={
            disabled ||
            !identifierContent?.value?.trim() ||
            !identifierContent.typeId
          }
          type="secondary"
          data-test-id="md-site-identifier-add"
        >
          {intl.formatMessage({ id: "general.add" })}
        </Button>
      </div>
      <FlexWrap>
        {identifierCodes.map(({ value, typeId }, index) => {
          const typeLabel = filterIdentifiersOptions.find(
            i => typeId === i.value
          )?.name;
          const label = `${typeLabel} : ${value}`;

          return (
            typeLabel && (
              <Tooltip showFullText text={label} placement="top">
                <Tag
                  key={`${typeId}-${index}`}
                  label={<span>{label}</span>}
                  data-test-id={`tag-${label}`}
                  onClose={() => {
                    onChange(
                      identifierCodes.filter(
                        item =>
                          !(item.typeId === typeId && item.value === value)
                      )
                    );
                  }}
                />
              </Tooltip>
            )
          );
        })}
      </FlexWrap>
    </div>
  );
};

export default IdentifierSelect;
