import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading, Datatable, Typography } from "@trace-one/design-system";
import { Empty, Modal } from "antd";

import { selectCertificateOptions } from "reduxStore/shared/selectors";

import FilePreview from "components/FilePreview";
import { mapDateToMomentLocal } from "shared/utils/dateUtils";

import { SiteVersionsResult } from "../../models";

import styles from "./CertificatesSection.module.less";

const CertificatesSection = ({
  versionDetails,
  previousVersionDetails,
}: {
  versionDetails: SiteVersionsResult;
  previousVersionDetails?: SiteVersionsResult;
}) => {
  const { formatMessage } = useIntl();
  const [fileModal, setFileModal] = useState({
    visible: false,
    certificateName: null,
    files: [],
  });

  const certificateList = useSelector(selectCertificateOptions);
  const certificates = versionDetails.siteCertificates.map(
    (certificate, key) => {
      const previousCertificate = previousVersionDetails?.siteCertificates.find(
        c => certificate.certificationId === c.certificationId
      );
      const certificateName = certificateList.find(
        c => c.value === certificate.certificationId
      )?.name;
      const certificateLabel = certificate.certificateLabel;
      const certificateKeyId = certificate.certificateKeyId;

      const certificateStartDate = mapDateToMomentLocal(
        certificate.certificateStartAvailabilityDate
      );
      const previousCertificateStartDate = mapDateToMomentLocal(
        previousCertificate &&
          previousCertificate.certificateStartAvailabilityDate
      );
      const certificateEndDate = mapDateToMomentLocal(
        certificate.certificateEndAvailabilityDate
      );
      const previousCertificateEndDate = mapDateToMomentLocal(
        previousCertificate &&
          previousCertificate.certificateEndAvailabilityDate
      );

      const otherNames = ["Other", "Autre"];

      return {
        key,
        certificateName: otherNames.includes(certificateName)
          ? certificateLabel
          : certificateName,
        certificateFiles: certificate.attachments,
        certificateStartDate: certificateStartDate,
        certificateEndDate: certificateEndDate,
        certificateKeyId,
        rowChanged: !previousCertificate,
        startDateChanged:
          previousCertificateStartDate?.format("L") !==
          certificateStartDate?.format("L"),
        endDateChanged:
          previousCertificateEndDate?.format("L") !==
          certificateEndDate?.format("L"),
        filesChanged:
          previousCertificate?.attachments?.length !==
          certificate?.attachments?.length,
      };
    }
  );

  return (
    <div className={styles.certificatesContainer}>
      <div className={styles.sectionHeader}>
        <Heading color="grey-4" size="s">
          {formatMessage({
            id: "siteDetails.certificatesTitle",
          })}
        </Heading>
        <hr />
      </div>
      <div className={styles.fieldSection}>
        <Datatable
          data-test-id="certification-table"
          columns={[
            {
              title: formatMessage({ id: "siteForm.certificateName" }),
              dataIndex: "certificateName",
              key: "certificateName",
              sorter: (a, b) =>
                a.certificateName?.localeCompare(b.certificateName),
              width: 130,
            },
            {
              title: formatMessage({
                id: "siteForm.certificateStartDate",
              }),
              dataIndex: "certificateStartDate",
              key: "certificateStartDate",
              render: (date, record) => (
                <Typography
                  className={record.startDateChanged && "changedField"}
                  component="span"
                >
                  {date?.format("L")}
                </Typography>
              ),
              sorter: (a, b) =>
                (b.certificateStartDate?.toDate()?.getTime() ?? 0) -
                (a.certificateStartDate?.toDate()?.getTime() ?? 0),
              width: 90,
            },
            {
              title: formatMessage({
                id: "siteForm.certificateEndDate",
              }),
              dataIndex: "certificateEndDate",
              key: "certificateEndDate",
              render: (date, record) => (
                <Typography
                  className={record.endDateChanged && "changedField"}
                  component="span"
                >
                  {date?.format("L")}
                </Typography>
              ),
              sorter: (a, b) =>
                (b.certificateEndDate?.toDate()?.getTime() ?? 0) -
                (a.certificateEndDate?.toDate()?.getTime() ?? 0),
              width: 90,
            },
            {
              title: formatMessage({ id: "siteForm.certificateFile" }),
              dataIndex: "certificateFiles",
              key: "certificateFiles",
              width: 100,
              render: (files, { certificateName, filesChanged }) =>
                files.length > 0 && (
                  <Typography
                    component="span"
                    className={filesChanged && "changedField"}
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      maxWidth: "100%",
                    }}
                    onClick={() =>
                      setFileModal({ visible: true, certificateName, files })
                    }
                  >
                    {formatMessage({ id: "siteForm.seeFiles" })}
                  </Typography>
                ),
            },
          ]}
          dataSource={certificates}
          rowClassName={record => record.rowChanged && "changedRow"}
          scroll={{ x: "100%" }}
          tableLayout="fixed"
          pagination={false}
          locale={{
            emptyText: (
              <Empty description={formatMessage({ id: "general.noData" })} />
            ),
          }}
        />
      </div>

      <Modal
        title={<Heading size="s">{fileModal.certificateName}</Heading>}
        visible={fileModal.visible}
        onCancel={() =>
          setFileModal({ visible: false, certificateName: null, files: [] })
        }
        footer={null}
      >
        <div>
          {" "}
          {fileModal.files.map(file => (
            <FilePreview key={file.fileId} file={file} />
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default CertificatesSection;
