import { useEffect, useRef } from "react";

import AXIOS_INSTANCES from "apis/axiosInstances";
import { AxiosInstance } from "axios";

import useToast from "shared/hooks/useToast";

function useInterceptor(
  axios: AxiosInstance,
  { accessToken }: { accessToken: string }
) {
  const accessTokenRef = useRef(accessToken);
  const toast = useToast();

  useEffect(() => {
    accessTokenRef.current = accessToken;
  }, [accessToken]);

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(config => {
      const token = accessTokenRef.current;
      if (!config.headers["Authorization"] && token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    });

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, [axios]);

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      undefined,
      error => {
        if (!error.response) {
          toast.fetchError({ checkErrorResponse: false });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [axios]);
}

export default function useInterceptorsSetup({
  accessToken,
}: {
  accessToken: string;
}) {
  Object.values(AXIOS_INSTANCES).forEach(axiosInstance => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useInterceptor(axiosInstance, { accessToken });
  });
}
