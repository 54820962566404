import styles from "../TabPageWrapper.module.less";

interface WrapperProps {
  children: React.ReactNode;
}
const Wrapper = ({ children }: WrapperProps) => {
  return <div className={styles.tabWrapper}>{children}</div>;
};

export default Wrapper;
