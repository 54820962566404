import { memo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading } from "@trace-one/design-system";
import { Form } from "antd";

import {
  selectFunctionOptions,
  selectCountriesOptions,
} from "reduxStore/shared/selectors";

import FormWrapper from "components/FormWrapper";
import Input from "components/Input";
import Select from "components/Select";

import { SectionsIds } from "../../../../constants";
import styles from "../SiteSupplierForm.module.less";

const ActivitySection = ({ disabled }) => {
  const intl = useIntl();
  const countryOptions = useSelector(selectCountriesOptions);
  const functionOptions = useSelector(selectFunctionOptions);

  return (
    <FormWrapper.Section
      title={
        <Heading size="m">
          {intl.formatMessage({ id: "siteForm.activitiesTitle" })}
        </Heading>
      }
      className={styles.root}
      id={SectionsIds.ACTIVITIES}
    >
      <Form.Item
        name="siteFunctions"
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "siteForm.function" })}{" "}
          </Heading>
        }
      >
        <Select
          mode="multiple"
          disabled={disabled}
          data-test-id="md-site-function-select"
          options={functionOptions}
          filterOption={(input, option) =>
            (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item
        name="operationManaged"
        label={
          <Heading size="xxs">
            {" "}
            {intl.formatMessage({ id: "siteForm.operationManaged" })}{" "}
          </Heading>
        }
      >
        <Input
          disabled={disabled}
          maxLength={256}
          data-test-id="md-site-operation-managed"
        />
      </Form.Item>
      <Form.Item
        name="countriesOutsideOfEurope"
        className={styles.countryLabel}
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "siteForm.countryOutside" })}{" "}
          </Heading>
        }
      >
        <Select
          mode="multiple"
          disabled={disabled}
          data-test-id="md-site-outside-country-select"
          options={countryOptions}
          filterOption={(input, option) =>
            (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
    </FormWrapper.Section>
  );
};

export default memo(ActivitySection);
