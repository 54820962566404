import { defineMessages } from "react-intl";

export const messages = defineMessages({
  confirmation: {
    id: "toast.confirmation",
    defaultMessage: "Confirmation",
  },
  confirmSavingDescription: {
    id: "toast.confirmation.savingDescription",
    defaultMessage: "Your changes have been well saved",
  },
  alert: {
    id: "toast.alert",
    defaultMessage: "Alert",
  },
  alertSavingDescription: {
    id: "toast.alert.savingDescription",
    defaultMessage: "Your changes could not be saved!",
  },
  alertUnidentifiedErrorDescription: {
    id: "toast.alert.unidentifiedErrorDescription",
    defaultMessage:
      "An unidentified error has occurred, please try again later or contact our support team if the error persists.",
  },
  information: {
    id: "general.information",
    defaultMessage: "Information",
  },
});
