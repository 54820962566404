import { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Filter } from "@trace-one/business-components";
import { Typography, Picker } from "@trace-one/design-system";
import { Checkbox } from "antd";

import {
  selectCountriesOptions,
  selectCompanyActivityOptions,
  selectTeamMemberResponsibilities,
  selectUserOptions,
} from "reduxStore/shared/selectors";

import AsyncPickerDropdown from "components/AsyncPickerDropdown";
import CategoryTreeSelect from "components/CategoryTreeSelect";
import CheckableTags from "components/CheckableTags";
import ContactsInputGroup from "components/SearchFilters/components/ContactInputGroupFilter";
import Select from "components/Select";
import { adjustIntercomButton } from "shared/utils/toggleIntercom";

import { SupplierFilter } from "../models";

interface SupplierSearchProps {
  filterObj: SupplierFilter;
  mergeFilters: (
    value:
      | Partial<SupplierFilter>
      | ((prevState: SupplierFilter) => SupplierFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: (searchValue: string) => void;
  onClearFiltersClick: () => void;
}

// interface userProps {
//   name: string;
//   value: string;
//   userStatus: string;
// }

const SupplierSearch: React.FC<SupplierSearchProps> = ({
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
}) => {
  const { formatMessage } = useIntl();
  const intl = useIntl();
  const countriesOptions = useSelector(selectCountriesOptions);
  const companyActivityOptions = useSelector(selectCompanyActivityOptions);
  // const countriesMap = useSelector(selectCountriesMap);
  // const [selectedUser, setSelectedUser] = useState<userProps>();
  // const [productCategoryName, setProductCategoryName] = useState<string>("...");
  // const categoryListTD = useSelector(selectCategoryListTD);
  // const companyActivityMap = useSelector(selectCompanyActivityMap);
  const usersOptions = useSelector(selectUserOptions);
  const teamMemberResponsibilitiesOption = useSelector(
    selectTeamMemberResponsibilities
  );
  const [selectedFilters, setSelectedFilters] = useState([]);
  const showIntercom = useRef(true);

  const statusOptions = [
    {
      value: "active" as const,
      label: formatMessage({ id: "general.active" }),
    },
    {
      value: "inactive" as const,
      label: formatMessage({ id: "general.inactive" }),
    },
  ];

  const users = usersOptions
    .map(({ userFirstName, userLastName, userId, userStatus }) => ({
      name: `${userFirstName} ${userLastName}`,
      value: userId,
      userStatus,
    }))
    .filter(item => item.name !== " ");

  // useEffect(() => {
  //   const { userId } = filterObj;
  //   if (userId) fetchUser(userId);
  // }, [filterObj]);

  // const fetchUser = async userId => {
  //   if (userId) {
  //     const user = await getUsersCollectionMap([userId]);
  //     const { userFirstName, userLastName, userStatus } = user[userId];
  //     setSelectedUser({
  //       name: `${userFirstName} ${userLastName}`,
  //       value: userId,
  //       userStatus,
  //     });
  //   }
  // };

  useEffect(() => {
    const {
      companyCountry,
      relationStatuses,
      companyActivity,
      checked,
      userId,
      productCategory,
    } = filterObj;

    const defaultSelectedFilters = [
      ...(companyCountry
        ? [
            {
              filterId: "1",
              values: [companyCountry],
            },
          ]
        : []),
      ...(companyActivity
        ? [
            {
              filterId: "2",
              values: [companyActivity],
            },
          ]
        : []),
      ...(productCategory
        ? [
            {
              filterId: "3",
              values: [productCategory],
            },
          ]
        : []),
      ...(relationStatuses
        ? [
            {
              filterId: "4",
              values: relationStatuses,
            },
          ]
        : []),

      ...(checked
        ? [
            {
              filterId: "5",
              values: [checked],
            },
          ]
        : []),
      ...(userId
        ? [
            {
              filterId: "6",
              values: [userId],
            },
          ]
        : []),
    ];
    setSelectedFilters(defaultSelectedFilters);
  }, [filterObj]);

  const filters = [
    {
      title: intl.formatMessage({ id: "general.country" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({ companyCountry: undefined });
      },
      elementToRender: (
        <Select
          value={filterObj.companyCountry}
          onChange={companyCountry => mergeFilters({ companyCountry })}
          options={countriesOptions}
          allowClear
          showSearch
          getPopupContainer={trigger => trigger.parentElement}
          data-test-id="md-country"
          filterOption={(input, option) =>
            (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
      filterId: "1",
    },
    {
      title: intl.formatMessage({
        id: "supplierDetails.activity",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({ companyActivity: undefined });
      },
      elementToRender: (
        <Select
          value={filterObj.companyActivity}
          getPopupContainer={trigger => trigger.parentElement}
          onChange={companyActivity => mergeFilters({ companyActivity })}
          options={companyActivityOptions}
          allowClear
          filterOption={(input, option) =>
            (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
          }
          showSearch
          data-test-id="md-companyActivity"
        />
      ),
      quickFilter: (
        <AsyncPickerDropdown
          data-test-id="picker-filter-activity"
          searchPlaceholderText={intl.formatMessage({
            id: "general.search",
          })}
          clearBtnText={intl.formatMessage({ id: "general.clear" })}
          closeBtnText={intl.formatMessage({ id: "general.close" })}
          title={intl.formatMessage({
            id: "supplierDetails.activity",
          })}
          showBadgeCount
          showSearchInput
          defaultOptions={companyActivityOptions.map(({ value, name }) => ({
            value: value,
            label: name,
          }))}
          value={filterObj.companyActivity ? [filterObj.companyActivity] : []}
          searchOnChange
          onAsyncSearch={undefined}
          minLengthToSearch={1}
          onSearch={str => {
            return companyActivityOptions
              .filter(option => {
                return option.name.toLowerCase().includes(str.toLowerCase());
              })
              .map(({ value, name }) => ({ value: value, label: name }));
          }}
          selectionType="single"
          onChange={({ value }) => {
            mergeFilters({
              companyActivity:
                value[0] === filterObj.companyActivity ? undefined : value[0],
            });
          }}
          clearSelection={() => mergeFilters({ companyActivity: undefined })}
        ></AsyncPickerDropdown>
      ),
      filterId: "2",
    },
    {
      title: intl.formatMessage({
        id: "general.productCategory",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          productCategory: undefined,
        });
      },
      elementToRender: (
        <CategoryTreeSelect
          showSearch
          multiple={false}
          onChange={productCategory => {
            mergeFilters({ productCategory });
          }}
          value={filterObj?.productCategory}
          onFullChange={value => {}}
          getPopupContainer={trigger => trigger.parentElement}
          categorySelection={true}
          data-test-id="md-productCategory"
        />
      ),
      filterId: "3",
    },
    {
      title: intl.formatMessage({
        id: "general.relationStatus",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          relationStatuses: [],
        });
      },
      elementToRender: (
        <CheckableTags
          value={filterObj.relationStatuses}
          onChange={relationStatuses => {
            mergeFilters({ relationStatuses });
          }}
          options={statusOptions}
        />
      ),
      quickFilter: (
        <Picker.Filter
          data-test-id="picker-filter-relationStatus"
          title={intl.formatMessage({
            id: "general.relationStatus",
          })}
          showBadgeCount
          items={statusOptions}
          clearBtnText={intl.formatMessage({ id: "general.clear" })}
          closeBtnText={intl.formatMessage({ id: "general.close" })}
          value={filterObj.relationStatuses ?? []}
          selectionType="multi"
          onChange={({ value: relationStatuses }) => {
            mergeFilters({
              relationStatuses,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              relationStatuses: [],
            })
          }
        ></Picker.Filter>
      ),
      filterId: "4",
    },
    {
      title: intl.formatMessage({
        id: "general.supplier",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          checked: false,
        });
      },
      elementToRender: (
        <Checkbox
          data-test-id="md-with-no-contact"
          checked={filterObj.checked}
          onChange={e => {
            mergeFilters({
              checked: e.target.checked,
              userId: undefined,
              responsibilityId: undefined,
            });
          }}
        >
          <Typography component="span">
            {intl.formatMessage({
              id: "productDetails.withNoContactText",
            })}
          </Typography>
        </Checkbox>
      ),
      filterId: "5",
    },
    {
      title: intl.formatMessage({
        id: "productDetails.contactNameText",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({ userId: undefined, responsibilityId: undefined });
      },
      elementToRender: (
        <div style={{ maxWidth: "100%" }}>
          <ContactsInputGroup
            filterObj={filterObj}
            mergeFilters={mergeFilters}
            teamMemberUsers={users}
            disabled={filterObj.checked}
            withUserStatusMention
            teamMemberResponsibilitiesOption={teamMemberResponsibilitiesOption}
          />
        </div>
      ),
      filterId: "6",
    },
  ];

  return (
    <Filter
      filters={filters}
      selectedFilters={selectedFilters}
      clearAllFilters={() => {
        onClearFiltersClick();
      }}
      search={{
        defaultValue: initialSearchValue,
        placeholder: formatMessage({ id: "supplierListPage.search" }),
        onSearch: query => {
          onSearch(query);
        },
      }}
      onAllFilterPanelToggle={() => {
        showIntercom.current = !showIntercom.current;
        adjustIntercomButton(showIntercom.current);
      }}
    />
  );
};

export default SupplierSearch;
