import React from "react";
import { useSelector } from "react-redux";
import { Switch, Redirect } from "react-router-dom";

import usePermissions from "core/oidc/usePermissions";

import { selectIsRetailer } from "reduxStore/oidc/selectors";

import AuthorizedRoute from "components/AuthorizedRoute";
import RouteIfNotFound from "components/RouteIfNotFound";

import SiteCreation from "./pages/SiteCreation";
import SiteHistory from "./pages/SiteHistory";
import SiteList from "./pages/SiteList";
import SiteRetailerDetails from "./pages/SiteRetailerDetails";
import SiteRetailerEdit from "./pages/SiteRetailerEdit";
import SiteSupplierDetails from "./pages/SiteSupplierDetails";
import SiteSupplierEdit from "./pages/SiteSupplierEdit";

const SitesRouting: React.FC = () => {
  const isRetailer = useSelector(selectIsRetailer);
  const {
    masterdataMySites,
    masterdataMyAssociatedSites,
    sitesWrite,
    sitesRead,
    siteRelationsWrite,
  } = usePermissions();

  const mdSitesPermissions = isRetailer
    ? masterdataMyAssociatedSites
    : masterdataMySites;

  const mdSitesEditPermissions = isRetailer
    ? sitesRead || siteRelationsWrite
    : sitesRead || sitesWrite;

  return (
    <Switch>
      <AuthorizedRoute
        path="/sites/list"
        component={SiteList}
        canActive={mdSitesPermissions}
        exact
      />
      <AuthorizedRoute
        path="/sites/edit/:siteId"
        component={isRetailer ? SiteRetailerEdit : SiteSupplierEdit}
        canActive={mdSitesPermissions && mdSitesEditPermissions}
        exact
      />
      <AuthorizedRoute
        path="/sites/create/"
        component={SiteCreation}
        canActive={!isRetailer && mdSitesPermissions && sitesWrite}
        exact
      />
      <AuthorizedRoute
        path="/sites/:siteId"
        component={isRetailer ? SiteRetailerDetails : SiteSupplierDetails}
        canActive={mdSitesPermissions && mdSitesEditPermissions}
        exact
      />
      <AuthorizedRoute
        path="/sites/history/:siteId"
        component={SiteHistory}
        canActive={mdSitesPermissions}
        exact
      />
      <Redirect from="/sites/" to="/sites/list" exact />
      <RouteIfNotFound />
    </Switch>
  );
};

export default SitesRouting;
