import React from "react";
import { useIntl } from "react-intl";

import { Paragraph, Datatable } from "@trace-one/design-system";
import { Empty, TableProps as AntdTableProps } from "antd";
import cn from "classnames";
import PropTypes from "prop-types";

import Header from "./Header";
import useResizableColumns from "./hooks/useResizableColumns";
import styles from "./Table.module.less";
import buildSelectAllHandler from "./utils/buildSelectAllHandler";
import buildSelectHandler from "./utils/buildSelectHandler";
import buildTableHandler from "./utils/buildTableHandler";
import calculatePagination from "./utils/calculatePagination";

export interface TableProps<RecordType>
  extends Omit<AntdTableProps<RecordType>, "loading"> {
  skip?: number;
  take?: number;
  skipAndTakeQueryStats?: {
    currentCount?: number;
    totalCount?: number;
  };
  loading?: boolean;
  setPaginationQuery?: ({ skip, take }: { skip: number; take: number }) => void;
  setSelectedRowKeys?: (keys: string[]) => void;
  isAllSelected?: boolean;
  showActionBanner?: boolean;
}

const TableInternal = <RecordType extends { key: string | number }>({
  columns,
  loading,
  skip,
  take,
  skipAndTakeQueryStats,
  setPaginationQuery,
  setSelectedRowKeys,
  isAllSelected,
  showActionBanner,
  ...rest
}: TableProps<RecordType>): JSX.Element => {
  const intl = useIntl();
  const { resizableColumns, components } = useResizableColumns(columns);

  const hasPagination = typeof skip === "number" && typeof take === "number";
  const hasRowSelection = !!setPaginationQuery && !!setSelectedRowKeys;
  const rowSelection = isAllSelected
    ? { selectedRowKeys: rest.dataSource.map(({ key }) => key.toString()) }
    : rest.rowSelection;

  const pagination =
    hasPagination && calculatePagination({ skip, take, skipAndTakeQueryStats });

  return (
    <Datatable
      columns={resizableColumns}
      components={components}
      loading={loading}
      // loading={{ spinning: loading, indicator: <LoadingOutlined /> }}
      locale={{
        emptyText: (
          <Empty
            description={
              <Paragraph size="m">
                {intl.formatMessage({ id: "general.noData" })}
              </Paragraph>
            }
          />
        ),
      }}
      onChange={buildTableHandler({ setPaginationQuery })}
      scroll={{ x: "100%" }}
      tableLayout="fixed"
      {...rest}
      className={cn(
        styles.root,
        rest.className,
        isAllSelected && styles.allRowsSelected,
        showActionBanner && styles.withActionBanner
      )}
      {...(hasRowSelection && {
        rowSelection: {
          type: "checkbox",
          onSelect: buildSelectHandler({ setSelectedRowKeys }),
          onSelectAll: buildSelectAllHandler({ setSelectedRowKeys }),
          getCheckboxProps: record => {
            return {
              disabled: isAllSelected,
              "data-test-id": `table-checkbox-${record.key}`,
            };
          },
          ...rowSelection,
        },
      })}
      pagination={hasPagination ? { ...pagination, ...rest.pagination } : false}
    />
  );
};

TableInternal.propTypes = {
  columns: PropTypes.array.isRequired,

  loading: PropTypes.bool,
  skip: PropTypes.number,
  take: PropTypes.number,
  skipAndTakeQueryStats: PropTypes.shape({
    currentCount: PropTypes.number,
    totalCount: PropTypes.number,
  }),
  setPaginationQuery: PropTypes.func,
  setSelectedRowKeys: PropTypes.func,
};

type InternalTableType = typeof TableInternal;
interface TableInterface extends InternalTableType {
  Header: typeof Header;
}

const Table = TableInternal as TableInterface;
Table.Header = Header;

export default Table;
