import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { TagV1 as Tag } from "@trace-one/design-system";
import { Form } from "antd";

import { SmdAPI } from "apis";
import { CompanyRelationData, SiteData } from "models";

import { useAppDispatch } from "reduxStore";
import { selectSiteRetailerFormData } from "reduxStore/siteRetailerDetails/selectors";
import {
  clearSiteRetailerFormData,
  setSiteRetailerFormData,
} from "reduxStore/siteRetailerDetails/slice";

import CommentsSection from "components/CommentsSection";
import LeavingFormPrompt from "components/LeavingFormPrompt";
import NewFormWrapper from "components/NewFormWrapper";
import useToast from "shared/hooks/useToast";

import { HeaderSubtitle } from "../../pages/SiteRetailerDetails";

import PrivateInformationSection from "./components/PrivateInformationSection";
import getFailedSubmitResult from "./utils/getFailedSubmitResults";
import prepareFormData from "./utils/prepareFormData";
import prepareInitialValues from "./utils/prepareInitialValues";

interface SiteFormProps {
  //TODO: add/fix site type to API & response
  initialValues: any;
  site: SiteData & {
    siteOwnerCompanyName: string;
    siteOwnerCompanyType: string;
  };
  companyRelation: CompanyRelationData;
  associatedCompanyId: string;
  disabled: boolean;
}

const SiteForm: React.FC<SiteFormProps> = props => {
  const {
    initialValues = {},
    site,
    companyRelation,
    associatedCompanyId,
    disabled,
  } = props;

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const toast = useToast();
  // Form handler.
  const [form] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [errorResponse, setErrorResponse] = useState({});
  const siteRetailerFormData = useSelector(selectSiteRetailerFormData);

  useEffect(() => {
    dispatch(setSiteRetailerFormData(initialValues));
    return () => {
      dispatch(clearSiteRetailerFormData());
    };
  }, []);

  const handleChange = (value: boolean) => {
    setActiveTab(null);
  };

  const tabList = [
    {
      label: intl.formatMessage({ id: "siteDetails.siteInformation" }),
      content: (
        <PrivateInformationSection
          disabled={disabled}
          submitErrors={errorResponse}
        />
      ),
    },

    {
      label: intl.formatMessage({ id: "general.comments" }),
      content: <CommentsSection name="comments" disabled={disabled} />,
    },
  ];

  const SiteStatusTag = () => {
    const statusTagData = siteRetailerFormData?.ownerCompanyRelationStatus
      ? {
          label: intl.formatMessage({ id: "general.active" }),
          color: "green",
        }
      : {
          label: intl.formatMessage({ id: "general.inactive" }),
          color: "grey",
        };
    return <Tag mode="light" {...statusTagData} />;
  };

  const handleSubmit = async values => {
    const fields = { ...siteRetailerFormData, ...form.getFieldsValue() };
    try {
      await SmdAPI.updateSiteRelationBySiteIdAndAssociatedCompanyId(
        site.id,
        associatedCompanyId,
        prepareFormData({ ...initialValues, ...fields })
      );

      toast.saveSuccess();
      setIsFormChanged(false);
    } catch (error) {
      const result = getFailedSubmitResult({ error, setActiveTab });
      setErrorResponse(prev => ({ ...prev, ...result }));
      toast.saveError({ error });
    }
  };

  return (
    <NewFormWrapper
      title={site.siteName}
      status={<SiteStatusTag />}
      submitText={intl.formatMessage({ id: "general.save" })}
      cancelText={intl.formatMessage({ id: "general.cancel" })}
      subtitle={HeaderSubtitle(
        companyRelation,
        site.siteOwnerCompanyName,
        site.siteOwnerCompanyType
      )}
      onBackClick={() => {
        history.goBack();
      }}
      form={form}
      withBackNav
      onFinish={handleSubmit}
      initialValues={prepareInitialValues({ initialValues })}
      activeTab={activeTab}
      onValuesChange={changedValues => {
        let dataToSave = { ...siteRetailerFormData, ...changedValues };
        dispatch(setSiteRetailerFormData(dataToSave));
        if (!isFormChanged) setIsFormChanged(true);
      }}
      disabled={disabled}
      tabs={tabList}
      handleChange={handleChange}
    >
      <LeavingFormPrompt when={isFormChanged} />
    </NewFormWrapper>
  );
};

export default SiteForm;
