import { Col } from "antd";
import { PageCol } from "pages/Sites/constants";

import MainInformation from "./MainInformation";

const ProductIdentification = () => {
  return (
    <>
      <Col {...PageCol.HALF}>
        <MainInformation />
      </Col>
    </>
  );
};

export default ProductIdentification;
