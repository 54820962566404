import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading, UserCard, Typography } from "@trace-one/design-system";
import moment from "moment";

import { selectIsRetailer } from "reduxStore/oidc/selectors";
import {
  selectIdentifiersOptions,
  selectFunctionOptions,
  selectCountriesOptions,
  selectRelatedCompanyOptions,
} from "reduxStore/shared/selectors";

import { UserStatus } from "shared/constants";

import { SiteVersionsResult } from "../models";

import CertificatesSection from "./CertificatesSection";
import DetailSection from "./DetailSection";
import styles from "./VersionsDetails.module.less";

const VersionsDetails = ({
  versionDetails,
  previousVersionDetails,
}: {
  versionDetails: SiteVersionsResult;
  previousVersionDetails?: SiteVersionsResult;
}) => {
  const { formatMessage } = useIntl();
  const isRetailer = useSelector(selectIsRetailer);
  const identifiersOptions = useSelector(selectIdentifiersOptions);
  const relatedCompanies = useSelector(selectRelatedCompanyOptions);
  const countries = useSelector(selectCountriesOptions);
  const functions = useSelector(selectFunctionOptions);

  const SiteInformation = [
    {
      title: formatMessage({
        id: "siteDetails.siteIdentification",
      }),
      fields: [
        {
          label: formatMessage({
            id: "siteForm.siteName",
          }),
          value: versionDetails.siteName,
          isChanged: previousVersionDetails
            ? previousVersionDetails.siteName !== versionDetails.siteName
            : false,
        },
        {
          label: formatMessage({
            id: "siteDetails.code",
          }),
          value: versionDetails.identifierCodes?.map(({ value, typeId }) => {
            const typeLabel = identifiersOptions.find(
              i => typeId === i.value
            )?.name;
            if (typeLabel.toLowerCase() === "internal code" && isRetailer)
              return null;
            return `${typeLabel}: ${value}`;
          }),
          isChanged: previousVersionDetails
            ? !(
                versionDetails.identifierCodes.length ===
                  previousVersionDetails.identifierCodes.length &&
                versionDetails.identifierCodes.every(
                  (v, i) =>
                    previousVersionDetails.identifierCodes[i].typeId ===
                      v.typeId &&
                    previousVersionDetails.identifierCodes[i].value === v.value
                )
              )
            : false,
        },
      ],
    },
    {
      title: formatMessage({
        id: "siteForm.activitiesTitle",
      }),
      fields: [
        {
          label: formatMessage({
            id: "general.activities",
          }),
          value: versionDetails.siteFunctions?.map(
            functionId => functions.find(i => functionId === i.value)?.name
          ),
          isChanged: previousVersionDetails
            ? !(
                versionDetails.siteFunctions.length ===
                  previousVersionDetails.siteFunctions.length &&
                versionDetails.siteFunctions.every(
                  (v, i) => previousVersionDetails.siteFunctions[i] === v
                )
              )
            : false,
        },
        {
          label: formatMessage({
            id: "siteDetails.operationsPerformed",
          }),
          value: versionDetails.operationManaged,
          isChanged: previousVersionDetails
            ? previousVersionDetails.operationManaged !==
              versionDetails.operationManaged
            : false,
        },
        {
          label: formatMessage({
            id: "siteDetails.countryOutside",
          }),
          value: versionDetails?.countriesOutsideOfEurope
            .map(country => countries.find(c => c.value === country)?.name)
            .filter(Boolean)
            .join(", "),
          isChanged: previousVersionDetails
            ? !(
                versionDetails.countriesOutsideOfEurope.length ===
                  previousVersionDetails.countriesOutsideOfEurope.length &&
                versionDetails.countriesOutsideOfEurope.every(
                  (v, i) =>
                    previousVersionDetails.countriesOutsideOfEurope[i] === v
                )
              )
            : false,
        },
      ],
    },
  ];
  const SiteUsage = [
    ...(!isRetailer
      ? [
          {
            fields: [
              {
                value: versionDetails.visibleByCompanyIds
                  ?.map(s => relatedCompanies.find(c => c.value === s)?.name)
                  .join(", "),
                isChanged: previousVersionDetails
                  ? !(
                      versionDetails.visibleByCompanyIds.length ===
                        previousVersionDetails.visibleByCompanyIds.length &&
                      versionDetails.visibleByCompanyIds.every(
                        (v, i) =>
                          previousVersionDetails.visibleByCompanyIds[i] === v
                      )
                    )
                  : false,
                testId: "siteaccess",
              },
            ],
          },
        ]
      : []),
  ];
  const SiteContacts = [
    {
      title: formatMessage({
        id: "siteForm.addressTitle",
      }),
      fields: [
        {
          label: formatMessage({
            id: "general.address",
          }),
          value: versionDetails.siteAddress1,
          isChanged: previousVersionDetails
            ? previousVersionDetails.siteAddress1 !==
              versionDetails.siteAddress1
            : false,
        },
        {
          label: formatMessage({
            id: "siteForm.address2",
          }),
          value: versionDetails.siteAddress2,
          isChanged: previousVersionDetails
            ? previousVersionDetails.siteAddress2 !==
              versionDetails.siteAddress2
            : false,
        },
        {
          label: formatMessage({
            id: "general.postalCode",
          }),
          value: versionDetails.sitePostalCode,
          isChanged: previousVersionDetails
            ? previousVersionDetails.sitePostalCode !==
              versionDetails.sitePostalCode
            : false,
        },
        {
          label: formatMessage({
            id: "general.city",
          }),
          value: versionDetails.siteCity,
          isChanged: previousVersionDetails
            ? previousVersionDetails.siteCity !== versionDetails.siteCity
            : false,
        },
        {
          label: formatMessage({
            id: "general.regionState",
          }),
          value: versionDetails.siteRegion,
          isChanged: previousVersionDetails
            ? previousVersionDetails.siteRegion !== versionDetails.siteRegion
            : false,
        },
        {
          label: formatMessage({
            id: "general.country",
          }),
          value: countries.find(c => c.value === versionDetails.siteCountry)
            ?.name,
          isChanged: previousVersionDetails
            ? previousVersionDetails.siteCountry !== versionDetails.siteCountry
            : false,
        },
      ],
    },
    {
      title: formatMessage({
        id: "siteForm.contactsTitle",
      }),
      fields: [
        {
          label: formatMessage({
            id: "general.phone",
          }),
          value: versionDetails.sitePhone,
          isChanged: previousVersionDetails
            ? previousVersionDetails.sitePhone !== versionDetails.sitePhone
            : false,
        },
        {
          label: formatMessage({
            id: "general.fax",
          }),
          value: versionDetails.siteFax,
          isChanged: previousVersionDetails
            ? previousVersionDetails.siteFax !== versionDetails.siteFax
            : false,
        },
        {
          label: formatMessage({
            id: "general.email",
          }),
          value: versionDetails.siteEmail,
          isChanged: previousVersionDetails
            ? previousVersionDetails.siteEmail !== versionDetails.siteEmail
            : false,
        },
        {
          label: formatMessage({
            id: "siteDetails.emergencyContact",
          }),
          value: versionDetails.siteEmergencyPhone,
          isChanged: previousVersionDetails
            ? previousVersionDetails.siteEmergencyPhone !==
              versionDetails.siteEmergencyPhone
            : false,
        },
        {
          label: formatMessage({
            id: "general.contacts",
          }),
          value: versionDetails.contactUserIds.map(id => {
            const userData = versionDetails.usersData[id];
            if (!userData) {
              return null;
            }
            const isUserInactive = userData.userStatus !== UserStatus.ENABLED;
            return (
              <UserCard
                key={id}
                name={userData.userFirstName + " " + userData.userLastName}
                title={userData.userJobTitle}
                photoUrl={userData.userPhotoUrl}
                size="xs"
                disabled={isUserInactive}
                inactiveTooltipText={formatMessage({
                  id: "general.userInactive",
                })}
                icon={false}
              />
            );
          }),
          isChanged: previousVersionDetails
            ? !(
                versionDetails.contactUserIds.length ===
                  previousVersionDetails.contactUserIds.length &&
                versionDetails.contactUserIds.every(
                  (v, i) => previousVersionDetails.contactUserIds[i] === v
                )
              )
            : false,
        },
      ],
    },
  ];

  return (
    <div className={styles.detailsContailer}>
      <Heading color="grey-5" className={styles.versionsTitle} size="m">
        {versionDetails.version ? "V" + versionDetails.version + " - " : ""}
        {versionDetails.siteName}
      </Heading>
      <Typography color="grey-5" variant="body-regular">
        {"(" +
          formatMessage({
            id: "general.modifiedAt",
          }) +
          " " +
          moment(versionDetails.modifiedAt).format("L") +
          ","}{" "}
        {moment(versionDetails.modifiedAt).format("LT") + ")"}
      </Typography>
      <DetailSection
        heading={formatMessage({
          id: "siteDetails.siteInformation",
        })}
        fieldItems={SiteInformation}
      />
      <DetailSection
        heading={formatMessage({
          id: "siteHistoryPage.contactsTitle",
        })}
        fieldItems={SiteContacts}
      />
      <CertificatesSection
        versionDetails={versionDetails}
        previousVersionDetails={previousVersionDetails}
      />
      {!isRetailer && (
        <DetailSection
          heading={formatMessage({
            id: "siteDetails.siteAccess",
          })}
          fieldItems={SiteUsage}
        />
      )}
    </div>
  );
};

export default VersionsDetails;
