import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

require("@formatjs/intl-getcanonicallocales/polyfill");

// This is polyfill for browsers without Locale
require("@formatjs/intl-locale/polyfill");

// This is polyfill for browsers without Plural Rules
require("@formatjs/intl-pluralrules/polyfill");
require("@formatjs/intl-pluralrules/locale-data/fr");
require("@formatjs/intl-pluralrules/locale-data/en");

// This is polyfill for browsers without Realtive Time Format
require("@formatjs/intl-relativetimeformat/polyfill");
require("@formatjs/intl-relativetimeformat/locale-data/fr");
