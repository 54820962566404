export const AddressModel = Object.freeze({
  ADDRESS_1: "companyAddress1",
  ADDRESS_2: "companyAddress2",
  CITY: "companyCity",
  POSTAL_CODE: "companyPostalCode",
  COUNTRY_CODE: "companyCountry",
});

export const DeliveryAddressModel = Object.freeze({
  ADDRESS_1: "companyDeliveryAddress1",
  ADDRESS_2: "companyDeliveryAddress2",
  CITY: "companyDeliveryCity",
  POSTAL_CODE: "companyDeliveryPostalCode",
  COUNTRY_CODE: "companyDeliveryCountry",
});
