import React from "react";

import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./Section.module.less";

export interface SectionProps
  extends Omit<React.HTMLProps<HTMLElement>, "title"> {
  title: React.ReactNode;
  children: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ title, children, ...rest }) => {
  return (
    <section {...rest} className={cn(styles.root, rest.className)}>
      <div className={styles.title}>
        {title}
        <hr />
      </div>
      {children}
    </section>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};
Section.displayName = "FormWrapper.Section";

export default Section;
