import { useIntl } from "react-intl";

import { TableColumnsType } from "antd";
import cn from "classnames";

import Icon from "components/Icon";
import IconButton from "components/IconButton";
import Input from "components/Input";

import styles from "../BrandTranslationTable.module.less";
import FlagIcon from "../FlagIcon";
import { BrandTranslationColumn, Translation } from "../models";

const useBrandTranslationTable = ({
  value,
  languagesMap,
  handleTranslationChange,
  handleRemoveItem,
}: {
  value: Translation[];
  languagesMap: { [itemCode: string]: string };
  handleTranslationChange: (
    languageItemCode: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveItem: (languageItemCode: string) => () => void;
}) => {
  const { formatMessage } = useIntl();

  const ActionColumn = [
    {
      title: formatMessage({ id: "classification.category.remove" }),
      dataIndex: "remove",
      key: "remove",
      render: (_, record) => {
        return (
          <IconButton
            onClick={handleRemoveItem(record.raw.languageItemCode)}
            // className={styles.deleteBtn}
            data-test-id="md-language-remove"
          >
            <Icon name="trash" color="primary" size="medium" useTOLibrary />
          </IconButton>
        );
      },
      width: 80,
    },
  ];
  const columns: TableColumnsType<BrandTranslationColumn> = [
    {
      title: formatMessage({ id: "classification.category.language" }),
      dataIndex: "languageText",
      key: "languageText",
      render: (value, record) => (
        <div className={styles.languageFlagWrapper}>
          <FlagIcon languageCode={record.raw.languageItemCode} />
          <span style={{ marginLeft: 8 }}>{value}</span>
        </div>
      ),
    },
    {
      title: formatMessage({ id: "classification.category.text" }),
      dataIndex: "text",
      key: "text",
      render: (value, record) => {
        return (
          <div>
            <Input
              value={value}
              onChange={handleTranslationChange(record.raw.languageItemCode)}
              maxLength={256}
              className={cn({
                [styles.textAreaRequired]: !value,
              })}
              data-test-id="md-language-text-area"
            />
            {!value && (
              <div className={styles.valueIsRequired}>
                <span style={{ fontSize: "12px" }}>
                  {formatMessage({ id: "general.valueIsRequired" })}{" "}
                </span>
              </div>
            )}
          </div>
        );
      },
    },
    ...ActionColumn,
  ];

  const data: BrandTranslationColumn[] = value.map(value => ({
    key: value.languageItemCode,
    languageText: languagesMap[value.languageItemCode],
    text: value.text,
    raw: value,
  }));

  return { columns, data };
};

export default useBrandTranslationTable;
