import { Switch, Redirect } from "react-router-dom";

import usePermissions from "core/oidc/usePermissions";

import AuthorizedRoute from "components/AuthorizedRoute";
import RouteIfNotFound from "components/RouteIfNotFound";

import SupplierDetails from "./pages/SupplierDetails";
import SupplierEdit from "./pages/SupplierEdit";
import SupplierList from "./pages/SupplierList";

const SuppliersRouting: React.FC = () => {
  const { masterdataMySuppliers, companyRelationsWrite, companiesRead } =
    usePermissions();

  return (
    <Switch>
      <AuthorizedRoute
        path="/suppliers/list"
        canActive={masterdataMySuppliers}
        component={SupplierList}
        exact
      />
      <AuthorizedRoute
        path="/suppliers/edit/:supplierId"
        canActive={masterdataMySuppliers && companyRelationsWrite}
        exact
      >
        <SupplierEdit
          // TODO Remove it
          isReadOnly={companiesRead && !companyRelationsWrite}
        />
      </AuthorizedRoute>

      <AuthorizedRoute
        path="/suppliers/:supplierId"
        component={SupplierDetails}
        canActive={
          masterdataMySuppliers && (companyRelationsWrite || companiesRead)
        }
        exact
      />

      <Redirect from="/suppliers/" to="/suppliers/list" exact />
      <RouteIfNotFound />
    </Switch>
  );
};

export default SuppliersRouting;
