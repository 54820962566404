import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import { Heading, Paragraph, Tooltip, Modal } from "@trace-one/design-system";
import { Card, Spinner } from "@trace-one/react-components";
import { Menu, Radio, Space } from "antd";
import cn from "classnames";
import moment from "moment";

import { CumdAPI, PmdAPI, RlmdAPI, SmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";
import { CategoryListData } from "models";

import { selectIsRetailer } from "reduxStore/oidc/selectors";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import ActionDropdown from "components/ActionDropdown";
import ShortMsg from "components/ShortMsg";
import { CategoryType, ListUrls } from "shared/constants";
import useAsyncHandler from "shared/hooks/useAsyncHandler";
import useToast from "shared/hooks/useToast";

import CreateClassificationModal from "../CreateClassificationModal";

import styles from "./ClassificationCard.module.less";

interface ClassificationCardProps {
  item: CategoryListData;
  isGlobal?: boolean;
  ownerCompanyName: string;
  hasPdm?: boolean;
  refetchClassifications: () => void;
}

interface ActionList {
  name: string;
  dataTestId: string;
  showAction: boolean;
  disabled?: boolean;
  loading?: boolean;
  icon?: JSX.Element;
  onClick?: () => void;
}

interface LinkedItems {
  productsLinked: number;
  sitesLinked: number;
  suppliersLinked: number;
}

const ClassificationCard: React.FC<ClassificationCardProps> = ({
  item,
  isGlobal,
  ownerCompanyName,
  refetchClassifications,
  hasPdm,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const toast = useToast();

  const { categoryListsWrite, categoryListsRead } = usePermissions();
  const [isCreateClassificationOpen, setIsCreateClassificationOpen] =
    useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const [isArchiveOpen, setIsArchiveOpen] = useState<boolean>(false);
  const [linkedItems, setLinkedItems] = useState<LinkedItems>(null);
  const isRetailer = useSelector(selectIsRetailer);
  const companyId = useSelector(selectUserOwningCompanyId);
  const { productsLinked, sitesLinked, suppliersLinked } = linkedItems ?? {};
  const canArchive = !(productsLinked + sitesLinked + suppliersLinked);
  const [selectedLink, setSelectedLink] = useState<string>("");
  const [handleExportClassification, isExportClassificationLoading] =
    useAsyncHandler(() => RlmdAPI.exportCategoryListById(item.categoryListId), {
      successMessage: {
        type: "info",
        description: (
          <ShortMsg.FileRequestProcessed formatMessage={intl.formatMessage} />
        ),
      },
    });

  const [handleArchiveClassification, isArchiveClassificationLoading] =
    useAsyncHandler(() =>
      Promise.all([
        PmdAPI.getTradeItemsByFilters(
          {},
          { categoryId: item.categoryListId, ownerCompanyId: companyId }
        ).then(({ data }) => data),
        SmdAPI.getRelationsByFilters(
          {},
          { categoryId: item.categoryListId, associatedCompanyId: companyId }
        ).then(({ data }) => data),
        CumdAPI.getCompaniesByFilters(
          {},
          {
            relatedCategoryId: item.categoryListId,
            relatedOwnerCompanyId: companyId,
            includePrivate: true,
          }
        ).then(({ data }) => data),
      ])
    );

  useEffect(() => {
    setSelectedLink(
      !!productsLinked
        ? ListUrls.products
        : !!sitesLinked
        ? ListUrls.sites
        : !!suppliersLinked
        ? ListUrls.suppliers
        : ""
    );
  }, [productsLinked, sitesLinked, suppliersLinked]);

  const confirmArchive = async () => {
    if (canArchive) {
      try {
        await RlmdAPI.archiveCategorylist(item.categoryListId);
        toast.success({
          message: intl.formatMessage({
            id: "general.archived",
          }),
          description: intl.formatMessage({
            id: "classification.archive.success",
          }),
        });
        setIsArchiveOpen(false);
        refetchClassifications();
      } catch (error) {
        toast.error({
          message: intl.formatMessage({
            id: "toast.alert",
          }),
          description: intl.formatMessage({
            id: "classification.archive.failed",
          }),
          checkErrorResponse: false,
        });
        setIsArchiveOpen(false);
      }
    } else {
      history.push(`/${selectedLink}/list`, {
        productListId: item.categoryListId,
      });
    }
  };

  const actionList: ActionList[] = [
    {
      name: intl.formatMessage({ id: "general.exportClassification" }),
      dataTestId: "md-classification-export",
      showAction: isRetailer && item.isActive,
      disabled: isExportClassificationLoading,
      loading: isExportClassificationLoading,
      onClick: handleExportClassification,
    },
    {
      name: intl.formatMessage({ id: "general.openClassification" }),
      dataTestId: "md-classification-open",
      showAction:
        window.env.SHOW_CLASSIFICATION_DETAIL === "true" &&
        (categoryListsWrite || categoryListsRead),
      disabled: false,
      loading: false,
      onClick: () => {
        history.push(`/classifications/${item.categoryListId}`);
      },
    },
    companyId === item.companyId && {
      name: intl.formatMessage({
        id: "classification.editClassificationAction",
      }),
      dataTestId: "md-classification-edit",
      showAction: false,
      disabled: false,
      loading: false,
      onClick: () => {
        setIsCreateClassificationOpen(true);
        setIsEditing(true);
      },
    },
    companyId === item.companyId && {
      name: intl.formatMessage({
        id: "general.archive",
      }),
      dataTestId: "md-classification-archive",
      showAction: categoryListsWrite && item.isActive && !hasPdm,
      disabled: isArchiveClassificationLoading,
      loading: isArchiveClassificationLoading,
      onClick: async () => {
        const [tradeItems, siteItems, supplierItems] =
          await handleArchiveClassification();

        setLinkedItems({
          productsLinked: tradeItems.tradeItems.length,
          sitesLinked: siteItems.sites.length,
          suppliersLinked: supplierItems.companies.length,
        });
        setShowArchiveModal(true);
        setIsArchiveOpen(true);
      },
    },
  ];

  const filteredActions = actionList.filter(action => action.showAction);

  const CardTitle = (
    <div className={styles.head}>
      <Heading size="xs" className={styles.categoryName}>
        <Tooltip showFullText text={item.categoryListName} placement="top">
          <div className={styles.classificationHeaderTitle}>
            {item.categoryListName}
          </div>
        </Tooltip>
      </Heading>
    </div>
  );

  return (
    <>
      <Card
        data-test-id="md-classification-card"
        title={
          <Link
            to={{
              pathname: `/classifications/${item.categoryListId}`,
            }}
          >
            {CardTitle}
          </Link>
        }
        extra={
          filteredActions.length && (
            <ActionDropdown
              data-test-id="md-classification-action"
              size="m"
              overlay={
                <Menu>
                  {filteredActions.map(action => (
                    <Menu.Item
                      key={action.dataTestId}
                      onClick={action.onClick}
                      icon={action.icon}
                      disabled={action.disabled || false}
                      data-test-id={action.dataTestId}
                    >
                      <Spinner spinning={action.loading || false}>
                        {action.name}
                      </Spinner>
                    </Menu.Item>
                  ))}
                </Menu>
              }
            />
          )
        }
        className={cn(styles.root, { [styles.disabled]: !item.isActive })}
      >
        <div className={styles.content}>
          <div className={styles.details}>
            <Paragraph size="s" className={styles.categoryType}>
              {item.categoryType === CategoryType.PRODUCT
                ? intl.formatMessage({ id: "general.productCategory" })
                : null}

              <p
                className={styles.owner}
                data-test-id="md-classification-owner"
              >
                {intl.formatMessage({
                  id: "general.owner",
                })}
                : {isGlobal ? "TRACE ONE" : ownerCompanyName}
              </p>
              {item.updatedDate && (
                <span className={styles.lastUpdateDate}>
                  {intl.formatMessage({
                    id: item.isActive ? "general.modified" : "general.archived",
                  })}
                  : {moment(item.updatedDate).format("MMM YYYY")}
                </span>
              )}
            </Paragraph>
          </div>
        </div>
      </Card>

      <CreateClassificationModal
        visible={isCreateClassificationOpen}
        onCancel={() => setIsCreateClassificationOpen(false)}
        initialValues={item && item}
        refetchClassifications={refetchClassifications}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />

      {showArchiveModal && linkedItems && (
        <Modal.Simple
          title={intl.formatMessage({ id: "general.information" })}
          size="m"
          className={styles.archiveClassificationModal}
          visible={isArchiveOpen}
          primaryButtonText={intl.formatMessage({ id: "general.confirm" })}
          secondaryButtonText={intl.formatMessage({ id: "general.cancel" })}
          illustrationName="non-conformity"
          onPrimaryButtonClick={confirmArchive}
          onSecondaryButtonClick={() => setIsArchiveOpen(false)}
          onCancel={() => setIsArchiveOpen(false)}
        >
          {canArchive ? (
            <Paragraph size="m">
              {intl.formatMessage({
                id: "classification.archive.confirmation",
              })}
            </Paragraph>
          ) : (
            <>
              <Paragraph size="m">
                {intl.formatMessage({ id: "classification.archive.warning" })}
              </Paragraph>
              <Radio.Group
                onChange={e => setSelectedLink(e.target.value)}
                value={selectedLink}
              >
                <Space direction="vertical" align="start">
                  <Radio
                    value={ListUrls.products}
                    data-test-id="classification-products-radio"
                    disabled={!productsLinked}
                    checked={true}
                  >
                    {intl.formatMessage(
                      { id: "classification.productsLinked" },
                      { productsCount: productsLinked }
                    )}
                  </Radio>
                  <Radio
                    value={ListUrls.sites}
                    data-test-id="classification-sites-radio"
                    disabled={!sitesLinked}
                  >
                    {intl.formatMessage(
                      { id: "classification.sitesLinked" },
                      { sitesCount: sitesLinked }
                    )}
                  </Radio>
                  <Radio
                    value={ListUrls.suppliers}
                    data-test-id="classification-suppliers-radio"
                    disabled={!suppliersLinked}
                  >
                    {intl.formatMessage(
                      { id: "classification.suppliersLinked" },
                      { suppliersCount: suppliersLinked }
                    )}
                  </Radio>
                </Space>
              </Radio.Group>
            </>
          )}
        </Modal.Simple>
      )}
    </>
  );
};

export default ClassificationCard;
