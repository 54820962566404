import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  Heading,
  UserCard,
  Typography,
  Divider,
  Tooltip,
  Button,
} from "@trace-one/design-system";
import { usePreviousLocation } from "@trace-one/react-components";
import cn from "classnames";
import moment from "moment";

import { SiteVersionsResult } from "../models";

import styles from "./VersionsPanel.module.less";

const VersionsPanel = ({
  versions,
  selectedVersion,
  setSelectedVersion,
}: {
  versions: SiteVersionsResult[];
  selectedVersion: number;
  setSelectedVersion: (version: number) => void;
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const previousLocation = usePreviousLocation();

  return (
    <div className={cn("versionsContainer", styles.versionsContainer)}>
      <div className={styles.panelHeader}>
        <Heading className={styles.versionsTitle} size="xs">
          {formatMessage({
            id: "siteHistoryPage.VersionsHistory",
          })}
        </Heading>
        <Button
          data-test-id="file-info-closing-btn"
          type="tertiary"
          color="grey"
          iconName="close"
          onClick={() => {
            if (!!previousLocation) {
              history.goBack();
            } else {
              history.push(`/sites/list`);
            }
          }}
        />
      </div>

      <div>
        {versions?.map((version, id) => {
          const userData = version.usersData[version.modifiedBy];
          const userName =
            (userData?.userFirstName || "") +
            " " +
            (userData?.userLastName || "");
          const versionName =
            (version.siteName || "") +
            " " +
            (version.version ? "V" + version.version : "");

          return (
            <div key={id}>
              <div
                data-test-id={`version-${id}`}
                className={cn(
                  selectedVersion === version.version && styles.selectedCard,
                  styles.versionCard
                )}
                onClick={() => setSelectedVersion(version.version)}
              >
                <div className={styles.versionCardLeft}>
                  <Tooltip
                    getPopupContainer={trigger =>
                      trigger.parentElement.parentElement
                    }
                    showFullText
                    text={versionName}
                    placement="top"
                  >
                    <Typography
                      className={styles.versionName}
                      variant="heading-xxs"
                    >
                      {versionName}
                    </Typography>
                  </Tooltip>
                  {!!userData && (
                    <div className={styles.userNameSection}>
                      <UserCard.Information
                        photoUrl={userData?.userPhotoUrl}
                        icon={false}
                        size="m"
                        name={userName}
                      />
                      <Tooltip
                        getPopupContainer={trigger => trigger.parentElement}
                        showFullText
                        text={userName}
                        placement="top"
                      >
                        <Typography
                          className={styles.userName}
                          variant="body-s"
                        >
                          {userName}
                        </Typography>
                      </Tooltip>
                    </div>
                  )}
                </div>
                <Typography className={styles.versionDate} component="span">
                  {!version.version ? (
                    formatMessage({
                      id: "siteHistoryPage.currentVersion",
                    })
                  ) : (
                    <div>
                      {moment(version.modifiedAt).format("L") + ","}{" "}
                      {moment(version.modifiedAt).format("LT")}
                    </div>
                  )}
                </Typography>
              </div>
              <Divider color="grey-2" height={1}></Divider>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VersionsPanel;
