import { CompanyData } from "models";

type State = {
  supplierCompaniesData: {
    isInitiated: boolean;
    isLoading: boolean;
    hasError: boolean;
    data: CompanyData[];
  };
  pakerCompaniesData: {
    isInitiated: boolean;
    isLoading: boolean;
    hasError: boolean;
    data: CompanyData[];
  };
};

export const initialState: State = {
  supplierCompaniesData: {
    isInitiated: false,
    isLoading: false,
    hasError: false,
    data: [],
  },
  pakerCompaniesData: {
    isInitiated: false,
    isLoading: false,
    hasError: false,
    data: [],
  },
};
