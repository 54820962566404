import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "reduxStore";
import { fetchCompanyActivity } from "reduxStore/shared/asyncActions";
import { selectCompanyActivityData } from "reduxStore/shared/selectors";
import { selectUserLanguageCode } from "reduxStore/user/selectors";

import getDisplayName from "shared/utils/getDisplayName";

import { BaseHocProps, renderHocMiddleware } from "../_helpers";

const withCompanyActivity =
  () =>
  <P extends BaseHocProps>(WrappedComponent: React.ComponentType<P>) => {
    const Component: React.FC<P> = props => {
      const dispatch = useAppDispatch();
      const languageCode = useSelector(selectUserLanguageCode);

      const { isInitiated, hasError, isLoading } = useSelector(
        selectCompanyActivityData
      );

      useEffect(() => {
        dispatch(fetchCompanyActivity({ languageCode }));
      }, [languageCode]);

      return renderHocMiddleware({
        WrappedComponent,
        _hasError: hasError,
        _isInitiated: isInitiated,
        _isLoading: isLoading,
        ...props,
      });
    };

    Component.displayName = getDisplayName(
      withCompanyActivity,
      WrappedComponent
    );

    return Component;
  };

export default withCompanyActivity;
