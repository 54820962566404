import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import { fetchCategoryLists } from "reduxStore/shared/asyncActions";
import { selectIsRelatedCompanyLoading } from "reduxStore/shared/selectors";
import { fetchRetailerForm } from "reduxStore/siteRetailerForm/asyncActions";
import {
  selectRetailerFormSite,
  selectRetailerFormRelation,
  selectRetailerFormIsLoading,
  selectRetailerFormHasErrors,
  selectRetailerFormErrorStatus,
  selectRetailerFormSiteHasData,
  selectRetailerFormCompanyRelation,
} from "reduxStore/siteRetailerForm/selectors";
import { clearSiteRetailerForm } from "reduxStore/siteRetailerForm/slice";
import { selectUserOwningCompanyId } from "reduxStore/user/selectors";

import ErrorPage from "components/ErrorPage";
import Spinner from "components/Spinner";

import SiteRetailerForm from "../../components/SiteRetailerForm";
import withSiteShared, { SiteSharedProps } from "../../hocs/withSiteShared";

interface SiteRetailerEditProps extends SiteSharedProps {}

export const SiteRetailerEdit: React.FC<SiteRetailerEditProps> = ({
  isSiteSharedInitiated,
}) => {
  const dispatch = useAppDispatch();
  const { siteId } = useParams<{ siteId?: string }>();
  const { siteRelationsWrite } = usePermissions();

  const site = useSelector(selectRetailerFormSite);
  const associatedRelation = useSelector(selectRetailerFormRelation);

  const isRelatedCompanyLoading = useSelector(selectIsRelatedCompanyLoading);
  const isLoading = useSelector(selectRetailerFormIsLoading);
  const hasErrors = useSelector(selectRetailerFormHasErrors);
  const errorStatus = useSelector(selectRetailerFormErrorStatus);
  const associatedCompanyId = useSelector(selectUserOwningCompanyId);
  const formSiteHasDate = useSelector(selectRetailerFormSiteHasData);
  const companyRelation = useSelector(selectRetailerFormCompanyRelation);

  useEffect(() => {
    dispatch(fetchCategoryLists({ companyId: associatedCompanyId }));
    dispatch(fetchRetailerForm({ siteId, associatedCompanyId }));

    return () => {
      dispatch(clearSiteRetailerForm(null));
    };
  }, [siteId, associatedCompanyId]);

  if (
    isLoading ||
    isRelatedCompanyLoading ||
    !isSiteSharedInitiated ||
    !formSiteHasDate
  ) {
    return <Spinner underHeader />;
  }

  if (hasErrors) {
    return <ErrorPage status={errorStatus} />;
  }

  return (
    <SiteRetailerForm
      initialValues={associatedRelation}
      site={site}
      companyRelation={companyRelation}
      associatedCompanyId={associatedCompanyId}
      disabled={!siteRelationsWrite}
    />
  );
};

export default withSiteShared(SiteRetailerEdit);
