import { ManufacturedItemState } from "reduxStore/productDetails/models";

import Table from "components/Table";

import useTargetMarketListTable from "../hooks/useTargetMarketListTable";

interface TargetMarket {
  targetMarketsList: ManufacturedItemState["targetMarketsList"];
  previousTargetMarketsList: ManufacturedItemState["targetMarketsList"];
}

const TargetMarketList: React.FC<TargetMarket> = ({
  targetMarketsList,
  previousTargetMarketsList,
}) => {
  const { columns, data } = useTargetMarketListTable({
    targetMarketsList,
    previousTargetMarketsList,
  });

  return (
    <Table
      rowClassName={record => record.rowChanged && "changedRow"}
      columns={columns}
      dataSource={data}
      loading={false}
      pagination={false}
      data-test-id="target-markets-table"
    />
  );
};

export default TargetMarketList;
