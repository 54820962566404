import { createSlice } from "@reduxjs/toolkit";

import {
  fetchUser,
  fetchUserApplications,
  fetchUserApplicationsCompanies,
} from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserLanguage: (state, { payload }) => {
      state.details.userLanguagePreference = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUser.pending, (state, action) => {
        state.isUserDetailsLoading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isUserDetailsLoading = false;
        state.details = { ...state.details, ...action.payload.user };
        state.companyActivityNameInEn = action.payload.companyActivityNameInEn;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.isUserDetailsLoading = false;
        state.hasUserDetailsError = true;
      })
      .addCase(fetchUserApplications.pending, (state, action) => {
        state.isApplicationsLoading = true;
      })
      .addCase(fetchUserApplications.fulfilled, (state, action) => {
        state.isApplicationsLoading = false;
        state.applications = action.payload;
      })
      .addCase(fetchUserApplications.rejected, (state, action) => {
        state.isApplicationsLoading = false;
        state.hasApplicationsError = true;
        state.applications = [];
      })
      .addCase(fetchUserApplicationsCompanies.fulfilled, (state, action) => {
        state.applicationsCompanies = action.payload;
      });
  },
});

export const { updateUserLanguage } = slice.actions;
export default slice.reducer;
