import { useDispatch } from "react-redux";

import {
  AnyAction,
  configureStore,
  DeepPartial,
  getDefaultMiddleware,
  ThunkDispatch,
} from "@reduxjs/toolkit";

import rootReducer, { RootState as RootStateFromReducer } from "./rootReducer";

export type RootState = RootStateFromReducer;

/**
 * Create store with preloadedState
 * immutableCheck & serializableCheck is disabled because of low performance
 * preloadedState is used only for testing
 */
export function createStore(
  preloadedStateOnlyForTest?: DeepPartial<RootState>
) {
  const store = configureStore({
    preloadedState: preloadedStateOnlyForTest as RootState,
    reducer: rootReducer,
    middleware: [
      ...getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
    ],
  });

  return {
    ...store,
    dispatch: store.dispatch as ThunkDispatch<RootState, any, AnyAction>,
  };
}

export type StoreType = ReturnType<typeof createStore>;
export type AppDispatch = StoreType["dispatch"];

export const useAppDispatch = () => useDispatch<AppDispatch>();

const store = createStore();
export default store;
