import { useState } from "react";

import { Form } from "antd";
import { FormInstance } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";

type RecursivePartial<T> = T extends object
  ? {
      [P in keyof T]?: T[P] extends (infer U)[]
        ? RecursivePartial<U>[]
        : T[P] extends object
        ? RecursivePartial<T[P]>
        : T[P];
    }
  : any;

export type FormInstanceType<Values = any> = FormInstance<Values> & {
  /**
   * Mark changes with changing flag `isFormChanged` to true
   */
  setFieldsValueAndMarkDirty: (value: RecursivePartial<Values>) => void;
  getFieldValue: <ReturnType = any>(name: NamePath) => ReturnType;
};

type ResultType<Values> = {
  form: FormInstanceType<Values>;
  isFormDirty: boolean;
  setIsFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function useAntForm<Values = any>(
  form?: FormInstance<Values>
): ResultType<Values> {
  const [_form] = Form.useForm<Values>(form);

  const [isFormDirty, setIsFormDirty] = useState(false);

  const formWithMiddleware: FormInstanceType<Values> = {
    ..._form,
    setFieldsValueAndMarkDirty: value => {
      setIsFormDirty(true);
      _form.setFieldsValue(value);
    },
  };

  return { form: formWithMiddleware, isFormDirty, setIsFormDirty };
}
