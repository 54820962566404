import { useCallback, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Container } from "@trace-one/design-system";
import { NET_CONTENT_PARENT_ID_FOR_PRODUCT_FORM } from "pages/Products/constants";
import { compose } from "redux";

import { PmdAPI } from "apis";

import { selectUserLanguageCode } from "reduxStore/user/selectors";

import Spinner from "components/Spinner";
import { TeamMemberResponsibilityParentItem } from "shared/constants";
import { withNetContentMeasures } from "shared/hocs";
import {
  withCountries,
  withPointOfSales,
  withTeamMemberResponsibilities,
  withBrands,
} from "shared/hocs";
import getAuthorsComments from "shared/utils/getAuthorsComments";
import getCategoriesWithHierarchy from "shared/utils/getCategoriesWithHierarchy";
import getUsersCollectionMap from "shared/utils/getUsersCollectionMap";

import { ProductVersionsResult } from "./models";
import styles from "./ProductHistory.module.less";
import VersionsDetails from "./VersionDetails";
import VersionsPanel from "./VersionsPanel";

const enhance = compose<React.FC>(
  withPointOfSales(),
  withCountries(),
  withBrands(),
  withNetContentMeasures({
    includeParentItems: true,
    parentItemId: NET_CONTENT_PARENT_ID_FOR_PRODUCT_FORM,
  }),
  withTeamMemberResponsibilities({
    includeParentItems: true,
    parentItemId: TeamMemberResponsibilityParentItem.RETAILER,
  })
);

const ProductHistory = ({ isInitiated }) => {
  const languageCode = useSelector(selectUserLanguageCode);
  const { productId } = useParams<{ productId?: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const versionsInfoRef = useRef<ProductVersionsResult[] | null>(null);
  const [selectedVersion, setSelectedVersion] = useState<number>(null);
  const selectedVersionIndex = versionsInfoRef.current?.findIndex(
    v => v.version === selectedVersion
  );

  const getCategoriesMap = async (data: string[]) => {
    const uniqueIds = data.filter(function (item, pos) {
      return data.indexOf(item) === pos;
    });

    const categoriesData = await getCategoriesWithHierarchy(
      uniqueIds,
      languageCode
    );
    return categoriesData.reduce(
      (prev, current) => ({ ...prev, [current.categoryItemId]: current }),
      {}
    );
  };

  const getComments = async (commentIds: string[]) => {
    if (!commentIds.length) {
      return [];
    }

    const uniqueIds = commentIds.filter(function (item, pos) {
      return commentIds.indexOf(item) === pos;
    });
    const { data } = await PmdAPI.getComments({
      idCollection: uniqueIds,
    });
    const comments = await getAuthorsComments({ comments: data });
    return comments.reduce(
      (prev, current) => ({ ...prev, [current.id]: current }),
      {}
    );
  };

  const refetchVersions = useCallback(async () => {
    try {
      const { data } = await PmdAPI.getProductHistory(productId);
      const commentIds = data.map(v => {
        return [...(v.commentIds || [])];
      });
      const comments = await getComments(
        Array.prototype.concat.apply([], commentIds)
      );
      const categories = data.map(v => {
        return [...(v.productCategories || [])];
      });
      const categoryItemIds = Array.prototype.concat
        .apply([], categories)
        .map(c => c.categoryItemId);
      const categoriesMap = await getCategoriesMap(categoryItemIds);
      const userIds = data.map(v => {
        return [...v.contacts.map(contact => contact.userId), v.modifiedBy];
      });
      const usersList = await getUsersCollectionMap(
        Array.prototype.concat.apply([], userIds)
      );
      const versionsData = data.map((v, i) => ({
        ...v,
        usersData: usersList || null,
        categoriesData: categoriesMap || null,
        comments: comments || null,
      }));
      versionsInfoRef.current = versionsData;
      setSelectedVersion(versionsInfoRef.current[0].version);
      setIsLoading(false);
      return data;
    } catch (error) {
      console.error(error);
    }
  }, [productId]);

  useEffect(() => {
    setIsLoading(true);
    refetchVersions();
  }, [refetchVersions]);

  if (isLoading || !isInitiated) return <Spinner underHeader />;

  return (
    <div className={styles.MainContainer}>
      <Container isPageWrapper className={styles.PrdouctHistoryContainer}>
        <VersionsPanel
          versions={versionsInfoRef.current}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
        />
        {versionsInfoRef.current && (
          <VersionsDetails
            versionDetails={versionsInfoRef.current[selectedVersionIndex]}
            previousVersionDetails={
              versionsInfoRef.current[selectedVersionIndex + 1]
            }
          />
        )}
      </Container>
    </div>
  );
};

export default enhance(ProductHistory);
