import { useIntl } from "react-intl";

import { Select as AntSelect } from "@trace-one/design-system";
import { Empty } from "@trace-one/react-components";

import { SelectProps } from "./types";

const Select: React.FC<SelectProps> = props => {
  const intl = useIntl();
  const {
    error,
    errorMessage,
    mode,
    disabled,
    options,
    primaryIconColor,
    onChange,
    placeholder,
    notFoundContent,
    value,
    errorDataTestId,
    ...rest
  } = props;

  return (
    <AntSelect
      {...rest}
      value={value}
      onChange={onChange}
      options={options}
      disabled={disabled}
      mode={mode}
      notFoundContent={
        notFoundContent ? (
          notFoundContent
        ) : (
          <Empty title={intl.formatMessage({ id: "general.noData" })} />
        )
      }
      error={error}
      errorMessage={<span data-test-id={errorDataTestId}>{errorMessage}</span>}
      placeholder={
        placeholder
          ? placeholder
          : intl.formatMessage({
              id: mode ? "general.selectValues" : "general.selectValue",
            })
      }
    />
  );
};

export default Select;
