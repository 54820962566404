import { Row, Col } from "antd";
import { PageCol } from "pages/Sites/constants";

import styles from "../SiteSupplierDetails.module.less";

import ContactsBloc from "./ContactsBloc";
import SiteIdentification from "./SiteIdentification";

const SiteInformationsTab = () => {
  return (
    <div className={styles.tabWrapper}>
      <Row gutter={[32, 32]}>
        <SiteIdentification />

        <Col {...PageCol.FULL}>
          <ContactsBloc />
        </Col>
      </Row>
    </div>
  );
};

export default SiteInformationsTab;
