import { createSlice } from "@reduxjs/toolkit";

import {
  fetchSites,
  fetchCompaniesSupplier,
  fetchKpiIndicators,
  fetchAllCompaniesSupplier,
} from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "siteList",
  initialState,
  reducers: {
    clearSites: (state, action) => {
      state.sites = [];
      state.companies = [];
      state.sitesSkipAndTakeQueryStats =
        initialState.sitesSkipAndTakeQueryStats;
      state.isSitesLoading = false;
      state.hasSitesError = false;
    },
    clearKpiData: state => {
      state.kpiData = undefined;
      state.isKpiDataLoading = true;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSites.pending, (state, action) => {
        state.isSitesLoading = true;
        state.hasSitesError = false;
      })
      .addCase(fetchSites.fulfilled, (state, action) => {
        state.sites = action.payload.siteList.sites;
        state.companies = action.payload.companyList;
        state.sitesSkipAndTakeQueryStats =
          action.payload.siteList.skipAndTakeQueryStats;
        state.isSitesLoading = false;
      })
      .addCase(fetchSites.rejected, (state, action) => {
        state.sites = [];
        state.companies = [];
        state.isSitesLoading = false;
        state.hasSitesError = true;
        state.sitesSkipAndTakeQueryStats =
          initialState.sitesSkipAndTakeQueryStats;
      })

      .addCase(fetchCompaniesSupplier.fulfilled, (state, action) => {
        state.defaultSupplierCompanies = action.payload;
      })
      .addCase(fetchCompaniesSupplier.rejected, (state, action) => {
        state.defaultSupplierCompanies = [];
      })
      .addCase(fetchAllCompaniesSupplier.fulfilled, (state, action) => {
        state.allSupplierCompanies = action.payload;
      })
      .addCase(fetchAllCompaniesSupplier.rejected, (state, action) => {
        state.allSupplierCompanies = [];
      })
      .addCase(fetchKpiIndicators.pending, (state, action) => {
        state.isKpiDataLoading = true;
      })
      .addCase(fetchKpiIndicators.fulfilled, (state, action) => {
        state.kpiData = action.payload;
        state.isKpiDataLoading = false;
      })
      .addCase(fetchKpiIndicators.rejected, (state, action) => {
        state.kpiData = undefined;
        state.isKpiDataLoading = false;
      });
  },
});

export const { clearSites, clearKpiData } = slice.actions;
export default slice.reducer;
