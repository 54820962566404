import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { CompanySettings } from "@trace-one/api-clients.pmd/dist/models/company-settings";
import { Button, Heading } from "@trace-one/design-system";
import { Card } from "@trace-one/react-components";
import { Form, Typography } from "antd";
import { FormListFieldData } from "antd/lib/form/FormList";
import moment from "moment";

import { useAppDispatch } from "reduxStore";
import { selectProductDetailsData } from "reduxStore/productDetails/selectors";
import { setProductFormData } from "reduxStore/productDetails/slice";
import { selectProductFormData } from "reduxStore/productForm/selectors";
import { selectSupplierCompaniesData } from "reduxStore/productShared/selectors";
import { selectCountriesOptions } from "reduxStore/shared/selectors";

import { CompanyType, ManufacturingItemStatus } from "shared/constants";
import { FormInstanceType } from "shared/hooks/useAntForm";

import { ProductFormValues, ManufacturedItemFormValues } from "../../../models";

import ManufacturedAction from "./components/ManufacturedAction";
import ManufacturedItem from "./components/ManufacturedItem";
import ManufacturedStatus from "./components/ManufacturedStatus";
import TargetMarket from "./components/TargetMarket";
import styles from "./ManufacturedCard.module.less";

interface ManufacturedCardProps {
  manufacturedField: FormListFieldData;
  onManufacturedRemove: () => void;
  disabled: boolean;
  form: FormInstanceType<ProductFormValues>;
  duplicatedSupplierCompanyId: string[];
  isTradeItemInactive: boolean;
  initialManufacturedItems: ManufacturedItemFormValues[];
  companySettings?: CompanySettings;
}

const ManufacturedCard: React.FC<ManufacturedCardProps> = ({
  manufacturedField,
  onManufacturedRemove,
  disabled,
  form,
  duplicatedSupplierCompanyId,
  isTradeItemInactive,
  initialManufacturedItems,
  companySettings,
}) => {
  const intl = useIntl();
  const countriesOptions = useSelector(selectCountriesOptions);
  const { data: supplierCompanies } = useSelector(selectSupplierCompaniesData);
  const { productFormData } = useSelector(selectProductDetailsData);
  const { productSelectedSupplier } = useSelector(selectProductFormData);
  const dispatch = useAppDispatch();
  const currentKey = ["manufacturedItems", manufacturedField.name];
  const {
    manufacturedItemName,
    id,
    manufacturedItemStatusId,
    lastStatusUpdateDateTime,
  } = form.getFieldValue<ManufacturedItemFormValues>(currentKey) ?? {};
  const { supplierCompanyId } =
    form.getFieldValue<ManufacturedItemFormValues>(currentKey);

  const supplierLabel = supplierCompanyId?.name;
  const isPrivate = supplierCompanyId?.companyType === CompanyType.PRIVATE;
  const isSupplierCompanyId = supplierCompanyId?.value;
  useEffect(() => {
    const fieldValue = form.getFieldsValue();
    const index = fieldValue.manufacturedItems.findIndex(
      item => item.id === productSelectedSupplier
    );
    const finalIndex = index === -1 ? 0 : index;
    setTimeout(
      () =>
        form.scrollToField(
          ["manufacturedItems", finalIndex, "supplierCompanyId"],
          {
            behavior: "smooth",
            block: "center",
          }
        ),
      100
    );
  }, [productSelectedSupplier]);
  const isManufacturedItemInactive =
    manufacturedItemStatusId === ManufacturingItemStatus.INACTIVE;
  const isManufacturedExist = !!id;

  const isSupplierCompanyIdDuplicated =
    !isManufacturedExist &&
    duplicatedSupplierCompanyId.includes(isSupplierCompanyId);

  const hasError =
    isSupplierCompanyIdDuplicated ||
    form.getFieldError([...currentKey, "supplierCompanyId"]).length > 0;

  const onToggleManufacturedStatus = () => {
    const fields = form.getFieldsValue();
    const fieldobj = isManufacturedItemInactive
      ? ManufacturingItemStatus.ACTIVE
      : ManufacturingItemStatus.INACTIVE;
    const selectedFieldData = fields.manufacturedItems[manufacturedField.name];
    const editedData = {
      ...selectedFieldData,
      ...{ manufacturedItemStatusId: fieldobj },
    };
    const finalEditedData = fields.manufacturedItems.map((item, index) => {
      if (index === manufacturedField.name) return editedData;
      else return item;
    });
    const dataToSave = {
      ...productFormData,
      ...{ manufacturedItems: finalEditedData },
    };
    dispatch(setProductFormData(dataToSave));
    form.setFieldsValueAndMarkDirty({ manufacturedItems: finalEditedData });
  };

  const shouldDisplayLastUpdateDateOfInative =
    isManufacturedItemInactive &&
    !!initialManufacturedItems.find(
      item =>
        item.id === id &&
        item.manufacturedItemStatusId === ManufacturingItemStatus.INACTIVE
    );

  const toExpand = id === productSelectedSupplier;

  return (
    <Card
      title={
        <div className={styles.cardTitleWrapper}>
          <div>
            <Heading
              className={isPrivate && styles.privateCompanyTitle}
              size="xs"
            >
              {[supplierLabel, manufacturedItemName]
                .filter(item => item)
                .join(" - ")}
            </Heading>
          </div>
          <div className={styles.cardTitleBottom}>
            <ManufacturedStatus value={manufacturedItemStatusId} size="small" />
            {shouldDisplayLastUpdateDateOfInative && (
              <Typography.Text>
                {" "}
                {intl.formatMessage({ id: "general.since" })}{" "}
                {moment(lastStatusUpdateDateTime).format(
                  moment.localeData().longDateFormat("L")
                )}
              </Typography.Text>
            )}
          </div>
        </div>
      }
      collapsible
      extra={
        !disabled && (
          <ManufacturedAction
            hideRemove={isManufacturedExist}
            hideToggleManufacturedStatus={
              !isManufacturedExist || isTradeItemInactive
            }
            isManufacturedItemInactive={isManufacturedItemInactive}
            onManufacturedRemove={onManufacturedRemove}
            manufacturedItemId={id}
            onToggleManufacturedStatus={onToggleManufacturedStatus}
          />
        )
      }
      expand={!isManufacturedExist || toExpand}
      hasError={hasError}
      errorMessage={intl.formatMessage({
        id: "general.correctTheHighlightedError",
      })}
      data-test-id={`MFCard ${manufacturedField.name}`}
      style={{ marginBottom: 15 }}
    >
      <div className={styles.cardContent}>
        <div>
          <Heading size="xs" className={styles.subTitle}>
            {intl.formatMessage({
              id: "productForm.manufactured.card.manufacturedItemTitle",
            })}
          </Heading>
          <div className={styles.cardSubSection}>
            <ManufacturedItem
              manufacturedItemStatusId={manufacturedItemStatusId}
              manufacturedField={manufacturedField}
              disabled={disabled || isTradeItemInactive}
              isManufacturedExist={isManufacturedExist}
              isSupplierCompanyIdDuplicated={isSupplierCompanyIdDuplicated}
              countriesOptions={countriesOptions}
              supplierCompanies={supplierCompanies}
              companySettings={companySettings}
            />
          </div>
        </div>
        <div>
          <Heading size="xs" className={styles.subTitle}>
            {intl.formatMessage({
              id: "productForm.manufactured.card.dateAndMarketsTitle",
            })}
          </Heading>
          <div className={styles.cardSubSection}>
            <Form.List name={[manufacturedField.name, "targetMarketsList"]}>
              {(targetMarketsList, { add, remove }) => (
                <>
                  <div>
                    {targetMarketsList.map(targetMarketField => (
                      <TargetMarket
                        key={targetMarketField.name}
                        disabled={disabled || isTradeItemInactive}
                        targetMarketField={targetMarketField}
                        onRemove={() => remove(targetMarketField.name)}
                        countriesOptions={countriesOptions}
                      />
                    ))}
                  </div>
                  <Button
                    onClick={() => {
                      add();
                    }}
                    className={styles.addMarketTargetBtn}
                    disabled={disabled || isTradeItemInactive}
                    type="secondary"
                    data-test-id="md-product-manu-add-target-markets"
                  >
                    {intl.formatMessage({
                      id: "productForm.manufactured.card.addMarket",
                    })}
                  </Button>
                </>
              )}
            </Form.List>
          </div>
        </div>
      </div>
    </Card>
  );
};

ManufacturedCard.propTypes = {};

export default ManufacturedCard;
