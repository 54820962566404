import { memo, useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Filter } from "@trace-one/business-components";
import { Picker } from "@trace-one/design-system";

import {
  selectCountriesOptions,
  selectRelatedCompanyOptions,
} from "reduxStore/shared/selectors";

import AsyncCompanySelect from "components/AsyncCompanySelect";
import AsyncPickerDropdown from "components/AsyncPickerDropdown";
import CheckableTags from "components/CheckableTags";
import Select from "components/Select";
import { CompanyActivityId } from "shared/constants";
import { adjustIntercomButton } from "shared/utils/toggleIntercom";

import { SiteRelationFilter } from "../modals";

import styles from "./SiteSearch.module.less";

interface SiteSearchProps {
  isRetailer: boolean;
  filterObj: SiteRelationFilter;
  mergeFilters: (
    value:
      | Partial<SiteRelationFilter>
      | ((prevState: SiteRelationFilter) => SiteRelationFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: (searchValue: string) => void;
  onClearFiltersClick: () => void;
  setSelectedKpi: (kpi: string) => void;
}

const SiteSearch: React.FC<SiteSearchProps> = ({
  isRetailer,
  filterObj,
  onSearch,
  initialSearchValue,
  mergeFilters,
  setSelectedKpi,
  onClearFiltersClick,
}) => {
  const intl = useIntl();
  const countries = useSelector(selectCountriesOptions);
  const relatedCompanyOptions = useSelector(selectRelatedCompanyOptions);
  const companies = relatedCompanyOptions;
  const [associatedCompanyLabel, setAssociatedCompanyLabel] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const showIntercom = useRef(true);

  const activeStatus = [
    {
      value: "true",
      label: intl.formatMessage({ id: "general.active" }),
    },
    {
      value: "false",
      label: intl.formatMessage({ id: "general.inactive" }),
    },
  ];

  const siteCertificatesOptions = [
    {
      value: "SiteWithNoCertificate",
      name: intl.formatMessage({ id: "siteForm.SiteWithNoCertificate" }),
    },
    {
      value: "SiteWithCertificateExpire",
      name: intl.formatMessage({ id: "siteForm.SiteWithCertificateExpire" }),
    },
    {
      value: "SiteWithCertificateNoEndDate",
      name: intl.formatMessage({ id: "siteForm.SiteWithCertificateNoEndDate" }),
    },
  ];

  useEffect(() => {
    const { associatedCompanyId, siteCountry, siteActive, siteCertificate } =
      filterObj;
    const defaultSelectedFilters = [
      ...(associatedCompanyId
        ? [
            {
              filterId: "1",
              values: [associatedCompanyId],
            },
          ]
        : []),

      ...(siteCountry
        ? [
            {
              filterId: "2",
              values: [siteCountry],
            },
          ]
        : []),
      ...(siteActive
        ? [
            {
              filterId: "3",
              values: siteActive,
            },
          ]
        : []),
      ...(siteCertificate
        ? [
            {
              filterId: "4",
              values: [true],
            },
          ]
        : []),
    ];
    setSelectedFilters(defaultSelectedFilters);
  }, [filterObj]);

  const filters = [
    {
      title: intl.formatMessage({ id: "sitesListPage.table.visibleBy" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({ associatedCompanyId: null });
      },
      elementToRender: (
        <AsyncCompanySelect
          includePrivate={true}
          isSupplierInput={""}
          value={filterObj.associatedCompanyId}
          label={associatedCompanyLabel}
          onChange={associatedCompanyId => {
            setSelectedKpi(null);
            mergeFilters({ associatedCompanyId });
          }}
          defaultOptions={companies}
          companyActivityId={
            isRetailer ? CompanyActivityId.SUPPLIER : undefined
          }
          isRetailer={isRetailer}
          onLabelChange={name => {
            setAssociatedCompanyLabel(name);
          }}
          key={associatedCompanyLabel}
          id="md-siteFilter-companyId"
          getPopupContainer={trigger => trigger.parentElement}
          data-test-id="md-siteFilter-visibleby"
        />
      ),
      quickFilter: (
        <AsyncPickerDropdown
          data-test-id="picker-filter-visibleby"
          searchPlaceholderText={intl.formatMessage({ id: "general.search" })}
          clearBtnText={intl.formatMessage({ id: "general.clear" })}
          closeBtnText={intl.formatMessage({ id: "general.close" })}
          title={intl.formatMessage({ id: "sitesListPage.table.visibleBy" })}
          showBadgeCount
          searchOnChange
          onSearch={str => {
            return companies
              .filter(x => x.name.toLowerCase().includes(str.toLowerCase()))
              .map(c => ({
                label: c.name,
                value: c.value,
              }));
          }}
          defaultOptions={companies.map(c => ({
            label: c.name,
            value: c.value,
          }))}
          value={
            filterObj.associatedCompanyId ? [filterObj.associatedCompanyId] : []
          }
          showSearchInput
          selectionType="single"
          minLengthToSearch={1}
          onAsyncSearch={undefined}
          onChange={({ value }) => {
            setSelectedKpi(null);
            mergeFilters({
              associatedCompanyId:
                value[0] === filterObj.associatedCompanyId
                  ? undefined
                  : value[0],
            });
          }}
          clearSelection={() => mergeFilters({ associatedCompanyId: null })}
        ></AsyncPickerDropdown>
      ),
      filterId: "1",
    },
    {
      title: intl.formatMessage({
        id: "general.country",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({ siteCountry: null });
      },
      elementToRender: (
        <Select
          allowClear
          showSearch
          options={countries}
          getPopupContainer={trigger => trigger.parentElement}
          value={filterObj.siteCountry}
          onChange={siteCountry => {
            setSelectedKpi(null);
            mergeFilters({ siteCountry });
          }}
          data-test-id="md-siteFilter-country"
          filterOption={(input, option) =>
            (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
      filterId: "2",
    },
    // {
    //   title: intl.formatMessage({
    //     id: "general.productCategory",
    //   }),
    //   controlType: "single_selection",
    //   clearSelection: () => {
    //     mergeFilters({ productCategory: undefined });
    //   },
    //   elementToRender: (
    //     <CategoryTreeSelect
    //       showSearch
    //       multiple={false}
    //       onChange={productCategory => {
    //         mergeFilters({ productCategory });
    //       }}
    //       value={filterObj?.productCategory}
    //       getPopupContainer={trigger => trigger.parentElement}
    //       onFullChange={value => {}}
    //       categorySelection={true}
    //       data-test-id="md-siteFilter-productCategory"
    //     />
    //   ),
    //   filterId: "3",
    // },
    {
      title: intl.formatMessage({
        id: "general.status",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          siteActive: [],
        });
      },
      elementToRender: (
        <CheckableTags
          value={filterObj.siteActive}
          onChange={siteActive => {
            setSelectedKpi(null);
            mergeFilters({ siteActive });
          }}
          options={activeStatus}
        />
      ),
      quickFilter: (
        <Picker.Filter
          data-test-id="picker-filter-status"
          title={intl.formatMessage({
            id: "general.status",
          })}
          clearBtnText={intl.formatMessage({ id: "general.clear" })}
          closeBtnText={intl.formatMessage({ id: "general.close" })}
          showBadgeCount
          items={activeStatus}
          value={filterObj.siteActive ?? []}
          selectionType="multi"
          onChange={({ value: siteActive }) => {
            setSelectedKpi(null);
            mergeFilters({
              siteActive,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              siteActive: [],
            })
          }
        ></Picker.Filter>
      ),
      filterId: "3",
    },
    {
      title: intl.formatMessage({
        id: "sitesListPage.table.siteCertificates",
      }),
      controlType: "single_selection",
      elementToRender: (
        <div className={styles.siteFilterCertificateSection}>
          <Select
            allowClear
            showSearch
            options={siteCertificatesOptions}
            getPopupContainer={trigger => trigger.parentElement}
            value={filterObj.siteCertificate}
            onChange={siteCertificate => {
              setSelectedKpi(null);
              mergeFilters({ siteCertificate });
            }}
            data-test-id="md-siteFilter-certificate"
          />
        </div>
      ),
      filterId: "4",
    },
  ];

  return (
    <Filter
      filters={filters}
      selectedFilters={selectedFilters}
      clearAllFilters={() => {
        onClearFiltersClick();
        setSelectedKpi(null);
      }}
      search={{
        defaultValue: initialSearchValue,
        placeholder: intl.formatMessage({
          id: "sitesListPage.search",
        }),
        onSearch: query => {
          onSearch(query);
        },
      }}
      onAllFilterPanelToggle={() => {
        showIntercom.current = !showIntercom.current;
        adjustIntercomButton(showIntercom.current);
      }}
    />
  );
};

export default memo(SiteSearch);
