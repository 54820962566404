import { useState } from "react";
import { useIntl } from "react-intl";

import { Button, Tooltip } from "@trace-one/design-system";

import { CumdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import Table from "components/Table";
import useAsyncFileDownload from "shared/hooks/useAsyncFileDownload";

import { SupplierFilter } from "../models";

interface SupplierHeaderProps {
  listingResult: React.ReactNode;
  searchText: string;
  filterObj: SupplierFilter;
  isEnabled?: boolean;
  selectedRowKeys: string[];
  ownerCompanyId: string;
  isAllSelected: boolean;
}

const SupplierHeader: React.FC<SupplierHeaderProps> = ({
  listingResult,
  searchText,
  filterObj,
  isEnabled,
  selectedRowKeys,
  ownerCompanyId,
  isAllSelected,
}) => {
  const intl = useIntl();
  const { companiesRead } = usePermissions();
  const {
    checked: onlyWithoutContacts,
    userId: contactUserId,
    responsibilityId: contactResponsibilityId,
  } = filterObj;
  const isExportDisabled = !selectedRowKeys.length && !isAllSelected;
  const [visible, setVisible] = useState<boolean>(false);

  const [handleExportAllSuppliers, isExportAllSuppliersLoading] =
    useAsyncFileDownload(
      () =>
        CumdAPI.exportCompanies(
          {},
          {
            relatedOwnerCompanyId: ownerCompanyId,
            companyActivityId: filterObj.companyActivity
              ? parseInt(filterObj.companyActivity)
              : undefined,
            isCompanyRelationStatusEnabled: isEnabled,
            country: filterObj.companyCountry,
            searchText,
            onlyWithoutContacts,
            contactUserId: contactUserId || undefined,
            contactResponsibilityId: contactResponsibilityId || undefined,
            relatedCategoryId: filterObj?.productCategory?.categoryId,
            relatedCategoryItemId: filterObj?.productCategory?.categoryItemId,
          }
        ),
      { fileExtension: "csv" }
    );

  const [handleExportSelectedSuppliers, isExportSelectedSuppliersLoading] =
    useAsyncFileDownload(
      () =>
        CumdAPI.exportCompanies(
          {
            companyIds: selectedRowKeys,
          },
          {
            relatedOwnerCompanyId: ownerCompanyId,
            relatedCategoryId: filterObj?.productCategory?.categoryId,
            relatedCategoryItemId: filterObj?.productCategory?.categoryItemId,
          }
        ),
      { fileExtension: "csv" }
    );

  return (
    <Table.Header listingResult={listingResult}>
      {companiesRead && (
        <Tooltip
          onOpenChange={() => isExportDisabled && setVisible(!visible)}
          placement="left"
          text={intl.formatMessage({
            id: "supplierListPage.export.toolTipTitle",
          })}
          visible={isExportDisabled && visible}
        >
          <div data-test-id="tooltipBtn">
            <Button
              onClick={
                isAllSelected
                  ? handleExportAllSuppliers
                  : handleExportSelectedSuppliers
              }
              loading={
                isExportAllSuppliersLoading || isExportSelectedSuppliersLoading
              }
              disabled={isExportDisabled}
              type="tertiary"
              data-test-id="md-supplier-export-btn"
            >
              {intl.formatMessage({
                id: "general.export",
              })}
            </Button>
          </div>
        </Tooltip>
      )}
    </Table.Header>
  );
};

export default SupplierHeader;
